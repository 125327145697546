import React, { Component, useEffect, useState, useContext } from "react";
import { Edit3 } from "react-feather";
import Notiflix from "notiflix";

import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import moment from "moment";
import { Edit } from "react-feather";
import { Link } from "react-router-dom";

import { Button, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { FaEdit } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PostApiCall from "../../../assets/API/PostApi";
import store from "../../../store";
import { AiOutlineFileSearch } from "react-icons/ai";

export default function VendorContactList(props) {
  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 3,

  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 4,
  //         infinite: true,
  //         // dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         initialSlide: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 860,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],

  // };
  var i = 0;
  const {
    addContactData,
    setAddContactData,
    addressId,
    setAddressId,
    contactId,
    setContactId,
    contactName,
    setContactName,
    designation,
    setDesignation,
    contactEmail,
    setContactEmail,
    contactMobile,
    setContactMobile,
  } = useContext(store);

  useEffect(() => {
    Notiflix.Loading.init({
      svgColor: "rgb(6, 10, 74)",
    });
    Notiflix.Loading.dots("Please wait...");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    PostApiCall.postRequest(
      {
        wherecaluse: props.contactAddressId,
      },
      "GetVendorContacts"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setAddContactData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Notify.failure("Error Ocurred!");
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);
  return (
    <>
      <div className="form-head mb-lg-0 mb-1 mt-2">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Contact List </h4>
        </div>
      </div>
      {/* <div className="card-vahani p-0 mt-2 vendor-card-address">
        <p className="personal-info m-0 left-line-card p-4 d-flex align-items-center justify-content-between">
          Vendor Contact List{" "}
          <div className="col-md-2 ps-0 pe-0">
            <Button variant="contained" className="w-100">
              <Link to="/add-vendor-contact-details" className="text-white">
                Add New Contact
              </Link>
            </Button>
          </div>
        </p>

        

        <Slider {...settings} className="mb-3">
          <div className="mx-3 my-3">
            <div class="card mx-2">
              <div class="card-body">
                <h5 class="card-title">
                  <strong>Vendor Name</strong>
                </h5>
                <p class="card-text mb-1 ">
                  <strong>Bank Name</strong>
                </p>
                <p class="card-text mb-1">Account Number</p>
                <p class="card-text mb-1">Branch</p>
                <p class="card-text mb-1">Account Holder Name</p>
                <p class="card-text mb-1">IFSC Code</p>
              </div>

              <div class="card-footer">
                <a href="javascript:void(0)" class="btn btn-primary float-end">
                  <FaEdit className="edit-icon edit-icon-font-size" />
                </a>
              </div>
            </div>
          </div>
          <div className="mx-3 my-3">
            <div class="card mx-2">
              <div class="card-body">
                <h5 class="card-title">
                  <strong>Vendor Name</strong>
                </h5>
                <p class="card-text mb-1 ">
                  <strong>Bank Name</strong>
                </p>
                <p class="card-text mb-1">Account Number</p>
                <p class="card-text mb-1">Branch</p>
                <p class="card-text mb-1">Account Holder Name</p>
                <p class="card-text mb-1">IFSC Code</p>
              </div>
              <div class="card-footer">
                <a href="javascript:void(0)" class="btn btn-primary float-end">
                  <FaEdit className="edit-icon edit-icon-font-size" />
                </a>
              </div>
            </div>
          </div>
          <div className="mx-3 my-3">
            <div class="card mx-2">
              <div class="card-body">
                <h5 class="card-title">
                  <strong>Vendor Name</strong>
                </h5>
                <p class="card-text mb-1 ">
                  <strong>Bank Name</strong>
                </p>
                <p class="card-text mb-1">Account Number</p>
                <p class="card-text mb-1">Branch</p>
                <p class="card-text mb-1">Account Holder Name</p>
                <p class="card-text mb-1">IFSC Code</p>
              </div>
              <div class="card-footer">
                <a href="javascript:void(0)" class="btn btn-primary float-end">
                  <FaEdit className="edit-icon edit-icon-font-size" />
                </a>
              </div>
            </div>
          </div>
          <div className="mx-3 my-3">
            <div class="card mx-2">
              <div class="card-body">
                <h5 class="card-title">
                  <strong>Vendor Name</strong>
                </h5>
                <p class="card-text mb-1 ">
                  <strong>Bank Name</strong>
                </p>
                <p class="card-text mb-1">Account Number</p>
                <p class="card-text mb-1">Branch</p>
                <p class="card-text mb-1">Account Holder Name</p>
                <p class="card-text mb-1">IFSC Code</p>
              </div>
              <div class="card-footer">
                <a href="javascript:void(0)" class="btn btn-primary float-end">
                  <FaEdit className="edit-icon edit-icon-font-size" />
                </a>
              </div>
            </div>
          </div>
          <div className="mx-3 my-3">
            <div class="card mx-2">
              <div class="card-body">
                <h5 class="card-title">
                  <strong>Vendor Name</strong>
                </h5>
                <p class="card-text mb-1 ">
                  <strong>Bank Name</strong>
                </p>
                <p class="card-text mb-1">Account Number</p>
                <p class="card-text mb-1">Branch</p>
                <p class="card-text mb-1">Account Holder Name</p>
                <p class="card-text mb-1">IFSC Code</p>
              </div>
              <div class="card-footer">
                <a href="javascript:void(0)" class="btn btn-primary float-end">
                  <FaEdit className="edit-icon edit-icon-font-size" />
                </a>
              </div>
            </div>
          </div>
        </Slider>
      </div> */}

      {addContactData.length != 0 ? (
        addContactData?.map((contact) => {
          return (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <strong>{contact.fld_contact_name}</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p class="card-text mb-1">{contact.fld_contact_name}</p>
                  <p class="card-text mb-1">{contact.fld_designation}</p>
                  <p class="card-text mb-1">{contact.fld_email}</p>
                  <p class="card-text mb-1">{contact.fld_mobile}</p>
                  <p class="card-text mb-1">{contact.fld_vendor_name}</p>
                </Typography>
                <div className="row">
                  <div className="col-12 d-flex justify-content-end">
                    <div className="w-25 text-end">
                      <FaEdit
                        className="fs-4"
                        onClick={() => {
                          setAddressId(contact.fld_address_id);
                          setContactId(contact.fld_id);
                          setContactName(contact.fld_contact_name);
                          setDesignation(contact.fld_designation);
                          setContactEmail(contact.fld_email);
                          setContactMobile(contact.fld_mobile);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <Col lg={12} className="">
          <div className="text-center w-100">
            <div className="justify-content-center no-data-icon mt-4">
              <AiOutlineFileSearch className="no-found-data-size" />
            </div>
            <h4>No data found</h4>
          </div>
        </Col>
      )}
    </>
  );
}
