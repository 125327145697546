import React, { useState, useContext } from "react";
import {
  Table,
  FormControl,
  FormLabel,
  Button,
  Select,
  FormControlLabel,
  MenuItem,
  FormHelperText,
  InputLabel,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
// import GetApiCall from "../../../assets/API/GetApi";
import EditIcon from "@mui/icons-material/Edit";
import { Visibility } from "@mui/icons-material";
let SemesterData = [
  { value: "1st", label: "1st" },
  { value: "2nd", label: "2nd" },
  { value: "3rd", label: "3rd" },
  { value: "4th", label: "4th" },
  { value: "5th", label: "5th" },
  { value: "6th", label: "6th" },
  { value: "7th", label: "7th" },
  { value: "8th", label: "8th" },
  { value: "9th", label: "9th" },
  { value: "10th", label: "10th" },
];
export default function ResultHistory({ stdid, action }) {
  var login = stdid
    ? { fld_id: stdid }
    : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [List, setList] = useState([]);
  const [SemesterDropdown, setSemesterDropdown] = useState(SemesterData);
  const [MarksheetUrl, setMarksheetUrl] = useState("");
  const getResut = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_ExamResults_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // let filterdropdown = SemesterData;
          obj.data.map((item) => {
            SemesterData = SemesterData.filter(
              (val) => val.value != item.fld_examSemester
            );
          });
          setSemesterDropdown(SemesterData);
          setList(obj.data);
          //
        }
        Notiflix.Loading.remove();
      })
    );
  };
  React.useEffect(() => {
    getResut();
  }, []);
  const onSave = (data) => {
    Notiflix.Loading.dots("Please wait...");
    PostApiCall.postRequest(
      {
        fld_id: data.fld_id == undefined ? null : data.fld_id,
        studentId: login.fld_id,
        examPercentage: data.fld_examPercentage,
        examTotalMarks: data.fld_examTotalMarks,
        examMaxMarks: data.fld_examMaxMarks,
        examSemester: data.fld_examSemester,
        examResultPdf: data.fld_examResultPdf,
      },
      "Add_ExamResults"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Result Save Successfully");
          getResut();
          reset();
          setMarksheetUrl("");
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
          reset();
        }
      })
    );
  };
  //
  return (
    <>
      <div className="form-head">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Exam Result History</h4>
        </div>
      </div>
      {action != "view" && SemesterDropdown.length > 0 && (
        <form
          className="card-vahani row m-0 mt-0 mb-5"
          onSubmit={handleSubmit(onSave)}
        >
          <div className="col-md-4 mb-3">
            <FormControl fullWidth error={Boolean(errors.fld_examSemester)}>
              <InputLabel>Semester*</InputLabel>
              <Select
                label="Semester"
                {...register("fld_examSemester", {
                  required: "This field is required",
                })}
                error={errors?.fld_examSemester}
                value={
                  watch("fld_examSemester") ? watch("fld_examSemester") : null
                }
                onChange={(e) => setValue("fld_examSemester", e.target.value)}
              >
                {SemesterDropdown.map((record, item) => (
                  <MenuItem value={record.value}>{record.label} </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {" "}
                {errors?.fld_examSemester
                  ? errors.fld_examSemester.message
                  : null}{" "}
              </FormHelperText>
            </FormControl>
          </div>
          <div className="col-md-4 mb-3">
            <TextField
              fullWidth
              label="Maximum Marks*"
              {...register("fld_examTotalMarks", {
                required: "This field is required",
              })}
              value={
                watch("fld_examTotalMarks") ? watch("fld_examTotalMarks") : ""
              }
              error={errors?.fld_examTotalMarks}
              helperText={
                errors?.fld_examTotalMarks
                  ? errors.fld_examTotalMarks.message
                  : null
              }
              InputLabelProps={{ required: true }}
              inputProps={{
                pattern: "[0-9]*",
              }}
            />
          </div>
          <div className="col-md-4 mb-3">
            <TextField
              fullWidth
              label="Marks Obtained*"
              {...register("fld_examMaxMarks", {
                required: "This field is required",
              })}
              value={watch("fld_examMaxMarks") ? watch("fld_examMaxMarks") : ""}
              error={errors?.fld_examMaxMarks}
              helperText={
                errors?.fld_examMaxMarks
                  ? errors.fld_examMaxMarks.message
                  : null
              }
              InputLabelProps={{ required: true }}
              inputProps={{
                pattern: "[0-9]*",
              }}
            />
          </div>
          <div className="col-md-6 mb-3">
            <TextField
              fullWidth
              label="Percentage / (CGPA / SGPA) / Grade*"
              {...register("fld_examPercentage", {
                required: "This field is required",
              })}
              value={
                watch("fld_examPercentage") ? watch("fld_examPercentage") : ""
              }
              error={errors?.fld_examPercentage}
              helperText={
                errors?.fld_examPercentage
                  ? errors.fld_examPercentage.message
                  : null
              }
              InputLabelProps={{ required: true }}
              // inputProps={{
              //   pattern: "[0-9]*",
              // }}
            />
          </div>
          <div class="col-md-6 mb-3">
            <button
              {...register("fld_examResultPdf", {
                required: "This field is required",
              })}
              className="btn btn-success position-absolute"
              style={{
                zIndex: "999",
                right: "20px",
                height: "40px",
                top: "8px",
                visibility:
                  watch(`fld_examResultPdf`) != "" ? "visible" : "hidden",
              }}
            >
              <a
                style={{ color: "white" }}
                href={watch("fld_examResultPdf")}
                target="_blank"
              >
                View
              </a>
            </button>
            <TextField
              fullWidth
              label="Upload Marksheet (Maximum Size 6 MB | File Format .pdf .jpeg)*"
              type="File"
              InputLabelProps={{ shrink: true }}
              error={errors?.fld_examResultPdf}
              helperText={
                errors?.fld_examResultPdf
                  ? errors.fld_examResultPdf.message
                  : null
              }
              inputProps={{ accept: "application/pdf, image/jpeg, image/jpg" }}
              onChange={async (e) => {
                if (e.target.files[0].size < 6000000) {
                  const form = new FormData();
                  let path = `${login.fld_id}-${Math.floor(
                    Math.random() * 1000000
                  )}-${e.target.files[0].name}`;
                  form.append("file", e.target.files[0]);
                  form.append("foldername", "ExamResult");
                  form.append("filename", path);
                  Notiflix.Loading.dots("Please Wait...");
                  const res = await fetch(
                    "https://admin.vahanischolarship.in/API/AddImage2",
                    { method: "POST", body: form }
                  );
                  const response = await res.json();
                  if (response) {
                    Notiflix.Notify.success("Marksheet Uploaded Successfully.");
                    Notiflix.Loading.remove();
                    setValue(
                      "fld_examResultPdf",
                      `https://admin.vahanischolarship.in/Images/ExamResult/${path}`
                    );
                  } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(
                      "There seems to be an Error. Please retry!"
                    );
                  }
                } else {
                  Notiflix.Notify.failure(
                    "File too large, upload file less than 6 MB."
                  );
                }
              }}
            />
          </div>
          <div className="d-flex justify-content-end">
            <div className="but-width-form">
              <Button
                variant="contained"
                className="but-login-vahani"
                type="submit"
              >
                Save and update
              </Button>
            </div>
          </div>
        </form>
      )}
      <div className="card-vahani p-0">
        <div className="row justify-content-between align-items-center m-0 left-line-card px-4 py-3 ">
          <p className="personal-info m-0 p-0 col-auto ">
            Exam Result History List
          </p>
          {/* <Link className=' btn btn-primary col-auto' onClick={() => setexpenseData({ action: "add" })} to='/expense' > Create New +</Link> */}
        </div>
        <div className="px-4">
          {List.length > 0 ? (
            <MDBDataTable
              disableRetreatAfterSorting={true}
              responsive
              hover
              striped
              data={{
                columns: [
                  {
                    label: "Semester",
                    field: "semester",
                    sort: "disabled",
                  },
                  {
                    label: "Marks Obtained",
                    field: "maxmarks",
                    sort: "disabled",
                  },
                  {
                    label: "Maximum Marks",
                    field: "totalmarks",
                    sort: "disabled",
                  },
                  {
                    label: "Percentage / (CGPA/SGPA) / Grade",
                    field: "percent",
                    sort: "disabled",
                  },
                  {
                    label: "Marksheet",
                    field: "marksheet",
                    sort: "disabled",
                  },
                  {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                  },
                ],
                rows: List.map((data, i) => {
                  return {
                    semester: data.fld_examSemester,
                    maxmarks: data.fld_examMaxMarks,
                    totalmarks: data.fld_examTotalMarks,
                    percent: data.fld_examPercentage,
                    marksheet: (
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          window.open(data.fld_examResultPdf);
                        }}
                      >
                        View
                      </button>
                    ),
                    action: (
                      <EditIcon
                        color="primary"
                        className="cursor-pointer"
                        onClick={() => {
                          reset(data);
                          setSemesterDropdown([
                            {
                              label: data.fld_examSemester,
                              value: data.fld_examSemester,
                            },
                          ]);
                        }}
                      />
                    ),
                  };
                }),
              }}
              entriesOptions={[10, 25, 50, 100]}
            />
          ) : (
            <p className="m-3">No Data Found</p>
          )}
        </div>
      </div>
    </>
  );
}
