import React, { useEffect, useState } from "react";
import Notiflix from "notiflix";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import GetApiCall from "../../../../assets/API/GetApi";
import PostApiCall from "../../../../assets/API/PostApi";
import { TextField } from "@mui/material";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
const localizer = momentLocalizer(moment);
let allAttendeceDate = [];
const Calender = () => {
  const location = useLocation();
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [holidayopen, setHolidayOpen] = useState(false);
  const [currstart, setStart] = useState("");
  const [currend, setEnd] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleHolidayOpen = () => setHolidayOpen(true);
  const handleHolidayClose = () => setHolidayOpen(false);
  const [currempid, SetEmpId] = useState("");
  const [value, setValue] = React.useState("present");
  const [currHoliday, setCurrHoliday] = React.useState("");
  const { views, ...otherProps } = useMemo(
    () => ({
      views: { month: true, week: false, day: false, agenda: false },
    }),
    []
  );

  useEffect(() => {
    Attandance();
    Holidays();
  }, []);
  const Attandance = () => {
    PostApiCall.postRequest(
      {
        whereClause: "where [fld_employee_id]='" + location.state.data + "'",
      },
      "GetAttendance"
    ).then((resultattendance) =>
      resultattendance.json().then((obj) => {
        obj.data.map((data, i) => {
          var attendanceobj;

          attendanceobj = {
            title: data.fld_title,
            start: new Date(data.fld_start_date),
            end: new Date(data.fld_end_date),
            id: data.fld_id,
          };
          setEvents((prevState) => [...prevState, attendanceobj]);
          let month = new Date(data.fld_start_date).getMonth();
          month += 1;
          let startDate =
            new Date(data.fld_start_date).getDate() +
            "/" +
            month +
            "/" +
            new Date(data.fld_start_date).getFullYear();
          allAttendeceDate.push(startDate);
          SetEmpId(location.state.data);
        });
      })
    );
  };

  const Holidays = () => {
    GetApiCall.getRequest("GetHolidayList").then((resultdes) =>
      resultdes.json().then((obj) => {
        obj.data.map((data, i) => {
          var holidaysobj;
          var startHolidayDate = new Date(data.fld_holiday_date);
          var endHolidayDate = new Date(
            new Date(data.fld_holiday_date).getTime() + 24 * 60 * 60 * 1000
          );
          holidaysobj = {
            title: data.fld_event_name,
            start: new Date(startHolidayDate.setHours(0, 0, 0)),
            end: new Date(endHolidayDate.setHours(0, 0, 0)),
            id: data.fld_id,
          };
          setEvents((prevState) => [...prevState, holidaysobj]);
        });

        Notiflix.Loading.remove();
      })
    );
  };

  const handleSelect = ({ start, end }) => {
    let currmonth = new Date(start).getMonth();
    currmonth += 1;
    let currStartDate =
      start.getDate() + "/" + currmonth + "/" + start.getFullYear();
    if (allAttendeceDate.includes(currStartDate) != true) {
      setStart(start);
      setEnd(end);
      handleOpen();
    }
  };

  const addAttendance = () => {
    let title;
    let updateoradd = false;
    let attendanceid = 0;
    if (value == "present") {
      title = "Present";
    } else {
      title = "Absent";
    }
    if (events.length != 0) {
      events.map((event, i) => {
        if (event.start == currstart) {
          event.title = title;
          attendanceid = event.id;
          updateoradd = true;
        }
      });
    }

    Notiflix.Loading.dots("Please wait...");

    PostApiCall.postRequest(
      {
        attendanceid: attendanceid,
        title: title,
        startdate: new Date(currstart),
        enddate: new Date(currend),
        employeeid: location.state.data,
      },
      "AddAttendance"
    ).then((resultattendance) =>
      resultattendance.json().then((obj) => {
        if (resultattendance.status == 200 || resultattendance.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Attendance successfully submitted.");
          setEvents([]);
          Attandance();
          Holidays();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occured");
        }
      })
    );
    handleClose();
  };

  const handleEventClick = (event) => {
    if (event.title != "Present" && event.title != "Absent") {
      setCurrHoliday(event.title);
      handleHolidayOpen();
    } else {
      setStart(event.start);
      setEnd(event.end);
      handleOpen();
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const eventPropGetter = React.useCallback(
    (event, start, end, isSelected) => ({
      ...(event.title.includes("Present") && {
        style: {
          backgroundColor: "#2ab72a",
        },
      }),
      ...(event.title.includes("Absent") && {
        style: {
          backgroundColor: "#ff1a1a",
        },
      }),
      ...(event.title.includes("Absent") == false &&
        event.title.includes("Present") == false && {
        style: {
          backgroundColor: "#00e6e6",
        },
      }),
    }),
    []
  );

  return (
    <div>
      <div className="row m-0 justify-content-between align-items-center py-2">
        <div className="col-md-6 text-end col-12 mb-3 ms-auto">
          <div className="row justify-content-end">
            <div className="col-lg-6 col-lg-4 mb-lg-0 my-2">
              <Button variant="contained" className="w-100">
                <Link to="/employee_attendance" className="text-white">
                  Go Back To Attendance List
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={views}
        selectable
        eventPropGetter={eventPropGetter}
        onSelectSlot={handleSelect}
        onSelectEvent={handleEventClick}
      />
      {/* Once attendance modal is open */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="add-attendence-modal">
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Attendance
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="present"
                control={<Radio />}
                label="Present"
              />
              <FormControlLabel
                value="absent"
                control={<Radio />}
                label="Absent"
              />
            </RadioGroup>
          </FormControl>
          <Stack spacing={2} direction="row">
            <Button
              className="ml-auto"
              variant="contained"
              onClick={addAttendance}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={holidayopen}
        onClose={handleHolidayClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="add-attendence-modal">
          <FormControl
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <FormLabel id="demo-row-radio-buttons-group-label">
              Holiday
            </FormLabel>

            <TextField
              fullWidth
              value={currHoliday}
              readonly
              id="fullWidth"
              className="w-100"
            />
          </FormControl>
        </Box>
      </Modal>
    </div>
  );
};

export default Calender;
