import React, { Component } from "react";
import Notiflix from "notiflix";
import PostApiCall from "../../../../assets/API/PostApi";
import {
  MenuItem,
  TextField,
  Checkbox,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput,
  Button,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import moment from "moment";

class EditEmployeeLoginCredentials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      EmpEmailForLogin: "",
      EmpPasswordForLogin: "",
      EmpJoiningdate: "",
      EmpCurrentposition: "",
      YearOfJoining: "",
      EmpFirstName: "",
      EmpMiddleName: "",
      EmpLastname: "",
      EmpContact: "",
      EmpDepartment: "",
      EmpMonthlySalary: "",
      EmpAnnualSalary: "",
      EmpType: "Full Time",
      HRMSAdmin: "No",
    };
  }

  componentDidMount() {
    if (this.props.location.state != undefined) {
      this.getEmployeeLoginDetails();
    }
  }

  getEmployeeLoginDetails = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_id=${this.props.location.state.id}`,
      },
      "GetEmployeeById"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.dots("Please wait...");
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            this.setState({
              EmpEmailForLogin: obj.data[0].fld_email,
              EmpJoiningdate: moment(obj.data[0].fld_date_of_joining).format(
                "YYYY-MM-DD"
              ),
              EmpCurrentposition: obj.data[0].fld_designation,
              EmpFirstName: obj.data[0].fld_employee_full_name.split(" ")[0],
              EmpMiddleName: obj.data[0].fld_employee_full_name.split(" ")[1],
              EmpLastname: obj.data[0].fld_employee_full_name.split(" ")[2],
              EmpContact:
                obj.data[0].fld_contact_no == null
                  ? ""
                  : obj.data[0].fld_contact_no.split(" ")[0],
              EmpDepartment: obj.data[0].fld_department,
              EmpMonthlySalary: obj.data[0].fld_monthly_salary,
              EmpAnnualSalary: obj.data[0].fld_annual_salary,
              EmpType: obj.data[0].fld_emp_type,
              HRMSAdmin: obj.data[0].fld_admin,
            });
            Notiflix.Loading.remove();
          }
        }
        Notiflix.Loading.remove();
      })
    );
  };

  onAddNewEmployee = () => {
    if (
      this.state.EmpFirstName != "" &&
      this.state.EmpLastname != "" &&
      this.state.EmpContact != "" &&
      this.state.EmpEmailForLogin != "" &&
      // this.state.EmpPasswordForLogin != "" &&
      this.state.EmpCurrentposition != "" &&
      this.state.EmpJoiningdate != "" &&
      this.state.EmpDepartment != "" &&
      this.state.EmpMonthlySalary != "" &&
      this.state.EmpAnnualSalary != ""
    ) {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          id: this.props.location.state.id,
          empname:
            this.state.EmpFirstName +
            " " +
            this.state.EmpMiddleName +
            " " +
            this.state.EmpLastname,
          empcontact: this.state.EmpContact,
          email: this.state.EmpEmailForLogin,
          // password: this.state.EmpPasswordForLogin,
          currdesignation: this.state.EmpCurrentposition,
          joiningdate: this.state.EmpJoiningdate,
          empdepartment: this.state.EmpDepartment,
          empmonthlysalary: this.state.EmpMonthlySalary,
          empannualsalary: this.state.EmpAnnualSalary,
          emptype: this.state.EmpType,
          yearofjoining: this.state.YearOfJoining.toString(),
          hrmsadmin: this.state.HRMSAdmin,
          action: "EditEmployee",
        },
        "AddEmployeeLoginCredentials"
      ).then((resultcategory) =>
        resultcategory.json().then((obj) => {
          if (resultcategory.status == 200 || resultcategory.status == 201) {
            Notiflix.Notify.success("Employee Credentials Successfully Updated.");
            Notiflix.Loading.remove();
            window.history.go(-1);
          } else {
            Notiflix.Notify.failure(obj.data);
            Notiflix.Loading.remove();
          }
        })
      );
    } else {
      Notiflix.Notify.failure("Please Fill the All Mandatory Fields!");
    }
  };
  render() {
    return (
      <>
        <div>
          <div className="form-head ">
            <div
              style={{
                borderLeft: "10px solid rgb(32, 101, 209)",
                paddingLeft: "10px",
              }}
            >
              <h4>Add New Employee</h4>
            </div>
          </div>
          <div className="card-vahani p-0 pb-4 ">
            <p className="personal-info left-line-card px-lg-4 px-3 py-4">
              Professional Details
            </p>
            <div class="toast-body">
              <div class="row">
                <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row ps-2 pe-3">
                        <div class="row pe-0">
                          <div className="col-lg-4 mb-lg-3 mobile-personal">
                            <TextField
                              fullWidth
                              type="text"
                              label="First Name"
                              required={true}
                              className="text-field capitalonly"
                              InputProps={{ inputProps: { min: 1 } }}
                              value={this.state.EmpFirstName}
                              onChange={(e) => {
                                this.setState({
                                  EmpFirstName: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-4 mb-lg-3 mobile-personal">
                            <TextField
                              fullWidth
                              type="text"
                              label="Middle Name"
                              className="text-field capitalonly"
                              InputProps={{ inputProps: { min: 1 } }}
                              value={this.state.EmpMiddleName}
                              onChange={(e) => {
                                this.setState({
                                  EmpMiddleName: e.target.value,
                                });
                              }}
                            />
                          </div>

                          <div className="col-lg-4 mb-lg-3 mobile-personal pe-0">
                            <TextField
                              fullWidth
                              type="text"
                              label="Last Name"
                              required={true}
                              className="text-field capitalonly"
                              InputProps={{ inputProps: { min: 1 } }}
                              value={this.state.EmpLastname}
                              onChange={(e) => {
                                this.setState({
                                  EmpLastname: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-lg-3 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="text"
                            required={true}
                            label="Contact Number"
                            value={this.state.EmpContact}
                            onChange={(e) => {
                              this.setState({
                                EmpContact: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6 mb-lg-3 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="email"
                            required={true}
                            label="Email"
                            value={this.state.EmpEmailForLogin}
                            onChange={(e) => {
                              this.setState({
                                EmpEmailForLogin: e.target.value,
                              });
                            }}
                          />
                        </div>
                        {/* <div className="col-lg-6 mb-lg-3 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Password"
                            required={true}
                            className="text-field capitalonly"
                            InputProps={{ inputProps: { min: 1 } }}
                            value={this.state.EmpPasswordForLogin}
                            onChange={(e) => {
                              this.setState({
                                EmpPasswordForLogin: e.target.value,
                              });
                            }}
                          />
                        </div> */}
                        <div className="col-lg-6 mb-lg-3 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="date"
                            required={true}
                            helperText={" Date of Joining*"}
                            value={this.state.EmpJoiningdate}
                            onChange={(e) => {
                              this.setState({
                                EmpJoiningdate: e.target.value,
                                YearOfJoining: new Date(
                                  e.target.value
                                ).getFullYear(),
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6 mb-lg-3 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Designation"
                            required={true}
                            className="text-field capitalonly"
                            InputProps={{ inputProps: { min: 1 } }}
                            value={this.state.EmpCurrentposition}
                            onChange={(e) => {
                              this.setState({
                                EmpCurrentposition: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-4 mb-lg-3 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="text"
                            required={true}
                            label="Department"
                            value={this.state.EmpDepartment}
                            onChange={(e) => {
                              this.setState({
                                EmpDepartment: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-4 mb-lg-3 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="text"
                            required={true}
                            label="Monthly Salary"
                            value={this.state.EmpMonthlySalary}
                            onChange={(e) => {
                              this.setState({
                                EmpMonthlySalary: e.target.value,
                                EmpAnnualSalary: 12 * e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-4 mb-lg-3 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="text"
                            required={true}
                            label="Annual Salary"
                            value={this.state.EmpAnnualSalary}
                          />
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <FormControl className="ps-2">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            Employee Type
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            defaultValue="Full Time"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Full Time"
                              control={<Radio />}
                              label="Full Time"
                              onChange={(e) => {
                                this.setState({
                                  EmpType: e.target.value,
                                });
                              }}
                            />
                            <FormControlLabel
                              value="Part Time"
                              control={<Radio />}
                              label="Part Time"
                              onChange={(e) => {
                                this.setState({
                                  EmpType: e.target.value,
                                });
                              }}
                            />
                            <FormControlLabel
                              value="Contract"
                              control={<Radio />}
                              label="Contract"
                              onChange={(e) => {
                                this.setState({
                                  EmpType: e.target.value,
                                });
                              }}
                            />
                          </RadioGroup>
                        </FormControl>

                        <FormControl className="ps-2">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            HRMS Admin
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            defaultValue="No"
                            name="row-radio-buttons-group"
                            value={this.state.HRMSAdmin}
                          >
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                              onChange={(e) => {
                                this.setState({
                                  HRMSAdmin: e.target.value,
                                });
                              }}
                            />
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                              onChange={(e) => {
                                this.setState({
                                  HRMSAdmin: e.target.value,
                                });
                              }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-toggle="toast"
            >
              <div
                className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-3"
                style={{ float: "right" }}
              >
                <div
                  className=" justify-content-end"
                  style={{ display: "flex" }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.onAddNewEmployee();
                    }}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default EditEmployeeLoginCredentials;
