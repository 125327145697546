import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  InputLabel,
} from "@mui/material";

import moment from "moment";
import Notiflix from "notiflix";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PostApiCall from "../../../assets/API/PostApi";

export default function AddRFQMaster() {
  let history = useHistory();
  let location = useLocation();
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [rfqDate, setRfqDate] = useState("");
  const [rfqNumber, setRfqNumber] = useState("Will be generated automatically");
  // RFQ Terms
  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const onAddRfq = (action) => {
    PostApiCall.postRequest(
      {
        id: location.state == undefined ? 0 : location.state.id,
        vendorid: selectedVendor,
        rfqNumber: rfqNumber,
        rfqstatus: "New",
        rfqdate: rfqDate,
        rfqterms: name,
        userid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
      },
      "UpdateRFQMaster"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          history.push("/rfq-list");
          Notiflix.Notify.success("RFQ saved successfully");
        }
      })
    );
  };
  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const login = JSON.parse(localStorage.getItem("LoginDetail"))[0]

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetVendorMaster"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setVendorList(obj.data);
        }
      })
    );

    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetProjectsTerms"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setItems(obj.data);
          Notiflix.Loading.remove();
        }
      })
    );

    if (location != undefined && location.state.id != null) {
      PostApiCall.postRequest(
        {
          whereClause: `where fld_id=${location.state.id}`,
        },
        "GetRFQMasters"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            setSelectedVendor(obj.data[0].fld_vendor_id);
            setRfqNumber(obj.data[0].fld_rfq_no);
            setRfqDate(moment(obj.data[0].fld_rfq_date).format("YYYY-MM-DD"));
            setName(obj.data[0].fld_rfq_terms);
          }
        })
      );
    }
  }, []);
  const addItem = (e) => {
    e.preventDefault();
    setName("");
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: null,
        rfqterms: name,
        userid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
      },
      "UpdateRFQTerms"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Notify.success("RFQ Tasks Updated successfully");
          PostApiCall.postRequest(
            {
              whereClause: "",
            },
            "GetProjectsTerms"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                setItems(obj.data);
                Notiflix.Loading.remove();
              }
            })
          );
          Notiflix.Loading.remove();
        } else {
          Notiflix.Notify.failure("Data not saved.Please save the data again.");
          Notiflix.Loading.remove();
        }
      })
    );
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <>
      <div>
        <div className="form-head ">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Add RFQ</h4>
          </div>
        </div>
        <div className="card-vahani p-0 pb-4 ">
          <p className="personal-info left-line-card px-lg-4 px-3 py-4">
            RFQ Details
          </p>
          <div class="toast-body">
            <div class="row">
              <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row ps-2 pe-3">
                      <div className="col-md-4 col-12 mb-lg-4 mobile-personal">
                        <Box>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Select Vendor
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // required={true}
                              disabled={login.fld_type == "Accountant"}
                              label="Select Vendor"
                              value={selectedVendor}
                              onChange={(e) => {
                                setSelectedVendor(e.target.value);
                              }}
                            >
                              <MenuItem selected>Select Vendor</MenuItem>
                              {vendorList.map((record) => {
                                return (
                                  <MenuItem
                                    key={record.fld_name}
                                    value={record.fld_id}
                                  >
                                    {record.fld_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                      <div className="col-md-4 col-12 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          className="text-field"
                          type="text"
                          required={false}
                          label="RFQ No."
                          disabled={true}
                          value={rfqNumber}
                        />
                      </div>

                      <div className="col-md-4 col-12 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          className="text-field"
                          type="date"
                          value={rfqDate}
                          onChange={(e) => {
                            setRfqDate(e.target.value);
                          }}
                          // required={true}
                          disabled={login.fld_type == "Accountant"}
                          label="RFQ Date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>

                      <div className="niceeditors">
                        {/* <Select
                          mode="tags"
                          style={{
                            width: "100%",
                          }}
                          placeholder="RFQ Terms"
                          onChange={handleChangeinputdrop}
                          options={options}
                        /> */}{" "}
                        <TextField
                          fullWidth
                          type="text"
                          label=" Payment Terms"
                          // required={true}
                          disabled={login.fld_type == "Accountant"}
                          multiline
                          value={name}
                          rows={7}
                          className="text-field capitalonly"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>


          <div
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-toggle="toast"
          >

            {login.fld_type != "Accountant" &&
              <div
                className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-3"
                style={{ float: "right" }}
              >
                <div className="d-flex justify-content-end">
                  <Button
                    variant="contained"
                    onClick={() => {
                      onAddRfq();
                      history.push("/rfq-list");
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
