import React, { Component } from "react";

import Notiflix from "notiflix";
import GetApiCall from "../../../../assets/API/GetApi";
import PostApiCall from "../../../../assets/API/PostApi";
import moment from "moment";
import { TextField } from "@mui/material";
import logo from "../../../../assets/Image/vahanilogo2.png";

class ViewSalarySlipEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PageTitle: "1",
      Page1: "Pending",
      Question: "",
      Answer: "",
      Data: [],
      Details: [],
      QuestionId: [],
      InformationData: [],
      Name: "",
      Type: "",
      Notes: "",
      Days: "",
      StartDate: "",
      EndDate: "",
      SalarySlipId: null,
      EmpSalaryData: [],
      NameData: [],
      NumRegex: /^[0-9]*$/,
      LeaveId: null,
      employeeName: "",
      bankAcc: "",
      dateOfJoining: "",
      month: "",
      numberOfDays: "",
      totalPaidDays: "",
      grossMonthlyPay: "",
      taxDeducted: "",
      reimbursement: "",
      advances: "",
      totalEarnings: "",
      totalDeductions: "",
      netSalary: "",
      ViewSalarySlipData: [],
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "/assets/js/pages/form-wizard.init.js";
    script.async = true;
    document.body.appendChild(script);
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    if (this.props.location.state != undefined) {
      this.getSalarySlipdetails();
    }
  }

  getSalarySlipdetails = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_id=${this.props.location.state.salaryslipid}`,
      },
      "GetSalarySlipById"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.dots("Please wait...");
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            this.setState({
              ViewSalarySlipData: obj.data,
            });
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Data Doesn't Exit");
          }
        }
        Notiflix.Loading.remove();
      })
    );
  };

  render() {
    return (
      <>
        <div className="form-head mb-lg-2 mb-3">
          <div
            className="col-auto "
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>View Salary Slip</h4>
          </div>
        </div>
        <div className="card-vahani p-0 mt-2">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body p-0">
                  <div id="smartwizard-arrows">
                    <p className="personal-info m-0 left-line-card p-4">
                      View Salary Slip
                    </p>

                    <div className="p-3" style={{ minHeight: "0px" }}>
                      <div
                        id="sw-arrows-step-1"
                        className="tab-pane step-content"
                        style={{
                          display:
                            this.state.PageTitle == "1" ? "block" : "none",
                        }}
                      >
                        <form
                          className="needs-validation"
                          novalidate
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <table style={{ width: "100%", maxWidth: "1300px" }}>
                            <tbody style={{ borderWidth: "1px" }}>
                              <tr style={{ borderWidth: "1px" }}>
                                <td
                                  colspan="2"
                                  style={{
                                    textAlign: "left",
                                    border: "none",
                                    padding: "0 1rem",
                                    // display: "flex",
                                    // flexWrap: "wrap",
                                    // justifyContent: "space-between",
                                    // width: "100%",
                                  }}
                                >
                                  <img
                                    src={logo}
                                    alt="logoinvoiceform"
                                    style={{
                                      width: "50%",
                                      // marginRight: "auto",
                                      // marginLeft: "auto",
                                      verticalAlign: "middle",
                                    }}
                                  />{" "}
                                </td>
                                <td
                                  colSpan={2}
                                  style={{
                                    textAlign: "right",
                                    border: "none",
                                    padding: "0 1rem",
                                    fontWeight: "bold",
                                    fontSize: "1.6rem",
                                  }}
                                >
                                  Vahani Scholarship Trust
                                </td>
                              </tr>
                              <tr>
                                <th
                                  colspan="4"
                                  style={{
                                    textAlign: "center",
                                    padding: "5px",
                                    borderWidth: "1px",
                                  }}
                                >
                                  HO- C- 20, First Floor, Defence Colony,
                                  <br /> New Delhi 110024. INDIA.
                                  <br />
                                  Phone: +91 99 99361969
                                </th>
                              </tr>
                              <tr>
                                <th
                                  colspan="4"
                                  style={{
                                    textAlign: "center",
                                    padding: "5px",
                                  }}
                                >
                                  Email: info@vahanischolarship.com | Website:
                                  www.vahanischolarship.com
                                </th>
                              </tr>
                              {this.state.ViewSalarySlipData.map((data, i) => {
                                if (i == 0) {
                                  return (
                                    <>
                                      {data.fld_emp_type == "Full Time" ? (
                                        <>
                                          <tr className="salary-slip-heading">
                                            <th
                                              colspan="4"
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                backgroundColor: "grey",
                                                color: "black",
                                              }}
                                            >
                                              PAY SLIP FOR THE MONTH OF{" "}
                                              {data.fld_month.toUpperCase()}{" "}
                                              {data.fld_year}
                                            </th>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Name
                                            </th>
                                            <td
                                              colspan="3"
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_employee_name}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Code
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_employee_id}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Date of Joining
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_date_of_joining}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Designation
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_designation}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Gross Salary
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_gross_salary}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Department
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_department}
                                            </td>

                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              No. of Days in Month
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_no_of_days_month}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Location
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_location}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              No. of Days Absent
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_no_of_days_absent}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              PAN
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_pan}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              No. of Working Days
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_no_of_working_days}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Account Holder Name
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_acc_holder_name}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Bank Account No.
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_bank_acc_no}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              IFSC Code
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_ifsc_code}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Branch Name
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_branch_name}
                                            </td>
                                          </tr>
                                          <tr
                                            style={{
                                              backgroundColor: "grey",
                                              color: "black",
                                            }}
                                          >
                                            <th
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Earnings
                                            </th>
                                            <th
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Amount
                                            </th>
                                            <th
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Deductions
                                            </th>
                                            <th
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Amount
                                            </th>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              Basic Salary
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_basic_salary}
                                            </td>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              Leave Deductions
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_leave_deductions}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              HRA
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_hra}
                                            </td>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              Advance Paid
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_advances}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              CCA
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_cca}
                                            </td>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              Tax Deductions
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_tax_deductions}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              Reimbursement
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_reimbursement == null
                                                ? 0
                                                : data.fld_reimbursement}
                                            </td>

                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              Other Deductions
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_other_deductions}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              Other Allowance
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_other_allowance}
                                            </td>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            ></th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            ></td>
                                          </tr>
                                        </>
                                      ) : data.fld_emp_type == "Part Time" ? (
                                        <>
                                          <tr className="salary-slip-heading">
                                            <th
                                              colspan="4"
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                backgroundColor: "grey",
                                                color: "black",
                                              }}
                                            >
                                              PAY SLIP FOR THE MONTH OF{" "}
                                              {data.fld_month.toUpperCase()}{" "}
                                              {data.fld_year}
                                            </th>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Name
                                            </th>
                                            <td
                                              colspan="3"
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_employee_name}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Code
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_employee_id}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Date of Joining
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_date_of_joining}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Designation
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_designation}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Gross Salary
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_gross_salary}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Department
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                              colspan="3"
                                            >
                                              {data.fld_department}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                              colspan="3"
                                            >
                                              Location
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_location}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                              colspan="3"
                                            >
                                              PAN
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_pan}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Account Holder Name
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_acc_holder_name}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Bank Account No.
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_bank_acc_no}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              IFSC Code
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_ifsc_code}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Branch Name
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_branch_name}
                                            </td>
                                          </tr>
                                          <tr
                                            style={{
                                              backgroundColor: "grey",
                                              color: "black",
                                            }}
                                          >
                                            <th
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Earnings
                                            </th>
                                            <th
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Amount
                                            </th>
                                            <th
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Deductions
                                            </th>
                                            <th
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Amount
                                            </th>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              Salary
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_basic_salary}
                                            </td>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              Leave Deductions
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_leave_deductions}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              Reimbursement
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_reimbursement == null
                                                ? 0
                                                : data.fld_reimbursement}
                                            </td>

                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              Other Deductions
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_other_deductions}
                                            </td>
                                          </tr>
                                        </>
                                      ) : data.fld_emp_type == "Contract" ? (
                                        <>
                                          <tr className="salary-slip-heading">
                                            <th
                                              colspan="4"
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                backgroundColor: "grey",
                                                color: "black",
                                              }}
                                            >
                                              PAY SLIP FOR THE MONTH OF{" "}
                                              {data.fld_month.toUpperCase()}{" "}
                                              {data.fld_year}
                                            </th>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Name
                                            </th>
                                            <td
                                              colspan="3"
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_employee_name}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Code
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_employee_id}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Date of Joining
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_date_of_joining}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Designation
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_designation}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Gross Salary
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_gross_salary}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Department
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_department}
                                            </td>

                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              No. of Days in Month
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_no_of_days_month}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Location
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_location}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              No. of Days Absent
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_no_of_days_absent}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              PAN
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_pan}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              No. of Working Days
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_no_of_working_days}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Account Holder Name
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_acc_holder_name}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Bank Account No.
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_bank_acc_no}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              IFSC Code
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_ifsc_code}
                                            </td>
                                            <th
                                              style={{
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Branch Name
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                width: "25%",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              {data.fld_branch_name}
                                            </td>
                                          </tr>
                                          <tr
                                            style={{
                                              backgroundColor: "grey",
                                              color: "black",
                                            }}
                                          >
                                            <th
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Earnings
                                            </th>
                                            <th
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Amount
                                            </th>
                                            <th
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Deductions
                                            </th>
                                            <th
                                              style={{
                                                textAlign: "center",
                                                padding: "5px",
                                                borderWidth: "1px",
                                                width: "25%",
                                              }}
                                            >
                                              Amount
                                            </th>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              Salary
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_basic_salary}
                                            </td>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              Tax Deductions
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_tax_deductions}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              Reimbursement
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_reimbursement == null
                                                ? 0
                                                : data.fld_reimbursement}
                                            </td>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              Advance Paid
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_advances}
                                            </td>
                                          </tr>
                                          <tr style={{ borderWidth: "1px" }}>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            ></th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            ></td>
                                            <th
                                              style={{
                                                borderWidth: "1px",
                                                padding: "5px",
                                                width: "25%",
                                              }}
                                            >
                                              Other Deductions
                                            </th>
                                            <td
                                              style={{
                                                borderWidth: "1px",
                                                textAlign: "right",
                                                width: "25%",
                                                paddingRight: "4px",
                                              }}
                                            >
                                              {data.fld_other_deductions}
                                            </td>
                                          </tr>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      <tr style={{ borderWidth: "1px" }}>
                                        <th
                                          style={{
                                            borderWidth: "1px",
                                            padding: "5px",
                                            width: "25%",
                                          }}
                                        >
                                          Total Earnings (A)
                                        </th>
                                        <td
                                          style={{
                                            borderWidth: "1px",
                                            textAlign: "right",
                                            width: "25%",
                                            paddingRight: "4px",
                                          }}
                                        >
                                          {data.fld_total_earnings}
                                        </td>
                                        <th
                                          style={{
                                            borderWidth: "1px",
                                            padding: "5px",
                                            width: "25%",
                                          }}
                                        >
                                          Total Deductions (B)
                                        </th>
                                        <td
                                          style={{
                                            borderWidth: "1px",
                                            textAlign: "right",
                                            width: "25%",
                                            paddingRight: "4px",
                                          }}
                                        >
                                          {data.fld_total_deductions}
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          backgroundColor: "grey",
                                          color: "black",
                                          borderWidth: "1px",
                                        }}
                                      >
                                        <th
                                          style={{
                                            borderWidth: "1px",
                                            textAlign: "right",
                                            padding: "5px",
                                            fontWeight: "bolder",
                                            fontSize: "1.2rem",
                                            borderRightColor: "black",
                                          }}
                                          colspan="3"
                                        >
                                          Net Pay (A-B)
                                        </th>
                                        <td
                                          style={{
                                            borderWidth: "1px",
                                            textAlign: "right",
                                            paddingRight: "4px",
                                          }}
                                        >
                                          {data.fld_net_pay}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              })}

                              <tr>
                                <td style={{ paddingLeft: "5px" }} colspan="4">
                                  This is a computer generated salary slip.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ViewSalarySlipEmployee;
