import { Button, TextField } from "@mui/material";
import Popover from "@mui/material/Popover";
import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { useEffect, useRef, useState } from "react";
import { defaultStyles, FileIcon } from "react-file-icon";
import FileViewer from "react-file-viewer";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import PostApiCall from "../../../assets/API/PostApi";
import Box from "@mui/material/Box";

//  ********* Compare qoutation *********
// import Modal from "@mui/material/Modal";
import { Modal } from "antd";

export default function QuotationList() {
  const [quotationData, setQuotationData] = useState([]);
  const [uploadedQuotations, setUploadedQuotations] = useState([]);
  const [documentUrl, setDocumentUrl] = useState("");
  const [documentName, setDocumentName] = useState("");
  // Compare quotation
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const hiddenFileInput = useRef(null);
  const [approvedQuotationId, setApprovedQuotationId] = useState("");
  const [approvalComments, setApprovalComments] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popOverData, setPopOverData] = useState([]);
  const [PdfPreview, setPdfPreview] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    setOpen(true);
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: ` where fld_id in (${checkedItems.toString()})`,
      },
      "GetQuotations"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setUploadedQuotations(obj.data);
          Notiflix.Loading.remove();
        }
      })
    );
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckedItems([...checkedItems, value]);
    } else {
      const updatedItems = checkedItems.filter((item) => item !== value);
      setCheckedItems(updatedItems);
    }
  };

  const approveQuotation = (event) => {
    PostApiCall.postRequest(
      {
        id: approvedQuotationId,
        comments: approvalComments,
        selectedQuotations: checkedItems,
        userid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
      },
      "ApproveQuotations"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setOpen(false);
          getQuotation();
          Notiflix.Notify.success("Quotation Approved");
        }
      })
    );
  };
  function getExtension(filename) {
    return filename.split(".").pop();
  }
  // Filter Data
  const [RFQData, setRFQData] = useState([]);
  const [dateData, setdateData] = React.useState({ from: "", to: "" });
  const [ConditionType, setConditionType] = useState("New");
  const [SortData, setSortData] = React.useState({
    type: "desc",
    value: "fld_id",
  });
  const login = JSON.parse(localStorage.getItem("LoginDetail"))[0]

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    getQuotation();
  }, []);

  const getQuotation = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: "where fld_status = 'New'",
      },
      "GetQuotations"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setQuotationData(obj.data);
          Notiflix.Loading.remove();
        }
      })
    );
  };

  const QuotationListData = (condition) => {
    PostApiCall.postRequest(
      {
        whereClause:
          dateData.from == "" && dateData.to == ""
            ? `order by ${SortData.value} ${SortData.type}`
            : `where fld_rfq_status='${condition}' ${dateData.from != "" && dateData.to != ""
              ? `and fld_created_on between '${dateData.from}' and '${dateData.to}' `
              : ""
            } order by ${SortData.value} ${SortData.type}`,
      },
      "GetQuotations"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setQuotationData(obj.data);
        }
      })
    );
  };

  function showInfo(id) {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_rfq_id = ${id}`,
      },
      "GetRFQTasks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setPopOverData(obj.data);
        }
      })
    );
  }

  const filteredData = [
    {
      label: "S.No.",
      field: "serial",
      sort: "disabled",
      width: 150,
    },
    {
      label: "RFQ Number",
      field: "rfqnumber",
      sort: "disabled",
      width: 150,
    },
    {
      label: "Vendor Name",
      field: "vendorname",
      sort: "disabled",
      width: 400,
    },
    {
      label: "Status",
      field: "status",
      sort: "disabled",
      width: 150,
    },
    {
      label: "Created On",
      field: "createdon",
      sort: "disabled",
      width: 150,
    },
    {
      label: "Creater",
      field: "createdby",
      sort: "asc",
      width: 150,
    },
    {
      label: "Updater",
      field: "UpdatedBy",
      sort: "asc",
      width: 150,
    },
    {
      label: (
        <div class="form-check">
          <Button variant="contained" onClick={(e) => handleClick()}>
            Compare
          </Button>
        </div>
      ),
      field: "action",
      sort: "disabled",
      width: 150,
    },
    {
      label: "View Document",
      field: "view",
      width: 150,
      sort: "asc",
    },
    {
      label: "Edit",
      field: "edit",
      width: 150,
      sort: "asc",
    },
  ]

  const data = {
    columns: filteredData,
    rows: quotationData.map((data, i) => {
      return {
        serial: i + 1,
        rfqnumber: (
          <span
            onMouseOver={(e) => {
              showInfo(data.fld_rfq_id);
              handlePopoverOpen(e);
            }}
            onMouseLeave={handlePopoverClose}
          >
            {data.fld_rfq_no}
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={openPopOver}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <table>
                <tr>
                  <th>Project Name</th>
                </tr>
                {popOverData.map((data1) => {
                  return (
                    <tr>
                      <td>{data1.project_name}</td>
                    </tr>
                  );
                })}
              </table>
            </Popover>
          </span>
        ),
        vendorname: data.vendor_name,
        createdby: data.created_by,
        UpdatedBy: data.updated_by,
        createdon: moment(data.fld_created_on).format("DD-MM-YYYY"),
        status: data.fld_status,
        action: (
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value={data.fld_id}
              id="flexCheckDefault"
              onChange={handleCheckboxChange}
            />
          </div>
        ),
        edit: (
          <div className="d-flex">
            <Link
              className="m-auto"
              to={{
                pathname: `/add-quotation`,
                state: {
                  id: data.fld_id,
                },
              }}
            >
              <FaEdit className="edit-icon edit-icon-font-size" />
            </Link>
          </div>
        ),
        view: (
          <div className="d-flex">
            <FaEdit
              className="edit-icon edit-icon-font-size"
              onClick={function (event) {
                data.fld_filename.split(".")[1] != "pdf"
                  ? setOpenPreview(true)
                  : setPdfPreview(true);
                setDocumentName(data.fld_filename);
                setDocumentUrl(data.fld_quotation_doc);
              }}
            />
          </div>
        ),
      };
    }),
  };

  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Quotation List </h4>
        </div>
      </div>
      <div className="card-vahani p-0 mt-2">
        <div className="personal-info left-line-card row m-0 align-items-lg-center justify-content-between px-2 py-4 mb-2 w-100">
          <div className="col-4 col-md-10 mb-2 mb-md-0">Quotation</div>
          {login.fld_type != "Accountant" &&
            <Link
              to="/add-quotation"
              className="col-8 col-md-2 mb-sm-0 text-white text-end"
            >
              <Button variant="contained">Add New Quotation</Button>
            </Link>
          }
        </div>
        <div className="personal-info m-0 ps-md-1 pe-md-3 pb-0 d-lg-flex align-items-center justify-content-between">
          <div className="col-xxl-6 col-12 mt-3">
            <div className="row px-0">
              <TextField
                type="date"
                label="From Date"
                id="outlined-size-small"
                size="small"
                value={dateData.from}
                onChange={(e) =>
                  setdateData({ ...dateData, from: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className="mb-3 mb-md-0 px-2 col-md-4"
              />
              <TextField
                type="date"
                label="To Date"
                id="outlined-size-small"
                size="small"
                value={dateData.to}
                onChange={(e) =>
                  setdateData({ ...dateData, to: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className="mb-3 mb-md-0 px-2 col-md-4"
              />
              <div className="px-2 col-md-2">
                <Button
                  className="sort-filter-size w-100"
                  variant="contained"
                  onClick={() => {
                    if (dateData.from != "") {
                      if (dateData.to != "") {
                        QuotationListData(ConditionType);
                      } else {
                        Notiflix.Notify.failure("Enter To date");
                      }
                    } else {
                      Notiflix.Notify.failure("Enter From date");
                    }
                  }}
                >
                  Filter
                </Button>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 px-lg-0 col-12 mt-3 px-0">
            <div className="row m-0 px-0 align-items-lg-start justify-content-lg-end">
              <div className="col-sm-3 mb-sm-0 mb-3 col-12 pe-lg-0">
                <select
                  class="form-select form-select-sm sort-filter-size"
                  aria-label=".form-select-sm example"
                  onChange={(e) =>
                    setSortData({ ...SortData, value: e.target.value })
                  }
                >
                  <option value="fld_id">Sort By</option>
                  <option value="trim(fld_rfq_no)">RFQ Number</option>
                  <option value="cast(fld_created_on as date)">
                    Raised On
                  </option>
                  <option value="trim(fld_rfq_status)">Status</option>
                </select>
              </div>
              <div className="col-sm-3 col-12 mb-sm-0 mb-3 ">
                <select
                  class="form-select form-select-sm sort-filter-size"
                  aria-label=".form-select-sm example"
                  onChange={(e) =>
                    setSortData({ ...SortData, type: e.target.value })
                  }
                >
                  <option value="desc">Order</option>
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending </option>
                </select>
              </div>
              <div className="col-sm-2 col-12 mb-sm-0 mb-3 px-lg-0">
                <Button
                  className="w-100"
                  variant="contained"
                  onClick={() => {
                    QuotationListData(ConditionType);
                  }}
                >
                  Sort
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 purchase-order-table overflow-x-auto">
          {quotationData.length > 0 ? (
            <>
              <MDBDataTable
                responsive
                hover
                disableRetreatAfterSorting={true}
                data={data}
                entriesOptions={[10, 25, 50, 100]}
              >
                <MDBTableHead columns={data.columns} />
                <MDBTableBody rows={data.rows} />
              </MDBDataTable>
            </>
          ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )}

          {/* ******** Compare button Quotation view ********** */}

          <Modal
            centered
            open={open}
            onOk={() => approveQuotation(false)}
            onCancel={() => setOpen(false)}
            width={1000}
            okText="Approve Quotation"
          >
            <h4>Compare and Approve Quotation</h4>

            <div className="row">
              {uploadedQuotations.map((data) => {
                return (
                  <div className="col-12 col-md-4">
                    <div className="mx-2 my-3">
                      <div class="card me-3 quotaion-doc-slider position-relative">
                        <div class="card-body p-0">
                          <div className="w-75 h-75  m-auto d-flex p-0 quotation-img-preview">
                            <a
                              role="button"
                              tabindex="0"
                              onClick={(e) => {
                                setDocumentUrl(data.fld_quotation_doc);
                                setDocumentName(data.fld_filename);
                                setOpenPreview(true);
                              }}
                            >
                              {" "}
                              <FileIcon
                                extension={getExtension(data.fld_filename)}
                                {...defaultStyles[
                                getExtension(data.fld_filename)
                                ]}
                              />
                            </a>

                            {/* <FileViewer id={data.fld_id}
                                fileType={getExtension(data.fld_quotation_doc)}
                                filePath={data.fld_quotation_doc} /> */}
                          </div>
                          <p className="mt-3 text-center px-1">
                            <b>{data.fld_rfq_no}</b>
                            <br />
                            {data.fld_filename}
                          </p>
                        </div>

                        {/*  Delete document */}
                        <div className="select-quotation-btn position-absolute top-0 left-0">
                          <div class="form-check">
                            <input
                              class="form-check-input fs-4"
                              type="radio"
                              value=""
                              id="flexCheckDefault"
                              name="selectedquotation"
                              onClick={(e) => {
                                setApprovedQuotationId(data.fld_id);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* **** Reason for Selected ****** */}
                    <div className="col-lg-12 mb-lg-4 mobile-personal">
                      <TextField
                        fullWidth
                        type="text"
                        label="Approval Comments"
                        required={true}
                        multiline
                        rows={2}
                        className="text-field capitalonly"
                        onChange={(e) => {
                          setApprovalComments(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </Modal>

          {/* ******** Preview Doc********** */}

          <Modal
            centered
            open={openPreview}
            onOk={() => setOpenPreview(false)}
            onCancel={() => setOpenPreview(false)}
            width={500}
          >
            <h4>Preview Document</h4>

            <div className="row">
              <div className="col-12 col-md-12">
                <div className="mx-2 my-3">
                  <div class="card me-3 quotaion-doc-slider position-relative">
                    <div class="card-body p-0">
                      <div className="col-12 col-md-12 m-auto d-flex p-0 quotation-img-preview">
                        <FileViewer
                          fileType={getExtension(documentName)}
                          filePath={documentUrl}
                          className="file-viewer-style"
                        />
                      </div>
                      <p className="mt-3 text-center px-1">
                        {data.fld_filename}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            open={PdfPreview}
            onOk={() => setPdfPreview(false)}
            onCancel={() => setPdfPreview(false)}
            className="quotation-pdf-preview-modal"
            width={1000}
            centered
          >
            <Box>
              <iframe src={documentUrl} />
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
}
