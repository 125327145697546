import React, { Component, useState } from "react";
import { Edit3 } from "react-feather";
import Notiflix from "notiflix";
import PostApiCall from "../../../src/assets/API/PostApi";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import moment from "moment";
import { Edit } from "react-feather";
import { Link } from "react-router-dom";
import GetApiCall from "../../../src/assets/API/GetApi";
import { Button, Menu, MenuItem, Avatar, TextField } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Eye } from "react-feather";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import "./DiscussionForum.css";

export default function DiscussionForum() {
  const [SearchData, setSearchData] = React.useState({
    question: "",
    questionid: "",
  });
  const [list, setlist] = React.useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [searchInput, setSearchinput] = useState("")
  const [filteredData, setFilteredData] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [questionid, setQuestionId] = useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState({
    right: false,
  });

  React.useEffect(() => {
    Notiflix.Loading.init({ svgColor: "#060a4a" });
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetQuestions").then((results) =>
      results.json().then((obj) => {
        var objdata = obj.data;
        setlist(objdata.reverse());
        setFilteredData(objdata)
        Notiflix.Loading.remove();
      })
    );
  }, []);

  function GetFilterQuestion(data) {
    console.log(data);
    if (data == "") {
      Notiflix.Loading.dots();
      GetApiCall.getRequest("GetQuestions").then((results) =>
        results.json().then((obj) => {
          var objdata = obj.data;
          setlist(objdata.reverse());
          setFilteredData(objdata.reverse())
          Notiflix.Loading.remove();
        })
      );
    } else {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          whereClause: data,
        },
        "GetFilterQuestions"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            setlist(obj.data);
            setFilteredData(obj.data)
            Notiflix.Loading.remove();
          }
          Notiflix.Loading.remove();
        })
      );
    }
  }

  function getAnswer(questionid) {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_question_id=${questionid}`,
      },
      "GetAnswer"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            setAnswerList(obj.data);
            Notiflix.Loading.remove();
          } else {
            setAnswerList([]);
          }
        }
      })
    );
  }

  const toggleDrawer = (anchor, open, questionid, question) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open == true) {
      getAnswer(questionid);
      setQuestionId(questionid);
      setCurrentQuestion(question);
    }
    setDrawerOpen({ ...drawerOpen, [anchor]: open });
  };

  function onAddNewAnswer() {
    if (answer != "") {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          id: null,
          questionid: questionid,
          answer: answer,
          scholarid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
          respondentprofilepic: JSON.parse(
            localStorage.getItem("LoginDetail")
          )[0].profile_pic,
          createdon: new Date(),
          updatedon: new Date(),
        },
        "AddAnswer"
      ).then((resultcategory) =>
        resultcategory.json().then((obj) => {
          if (resultcategory.status == 200 || resultcategory.status == 201) {
            Notiflix.Notify.success("Answer Successfully added.");
            setAnswer("");
            getAnswer(questionid);
            Notiflix.Loading.remove();
            //window.history.go(-1);
          } else {
            Notiflix.Notify.failure(obj.data);
            Notiflix.Loading.remove();
          }
        })
      );
    } else {
      Notiflix.Notify.failure("Please Enter the answer!");
    }
  }
  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Discussion Forum </h4>
        </div>
      </div>
      <div className="card-vahani p-0 mt-2">
        <p className="personal-info m-0 left-line-card p-4 d-flex align-items-center justify-content-between">
          Discussion Forum{" "}
          <div className="col-md-2 ps-0 pe-0">
            <Button variant="contained" className="w-100">
              <Link to="/add-question" className="text-white">
                Ask Question
              </Link>
            </Button>
          </div>
        </p>
        <div className="row m-0 justify-content-between py-4  border-top border-bottom border-secondary  position-relative">
          <div className="col-md-8 mb-3">
            <input
              type="text"
              value={SearchData.empid}
              onChange={(e) =>
                setSearchData({ ...SearchData, question: e.target.value })
              }
              class="form-control"
              placeholder="Enter Question"
            />
          </div>
          <div className="col-lg-2 col-lg-2 mb-lg-0 mb-2">
            <Button
              variant="contained"
              className="w-100"
              onClick={() => {
                let whereClause;
                if (SearchData.question != "") {
                  whereClause =
                    "where fld_question = '" + SearchData.question + "'";
                }
                GetFilterQuestion(whereClause);
              }}
            >
              Search
            </Button>
          </div>
          <div className="col-lg-2 col-lg-2 mb-lg-0 mb-2">
            <Button
              variant="contained"
              className="w-100"
              onClick={() => {
                setSearchData({ ...SearchData, question: "" });
                GetFilterQuestion("");
              }}
            >
              Refresh
            </Button>
          </div>
          {list.length &&
          <div className="col-sm-auto mb-sm-0 mb-2 col-12 p-0 mdb-replace-custom-discussion-searchbar searchbar-custom">
            <input
              className="form-control form-control-sm ml-0 common-search-bar"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => {
                setSearchinput(e.target.value);
              }}
              value={searchInput}
            />
            {/* Search box end */}
          </div> }
        </div>
        <div className="px-4 approval-search-mdbtable-hide">

          {list.length > 0 ? (
            <MDBDataTable
              disableRetreatAfterSorting={true}
              responsive
              hover
              striped
              data={{
                columns: [
                  {
                    label: "Questioner",
                    field: "questioner",
                    sort: "disabled",
                    width: 400,
                  },
                  {
                    label: "Question",
                    field: "question",
                    sort: "disabled",
                    width: 400,
                  },
                  {
                    label: "Raised On",
                    field: "raisedon",
                    sort: "disabled",
                    width: 150,
                  },
                  {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 150,
                  },
                ],
                rows: filteredData
                  .filter((data) => {
                    if (searchInput == "") {
                      return data;
                    }
                    if (
                      // searchInput.toString() !== "" &&
                      data.fld_question
                        .toString()
                        .toLowerCase()
                        .includes(searchInput.toString().toLowerCase()) ||
                      `${data.fld_createdon != null
                        ? new Date(data.fld_createdon).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric"
                        }).toString().toLowerCase()
                        : null
                        }`
                        .includes(searchInput.toString().toLowerCase())

                      // searchInput !== "" &&
                      // moment(data.fld_date)
                      //   .format("DD-MM-YYYY")
                      //   .toLowerCase()
                      //   .includes(searchInput.toLowerCase())
                    ) {
                      return data;
                    }
                  })
                  .map((data, i) => {
                    return {
                      questioner: (
                        <Avatar
                          sx={{ width: 40, height: 40 }}
                          alt="questioner_img"
                          src={data.fld_questioner_profile_pic}
                        />
                      ),
                      question: data.fld_question,
                      raisedon: moment(data.fld_createdon).format("DD/MM/YYYY"),
                      action: (
                        <Eye
                          className="text-primary action-pointer"
                          onClick={toggleDrawer(
                            "right",
                            true,
                            data.fld_id,
                            data.fld_question
                          )}
                        />
                      ),
                    };
                  }),
              }}
              entriesOptions={[10, 25, 50, 100]}
            />
          ) : (
            <p className="m-3">No Data Found</p>
          )}
        </div>
      </div>
      <SwipeableDrawer
        anchor={"right"}
        open={drawerOpen["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        <Box className="h-100 " sx={{ width: 850 }} role="presentation">
          <div className="discussion-form-main">
            <div className="discussion-box">
              <div className="qus-main">
                <div className="qus-box">
                  <span>Q.</span>
                  <p>{currentQuestion}</p>
                </div>
              </div>
              <span className="fw-bold mb-2 ps-2 d-block">Ans.</span>
              <div className="ans-main">
                {answerList.length != 0 ? (
                  answerList.map((answer) => {
                    return (
                      <div className="ans-box">
                        <div className="d-flex align-items-start">
                          <img
                            src={answer.fld_respondent_profile_pic}
                            className="img-fluid"
                          />
                          <p>{answer.fld_answer}</p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>No answer's</>
                )}
              </div>
            </div>
            <div className="discussion-btn-box">
              <div className="discussion-btn">
                <TextField
                  fullWidth
                  type="text"
                  label="Write Your Answer Here"
                  required={true}
                  className="text-field capitalonly"
                  InputProps={{ inputProps: { min: 1 } }}
                  value={answer}
                  onChange={(e) => {
                    setAnswer(e.target.value);
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    onAddNewAnswer();
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </SwipeableDrawer>
    </>
  );
}
