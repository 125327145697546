import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useContext, useState } from "react";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import logo from "../../assets/Image/Vahanilogo.png";
import logo2 from "../../assets/Image/vahanilogo2.png";
import "./Sidebar.css";
// import Avatar from "@mui/material/Avatar";
// import Header from "../../Header/Header";
// import Mainsection from "../Mainsection/Mainsection";
import ApprovalManagementIcon from "../../assets/icons/approval_management.svg";
import DiscussionIcon from "../../assets/icons/discussion-icon.svg";
import ExpenseMasterIcon from "../../assets/icons/expense_master.svg";
import DashboardIcon from "../../assets/icons/Home.svg";
import NotificationManagementIcon from "../../assets/icons/notification_management.svg";
import ReportMasterIcon from "../../assets/icons/report_master.svg";
import ScholarInfoIcon from "../../assets/icons/scholar_info.svg";
import StudentsInfoIcon from "../../assets/icons/students_info.svg";
import TicketManagementIcon from "../../assets/icons/ticket_management.svg";
import store from "../../store";

const AdminSidebar = () => {
  const { drawerWidth, setdrawerWidth, Showdraw, setShowdraw } =
    useContext(store);
  const [open, setOpen] = useState(true);
  const [subcat1, setSubcat1] = useState(false);
  const [subcat, setSubcat] = useState(false);
  const [subcat2, setSubcat2] = useState(false);
  const [topc, settopc] = useState("slidertop2");
  // const [openmobile, setOpenmobile] = useState(true);
  const mobiledrawer = () => {
    setShowdraw(false);
  };
  const handleDrawerOpen = () => {
    settopc("topcno");
    setOpen(false);
    setdrawerWidth(70);
  };
  const handleDrawerclose = () => {
    settopc("slidertop2");
    setOpen(true);
    setdrawerWidth(290);
  };
  const showsub1 = () => {
    setSubcat1(!subcat1);
  };
  const showsub = () => {
    setSubcat(!subcat);
  };
  const showsub2 = () => {
    setSubcat2(!subcat2);
  };
  const NavList = () => {
    return (
      <List>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/dashboard"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <AiFillHome
                                    style={{ fontSize: "18px" }}
                                    className="active-sub"
                                /> */}
                <img
                  src={DashboardIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Dashboard"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/student-list"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <HiUser
                                    style={{ fontSize: "18px" }}
                                    className="active-sub"
                                /> */}
                <img
                  src={StudentsInfoIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Students Information"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/scholar-list"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <WorkspacePremiumIcon
                                    style={{ fontSize: "18px" }}
                                    className="active-sub"
                                /> */}
                <img
                  src={ScholarInfoIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Scholar Information"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/approval-list"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <VerifiedIcon
                                    style={{ fontSize: "18px" }}
                                    className="active-sub"
                                /> */}
                <img
                  src={ApprovalManagementIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Approval Management"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/payments"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <VerifiedIcon
                                    style={{ fontSize: "18px" }}
                                    className="active-sub"
                                /> */}
                <img
                  src={ApprovalManagementIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Payments"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/notification-list"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <NotificationsIcon
                                    style={{ fontSize: "18px" }}
                                    className="active-sub"
                                /> */}
                <img
                  src={NotificationManagementIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Notification Management"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/ticket-list"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <LocalPostOfficeIcon
                                    style={{ fontSize: "18px" }}
                                    className="active-sub"
                                /> */}
                <img
                  src={TicketManagementIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Ticket Management"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>

        <NavLink
          onClick={mobiledrawer}
          exact
          to="/report-list"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <AssessmentIcon
                                    style={{ fontSize: "18px" }}
                                    className="active-sub"
                                /> */}
                <img
                  src={ReportMasterIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Report Master"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/expense-master"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <DesktopMacIcon
                                    style={{ fontSize: "18px" }}
                                    className="active-sub"
                                /> */}
                <img
                  src={ExpenseMasterIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Expense Master"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/discussion-forum"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={DiscussionIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Discussion Forum"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>

        {/* up down */}
      </List>
    );
  };
  return (
    <>
      <div className="drawer-laptop">
        <Drawer
          className="drawer-d smallImagesScroll"
          style={{ width: "drawerWidth", transition: " width 5s  all" }}
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <div className="swipe-col-dw">
            <div className="swipe-left-dw">
              <div onClick={handleDrawerclose}>
                {open ? (
                  <img
                    src={logo2}
                    alt="logo"
                    style={{
                      width: "100px",
                      // height: "40px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <img
                    src={logo}
                    alt="logo"
                    style={{ width: "40px", height: "40px", cursor: "pointer" }}
                  />
                )}
              </div>
              {open ? (
                <div onClick={handleDrawerOpen}>
                  <BsChevronDoubleLeft className="arrow-0pen" />
                </div>
              ) : null}
            </div>
            <div></div>
          </div>
          <NavList />
        </Drawer>
      </div>
      {Showdraw ? (
        <div className="drawer-moblie">
          <Drawer
            className="drawer-d"
            style={{ width: "drawerWidth", transition: " width 1s  all" }}
            sx={{
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <div className="swipe-col-dw">
              <div className="swipe-left-dw">
                <div>
                  <img
                    src={logo2}
                    alt="logo"
                    style={{
                      width: "100px",
                      // height: "40px",
                      cursor: "pointer",
                    }}
                  />
                </div>
                {open ? (
                  <div onClick={mobiledrawer}>
                    {" "}
                    <BsChevronDoubleLeft className="arrow-0pen" />{" "}
                  </div>
                ) : null}
              </div>
            </div>
            <NavList />
          </Drawer>
        </div>
      ) : null}
    </>
  );
};
export default AdminSidebar;
