import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useContext, useState } from "react";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import logo from "../../assets/Image/Vahanilogo.png";
import logo2 from "../../assets/Image/vahanilogo2.png";
import "./Sidebar.css";
// import Avatar from "@mui/material/Avatar";
// import Header from "../../Header/Header";
// import Mainsection from "../Mainsection/Mainsection";
import EmployeeApplyLeave from "../../../src/assets/icons/employee-apply-leave.svg";
import EmployeeProfile from "../../../src/assets/icons/employee-profile.svg";
import AttendanceManagement from "../../assets/icons/attendance-management.svg";
import HolidayManagement from "../../assets/icons/holiday-management.svg";
import SalarySlipRecords from "../../assets/icons/salaryslip-records.svg";
// import Invoice from "../../assets/Image/vendor-image/invoice.png"
import store from "../../store";

const EmployeeSidebar = () => {
  const { drawerWidth, setdrawerWidth, Showdraw, setShowdraw } =
    useContext(store);
  const [open, setOpen] = useState(true);
  const [subcat1, setSubcat1] = useState(false);
  const [subcat, setSubcat] = useState(false);
  const [subcat2, setSubcat2] = useState(false);
  const [subcat4, setSubcat4] = useState(false);
  const [subcat5, setSubcat5] = useState(false);
  const [topc, settopc] = useState("slidertop2");
  // const [openmobile, setOpenmobile] = useState(true);
  const mobiledrawer = () => {
    setShowdraw(false);
  };
  const handleDrawerOpen = () => {
    settopc("topcno");
    setOpen(false);
    setdrawerWidth(70);
  };
  const handleDrawerclose = () => {
    settopc("slidertop2");
    setOpen(true);
    setdrawerWidth(290);
  };
  const showsub1 = () => {
    setSubcat1(!subcat1);
  };
  const showsub = () => {
    setSubcat(!subcat);
  };
  const showsub4 = () => {
    setSubcat4(!subcat4);
  };
  const showsub5 = () => {
    setSubcat5(!subcat5);
  };
  const showsub2 = () => {
    setSubcat2(!subcat2);
  };
  const NavList = () => {
    return (
      <List>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/employeeprofile"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={EmployeeProfile}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Profile"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>

        <NavLink
          onClick={mobiledrawer}
          exact
          to="/leaveapplications"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={EmployeeApplyLeave}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Leave Records"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>

        <NavLink
          onClick={mobiledrawer}
          exact
          to="/attendance"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={AttendanceManagement}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Attendance"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>

        <NavLink
          onClick={mobiledrawer}
          exact
          to="/holidayslist"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={HolidayManagement}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Holiday List"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>

        <NavLink
          onClick={mobiledrawer}
          exact
          to="/salarysliprecordsemployee"
          activeClassName="active"
          params={{ dataToSend: "55" }}>
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={SalarySlipRecords}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Salary Slip Records"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>

        {/* Invoice section */}
        {/* <NavLink
          onClick={mobiledrawer}
          exact
          to="/salaryinvoice"
          activeClassName="active"
          params={{ dataToSend: "55" }}>
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Invoice}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Employee Invoices"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink> */}


      </List>
    );
  };
  return (
    <>
      <div className="drawer-laptop">
        <Drawer
          className="drawer-d smallImagesScroll"
          style={{ width: "drawerWidth", transition: " width 5s  all" }}
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <div className="swipe-col-dw">
            <div className="swipe-left-dw">
              <div onClick={handleDrawerclose}>
                {open ? (
                  <img
                    src={logo2}
                    alt="logo"
                    style={{
                      width: "100px",
                      // height: "40px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <img
                    src={logo}
                    alt="logo"
                    style={{ width: "40px", height: "40px", cursor: "pointer" }}
                  />
                )}
              </div>
              {open ? (
                <div onClick={handleDrawerOpen}>
                  <BsChevronDoubleLeft className="arrow-0pen" />
                </div>
              ) : null}
            </div>
            <div></div>
          </div>
          <NavList />
        </Drawer>
      </div>
      {Showdraw ? (
        <div className="drawer-moblie">
          <Drawer
            className="drawer-d"
            style={{ width: "drawerWidth", transition: " width 1s  all" }}
            sx={{
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <div className="swipe-col-dw">
              <div className="swipe-left-dw">
                <div>
                  <img
                    src={logo2}
                    alt="logo"
                    style={{
                      width: "100px",
                      // height: "40px",
                      cursor: "pointer",
                    }}
                  />
                </div>
                {open ? (
                  <div onClick={mobiledrawer}>
                    {" "}
                    <BsChevronDoubleLeft className="arrow-0pen" />{" "}
                  </div>
                ) : null}
              </div>
            </div>
            <NavList />
          </Drawer>
        </div>
      ) : null}
    </>
  );
};
export default EmployeeSidebar;
