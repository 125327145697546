import { createContext, useState } from "react";
const store = createContext();
export function Provider({ children }) {
  const [drawerWidth, setdrawerWidth] = useState(290);
  const [Showdraw, setShowdraw] = useState(false);
  const [emit, setemit] = useState(false);
  const [expenseData, setexpenseData] = useState(null);
  const [userType, setuserType] = useState(null);
  const [ticketData, setticketData] = useState(null);
  const [accomodationData, setaccomodationData] = useState(null);
  const [studentData, setstudentData] = useState(null);
  const [NotificationData, setNotificationData] = useState([]);
  const [scolarNotificationResponse, setScolarNotificationResponse] =
    useState(false);

  const [vendorAccess, setVendorAccess] = useState(
    localStorage.getItem("vendoraccess") === null
      ? "false"
      : localStorage.getItem("vendoraccess")
  );

  const [vendorGlobalId, setVendorGlobalId] = useState(null);
  const [vendorGlobalName, setVendorGlobalName] = useState("");
  const [vendorIndustryType, setVendorIndustryType] = useState("");
  const [vendorData, setVendorData] = useState([]);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [default1, setDefault1] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [currCountry, setCurrCountry] = useState("");
  const [stateData, setStateData] = useState([]);
  const [currState, setCurrState] = useState("");
  const [cityData, setCityData] = useState([]);
  const [currCity, setCurrCity] = useState("");
  const [addressId, setAddressId] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [contactName, setContactName] = useState("");
  const [designation, setDesignation] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactMobile, setContactMobile] = useState("");
  const [addContactData, setAddContactData] = useState([]);
  const [bankId, setBankId] = useState(null);
  const [bankName, setBankName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [branch, setBranch] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifsccode, setIfsccode] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [defaultBank, setDefaultBank] = useState(false);
  const [disabledTab, setDisabledTab] = useState(true);
  const [scholarNotificationData, setScholarNotificationData] = useState([]);
  // let eventSource = new EventSource(`http://localhost:8020/API/expense`);

  // eventSource.addEventListener('connected', (e) =>

  // let eventSource = new EventSource(`http://localhost:8020/API/expense`);
  //
  // eventSource.addEventListener('connected', (e) =>
  return (
    <store.Provider
      value={{
        drawerWidth,
        setdrawerWidth,
        Showdraw,
        setShowdraw,
        expenseData,
        setexpenseData,
        ticketData,
        setticketData,
        accomodationData,
        setaccomodationData,
        studentData,
        setstudentData,
        NotificationData,
        setNotificationData,
        drawerWidth,
        setdrawerWidth,
        Showdraw,
        setShowdraw,
        expenseData,
        setexpenseData,
        ticketData,
        setticketData,
        accomodationData,
        setaccomodationData,
        vendorGlobalId,
        setVendorGlobalId,
        vendorGlobalName,
        setVendorGlobalName,
        vendorIndustryType,
        setVendorIndustryType,
        vendorData,
        setVendorData,
        addressId,
        setAddressId,
        address1,
        setAddress1,
        address2,
        setAddress2,
        address3,
        setAddress3,
        landmark,
        setLandmark,
        pincode,
        setPincode,
        default1,
        setDefault1,
        countryData,
        setCountryData,
        currCountry,
        setCurrCountry,
        stateData,
        setStateData,
        currState,
        setCurrState,
        cityData,
        setCityData,
        currCity,
        setCurrCity,
        contactId,
        setContactId,
        contactName,
        setContactName,
        designation,
        setDesignation,
        contactEmail,
        setContactEmail,
        contactMobile,
        setContactMobile,
        addContactData,
        setAddContactData,
        bankId,
        setBankId,
        bankName,
        setBankName,
        accountHolderName,
        setAccountHolderName,
        branch,
        setBranch,
        accountNumber,
        setAccountNumber,
        ifsccode,
        setIfsccode,
        swiftCode,
        setSwiftCode,
        defaultBank,
        setDefaultBank,
        disabledTab,
        setDisabledTab,
        vendorAccess,
        setVendorAccess,
        scholarNotificationData,
        setScholarNotificationData,
        scolarNotificationResponse,
        setScolarNotificationResponse
      }}
    >
      {children}
    </store.Provider>
  );
}
export default store;
