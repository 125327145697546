import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Notiflix from "notiflix";
import React, { useEffect, useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PostApiCall from "../../../assets/API/PostApi";

// import { Modal } from "react-bootstrap";
import { Button, TextField } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

export default function AddProjectOrderInvoice() {
  let history = useHistory();
  let location = useLocation();
  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const [poId, setPoId] = useState("");
  const hiddenFileInput = useRef(null);
  const hiddenFileInput1 = useRef(null);
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [proformaInvoice, setProformaInvoice] = useState({});
  const [invoiceUrl, setInvoiceUrl] = useState();
  const [proformaInvoiceUrl, setProformaInvoiceUrl] = useState();
  const [selectedBank, setSelectedBank] = useState(null);
  const [ImageApiUrl, setImageApiUrl] = useState(
    "https://admin.vahanischolarship.in/API/AddImage2"
  );
  const [invoice, setInvoice] = useState({});
  const [gstper, setGstPer] = useState(null);
  const [sgst, setSgst] = useState(0);
  const [igst, setIgst] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [tds, setTds] = useState();
  const [tdsAmount, setTdsAmount] = useState();
  const [amount, setAmount] = useState();
  const [payableamount, setPayableAmount] = useState();
  const [description, setDescription] = useState("");
  const [performaInvoicePath, setPerformaInvoicePath] = useState();
  const [invoicePath, setInvoicePath] = useState();
  const [approvalList, setapprovalList] = useState([]);
  const [rejectionComment, setRejectionComment] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("Not Raised");
  const [fieldReadOnly, setFieldReadOnly] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [ReceiptImage, setReceiptImage] = useState("/upload.jpg");
  const [ReciptFile, setReciptFile] = useState(null);
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentNo, setPaymentNo] = useState("");
  const [payBtnDisabled, setPayBtnDisabled] = useState(true);
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const [addBankData, setAddBankData] = useState([]);
  const [bankaccno, setBankAccNo] = useState("");
  const [bankaccholdername, setBankAccHolderName] = useState("");
  const [ifsccode, setIfsccode] = useState("");
  const [gstEligible, setGstEligible] = useState("No")



  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleChangeInvoice = (event) => {
    setInvoice(event.target.files[0]);
  };
  const handleChangeProformaInvoice = (event) => {
    setProformaInvoice(event.target.files[0]);
  };
  const handleGstStatus = (e) => {
    setGstEligible(e.target.value)
  }
  const uploadPIDoc = (invoiceNumber, random) => {
    let response;
    const fileUploaded = proformaInvoice;
    Notiflix.Loading.dots("Uploading document");
    const form = new FormData();
    form.append("file", fileUploaded);
    form.append("foldername", "Invoices");
    form.append(
      "filename",
      invoiceNumber + "-" + random + "-" + fileUploaded.name
    );
    setProformaInvoiceUrl(
      invoiceNumber + "-" + random + "-" + fileUploaded.name
    );
    response = fetch(ImageApiUrl, {
      method: "POST",
      body: form,
      mode: "cors",
    })
      .then((response) => response.json())
      .then(() => {
        Notiflix.Loading.remove();
      });
  };
  const uploadInvoiceDoc = (invoiceNumber, random) => {
    let response;
    const fileUploaded = invoice;
    Notiflix.Loading.dots("Uploading document");
    const form = new FormData();
    form.append("file", fileUploaded);
    form.append("foldername", "Invoices");
    form.append(
      "filename",
      invoiceNumber + "-" + random + "-" + fileUploaded.name
    );
    setInvoiceUrl(invoiceNumber + "-" + random + "-" + fileUploaded.name);
    response = fetch(ImageApiUrl, {
      method: "POST",
      body: form,
      mode: "cors",
    })
      .then((response) => response.json())
      .then(() => {
        Notiflix.Loading.remove();
      });
  };

  const uploadPaymentReceipt = () => {
    let response;
    const fileUploaded = ReciptFile;
    Notiflix.Loading.dots("Uploading document");
    const form = new FormData();
    form.append("file", fileUploaded);
    form.append("foldername", "VendorInformations");
    form.append("filename", fileUploaded.name);

    response = fetch(ImageApiUrl, {
      method: "POST",
      body: form,
      mode: "cors",
    })
      .then((response) => response.json())
      .then(() => {
        Notiflix.Loading.remove();
      });
  };
  const onAddInvoice = (status) => {
    let random = Math.floor(Math.random() * 1000000);
    let random1 = Math.floor(Math.random() * 1000000);

    if (location.state.id == null && location.state.poid != null) {
      if (
        parseInt(payableamount) <= location.state.remainingAmount &&
        location.state.remainingAmount > 0
      ) {
        PostApiCall.postRequest(
          {
            id:
              location.state == undefined || location.state == null
                ? 0
                : location.state.poid,
            poid: poId,
            invoiceno: invoiceNumber,
            proformainvoice:
              proformaInvoice.name != undefined
                ? "https://admin.vahanischolarship.in/Images/Invoices/" +
                invoiceNumber +
                "-" +
                random +
                "-" +
                proformaInvoice.name
                : performaInvoicePath,
            invoice:
              invoice.name != undefined
                ? "https://admin.vahanischolarship.in/Images/Invoices/" +
                invoiceNumber +
                "-" +
                random +
                "-" +
                invoice.name
                : invoicePath,
            description: description,
            status:
              status != "Partially Paid" &&
                status != "Fully Paid" &&
                location.state.poid != null
                ? "Not Paid"
                : status,
            amount: amount,
            gstper: gstEligible == "yes" ? gstper : null,
            sgst: sgst,
            cgst: cgst,
            igst: igst,
            tds: tds,
            tdsAmount: tdsAmount,
            paymentstatus:
              status != "Partially Paid" && status != "Fully Paid"
                ? "Not Paid"
                : status,
            payableamount: payableamount,
            userid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
          },
          "UpdatePurchaseInvoices"
        ).then((results) =>
          results.json().then((obj) => {
            if (results.status == 200 || results.status == 201) {
              if (proformaInvoice.name != undefined) {
                uploadPIDoc(invoiceNumber, random);
              }
              if (invoice.name != undefined) {
                uploadInvoiceDoc(invoiceNumber, random);
              }

              PostApiCall.postRequest(
                {
                  id: location.state.id,
                  invoiceid: obj.data[0].invoice_id,
                  amountpayable: amount,
                  amountpaid: payableamount,
                  paymentrecipt:
                    ReciptFile != null
                      ? "https://admin.vahanischolarship.in/Images/VendorInformations/" +
                      ReciptFile.name
                      : "",
                  amountremaining:
                    location.state.poid == null && location.state.id == null
                      ? parseInt(amount) - parseInt(payableamount)
                      : location.state.poid != null && location.state.id != null
                        ? location.state.remainingAmount
                        : location.state.remainingAmount -
                        parseInt(payableamount),
                  status:
                    status == "New"
                      ? "Pending"
                      : status == "Partially Paid" || status == "Fully Paid"
                        ? "Paid"
                        : status,
                },
                "Update_PurchasePaymentsHistory"
                // "GetProjectsTasks"
              ).then((results) =>
                results.json().then((obj2) => {
                  if (results.status == 200 || results.status == 201) {
                    if (ReciptFile != null) {
                      uploadPaymentReceipt();
                    }
                    PostApiCall.postRequest(
                      {
                        approvalid: null,
                        invoiceid: obj2.data[0].approval_id,
                        approverid: login.fld_id,
                        action:
                          status == "New"
                            ? "Pending"
                            : status == "Partially Paid" ||
                              status == "Fully Paid"
                              ? "Paid"
                              : status,
                        comments: rejectionComment,
                        actiondate: null,
                        post: login.fld_type,
                        pendingwith: login.fld_type,
                        sequence: 0,
                      },
                      "Update_InvoiceApprovalHistory"
                      // "GetProjectsTasks"
                    ).then((results) =>
                      results.json().then((obj2) => {
                        if (results.status == 200 || results.status == 201) {
                        }
                        Notiflix.Loading.remove();
                        window.history.go(-1);
                      })
                    );
                    // window.history.go(-1);
                  }
                  Notiflix.Loading.remove();
                })
              );
              //history.push("/purchase-Order-invoice-list");
              Notiflix.Notify.success("Request saved successfully");
              Notiflix.Loading.remove();
            }
          })
        );
      } else {
        Notiflix.Notify.failure(
          "Payable amount must not be greater than remaining amount!"
        );
      }
    } else {
      if (location.state.id != null && location.state.poid != null) {
        PostApiCall.postRequest(
          {
            id:
              location.state == undefined || location.state == null
                ? 0
                : location.state.poid,
            poid: poId,
            invoiceno: invoiceNumber,
            proformainvoice:
              proformaInvoice.name != undefined
                ? "https://admin.vahanischolarship.in/Images/Invoices/" +
                invoiceNumber +
                "-" +
                random +
                "-" +
                proformaInvoice.name
                : performaInvoicePath,
            invoice:
              invoice.name != undefined
                ? "https://admin.vahanischolarship.in/Images/Invoices/" +
                invoiceNumber +
                "-" +
                random +
                "-" +
                invoice.name
                : invoicePath,
            description: description,
            status:
              status != "Partially Paid" &&
                status != "Fully Paid" &&
                location.state.poid != null
                ? "Not Paid"
                : status,
            amount: amount,
            gstper: gstper,
            sgst: sgst,
            cgst: cgst,
            igst: igst,
            tds: tds,
            tdsAmount: tdsAmount,
            paymentstatus:
              status != "Partially Paid" && status != "Fully Paid"
                ? "Not Paid"
                : status,
            payableamount: payableamount,
            userid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
          },
          "UpdatePurchaseInvoices"
        ).then((results) =>
          results.json().then((obj) => {
            if (results.status == 200 || results.status == 201) {
              if (proformaInvoice.name != undefined) {
                uploadPIDoc(invoiceNumber, random);
              }
              if (invoice.name != undefined) {
                uploadInvoiceDoc(invoiceNumber, random);
              }

              PostApiCall.postRequest(
                {
                  id: location.state.id,
                  invoiceid: obj.data[0].invoice_id,
                  amountpayable: amount,
                  amountpaid: payableamount,
                  paymentrecipt:
                    ReciptFile != null
                      ? "https://admin.vahanischolarship.in/Images/VendorInformations/" +
                      ReciptFile.name
                      : "",
                  amountremaining:
                    location.state.poid == null && location.state.id == null
                      ? parseInt(amount) - parseInt(payableamount)
                      : location.state.poid != null &&
                        location.state.id != null &&
                        login.fld_type != "Approver" &&
                        invoiceStatus == "Pending"
                        ? location.state.remainingAmount - parseInt(payableamount)
                        : location.state.remainingAmount,
                  status:
                    status == "New"
                      ? "Pending"
                      : status == "Partially Paid" || status == "Fully Paid"
                        ? "Paid"
                        : status,
                },
                "Update_PurchasePaymentsHistory"
                // "GetProjectsTasks"
              ).then((results) =>
                results.json().then((obj2) => {
                  if (results.status == 200 || results.status == 201) {
                    if (ReciptFile != null) {
                      uploadPaymentReceipt();
                    }
                    PostApiCall.postRequest(
                      {
                        approvalid: null,
                        invoiceid: obj2.data[0].approval_id,
                        approverid: login.fld_id,
                        action:
                          status == "New"
                            ? "Pending"
                            : status == "Partially Paid" ||
                              status == "Fully Paid"
                              ? "Paid"
                              : status,
                        comments: rejectionComment,
                        actiondate: null,
                        post: login.fld_type,
                        pendingwith: login.fld_type,
                        sequence: 0,
                      },
                      "Update_InvoiceApprovalHistory"
                      // "GetProjectsTasks"
                    ).then((results) =>
                      results.json().then((obj2) => {
                        if (results.status == 200 || results.status == 201) {
                          if (results.status == 200 || results.status == 201) {
                            if (
                              status == "Partially Paid" ||
                              status == "Fully Paid"
                            ) {
                              PostApiCall.postRequest(
                                {
                                  id: location.state.id,
                                  paymentmode: paymentMode,
                                  paymentno: paymentNo,
                                  bankaccno: bankaccno,
                                  bankaccholdername: bankaccholdername,
                                  ifsccode: ifsccode,
                                },
                                "Update_Purchase_Order_Payment_Bank_Details"
                                // "GetProjectsTasks"
                              ).then((results) =>
                                results.json().then((obj2) => {
                                  if (
                                    results.status == 200 ||
                                    results.status == 201
                                  ) {
                                    Notiflix.Loading.remove();
                                    window.history.go(-1);
                                  } else {
                                    Notiflix.Loading.remove();
                                    Notiflix.Notify.failure("error occurred!");
                                  }
                                })
                              );
                            } else {
                              Notiflix.Loading.remove();
                              window.history.go(-1);
                            }
                          } else {
                            Notiflix.Loading.remove();
                            Notiflix.Notify.failure("error occurred!");
                          }
                        } else {
                          Notiflix.Loading.remove();
                          Notiflix.Notify.failure("error occurred!");
                        }
                      })
                    );
                    // window.history.go(-1);
                  }
                  Notiflix.Loading.remove();
                })
              );
              //history.push("/purchase-Order-invoice-list");
              Notiflix.Notify.success("Request saved successfully");
              Notiflix.Loading.remove();
            }
          })
        );
      } else {
        if (parseInt(payableamount) <= parseInt(amount)) {
          PostApiCall.postRequest(
            {
              id:
                location.state == undefined || location.state == null
                  ? 0
                  : location.state.poid,
              poid: poId,
              invoiceno: invoiceNumber,
              proformainvoice:
                proformaInvoice.name != undefined
                  ? "https://admin.vahanischolarship.in/Images/Invoices/" +
                  invoiceNumber +
                  "-" +
                  random +
                  "-" +
                  proformaInvoice.name
                  : performaInvoicePath,
              invoice:
                invoice.name != undefined
                  ? "https://admin.vahanischolarship.in/Images/Invoices/" +
                  invoiceNumber +
                  "-" +
                  random +
                  "-" +
                  invoice.name
                  : invoicePath,
              description: description,
              status:
                status != "Partially Paid" &&
                  status != "Fully Paid" &&
                  location.state.poid != null
                  ? "Not Paid"
                  : status,
              amount: amount,
              gstper: gstEligible == "Yes" ? gstper : null,
              sgst: gstEligible == "Yes" ? sgst : 0.0,
              cgst: gstEligible == "Yes" ? cgst : 0.0,
              igst: gstEligible == "Yes" ? igst : 0.0,
              tds: tds,
              tdsAmount: tdsAmount,
              paymentstatus:
                status != "Partially Paid" && status != "Fully Paid"
                  ? "Not Paid"
                  : status,
              payableamount: payableamount,
              userid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
            },
            "UpdatePurchaseInvoices"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                if (proformaInvoice.name != undefined) {
                  uploadPIDoc(invoiceNumber, random);
                }
                if (invoice.name != undefined) {
                  uploadInvoiceDoc(invoiceNumber, random);
                }

                PostApiCall.postRequest(
                  {
                    id: location.state.id,
                    invoiceid: obj.data[0].invoice_id,
                    amountpayable: amount,
                    amountpaid: payableamount,
                    amountremaining:
                      location.state.poid == null && location.state.id == null
                        ? parseInt(amount) - parseInt(payableamount)
                        : location.state.poid != null &&
                          location.state.id != null
                          ? location.state.remainingAmount
                          : location.state.remainingAmount -
                          parseInt(payableamount),
                    status:
                      status == "New"
                        ? "Pending"
                        : status == "Partially Paid" || status == "Fully Paid"
                          ? "Paid"
                          : status,
                  },
                  "Update_PurchasePaymentsHistory"
                  // "GetProjectsTasks"
                ).then((results) =>
                  results.json().then((obj2) => {
                    if (results.status == 200 || results.status == 201) {
                      PostApiCall.postRequest(
                        {
                          approvalid: null,
                          invoiceid: obj2.data[0].approval_id,
                          approverid: login.fld_id,
                          action:
                            status == "New"
                              ? "Pending"
                              : status == "Partially Paid" ||
                                status == "Fully Paid"
                                ? "Paid"
                                : status,
                          comments: rejectionComment,
                          actiondate: null,
                          post: login.fld_type,
                          pendingwith: login.fld_type,
                          sequence: 0,
                        },
                        "Update_InvoiceApprovalHistory"
                        // "GetProjectsTasks"
                      ).then((results) =>
                        results.json().then((obj2) => {
                          if (results.status == 200 || results.status == 201) {
                            if (
                              status == "Partially Paid" ||
                              status == "Fully Paid"
                            ) {
                              PostApiCall.postRequest(
                                {
                                  id: location.state.id,
                                  paymentmode: paymentMode,
                                  paymentno: paymentNo,
                                  bankaccno: bankaccno,
                                  bankaccholdername: bankaccholdername,
                                  ifsccode: ifsccode,
                                },
                                "Update_Purchase_Order_Payment_Bank_Details"
                                // "GetProjectsTasks"
                              ).then((results) =>
                                results.json().then((obj2) => {
                                  if (
                                    results.status == 200 ||
                                    results.status == 201
                                  ) {
                                    Notiflix.Loading.remove();
                                    window.history.go(-1);
                                  } else {
                                    Notiflix.Loading.remove();
                                    Notiflix.Notify.failure("error occurred!");
                                  }
                                })
                              );
                            } else {
                              Notiflix.Loading.remove();
                              window.history.go(-1);
                            }
                          } else {
                            Notiflix.Loading.remove();
                            Notiflix.Notify.failure("error occurred!");
                          }
                        })
                      );

                      // window.history.go(-1);
                    }
                    Notiflix.Loading.remove();
                  })
                );
                //history.push("/purchase-Order-invoice-list");
                Notiflix.Notify.success("Request saved successfully");
                Notiflix.Loading.remove();
              }
            })
          );
        } else {
          Notiflix.Notify.failure(
            "Payable amount must not be greater than invoice amount!"
          );
        }
      }
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (location.state != undefined && location.state.poid != null) {
      Notiflix.Loading.dots();
      if (location.state.id != null && location.state.id != undefined) {
        PostApiCall.postRequest(
          {
            whereClause: `where fld_id=${location.state.poid}`,
          },
          "GetPurchaseInvoices"
          // "GetProjectsTasks"
        ).then((results) =>
          results.json().then((obj) => {
            if (results.status == 200 || results.status == 201) {
              PostApiCall.postRequest(
                {
                  whereClause: `where fld_invoice_id=${location.state.id} order by fld_approval_id asc`,
                },
                "Get_VendorPaymentApprovalHistory"
              ).then((results) =>
                results.json().then((obj1) => {
                  if (results.status === 200 || results.status === 201) {
                    //
                    if (obj1.data.length > 0) {
                      setInvoiceStatus(
                        obj1.data[obj1.data.length - 1].fld_action
                      );
                      setapprovalList(obj1.data);
                    }
                    PostApiCall.postRequest(
                      {
                        whereClause: `where fld_id = ${obj.data[0].fld_po_id}`,
                      },
                      "GetPurchaseOrders"
                      // "GetProjectsTasks"
                    ).then((results) =>
                      results.json().then((obj4) => {
                        if (results.status == 200 || results.status == 201) {
                          if (
                            obj1.data[obj1.data.length - 1].fld_action ==
                            "Approved"
                          ) {
                            PostApiCall.postRequest(
                              {
                                wherecalue: obj4.data[0].fld_vendor_id,
                              },
                              "GetVendorBanks"
                            ).then((results) =>
                              results.json().then((obj) => {
                                if (
                                  results.status === 200 ||
                                  results.status === 201
                                ) {
                                  setAddBankData(obj.data);
                                  Notiflix.Loading.remove();
                                } else {
                                  Notiflix.Notify.failure("error occurred!");
                                  Notiflix.Loading.remove();
                                }
                              })
                            );
                          }
                        } else {
                          Notiflix.Loading.remove();
                          Notiflix.Notify.failure("Error Occurred!");
                        }
                      })
                    );
                  }
                })
              );
              setPoId(obj.data[0].fld_po_id);
              setInvoiceNumber(obj.data[0].fld_invoice_no);
              setAmount(obj.data[0].fld_amount);
              setGstPer(obj.data[0].fld_gst);
              setSgst(obj.data[0].fld_sgst);
              setCgst(obj.data[0].fld_cgst);
              setIgst(obj.data[0].fld_igst);
              setTds(obj.data[0].fld_tds);
              setTdsAmount(obj.data[0].fld_tds_amount);
              setPayableAmount(obj.data[0].fld_payable_amount);
              setPerformaInvoicePath(obj.data[0].fld_proforma_invoice);
              setInvoicePath(obj.data[0].fld_invoice);
              setDescription(obj.data[0].fld_description);
              setFieldReadOnly(true);
              Notiflix.Loading.remove();
            }
          })
        );
      } else {
        PostApiCall.postRequest(
          {
            whereClause: `where fld_id=${location.state.poid}`,
          },
          "GetPurchaseInvoices"
          // "GetProjectsTasks"
        ).then((results) =>
          results.json().then((obj) => {
            if (results.status == 200 || results.status == 201) {
              PostApiCall.postRequest(
                {
                  whereClause: `where fld_invoice_id=${location.state.id} order by fld_approval_id asc`,
                },
                "Get_VendorPaymentApprovalHistory"
              ).then((results) =>
                results.json().then((obj1) => {
                  if (results.status === 200 || results.status === 201) {
                    //
                    if (obj1.data.length > 0) {
                      setInvoiceStatus(
                        obj1.data[obj1.data.length - 1].fld_action
                      );
                      setapprovalList(obj1.data);
                    }
                    PostApiCall.postRequest(
                      {
                        whereClause: `where fld_id = ${obj.data[0].fld_po_id}`,
                      },
                      "GetPurchaseOrders"
                      // "GetProjectsTasks"
                    ).then((results) =>
                      results.json().then((obj4) => {
                        if (results.status == 200 || results.status == 201) {
                          if (
                            obj1.data[obj1.data.length - 1].fld_action ==
                            "Approved"
                          ) {
                            PostApiCall.postRequest(
                              {
                                wherecalue: obj4.data[0].fld_vendor_id,
                              },
                              "GetVendorBanks"
                            ).then((results) =>
                              results.json().then((obj) => {
                                if (
                                  results.status === 200 ||
                                  results.status === 201
                                ) {
                                  setAddBankData(obj.data);
                                  Notiflix.Loading.remove();
                                } else {
                                  Notiflix.Notify.failure("error occurred!");
                                  Notiflix.Loading.remove();
                                }
                              })
                            );
                          }
                        } else {
                          Notiflix.Loading.remove();
                          Notiflix.Notify.failure("Error Occurred!");
                        }
                      })
                    );
                  }
                })
              );
              setPoId(obj.data[0].fld_po_id);
              setInvoiceNumber(obj.data[0].fld_invoice_no);
              setAmount(obj.data[0].fld_amount);
              setGstPer(obj.data[0].fld_gst);
              setSgst(obj.data[0].fld_sgst);
              setCgst(obj.data[0].fld_cgst);
              setIgst(obj.data[0].fld_igst);
              setTds(obj.data[0].fld_tds);
              setTdsAmount(obj.data[0].fld_tds_amount);
              setPerformaInvoicePath(obj.data[0].fld_proforma_invoice);
              setInvoicePath(obj.data[0].fld_invoice);
              setFieldReadOnly(true);
              Notiflix.Loading.remove();
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure("Error Occurred!");
            }
          })
        );
      }
    }

    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetPurchaseOrders"
      // "GetProjectsTasks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setPurchaseOrderData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred!");
        }
      })
    );
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div>
        <div className="form-head ">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Add PO Invoice</h4>
          </div>
        </div>
        <div className="card-vahani p-0 pb-4 ">
          <p className="personal-info left-line-card px-lg-4 px-3 py-4">
            Purchase Order Invoice Details
          </p>

          <div class="toast-body">
            <div class="row">
              <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row ps-2 pe-3">
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <Box>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Purchase Order
                            </InputLabel>
                            <div className="d-flex">
                              <Select
                                labelId="demo-simple-select-quo"
                                id="demo-simple-select-quo"
                                // required={true}
                                label="Select PO"
                                value={poId}
                                className="w-100"
                                onChange={(value) => {
                                  setPoId(value.target.value);
                                }}
                              >
                                {purchaseOrderData.map((record) => {
                                  return (
                                    <MenuItem
                                      poid={record.fld_id}
                                      key={record.fld_id}
                                      value={record.fld_id}
                                    >
                                      {record.fld_po_no}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              <Link
                                to={{
                                  pathname: `/purchaseorderprint`,
                                  target: "_blank",
                                  state: {
                                    id: poId,
                                  },
                                }}
                                className="view-order-doc"
                              >
                                <FaEye className="fs-4 edit-icon edit-icon-font-size" />
                              </Link>
                            </div>
                          </FormControl>
                        </Box>
                      </div>

                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          className="text-field"
                          type="text"
                          value={invoiceNumber}
                          InputProps={{
                            readOnly: fieldReadOnly,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            setInvoiceNumber(e.target.value);
                          }}
                          // required={true}
                          label="Invoice No"
                        />
                      </div>

                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <div className="row">
                          <div className="col-12 mb-lg-3 mobile-personal">
                            <TextField
                              fullWidth
                              className="text-field"
                              type="text"
                              // required={true}
                              label="Invoice Amount"
                              value={amount}
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: fieldReadOnly,
                              }}
                            />
                          </div>
                          <div>
                            <FormLabel id="demo-controlled-radio-buttons-group">Is the Vendor Eligible for GST?</FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={gstEligible}
                              onChange={handleGstStatus}
                              className="d-flex flex-nowrap flex-row"
                            >
                              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                              <FormControlLabel value="No" control={<Radio />} label="NO" />
                            </RadioGroup>
                          </div>
                          {gstEligible == "Yes" &&
                            <>
                              <div className="col-12 mb-lg-3 mobile-personal">
                                <Box>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      GST*
                                    </InputLabel>
                                    <div className="d-flex">
                                      <Select
                                        labelId="demo-simple-select-gst"
                                        id="demo-simple-select-gst"
                                        // required={true}
                                        label="GST"
                                        className="w-100"
                                        value={gstper}
                                        onChange={(e) => {
                                          setGstPer(e.target.value);
                                        }}
                                      >
                                        <MenuItem key="5" value={5}>
                                          {5}
                                        </MenuItem>
                                        <MenuItem key="12" value={12}>
                                          {12}
                                        </MenuItem>
                                        <MenuItem key="18" value={18}>
                                          {18}
                                        </MenuItem>
                                        <MenuItem key="28" value={28}>
                                          {28}
                                        </MenuItem>
                                      </Select>
                                    </div>
                                  </FormControl>
                                </Box>
                              </div>
                              <div className="col-12 mb-lg-3 mobile-personal">
                                <TextField
                                  fullWidth
                                  className="text-field"
                                  type="text"
                                  // required={true}
                                  label="SGST"
                                  value={sgst}
                                  onChange={(e) => {
                                    setSgst(e.target.value);
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    readOnly: fieldReadOnly,
                                  }}
                                />
                              </div>
                              <div className="col-12 mb-lg-3 mobile-personal">
                                <TextField
                                  fullWidth
                                  type="text"
                                  label="CGST"
                                  value={cgst}
                                  // required={true}
                                  className="text-field capitalonly"
                                  onChange={(e) => {
                                    setCgst(e.target.value);
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    readOnly: fieldReadOnly,
                                  }}
                                />
                              </div>
                              <div className="col-12 mb-lg-3 mobile-personal">
                                <TextField
                                  fullWidth
                                  type="text"
                                  label="IGST"
                                  value={igst}
                                  onChange={(e) => {
                                    setIgst(e.target.value);
                                  }}
                                  // required={true}
                                  className="text-field capitalonly"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    readOnly: fieldReadOnly,
                                  }}
                                />
                              </div>
                            </>
                          }

                          <div className="col-6 mb-lg-3 mobile-personal">
                            <TextField
                              fullWidth
                              type="text"
                              label="TDS%"
                              value={tds}
                              onChange={(e) => {
                                setTds(e.target.value);
                              }}
                              // required={true}
                              className="text-field capitalonly"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: fieldReadOnly,
                              }}
                            />
                          </div>
                          <div className="col-6 mb-lg-3 mobile-personal">
                            <TextField
                              fullWidth
                              type="text"
                              label="TDS Amount"
                              value={tdsAmount}
                              onChange={(e) => {
                                setTdsAmount(e.target.value);
                              }}
                              // required={true}
                              className="text-field capitalonly"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: fieldReadOnly,
                              }}
                            />
                          </div>
                          <div className="col-12 mb-lg-3 mobile-personal">
                            <TextField
                              fullWidth
                              className="text-field"
                              type="text"
                              // required={true}
                              label="Amount Payable"
                              value={payableamount}
                              onChange={(e) => {
                                setPayableAmount(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly:
                                  login.fld_type == "Approver" &&
                                    invoiceStatus != "Not Raised"
                                    ? true
                                    : invoiceStatus != "Not Raised"
                                      ? true
                                      : false,
                              }}
                            />
                          </div>
                          <a
                            href="https://incometaxindia.gov.in/charts%20%20tables/tds%20rates.htm"
                            target={"_blank"}
                          >
                            Click Here To Check TDS Rate
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <div className="row">
                          <div className="col-10 mb-lg-4 mobile-personal">
                            <TextField
                              fullWidth
                              className="text-field"
                              type="file"
                              // required={true}
                              label="Proforma Invoice"
                              variant="outlined"
                              id="pinvoice"
                              onChange={handleChangeProformaInvoice}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: fieldReadOnly,
                              }}
                              inputProps={{
                                accept: "application/pdf, image/jpeg",
                              }}
                            />
                          </div>
                          {performaInvoicePath != "" &&
                            performaInvoicePath != null ? (
                            <div className="col-2 mb-lg-4 mobile-personal">
                              <div className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3">
                                <div
                                  className=" justify-content-end"
                                  style={{ display: "flex" }}
                                >
                                  <Button variant="contained">
                                    <a
                                      class="text-decoration-none text-reset"
                                      variant="contained"
                                      href={performaInvoicePath}
                                      target="_blank"
                                    >
                                      View
                                    </a>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="col-10  mt-lg-1 mb-lg-4 mobile-personal">
                            <TextField
                              fullWidth
                              className="text-field"
                              type="file"
                              // required={true}
                              label="Change Invoice"
                              variant="outlined"
                              id="invoice"
                              onChange={handleChangeInvoice}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: fieldReadOnly,
                              }}
                              inputProps={{
                                accept: "application/pdf, image/jpeg",
                              }}
                            />
                          </div>
                          {invoicePath != "" && invoicePath != null ? (
                            <div className="col-lg-2 mb-lg-3 mobile-personal">
                              <div className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3">
                                <div
                                  className=" justify-content-end"
                                  style={{ display: "flex" }}
                                >
                                  <Button variant="contained">
                                    <a
                                      class="text-decoration-none text-reset"
                                      variant="contained"
                                      href={invoicePath}
                                      target="_blank"
                                    >
                                      View
                                    </a>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="col-12  mobile-personal">
                            <TextField
                              fullWidth
                              type="text"
                              label="Description"
                              value={description}
                              // required={true}
                              multiline
                              rows={gstEligible == "Yes" ? 16 : 5}
                              className="text-field capitalonly purchase-order-description-text"
                              onChange={(e) => {
                                setDescription(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
          {login.fld_type == "Approver" &&
            invoiceStatus != "Paid" &&
            invoiceStatus != "Closed" &&
            invoiceStatus != "Rejected" ? (
            <div className="col-md-12 mb-3">
              <TextField
                id="outlined-basic"
                label="Reason.."
                value={rejectionComment}
                onChange={(e) => setRejectionComment(e.target.value)}
                variant="outlined"
                rows={4}
                multiline
                fullWidth
              />
            </div>
          ) : null}

          {addBankData.length != 0 ? (
            <Slider {...settings} className="mb-3">
              {addBankData?.map((bank) => {
                return (
                  <div className="mx-3 my-3">
                    <div class="card mx-2">
                      <div class="card-body">
                        <h5 class="card-title">
                          <strong>{bank.fld_name}</strong>
                        </h5>
                        <p class="card-text mb-1 ">
                          <strong>{bank.fld_bank_name}</strong>
                        </p>
                        <p class="card-text mb-1">{bank.fld_account_number}</p>
                        <p class="card-text mb-1">{bank.fld_branch_name}</p>
                        <p class="card-text mb-1">{bank.fld_account_number}</p>
                        <p class="card-text mb-1">
                          {bank.fld_bank_holder_name}
                        </p>
                        <p class="card-text mb-1">{bank.fld_ifsccode}</p>
                        <p class="card-text mb-1">{bank.fld_swiftc_code}</p>
                      </div>

                      <div class="form-check position-absolute check-box-po-btn">
                        <input
                          class="form-check-input fs-4"
                          type="radio"
                          name="contactlist"
                          value=""
                          id="flexCheckDefault"
                          onChange={(e) => {
                            setSelectedBank(bank.fld_id);
                            setBankAccNo(bank.fld_account_number);
                            setBankAccHolderName(bank.fld_account_holder_name);
                            setIfsccode(bank.fld_ifsccode);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          ) : null}
          {addBankData.length != 0 ? (
            <div>
              <div class="col-md-12">
                <div class="row ps-2 pe-3">
                  <div className="col-lg-6 mb-lg-4 mobile-personal">
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Payment Mode
                        </InputLabel>
                        <div className="d-flex">
                          <Select
                            labelId="demo-simple-select-quo"
                            id="demo-simple-select-quo"
                            // required={true}
                            label="Select Payment Mode"
                            value={paymentMode}
                            className="w-100"
                            onChange={(value) => {
                              setPaymentMode(value.target.value);
                              if (
                                paymentNo != "" &&
                                paymentMode != "" &&
                                selectedBank != null
                              ) {
                                setPayBtnDisabled(false);
                              }
                            }}
                          >
                            <MenuItem value="NEFT/RTGS">NEFT/RTGS</MenuItem>

                            <MenuItem value="Cheque">Cheque</MenuItem>
                          </Select>
                        </div>
                      </FormControl>
                    </Box>
                  </div>

                  <div className="col-lg-6 mb-lg-4 mobile-personal">
                    <TextField
                      fullWidth
                      className="text-field"
                      type="text"
                      value={paymentNo}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setPaymentNo(e.target.value);
                        if (
                          paymentNo != "" &&
                          paymentMode != "" &&
                          selectedBank != null
                        ) {
                          setPayBtnDisabled(false);
                        }
                      }}
                      // required={true}
                      label="Payment No."
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-toggle="toast"
          >
            <div
              className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-3"
              style={{ float: "right" }}
            >
              <div className="d-flex justify-content-end">
                {/* on vendor's end */}
                {invoiceStatus == "Not Raised" ? (
                  <Button
                    variant="contained"
                    onClick={() => onAddInvoice("New")}
                  >
                    Send For Approval
                  </Button>
                ) : invoiceStatus == "Pending" && login.fld_id == 4 ? (
                  <>
                    {/* on approver dashboard */}
                    <Button
                      className="me-2 bg-danger"
                      variant="contained"
                      onClick={
                        rejectionComment != ""
                          ? () => onAddInvoice("Rejected")
                          : () =>
                            Notiflix.Notify.failure(
                              "Please enter the reason for rejection!"
                            )
                      }
                    >
                      Reject
                    </Button>

                    <Button
                      variant="contained"
                      onClick={() => onAddInvoice("Verified")}
                    >
                      Verify
                    </Button>
                  </>
                ) : invoiceStatus == "Verified" && login.fld_id == 4 ? (
                  <>
                    {/* again on approver dashboard */}
                    <Button
                      className="me-2 bg-danger"
                      variant="contained"
                      onClick={
                        rejectionComment != ""
                          ? () => onAddInvoice("Rejected")
                          : () =>
                            Notiflix.Notify.failure(
                              "Please enter the reason for rejection!"
                            )
                      }
                    >
                      Reject
                    </Button>

                    <Button
                      variant="contained"
                      onClick={() => onAddInvoice("Approved")}
                    >
                      Approve
                    </Button>
                  </>
                ) : invoiceStatus == "Approved" ? (
                  <Button
                    variant="contained"
                    disabled={payBtnDisabled}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Pay
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {location.state.frominvoce == "Yes" && (
          <div className=" mt-5 card-vahani p-0 ">
            <div className="personal-info left-line-card px-lg-4 px-4 py-4">
              Approval History
            </div>
            <div className="p-4 table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Sr. No.</th>
                    <th scope="col">Approval Sent On</th>
                    <th scope="col">Approval Level</th>
                    <th scope="col">Approval Status</th>
                    <th scope="col">Updated On</th>
                    <th scope="col">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {approvalList.map((val, i) => {
                    return (
                      <tr>
                        <th scope="row">{i + 1}</th>
                        <td>
                          {moment(val.fld_created_on).format("DD/MM/YYYY")}
                        </td>
                        <td>{val.fld_pending_with == "Vendor Management" ?
                          "Vahani Operations"
                          : val.fld_pending_with}</td>
                        <td>
                          <div>
                            <span>{(val.fld_action == "Pending" && val.fld_pending_with == "Vendor Management")
                              ? "Created"
                              : val.fld_action}</span>
                            <br />
                            {(val.approved_by != null && val.approved_by == "Info Vendor") ? (
                              <span> By Vahani Operations</span>)
                              : (
                                <span> By {val.approved_by}</span>
                              )}
                          </div>
                        </td>
                        <td>
                          {val.fld_action_date == null
                            ? ""
                            : moment(val.fld_action_date).format("DD/MM/YYYY")}
                        </td>
                        <td>{val.fld_comments}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Modal show={open} onHide={handleClose} size="lg" centered>
          <Modal.Body>
            <p>
              Payment Transfer Receipt{" "}
              <small className="text-secondary">
                (Max Size 2MB File Format *.jpeg, *.jpg, *.png, *.pdf)
              </small>
            </p>
            <div className="col-md-6 mb-3 mt-4 mx-auto">
              <div
                style={{
                  width: "300px",
                  height: "200px",
                  border: "1px solid gray",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <img
                  src={ReceiptImage}
                  width="100%"
                  height="100%"
                  onClick={() => document.querySelector("#checkphoto").click()}
                />
              </div>

              <input
                type="file"
                style={{ display: "none" }}
                id="checkphoto"
                onChange={(e) => {
                  //
                  setReciptFile(e.target.files[0]);
                  setReceiptImage(URL.createObjectURL(e.target.files[0]));
                }}
              />
            </div>
            <div
              className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-3"
              style={{ float: "right" }}
            >
              <div className="d-flex justify-content-end"></div>
              <Button
                variant="contained"
                onClick={() => {
                  if (ReciptFile != null) {
                    if (location.state.id != null) {
                      if (
                        location.state.remainingAmount -
                        parseInt(payableamount) >
                        0
                      ) {
                        onAddInvoice("Partially Paid");
                      } else {
                        onAddInvoice("Fully Paid");
                      }
                    } else {
                      if (parseInt(amount) - parseInt(payableamount) > 0) {
                        onAddInvoice("Partially Paid");
                      } else {
                        onAddInvoice("Fully Paid");
                      }
                    }
                  }
                }}
              >
                Save
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
