import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Academic from "../Components/Form/Academic Achievement/AcademicAch";
import Additional from "../Components/Form/AdditionalApplication/AdditionalApplication";
import Address from "../Components/Form/Address/Address";
import ResetEmail from '../Components/Form/Email/Email';
import Essay from "../Components/Form/Essay/Essay";
import Extra from "../Components/Form/ExtraCurriculation/ExtraCurriculation";
import AdditionalFamilyInfo from '../Components/Form/Familyinfo/AdditionalFamilyInfo';
import Familyinfo from '../Components/Form/Familyinfo/Familyinfo';
import ResetPassword from '../Components/Form/Password/Password';
import PersonalInfo from '../Components/Form/Personalinfo/Personalinfo';
import SchoolInfo from "../Components/Form/SchoolForm/SchoolInfo";
import SubmitTicket from "../Components/Form/TicketManagement/SubmitTicket";
import TicketHistory from "../Components/Form/TicketManagement/TicketHistory";
import ViewTicket from '../Components/Form/TicketManagement/ViewTicket';
import University from "../Components/Form/University/University-College";
import ViewStudentList from '../pages/AdminManagement/Students/ViewStudentList';
import PageNotFound from '../pages/PageNotFound';
import ParentBank from "../pages/ScholarManagement/BankDetails/ParentsBank";
import PersonalBank from "../pages/ScholarManagement/BankDetails/PersonalBank";
import ExpenseForm from '../pages/ScholarManagement/Expense/ExpenseForm';
import ExpenseList from '../pages/ScholarManagement/Expense/ExpenseList';
import ViewExpense from '../pages/ScholarManagement/Expense/ViewExpense';
import ViewNotification from '../pages/ScholarManagement/Notification/ViewNotifcation';
import Dashboard from '../pages/StudentManagement/Dashboard/Dashboard';
const StudentRoute = () => {
    return (
        <Switch>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/expense" component={ExpenseForm} />
            <Route exact path="/expense-list" component={ExpenseList} />
            <Route exact path="/scholer-expense-list" component={ViewExpense} />
            <Route exact path="/personalinformation" component={PersonalInfo} />
            <Route exact path="/familyinformation" component={Familyinfo} />
            <Route exact path="/additionalfamily" component={AdditionalFamilyInfo} />
            <Route path="/address" component={Address} exact />
            {/* <Route path="/personal-bank" component={PersonalBank} exact /> */}
            {/* <Route path="/parent-bank" component={ParentBank} exact /> */}
            <Route path="/schoolinfo" component={SchoolInfo} exact />
            <Route path="/academic-achievment" component={Academic} exact />
            <Route path="/extra-curricular-activities" component={Extra} exact />
            <Route path="/university-college-preference" component={University} exact />
            <Route path="/essay" component={Essay} exact />
            <Route path="/additional-applicant" component={Additional} exact />
            <Route path="/submit-ticket" component={SubmitTicket} exact />
            <Route path="/ticket-history" component={TicketHistory} exact />
            <Route path="/view-ticket" component={ViewTicket} exact />
            <Route exact path="/change-email" component={ResetEmail} />
            <Route exact path="/change-password" component={ResetPassword} />
            <Route exact path="/view-notification" component={ViewNotification} />
            <Route exact path="/student-list" component={ViewStudentList} />
            <Route exact path="/student-list-search" component={ViewStudentList} />
            <Route path="*" component={PageNotFound} />
        </Switch>
    )
}
export default StudentRoute