import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Notiflix from "notiflix";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { MDBDataTable } from "mdbreact";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PostApiCall from "../../../assets/API/PostApi";
import store from "../../../store";

export default function ExpenseList() {
  const { setexpenseData } = useContext(store);
  const [expense, setexpense] = useState(null);
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const [rows, setrows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showModal, setshowModal] = useState(false);
  const [showUploadModal, setshowUploadModal] = useState(false);
  const [approvalList, setapprovalList] = useState([]);
  const [billDate, setbillDate] = useState("");
  const [ReciptFile, setReciptFile] = useState(null);
  const [ReceiptImage, setReceiptImage] = useState("/upload.jpg");
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    GetExpenseList();
  }, []);

  const GetExpenseList = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_studentId=${login.fld_id} order by fld_id desc`,
      },
      "Get_ScholarExpenseInformation"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          //
          Notiflix.Loading.remove();
          setrows(obj.data);
        }
      })
    );
  };

  // Render in modal
  const FetchApprovalHistory = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_expense_id=${expense.fld_id} order by fld_approval_id asc`,
      },
      "Get_ScholarExpenseApprovalHistory"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          //
          setapprovalList(obj.data);
          Notiflix.Loading.remove();
        }
      })
    );
  };

  useEffect(() => {
    GetExpenseList();
  }, []);

  return (
    <>
      <div className="form-head">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Expense List</h4>
        </div>
      </div>
      <div className="card-vahani p-0">
        <div className="row justify-content-between align-items-center m-0 left-line-card px-4 py-3 ">
          <p className="personal-info m-0 p-0 col-auto ">Expense List</p>
          <Link
            className=" btn btn-primary col-auto"
            onClick={() => setexpenseData({ action: "add" })} // update store 
            to="/expense" // route to expense form
          >
            {" "}
            Create New +
          </Link>
        </div>
        <div className="px-4">
          {rows.length > 0 ? (
            <MDBDataTable
              responsive
              hover
              striped
              disableRetreatAfterSorting={true}
              data={{
                columns: [
                  {
                    label: "Student ID",
                    field: "studentid",
                    sort: "disabled",
                  },
                  {
                    label: "Name",
                    field: "name",
                    sort: "disabled",
                  },
                  {
                    label: "Expense Type",
                    field: "expenseType",
                    sort: "disabled",
                  },
                  {
                    label: "Amount (₹)",
                    field: "amount",
                    sort: "disabled",
                  },
                  {
                    label: "Raised on",
                    field: "raised",
                    sort: "disabled",
                  },
                  {
                    label: "Payment Receipt",
                    field: "paymentReceipt",
                    sort: "disabled",
                  },
                  {
                    label: "Bill Receipt",
                    field: "billReceipt",
                    sort: "disabled",
                  },
                  {
                    label: "Status",
                    field: "status",
                    sort: "disabled",
                  },
                  {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                  },
                ],
                rows: rows.map((row, i) => {
                  return {
                    studentid: row.fld_student_registration_no,
                    name: row.full_name,
                    expenseType: row.fld_expense_type,
                    amount: <p className="text-end">{row.fld_feeAmount}/-</p>,
                    raised: moment(row.fld_requestedon).format("DD/MM/YYYY"),
                    paymentReceipt: row.fld_adminReceipt ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => window.open(row.fld_adminReceipt)}
                      >
                        View Receipt
                      </button>
                    ) : null,
                    billReceipt: (
                      <>
                        {(row.fld_approval_status == "Paid" ||
                          row.fld_approval_status ==
                          "Receipt Not Approved") && (
                            <>
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  setexpense(row);
                                  setshowUploadModal(true);
                                }}
                              >
                                Upload Receipt
                              </button>
                              <input
                                type="file"
                                className="d-none"
                                id={`upload-${row.fld_id}`}
                              />
                            </>
                          )}
                        {row.fld_approval_status == "Receipt Uploaded" && (
                          <div className="d-flex justify-content-around">
                            <a
                              className="btn btn-success m-0"
                              href={row.fld_billReceipt}
                              target="_blank"
                            >
                              View
                            </a>
                            <button
                              className="btn btn-primary p-0 px-1 m-0 cursor-pointer"
                              onClick={() => {
                                setexpense(row);
                                setshowUploadModal(true);
                              }}
                            >
                              <Tooltip title="Upload Receipt">
                                <CloudUploadIcon />
                              </Tooltip>
                            </button>
                            <input
                              type="file"
                              className="d-none"
                              id={`upload-${row.fld_id}`}
                            />
                          </div>
                        )}
                        {row.fld_approval_status == "Closed" && (
                          <a
                            className="btn btn-success m-0"
                            href={row.fld_billReceipt}
                            target="_blank"
                          >
                            View Receipt
                          </a>
                        )}
                      </>
                    ),
                    status: row.fld_approval_status,
                    action: (
                      <Button
                        id="basic-button"
                        value={row.fld_id}
                        aria-controls={
                          Boolean(anchorEl) ? "basic-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setexpense(row);
                        }}
                      >
                        {" "}
                        <MoreHorizIcon />
                      </Button>
                    ),
                    // action:
                    //   <FormControl sx={{ minWidth: 100 }} size="small">
                    //     <InputLabel id="demo-select-small">Select</InputLabel>
                    //     <Select
                    //       labelId="demo-select-small"
                    //       id="demo-select-small"
                    //       // value={age}
                    //       label="Select"
                    //       // onChange={handleChange}
                    //     >
                    //       <MenuItem value="">Select</MenuItem>
                    //       <MenuItem value={10}>Ten</MenuItem>
                    //       <MenuItem value={20}>Twenty</MenuItem>
                    //       <MenuItem value={30}>Thirty</MenuItem>
                    //     </Select>
                    //   </FormControl>
                  };
                }),
              }}
              entriesOptions={[10, 25, 50, 100]}
            />
          ) : (
            <p className="m-3">No Data Found</p>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {(expense?.fld_approval_status == "Rejected" ||
              expense?.fld_approval_status == "Draft") && (
                <MenuItem
                  onClick={() =>
                    setexpenseData({ data: expense, action: "edit" })
                  }
                >
                  <Link to="/expense">Edit</Link>
                </MenuItem>
              )}
            <MenuItem
              onClick={() => setexpenseData({ data: expense, action: "view" })}
            >
              <Link to="/expense">View</Link>
            </MenuItem>
            <MenuItem
              className="text-center"
              onClick={() => setexpenseData({ data: expense, action: "copy" })}
            >
              <Link to="/expense">Copy</Link>
            </MenuItem>
            <MenuItem
              onClick={() => {
                FetchApprovalHistory();
                setshowModal(true);
              }}
            >
              View Approval History
            </MenuItem>
          </Menu>
        </div>
      </div>
      <Dialog
        open={showModal}
        maxWidth={"md"}
        onClose={() => setshowModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className="d-flex justify-content-between"
          id="alert-dialog-title"
        >
          {" "}
          <h2>Approval History</h2>{" "}
          <CancelIcon
            className="cursor-pointer"
            onClick={() => setshowModal(false)}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className=" mt-2 card-vahani p-0">
              <h4 className="personal-info left-line-card p-4">
                Approval History
              </h4>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Sr. No.</th>
                      <th scope="col">Approval Sent On</th>
                      <th scope="col">Approved By</th>
                      <th scope="col">Approval Status</th>
                      <th scope="col">Updated On</th>
                      <th scope="col">Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvalList.map((val, i) => {
                      return (
                        <tr>
                          <th scope="row">{i + 1}</th>
                          <td>
                            {moment(val.fld_created_on).format("DD/MM/YYYY")}
                          </td>
                          <td>{val.fld_pending_with}</td>
                          <td>
                            <div>
                              <span>
                                {val.fld_action == null
                                  ? "Pending"
                                  : val.fld_action}
                              </span>
                              <br />
                              {val.approved_by != null && (
                                <span> By {val.approved_by}</span>
                              )}
                            </div>
                          </td>
                          <td>
                            {val.fld_action_date == null
                              ? null
                              : moment(val.fld_action_date).format(
                                "DD/MM/YYYY"
                              )}
                          </td>
                          <td>{val.fld_comments}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showUploadModal}
        maxWidth={"md"}
        onClose={() => setshowUploadModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className="d-flex justify-content-between"
          id="alert-dialog-title"
        >
          {" "}
          <h2>Upload Bill / Receipt</h2>{" "}
          <CancelIcon
            className="cursor-pointer"
            onClick={() => setshowUploadModal(false)}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className=" mt-1 text-center card-vahani ">
              <TextField
                fullWidth
                type="date"
                label="Enter Bill Payment Date*"
                value={billDate}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setbillDate(e.target.value)}
                InputProps={{
                  inputProps: {
                    max: moment(Date.now())
                      .format("YYYY/MM/DD")
                      .split("/")
                      .join("-"),
                  },
                }}
              />
              <div
                className="col-6 my-3 mx-auto cursor-pointer"
                style={{
                  border: "1px solid gray",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <img
                  src={ReceiptImage}
                  width="100%"
                  height="100%"
                  onClick={() => document.querySelector("#uploadphoto").click()}
                />
              </div>
              <span>Bill Receipt </span>
              <br />
              <small className="text-secondary">
                (Max Size 2MB File Format *.jpeg, *.jpg, *.png, *.pdf)
              </small>
              <input
                type="file"
                style={{ display: "none" }}
                id="uploadphoto"
                onChange={(e) => {
                  //
                  setReciptFile(e.target.files[0]);
                  setReceiptImage(URL.createObjectURL(e.target.files[0]));
                }}
              />
              <div className="col-12 mt-3 text-center">
                <button
                  className="btn btn-primary col-auto"
                  onClick={async () => {
                    //
                    if (billDate != "") {
                      if (ReciptFile != null) {
                        if (ReciptFile.size < 2000000) {
                          if (
                            ReciptFile.type == "image/png" ||
                            ReciptFile.type == "image/jpg" ||
                            ReciptFile.type == "image/jpeg" ||
                            ReciptFile.type == "application/pdf"
                          ) {
                            const form = new FormData();
                            let path = `${login.fld_id}-${Math.floor(
                              Math.random() * 1000000
                            )}-${ReciptFile.name}`;
                            form.append("file", ReciptFile);
                            form.append("foldername", "FinanceAccounts");
                            form.append("filename", path);
                            Notiflix.Loading.dots();
                            const res = await fetch(
                              "https://admin.vahanischolarship.in/API/AddImage2",
                              { method: "POST", body: form }
                            );
                            const response = await res.json();
                            if (response) {
                              Notiflix.Loading.remove();
                              Notiflix.Notify.success(
                                "Bill Receipt Uploaded Successfully."
                              );
                              let url = `https://admin.vahanischolarship.in/Images/FinanceAccounts/${path}`;
                              PostApiCall.postRequest(
                                {
                                  id: expense.fld_id,
                                  billReceipt: url,
                                  billPayDate: billDate,
                                  updatedon: null,
                                  updatedby: login.fld_id,
                                },
                                "Update_ScholarExpenseInformationBillReceiptImage"
                              ).then((results) =>
                                results.json().then((obj) => {
                                  if (
                                    results.status === 200 ||
                                    results.status === 201
                                  ) {
                                    setReciptFile(null);
                                    setReceiptImage("/upload.jpg");
                                    setshowUploadModal(false);
                                    GetExpenseList();
                                    Notiflix.Loading.remove();
                                  }
                                })
                              );
                            } else {
                              Notiflix.Loading.remove();
                              Notiflix.Notify.failure(
                                "There seems to be an Error. Please retry!"
                              );
                            }
                          } else {
                            Notiflix.Notify.failure(
                              "Image should be in jpeg, jpg or png format"
                            );
                          }
                        } else {
                          Notiflix.Notify.failure(
                            "File too large, upload file less than 2 MB."
                          );
                        }
                      } else {
                        Notiflix.Notify.failure("Please Upload Bill Receipt.");
                      }
                    } else {
                      Notiflix.Notify.failure("Please Enter Bill Pay Date.");
                    }
                  }}
                >
                  Upload Bill Receipt
                </button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
