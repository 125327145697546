import { Button, TextField } from "@mui/material";
import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import PostApiCall from "../../../assets/API/PostApi";

export default function RfqList() {
  const [RFQData, setRFQData] = useState([]);
  const [dateData, setdateData] = React.useState({ from: "", to: "" });
  const [ConditionType, setConditionType] = useState("New");
  const [SortData, setSortData] = React.useState({
    type: "desc",
    value: "fld_id",
  });
  const login = JSON.parse(localStorage.getItem("LoginDetail"))[0]

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    Notiflix.Loading.init({
      svgColor: "rgb(6, 10, 74)",
    });
    Notiflix.Loading.dots("Please wait...");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetRFQMasters"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setRFQData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Notify.failure("error occurred!");
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);

  const getRFQList = (condition) => {
    PostApiCall.postRequest(
      {
        whereClause:
          dateData.from == "" && dateData.to == ""
            ? `order by ${SortData.value} ${SortData.type}`
            : `where fld_rfq_status='${condition}' ${dateData.from != "" && dateData.to != ""
              ? `and fld_created_on between '${dateData.from}' and '${dateData.to}' `
              : ""
            } order by ${SortData.value} ${SortData.type}`,
      },
      "GetRFQMasters"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setRFQData(obj.data);
        }
      })
    );
  };

  const data = {
    columns: [
      {
        label: "S.No.",
        field: "serial",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Vendor Name",
        field: "Vendorname",
        sort: "disabled",
        width: 400,
      },
      {
        label: "RFQ No.",
        field: "rfqno",
        sort: "disabled",
        width: 400,
      },
      {
        label: "RFQ Date",
        field: "rfqDate",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Created By",
        field: "createdby",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
        sort: "asc",
      },
    ],
    rows: RFQData.map((data, i) => {
      return {
        serial: i + 1,
        Vendorname: data.vendor_name,
        rfqno: data.fld_rfq_no,
        rfqDate: moment(data.fld_rfq_date).format("DD-MM-YYYY"),
        status: data.fld_rfq_status,
        createdby: data.created_by,
        action: (
          <div className="d-flex">
            {

              <Link
                className="m-auto"
                to={{
                  pathname: `/add-rfq`,
                  state: {
                    id: data.fld_id,
                  },
                }}
              >
                <FaEdit className="edit-icon edit-icon-font-size" />
              </Link>
            }
            {data.fld_rfq_task_count > 0 ? (
              <Link
                to={{
                  pathname: `/add-rfq-task`,
                  state: {
                    id: data.fld_id,
                  },
                }}
                className="task-btn-color ms-2"
              >
                View RFQ Task
              </Link>
            ) : (
              <Link
                to={{
                  pathname: `/add-rfq-task`,
                  state: {
                    id: data.fld_id,
                  },
                }}
                className="task-btn-color ms-2"
              >
                Add RFQ Task
              </Link>
            )}
          </div>
        ),
      };
    }),
  };

  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>RFQ List </h4>
        </div>
      </div>
      <div className="card-vahani p-0 mt-2">
        <div className="personal-info left-line-card row m-0 align-items-lg-center justify-content-between px-2 py-4 mb-2 w-100">
          <div className="col-4 col-md-10 mb-2 mb-md-0">RFQs'</div>
          {/* hide from accountant */}
          {login.fld_type != "Accountant" &&
            <Link
              to={{
                pathname: `/add-rfq`,
                state: {
                  id: null,
                },
              }}
              className="col-8 col-md-2 mb-sm-0 text-white text-end"
            >
              <Button variant="contained">Add RFQ</Button>
            </Link>
          }
        </div>
        <div className="personal-info m-0 ps-md-1 pe-md-3 pb-0 d-lg-flex align-items-center justify-content-between">
          <div className="col-xxl-6 col-12 mt-3">
            <div className="row px-0">
              <TextField
                type="date"
                label="From Date"
                id="outlined-size-small"
                size="small"
                value={dateData.from}
                onChange={(e) =>
                  setdateData({ ...dateData, from: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className="mb-3 mb-md-0 px-2 col-md-4"
              />
              <TextField
                type="date"
                label="To Date"
                id="outlined-size-small"
                size="small"
                value={dateData.to}
                onChange={(e) =>
                  setdateData({ ...dateData, to: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className="px-2 col-md-4 mb-3 mb-md-0"
              />
              <div className="px-2 col-md-2">
                <Button
                  className="sort-filter-size w-100"
                  variant="contained"
                  onClick={() => {
                    if (dateData.from != "") {
                      if (dateData.to != "") {
                        getRFQList(ConditionType);
                      } else {
                        Notiflix.Notify.failure("Enter To date");
                      }
                    } else {
                      Notiflix.Notify.failure("Enter From date");
                    }
                  }}
                >
                  Filter
                </Button>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 px-lg-0 col-12 mt-3 px-0">
            <div className="row m-0 px-0 align-items-lg-start justify-content-lg-end">
              <div className="col-sm-3 mb-sm-0 mb-3 col-12 pe-lg-0">
                <select
                  class="form-select form-select-sm sort-filter-size"
                  aria-label=".form-select-sm example"
                  onChange={(e) =>
                    setSortData({ ...SortData, value: e.target.value })
                  }
                >
                  <option value="fld_id">Sort By</option>
                  <option value="vendor_name">Vendor Name</option>
                  <option value="trim(fld_rfq_no)">RFQ Number</option>
                  <option value="cast(fld_created_on as date)">
                    Raised On
                  </option>
                  <option value="trim(fld_rfq_status)">Status</option>
                </select>
              </div>
              <div className="col-sm-3 col-12 mb-sm-0 mb-3 ">
                <select
                  class="form-select form-select-sm sort-filter-size"
                  aria-label=".form-select-sm example"
                  onChange={(e) =>
                    setSortData({ ...SortData, type: e.target.value })
                  }
                >
                  <option value="desc">Order</option>
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending </option>
                </select>
              </div>
              <div className="col-sm-2 col-12 mb-sm-0 mb-3 px-lg-0">
                <Button
                  className="w-100"
                  variant="contained"
                  onClick={() => {
                    getRFQList(ConditionType);
                  }}
                >
                  Sort
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="p-3 purchase-order-table overflow-x-auto">
          {RFQData.length > 0 ? (
            <>
              <MDBDataTable
                responsive
                hover
                disableRetreatAfterSorting={true}
                data={data}
                entriesOptions={[10, 25, 50, 100]}
              >
                <MDBTableHead columns={data.columns} />
                <MDBTableBody rows={data.rows} />
              </MDBDataTable>
            </>
          ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )}
        </div>
      </div>
    </>
  );
}
