import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Avatar,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PostApiCall from "../../../assets/API/PostApi";
// import GetApiCall from '../../../assets/API/GetApi';
import moment from "moment";
import Notiflix from "notiflix";
export default function TicketList() {
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const [List, setList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchinput] = useState("");
  const [open, setOpen] = React.useState(null);
  const [AnswerList, setAnswerList] = useState([]);
  const [Answer, setAnswer] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageCount, setPageCount] = useState([]);
  const [SortData, setSortData] = React.useState({
    type: "desc",
    value: "fld_id",
  });
  const [ConditionType, setConditionType] = useState("Open");
  useEffect(() => {
    getTicketList(ConditionType);
  }, []);
  const getTicketList = (condition) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause:
          condition == "All"
            ? `where fld_status not like 'Delete'`
            : `where fld_status='${condition}' order by ${SortData.value} ${SortData.type}`,
      },
      "Get_AdminTicketMessageList"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          //
          setList(obj.data);
          setFilteredData(obj.data);
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  const getTicketAnswer = (stdid, quesid) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        studentId: stdid,
        questionId: quesid,
      },
      "Get_TicketAnswer_ById"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          //
          setAnswerList(obj.data);
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  const updateAnswer = (stdid, quesid) => {
    if (Answer != "") {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          studentId: stdid,
          questionId: quesid,
          answer: Answer,
          updatedOn: moment().format("lll"),
          updatedBy: login.fld_id,
        },
        "Add_TicketAmswer"
      ).then((result) =>
        result.json().then((obj) => {
          if (result.status == 200 || result.status == 201) {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Query Submitted Successfully");
            setAnswer("");
            getTicketAnswer(stdid, quesid);
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(
              "There seems to be an Error. Please retry!"
            );
          }
        })
      );
    } else {
      Notiflix.Notify.failure("Please enter query.");
    }
  };
  const closeTicket = (quesid, status) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: quesid,
        status: status,
      },
      "Update_TicketMessageStatus"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Ticket Closed.");
          window.location.reload();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Ticket List</h4>
        </div>
      </div>
      <div className="card-vahani p-0">
        <div className="row m-0 left-line-card pt-3 pb-0 px-4 justify-content-between align-items-center ">
          <p className="personal-info col-auto m-0  ">Query List</p>
          <div className="col-md-3 mb-3 ">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">View</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue="Open"
                label="View"
                onChange={(e) => {
                  getTicketList(e.target.value);
                  setConditionType(e.target.value);
                }}
                value={ConditionType}
              >
                <MenuItem value="Open">Open</MenuItem>
                <MenuItem value="Closed">Closed</MenuItem>
                <MenuItem value="All">List All</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="row m-0 border-top p-2 justify-content-end ">
          {/* Search box */}

          <div className="col-sm-3 col-6">
            <select
              class="form-select form-select-sm "
              aria-label=".form-select-sm example"
              onChange={(e) =>
                setSortData({ ...SortData, value: e.target.value })
              }
            >
              <option value="fld_id">Sort By</option>
              <option value="fld_id">Ticket No.</option>
              <option value="trim(fld_firstName)">Name</option>
              <option value="fld_question">Query</option>
              <option value="cast(fld_generatedon as date)">Date</option>
              <option value="fld_status">Status</option>
            </select>
          </div>
          <div className="col-sm-2 col-6">
            <select
              class="form-select form-select-sm "
              aria-label=".form-select-sm example"
              onChange={(e) =>
                setSortData({ ...SortData, type: e.target.value })
              }
            >
              <option value="desc">Order</option>
              <option value="asc">Ascending</option>
              <option value="desc">Descending </option>
            </select>
          </div>
          <Button
            className="mt-sm-0 ms-2 mt-2 py-0 col-auto me-sm-0 me-3"
            variant="contained"
            onClick={() => {
              getTicketList(ConditionType);
            }}
          >
            Sort
          </Button>
          <div className="col-sm-3 col-6">
            <input
              className="form-control form-control-sm ml-0 common-search-bar"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => {
                setSearchinput(e.target.value);
                localStorage.setItem("searchticketdata", e.target.value); // save to pre-fill
              }}
              value={searchInput}
            />
            {/* Search box end */}
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead className="tablesimple-head">
              <TableRow>
                <TableCell className="fw-bold" style={{ minWidth: "101px" }}>
                  Ticket No.
                </TableCell>
                <TableCell className="fw-bold">Name</TableCell>
                <TableCell className="fw-bold">Query</TableCell>
                <TableCell className="fw-bold" style={{ minWidth: "110px" }}>
                  Date/Time
                </TableCell>
                <TableCell className="fw-bold">Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).filter((data) => {
                if (searchInput == "") {
                  return data;
                }
                if (
                  // searchInput.toString() !== "" &&
                  data.fld_id
                    .toString()
                    .includes(searchInput.toString().toLowerCase()) ||
                  `${data.fld_status
                    ? data.fld_status.toString().toLowerCase()
                    : ""
                    }`
                    .toString()
                    .includes(searchInput.toString().toLowerCase()) ||
                  `${data.fld_question != null
                    ? data.fld_question.toString().toLowerCase()
                    : null
                    }`
                    .toString()
                    .includes(searchInput.toString().toLowerCase()) ||
                  `${data.fld_firstName != null
                    ? data.fld_firstName.toString().toLowerCase()
                    : null
                    }`
                    .toString()
                    .includes(searchInput.toString().toLowerCase()) ||
                  `${data.fld_generatedon != null
                    ? new Date(data.fld_generatedon).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric"
                    }).toString().toLowerCase()
                    : null
                    }`
                    .includes(searchInput.toString().toLowerCase())
                ) {
                  return data;
                }
              }).map((row, i) => {
                return (
                  <>
                    <TableRow
                      style={{
                        backgroundColor: open == i ? "#f2f2f2" : "white",
                      }}
                      sx={{ "& > *": { borderBottom: "unset" } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.fld_id}
                      </TableCell>
                      <TableCell>{row.fld_firstName}</TableCell>
                      <TableCell>{row.fld_question}</TableCell>
                      <TableCell>
                        {moment(row.fld_generatedon).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{row.fld_status}</TableCell>
                      <TableCell>
                        <IconButton
                          className="bg-primary text-white"
                          aria-label="expand row"
                          // size="small"
                          onClick={() => {
                            if (open != null) {
                              setOpen(open == i ? null : i);
                              if (open != i) {
                                getTicketAnswer(row.fld_studentid, row.fld_id);
                              }
                            } else {
                              setOpen(i);
                              getTicketAnswer(row.fld_studentid, row.fld_id);
                            }
                          }}
                        >
                          {open == i ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={open == i ? true : false}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ margin: 1 }}>
                            <div className="row m-0 py-3 ">
                              {AnswerList.map((val) => {
                                return (
                                  <div className="row m-0 py-2 border-bottom">
                                    <div className="d-flex align-items-center">
                                      <Avatar
                                        alt="Remy Sharp"
                                        src={val.fld_photo}
                                        sx={{ width: 60, height: 60 }}
                                      />
                                      <div className="w-100">
                                        <div className="d-flex justify-content-between align-items-center">
                                          <span className="ms-3 fw-bold">
                                            {val.fld_fullName}
                                          </span>
                                          <span className="fw-bold">
                                            Posted :{" "}
                                            {moment(
                                              val.fld_updatedon
                                            ).calendar()}
                                          </span>
                                        </div>
                                        <p className="mt-2 ms-3">
                                          {val.fld_answer}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                              {row.fld_status != "Closed" && (
                                <>
                                  <TextField
                                    type="text"
                                    fullWidth
                                    label="Write Your Answers"
                                    onChange={(e) => setAnswer(e.target.value)}
                                  />
                                  <div className="d-flex justify-content-end mt-3">
                                    <Button
                                      variant="contained"
                                      className="me-3"
                                      onClick={() => {
                                        updateAnswer(
                                          row.fld_studentid,
                                          row.fld_id
                                        );
                                        closeTicket(row.fld_id, "Closed");
                                      }}
                                    >
                                      Submit & Close
                                    </Button>
                                    <Button
                                      variant="contained"
                                      onClick={() =>
                                        updateAnswer(
                                          row.fld_studentid,
                                          row.fld_id
                                        )
                                      }
                                    >
                                      Post Reply
                                    </Button>
                                  </div>
                                </>
                              )}
                              {row.fld_status == "Closed" && (
                                <div className="d-flex justify-content-end mt-3">
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() =>
                                      closeTicket(row.fld_id, "Delete")
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              )}
                            </div>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={List.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </div>
    </>
  );
}
