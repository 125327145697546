import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable, MDBCol } from "mdbreact";
import {
  Breadcrumbs,
  TextField,
  Link,
  Tooltip,
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import CancelIcon from "@mui/icons-material/Cancel";
import { BsLockFill } from "react-icons/bs";
import { BsUnlockFill } from "react-icons/bs";
import Notiflix from "notiflix";
import moment from "moment";
import PostApiCall from "../../../assets/API/PostApi";
import store from "../../../store";

import GetApiCall from "../../../assets/API/GetApi";
import ReactApexChart from "react-apexcharts";

import ListRegistrationIcon from "../../../assets/icons/list_registration.svg";
import ShortlistedIcon from "../../../assets/icons/shortlisted.svg";
import TotalScholarsIcon from "../../../assets/icons/totalscholars.svg";
import RejectedRegistrationIcon from "../../../assets/icons/rejected_registartion.svg";
import NewRegistrationIcon from "../../../assets/icons/new_register.svg";
import { Avatar } from "@mui/material";
import { Eye } from "react-feather";

export default function Dashboard() {
  const history = useHistory();
  const login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const { setexpenseData, NotificationData } = useContext(store);
  const [allCounts, setAllCounts] = useState({});
  const [courseRatio, setCourseRatio] = useState({});
  const [stateRatio, setStateRatio] = useState({});
  const [genderRatio, setGenderRatio] = useState({});
  const [registrationCount, setRegistrationCount] = useState({});
  const [expenstBarGraphData, setexpenstBarGraphData] = useState(null);
  const [NewExpenseList, setNewExpenseList] = useState([]);
  const [expenseFields, setexpenseFields] = useState([]);
  const [expenseDetail, setexpenseDetail] = useState(null);
  const [showModal, setshowModal] = useState(false);
  // track expense graph data
  const [filterExpenseList, setfilterExpenseList] = useState([]);
  const [SelectfilterExpenseList, setSelectfilterExpenseList] = useState("1");
  const [latestQuestions, setLatestQuestions] = useState([]);
  const [SelectYearMonth, setSelectYearMonth] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  const [showHideModal, setshowHideModal] = useState(false);
  const [holdReason, setholdReason] = useState("");
  const [expenseData, setExpenseData] = useState({})

  // hold years' data
  let yearData = [];
  for (var i = new Date().getFullYear(); i >= 2016; i--) {
    yearData.push(i);
  }

  ////////////////////////////////////////////////////////////// Handlers //////////////////////////////////////////////////////////////////////////////////////////////
  // expense details || click specific
  const getScholarExpenseDetails = (data) => {
    PostApiCall.postRequest(
      {
        whereClause: `where expense_master_id=${data.fld_id} and fld_studentId=${data.fld_studentId} order by fld_sequence asc`,
      },
      "Get_Scholar_Expense_Details"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setexpenseFields(
            obj.data.map((val) => ({
              fld_field_name: val.fld_field_name,
              fld_value: val.fld_answer,
            }))
          );
          Notiflix.Loading.remove();
        }
      })
    );
  };

  //approval list
  const getApprovalList = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        // whereClause: `where exists(select 1 from udv_expenseapprovalhistory eh where eh.fld_expense_id = ei.fld_id and eh.fld_pending_with='${login.fld_type}' and eh.fld_status = 'Open') order by fld_id desc`,
        whereClause: `where fld_approval_status='${login.fld_type == "Checker"
          ? "Pending"
          : login.fld_type == "Approver"
            ? "Verified"
            : login.fld_type == "Accountant"
              ? "Approved"
              : null
          }' order by fld_id desc `,
      },
      "Get_ScholarExpenseInformation"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setNewExpenseList(obj.data);
        }
        Notiflix.Loading.remove();
      })
    );
  };

  // handler to get filtered expense list
  const getExpenseReportList = (data) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause:
          data == 2
            ? `where datepart(week,fld_requestedon) =datepart(week ,getdate())` // weekly data
            : data == 3
              ? `where datepart(month,fld_requestedon) =datepart(month,getdate())` // monthly daya
              : data == 4
                ? `where datepart(year,fld_requestedon) =datepart(year ,getdate())` // yearly data
                : `where fld_requestedon =concat(convert(varchar, getdate(), 107), ' ', Format(GetDate(), 'hh:mm tt'))`, // daily data
      },
      "Admin_Get_Expense_Report_List"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setfilterExpenseList(obj.data);
        }
        Notiflix.Loading.remove();
      })
    );
  };

  // questions list || called inside useEffect || non-click specific
  const getLatestQuestions = () => {
    GetApiCall.getRequest("GetQuestions").then((results) =>
      results.json().then((obj) => {
        var objdata = obj.data;
        setLatestQuestions(objdata.reverse());
        // console.log(objdata);
        Notiflix.Loading.remove();
      })
    );
  };
  ////////////////////////////////////////////////////////////// Handlers End ////////////////////////////////////////////////////////////////////////////////////////////////

  // render approval|expense|quuestions
  useEffect(() => {
    getApprovalList();
    getExpenseReportList();
    getLatestQuestions();
  }, []);

  // for expense graph
  useEffect(() => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        year: SelectYearMonth.year,
        month: SelectYearMonth.month,
      },
      "Admin_Get_Expense_Report_Dashboard"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setexpenstBarGraphData(
            obj.data.length > 0
              ? {
                name: obj.data.map((val) => val.fld_expense_type),
                value: obj.data.map((val) => val.fee_amount),
              }
              : null
          );
        }
        Notiflix.Loading.remove();
      })
    );
  }, [SelectYearMonth]);

  // dashboard counts'
  useEffect(() => {
    GetApiCall.getRequest("GetDashboardCountByDate").then((resultdes) =>
      resultdes.json().then((objuser) => {
        setAllCounts({
          listOfRegistration: objuser.data[0].TotalStudents,
          publishedStudents: objuser.data[0].PublishedStudents,
          shortedStudents: objuser.data[0].ShortedStudents,
          totalScholars: objuser.data[0].TotalScholars,
          rejectedStudents: objuser.data[0].RejectedStudents,
          newAllRegistration: objuser.data[0].currentyearstudent,
        });
      })
    );
  }, []);

  /////////////////////////////////////////////////////////////////// Chart section data fetch ////////////////////////////////////////////////////////////////////////////////////
  // state ratio chart data
  useEffect(() => {
    GetApiCall.getRequest("Report_Get_StateRatio").then((resultdes) =>
      resultdes.json().then((objuser) => {
        let serie = [];
        let option = [];
        objuser.data.map((data) => {
          serie.push(data.StateCount);
          option.push(data.name);
        });
        let data = {
          stateOption: {
            chart: {
              type: "donut",
            },
            labels: option,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  fill: {
                    type: "gradient",
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
          series: serie,
        };
        setStateRatio(data);
      })
    );
  }, []);

  // course ratio chart data
  useEffect(() => {
    GetApiCall.getRequest("Report_Get_CourseRatio").then((resultdes) =>
      resultdes.json().then((objuser) => {
        let serie = [];
        let option = [];
        objuser.data.map((data) => {
          serie.push(data.CountOf);
          option.push(`${data.fld_courseName.slice(0, 20)}` + "...");
        });
        let data = {
          courseOption: {
            chart: {
              type: "donut",
            },
            labels: option,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  fill: {
                    type: "gradient",
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
          series: serie,
        };
        setCourseRatio(data);
      })
    );
  }, []);

  // gender status chart data
  useEffect(() => {
    GetApiCall.getRequest("Report_Get_GenderRatioReport").then((resultdes) =>
      resultdes.json().then((objuser) => {
        let data = {
          genderOption: {
            chart: {
              type: "donut",
            },
            labels: ["Total Male", "Total Female"],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  fill: {
                    type: "gradient",
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
          series: [objuser.data[0].male, objuser.data[0].female],
        };
        setGenderRatio({
          ...data,
        });
      })
    );
  }, []);

  // expense chart data
  // useEffect(() => {
  //   GetApiCall.getRequest("Admin_Get_Expense_Report_Dashboard").then((res) =>
  //     res.json().then((obj) => {
  //      let name = [];
  //      let value = [];
  //      obj.map((data)=>{
  //       name.push(data.fld_expense_type)
  //       value.push(data.fee_amount)
  //      })

  //      let data = {
  //       expenseOption : {
  //         chart : {
  //           type: "donut",
  //         },
  //         labels : name,
  //         responsive :[
  //           {
  //             breakpoint : 480,
  //             options : {
  //               chart : {
  //                 width:200,
  //               },
  //               fill : {
  //                 type : "gradient"
  //               },
  //               legend  : {
  //                 position : 'bottom',
  //               }
  //             }
  //           }
  //         ]
  //       },
  //       series : value
  //      }
  //      setexpenseData(data)
  //     })
  //   );
  // }, []);

  // Registration status chart data

  useEffect(() => {
    GetApiCall.getRequest("GetDashboardCountByDate").then((resultdes) =>
      resultdes.json().then((objuser) => {
        let registration = {
          registrationOption: {
            chart: {
              type: "donut",
            },
            labels: [
              "Total Registration",
              "Total Scholars",
              "Publish Registration",
              "Rejected Registration",
              "Shorted Registration",
            ],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  fill: {
                    type: "gradient",
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
          series: [
            objuser.data[0].TotalStudents,
            objuser.data[0].TotalScholars,
            objuser.data[0].PublishedStudents,
            objuser.data[0].RejectedStudents,
            objuser.data[0].ShortedStudents,
          ],
        };
        setRegistrationCount({
          ...registration,
        });
      })
    );
  }, []);
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // expense holder handler
  const HoldExpense = (status = null) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        expenseid: expenseDetail.fld_id,
        userid: login.fld_id,
        comments: status == null || holdReason == "" ? null : holdReason,
        status: status,
      },
      "Add_Admin_Expense_Hold"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Expense Hold Successfully.");
          window.location.reload();
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      })
    );
  };

  return (
    <>
      {/* <AppBar position="static" className="mb-4">
        <Tabs
          className="employee-form-tabs"
          value={tab}
          onChange={(event, newValue) => setTab(newValue)}
          textColor="inherit"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Scholar" />
          <Tab label="Student" />
          <Tab label="HRMS" />
          <Tab label="Vendor Management" />
        </Tabs>
      </AppBar> */}

      {/* Greeting section starts */}
      <div className="form-head">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Hi {login.full_name}, Welcome back!</h4>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="text.primary"
              href="/dashboard"
              aria-current="page"
            >
              {" "}
              Dashboard{" "}
            </Link>
          </Breadcrumbs>
        </div>
      </div>
      {/* Greeting section ends */}
      {/* ------------------------------------------------------------ */}
      {/* cards start */}
      <div className="row mt-5">
        {/* New registrations */}
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-3">
          <div className="card-hover p-3">
            <div>
              <div className="d-flex justify-content-between align-content-center">
                <h6 className="personal-info p-0">New Registrations</h6>
                {/* <PeopleAltIcon /> */}
                <img src={NewRegistrationIcon} className="dash-card-icon" />
              </div>
              <h3 className="font-weight-bold" style={{ color: "#fdd999" }}>
                {allCounts.newAllRegistration}
              </h3>
            </div>
          </div>
        </div>
        {/* shortlisted */}
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-3">
          <div className="card-hover p-3">
            <div>
              <div className="d-flex justify-content-between align-content-center">
                <h6 className="personal-info p-0">Shortlisted</h6>
                {/* <TaskAltIcon /> */}
                <img src={ShortlistedIcon} className="dash-card-icon" />
              </div>
              <h3 className="font-weight-bold" style={{ color: "#1976D2" }}>
                {allCounts.shortedStudents}
              </h3>
            </div>
          </div>
        </div>
        {/* total scholars */}
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-3">
          <div className="card-hover p-3">
            <div className="d-flex justify-content-between align-content-center">
              <h6 className="personal-info p-0">Total Scholars</h6>
              {/* <SchoolIcon /> */}
              <img src={TotalScholarsIcon} className="dash-card-icon orange" />
            </div>
            <div>
              <h3 className="font-weight-bold" style={{ color: "#ED6C02" }}>
                {allCounts.totalScholars}
              </h3>
            </div>
          </div>
        </div>
        {/* rejected registration */}
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-3">
          <div className="card-hover p-3">
            <div className="d-flex justify-content-between align-content-center">
              <h6 className="personal-info p-0">Rejected Registrations</h6>
              {/* <HighlightOffIcon /> */}
              <img
                src={RejectedRegistrationIcon}
                className="dash-card-icon purple"
              />
            </div>
            <div>
              <h3 className="font-weight-bold" style={{ color: "#9C27B0" }}>
                {allCounts.rejectedStudents}
              </h3>
            </div>
          </div>
        </div>
        {/* total registrations */}
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-3">
          <div className="card-hover p-3">
            <div>
              <div className="d-flex justify-content-between align-content-center">
                <h6 className="personal-info p-0">Total Registrations</h6>
                {/* <PeopleAltIcon /> */}
                <img
                  src={ListRegistrationIcon}
                  className="dash-card-icon gray"
                />
              </div>
              <h3
                className="font-weight-bold"
                style={{ color: "darkslategrey" }}
              >
                {allCounts.listOfRegistration}
              </h3>
            </div>
          </div>
        </div>
      </div>
      {/* cards end */}

      {/* latest expenses start */}
      <div className="card-vahani p-0 pb-4 mt-5">
        <p className="personal-info p-0 left-line-card px-lg-4 px-4 py-4">
          Latest Expenses
        </p>
        {/* col data */}
        <div className="px-4">
          {NewExpenseList.length !== 0 ? (
            <MDBDataTable
              disableRetreatAfterSorting={true}
              responsive
              hover
              striped
              data={{
                columns: [
                  {
                    label: "Txn ID",
                    field: "txn",
                    sort: "disabled",
                  },
                  {
                    label: "Student ID",
                    field: "studentid",
                    sort: "disabled",
                  },
                  {
                    label: "Name",
                    field: "name",
                    sort: "disabled",
                  },
                  {
                    label: "Expense Type",
                    field: "expenseType",
                    sort: "disabled",
                  },
                  {
                    label: "Amount (₹)",
                    field: "amount",
                    sort: "disabled",
                  },
                  {
                    label: "Raised on",
                    field: "raised",
                    sort: "disabled",
                  },
                  {
                    label: "Payment Details",
                    field: "paymentdetail",
                    sort: "disabled",
                  },
                  {
                    label: "Payment Receipt",
                    field: "paymentReceipt",
                    sort: "disabled",
                  },
                  {
                    label: "Bill Receipt",
                    field: "billReceipt",
                    sort: "disabled",
                  },
                  {
                    label: "Status",
                    field: "status",
                    sort: "disabled",
                  },
                  {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                  },
                ],
                rows: NewExpenseList.map((row, i) => {
                  return {
                    txn: row.fld_id,
                    studentid: row.fld_student_registration_no,
                    name: row.full_name,
                    expenseType: row.fld_expense_type,
                    amount: <p>{row.fld_feeAmount}/-</p>,
                    raised: moment(row.fld_requestedon).format("DD/MM/YYYY"),
                    paymentdetail:
                      row.fld_bankAccountNumber != null ? (
                        row.fld_portalUrl == null ? (
                          <div>
                            <span>AC No. - {row.fld_bankAccountNumber} </span>
                            <br />
                            <span>Name - {row.fld_bankHolderName} </span>
                            <br />
                            <span>Bank - {row.fld_bankName} </span>
                            <br />
                            <span>IFSC - {row.fld_ifscCode} </span>
                            <br />
                          </div>
                        ) : (
                          <div>
                            <span>Portal - {row.fld_portalUrl}</span>
                            <span>Login ID - {row.fld_loginId}</span>
                            <span>Password - {row.portal_password}</span>
                          </div>
                        )
                      ) : (
                        <div></div>
                      ),
                    paymentReceipt: row.fld_adminReceipt ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => window.open(row.fld_adminReceipt)}
                      >
                        View Receipt
                      </button>
                    ) : null,
                    billReceipt: row.fld_billReceipt ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => window.open(row.fld_billReceipt)}
                      >
                        View Receipt
                      </button>
                    ) : null,
                    status: row.fld_approval_status,
                    action: (
                      <>
                        {/* anyone can view the expense details */}
                        <Tooltip title="View">
                          <VisibilityIcon
                            className="text-primary cursor-pointer"
                            onClick={() => {
                              let NotificationDetail = NotificationData.find(
                                (val) => val.fld_expense_id == row.fld_id
                              );
                              if (NotificationDetail) {
                                PostApiCall.postRequest(
                                  {
                                    id: NotificationDetail.fld_id,
                                    status: "Closed",
                                  },
                                  "Update_Expense_Notification"
                                ).then((results) =>
                                  results.json().then((expobj) => {
                                    setexpenseData({
                                      data: row,
                                      action: "view",
                                      source: "admin",
                                      adminAction:
                                        login.fld_type == "Checker"
                                          ? "Pending"
                                          : login.fld_type == "Approver"
                                            ? "Verified"
                                            : login.fld_type == "Accountant" &&
                                            "Approved",
                                    });
                                    history.push("/expense");
                                  })
                                );
                              } else {
                                setexpenseData({
                                  data: row,
                                  action: "view",
                                  source: "admin",
                                  adminAction:
                                    login.fld_type == "Checker"
                                      ? "Pending"
                                      : login.fld_type == "Approver"
                                        ? "Verified"
                                        : login.fld_type == "Accountant" &&
                                        "Approved",
                                });
                                history.push("/expense");
                              }
                              // setexpenseData({ data: row, action: "view", source: "admin", adminAction: login.fld_type == "Checker" ? "Pending" : (login.fld_type == "Approver" ? "Verified" : (login.fld_type == "Accountant" && "Paid")) })
                              // history.push("/expense")
                            }}
                          />
                        </Tooltip>

                        {/* only checker and approver can view these buttons */}
                        {(login.fld_type == "Checker" ||
                          login.fld_type == "Approver") && (
                            <>
                              <Tooltip title="Quick Approve">
                                <OfflineBoltIcon
                                  className="text-primary cursor-pointer mx-2"
                                  onClick={() => {
                                    let NotificationDetail =
                                      NotificationData.find(
                                        (val) => val.fld_expense_id == row.fld_id
                                      );
                                    if (NotificationDetail) {
                                      PostApiCall.postRequest(
                                        {
                                          id: NotificationDetail.fld_id,
                                          status: "Closed",
                                        },
                                        "Update_Expense_Notification"
                                      ).then((results) =>
                                        results.json().then((expobj) => {
                                          getScholarExpenseDetails(row);
                                          setexpenseDetail(row);
                                          setshowModal(true);
                                        })
                                      );
                                    } else {
                                      getScholarExpenseDetails(row);
                                      setexpenseDetail(row);
                                      setshowModal(true);
                                    }
                                  }}
                                />
                              </Tooltip>
                              <Tooltip title="Hold Expense">
                                <span
                                  onClick={() => {
                                    setshowHideModal(true);
                                    setexpenseDetail(row);
                                    setholdReason(
                                      row.fld_hold_comments == null
                                        ? ""
                                        : row.fld_hold_comments
                                    );
                                  }}
                                >
                                  {row.fld_hold == "true" ? (
                                    <BsLockFill
                                      style={{
                                        fontSize: "20px",
                                        cursor: "pointer",
                                        color: "var(--bs-red)",
                                      }}
                                    />
                                  ) : (
                                    <BsUnlockFill
                                      style={{
                                        fontSize: "20px",
                                        cursor: "pointer",
                                        color: "var(--bs-blue)",
                                      }}
                                    />
                                  )}
                                </span>
                              </Tooltip>
                            </>
                          )}
                      </>
                    ),
                  };
                }),
              }}
              entriesOptions={[10, 25, 50, 100]}
            />
          ) : (
            <p>No Data Found</p>
          )}
        </div>

        <Dialog
          open={showModal}
          maxWidth={"sm"}
          onClose={() => setshowModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            className="d-flex justify-content-between"
            id="alert-dialog-title"
          >
            <h4 className="px-2" style={{ borderBottom: "5px solid blue" }}>
              Approval Form
            </h4>{" "}
            <CancelIcon onClick={() => setshowModal(false)} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="row m-0 border  border-secondary rounded-3">
                <h5 className=" mt-2 mb-4 text-center">
                  <b>Expense Type - </b>
                  {expenseDetail?.fld_expense_type}
                </h5>
                {expenseFields.map((val) => {
                  //
                  return (
                    <div
                      className="row m-0 justify-content-between mb-2 border-secondary border-bottom"
                      style={{ wordBreak: "break-all" }}
                    >
                      <b className="col-6">{val?.fld_field_name}</b>
                      <div className="col-6">
                        {" "}
                        :
                        {val?.fld_value.includes("http") ? (
                          <a href={val?.fld_value} target="_blank">
                            {" "}
                            {val?.fld_value}
                          </a>
                        ) : (
                          ` ${val?.fld_value}`
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className=" row m-0 justify-content-between mb-2 border-secondary border-bottom">
                  <b className="col-6">Amount (₹)</b>
                  <div className="col-6">
                    {" "}
                    : {expenseDetail?.fld_feeAmount}/-{" "}
                  </div>
                </div>
                <div className=" row m-0 justify-content-between mb-2 border-secondary border-bottom">
                  <b className="col-6">Amount In Words</b>
                  <div className="col-6"> : {expenseDetail?.amtInWords} </div>
                </div>
                <div className=" row m-0 justify-content-between mb-2 border-secondary border-bottom">
                  <b className="col-6">Pay To</b>
                  <div className="col-6"> : {expenseDetail?.payto} </div>
                </div>
                <div className=" row m-0 justify-content-between mb-2 border-secondary border-bottom">
                  <b className="col-6">Description</b>
                  <div className="col-6"> : {expenseDetail?.fld_details} </div>
                </div>
                {expenseDetail?.fld_hold == "true" ? (
                  <div className="row m-0 justify-content-between mb-3">
                    <button className="col-auto btn btn-danger" disabled>
                      Reject
                    </button>
                    <button className="col-auto btn btn-success" disabled>
                      Approve
                    </button>
                  </div>
                ) : (
                  <div className="row m-0 justify-content-between mb-3">
                    <button
                      className="col-auto btn btn-danger"
                      onClick={() => {
                        setexpenseData({
                          data: expenseDetail,
                          action: "view",
                          source: "admin",
                          adminAction: "Open",
                        });
                        history.push("/expense");
                      }}
                    >
                      Reject
                    </button>
                    <button
                      className="col-auto btn btn-success"
                      onClick={() => {
                        Notiflix.Loading.dots();
                        PostApiCall.postRequest(
                          {
                            expenseid: expenseDetail.fld_id,
                            actionby: login.fld_id,
                            post: login.fld_type,
                            comments: null,
                            action:
                              login.fld_type == "Checker"
                                ? "Verified"
                                : "Approved",
                          },
                          "Update_ScholarExpenseStatus"
                        ).then((results) =>
                          results.json().then((obj) => {
                            if (
                              results.status === 200 ||
                              results.status === 201
                            ) {
                              Notiflix.Loading.remove();
                              Notiflix.Notify.success(
                                "Expense Status Updated Successfully."
                              );
                              setshowModal(false);
                              getApprovalList();
                            }
                            Notiflix.Loading.remove();
                          })
                        );
                      }}
                    >
                      Approve
                    </button>
                  </div>
                )}
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog
          open={showHideModal}
          maxWidth={"sm"}
          onClose={() => setshowHideModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            className="d-flex justify-content-between"
            id="alert-dialog-title"
          >
            <h4 className="px-2" style={{ borderBottom: "5px solid blue" }}>
              {expenseDetail?.fld_hold == "true" ? "Locked" : "Lock"} Request
            </h4>{" "}
            <CancelIcon onClick={() => setshowHideModal(false)} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="row m-0 border p-3  border-secondary rounded-3">
                <TextField
                  id="outlined-basic"
                  label="Reason For Locking..."
                  value={holdReason}
                  onChange={(e) => setholdReason(e.target.value)}
                  variant="outlined"
                  rows={3}
                  multiline
                  fullWidth
                />
                {expenseDetail?.fld_hold == "true" && (
                  <>
                    <h6
                      className="personal-info p-0 mt-3"
                      style={{ fontSize: "14px" }}
                    >
                      Locked by -{" "}
                      <span className="text-secondary">
                        {expenseDetail?.hold_by}
                      </span>{" "}
                    </h6>
                    <h6
                      className="personal-info p-0"
                      style={{ fontSize: "14px", lineHeight: "10px" }}
                    >
                      On -{" "}
                      <span className="text-secondary">
                        {moment(expenseDetail?.fld_hold_date).format(
                          "DD/MM/YYYY"
                        )}
                      </span>{" "}
                    </h6>
                  </>
                )}
                <div className="d-flex justify-content-start p-0 mt-3">
                  {expenseDetail?.fld_hold == "true" && (
                    <button
                      className="btn btn-success me-3"
                      onClick={() => HoldExpense()}
                    >
                      Unlock
                    </button>
                  )}
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      if (holdReason != "") {
                        HoldExpense("true");
                      } else {
                        Notiflix.Notify.failure("Please Enter Reason.");
                      }
                    }}
                  >
                    Lock it
                  </button>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      {/* latest expenses end */}

      {/* Latest questions start */}
      <div className="card-vahani p-0 pb-4 mt-5">
        <p className="personal-info p-0 left-line-card px-lg-4 px-4 py-4">
          Latest Questions
        </p>
        <div className="p-3">
          {latestQuestions.length > 0 ? (
            <MDBDataTable
              disableRetreatAfterSorting={true}
              responsive
              hover
              striped
              data={{
                columns: [
                  {
                    label: "Questioner",
                    field: "questioner",
                    sort: "disabled",
                    width: 400,
                  },
                  {
                    label: "Question",
                    field: "question",
                    sort: "disabled",
                    width: 400,
                  },
                  {
                    label: "Raised On",
                    field: "raisedon",
                    sort: "disabled",
                    width: 150,
                  },
                  {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 150,
                  },
                ],
                rows: latestQuestions.slice(0, 3).map((data, i) => {
                  return {
                    questioner: (
                      <Avatar
                        sx={{ width: 40, height: 40 }}
                        alt="questioner_img"
                        src={data.fld_questioner_profile_pic}
                      />
                    ),

                    question: data.fld_question,
                    raisedon: moment(data.fld_createdon).format("DD/MM/YYYY"),
                    action: (
                      <Eye
                        className="text-primary action-pointer"
                        onClick={() => history.push("/discussion-forum")}
                      />
                    ),
                  };
                }),
              }}
              entriesOptions={[10, 25, 50, 100]}
            />
          ) : (
            <p className="m-3">No Data Found</p>
          )}
        </div>
      </div>
      {/* Latest questions end */}

      {/* Expense list start */}
      <div className="card-vahani p-0 pb-4 mt-5">
        <div className="row justify-content-between align-items-center m-0 left-line-card px-4 pb-3 pt-3 mb-3">
          <p className="personal-info p-0 col-auto mb-3">Expense List</p>
          <FormControl
            sx={{ m: 1, minWidth: 120 }}
            className="col-auto"
            size="small"
          >
            <InputLabel>Filter By</InputLabel>
            <Select
              value={SelectfilterExpenseList}
              label="Filter By"
              onChange={(e) => {
                getExpenseReportList(e.target.value);
                setSelectfilterExpenseList(e.target.value);
              }}
            >
              <MenuItem value={1}>Today</MenuItem>
              <MenuItem value={2}>Week</MenuItem>
              <MenuItem value={3}>Month</MenuItem>
              <MenuItem value={4}>Year</MenuItem>
            </Select>
          </FormControl>
        </div>
        {/* mapping expenses */}
        <div className="px-4 ">
          {filterExpenseList.length !== 0 ? (
            <MDBDataTable
              disableRetreatAfterSorting={true}
              responsive
              hover
              striped
              data={{
                columns: [
                  {
                    label: "Txn ID",
                    field: "txn",
                    sort: "disabled",
                  },
                  {
                    label: "Student ID",
                    field: "studentid",
                    sort: "disabled",
                  },
                  {
                    label: "Name",
                    field: "name",
                    sort: "disabled",
                  },
                  {
                    label: "Expense Type",
                    field: "expenseType",
                    sort: "disabled",
                  },
                  {
                    label: "Amount (₹)",
                    field: "amount",
                    sort: "disabled",
                  },
                  {
                    label: "Raised on",
                    field: "raised",
                    sort: "disabled",
                  },
                  {
                    label: "Payment Details",
                    field: "paymentdetail",
                    sort: "disabled",
                  },
                  {
                    label: "Payment Receipt",
                    field: "paymentReceipt",
                    sort: "disabled",
                  },
                  {
                    label: "Bill Receipt",
                    field: "billReceipt",
                    sort: "disabled",
                  },
                  {
                    label: "Status",
                    field: "status",
                    sort: "disabled",
                  },
                ],
                rows: filterExpenseList.map((row, i) => {
                  return {
                    txn: row.fld_id,
                    studentid: row.fld_student_registration_no,
                    name: row.full_name,
                    expenseType: row.fld_expense_type,
                    amount: <p>{row.fld_feeAmount}/-</p>,
                    raised: moment(row.fld_requestedon).format("DD/MM/YYYY"),
                    paymentdetail:
                      row.fld_bankAccountNumber != null ? (
                        row.fld_portalUrl == null ? (
                          <div>
                            <span>AC No. - {row.fld_bankAccountNumber} </span>
                            <br />
                            <span>Name - {row.fld_bankHolderName} </span>
                            <br />
                            <span>Bank - {row.fld_bankName} </span>
                            <br />
                            <span>IFSC - {row.fld_ifscCode} </span>
                            <br />
                          </div>
                        ) : (
                          <div>
                            <span>Portal - {row.fld_portalUrl}</span>
                            <span>Login ID - {row.fld_loginId}</span>
                            <span>Password - {row.portal_password}</span>
                          </div>
                        )
                      ) : (
                        <div></div>
                      ),
                    paymentReceipt: row.fld_adminReceipt ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => window.open(row.fld_adminReceipt)}
                      >
                        View Receipt
                      </button>
                    ) : null,
                    billReceipt: row.fld_billReceipt ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => window.open(row.fld_billReceipt)}
                      >
                        View Receipt
                      </button>
                    ) : null,
                    status: row.fld_approval_status,
                  };
                }),
              }}
              entriesOptions={[10, 25, 50, 100]}
            />
          ) : (
            <p>No Data Found</p>
          )}
        </div>
      </div>
      {/* Expense list end*/}

      {/* Expense graph start */}
      <div className="row mt-4 mb-lg-3 mobile-personal">
        <div className="col-md-12 mb-lg-4">
          <div className="card-vahani p-0">
            <div className="row justify-content-between align-items-center m-0 left-line-card px-4 pb-1 pt-3">
              <p className="personal-info p-0 col-auto ">Expense Graph</p>
              <div className="col-auto">
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  className="col-auto"
                  size="small"
                >
                  <InputLabel>Month</InputLabel>
                  <Select
                    label="Month"
                    value={SelectYearMonth.month}
                    onChange={(e) => {
                      setSelectYearMonth({
                        ...SelectYearMonth,
                        month: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="1">Jan</MenuItem>
                    <MenuItem value="2">Feb</MenuItem>
                    <MenuItem value="3">Mar</MenuItem>
                    <MenuItem value="4">Apr</MenuItem>
                    <MenuItem value="5">May</MenuItem>
                    <MenuItem value="6">Jun</MenuItem>
                    <MenuItem value="7">Jul</MenuItem>
                    <MenuItem value="8">Aug</MenuItem>
                    <MenuItem value="9">Sep</MenuItem>
                    <MenuItem value="10">Oct</MenuItem>
                    <MenuItem value="11">Nov</MenuItem>
                    <MenuItem value="12">Dec</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  className="col-auto"
                  size="small"
                >
                  <InputLabel>Year</InputLabel>
                  <Select
                    value={new Date().getFullYear()}
                    label="Filter By"
                    onChange={(e) => {
                      setSelectYearMonth({
                        ...SelectYearMonth,
                        year: e.target.value,
                      });
                    }}
                  >
                    {yearData.map((val) => (
                      <MenuItem value={val}>{val}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div id="chart">
              {expenstBarGraphData != null ? (
                <ReactApexChart
                  options={{
                    chart: { type: "bar" },
                    plotOptions: {
                      bar: { columnWidth: "50%", distributed: true },
                    },
                    legend: { show: false },
                    dataLabels: { enabled: false },
                    xaxis: {
                      categories: expenstBarGraphData.name,
                      labels: { style: { fontSize: "12px" } },
                    },
                  }}
                  series={[{ data: expenstBarGraphData.value }]}
                  type="bar"
                  height={300}
                />
              ) : (
                <p className="p-3">No Data Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Expense graph end */}

      {/* /////////////////////////////////////////////////////////////////////////////////// Chart section start ///////////////////////////////////////////////////////////////////// */}
      <div class="row">
        {/* Expense ratio chart */}
        <div className="col-xl-6 col-lg-6 mb-lg-3 mobile-personal">
          <div className="card-vahani p-0">
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              Total Expense
            </p>
            <div id="chart">
              {registrationCount.series && (
                <ReactApexChart
                  options={registrationCount.registrationOption}
                  series={registrationCount.series}
                  type="donut"
                />
              )}
            </div>
          </div>
        </div>

        {/* Registration ratio chart */}
        <div className="col-xl-6 col-lg-6 mb-lg-3 mobile-personal">
          <div className="card-vahani p-0">
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              {" "}
              Registration Status
            </p>
            <div id="chart">
              {registrationCount.series && (
                <ReactApexChart
                  options={registrationCount.registrationOption}
                  series={registrationCount.series}
                  type="donut"
                />
              )}
            </div>
          </div>
        </div>

        {/* Course ratio chart */}
        <div className="col-xl-6 col-lg-6 mb-lg-3 mobile-personal">
          <div className="card-vahani p-0">
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              Course Ratio
            </p>
            <div id="chart">
              {courseRatio.series && (
                <ReactApexChart
                  options={courseRatio.courseOption}
                  series={courseRatio.series}
                  type="donut"
                />
              )}
            </div>
          </div>
        </div>

        {/* State ratio chart */}
        <div className="col-xl-6 col-lg-6 mb-lg-3 mobile-personal">
          <div className="card-vahani p-0">
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              {" "}
              State Ratio
            </p>
            <div id="chart">
              {stateRatio.series && (
                <ReactApexChart
                  options={stateRatio.stateOption}
                  series={stateRatio.series}
                  type="donut"
                />
              )}
            </div>
          </div>
        </div>

        {/* Gender ratio chart */}
        <div className="col-xl-6 col-lg-6 mb-lg-3 mobile-personal">
          <div className="card-vahani p-0">
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              Gender Ratio Status
            </p>
            <div id="chart">
              {genderRatio.series && (
                <ReactApexChart
                  options={genderRatio.genderOption}
                  series={genderRatio.series}
                  type="donut"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* /////////////////////////////////////////////////////////////////////////////////// Chart section end ///////////////////////////////////////////////////////////////////// */}
    </>
  );
}
