import "../Personalinfo/Personalinfo.css";
import React, { useState, useContext } from "react";
import { FormControlLabel, FormControl, TextField, Button, Select, MenuItem, FormHelperText, InputLabel } from "@mui/material";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
// import GetApiCall from "../../../assets/API/GetApi";
import moment from "moment";
import Notiflix from "notiflix";
export default function Additional_application() {
  //
  var login = localStorage.getItem("LoginDetail");
  var details = JSON.parse(login);
  const [values, setValues] = React.useState({
    fld_specialEducation: "",
    fld_otherScholarshipProgramme: "",
  });
  const onChangeHandler = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  React.useEffect(() => {
    GetStudentAdditionalApplicant();
  }, []);
  const GetStudentAdditionalApplicant = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        studentId: details[0].fld_id,
      },
      "GetStudentAdditionalApplicantInformation_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.remove();
          if (obj.data.length !== 0) {
            reset(obj.data[0]);
            setValues(obj.data[0]);
          }
        }
      })
    );
  };
  const onPost = (addap) => {
    Notiflix.Loading.dots();
    // var Otherscholarship = "";
    // if (addap.fld_otherScholarshipProgramme == "Yes") {
    //   Otherscholarship =
    //     addap.fld_otherScholarshipProgramme + "," + addap.Specify;
    // } else {
    //   Otherscholarship = addap.fld_otherScholarshipProgramme;
    // }
    PostApiCall.postRequest(
      {
        studentId: details[0].fld_id,
        medicalConcerns: addap.fld_medicalConcerns,
        specialEducation: addap.fld_specialEducation,
        otherScholarshipProgramme: addap.fld_otherScholarshipProgramme,
        fldotherschdtl: addap.fld_other_sch_dtl,
        updatedby: details[0].fld_id,
        updatedon: null,
      },
      "UpdateStudentAdditionalApplicantInformation"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Saved and Updated Successfully");
          GetStudentAdditionalApplicant();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (addap) => {
    // e.preventDefault();
    onPost(addap);
  };
  return (
    <>
      <div>
        <div className="form-head">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Additional Applicant Information</h4>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p className="personal-info left-line-card p-4">Additional Information</p>
            <div className="row p-4">
              <div className="col-lg-12 col-12 mb-3">
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Are There Any Medical / Psychological Concerns That Vahani Scholarship Should Be Aware of?*"
                  multiline
                  style={{ width: "100%" }}
                  {...register("fld_medicalConcerns", {
                    required: "This field is required",
                  })}
                  error={!!errors?.fld_medicalConcerns}
                  helperText={
                    errors?.fld_medicalConcerns
                      ? errors.fld_medicalConcerns.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-md-6 mb-3">
                <FormControl fullWidth error={Boolean(errors.fld_specialEducation)}>
                  <InputLabel>Do You Have Any Special Education Needs?*</InputLabel>
                  <Select
                    {...register("fld_specialEducation", { required: "This field is required", })}
                    value={watch("fld_specialEducation") ? watch("fld_specialEducation") : ""}
                    label="Do You Have Any Special Education Needs?*"
                  // onChange={onChangeHandler}
                  >
                    <MenuItem value="Yes" >Yes</MenuItem>
                    <MenuItem value="No" >No</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.fld_specialEducation ? errors.fld_specialEducation.message : null}</FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-6 mb-3">
                <FormControl fullWidth error={Boolean(errors.fld_otherScholarshipProgramme)}>
                  <InputLabel> Have You Applied To Any Other Scholarship Programme?*</InputLabel>
                  <Select
                    {...register("fld_otherScholarshipProgramme", { required: "This field is required", })}
                    value={watch("fld_otherScholarshipProgramme") ? watch("fld_otherScholarshipProgramme") : ""}
                    label="Have You Applied To Any Other Scholarship Programme?*"
                    onChange={e => setValue("fld_otherScholarshipProgramme", e.target.value)}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No" >No</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.fld_otherScholarshipProgramme ? errors.fld_otherScholarshipProgramme.message : null}</FormHelperText>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="card-vahani" style={{ marginTop: "26px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <div className="but-width-form">
                <Button
                  variant="contained"
                  className="but-login-vahani"
                  type="submit"
                >
                  Save and update
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
