import "../Personalinfo/Personalinfo.css";
import React, { useState, useContext } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ButtonB, { ButtonProps } from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import GetApiCall from "../../../assets/API/GetApi";
import PostApiCall from "../../../assets/API/PostApi";
import Notiflix from "notiflix";
import moment from "moment";
export default function Address({ stdid, action }) {
  var login = stdid
    ? { fld_id: stdid }
    : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  var ValidationList = JSON.parse(localStorage.getItem("Validation"));
  const [country, setCountry] = useState([]);
  const [countryid, setCountryid] = useState("");
  const [countryp, setCountryp] = useState([]);
  const [countryidp, setCountryidp] = useState("");
  const [st, setSt] = useState([]);
  const [stp, setStp] = useState([]);
  const [stateid, setStateid] = useState("");
  const [stateidp, setStateidp] = useState("");
  const [city, setCity] = useState([]);
  const [cityp, setCityp] = useState([]);
  const [cityid, setCityid] = useState("");
  const [cityidp, setCityidp] = useState("");
  const [data, setData] = React.useState("");
  // const [stateData, setStatedata] = React.useState([]);
  // const [values, setValues] = React.useState({
  //   CountryPermanentData: "",
  //   CountryPermanentId: "",
  //   StateId: "",
  // });
  //select
  const [show, setshow] = React.useState(true);
  const lab = (e) => {
    //
    setshow(!show);
  };
  React.useEffect(() => {
    // Notiflix.Loading.dots();
    GetStudentAddress();
  }, []);
  const GetStudentAddress = () => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "GetStudentAddressInformation_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.remove();
          setData(obj.data[0]);
          //
          reset(obj.data[0]);
          setCountryid(obj.data[0].fld_currentCountry);
          setStateid(obj.data[0].fld_currentState);
          setCityid(obj.data[0].fld_currentCity);
          setCountryidp(obj.data[0].fld_permanentCountry);
          setStateidp(obj.data[0].fld_permanentState);
          setCityidp(obj.data[0].fld_permanentCity);
        }
      })
    );
  };
  //https://admin.vahanischolarship.in/API/GetCity
  // --------------------------country---------------------
  React.useEffect(() => {
    GetApiCall.getRequest("GetCountry").then((resultdes) =>
      resultdes.json().then((obj) => {
        setCountry(obj.data);
      })
    );
  }, [countryid]);
  const handlecountry = (event) => {
    const getcountryid = event.target.value;
    //
    setCountryid(getcountryid);
  };
  React.useEffect(() => {
    if (countryid) {
      const asyncPostCall = async () => {
        try {
          const response = await fetch(
            "https://admin.vahanischolarship.in/API/GetState",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                countryid: countryid,
              }),
            }
          );
          const data = await response.json();
          //
          setSt(data.data);
        } catch (error) {
          //
        }
      };
      asyncPostCall();
    }
    const getstate = async () => {
      const resstate = await fetch(
        `https://admin.vahanischolarship.in/API/GetState`
      );
      const resst = await resstate.json();
      setSt(await resst);
    };
    getstate();
  }, [countryid]);
  const handlestate = (event) => {
    const getstateid = event.target.value;
    setStateid(getstateid);
  };
  React.useEffect(() => {
    if (stateid) {
      const asyncPostCall = async () => {
        try {
          const response = await fetch(
            "https://admin.vahanischolarship.in/API/GetCity",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                stateid: stateid,
              }),
            }
          );
          const data = await response.json();
          //
          setCity(data.data);
        } catch (error) {
          //
        }
      };
      asyncPostCall();
    }
  }, [stateid]);
  const handlecity = (e) => {
    const getcityid = e.target.value;
    setCityid(getcityid);
    //
  };
  // --------------2ndaddress--------------------
  React.useEffect(() => {
    const getcountryp = async () => {
      const rescountry = await fetch(
        "https://admin.vahanischolarship.in/API/GetCountry"
      );
      const rescon = await rescountry.json();
      setCountryp(await rescon.data);
    };
    getcountryp();
  }, [countryidp]);
  const handlecountryp = (event) => {
    const getcountryidp = event.target.value;
    // //
    setCountryidp(getcountryidp);
  };
  React.useEffect(() => {
    if (countryidp) {
      const asyncPostCallp = async () => {
        try {
          const response = await fetch(
            "https://admin.vahanischolarship.in/API/GetState",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                countryid: countryidp,
              }),
            }
          );
          const data = await response.json();
          //
          setStp(data.data);
        } catch (error) {
          //
        }
      };
      asyncPostCallp();
    }
    const getstatep = async () => {
      const resstatep = await fetch(
        `https://admin.vahanischolarship.in/API/GetState`
      );
      const resstp = await resstatep.json();
      setSt(await resstp);
    };
    getstatep();
  }, [countryidp]);
  const handlestatep = (event) => {
    const getstateidp = event.target.value;
    setStateidp(getstateidp);
  };
  React.useEffect(() => {
    if (stateidp) {
      const asyncPostCallp = async () => {
        try {
          const response = await fetch(
            "https://admin.vahanischolarship.in/API/GetCity",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                stateid: stateidp,
              }),
            }
          );
          const data = await response.json();
          //
          setCityp(data.data);
        } catch (error) {
          //
        }
      };
      asyncPostCallp();
    }
  }, [stateidp]);
  const handlecityp = (e) => {
    const getcityidp = e.target.value;
    setCityidp(getcityidp);
    //
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // -------------------postdata-------------------------
  const onPost = (address) => {
    Notiflix.Loading.dots();
    var detail = JSON.parse(localStorage.getItem("LoginDetail"))[0];
    if (!show) {
      var permanentAddresss = address.fld_currentAddress;
      var permanentCountry = address.fld_currentCountry;
      var permanentState = address.fld_currentState;
      var permanentCity = address.fld_currentCity;
      var permanentPincode = address.fld_currentPincode;
    } else {
      permanentAddresss = address.fld_permanentAddress;
      permanentCountry = address.fld_permanentCountry;
      permanentState = address.fld_permanentState;
      permanentCity = address.fld_permanentCity;
      permanentPincode = address.fld_permanentPincode;
    }
    // //
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
        currentAddress: address.fld_currentAddress,
        currentCountry: address.fld_currentCountry,
        currentState: address.fld_currentState,
        currentCity: address.fld_currentCity,
        currentPincode: address.fld_currentPincode,
        permanentAddress: permanentAddresss,
        permanentCountry: permanentCountry,
        permanentState: permanentState,
        permanentCity: permanentCity,
        permanentPincode: permanentPincode,
        updatedOn: moment().format("lll"),
        updatedBy: detail.fld_id,
      },
      "UpdateStudentAddressInformation"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Saved and Updated Successfully");
          GetStudentAddress();
          // window.location.reload();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Something went wrong. Please try again.");
        }
      })
    );
  };
  const onSubmit = (address) => {
    // e.preventDefault();
    onPost(address);
    //
  };
  return (
    <>
      <div>
        <div className="form-head">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Address</h4>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p className="personal-info left-line-card px-lg-4 px-3 py-4">
              Current Address
            </p>
            <div className="row m-0 p-lg-4 p-3">
              <div className="col-lg-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  label="Address*"
                  multiline
                  maxRows={4}
                  variant="outlined"
                  {...register("fld_currentAddress", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[3].fld_reg_ex, "i"),
                      message: "Enter a valid address",
                    },
                  })}
                  error={!!errors?.fld_currentAddress}
                  helperText={
                    errors?.fld_currentAddress
                      ? errors.fld_currentAddress.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-3 col-12 mb-lg-3 mobile-personal">
                <FormControl
                  fullWidth
                  error={Boolean(errors.fld_currentCountry)}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    error={Boolean(errors.fld_currentCountry)}
                  >
                    Country*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    label="Country*"
                    {...register("fld_currentCountry", {
                      required: "This field is required",
                    })}
                    error={!!errors?.fld_currentCountry}
                    onChange={handlecountry}
                    value={countryid}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {country.map((record, item) => {
                      return (
                        <MenuItem value={record.value}>{record.label}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_currentCountry
                      ? errors.fld_currentCountry.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-3 col-12 mb-lg-3 mobile-personal">
                <FormControl fullWidth error={Boolean(errors.fld_currentState)}>
                  <InputLabel id="demo-simple-select-label">State*</InputLabel>
                  <Select
                    labelId="label-state"
                    id="id-state"
                    label="State*"
                    {...register("fld_currentState", {
                      required: "This field is required",
                    })}
                    error={!!errors?.fld_currentState}
                    onChange={handlestate}
                    value={stateid}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {st.map((record, item) => {
                      return (
                        <MenuItem value={record.value}>{record.label}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_currentState
                      ? errors.fld_currentState.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-3 col-12 mb-lg-3 mobile-personal">
                <FormControl fullWidth error={Boolean(errors.fld_currentCity)}>
                  <InputLabel id="label-id-city">City*</InputLabel>
                  <Select
                    labelId="label-city"
                    id="id-city"
                    {...register("fld_currentCity", {
                      required: "This field is required",
                    })}
                    error={!!errors?.fld_currentCity}
                    onChange={handlecity}
                    value={cityid}
                    label="City*"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {city.map((record, item) => {
                      return (
                        <MenuItem value={record.value}>{record.label}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_currentCity
                      ? errors.fld_currentCity.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-3 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Pincode*"
                  type="text"
                  variant="outlined"
                  {...register("fld_currentPincode", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[4].fld_reg_ex, "i"),
                      message: "Enter a valid pincode",
                    },
                    minLength: {
                      value: 6,
                      message: "Enter minimum 6-digit number",
                    },
                  })}
                  error={!!errors?.fld_currentPincode}
                  helperText={
                    errors?.fld_currentPincode
                      ? errors.fld_currentPincode.message
                      : null
                  }
                  inputProps={{
                    maxLength: 6,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="lab"
                  id="flexCheckChecked"
                  onChange={lab}
                />
                <label class="form-check-label" for="flexCheckChecked">
                  Permanent Address is same as current address
                </label>
              </div>
            </div>
          </div>
          {show && (
            <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
              <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                Permanent Address
              </p>
              <div className="row m-0 p-lg-4 p-3">
                <div className="col-lg-12 mb-lg-3 mobile-personal">
                  <TextField
                    fullWidth
                    id="standard-multiline-flexible"
                    label="Address*"
                    multiline
                    maxRows={4}
                    variant="outlined"
                    style={{ width: "100%" }}
                    {...register("fld_permanentAddress", {
                      required: "This field is required",
                      pattern: {
                        value: new RegExp(ValidationList[3].fld_reg_ex, "i"),
                        message: "Enter a valid address",
                      },
                    })}
                    error={!!errors?.fld_permanentAddress}
                    helperText={
                      errors?.fld_permanentAddress
                        ? errors.fld_permanentAddress.message
                        : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-lg-3 col-12 mb-lg-3 mobile-personal">
                  <FormControl
                    fullWidth
                    error={Boolean(errors.fld_permanentCountry)}
                  >
                    <InputLabel error={Boolean(errors.fld_permanentCountry)}>
                      Country*
                    </InputLabel>
                    <Select
                      label="Country*"
                      {...register("fld_permanentCountry", {
                        required: "This field is required",
                      })}
                      error={!!errors?.fld_permanentCountry}
                      onChange={handlecountryp}
                      value={countryidp}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {countryp.map((record, item) => {
                        return (
                          <MenuItem value={record.value}>
                            {record.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {errors?.fld_permanentCountry
                        ? errors.fld_permanentCountry.message
                        : null}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-lg-3 col-12 mb-lg-3 mobile-personal">
                  <FormControl
                    fullWidth
                    error={Boolean(errors.fld_currentState)}
                  >
                    <InputLabel id="demo-simple-select-label">
                      State*
                    </InputLabel>
                    <Select
                      labelId="label-state"
                      id="id-state"
                      label="State*"
                      {...register("fld_permanentState", {
                        required: "This field is required",
                      })}
                      error={errors?.fld_permanentState}
                      helperText={
                        errors?.fld_permanentState
                          ? errors.fld_permanentState.message
                          : null
                      }
                      onChange={handlestatep}
                      value={stateidp}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {stp.map((record, item) => {
                        return (
                          <MenuItem value={record.value}>
                            {record.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {errors?.fld_permanentState
                        ? errors.fld_permanentState.message
                        : null}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-lg-3 mb-lg-3 mobile-personal">
                  <FormControl
                    fullWidth
                    error={Boolean(errors.fld_permanentCity)}
                  >
                    <InputLabel id="label-id-city">City*</InputLabel>
                    <Select
                      labelId="label-city"
                      id="id-city"
                      {...register("fld_permanentCity", {
                        required: "This field is required",
                      })}
                      error={errors?.fld_permanentCity}
                      onChange={handlecityp}
                      value={cityidp}
                      label="City*"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {cityp.map((record, item) => {
                        return (
                          <MenuItem value={record.value}>
                            {record.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {errors?.fld_permanentCity
                        ? errors.fld_permanentCity.message
                        : null}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-lg-3 col-12 mb-lg-3 mobile-personal">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Pincode*"
                    type="text"
                    variant="outlined"
                    className="text-field"
                    {...register("fld_permanentPincode", {
                      required: "This field is required",
                      pattern: {
                        value: new RegExp(ValidationList[4].fld_reg_ex, "i"),
                        message: "Enter a valid pincode",
                      },
                      minLength: {
                        value: 6,
                        message: "Enter minimum 6-digit number",
                      },
                    })}
                    error={!!errors?.fld_permanentPincode}
                    helperText={
                      errors?.fld_permanentPincode
                        ? errors.fld_permanentPincode.message
                        : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 6,
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {action != "view" && (
            <div className="card-vahani" style={{ marginTop: "26px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  // padding: "24px 0px",
                }}
              >
                <div className="but-width-form">
                  <ButtonB
                    variant="contained"
                    className="but-login-vahani"
                    type="submit"
                  >
                    Save and updated
                  </ButtonB>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
