import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
import moment from "moment";
import Notiflix from "notiflix";
import Modal from "react-bootstrap/Modal";

import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
export default function MessBank({ stdid, action }) {
  var login = stdid
    ? { fld_id: stdid }
    : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  var ValidationList = JSON.parse(localStorage.getItem("Validation"));
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [Type, setType] = useState("bank");
  const [CheckUpload, setCheckUpload] = useState(null);
  const [CheckUploadUrl, setCheckUploadUrl] = useState("/upload.jpg");
  const [bankDetailsVerification, setBankDetailsVerification] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const GetMessBankDetail = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "GetScholarMessBankAcountInformation_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (
          (results.status == 200 || results.status == 201) &&
          obj.data.length > 0
        ) {
          PostApiCall.postRequest(
            {
              studentId: login.fld_id,
              bankaccno: obj.data[0].fld_accountNum,
            },
            "BankDetailsVerfication"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                if (obj.data.length != 0) {
                  var bankdetailsVerification = false;
                  for (var i = 0; i < obj.data.length; i++) {
                    if (
                      obj.data[0].fld_approval_status === "Paid" ||
                      obj.data[0].fld_approval_status === "Closed"
                    ) {
                      bankdetailsVerification = false;
                      break;
                    } else {
                      bankdetailsVerification = true;
                    }
                  }

                  setBankDetailsVerification(bankdetailsVerification);
                } else {
                  Notiflix.Loading.remove();
                  setBankDetailsVerification(true);
                }
              } else {
                setBankDetailsVerification(true);
              }
            })
          );
          reset(obj.data[0]);
          setCheckUploadUrl(
            obj.data[0]?.fld_cancelCheque == null
              ? CheckUploadUrl
              : obj.data[0].fld_cancelCheque
          );
          Notiflix.Loading.remove();
        } else {
          setBankDetailsVerification(true);
        }
        reset(obj.data[0]);
        Notiflix.Loading.remove();
      })
    );
  };

  React.useEffect(() => {
    GetMessBankDetail();
  }, []);
  const onSave = async (data) => {
    const UpdateData = (data) => {
      Notiflix.Loading.dots();
      var detail = JSON.parse(localStorage.getItem("LoginDetail"))[0];
      PostApiCall.postRequest(
        {
          fld_id: modalShow == true ? null : data.fld_id,
          studentId: login.fld_id,
          accountNum: Type == "bank" ? data.fld_accountNum : null,
          accountName: Type == "bank" ? data.fld_accountName : null,
          bankName: Type == "bank" ? data.fld_bankName : null,
          branchName: Type == "bank" ? data.fld_branchName : null,
          ifscCode: Type == "bank" ? data.fld_ifscCode : null,
          updatedby: detail.fld_id,
          updatedon: moment().format("lll"),
          loginId: Type == "portal" ? data.fld_loginId : null,
          password: Type == "portal" ? data.fld_password : null,
          portalUrl: Type == "portal" ? data.fld_portalUrl : null,
          otherDetails: Type == "portal" ? data.fld_otherDetails : null,
        },
        "Update_ScholarMessBankAcountInformation"
      ).then((result) =>
        result.json().then((obj) => {
          if (result.status == 200 || result.status == 201) {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Saved and Updated Successfully");
            GetMessBankDetail();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(
              "There seems to be an Error. Please retry!"
            );
          }
        })
      );
    };
    if (CheckUpload) {
      const form = new FormData();
      let path = `${login.fld_id}-${Math.floor(Math.random() * 1000000)}-${
        CheckUpload.name
      }`;
      form.append("file", CheckUpload);
      form.append("foldername", "HostelBankCancelCheque");
      form.append("filename", path);
      Notiflix.Loading.dots();
      const res = await fetch(
        "https://admin.vahanischolarship.in/API/AddImage2",
        { method: "POST", body: form }
      );
      const response = await res.json();
      if (response) {
        Notiflix.Loading.remove();
        Notiflix.Notify.success(
          "Cancelled Cheque Document Uploaded Successfully"
        );
        data.fld_cancelCheque = `https://admin.vahanischolarship.in/Images/HostelBankCancelCheque/${path}`;
        UpdateData(data);
      } else {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure("There seems to be an Error. Please retry!");
      }
    } else {
      UpdateData(data);
    }
  };
  return (
    <>
      <form
        onSubmit={bankDetailsVerification === true ? handleSubmit(onSave) : ""}
      >
        {Type == "bank" && (
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                Mess Bank Details
                <div className="but-width-form">
                  <Button
                    variant="contained"
                    className="but-login-vahani"
                    type="button"
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    Add Bank Details
                  </Button>
                </div>
              </div>
            </p>

            <div className="row m-0 p-lg-4 p-3 ">
              <div className="col-md-6 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  label="Account Number*"
                  {...register("fld_accountNum", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[10].fld_reg_ex, "i"),
                      message: "Enter a valid account number",
                    },
                    minLength: {
                      value: 9,
                      message: "Enter minimum 9-digit number",
                    },
                  })}
                  value={watch("fld_accountNum") ? watch("fld_accountNum") : ""}
                  error={errors?.fld_accountNum}
                  helperText={
                    errors?.fld_accountNum
                      ? errors.fld_accountNum.message
                      : null
                  }
                />
              </div>
              <div className="col-md-6 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  label="Account Holder Name*"
                  className="capital"
                  {...register("fld_accountName", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[0].fld_reg_ex, "i"),
                      message: "Enter a valid name",
                    },
                  })}
                  value={
                    watch("fld_accountName") ? watch("fld_accountName") : ""
                  }
                  error={errors?.fld_accountName}
                  helperText={
                    errors?.fld_accountName
                      ? errors.fld_accountName.message
                      : null
                  }
                />
              </div>
              <div className="col-md-6 mb-lg-3 mobile-personal capital-col ">
                <TextField
                  fullWidth
                  label="Bank Name*"
                  className="capitalonly"
                  {...register("fld_bankName", {
                    required: "This field is required",
                    pattern: {
                      message: "Enter a valid bank name",
                    },
                  })}
                  value={watch("fld_bankName") ? watch("fld_bankName") : ""}
                  error={errors?.fld_bankName}
                  helperText={
                    errors?.fld_bankName ? errors.fld_bankName.message : null
                  }
                />
              </div>
              <div className="col-md-6 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  label="Branch Name*"
                  className="capital"
                  {...register("fld_branchName", {
                    required: "This field is required",
                    pattern: {
                      message: "Enter a valid branch name",
                    },
                  })}
                  value={watch("fld_branchName") ? watch("fld_branchName") : ""}
                  error={errors?.fld_branchName}
                  helperText={
                    errors?.fld_branchName
                      ? errors.fld_branchName.message
                      : null
                  }
                />
              </div>
              <div className="col-lg-6 mb-lg-3 mobile-personal capital-col ">
                <TextField
                  fullWidth
                  label="IFSC Code*"
                  className="capitalonly"
                  {...register("fld_ifscCode", {
                    required: "This field is required",
                    pattern: {
                      message: ValidationList[5].fld_reg_ex_msg,
                    },
                    maxLength: {
                      value: 11,
                      message: "Enter maximum 11-digit IFSC",
                    },
                    minLength: {
                      value: 11,
                      message: "Enter minimum 11-digit IFSC",
                    },
                  })}
                  value={watch("fld_ifscCode") ? watch("fld_ifscCode") : ""}
                  error={errors?.fld_ifscCode}
                  helperText={
                    errors?.fld_ifscCode ? errors.fld_ifscCode.message : null
                  }
                />
              </div>
            </div>
          </div>
        )}
        {Type == "portal" && (
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              PG / Hostel Portal Details
            </p>
            <div className="row m-0 p-4">
              <div className="col-lg-6 mb-lg-3 mobile-personal ">
                <TextField
                  fullWidth
                  label="College Login ID*"
                  {...register("fld_loginId", {
                    required: "This field is required",
                  })}
                  value={watch("fld_loginId") ? watch("fld_loginId") : ""}
                  error={errors?.fld_loginId}
                  helperText={
                    errors?.fld_loginId ? errors.fld_loginId.message : null
                  }
                />
              </div>
              <div className="col-lg-6 mb-lg-3 mobile-personal ">
                <TextField
                  fullWidth
                  label="Password*"
                  {...register("fld_password", {
                    required: "This field is required",
                  })}
                  value={watch("fld_password") ? watch("fld_password") : ""}
                  error={errors?.fld_password}
                  helperText={
                    errors?.fld_password ? errors.fld_password.message : null
                  }
                />
              </div>
              <div className="col-12 mb-lg-3 mobile-personal ">
                <TextField
                  fullWidth
                  label="Portal Link*"
                  {...register("fld_portalUrl", {
                    required: "This field is required",
                  })}
                  value={watch("fld_portalUrl") ? watch("fld_portalUrl") : ""}
                  error={errors?.fld_portalUrl}
                  helperText={
                    errors?.fld_portalUrl ? errors.fld_portalUrl.message : null
                  }
                />
              </div>
              <div className="col-12 mb-lg-3 mobile-personal ">
                <TextField
                  fullWidth
                  label="Other Details*"
                  multiline
                  rows="3"
                  {...register("fld_otherDetails", {
                    required: "This field is required",
                  })}
                  value={
                    watch("fld_otherDetails") ? watch("fld_otherDetails") : ""
                  }
                  error={errors?.fld_otherDetails}
                  helperText={
                    errors?.fld_otherDetails
                      ? errors.fld_otherDetails.message
                      : null
                  }
                />
              </div>
            </div>
          </div>
        )}
        {action != "view" && (
          <div className="card-vahani" style={{ marginTop: "26px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span
                className="text-danger"
                style={{
                  display: bankDetailsVerification === true ? "none" : "block",
                }}
              >
                Payment is already made on this bank account you can't edit
                this. You can add new account details.
              </span>
              <div className="but-width-form ms-auto">
                <Button
                  variant="contained"
                  type={bankDetailsVerification === true ? "submit" : "button"}
                  style={{
                    backgroundColor:
                      bankDetailsVerification === true
                        ? "rgb(32, 101, 209)"
                        : "#adad85",
                  }}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        )}
      </form>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Bank Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSave)}>
            {Type == "bank" && (
              <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
                <p className="personal-info left-line-card px-lg-4 px-4 py-4"></p>

                <div className="row m-0 p-lg-4 p-3 ">
                  <div className="col-md-6 mb-lg-3 mobile-personal">
                    <TextField
                      fullWidth
                      label="Account Number*"
                      {...register("fld_accountNum", {
                        required: "This field is required",
                        pattern: {
                          value: new RegExp(ValidationList[10].fld_reg_ex, "i"),
                          message: "Enter a valid account number",
                        },
                        minLength: {
                          value: 9,
                          message: "Enter minimum 9-digit number",
                        },
                      })}
                      value={
                        watch("fld_accountNum") ? watch("fld_accountNum") : ""
                      }
                      error={errors?.fld_accountNum}
                      helperText={
                        errors?.fld_accountNum
                          ? errors.fld_accountNum.message
                          : null
                      }
                    />
                  </div>
                  <div className="col-md-6 mb-lg-3 mobile-personal">
                    <TextField
                      fullWidth
                      label="Account Holder Name*"
                      className="capital"
                      {...register("fld_accountName", {
                        required: "This field is required",
                        pattern: {
                          value: new RegExp(ValidationList[0].fld_reg_ex, "i"),
                          message: "Enter a valid name",
                        },
                      })}
                      value={
                        watch("fld_accountName") ? watch("fld_accountName") : ""
                      }
                      error={errors?.fld_accountName}
                      helperText={
                        errors?.fld_accountName
                          ? errors.fld_accountName.message
                          : null
                      }
                    />
                  </div>
                  <div className="col-md-6 mb-lg-3 mobile-personal capital-col ">
                    <TextField
                      fullWidth
                      label="Bank Name*"
                      className="capitalonly"
                      {...register("fld_bankName", {
                        required: "This field is required",
                        pattern: {
                          message: "Enter a valid bank name",
                        },
                      })}
                      value={watch("fld_bankName") ? watch("fld_bankName") : ""}
                      error={errors?.fld_bankName}
                      helperText={
                        errors?.fld_bankName
                          ? errors.fld_bankName.message
                          : null
                      }
                    />
                  </div>
                  <div className="col-md-6 mb-lg-3 mobile-personal">
                    <TextField
                      fullWidth
                      label="Branch Name*"
                      className="capital"
                      {...register("fld_branchName", {
                        required: "This field is required",
                        pattern: {
                          message: "Enter a valid branch name",
                        },
                      })}
                      value={
                        watch("fld_branchName") ? watch("fld_branchName") : ""
                      }
                      error={errors?.fld_branchName}
                      helperText={
                        errors?.fld_branchName
                          ? errors.fld_branchName.message
                          : null
                      }
                    />
                  </div>
                  <div className="col-lg-6 mb-lg-3 mobile-personal capital-col ">
                    <TextField
                      fullWidth
                      label="IFSC Code*"
                      className="capitalonly"
                      {...register("fld_ifscCode", {
                        required: "This field is required",
                        pattern: {
                          message: ValidationList[5].fld_reg_ex_msg,
                        },
                        maxLength: {
                          value: 11,
                          message: "Enter maximum 11-digit IFSC",
                        },
                        minLength: {
                          value: 11,
                          message: "Enter minimum 11-digit IFSC",
                        },
                      })}
                      value={watch("fld_ifscCode") ? watch("fld_ifscCode") : ""}
                      error={errors?.fld_ifscCode}
                      helperText={
                        errors?.fld_ifscCode
                          ? errors.fld_ifscCode.message
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {action != "view" && (
              <div className="card-vahani" style={{ marginTop: "26px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="text-danger">
                    If you add new bank details your current bank details will
                    get deactivated!
                  </span>

                  <div className="but-width-form ms-auto">
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
