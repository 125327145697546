import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import PostApiCall from "../../../assets/API/PostApi";
import Notiflix from "notiflix";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

export default function PurchaseOrderInvoicePayments() {
  let location = useLocation();
  const [orderPayments, setOrderPayments] = useState([]);
  const [showRaisePayment, setShowRaisePayment] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const login = JSON.parse(localStorage.getItem("LoginDetail"))[0];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (location.state != undefined && location.state.id != null) {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          invoiceid: location.state.id,
        },
        "Get_PurchaseOrderPaymentsHistory"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            if (obj.data[obj.data.length - 1].fld_amount_remaining > 0) {
              setShowRaisePayment(true);
              setRemainingAmount(
                obj.data[obj.data.length - 1].fld_amount_remaining
              );
            } else {
              setShowRaisePayment(false);
            }

            setOrderPayments(obj.data);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Error Occurred!");
          }
        })
      );
    }
  }, []);

  const data = {
    columns: [
      {
        label: "S.No.",
        field: "serial",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Po Number",
        field: "ponumber",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Invoice No",
        field: "invoiceno",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Amount Payable",
        field: "payable",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Requested Amount",
        field: "requestedamount",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Remaining Amount",
        field: "remainingamount",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Created On",
        field: "createdon",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
        sort: "asc",
      },
    ],
    rows: orderPayments.map((data, i) => {
      return {
        serial: i + 1,
        ponumber: data.fld_po_no,
        invoiceno: data.fld_invoice_no,
        payable: data.fld_amount_payable,
        requestedamount: data.fld_amount_paid,
        remainingamount: data.fld_amount_remaining,
        status: data.fld_status,
        createdon: moment(data.fld_created_on).format("DD/MM/YYYY"),
        action: (
          <div className="d-flex">
            <Link
              className="m-auto"
              to={{
                pathname: `/add-purchase-Order-invoice`,
                state: {
                  id: data.fld_id,
                  poid: location.state.id,
                  frominvoce: "Yes",
                  remainingAmount: remainingAmount,
                },
              }}
            >
              <FaEdit className="edit-icon edit-icon-font-size" />
            </Link>
          </div>
        ),
      };
    }),
  };

  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Purchase Order Payments History </h4>
        </div>
      </div>
      <div className="card-vahani p-0 mt-2">
        <p className="personal-info m-0 left-line-card p-4 d-flex align-items-center justify-content-between">
          Purchase Order Payments History{" "}
          {login.fld_type !== "Accountant" &&
            <div className="col-md-2 ps-0 pe-0">
              {showRaisePayment == true && (
                <Button variant="contained" className="w-100">
                  <Link
                    to={{
                      pathname: `/add-purchase-Order-invoice`,
                      state: {
                        id: null,
                        poid: location.state.id,
                        remainingAmount: remainingAmount,
                      },
                    }}
                    className="text-white"
                  >
                    Raise New Payment
                  </Link>
                </Button>
              )}
            </div>
          }
        </p>
        <div className="p-3">
          {/* {addProjectData.length > 0 ? ( */}
          <>
            <MDBDataTable
              className="po-invoice"
              responsive
              hover
              disableRetreatAfterSorting={true}
              data={data}
              entriesOptions={[10, 25, 50, 100]}
            >
              <MDBTableHead columns={data.columns} />
              <MDBTableBody rows={data.rows} />
            </MDBDataTable>
          </>
          {/* ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )} */}
        </div>
      </div>
    </>
  );
}
