import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip
} from "@mui/material";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GetApiCall from "../../../assets/API/GetApi";
export default function NotificationList() {
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const [rows, setrows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchinput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModal, setshowModal] = useState(false);
  const [SelectData, setSelectData] = useState(null);
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    GetNotificationList();
  }, []);
  const GetNotificationList = () => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("Get_AdminNotificationList").then((resultdes) =>
      resultdes.json().then((obj) => {
        setrows(
          obj.data.sort(function (a, b) {
            return new Date(b.fld_updatedOn) - new Date(a.fld_updatedOn);
          })
        );
        setFilteredData(
          obj.data.sort(function (a, b) {
            return new Date(b.fld_updatedOn) - new Date(a.fld_updatedOn);
          })
        );
        Notiflix.Loading.remove();
      })
    );
  };
  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Notification List</h4>
        </div>
      </div>
      <div className="card-vahani p-0">
        <div className="row justify-content-between align-items-center m-0 left-line-card px-4 py-3 ">
          <p className="personal-info m-0 p-0 col-auto ">Notification List</p>

          <Link className=" btn btn-primary col-auto" to="/add-notification">
            {" "}
            Create New +
          </Link>
        </div>
        <div className="px-4 approval-search-mdbtable-hide">
          <div className="col-sm-auto mb-sm-0 mb-2 col-12 p-0 mdb-replace-custom-notification-searchbar">
            <input
              className="form-control form-control-sm ml-0 common-search-bar"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => {
                setSearchinput(e.target.value);
              }}
              value={searchInput}
            />
          </div>
          {rows.length > 0 ? (
            <MDBDataTable
              disableRetreatAfterSorting={true}
              responsive
              hover
              striped
              data={{
                columns: [
                  {
                    label: "Title",
                    field: "title",
                    sort: "disabled",
                  },
                  {
                    label: "Sent To",
                    field: "sent",
                    sort: "disabled",
                  },
                  {
                    label: "Sent On",
                    field: "created",
                    sort: "disabled",
                  },
                  {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                  },
                ],
                // rows: rows.map((data, i) => {
                //   return {
                //     title: data.fld_title,
                //     sent: data.fld_type,
                //     created: moment(data.fld_updatedOn).format("DD/MM/YYYY"),
                //     action: (
                //       <Tooltip title="View">
                //         <VisibilityIcon
                //           className="cursor-pointer"
                //           color="primary"
                //           onClick={() => {
                //             setshowModal(true);
                //             setSelectData(data);
                //           }}
                //         />
                //       </Tooltip>
                //     ),
                //   };
                // }),

                rows: filteredData
                  .filter((data) => {
                    if (searchInput == "") {
                      return data;
                    }
                    if (
                      // searchInput.toString() !== "" &&
                      data.fld_title
                        .toString()
                        .toLowerCase()
                        .includes(searchInput.toString().toLowerCase()) ||
                      `${data.fld_type
                        ? data.fld_type.toString().toLowerCase()
                        : ""
                        }`
                        .toString()
                        .includes(searchInput.toString().toLowerCase()) ||
                      `${data.fld_updatedOn != null
                        ? new Date(data.fld_updatedOn).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric"
                        }).toString().toLowerCase()
                        : null
                        }`
                        .includes(searchInput.toString().toLowerCase())
                    ) {
                      console.log(data);
                      return data;
                    }
                  })
                  .map((row, i) => {
                    let sentValue;
                    if (row.fld_studentId !== 0) {
                      sentValue = `${row.fld_type} - ${row.fld_studentId}`;
                    }
                    else {
                      sentValue = row.fld_type
                    }
                    return {
                      title: row.fld_title,
                      sent: sentValue,
                      created: moment(row.fld_updatedOn).format("DD/MM/YYYY"),
                      action: (
                        <Tooltip title="View">
                          <VisibilityIcon
                            className="cursor-pointer"
                            color="primary"
                            onClick={() => {
                              setshowModal(true);
                              setSelectData(row);
                            }}
                          />
                        </Tooltip>
                      ),
                    };
                  }),
              }}
              entriesOptions={[10, 25, 50, 100]}
            />
          ) : (
            <p className="m-3">No Data Found</p>
          )}
        </div>
      </div>
      <Dialog
        open={showModal}
        maxWidth={"md"}
        onClose={() => setshowModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className="d-flex justify-content-between"
          id="alert-dialog-title"
        >
          {" "}
          <h2>Notification Detail</h2>{" "}
          <CancelIcon
            className="cursor-pointer ms-3"
            onClick={() => setshowModal(false)}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              className="card-vahani text-white"
              style={{
                backgroundImage:
                  "url('https://media.istockphoto.com/photos/cork-board-background-picture-id848498476?b=1&k=20&m=848498476&s=170667a&w=0&h=jPpRWw52eQxqOg4-ygMEekauGcoY0ye-UlrY-SFJsBQ=')",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <h1>{SelectData?.fld_title}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: SelectData?.fld_description,
                }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
