import React, { Component, useContext, useEffect, useState } from "react";
import { Edit3 } from "react-feather";
import Notiflix from "notiflix";
import { AiOutlineFileSearch } from "react-icons/ai";

import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import moment from "moment";
import { Edit } from "react-feather";
import { Link } from "react-router-dom";

import { Button, Menu, MenuItem, TextField } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PostApiCall from "../../../assets/API/PostApi";
import { FaEdit } from "react-icons/fa";
import store from "../../../store";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function VendorInfoList({ setShowVendorList }) {
  const { setVendorData, vendorGlobalId, setVendorGlobalId } =
    useContext(store);
  const { disabledTab, setDisabledTab } = useContext(store);
  const [VendorListData, setVendorListData] = useState([]);
  const [dateData, setdateData] = React.useState({ from: "", to: "" });
  const [ConditionType, setConditionType] = useState("New");
  const [SortData, setSortData] = React.useState({
    type: "desc",
    value: "fld_id",
  });

  const [addVendorData, setAddVendorData] = useState([]); // initializing the vendor data
  const history = useHistory();

  // render the vendor list
  useEffect(() => {
    Notiflix.Loading.init({
      svgColor: "rgb(6, 10, 74)",
    });
    Notiflix.Loading.dots("Please wait...");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    PostApiCall.postRequest(
      {
        wherecaluse: "",
      },
      "GetVendorMaster"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setAddVendorData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Notify.failure("error occurred!");
          Notiflix.Loading.remove();
        }
      })
    );
    Notiflix.Loading.remove();
  }, []);

  const getProjectList = (condition) => {
    PostApiCall.postRequest(
      {
        whereClause:
          dateData.from == "" && dateData.to == ""
            ? `order by ${SortData.value} ${SortData.type}`
            : `where fld_created_on between '${dateData.from}' and '${dateData.to
            }' ${dateData.from != "" && dateData.to != ""
              ? `and fld_created_on between '${dateData.from}' and '${dateData.to}' `
              : ""
            } order by ${SortData.value} ${SortData.type}`,
      },
      "GetVendorMaster"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setAddVendorData(obj.data);
        }
      })
    );
  };
  const data = {
    columns: [
      {
        label: "S.No.",
        field: "serial",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Vendor Name",
        field: "vendorName",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Email",
        field: "email",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Website",
        field: "website",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Mobile No.",
        field: "mobileNo",
        sort: "disabled",
        width: 150,
      },
      {
        label: "GSTIN",
        field: "gstNo",
        sort: "disabled",
        width: 150,
      },
      {
        label: "TAN",
        field: "tan",
        sort: "disabled",
        width: 150,
      },
      {
        label: "PAN",
        field: "pan",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 150,
      },
    ],
    rows: addVendorData.map((data, i) => {
      return {
        serial: i + 1,
        vendorName: data.fld_name,
        industryType: data.fld_industry,
        email: data.fld_email,
        website: data.fld_website,
        mobileNo: data.fld_mobile,
        phoneNo: data.fld_landline,
        gstNo: data.fld_gst_no,
        tan: data.fld_tan,
        pan: data.fld_pan_no,
        action: (
          <Link
            className="m-auto"
            to={{
              pathname: `/add-vendor`,
              state: {
                id: data.fld_id,
              },
            }}
          >
            <FaEdit
              className="edit-icon edit-icon-font-size fs-4"
              onClick={() => {
                setVendorGlobalId(data.fld_id);
                setDisabledTab(false);
              }}
            />
          </Link>
        ),
      };
    }),
  };

  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Vendors List </h4>
        </div>
      </div>
      <div className="card-vahani p-0 mt-2">
        <div className="personal-info left-line-card row m-0 align-items-lg-center justify-content-between px-2 py-4 mb-2 w-100">
          <div className="col-4 col-md-10 mb-2 mb-md-0">Vendors</div>
          {JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_type != "Accountant" &&
            (
              <Link
                to={{
                  pathname: `/add-vendor`,
                  state: {
                    id: null,
                  },
                }}
                className="col-8 col-md-2 mb-sm-0 text-white text-end"
              >
                <Button variant="contained">Add New Vendor</Button>
              </Link>
            )
          }

        </div>
        <div className="personal-info m-0 ps-md-1 pe-md-3 pb-0 d-lg-flex align-items-center justify-content-between">
          <div className="col-xxl-6 col-12 mt-3">
            <div className="row px-0">
              <TextField
                type="date"
                label="From Date"
                id="outlined-size-small"
                size="small"
                value={dateData.from}
                onChange={(e) =>
                  setdateData({ ...dateData, from: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className="mb-3 mb-md-0 px-2 col-md-4"
              />
              <TextField
                type="date"
                label="To Date"
                id="outlined-size-small"
                size="small"
                value={dateData.to}
                onChange={(e) =>
                  setdateData({ ...dateData, to: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className="px-2 col-md-4 mb-3 mb-md-0"
              />
              <div className="px-2 col-md-2">
                <Button
                  className="sort-filter-size w-100"
                  variant="contained"
                  onClick={() => {
                    if (dateData.from != "") {
                      if (dateData.to != "") {
                        getProjectList(ConditionType);
                      } else {
                        Notiflix.Notify.failure("Enter To date");
                      }
                    } else {
                      Notiflix.Notify.failure("Enter From date");
                    }
                  }}
                >
                  Filter
                </Button>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 px-lg-0 col-12 mt-3 px-0">
            <div className="row m-0 px-0 align-items-lg-start justify-content-lg-end">
              <div className="col-sm-3 mb-sm-0 mb-3 col-12 pe-lg-0">
                <select
                  class="form-select form-select-sm sort-filter-size"
                  aria-label=".form-select-sm example"
                  onChange={(e) =>
                    setSortData({ ...SortData, value: e.target.value })
                  }
                >
                  <option value="fld_id">Sort By</option>
                  <option value="fld_name">Vendor Name</option>
                  <option value="trim(fld_gst_no)">GSTIN</option>
                  <option value="cast(fld_created_on as date)">
                    Raised On
                  </option>
                </select>
              </div>
              <div className="col-sm-3 col-12 mb-sm-0 mb-3 ">
                <select
                  class="form-select form-select-sm sort-filter-size"
                  aria-label=".form-select-sm example"
                  onChange={(e) =>
                    setSortData({ ...SortData, type: e.target.value })
                  }
                >
                  <option value="desc">Order</option>
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending </option>
                </select>
              </div>
              <div className="col-sm-2 col-12 mb-sm-0 mb-3 px-lg-0">
                <Button
                  className="w-100"
                  variant="contained"
                  onClick={() => {
                    getProjectList(ConditionType);
                  }}
                >
                  Sort
                </Button>
              </div>
              {/* <Link
                to="/add-vendor"
                className="col-sm-4 col-12 mb-sm-0 text-white">
                <Button variant="contained" className="w-100">
                  Add New Vendor
                </Button>
              </Link> */}
            </div>
          </div>
        </div>
        {/* </div> */}

        <div className="p-3">
          {addVendorData.length > 0 ? (
            <>
              <MDBDataTable
                responsive
                hover
                disableRetreatAfterSorting={true}
                data={data}
                entriesOptions={[10, 25, 50, 100]}
              >
                <MDBTableHead columns={data.columns} />
                <MDBTableBody rows={data.rows} />
              </MDBDataTable>
            </>
          ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )}
        </div>
      </div>
    </>
  );
}
