import { Button } from "@mui/material";
import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import PostApiCall from "../../../assets/API/PostApi";

export default function RfqTaskList() {
  const [addRfqTaskList, setRfqTaskList] = useState([]);
  const [param, setParam] = this.props.location.rfq_id;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    PostApiCall.postRequest(
      {
        whereClause: ` where fld_id = ${param} `,
      },
      "GetRFQTasks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setRfqTaskList(obj.data);
        }
      })
    );
  }, []);

  const data = {
    columns: [
      {
        label: "S.No.",
        field: "serial",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Project Name",
        field: "projectname",
        sort: "disabled",
        width: 400,
      },
      {
        label: "RFQ Task",
        field: "rfqtask",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Created By",
        field: "createdby",
        sort: "asc",
        width: 150,
      },
      {
        label: "Updated By",
        field: "UpdatedBy",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
        sort: "asc",
      },
    ],
    rows: addRfqTaskList.map((data, i) => {
      return {
        serial: i + 1,
        Vendorname: data.vendor_name,
        rfqno: data.fld_rfq_no,
        rfqDate: moment(data.fld_created_on).format("DD-MM-YYYY"),
        status: data.fld_rfq_status,
        createdby: data.created_by,
        UpdatedBy: data.updated_by,
        action: (
          <div className="d-flex">
            <Link
              className="m-auto"
              to={
                {
                  //   pathname: `/add-project/${data.fld_id}`,
                }
              }
            >
              <AiFillEye className="edit-icon edit-icon-font-size" />
            </Link>
          </div>
        ),
      };
    }),
  };

  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>RFQ Task List </h4>
        </div>
      </div>
      <div className="card-vahani p-0 mt-2">
        <p className="personal-info m-0 left-line-card p-4 d-flex align-items-center justify-content-between">
          RFQ Task{" "}
          <div className="col-md-2 ps-0 pe-0">
            <Button variant="contained" className="w-100">
              <Link to="/add-rfq-task" className="text-white">
                Add New Task
              </Link>
            </Button>
          </div>
        </p>
        <div className="p-3">
          {/* {addProjectData.length > 0 ? ( */}
          <>
            <MDBDataTable
              responsive
              hover
              disableRetreatAfterSorting={true}
              data={data}
              entriesOptions={[10, 25, 50, 100]}
            >
              <MDBTableHead columns={data.columns} />
              <MDBTableBody rows={data.rows} />
            </MDBDataTable>
          </>
          {/* ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )} */}
        </div>
      </div>
    </>
  );
}
