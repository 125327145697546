import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import PostApiCall from "./assets/API/PostApi";
// import Login from "./pages/Login/Login";
// import Register from "./pages/Register/Register";
import Home from "./pages/Home";
// import Main from './Main';
import FirstPage from "./pages/FirstPage";
// import QrCode from "./QrCode";
import HrmsSidebar from "./Components/Sidebar/Hrms";
import StudentDetailUrl from "./pages/AdminManagement/Students/StudentDetailUrl";
import EmployeeHome from "./pages/EmployeeHome";
import EmployeeLogin from "./pages/Login/EmployeeLogin";
import VendorDashboard from "./pages/VendorManagement/VendorDashboard/VendorDashboard";

function App() {
  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetCommonValidation"
    ).then((results10) =>
      results10.json().then((obj10) => {
        if (results10.status === 200 || results10.status === 201) {
          //
          localStorage.setItem("Validation", JSON.stringify(obj10.data));
          // setValidationList(obj10.data)
        }
      })
    );
  }, []);
  return (
    <Switch>
      <Route exact path="/" component={FirstPage} />
      <Route exact path="/employeelogin" component={EmployeeLogin} />
      <Route exact path="/hrms" component={HrmsSidebar} />
      <Route exact path="/student-detail/:data" component={StudentDetailUrl} />
      {localStorage.getItem("LoginDetail") != null ? (
        JSON.parse(localStorage.getItem("LoginDetail")).fld_type ==
          "Employee" ? (
          <Route path="*" component={EmployeeHome} />
        ) : JSON.parse(localStorage.getItem("LoginDetail")).fld_type ==
          "Vendor Management" ? (
          <Route path="*" component={VendorDashboard} />) : (
          <Route path="*" component={Home} />
        )
      ) : (
        ""
      )}
    </Switch>
  );
}
export default App;
