import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
import GetApiCall from "../../../assets/API/GetApi";
import moment from "moment";
import Notiflix from "notiflix";
import upload from "../../../assets/Image/image-log.webp";
import Button from "@mui/material/Button";
import imageCompression from "browser-image-compression";
import TextField from "@mui/material/TextField";
import "../../../Components/Form/Personalinfo/Personalinfo.css";
import Modal from "react-bootstrap/Modal";

export default function ParentBank({ stdid, action }) {
  var login = stdid
    ? { fld_id: stdid }
    : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  var ValidationList = JSON.parse(localStorage.getItem("Validation"));
  const [file, setFile] = useState();
  const [ImageApiUrl, setImageApiUrl] = useState(
    "https://admin.vahanischolarship.in/API/AddImage2"
  );
  const [originalImage, setoriginalImage] = useState([]);
  const [originalImageCheque, setoriginalImageCheque] = useState([]);
  const [uploadImage, setuploadImage] = useState(false);
  const [uploadImageCheque, setuploadImageCheque] = useState(false);
  const [outputFileName, setoutputFileName] = useState("");
  const [outputFileNameCheque, setoutputFileNameCheque] = useState("");
  const [previewUrl, setPreviewUrl] = useState("/upload.jpg");
  const [previewUrlCheque, setPreviewUrlCheque] = useState("/upload.jpg");
  const [bankDetailsVerification, setBankDetailsVerification] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  function handleChange(e) {
    //
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  React.useEffect(() => {
    Notiflix.Loading.dots();
    GetStudentParentBankInformation();
  }, []);
  const ImgUpload = ({ onChange, onCancel, src }) => (
    <label
      htmlFor="photo-upload"
      className="custom-file-upload fas text-center"
      style={{ width: "100%" }}
    >
      <div className="img-wrap img-upload">
        <img
          for="photo-upload"
          src={src}
          style={{ width: "100%", height: "100%", borderRadius: "5%" }}
          alt=""
        />
      </div>
      <input
        id="photo-upload"
        type="file"
        onChange={onChange}
        accept="image/png, image/jpeg, image/jpg"
        style={{ display: "none" }}
      />
      {src != "/upload.jpg" && (
        <a href={src} target="_blank" className="btn btn-primary mt-2">
          View
        </a>
      )}
    </label>
  );
  const ImgUploadCheque = ({ onChange1, onCancel1, src1 }) => (
    <label
      htmlFor="photo-upload1"
      className="custom-file-upload fas text-center"
      style={{ width: "100%" }}
    >
      <div className="img-wrap img-upload">
        <img
          for="photo-upload1"
          src={src1}
          style={{ width: "100%", height: "100%", borderRadius: "5%" }}
          alt=""
        />
      </div>
      <input
        id="photo-upload1"
        type="file"
        onChange={onChange1}
        accept="image/png, image/jpeg, image/jpg"
        style={{ display: "none" }}
      />
      {src1 != "/upload.jpg" && (
        <a href={src1} target="_blank" className="btn btn-primary mt-2">
          View
        </a>
      )}
    </label>
  );
  const GetStudentParentBankInformation = () => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "GetStudentParentBankInformation_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (
          (results.status == 200 || results.status == 201) &&
          obj.data.length > 0
        ) {
          if (obj.data.length != 0) {
            PostApiCall.postRequest(
              {
                studentId: login.fld_id,
                bankaccno: obj.data[0].fld_accountNumber,
              },
              "BankDetailsVerfication"
            ).then((results) =>
              results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                  if (obj.data.length != 0) {
                    var bankdetailsVerification = false;
                    for (var i = 0; i < obj.data.length; i++) {
                      if (
                        obj.data[0].fld_approval_status === "Paid" ||
                        obj.data[0].fld_approval_status === "Closed"
                      ) {
                        bankdetailsVerification = false;
                        break;
                      } else {
                        bankdetailsVerification = true;
                      }
                    }

                    setBankDetailsVerification(bankdetailsVerification);
                  } else {
                    Notiflix.Loading.remove();
                    setBankDetailsVerification(true);
                  }
                }
              })
            );
            setPreviewUrl(obj.data[0].fld_bankPassbook);
            setPreviewUrlCheque(obj.data[0].fld_cancelCheque);
            reset(obj.data[0]);
          }
          Notiflix.Loading.remove();
        } else {
          setBankDetailsVerification(true);
        }
        Notiflix.Loading.remove();
      })
    );
  };
  async function onPost(parentbank) {
    let response;
    if (outputFileName != "") {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(originalImage, options).then((compressedFile) => {
        const form = new FormData();
        form.append("file", compressedFile);
        form.append("foldername", "ParentBankInformation");
        form.append(
          "filename",
          parentbank.fld_accountName.trim() +
          "-ParentBankPassbook" +
          "-" +
          compressedFile.name
        );
        response = fetch(ImageApiUrl, {
          method: "POST",
          body: form,
          mode: "cors",
        })
          .then((response) => response.json())
          .then((data) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Saved and Updated Successfully");
            onPostUploadCheque(parentbank);
          });
      });
    } else {
      onPostUploadCheque(parentbank);
    }
  }
  async function onPostUploadCheque(parentbank) {
    let response;
    if (outputFileNameCheque != "") {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(originalImageCheque, options).then((compressedFile) => {
        const form = new FormData();
        form.append("file", compressedFile);
        form.append("foldername", "ParentBankInformation");
        form.append(
          "filename",
          parentbank.fld_accountName.trim() +
          "-CancelledCheque" +
          "-" +
          compressedFile.name
        );
        response = fetch(ImageApiUrl, {
          method: "POST",
          body: form,
          mode: "cors",
        })
          .then((response) => response.json())
          .then((data) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Cancelled Cheque Uploaded successfully.");
            onPostParent(parentbank);
          });
      });
    } else {
      onPostParent(parentbank);
    }
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (parentbank) => {
    Notiflix.Loading.dots();
    onPost(parentbank);
  };
  const onPostParent = (parentbank) => {
    Notiflix.Loading.dots();
    var detail = JSON.parse(localStorage.getItem("LoginDetail"))[0];
    PostApiCall.postRequest(
      {
        id: modalShow == true ? null : parentbank.fld_id,
        studentId: login.fld_id,
        accountNumber: parentbank.fld_accountNumber,
        accountName: parentbank.fld_accountName,
        bankName: parentbank.fld_bankName,
        branchName: parentbank.fld_branchName,
        IFSC: parentbank.fld_IFSC,
        updatedby: detail.fld_id,
        updatedon: moment().format("lll"),
        passbook:
          outputFileName == ""
            ? previewUrl
            : "https://admin.vahanischolarship.in/Images/" +
            "ParentBankInformation/" +
            parentbank.fld_accountName.trim() +
            "-ParentBankPassbook" +
            "-" +
            outputFileName,
        chequebook:
          outputFileNameCheque == ""
            ? previewUrlCheque
            : "https://admin.vahanischolarship.in/Images/" +
            "ParentBankInformation/" +
            parentbank.fld_accountName.trim() +
            "-CancelledCheque" +
            "-" +
            outputFileNameCheque,
      },
      "UpdateStudentParentBankInformation"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          GetStudentParentBankInformation();
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Saved and Updated Successfully");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  return (
    <>
      <div>
        {!action && (
          <div className="form-head">
            <div
              style={{
                borderLeft: "10px solid rgb(32, 101, 209)",
                paddingLeft: "10px",
              }}
            >
              <h4>Bank Details</h4>{" "}
            </div>
          </div>
        )}
        <form
          onSubmit={
            bankDetailsVerification === true ? handleSubmit(onSubmit) : ""
          }
        >
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                Parent's Bank Details
                <div className="but-width-form">
                  <Button
                    variant="contained"
                    className="but-login-vahani"
                    type="button"
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    Add Bank Details
                  </Button>
                </div>
              </div>
            </p>
            <div className="row m-0 p-lg-4 p-3">
              <div className="col-lg-6 mb-lg-3 mobile-personal col-12 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Account Number*"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_accountNumber", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[10].fld_reg_ex, "i"),
                      message: "Enter a valid account number",
                    },
                    minLength: {
                      value: 9,
                      message: "Enter minimum 9-digit number",
                    },
                  })}
                  error={!!errors?.fld_accountNumber}
                  helperText={
                    errors?.fld_accountNumber
                      ? errors.fld_accountNumber.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 17,
                  }}
                />
              </div>
              <div className="col-lg-6 mb-lg-3 mobile-personal col-12">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Account Holder Name*"
                  variant="outlined"
                  className="capital"
                  {...register("fld_accountName", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[0].fld_reg_ex, "i"),
                      message: "Enter a valid name",
                    },
                  })}
                  error={!!errors?.fld_accountName}
                  helperText={
                    errors?.fld_accountName
                      ? errors.fld_accountName.message
                      : null
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="col-lg-6 mb-lg-3 mobile-personal col-12 capital-col">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Bank Name*"
                  variant="outlined"
                  className="capitalonly"
                  {...register("fld_bankName", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[7].fld_reg_ex, "i"),
                      message: "Enter a valid bank name",
                    },
                  })}
                  error={errors?.fld_bankName}
                  helperText={
                    errors?.fld_bankName ? errors.fld_bankName.message : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-6 mb-lg-3 mobile-personal col-12">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Branch Name*"
                  variant="outlined"
                  className="capital"
                  {...register("fld_branchName", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[11].fld_reg_ex, "i"),
                      message: "Enter a valid branch name",
                    },
                  })}
                  error={!!errors?.fld_branchName}
                  helperText={
                    errors?.fld_branchName
                      ? errors.fld_branchName.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-6 mb-lg-3 mobile-personal col-12 capital-col">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="IFSC Code*"
                  variant="outlined"
                  className="capitalonly"
                  {...register("fld_IFSC", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[5].fld_reg_ex, "i"),
                      message: ValidationList[5].fld_reg_ex_msg,
                    },
                    maxLength: {
                      value: 11,
                      message: "Enter maximum 11-digit IFSC",
                    },
                    minLength: {
                      value: 11,
                      message: "Enter minimum 11-digit IFSC",
                    },
                  })}
                  error={errors?.fld_IFSC}
                  helperText={errors?.fld_IFSC ? errors.fld_IFSC.message : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              Upload Documents{" "}
            </p>
            <div className="row m-0 p-4">
              <div className="col-lg-3 col-12 mobile-personal ">
                <ImgUploadCheque
                  onChange1={(e) => {
                    e.preventDefault();
                    const imageFile = e.target.files[0];
                    setPreviewUrlCheque(URL.createObjectURL(imageFile));
                    setoriginalImageCheque(imageFile);
                    setoutputFileNameCheque(imageFile.name);
                    setuploadImageCheque(true);
                  }}
                  src1={previewUrlCheque}
                />
                <div className="upload-image-family">
                  Upload Your Cancelled Cheque
                  <br />
                  (Maximum Size 2MB | File Format *.jpeg, *.jpg, *.png)
                </div>
              </div>
              <div className="col-lg-3 col-12 mobile-personal ">
                <ImgUpload
                  onChange={(e) => {
                    e.preventDefault();
                    const imageFile = e.target.files[0];
                    setPreviewUrl(URL.createObjectURL(imageFile));
                    setoriginalImage(imageFile);
                    setoutputFileName(imageFile.name);
                    setuploadImage(true);
                  }}
                  src={previewUrl}
                />
                <div className="upload-image-family">
                  Upload Your Bank Passbook <br /> (Maximum Size 2MB | File
                  Format *.jpeg, *.jpg, *.png)
                </div>
              </div>
            </div>
          </div>
          {action != "view" && (
            <div className="card-vahani " style={{ marginTop: "24px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span
                  className="text-danger"
                  style={{
                    display:
                      bankDetailsVerification === true ? "none" : "block",
                  }}
                >
                  Payment is already made on this bank account you can't edit
                  this. You can add new account details.
                </span>
                <div className="but-width-form ms-auto">
                  <Button
                    variant="contained"
                    type={
                      bankDetailsVerification === true ? "submit" : "button"
                    }
                    style={{
                      backgroundColor:
                        bankDetailsVerification === true
                          ? "rgb(32, 101, 209)"
                          : "#adad85",
                    }}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Bank Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onPostParent)}>
            <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
              <p className="personal-info left-line-card px-lg-4 px-4 py-4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Parent's Bank Details
                </div>
              </p>
              <div className="row m-0 p-lg-4 p-3">
                <div className="col-lg-6 mb-lg-3 mobile-personal col-12 mobile-personal">
                  <TextField
                    fullWidth
                    id="standard-multiline-flexible"
                    label="Account Number*"
                    variant="outlined"
                    style={{ width: "100%" }}
                    {...register("fld_accountNumber", {
                      required: "This field is required",
                      pattern: {
                        value: new RegExp(ValidationList[10].fld_reg_ex, "i"),
                        message: "Enter a valid account number",
                      },
                      minLength: {
                        value: 9,
                        message: "Enter minimum 9-digit number",
                      },
                    })}
                    error={!!errors?.fld_accountNumber}
                    helperText={
                      errors?.fld_accountNumber
                        ? errors.fld_accountNumber.message
                        : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 17,
                    }}
                  />
                </div>
                <div className="col-lg-6 mb-lg-3 mobile-personal col-12">
                  <TextField
                    fullWidth
                    id="standard-multiline-flexible"
                    label="Account Holder Name*"
                    variant="outlined"
                    className="capital"
                    {...register("fld_accountName", {
                      required: "This field is required",
                      pattern: {
                        value: new RegExp(ValidationList[0].fld_reg_ex, "i"),
                        message: "Enter a valid name",
                      },
                    })}
                    error={!!errors?.fld_accountName}
                    helperText={
                      errors?.fld_accountName
                        ? errors.fld_accountName.message
                        : null
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="col-lg-6 mb-lg-3 mobile-personal col-12 capital-col">
                  <TextField
                    fullWidth
                    id="standard-multiline-flexible"
                    label="Bank Name*"
                    variant="outlined"
                    className="capitalonly"
                    {...register("fld_bankName", {
                      required: "This field is required",
                      pattern: {
                        value: new RegExp(ValidationList[7].fld_reg_ex, "i"),
                        message: "Enter a valid bank name",
                      },
                    })}
                    error={errors?.fld_bankName}
                    helperText={
                      errors?.fld_bankName ? errors.fld_bankName.message : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-lg-6 mb-lg-3 mobile-personal col-12">
                  <TextField
                    fullWidth
                    id="standard-multiline-flexible"
                    label="Branch Name*"
                    variant="outlined"
                    className="capital"
                    {...register("fld_branchName", {
                      required: "This field is required",
                      pattern: {
                        value: new RegExp(ValidationList[11].fld_reg_ex, "i"),
                        message: "Enter a valid branch name",
                      },
                    })}
                    error={!!errors?.fld_branchName}
                    helperText={
                      errors?.fld_branchName
                        ? errors.fld_branchName.message
                        : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-lg-6 mb-lg-3 mobile-personal col-12 capital-col">
                  <TextField
                    fullWidth
                    id="standard-multiline-flexible"
                    label="IFSC Code*"
                    variant="outlined"
                    className="capitalonly"
                    {...register("fld_IFSC", {
                      required: "This field is required",
                      pattern: {
                        value: new RegExp(ValidationList[5].fld_reg_ex, "i"),
                        message: ValidationList[5].fld_reg_ex_msg,
                      },
                      maxLength: {
                        value: 11,
                        message: "Enter maximum 11-digit IFSC",
                      },
                      minLength: {
                        value: 11,
                        message: "Enter minimum 11-digit IFSC",
                      },
                    })}
                    error={errors?.fld_IFSC}
                    helperText={
                      errors?.fld_IFSC ? errors.fld_IFSC.message : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
              <p className="personal-info left-line-card px-lg-4 px-4 py-4">
                Upload Documents{" "}
              </p>
              <div className="row m-0 p-4">
                <div className="col-lg-3 col-12 mobile-personal ">
                  <ImgUploadCheque
                    onChange1={(e) => {
                      e.preventDefault();
                      const imageFile = e.target.files[0];
                      setPreviewUrlCheque(URL.createObjectURL(imageFile));
                      setoriginalImageCheque(imageFile);
                      setoutputFileNameCheque(imageFile.name);
                      setuploadImageCheque(true);
                    }}
                    src1={previewUrlCheque}
                  />
                  <div className="upload-image-family">
                    Upload Your Cancelled Cheque
                    <br />
                    (Maximum Size 2MB | File Format *.jpeg, *.jpg, *.png)
                  </div>
                </div>
                <div className="col-lg-3 col-12 mobile-personal ">
                  <ImgUpload
                    onChange={(e) => {
                      e.preventDefault();
                      const imageFile = e.target.files[0];
                      setPreviewUrl(URL.createObjectURL(imageFile));
                      setoriginalImage(imageFile);
                      setoutputFileName(imageFile.name);
                      setuploadImage(true);
                    }}
                    src={previewUrl}
                  />
                  <div className="upload-image-family">
                    Upload Your Bank Passbook <br /> (Maximum Size 2MB | File
                    Format *.jpeg, *.jpg, *.png)
                  </div>
                </div>
              </div>
            </div>
            {action != "view" && (
              <div className="card-vahani " style={{ marginTop: "24px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="text-danger">
                    If you add new bank details your current bank details will
                    get deactivated!
                  </span>
                  <div className="but-width-form ms-auto">
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
