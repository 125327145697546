import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Button,
  Menu,
  MenuItem
} from "@mui/material";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import PostApiCall from "../../../assets/API/PostApi";
import store from "../../../store";
export default function ScholarList() {
  const history = useHistory();
  const { setstudentData } = useContext(store);
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const [ListAll, setListAll] = React.useState(false);
  const [searchInput, setSearchinput] = useState("")
  const [SortData, setSortData] = React.useState({
    type: "desc",
    value: "fld_id",
  });

  const [list, setlist] = React.useState([]);
  const [filteredData, setFilteredData] = useState([]);

  let scholarlist = list.filter(
    (ele, ind) =>
      ind ===
      list.findIndex(
        (elem) =>
          elem.fld_studentId === ele.fld_studentId &&
          elem.fld_full_name === ele.fld_full_name
      )
  );

  const [ShowViewModal, setShowViewModal] = React.useState(null);
  const [Showcheck, setShowcheck] = React.useState(false);
  const [SelectCheck, setSelectCheck] = React.useState([]);
  const [SelectData, setSelectData] = React.useState(null);
  const [SearchData, setSearchData] = React.useState({
    stdid: "",
    name: "",
    email: "",
    mobile: "",
    fromDate: "",
    toDate: "",
  });

  React.useEffect(() => {
    Notiflix.Loading.init({ svgColor: "#060a4a" });
    GetScholarsList();
    if (window.location.pathname === '/scholar-list-search') {
      if (localStorage.getItem("searchscholarlist") != null) {
        const value = localStorage.getItem("searchscholarlist");
        setSearchinput(value);

        const filtered = list.filter((item) => {
          return Object.keys(item).some((key) =>
            item[key].toString().toLowerCase().includes(value)
          );
        });
        setFilteredData(filtered);
      }
    }
  }, []);
  const GetScholarsList = (data) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause:
          data == undefined
            ? `where fld_studentStatus = 'Approved' and cast(fld_updatedOn as date) between '${new Date().getFullYear() - 4
            }/01/31' and '${new Date().getFullYear() + 1
            }/08/31' order by ${SortData.value} ${SortData.type}`
            : data,
      },
      "Get_StudentRegistraionList"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        //
        setlist(obj.data);
        setFilteredData(obj.data)
        Notiflix.Loading.remove();
      })
    );
  };
  const UpdateReject = (data) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        studentId: data.fld_id,
        studentStatus: "Rejected",
        type: "Rejected",
        typeLogin: "Rejected",
        email: data.fld_email,
      },
      "Admin_Update_AdminRejectStatus"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Scholar Rejected.");
          GetScholarsList();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Scholars' List </h4>
        </div>
      </div>
      <div className="card-vahani p-0 mt-2">
        <p className="personal-info m-0 left-line-card p-4">Scholars' List</p>
        <div className="row m-0 justify-content-between py-4  border-top border-bottom border-secondary">
          <div className="col-md-3 mb-3">
            <input
              type="text"
              value={SearchData.stdid}
              onChange={(e) =>
                setSearchData({ ...SearchData, stdid: e.target.value })
              }
              class="form-control"
              placeholder="Enter Scholar ID"
            />
          </div>
          <div className="col-md-3 mb-3">
            <input
              type="text"
              value={SearchData.name}
              onChange={(e) =>
                setSearchData({ ...SearchData, name: e.target.value })
              }
              class="form-control "
              placeholder="Enter Name"
            />
          </div>
          <div className="col-md-3 mb-3">
            <input
              type="email"
              value={SearchData.email}
              onChange={(e) =>
                setSearchData({ ...SearchData, email: e.target.value })
              }
              class="form-control "
              placeholder="Enter Email"
            />
          </div>
          <div className="col-md-3 mb-3">
            <input
              type="number"
              value={SearchData.mobile}
              onChange={(e) =>
                setSearchData({ ...SearchData, mobile: e.target.value })
              }
              class="form-control "
              placeholder="Enter Mobile No."
            />
          </div>
          <div className="col-md-3 mb-3">
            <input
              type="text"
              onFocus={(e) => (e.target.type = "date")}
              value={SearchData.fromDate}
              onChange={(e) =>
                setSearchData({ ...SearchData, fromDate: e.target.value })
              }
              class="form-control "
              placeholder="Enter From Date"
            />
          </div>
          <div className="col-md-3 mb-3">
            <input
              type="text"
              onFocus={(e) => (e.target.type = "date")}
              value={SearchData.toDate}
              onChange={(e) =>
                setSearchData({ ...SearchData, toDate: e.target.value })
              }
              class="form-control "
              placeholder="Enter To Date"
            />
          </div>
          <div className="col-md-6 text-end col-12 mb-3">
            <div className="row">
              <div className="col-lg-4 col-lg-4 mb-lg-0 mb-2">
                <Button
                  variant="contained"
                  className="w-100"
                  onClick={() => {
                    let whereClause = `where fld_studentStatus = 'Approved' ${SearchData.stdid == ""
                      ? ""
                      : `AND UPPER(fld_studentId) like UPPER('%${SearchData.stdid}%')`
                      } ${SearchData.name == ""
                        ? ""
                        : `AND UPPER(fld_full_name) like UPPER('%${SearchData.name}%')`
                      } ${SearchData.email == ""
                        ? ""
                        : `AND UPPER(fld_email) like UPPER('%${SearchData.email}%')`
                      } ${SearchData.mobile == ""
                        ? ""
                        : `AND UPPER(fld_mobile) like UPPER('%${SearchData.mobile}%')`
                      }  ${SearchData.fromDate == "" && SearchData.toDate == ""
                        ? ""
                        : `AND cast(fld_updatedOn as date) between '${moment(
                          SearchData.fromDate
                        ).format("YYYY/MM/DD")}' and '${moment(
                          SearchData.toDate
                        ).format("YYYY/MM/DD")}'`
                      } order by ${SortData.value} ${SortData.type}`;
                    GetScholarsList(whereClause);
                  }}
                >
                  Search
                </Button>
              </div>
              <div className="col-lg-4 col-lg-4 mb-lg-0 mb-2">
                <Button
                  variant="contained"
                  className="w-100"
                  onClick={() => {
                    setListAll(false);
                    GetScholarsList();
                  }}
                >
                  Refresh
                </Button>
              </div>
              <div className="col-lg-4 col-lg-4 mb-lg-0 mb-2">
                <Button
                  variant="contained"
                  className="w-100"
                  onClick={() => {
                    setListAll(true);
                    GetScholarsList(
                      `where fld_studentStatus = 'Approved' order by ${SortData.value} ${SortData.type}`
                    );
                  }}
                >
                  List All
                </Button>
              </div>
            </div>
          </div>
          <div className="row m-0 border-top pt-2 justify-content-end ">
            <div className="col-sm-3 col-6">
              <select
                class="form-select form-select-sm "
                aria-label=".form-select-sm example"
                onChange={(e) =>
                  setSortData({ ...SortData, value: e.target.value })
                }
              >
                <option value="fld_id">Sort By</option>
                <option value="fld_studentId">Scholar ID</option>
                <option value="trim(fld_full_name)">Name</option>
                <option value="trim(fld_email)">Email</option>
                <option value="trim(fld_mobile)">Mobile</option>
              </select>
            </div>
            <div className="col-sm-2 col-6">
              <select
                class="form-select form-select-sm "
                aria-label=".form-select-sm example"
                onChange={(e) =>
                  setSortData({ ...SortData, type: e.target.value })
                }
              >
                <option value="desc">Order</option>
                <option value="asc">Ascending</option>
                <option value="desc">Descending </option>
              </select>
            </div>
            <Button
              className="col-sm-auto mt-sm-0 ms-2 mt-2 col-12"
              variant="contained"
              onClick={() => {
                GetScholarsList(
                  `where fld_studentStatus = 'Approved' ${ListAll
                    ? ""
                    : `and cast(fld_updatedOn as date) between '${new Date().getFullYear()}/09/01' and '${new Date().getFullYear() + 1
                    }/08/31'`
                  } order by ${SortData.value} ${SortData.type}`
                );
              }}
            >
              Sort
            </Button>

            <Button
              variant="contained"
              className="text-white ms-auto col-sm-auto col-12 mt-sm-0 mt-2"
            >
              <CSVLink
                className="text-white"
                filename={"ScholarList.csv"}
                data={list}
              >
                Download List{" "}
                <span style={{ textTransform: "lowercase" }}>(.csv)</span>{" "}
              </CSVLink>
            </Button>
          </div>
        </div>

        <div className='p-3 approval-search-mdbtable-hide'>
          {list.length > 0 &&

            <div className="col-sm-auto mb-sm-0 mb-2 col-6 p-0 mdb-replace-custom-common-studene-searchbar">
              <input
                className="form-control form-control-sm ml-0 common-search-bar"
                type="text"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => {
                  setSearchinput(e.target.value);
                  localStorage.setItem("searchscholarlist", e.target.value); // save to pre-fill
                }}
                value={searchInput}
              />
            </div>
          }

          {list.length > 0 ? (
            <MDBDataTable
              disableRetreatAfterSorting={true}
              responsive
              hover
              //   striped
              data={{
                columns: [
                  {
                    label: "Scholar ID",
                    field: "studentid",
                    sort: "disabled",
                  },
                  {
                    label: "Name",
                    field: "name",
                    sort: "disabled",
                  },
                  {
                    label: "Email",
                    field: "email",
                    sort: "disabled",
                  },
                  {
                    label: "Mobile",
                    field: "mobile",
                    sort: "disabled",
                  },
                  {
                    label: "Status",
                    field: "status",
                    sort: "disabled",
                  },
                  {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                  },
                ],

                rows: filteredData
                  .filter((data) => {
                    if (searchInput == "") {
                      return data;
                    }
                    if (
                      // searchInput.toString() !== "" &&
                      data.fld_studentId
                        .includes(searchInput) ||
                      `${data.fld_full_name
                        ? data.fld_full_name.toString().toLowerCase()
                        : ""
                        }`
                        .toString()
                        .includes(searchInput.toString().toLowerCase()) ||
                      `${data.fld_email != null
                        ? data.fld_email.toString().toLowerCase()
                        : null
                        }`
                        .toString()
                        .includes(searchInput.toString().toLowerCase()) ||
                      `${data.fld_mobile != null
                        ? data.fld_mobile.toString().toLowerCase()
                        : null
                        }`
                        .toString()
                        .includes(searchInput.toString().toLowerCase())
                    ) {
                      return data;
                    }
                  })
                  .map((row, i) => {
                    return {
                      studentid: row.fld_studentId,
                      name: row.fld_full_name,
                      email: row.fld_email,
                      mobile: row.fld_mobile,
                      status: row.fld_studentStatus,
                      action: (
                        <Button
                          id="basic-button"
                          aria-controls={ShowViewModal ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={ShowViewModal ? "true" : undefined}
                          onClick={(e) => {
                            setShowViewModal(e.currentTarget);
                            setSelectData(row);
                          }}
                        >
                          <MoreHorizIcon />
                        </Button>
                      ),
                    };
                  }),
              }}
              entriesOptions={[10, 25, 50, 100]}
            />
          ) : (
            <p className="m-3">No Data Found</p>
          )}
          <Menu
            id="basic-menu"
            anchorEl={ShowViewModal}
            open={ShowViewModal}
            onClose={() => setShowViewModal(null)}
            MenuListProps={{ "aria-labelledby": "basic-button" }}
          >
            <MenuItem
              onClick={() => {
                setstudentData({
                  student: SelectData,
                  action: "view",
                  type: "scholar",
                });
                history.push("/view-student");
              }}
            >
              View
            </MenuItem>
            {(login.fld_type == "Checker" || login.fld_type == "Approver") && (
              <MenuItem
                onClick={() => {
                  setstudentData({
                    student: SelectData,
                    action: "edit",
                    type: "scholar",
                  });
                  history.push("/view-student");
                }}
              >
                Edit
              </MenuItem>
            )}
            <MenuItem onClick={() => UpdateReject(SelectData)}>Reject</MenuItem>
          </Menu>
        </div>
      </div>
    </>
  );
}
