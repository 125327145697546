import React, { useState, useEffect } from "react";
import "../Login/Login.css";
import scholar from "../../assets/Image/scholar.png";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Button, { ButtonProps } from "@mui/material/Button";
import logo from "../../assets/Image/Vahanilogo.png";
import poster from "../../assets/Image/thumbnail1.webp";
import "./Register.css";
import { useForm } from "react-hook-form";
import PostApiCall from "../../assets/API/PostApi";
import moment from "moment";
import Notiflix from "notiflix";
import { NavLink } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { TbHandClick } from "react-icons/tb";
import { useHistory } from "react-router-dom";
import logo2 from "../../assets/Image/vahanilogo2.png";
import ReactPlayer from "react-player";
import FormHelperText from "@mui/material/FormHelperText";

export default function Register() {
  const history = useHistory();
  let [data, setData] = useState([]);
  let [firstName, setFirstName] = useState("");
  let [middleName, setMiddleName] = useState("");
  let [lastName, setLastName] = useState("");
  let [email, setEmail] = useState("");
  let [confirmEmail, setConfirmEmail] = useState("");
  let [mobile, setmobile] = useState("");
  let [confirmMobile, setConfirmMobile] = useState("");
  let [password, setpassword] = useState("");
  let [passwordeye, setpasswordeye] = useState(false);
  let [confirmpasswordeye, setconfirmpasswordeye] = useState(false);
  const handlepasswordclick = () => {
    setpasswordeye(!passwordeye);
  };
  useEffect(() => {
    // Notiflix.Loading.init({
    //   svgColor: "#060a4a",
    // });
    localStorage.removeItem("access");
    localStorage.removeItem("OldPassword");
    localStorage.removeItem("LoginDetail");
    localStorage.removeItem("AccessAdmin");
    localStorage.removeItem("Validation");
    fetchData();
  }, []);
  const fetchData = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_module = 'register' and fld_enabled = 'True'`,
      },
      "FormValidation"
    ).then((results10) =>
      results10.json().then((obj10) => {
        //
        setData(obj10.data);
        if (results10.status === 200 || results10.status === 201) {
          let receviedata = obj10.data;
          // setData(receviedata);
          setFirstName(obj10.data[0]);
          setMiddleName(obj10.data[1]);
          setLastName(obj10.data[2]);
          setEmail(obj10.data[3]);
          setConfirmEmail(obj10.data[4]);
          setmobile(obj10.data[5]);
          setConfirmMobile(obj10.data[6]);
          setpassword(obj10.data[7]);
        }
        // Notiflix.Loading.remove();
      })
    );
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: { firstName: "" } });
  // //
  let mob = watch("mobile");
  let ema = watch("email");
  let pass = watch("password");
  const onSubmit = (data) => {
    Notiflix.Loading.dots();
    //
    PostApiCall.postRequest(
      {
        firstName:
          data.firstName.charAt(0).toUpperCase() + data.firstName.substr(1),
        middleName:
          data.middleName.charAt(0).toUpperCase() + data.middleName.substr(1),
        lastName:
          data.lastName.charAt(0).toUpperCase() + data.lastName.substr(1),
        mobile: data.mobile,
        email: data.email,
        year: moment().format("YYYY"),
        password: data.password,
        registeredon: moment().format("lll"),
        updatedOn: moment().format("lll"),
        updatedBy: 0,
        adminAccess: null,
      },
      "RegisterStudent"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Thanks for applying to Vahani Scholarship");
          history.push("/");
          // window.location.href = '/'
          // this.props.history.push("/");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };
  return (
    <>
      <div className="text-center">
        <img
          className="register-logo"
          src={require("../../assets/Image/vahanilogo2.png")}
        />
      </div>
      <form className="px-lg-3" onSubmit={handleSubmit(onSubmit)}>
        <div className="text-field">
          <div className="row">
            <div className="col-md-4 col-12 ">
              <TextField
                fullWidth
                id="outlined-required"
                label={
                  firstName.fld_field_name
                    ? firstName.fld_field_name
                    : "First Name*"
                }
                variant="outlined"
                className="text-field-input capital"
                {...register("firstName", {
                  required: firstName.fld_error_message,
                  // maxLength: {
                  //   value: 5,
                  //   message: "maxchar",
                  // },
                })}
                error={!!errors?.firstName}
                helperText={errors?.firstName ? errors.firstName.message : null}
              />
            </div>
            <div className="col-md-4 col-12">
              <TextField
                fullWidth
                id="outlined-basic"
                label={
                  middleName.fld_field_name
                    ? middleName.fld_field_name
                    : "Middle Name"
                }
                variant="outlined"
                className="text-field-inputcapital "
                {...register("middleName", {
                  // required: "Enter middle name",
                  // maxLength: {
                  //   value: 5,
                  //   message: "maxchar",
                  // },
                })}
              // error={!!errors?.middleName}
              // helperText={
              //   errors?.middleName
              //     ? errors.middleName.message
              //     : null
              // }
              />
            </div>
            <div className="col-md-4 col-12">
              <TextField
                fullWidth
                id="outlined-basic"
                label={
                  lastName.fld_field_name
                    ? lastName.fld_field_name
                    : "Last Name*"
                }
                variant="outlined"
                className="text-field-input  capital"
                {...register("lastName", {
                  required: lastName.fld_error_message,
                  // maxLength: {
                  //   value: 5,
                  //   message: "maxchar",
                  // },
                })}
                error={!!errors?.lastName}
                helperText={errors?.lastName ? errors.lastName.message : null}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <TextField
                fullWidth
                id="outlined-basic"
                label={email.fld_field_name ? email.fld_field_name : "Email*"}
                // type="email"
                variant="outlined"
                className="text-field-input"
                style={{ width: "100%" }}
                {...register("email", {
                  required: email.fld_error_message,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                error={!!errors?.email}
                helperText={errors?.email ? errors.email.message : null}
              />
            </div>
            <div className="col-md-6 col-12">
              <TextField
                fullWidth
                id="outlined-basic"
                label={
                  confirmEmail.fld_field_name
                    ? confirmEmail.fld_field_name
                    : " Confirm Email*"
                }
                variant="outlined"
                className="text-field-input"
                style={{ width: "100%" }}
                {...register("confirmEmail", {
                  required: confirmEmail.fld_error_message,
                  validate: (value) =>
                    value == ema || "The password do not match",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                error={!!errors?.confirmEmail}
                helperText={
                  errors?.confirmEmail ? errors.confirmEmail.message : null
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <TextField
                fullWidth
                id="outlined-basic"
                label={
                  mobile.fld_field_name
                    ? mobile.fld_field_name
                    : "Mobile Number*"
                }
                variant="outlined"
                className="text-field-input mg-textfield  capital"
                style={{ width: "100%" }}
                {...register("mobile", {
                  required: mobile.fld_error_message,
                  minLength: {
                    value: 10,
                    message: "Enter minimum 10-digit number",
                  },
                  pattern: {
                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    message: "Enter valid mobile number",
                  },
                })}
                error={!!errors?.mobile}
                helperText={errors?.mobile ? errors.mobile.message : null}
                inputProps={{ maxLength: 10 }}
              />
            </div>
            <div className="col-md-6 col-12">
              <TextField
                fullWidth
                id="outlined-basic"
                label={
                  confirmMobile.fld_field_name
                    ? confirmMobile.fld_field_name
                    : "Confirm Mobile Number"
                }
                variant="outlined"
                className="text-field-input  capital "
                style={{ width: "100%" }}
                {...register("ConfirmMobile", {
                  required: confirmMobile.fld_error_message,
                  validate: (value) =>
                    value == mob || "The mobile do not match",
                  minLength: {
                    value: 10,
                    message: "Enter minimum 10-digit number",
                  },
                  pattern: {
                    value: /^[0-9\b]+$/,
                    message: "Enter valid mobile numbe",
                  },
                })}
                error={!!errors?.ConfirmMobile}
                helperText={
                  errors?.ConfirmMobile ? errors.ConfirmMobile.message : null
                }
                inputProps={{
                  maxLength: 10,
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <div style={{ position: "relative" }}>
                <TextField
                  fullWidth
                  type={passwordeye === false ? "password" : "text"}
                  id="outlined-basic"
                  label={
                    password.fld_field_name
                      ? password.fld_field_name
                      : "Password"
                  }
                  variant="outlined"
                  className="text-field-input"
                  {...register("password", {
                    required: password.fld_error_message,
                    minLength: {
                      value: 6,
                      message: "Enter minimum 6-digit password",
                    },
                    maxLength: {
                      value: 8,
                      message: "Enter maximum 8-digit password",
                    },
                    pattern: {
                      value:
                        /(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                      message:
                        "Password must contain at least one number and one uppercase and lowercase letter",
                    },
                  })}
                  error={!!errors?.password}
                  helperText={errors?.password ? errors.password.message : null}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "15px",
                    left: "calc(100% - 40px)",
                  }}
                >
                  {!passwordeye ? (
                    <AiFillEyeInvisible
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={handlepasswordclick}
                    />
                  ) : (
                    <AiFillEye
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={handlepasswordclick}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div style={{ position: "relative" }}>
                <TextField
                  fullWidth
                  type={confirmpasswordeye === false ? "password" : "text"}
                  id="outlined-basic"
                  label="Confirm password*"
                  variant="outlined"
                  className="text-field-input"
                  {...register("Confirmpassword", {
                    required: "This field is required",
                    validate: (value) =>
                      value == pass || "The password does not match",
                    minLength: {
                      value: 6,
                      message: "Enter minimum 6-digit password",
                    },
                    maxLength: {
                      value: 8,
                      message: "Enter maximum 8-digit password",
                    },
                    pattern: {
                      value:
                        /(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                      message:
                        "Password must contain at least one number and one uppercase and lowercase letter",
                    },
                  })}
                  error={errors?.Confirmpassword}
                  helperText={
                    errors?.Confirmpassword
                      ? errors.Confirmpassword.message
                      : null
                  }
                />
                <div
                  style={{
                    position: "absolute",
                    top: "15px",
                    left: "calc(100% - 40px)",
                  }}
                >
                  {!confirmpasswordeye ? (
                    <AiFillEyeInvisible
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => setconfirmpasswordeye(!confirmpasswordeye)}
                    />
                  ) : (
                    <AiFillEye
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => setconfirmpasswordeye(!confirmpasswordeye)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-label-login class-checkbox">
            <FormControl
              fullWidth
              size="small"
              error={Boolean(errors.label)}
              className="flex-column"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    {...register("label", {
                      required: "select are you in 12th class",
                    })}
                    error={!!errors?.label}
                  />
                }
                label="Are you studying in class XII?*"
              />
              <FormHelperText>
                {errors?.label ? errors.label.message : null}
              </FormHelperText>
            </FormControl>
          </div>
          <small className="my-3">
            <span className="text-danger">*</span>This Scholarship is available
            for class 12 students.{" "}
          </small>
          <Button
            variant="contained"
            className="but-login-vahani"
            type="submit"
          >
            Register
          </Button>
          {/* <div className="hide-mob-lap">
            <p className="link-register">
              Already have an account?{" "}
              <NavLink exact to="/" activeClassName="active">
                Login
              </NavLink>
            </p>
          </div>*/}
          {/* <p className="css-399fq3 ">
            Copyright © 2022 Vahani Scholarship. All rights reserved.
            Powered by Global Trendz
          </p>
          <div className="mobile-link-register">
            <p className="link-register-mobile">
              Already have an account? <a href="">Login</a>
            </p>
          </div> */}
        </div>
      </form>
    </>
  );
}
