import React, { useContext } from "react";
// import { MobileFields, TravelFields } from "../../../Components/Form/ExpenseForm/demo";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
// import { Captions } from "react-html5video/dist";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { useForm, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import PostApiCall from "../../../assets/API/PostApi";
// import GetApiCall from "../../../assets/API/GetApi";
// import moment from "moment";
import Notiflix from "notiflix";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import store from "../../../store";
// import { TramRounded } from "@mui/icons-material";
import SweetAlert from "react-bootstrap-sweetalert";
function PreviewExpenseForm() {
    const history = useHistory();
    const param = useParams();
    // //
    const { expenseData } = useContext(store)
    const { register, handleSubmit, setValue, watch, reset, formState: { errors }, } = useForm();
    // const [expense_id, setexpense_id] = useState(new URLSearchParams(location.search).get("id"))
    const [materialType, setMaterialType] = useState("");
    const [expenseType, setExpenseType] = useState("");
    const [fields, setFields] = useState([]);
    const [expenseFields, setexpenseFields] = useState([]);
    // const [value, setValue] = useState(null);
    const [fieldValue, setFieldValue] = useState("");
    const [attributeValue, setAttributeValue] = useState([]);
    const [dateValue, setDateValue] = useState(null);
    const [expenseHeadData, setExpenseHeadData] = useState([]);
    const [dataType, setdataType] = useState("")
    const [showalert, setshowalert] = useState(false);
    const [submitType, setsubmitType] = useState("")
    const [formData, setformData] = useState("")
    const [source, setsource] = useState("")
    const GetExpenseData = (id) => {
        Notiflix.Loading.dots()
        PostApiCall.postRequest(
            {
                whereClause: `where fld_expense_type=${id} order by fld_sequence asc`,
            },
            "Get_Expense_Config_Master"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    //
                    setexpenseFields(obj.data)
                    Notiflix.Loading.remove();
                }
            }))
    }
    useEffect(() => {
        GetExpenseData(param.id)
    }, [])
    const onSubmit = (expense) => {
        //
        //
    };
    //
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-vahani">
                    <p className="personal-info">Preview Form</p>
                    <div className="App">
                        <div>
                            <Row>
                                {expenseFields.map((item, index) => {
                                    switch (item.fld_field_type) {
                                        case "Input String":
                                            return (
                                                <div className="col-md-4 mb-3">
                                                    <TextField
                                                        fullWidth
                                                        type="text"
                                                        {...register(`${item?.fld_config_id}`, { required: item.fld_mandatory == "Yes" ? item.fld_error_message : false })}
                                                        error={errors[item.fld_config_id]}
                                                        helperText={errors[item.fld_config_id]?.message ? errors[item.fld_config_id].message : item.fld_help_test}
                                                        id={index}
                                                        label={item.fld_field_name}
                                                        variant="outlined"
                                                        InputLabelProps={{ required: item.fld_mandatory == "Yes" ? true : false }}
                                                    />
                                                </div>
                                            )
                                        case "Input Numeric":
                                            return (
                                                <div className="col-md-4 mb-3">
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        {...register(`${item?.fld_config_id}`, { required: item.fld_mandatory == "Yes" ? item.fld_error_message : false })}
                                                        error={errors[item.fld_config_id]}
                                                        helperText={errors[item.fld_config_id]?.message ? errors[item.fld_config_id].message : item.fld_help_test}
                                                        id={index}
                                                        label={item.fld_field_name}
                                                        variant="outlined"
                                                        InputLabelProps={{ required: item.fld_mandatory == "Yes" ? true : false }}
                                                    />
                                                </div>
                                            )
                                        case "Calendar":
                                            return (
                                                <div className="col-md-4">
                                                    {/* <CalenderBox item={item} ref={register} /> */}
                                                    <TextField
                                                        id={index}
                                                        type="date"
                                                        fullWidth
                                                        {...register(`${item?.fld_config_id}`, { required: item.fld_mandatory == "Yes" ? item.fld_error_message : false })}
                                                        error={errors[item.fld_config_id]}
                                                        helperText={errors[item.fld_config_id]?.message ? errors[item.fld_config_id].message : item.fld_help_test}
                                                        label={item.fld_field_name}
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true, required: item.fld_mandatory == "Yes" ? true : false }}
                                                    />
                                                </div>
                                            )
                                        case "Dropdown":
                                            return (
                                                <div className="col-md-4">
                                                    <FormControl fullWidth error={errors[item.fld_config_id]} >
                                                        <InputLabel id={index} required={item.fld_mandatory == "Yes" ? true : false} >{item.fld_field_name}</InputLabel>
                                                        <Select
                                                            id={index}
                                                            {...register(`${item?.fld_config_id}`, { required: item.fld_mandatory == "Yes" ? item.fld_error_message : false })}
                                                            label={item.fld_field_name}
                                                        >
                                                            {item.fld_value != null && item.fld_value.split(",").map(val => <MenuItem value={val}>{val}</MenuItem>)}
                                                        </Select>
                                                        <FormHelperText>{errors[item.fld_config_id]?.message ? errors[item.fld_config_id].message : item.fld_help_test}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            )
                                        case "Radio":
                                            return (
                                                <div className="col-md-4">
                                                    {/* <RadioBox item={item} ref={register} /> */}
                                                    <FormControl fullWidth error={errors[item.fld_config_id]}>
                                                        <FormLabel id={index} required={item.fld_mandatory == "Yes" ? true : false}>{item.fld_field_name}</FormLabel>
                                                        <RadioGroup row
                                                            name={item.fld_field_name}
                                                            {...register(`${item?.fld_config_id}`, { required: item.fld_mandatory == "Yes" ? item.fld_error_message : false })}
                                                        >
                                                            {item.fld_value != null && item.fld_value.split(",").map(val => <FormControlLabel value={val} control={<Radio />} label={val} />)}
                                                        </RadioGroup>
                                                        <FormHelperText>{errors[item.fld_config_id]?.message ? errors[item.fld_config_id].message : item.fld_help_test}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            )
                                        case "Checkbox":
                                            return (
                                                <div className="col-md-4">
                                                    <FormControl fullWidth variant="standard" error={errors[item.fld_config_id]} >
                                                        <FormControlLabel control={<Checkbox name={item.fld_field_name} {...register(`${item?.fld_config_id}`, { required: item.fld_mandatory == "Yes" ? item.fld_error_message : false })} />} label={item.fld_field_name} />
                                                        <FormHelperText>{errors[item.fld_config_id]?.message ? errors[item.fld_config_id].message : item.fld_help_test}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            )
                                        case "ImageUpload":
                                            return (
                                                <div className="col-md-4">
                                                    <TextField
                                                        fullWidth
                                                        {...register(`${item?.fld_config_id}`, { required: item.fld_mandatory == "Yes" ? item.fld_error_message : false })}
                                                        id="outlined-basic"
                                                        label={item.fld_field_name}
                                                        variant="outlined"
                                                        className="text-field"
                                                        type="File"
                                                        InputLabelProps={{ shrink: true, }}
                                                        error={errors[item.fld_config_id]}
                                                        helperText={errors[item.fld_config_id]?.message ? errors[item.fld_config_id].message : item.fld_help_test}
                                                    />
                                                </div>
                                            )
                                        case "PDFUpload":
                                            return (
                                                <div className="col-md-4">
                                                    <TextField
                                                        fullWidth
                                                        {...register(`${item?.fld_config_id}`, { required: item.fld_mandatory == "Yes" ? item.fld_error_message : false })}
                                                        id="outlined-basic"
                                                        label={item.fld_field_name}
                                                        variant="outlined"
                                                        className="text-field"
                                                        type="File"
                                                        InputLabelProps={{ shrink: true, }}
                                                        error={errors[item.fld_config_id]}
                                                        helperText={errors[item.fld_config_id]?.message ? errors[item.fld_config_id].message : item.fld_help_test}
                                                    />
                                                </div>
                                            )
                                    }
                                })}
                            </Row>
                        </div>
                    </div>
                    <div className="row m-0 mt-4 justify-content-between">
                        <button type="submit" className="col-2 btn btn-primary">Check</button>
                        <button className="col-2 btn btn-primary" onClick={() => setshowalert(true)}>Finish</button>
                    </div>
                </div>
            </form>
            {showalert &&
                <SweetAlert
                    title="Are you sure to save the expense form ? "
                    custom
                    showCancel
                    showCloseButton
                    onConfirm={() => "rahul"} onCancel={() => setshowalert(false)}
                    customButtons={
                        <React.Fragment>
                            <button onClick={() => setshowalert(false)} className="btn btn-danger mx-2">Cancel</button>
                            <button className="btn btn-success" onClick={() => {
                                PostApiCall.postRequest(
                                    {
                                        expenseid: param.id
                                    },
                                    "Update_Activate_Expense_Master"
                                ).then((results) =>
                                    results.json().then((obj) => {
                                        if (results.status === 200 || results.status === 201) {
                                            //
                                            Notiflix.Loading.remove();
                                            history.push("/expense-master")
                                        }
                                    })
                                )
                            }} >Yes</button>
                        </React.Fragment>
                    }
                > </SweetAlert>
            }
        </>
    );
}
export default PreviewExpenseForm;
