import React, { useContext, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NewStudentList from './NewStudentList';
import ShortedStudentList from './ShortedStudentList';
import ApprovedStudentList from './ApprovedStudentList';
import RejectStudentList from './RejectStudentList';
import store from '../../../store';
const ViewStudentList = () => {
    const [listType, setlistType] = React.useState("New"); // show the new list by default
    const { studentData } = useContext(store);
    useEffect(() => {
        setlistType(studentData?.page == undefined ? "New" : studentData.page)
    }, [])
    return (
        <>
            <div className="form-head row m-0 justify-content-between align-items-start">
                <div className='col-6 ' style={{ borderLeft: "10px solid rgb(32, 101, 209)", paddingLeft: "10px", }} >
                    <h4>Student List </h4>
                </div>
                <div className='col-md-2 col-6 mb-2'>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Student Type</InputLabel>
                        {/* Dropdown */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={listType} // new  by default
                            label="Student Type"
                            onChange={e => setlistType(e.target.value)}
                        >
                            {/* dropdown options */}
                            <MenuItem value="New">New</MenuItem>
                            <MenuItem value="Sortlisted">Shortlisted</MenuItem>
                            <MenuItem value="Approved">Approved</MenuItem>
                            <MenuItem value="Rejected">Rejected</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            {/* different lists to be rendered */}
            {listType == "New" && <NewStudentList />}
            {listType == "Sortlisted" && <ShortedStudentList />}
            {listType == "Approved" && <ApprovedStudentList />}
            {listType == "Rejected" && <RejectStudentList />}
        </>
    )
}
export default ViewStudentList