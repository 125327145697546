import React, { Component } from "react";
import Notiflix from "notiflix";
import PostApiCall from "../../../../assets/API/PostApi";
import moment from "moment";
import { XSquare } from "react-feather";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

class Update_DayOff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PageTitle: "1",
      Page1: "Pending",
      Question: "",
      Answer: "",
      Data: [],
      Details: [],
      QuestionId: [],
      InformationData: [],
      Name: "",
      Type: "",
      Notes: "",
      Days: "",
      StartDate: "",
      EndDate: "",
      LeaveData: [
        {
          value: "Privilege Leave/Earned Leave/Annual Leave",
          label: "Privilege Leave/Earned Leave/Annual Leave",
        },
        { value: "Casual Leave", label: "Casual Leave" },
        { value: "Sick Leave", label: "Sick Leave" },
        { value: "Maternity Leave", label: "Maternity Leave" },
        { value: "Marriage Leave", label: "Marriage Leave" },
        { value: "Paternity Leave", label: "Paternity Leave" },
        { value: "Bereavement Leave", label: "Bereavement Leave" },
        { value: "Compensatory Off", label: "Compensatory Off" },
        { value: "Loss Of Pay Leave", label: "Loss Of Pay Leave" },
      ],
      NameData: [],
      NumRegex: /^[0-9]*$/,
      Details: [],
      Status: "approved",
      LeaveId: null,
      LeaveType: "",
      LeaveDays: 0,
      StartDate: "",
      EndDate: "",
      LeavesNotes: "",
      ReasonOfRejection: "",
      EmpName: "",
      EmpId: null,
      RaisedOn: "",
      ModalShow: false,
      EmpEmail: "",
      EmpFile: "",
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "/assets/js/pages/form-wizard.init.js";
    script.async = true;
    document.body.appendChild(script);

    Notiflix.Loading.init({
      svgColor: "#060a4a",
    });

    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);

    this.setState({
      Details: details[0],
    });

    var det = localStorage.getItem("dayoffdetail");
    var DayOffData = JSON.parse(det);

    this.setState({
      LeaveId:
        this.props.location.state === undefined
          ? 0
          : this.props.location.state.leaveid,
    });
    if (this.props.location.state != undefined) {
      this.getLeaveDetails();
    }
  }

  //  get leaves fpor a specific employee
  getLeaveDetails = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_id=${this.props.location.state.leaveid}`,
      },
      "GetLeaveById"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.dots("Please wait...");
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            this.setState({
              EmpId: obj.data[0].fld_emp_id,
              EmpName: obj.data[0].fld_employee_name,
              LeaveType: obj.data[0].fld_leave_type,
              EmpEmail: obj.data[0].fld_email,
              LeaveDays: obj.data[0].fld_leave_days,
              StartDate: obj.data[0].fld_star_date,
              EndDate: obj.data[0].fld_end_date,
              RaisedOn: obj.data[0].fld_raised_date,
              LeavesNotes: obj.data[0].fld_leave_notes,
              ReasonOfRejection: obj.data[0].fld_reason_of_rejection,
              EmpFile: obj.data[0].leave_proof_doc,
              Status: obj.data[0].fld_status,
            });
            Notiflix.Loading.remove();
          }
        }
        Notiflix.Loading.remove();
      })
    );
  };

  SaveLeave(status) {
    if (this.state.LeaveType != "") {
      if (this.state.LeavesNotes != "") {
        if (this.state.StartDate != "") {
          if (this.state.EndDate != "") {
            this.onPost(status);
          } else {
            Notiflix.Notify.failure("Please select End Date");
          }
        } else {
          Notiflix.Notify.failure("Please select Start Date");
        }
      } else {
        Notiflix.Notify.failure("Please Select Leave Notes");
      }
    } else {
      Notiflix.Notify.failure("Please select Leave Type");
    }
  }

  onPost = (status) => {
    Notiflix.Loading.dots("Please wait...");

    PostApiCall.postRequest(
      {
        id: this.state.LeaveId,
        employeeid: this.state.EmpId,
        leavetype: this.state.LeaveType,
        leavedays: this.state.LeaveDays,
        startdate: this.state.StartDate,
        email: this.state.EmpEmail,
        enddate: this.state.EndDate,
        raiseddate: this.state.RaisedOn,
        leavenotes: this.state.LeavesNotes,
        status: status,
        reasonofrejection: this.state.ReasonOfRejection,
        employeename: this.state.EmpName,
      },
      "AddLeave"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Leave successfully updated.");
          window.history.go(-1);
        } else {
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  render() {
    return (
      <>
        <div className="form-head mb-lg-2 mb-3">
          <div
            className="col-auto "
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Update Leave</h4>
          </div>
        </div>
        <div className="card-vahani p-0 mt-2">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body p-0">
                  <div id="smartwizard-arrows">
                    <div className="row m-0 justify-content-between align-items-center left-line-card px-4 pt-3 pb-3">
                      <div className="personal-info m-0 p-0 col-auto">
                        Update Leave{" "}
                      </div>
                      <div className="col-md-2 ps-0 pe-0">
                        <Button variant="contained" className="w-100">
                          <Link to="/dayoff-list" className="text-white">
                            Back to List
                          </Link>
                        </Button>
                      </div>
                    </div>

                    <div className="p-3" style={{ minHeight: "0px" }}>
                      <div
                        id="sw-arrows-step-1"
                        className="tab-pane step-content"
                        style={{
                          display:
                            this.state.PageTitle == "1" ? "block" : "none",
                        }}
                      >
                        <form
                          className="needs-validation"
                          novalidate
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <div
                            className="vahani-card text-start"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                            data-toggle="toast"
                          >
                            <div class="toast-body">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="form-group mb-2">
                                        <label for="validationCustom05">
                                          Leave Type
                                          <span className="mandatory">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="validationCustom05"
                                          value={this.state.LeaveType}
                                          readonly
                                        />
                                      </div>
                                    </div>

                                    <div class="col-md-6">
                                      <div class="form-group mb-2">
                                        <label for="validationCustom05">
                                          Total Days
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="validationCustom05"
                                          value={this.state.LeaveDays}
                                          readonly
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="form-group mb-2">
                                        <label for="validationCustom05">
                                          Start Date
                                          <span className="mandatory">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={moment(
                                            this.state.StartDate
                                          ).format("DD/MM/YYYY")}
                                          readonly
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="form-group mb-2">
                                        <label for="validationCustom05">
                                          End Date
                                          <span className="mandatory">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={moment(
                                            this.state.EndDate
                                          ).format("DD/MM/YYYY")}
                                          readonly
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-12">
                                      <div class="form-group mb-2">
                                        <label for="validationCustom05">
                                          Leave Notes
                                          <span className="mandatory">*</span>
                                        </label>
                                        <textarea
                                          type="text"
                                          class="form-control"
                                          id="validationCustom05"
                                          value={this.state.LeavesNotes}
                                          readonly
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                          {
                            this.state.EmpFile != null &&
                            <a href={this.state.EmpFile} target="_blank">Leave Application Attachment</a>
                          }
                          <div
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                            data-toggle="toast"
                          >
                            <div
                              className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 ms-3"
                              style={{ float: "right" }}
                            >
                              <div
                                className=" justify-content-end"
                                style={{ display: "flex" }}
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    display:
                                      this.state.Status != "Pending"
                                        ? "none"
                                        : "block",
                                  }}
                                  className="btn-approved"
                                  onClick={() => {
                                    this.setState({ Status: "Approved" });
                                    this.SaveLeave("Approved");
                                  }}
                                >
                                  Approve
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                            data-toggle="toast"
                          >
                            <div
                              className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3"
                              style={{ float: "right" }}
                            >
                              <div
                                className=" justify-content-end"
                                style={{ display: "flex" }}
                              >
                                <Button
                                  variant="contained"
                                  className="btn-reject"
                                  style={{
                                    display:
                                      this.state.Status != "Pending"
                                        ? "none"
                                        : "block",
                                  }}
                                  onClick={() => {
                                    this.setState({ ModalShow: true });
                                  }}
                                >
                                  Reject
                                </Button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {" "}
        <Modal
          show={this.state.ModalShow}
          onHide={() => this.setState({ ModalShow: false })}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal-90w"
          backdrop="static"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <>
              <div class="col-md-12">
                <div class="form-group mb-2">
                  <label for="validationCustom05">
                    Reason of Rejection
                    <span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="validationCustom05"
                    value={this.state.ReasonOfRejection}
                    onChange={(e) => {
                      this.setState({ ReasonOfRejection: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                data-toggle="toast"
              >
                <div
                  className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 ms-3"
                  style={{ float: "right" }}
                >
                  <div
                    className=" justify-content-end"
                    style={{ display: "flex" }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (this.state.ReasonOfRejection != "") {
                          Notiflix.Loading.dots("Please wait...");

                          PostApiCall.postRequest(
                            {
                              id: this.state.LeaveId,
                              employeeid: this.state.EmpId,
                              leavetype: this.state.LeaveType,
                              leavedays: this.state.LeaveDays,
                              startdate: this.state.StartDate,
                              enddate: this.state.EndDate,
                              raiseddate: this.state.RaisedOn,
                              leavenotes: this.state.LeavesNotes,
                              email: this.state.EmpEmail,
                              status: "Rejected",
                              reasonofrejection: this.state.ReasonOfRejection,
                              employeename: this.state.EmpName,
                            },
                            "AddLeave"
                          ).then((resultcategory) =>
                            resultcategory.json().then((obj) => {
                              if (
                                resultcategory.status == 200 ||
                                resultcategory.status == 201
                              ) {
                                Notiflix.Loading.remove();
                                Notiflix.Notify.success(
                                  "Leave successfully updated."
                                );
                                window.history.go(-1);
                              } else {
                                Notiflix.Notify.failure(obj.data);
                              }
                            })
                          );
                        } else {
                          Notiflix.Notify.failure(
                            "Please enter the reason of rejection!"
                          );
                        }
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Update_DayOff;
