import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "./Login.css";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { NavLink, useHistory } from "react-router-dom";
import GetApiCall from "../../assets/API/GetApi";
import PostApiCall from "../../assets/API/PostApi";
import store from "../../store";
var AccessAdmin = "Yes";

export default function Login() {
  const history = useHistory();
  let EmailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let [email, setEmail] = useState("");
  let [data, setData] = useState([]);
  let [password, setpassword] = useState("");
  const { setVendorAccess } = useContext(store);
  let [Registration, setRegistration] = useState({
    RegistrationData: "",
    RegistrationStatus: "",
  });
  const [isClosed, setIsClosed] = useState(true); // track the registration status
  let [passwordeye, setpasswordeye] = useState(false);
  let [ShowModal, setShowModal] = useState(false);
  let [ShowEmailBox, setShowEmailBox] = useState(false);
  let [ShowOTPBox, setShowOTPBox] = useState(false);
  let [ShowPasswordBox, setShowPasswordBox] = useState(false);
  let [newpasswordeye, setnewpasswordeye] = useState(false);
  let [confirmpasswordeye, setconfirmpasswordeye] = useState(false);
  let [salt, setSalt] = useState("");
  let [forgetForm, setforgetForm] = useState({
    email: "",
    salt: "",
    otp: "",
    confirmotp: "",
    password: "",
    confirmpassword: "",
  });
  const handlepasswordclick = () => {
    setpasswordeye(!passwordeye);
  };
  useEffect(() => {
    // Notiflix.Loading.init({
    //   svgColor: "#060a4a",
    // });
    localStorage.removeItem("access");
    localStorage.removeItem("OldPassword");
    localStorage.removeItem("LoginDetail");
    localStorage.removeItem("AccessAdmin");
    localStorage.removeItem("Validation");
    // Get_RegistrationMaster();
    // fetchData();
  }, []);


  useEffect(() => {
    GetApiCall.getRequest("Get_RegistrationMaster").then((resultdes) =>
      resultdes.json().then((obj) => {
        setIsClosed(obj.data[0].fld_registrationStatus
          === "YES" ? true : false)
      })
    );
  }, []);
  // const Get_RegistrationMaster = () => {
  //   GetApiCall.getRequest("Get_RegistrationMaster").then((resultdes) =>
  //     resultdes.json().then((obj) => {
  //       //
  //       setRegistration({
  //         RegistrationData: obj.data,
  //         RegistrationStatus: obj.data[0]?.fld_registrationStatus,
  //       });
  //       // Notiflix.Loading.Remove();
  //     })
  //   );
  //   localStorage.removeItem("LoginDetail");
  // };
  const fetchData = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_module = 'login' and fld_enabled = 'True'`,
      },
      "FormValidation"
    ).then((results10) =>
      results10.json().then((obj10) => {
        if (results10.status === 200 || results10.status === 201) {
          // this.setState({
          //   HistoryData: obj10.data,
          // });
          let receviedata = obj10.data;
          setData(receviedata);
          setEmail(obj10.data[0]);
          setpassword(obj10.data[1]);
        }
        // Notiflix.Loading.remove();
      })
    );
  };

  // Login function
  const onLogin = (data) => {
    // window.location.href='/dashboard'
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetCommonValidation"
    ).then((results10) =>
      results10.json().then((obj10) => {
        if (results10.status === 200 || results10.status === 201) {
          localStorage.setItem("CommonValidation", JSON.stringify(obj10.data));
          Notiflix.Loading.remove();
        }
        Notiflix.Loading.remove();
      })
    );
    PostApiCall.postRequest(
      {
        email: data.email,
        password: data.password,
      },
      "AuthenticateStudent"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)

      results.json().then((obj) => {

        if (results.status == 200 || results.status == 201) {
          localStorage.setItem("access", JSON.stringify(obj.token));

          if (obj.data[0].fld_AdminAccess == 1 && obj.data[0].fld_type != "Vendor Management") {
            // Admin specific login || dashborad
            localStorage.setItem("OldPassword", JSON.stringify(data.password));
            localStorage.setItem("LoginDetail", JSON.stringify(obj.data));
            localStorage.setItem("AccessAdmin", JSON.stringify(AccessAdmin));
            Notiflix.Loading.remove();
            window.location.href = "/dashboard";
          } else if (obj.data[0].fld_type == "Scholar") {
            // scholar specifc || dashboard
            localStorage.setItem("OldPassword", JSON.stringify(data.password));
            localStorage.setItem("LoginDetail", JSON.stringify(obj.data));
            localStorage.removeItem("AdminDetails");
            Notiflix.Loading.remove();
            window.location.href = "/dashboard";
          } else if (obj.data[0].fld_type == "Vendor Management") {
            // scholar specifc || dashboard
            localStorage.setItem("OldPassword", JSON.stringify(data.password));
            localStorage.setItem("LoginDetail", JSON.stringify(obj.data));
            setVendorAccess("true");
            localStorage.setItem("vendoraccess", "true");
            localStorage.setItem("AccessAdmin", JSON.stringify(AccessAdmin));
            localStorage.removeItem("AdminDetails");
            Notiflix.Loading.remove();
            window.location.href = "/vendor-dashboard";
          } else if (obj.data[0].fld_AdminAccess == 0) {
            // employee specific || dashboard
            localStorage.setItem("OldPassword", JSON.stringify(data.password));
            localStorage.setItem("LoginDetail", JSON.stringify(obj.data));
            Notiflix.Loading.remove();
            window.location.href = "/employeedashboard";
          } else {
            if (isClosed) {
              localStorage.setItem("OldPassword", JSON.stringify(data.password));
              localStorage.setItem("LoginDetail", JSON.stringify(obj.data));
              localStorage.setItem("Video_Popup", true);
              Notiflix.Loading.remove();
              window.location.href = "/dashboard";
            } else {
              Notiflix.Notify.failure("Registration is closed!")
            }
            // student specific || dashboard

          }


        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // Notiflix.Loading.dots();
    onLogin(data);
  };
  const GetSalt = () => {
    PostApiCall.postRequest(
      {
        email: forgetForm.email,
      },
      "Get_SaltByEmailId_ForgotPassword"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setSalt(obj.data[0].fld_salt);
          // setforgetForm({ ...forgetForm, salt: obj.data[0].fld_salt });
          //
          ////
        }
      })
    );
  };
  return (
    <>
      <form className="px-lg-3" onSubmit={handleSubmit(onSubmit)}>
        <div className="text-center">
          <img
            className="login-logo"
            src={require("../../assets/Image/vahanilogo2.png")}
          />
        </div>
        <div>
          <div className="text-field">
            <TextField
              label="Email Address*"
              variant="outlined"
              className="text-field-input"
              {...register("email", {
                required: "Enter Email",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              error={!!errors?.email}
              helperText={errors?.email ? errors.email.message : null}
            />
            <div className="position-relative">
              <TextField
                id="outlined-basic"
                label="Password*"
                variant="outlined"
                type={passwordeye === false ? "password" : "text"}
                className="text-field-input"
                {...register("password", {
                  required: "Please enter password",
                })}
                error={errors?.password}
                helperText={errors?.password ? errors.password.message : null}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: errors?.password ? "50px" : "25px",
                  left: "calc(100% - 40px)",
                }}
              >
                {!passwordeye ? (
                  <AiFillEyeInvisible
                    style={{ fontSize: "25px", cursor: "pointer" }}
                    onClick={handlepasswordclick}
                  />
                ) : (
                  <AiFillEye
                    style={{ fontSize: "25px", cursor: "pointer" }}
                    onClick={handlepasswordclick}
                  />
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <small
                className="text-primary cursor-pointer"
                onClick={() => {
                  setShowModal(true);
                  setShowEmailBox(true);
                }}
              >
                Forgot your Password?
              </small>
            </div>
            <Button
              variant="contained"
              className="but-login-vahani mt-3"
              type="submit"
            >
              Login
            </Button>
            <NavLink to="/employeelogin" className="mt-2">
              Login to Employee Panel
            </NavLink>

            {/* <div className="mobile-link-register">
              <p className="link-register-mobile">
                Don’t have an account?{" "}
                <a href="/register">Get started</a>
              </p>
            </div> */}
          </div>
        </div>
      </form>
      <Dialog
        onClose={() => {
          setShowModal(false);
          setShowEmailBox(false);
          setShowOTPBox(false);
          setShowPasswordBox(false);
          setnewpasswordeye(false);
          setconfirmpasswordeye(false);
          setforgetForm({
            email: "",
            salt: "",
            otp: "",
            confirmotp: "",
            password: "",
            confirmpassword: "",
          });
        }}
        open={ShowModal}
        fullWidth
      >
        <DialogTitle>Forgot Your Password?</DialogTitle>
        <DialogContent dividers>
          {ShowEmailBox && (
            <div className="row m-0 ">
              <p className="ps-0">
                Please enter your registered email address. An email
                notification with an OTP will then be sent to you.
              </p>
              <TextField
                label="Enter Email"
                value={forgetForm.email}
                onChange={(e) =>
                  setforgetForm({ ...forgetForm, email: e.target.value })
                }
                fullWidth
              />
              <Button
                variant="contained"
                className="but-login-vahani mt-3"
                onClick={() => {
                  if (forgetForm.email != "") {
                    if (EmailRegex.test(forgetForm.email)) {
                      Notiflix.Loading.dots();
                      let genOTP = Math.floor(100000 + Math.random() * 900000);
                      PostApiCall.postRequest(
                        {
                          OTP: genOTP,
                          email: forgetForm.email,
                        },
                        "Mailer_ForgotPassword"
                      ).then((result) =>
                        result.json().then((obj) => {
                          if (result.status == 200 || result.status == 201) {
                            Notiflix.Loading.remove();
                            Notiflix.Notify.success(
                              "Your verification OTP is successfully sent to your email address. "
                            );

                            setforgetForm({ ...forgetForm, otp: genOTP });
                            setShowOTPBox(true);
                            setShowEmailBox(false);
                            GetSalt();
                          }
                        })
                      );
                    } else {
                      Notiflix.Notify.failure(
                        "Please enter valid email address."
                      );
                    }
                  } else {
                    Notiflix.Notify.failure("Please enter your email address.");
                  }
                }}
              >
                Send OTP
              </Button>
            </div>
          )}
          {ShowOTPBox && (
            <div className="row m-0 mt-2 justify-content-between align-items-center">
              <p className="ps-0 col-12">
                Please enter the OTP below to verify your email address.
              </p>
              <TextField
                label="Enter OTP"
                className="col-md-8"
                onChange={(e) =>
                  setforgetForm({ ...forgetForm, confirmotp: e.target.value })
                }
              />
              <button
                className="col-auto btn btn-primary mt-md-0 mt-3"
                onClick={() => {
                  if (forgetForm.confirmotp != "") {
                    if (forgetForm.otp == forgetForm.confirmotp) {
                      setShowPasswordBox(true);
                      setShowOTPBox(false);
                    } else {
                      Notiflix.Notify.failure(
                        "You have entered incorrect OTP. Please Enter Correct One. "
                      );
                    }
                  } else {
                    Notiflix.Notify.failure("Please Enter OTP.");
                  }
                }}
              >
                Verify OTP
              </button>
            </div>
          )}
          {ShowPasswordBox && (
            <div className="row m-0  justify-content-between align-items-center">
              <div className="col-12 p-0 mt-3 position-relative">
                <TextField
                  label="New Password"
                  type={newpasswordeye ? "text" : "password"}
                  fullWidth
                  onChange={(e) => {
                    setforgetForm({ ...forgetForm, password: e.target.value });
                  }}
                />
                <div
                  style={{ position: "absolute", top: "15px", right: "20px" }}
                >
                  {!newpasswordeye ? (
                    <AiFillEyeInvisible
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => setnewpasswordeye(true)}
                    />
                  ) : (
                    <AiFillEye
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => setnewpasswordeye(false)}
                    />
                  )}
                </div>
              </div>
              <div className="col-12 p-0 mt-3 position-relative">
                <TextField
                  label="Confirm Password"
                  type={confirmpasswordeye ? "text" : "password"}
                  fullWidth
                  onChange={(e) => {
                    setforgetForm({
                      ...forgetForm,
                      confirmpassword: e.target.value,
                    });
                  }}
                />
                <div
                  style={{ position: "absolute", top: "15px", right: "20px" }}
                >
                  {!confirmpasswordeye ? (
                    <AiFillEyeInvisible
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => setconfirmpasswordeye(true)}
                    />
                  ) : (
                    <AiFillEye
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => setconfirmpasswordeye(false)}
                    />
                  )}
                </div>
              </div>
              <button
                className="col-auto btn btn-primary mt-3"
                onClick={() => {
                  //
                  if (forgetForm.password != "") {
                    if (forgetForm.confirmpassword != "") {
                      if (forgetForm.password == forgetForm.confirmpassword) {
                        Notiflix.Loading.dots();
                        PostApiCall.postRequest(
                          {
                            email: forgetForm.email,
                            password: forgetForm.password,
                            salt: salt,
                            updatedon: null,
                            updatedby: 0,
                          },
                          "ChangeStudentPassword"
                        ).then((result) =>
                          result.json().then((obj) => {
                            if (result.status == 200 || result.status == 201) {
                              Notiflix.Notify.success(
                                "Password Successfully Changed"
                              );
                              window.location.reload();
                            } else {
                              Notiflix.Loading.remove();
                              Notiflix.Notify.failure(
                                "There seems to be an Error. Please retry!"
                              );
                            }
                          })
                        );
                      } else {
                        Notiflix.Notify.failure(
                          "New Password and Confirm Password does not match."
                        );
                      }
                    } else {
                      Notiflix.Notify.failure("Please Enter Confirm Password.");
                    }
                  } else {
                    Notiflix.Notify.failure("Please Enter New Password.");
                  }
                }}
              >
                Confirm Password
              </button>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
// <div className="row m-0" style={{ margin: "0px", overflow: "hidden" }}>
// <div className="head col-12">
//   <div>
//     <img
//       src={logo}
//       alt="logo"
//       style={{ width: "40px", height: "40px" }}
//     />
//   </div>
//   <div>
//     <p className="link-register">
//       Don’t have an account?{" "}
//       <NavLink exact to="/register" activeClassName="active">
//         Get started
//       </NavLink>
//     </p>
//   </div>
// </div>
// <div className="col-12 col-md-6 desktop" style={{ padding: "16px", height: "100vh" }} >
//   <div className="left-con-login">
//     <h3>Hi, Welcome Back</h3>
//     <div className="left-mg-log">
//       <img src={scholar} alt="" />
//     </div>
//   </div>
// </div>
// <div className="col-12 col-md-6 ">
//   <div className="right-col-login">
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <div>
//         <div className="right-col-login-div">
//           <h4>Sign in to Vahani</h4>
//           <p>Enter your details below.</p>
//         </div>
//         <div className="text-field">
//           <TextField
//             id="outlined-basic"
//             label="Email address"
//             variant="outlined"
//             className="text-field-input"
//             {...register("email", {
//               required: "Enter Email",
//               pattern: {
//                 value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
//                 message: "Invalid email address",
//               },
//             })}
//             error={!!errors?.email}
//             helperText={errors?.email ? errors.email.message : null}
//           />
//           <TextField
//             id="outlined-basic"
//             label="Password"
//             variant="outlined"
//             type={passwordeye === false ? "password" : "text"}
//             className="text-field-input"
//             {...register("password", {
//               required: "Please enter password",
//               maxLength: {
//                 value: 10,
//                 message: "maxchar",
//               },
//             })}
//             error={errors?.password}
//             helperText={
//               errors?.password ? errors.password.message : null
//             }
//           />
//           <small className="text-primary cursor-pointer text-end" onClick={() => {
//             setShowModal(true)
//             setShowEmailBox(true)
//             // setShowPasswordBox(true)
//           }}>Forgot your Password ?</small>
//           <div style={{ position: "relative", bottom: errors?.password ? "95px" : "70px", left: "calc(100% - 40px)" }} >
//             {!passwordeye ? (<AiFillEyeInvisible style={{ fontSize: "25px", cursor: "pointer" }} onClick={handlepasswordclick} />
//             ) : (
//               <AiFillEye style={{ fontSize: "25px", cursor: "pointer" }} onClick={handlepasswordclick} />
//             )}
//           </div>
//           {/* <div className="flex-label-login">
//     <FormControlLabel
//       control={<Checkbox defaultChecked />}
//       label="Remember me"
//     />
//     <div>Forgot password?</div>
//   </div> */}
//           <Button
//             variant="contained"
//             className="but-login-vahani"
//             type="submit"
//           >
//             Login
//           </Button>
//           <div className="mobile-link-register">
//             <p className="link-register-mobile">
//               Don’t have an account?{" "}
//               <a href="/register">Get started</a>
//             </p>
//           </div>
//         </div>
//       </div>
//     </form>
//   </div>
// </div>
// </div>
