// import "../Personalinfo/Personalinfo.css";
import React, { useState, useContext } from "react";
import { set, useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
import GetApiCall from "../../../assets/API/GetApi";
import moment from "moment";
import Notiflix from "notiflix";
// import { CKEditor } from 'ckeditor4-react';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useHistory } from "react-router-dom";
import {
  FormHelperText,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
export default function AddNotification() {
  const history = useHistory();
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [formData, setformData] = React.useState({});
  const [ScholarList, setScholarList] = React.useState([]);
  const [FilterScholarList, setFilterScholarList] = React.useState([]);
  const [BatchList, setBatchList] = React.useState([]);
  const [description, setdescription] = React.useState(
    localStorage.getItem("notification_data")
  );
  const onSubmit = (data) => {
    if (data.type == "To Scholar") {
      PostApiCall.postRequest(
        {
          studentId: data.selectScholar,
          title: data.title,
          description: data.description,
          type: data.type,
          updatedOn: null,
          updatedBy: login.fld_id,
        },
        "Add_AdminNotification"
      ).then((result) =>
        result.json().then((obj) => {
          if (result.status == 200 || result.status == 201) {
            //
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Notification Added Successfully.");
            history.push("/notification-list");
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(
              "There seems to be an Error. Please retry!"
            );
          }
        })
      );
    }
    if (data.type == "To All") {
      PostApiCall.postRequest(
        {
          studentId: 0,
          title: data.title,
          description: data.description,
          type: data.type,
          updatedOn: null,
          updatedBy: login.fld_id,
        },
        "Add_AdminNotification"
      ).then((result) =>
        result.json().then((obj) => {
          if (result.status == 200 || result.status == 201) {
            //
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Notification Added Successfully.");
            history.push("/notification-list");
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(
              "There seems to be an Error. Please retry!"
            );
          }
        })
      );
    }
    if (data.type == "Batchwise") {
      PostApiCall.postRequest(
        {
          studentId: 0,
          title: data.title,
          description: data.description,
          type: data.selectBatch,
          updatedOn: null,
          updatedBy: login.fld_id,
        },
        "Add_AdminNotification"
      ).then((result) =>
        result.json().then((obj) => {
          if (result.status == 200 || result.status == 201) {
            //
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Notification Added Successfully.");
            history.push("/notification-list");
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(
              "There seems to be an Error. Please retry!"
            );
          }
        })
      );
    }
  };
  //
  React.useEffect(() => {
    GetApiCall.getRequest("Get_ScholarDropDown").then((resultdes) =>
      resultdes.json().then((obj) => {
        //
        setScholarList(obj.data);
        setFilterScholarList(obj.data);
        Notiflix.Loading.remove();
      })
    );
    GetApiCall.getRequest("Get_ScholarBatchDropDown").then((resultdes) =>
      resultdes.json().then((obj) => {
        //
        setBatchList(obj.data);
        Notiflix.Loading.remove();
      })
    );

    setdescription(
      localStorage.getItem("notification_data") != null
        ? localStorage.getItem("notification_data")
        : ""
    );
  }, []);
  //
  return (
    <>
      <div style={{ marginBottom: "210px" }}>
        <div className="form-head">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Add Notification</h4>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-vahani" style={{ marginTop: "24px" }}>
            <div className="row m-0">
              <div className="col-md-6 mb-3">
                <FormControl fullWidth error={errors?.type}>
                  <InputLabel>Send to*</InputLabel>
                  <Select
                    label="Sent to"
                    {...register("type", { required: "Please select" })}
                  >
                    <MenuItem value="To Scholar">To Scholar</MenuItem>
                    <MenuItem value="To All">To All</MenuItem>
                    <MenuItem value="Batchwise">Batchwise</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.type ? errors?.type.message : null}
                  </FormHelperText>
                </FormControl>
              </div>
              {watch("type") == "To Scholar" && (
                <div className="col-md-6 mb-3">
                  <FormControl fullWidth error={errors?.selectScholar}>
                    <InputLabel>Select Scholar*</InputLabel>
                    <Select
                      value={
                        watch("selectScholar") ? watch("selectScholar") : []
                      }
                      multiple
                      // onChange={handleChange}
                      label="Select Scholar"
                      {...register("selectScholar", {
                        required: "Select Scholar",
                      })}
                    >
                      <TextField
                        label="Search..."
                        onChange={(e) => {
                          setFilterScholarList(
                            ScholarList.filter((val) =>
                              val.label
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase())
                            )
                          );
                        }}
                        variant="filled"
                        className="m-3 w-100"
                      />
                      {FilterScholarList.map((val) => (
                        <MenuItem value={val.value}>{val.label}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors?.selectScholar
                        ? errors?.selectScholar.message
                        : null}
                    </FormHelperText>
                  </FormControl>
                </div>
              )}
              {watch("type") == "Batchwise" && (
                <div className="col-md-6 mb-3">
                  <FormControl fullWidth error={errors?.selectBatch}>
                    <InputLabel>Select Batch*</InputLabel>
                    <Select
                      // value={formData.selected}
                      // onChange={handleChange}
                      label="Select Batch"
                      {...register("selectBatch", { required: "Select Batch" })}
                    >
                      {BatchList.map((val) => {
                        return (
                          <MenuItem value={val.value}>
                            {val.label} - {val.fld_count_scholar}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {errors?.selected ? errors?.selected.message : null}
                    </FormHelperText>
                  </FormControl>
                </div>
              )}
              <div className="mb-3">
                <TextField
                  fullWidth
                  label="Title*"
                  {...register(`title`, { required: "This Field is Required" })}
                  error={errors.title}
                  helperText={errors?.title ? errors.title.message : null}
                // InputLabelProps={{ required: true }}
                />
              </div>
              <FormControl
                fullwidth
                required
                className="mb-4"
                error={errors?.description}
              >
                <InputLabel id="demo-simple-select-autowidth-label">
                  Description
                </InputLabel>
                <div className="mt-5">
                  <CKEditor
                    editor={ClassicEditor}
                    {...register("description", {
                      required: "This Field is Required",
                    })}
                    data={description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      localStorage.setItem("notification_data", data);
                      setdescription(data);
                      setValue("description", data);
                    }}
                  />
                </div>
                <FormHelperText>
                  {errors?.description ? errors?.description.message : null}
                </FormHelperText>
              </FormControl>
            </div>
          </div>
          <div className="card-vahani" style={{ marginTop: "26px" }}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <div className="but-width-form">
                <Button
                  variant="contained"
                  className="but-login-vahani"
                  type="submit"
                >
                  {" "}
                  Submit{" "}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
