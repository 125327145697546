import React, { useState } from "react";
import { Edit3 } from "react-feather";
import Notiflix from "notiflix";
import PostApiCall from "../../../../assets/API/PostApi";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { Edit } from "react-feather";
import { Link } from "react-router-dom";
import GetApiCall from "../../../../assets/API/GetApi";
import { Button, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Modal } from "react-bootstrap";

export default function EmployeeList() {
  const [employeedata, setemployeedata] = React.useState([]);
  const [empId, setEmpId] = React.useState([]);
  const [selectedEmpID, setSelectedEmpID] = useState(null)
  const [selectedEmpWorkingStatus, setSelectedEmpWorkingStatus] = useState("")
  const [UserData, setUserData] = React.useState([]);
  const [searchInput, setsearchInput] = React.useState("");
  const [SearchData, setSearchData] = React.useState({
    empid: "",
    email: "",
  });
  const [id, SetId] = useState([]);
  const [showAlert, setShowAlert] = React.useState(false)
  const [list, setlist] = React.useState([]);
  const [allData, setAllData] = React.useState([]);
  const [ShowViewModal, setShowViewModal] = React.useState(null);
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  const [empStatus, setEmpStatus] = React.useState(true)
  const [showEmp, setShowEmp] = useState('Active');
  const [alertName, setAlertName] = useState("")

  React.useEffect(() => {
    Notiflix.Loading.init({ svgColor: "#060a4a" });
    Notiflix.Loading.dots();
    GetEmployeeList("")
  }, []);


  function GetEmployeeList(status) {
    GetApiCall.getRequest("Get_Employee_List").then((results) =>
      results.json().then((obj) => {
        setlist(obj.data.filter((dat) => dat.fld_emp_status == showEmp));
        setAllData(obj.data)
        Notiflix.Loading.remove();
      })
    );
  }
  function getInactiveEmp() {
    setShowEmp((prevState) => {
      if (prevState == "Active") {
        let toggledata = allData
        setlist(toggledata.filter((dat) => dat.fld_emp_status == "Inactive"))
        return 'Inactive'
      } else {
        let toggledata = allData
        setlist(toggledata.filter((dat) => dat.fld_emp_status == "Active"))
        return 'Active'
      }
    })
  }

  function GetFilterEmployeeList(data) {
    if (data == "") {
      Notiflix.Loading.dots();

      GetApiCall.getRequest("Get_Employee_List").then((results) =>
        results.json().then((obj) => {
          setlist(obj.data);
          Notiflix.Loading.remove();
        })
      );
    } else {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          whereClause: data,
        },
        "GetFilterEmployeeList"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            setlist(obj.data);
            Notiflix.Loading.remove();
          }
          Notiflix.Loading.remove();
        })
      );
    }
  }

  function handleCloseAlert() {
    setShowAlert(false)
  }

  function handleEmpStatus() {
    setEmpStatus((prevState) => !prevState)
  }
  return (
    <>
      <Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header closeButton>
          <Modal.Title>{alertName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEmpWorkingStatus == "Active" ? "Are you sure you want to mark this employee as inactive?" : "Are you sure you want to mark this employee as active?"}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" color="success" className="me-3" onClick={() => {
            PostApiCall.postRequest(
              {
                id: selectedEmpID,
                workingStatus: selectedEmpWorkingStatus == "Active" ? "Inactive" : "Active"
              },
              "Update_Employee_Working_Status"
            ).then((results) =>
              results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                  GetEmployeeList("");
                  handleCloseAlert();
                  setSelectedEmpID(null);
                  Notiflix.Loading.remove();
                }
                Notiflix.Loading.remove();
              })
            );
          }}>
            Yes
          </Button>
          <Button variant="contained" color="error" onClick={handleCloseAlert}>
            No
          </Button>
        </Modal.Footer>
      </Modal >

      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Employee's Records </h4>
        </div>
      </div>
      <div className="card-vahani p-0 mt-2">
        <p className="personal-info m-0 left-line-card py-4 d-flex align-items-center justify-content-between">
          <div className="col-md-6 text-start col-12 mb-3">
            Employee's List{" "}
          </div>
          <div className="col-md-6 text-end col-12 mb-3 ms-auto">
            <div className="row justify-content-end">
              <div className="col-lg-4 col-lg-4 mb-lg-0 mb-2">
                <Button variant="contained" className="w-100">
                  <Link to="/employeeprofile" className="text-white">
                    Show Your Profile
                  </Link>
                </Button>
              </div>
              <div className="col-lg-4 col-lg-4 mb-lg-0 mb-2">
                <Button variant="contained" className="w-100">
                  <Link to="/addemployee" className="text-white">
                    Add Employee
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </p>
        <div className="row m-0 justify-content-between py-4  border-top border-bottom border-secondary">
          <div className="col-md-3 mb-3">
            <input
              type="text"
              value={SearchData.empid}
              onChange={(e) =>
                setSearchData({ ...SearchData, empid: e.target.value })
              }
              class="form-control"
              placeholder="Enter Employee ID"
            />
          </div>
          <div className="col-md-3 mb-3">
            <input
              type="email"
              value={SearchData.email}
              onChange={(e) =>
                setSearchData({ ...SearchData, email: e.target.value })
              }
              class="form-control "
              placeholder="Enter Email"
            />
          </div>
          <div className="col-md-6 text-end col-12 mb-3 ms-auto">
            <div className="row justify-content-end">
              <div className="col-lg-6 mb-lg-0 mb-2">
                <Button
                  variant="contained"
                  className="w-100"
                  onClick={getInactiveEmp}>
                  {showEmp == "Active" ? 'Show Inactive Employees' : 'Show Active Employees'}
                </Button>
              </div>
              <div className="col-lg-3 mb-lg-0 mb-2">
                <Button
                  variant="contained"
                  className="w-100"
                  onClick={() => {
                    let whereClause;
                    if (SearchData.empid != "" && SearchData.email == "") {
                      whereClause =
                        "where fld_employee_id = '" + SearchData.empid + "'";
                    } else if (
                      SearchData.empid != "" &&
                      SearchData.email != ""
                    ) {
                      whereClause =
                        "where fld_employee_id = '" +
                        SearchData.empid +
                        "' AND fld_email = '" +
                        SearchData.email +
                        "'";
                    } else if (
                      SearchData.empid == "" &&
                      SearchData.email != ""
                    ) {
                      whereClause =
                        "where fld_email = '" + SearchData.email + "'";
                    } else {
                      whereClause = "";
                    }
                    GetFilterEmployeeList(whereClause);
                  }}
                >
                  Search
                </Button>
              </div>
              <div className="col-lg-3 mb-lg-0 mb-2">
                <Button
                  variant="contained"
                  className="w-100"
                  onClick={() => {
                    setSearchData({ ...SearchData, empid: "", email: "" });
                    GetFilterEmployeeList("");
                  }}
                >
                  Refresh
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3">
          {list.length > 0 ? (
            <MDBDataTable
              disableRetreatAfterSorting={true}
              responsive
              hover
              //   striped
              data={{
                columns: [
                  {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 150,
                  },
                  {
                    label: "Employee Id",
                    field: "empId",
                    sort: "disabled",
                    width: 400,
                  },
                  {
                    label: "Employee Name",
                    field: "empName",
                    sort: "disabled",
                    width: 400,
                  },
                  {
                    label: "Contact No.",
                    field: "empContact",
                    sort: "disabled",
                    width: 400,
                  },
                  {
                    label: "Employee Email",
                    field: "empEmail",
                    sort: "disabled",
                    width: 150,
                  },
                  {
                    label: "Employee Designation",
                    field: "empDesignation",
                    sort: "disabled",
                    width: 150,
                  },
                  {
                    label: "Employee Department",
                    field: "empDepartment",
                    sort: "disabled",
                    width: 150,
                  },
                  {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 150,
                  },
                  {
                    label: "Status",
                    field: "status",
                    sort: "disabled",
                    width: 150,
                  },
                ],
                rows: list.map((data, i) => {
                  return {
                    serial: i + 1,
                    empName: data.fld_employee_full_name,
                    empContact: data.fld_contact_no,
                    empId: data.fld_employee_id,
                    empEmail: data.fld_email,
                    empDesignation: data.fld_designation,
                    empDepartment: data.fld_department,
                    action: (
                      <Button
                        id="basic-button"
                        aria-controls={ShowViewModal ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={ShowViewModal ? "true" : undefined}
                        onClick={(e) => {
                          // SetId([...id, data.fld_id]);
                          SetId(...id, data.fld_id);
                          setEmpId(...empId, data.fld_employee_id);
                          setShowViewModal(e.currentTarget);
                          // setSelectData(row);
                        }}
                      >
                        <MoreHorizIcon />
                      </Button>
                    ),
                    status: (
                      <Button
                        variant="contained"
                        color={data.fld_emp_status == "Active" ? "error" : "success"}
                        onClick={() => {
                          setSelectedEmpID(data.fld_id);
                          setSelectedEmpWorkingStatus(data.fld_emp_status)
                          setAlertName(data.fld_employee_full_name)
                          setShowAlert(true)
                        }}>
                        {data.fld_emp_status == "Active" ? "Mark Inactive" : "Mark Active"}
                      </Button>
                    )
                  };
                }),
              }}
              entriesOptions={[10, 25, 50, 100]}
            />
          ) : (
            <p className="m-3">No Data Found</p>
          )}
          <Menu
            id="basic-menu"
            anchorEl={ShowViewModal}
            open={ShowViewModal}
            onClose={() => setShowViewModal(null)}
            MenuListProps={{ "aria-labelledby": "basic-button" }}
          >
            <MenuItem>
              <Link
                to={{
                  pathname: `/editlogincredentials`,
                  state: {
                    id: id,
                  },
                }}
              >
                Edit Credentials
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to={{
                  pathname: `/editpersonaldetails`,
                  state: {
                    id: id,
                    empId: empId,
                  },
                }}
              >
                View Employee Details
              </Link>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </>
  );
}
