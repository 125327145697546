import React, { Component, useState, useEffect } from "react";
import { Edit3 } from "react-feather";
import Notiflix from "notiflix";

import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import moment from "moment";
import { Edit } from "react-feather";
import { Link } from "react-router-dom";
import { AiOutlineFileSearch } from "react-icons/ai";

import { Button, Menu, MenuItem } from "@mui/material";
import PostApiCall from "../../../assets/API/PostApi";
import { FaEdit } from "react-icons/fa";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export default function TaskList({ match }) {
  const projectId = match.params.fld_id; // project specific id from project list
  const [addTaskData, setTaskData] = useState([]);
  const location = useLocation();
  const { projectData } = location.state || {};
  const login = JSON.parse(localStorage.getItem("LoginDetail"))[0]

  useEffect(() => {
    Notiflix.Loading.init({
      svgColor: "rgb(6, 10, 74)",
    });
    Notiflix.Loading.dots("Please wait...");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // filter out the tasks for a specific project
    PostApiCall.postRequest(
      {
        whereClause: `where fld_project_id= '${projectId}'`,
      },
      "GetProjectsTasks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setTaskData(obj.data);
        }
        Notiflix.Loading.remove();
      })
    );
  }, [projectId]);

  const data = {
    columns: [
      {
        label: "S.No.",
        field: "serial",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Task",
        field: "task",
        sort: "disabled",
        width: 400,
      },
      // {
      //   label: "Description",
      //   field: "description",
      //   sort: "disabled",
      //   width: 400,
      // },
      {
        label: "Deadline",
        field: "deadline",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Start Date",
        field: "startDate",
        sort: "disabled",
        width: 150,
      },
      {
        label: "End Date",
        field: "endDate",
        sort: "disabled",
        width: 150,
      },

      // {
      //   label: "Reason",
      //   field: "reason",
      //   sort: "disabled",
      //   width: 150,
      // },
      {
        label: "Budget",
        field: "budget",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 150,
      },
    ],

    rows: addTaskData.map((data, i) => {
      return {
        serial: i + 1,
        task: data.fld_task,
        description: data.fld_desc,
        deadline: moment(data.fld_deadline).format("DD-MM-YYYY"),
        endDate: moment(data.fld_end_date).format("DD-MM-YYYY"),
        startDate: moment(data.fld_start_date).format("DD-MM-YYYY"),
        reason: data.fld_reason,
        budget: data.fld_budget,
        status: data.fld_status,
        action: (
          <div className="d-flex">
            {/* Edit a task */}
            <Link
              className="m-auto"
              to={{
                pathname: `/add-task/${data.fld_id}`,
                state: { taskData: data },
              }}
            >
              <FaEdit className="edit-icon edit-icon-font-size" />
            </Link>
          </div>
        ),
      };
    }),
  };

  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Task List </h4>
        </div>
      </div>
      <div className="card-vahani p-0 mt-2">
        <p className="personal-info m-0 left-line-card p-4 d-flex align-items-center justify-content-between">
          Project Task List
          {login.fld_type != "Accountant" &&
            <div className="col-md-2 ps-0 pe-0">
              <Button variant="contained" className="w-100">
                <Link to={`/add-task/${projectId}`} className="text-white">
                  Add New Task
                </Link>
              </Button>
            </div>
          }
        </p>
        <div className="p-3">
          {addTaskData.length > 0 ? (
            <>
              <MDBDataTable
                responsive
                hover
                disableRetreatAfterSorting={true}
                data={data}
                entriesOptions={[10, 25, 50, 100]}
              >
                <MDBTableHead columns={data.columns} />
                <MDBTableBody rows={data.rows} />
              </MDBDataTable>
            </>
          ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )}
        </div>
      </div>
    </>
  );
}
