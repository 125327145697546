import React, { Component, useContext } from "react";
import Notiflix from "notiflix";
import {
  MenuItem,
  TextField,
  Checkbox,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput,
  Button,
  Select,
  InputLabel,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useHistory, useLocation, useParams } from "react-router-dom";
import store from "../../../store";
import PostApiCall from "../../../assets/API/PostApi";

export default function AddVendorContact(props) {
  let history = useHistory();
  const {
    addressId,
    setAddressId,
    contactId,
    setContactId,
    contactName,
    setContactName,
    designation,
    setDesignation,
    contactEmail,
    setContactEmail,
    contactMobile,
    setContactMobile,
    addContactData,
    setAddContactData,
  } = useContext(store);

  function onSaveContact() {
    Notiflix.Loading.init({
      svgColor: "rgb(6,10,74)",
    });
    Notiflix.Loading.dots("Please wait...");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    PostApiCall.postRequest(
      {
        id: contactId,
        addressid: props.contactAddressId,
        contactname: contactName,
        designation: designation,
        email: contactEmail,
        mobile: contactMobile,
        userid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
      },
      "UpdateVendorContacts"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Contact added successfully");
          PostApiCall.postRequest(
            {
              wherecaluse: props.contactAddressId,
            },
            "GetVendorContacts"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status === 200 || results.status === 201) {
                setAddContactData(obj.data);
                setContactName("");
                setDesignation("");
                setContactEmail("");
                setContactMobile("");
              }
            })
          );
        } else {
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
    Notiflix.Loading.remove();
  }
  return (
    <>
      <div>
        {/* <div className="form-head ">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Add Contact</h4>
          </div>
        </div> */}
        <div className="card-vahani p-0 pb-4 mt-4">
          <p className="personal-info left-line-card px-lg-4 px-3 py-4">
            Contact Details
          </p>
          <div class="toast-body">
            <div class="row">
              <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row ps-2 pe-3">
                      {/* <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <Box>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Select Address*
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              required={true}
                              label="Select Address"
                            >
                              <MenuItem value="">Address</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </div> */}
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          className="text-field"
                          type="text"
                          // required={true}
                          value={contactName}
                          onChange={(e) => {
                            setContactName(e.target.value);
                          }}
                          label="Contact Person Name"
                        />
                      </div>
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          type="text"
                          label="Designation"
                          value={designation}
                          onChange={(e) => {
                            setDesignation(e.target.value);
                          }}
                          // required={true}
                          className="text-field capitalonly"
                        />
                      </div>
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          type="email"
                          label="Email Id"
                          // required={true}
                          value={contactEmail}
                          onChange={(e) => {
                            setContactEmail(e.target.value);
                          }}
                          className="text-field capitalonly"
                        />
                      </div>
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          type="text"
                          label="Mobile"
                          // required={true}
                          value={contactMobile}
                          onChange={(e) => {
                            setContactMobile(e.target.value);
                          }}
                          className="text-field capitalonly"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
          <div
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-toggle="toast"
          >
            <div
              className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-3"
              style={{ float: "right" }}
            >
              <div className="justify-content-end d-flex">
                <Button
                  variant="contained"
                  onClick={() => {
                    onSaveContact();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
