import Notiflix from "notiflix";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PostApiCall from "../../../assets/API/PostApi";
import {
  MenuItem,
  Checkbox,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput,
  Select,
  InputLabel,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";

// import { Modal } from "react-bootstrap";
import { Button, TextField } from "@mui/material";

export default function AddPayment() {
  let history = useHistory();

  const OrderPayments = (action) => {
    PostApiCall.postRequest({}, "").then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          history.push("/purchase-Order-invoice-list");
          Notiflix.Notify.success("Data saved successfully");
        }
      })
    );
  };

  return (
    <>
      <div>
        <div className="form-head ">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Payments</h4>
          </div>
        </div>
        <div className="card-vahani p-0 pb-4 ">
          <p className="personal-info left-line-card px-lg-4 px-3 py-4">
            Payment Details
          </p>

          <div class="toast-body">
            <div class="row">
              <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row ps-2 pe-3">
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <Box>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Invoice Id
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // required={true}
                              label="Invoice Id"
                            >
                              <MenuItem value="">Invoice Id</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </div>

                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          className="text-field"
                          type="text"
                          // required={true}
                          label="Amount"
                        />
                      </div>

                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          className="text-field"
                          type="text"
                          // required={true}
                          label="TDS Percentage"
                        />
                      </div>

                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          className="text-field"
                          type="text"
                          // required={true}
                          label="TDS Amount"
                        />
                      </div>
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          className="text-field"
                          type="text"
                          // required={true}
                          label="SGST"
                        />
                      </div>
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          className="text-field"
                          type="text"
                          // required={true}
                          label="CGST"
                        />
                      </div>

                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          type="text"
                          label="IGST"
                          // required={true}
                          className="text-field capitalonly"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
          <div
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-toggle="toast"
          >
            <div
              className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-3"
              style={{ float: "right" }}
            >
              <div className="justify-content-end">
                <Button
                  variant="contained"
                  onClick={() => {
                    history.push("/payment-details");
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
