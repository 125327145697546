import { Button } from "@mui/material";
import { Modal } from "antd";
import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { useEffect, useRef, useState } from "react";
import FileViewer from "react-file-viewer";
import { AiOutlineFileSearch } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Col } from "reactstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PostApiCall from "../../../assets/API/PostApi";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";

// End multiple select task
export default function AddQuotation() {
  let history = useHistory();
  let location = useLocation();
  const [projectList, setProjectList] = useState([]);
  const [documentUrl, setDocumentUrl] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [rfqNumber, setRfqNumber] = useState("");
  const [rfqId, setRfqId] = useState("");
  const [taskid, setTaskId] = useState("");
  const [projectTaskList, setProjectTaskList] = useState([]);
  const [PdfPreview, setPdfPreview] = useState(false);
  const [ImageApiUrl, setImageApiUrl] = useState(
    "https://admin.vahanischolarship.in/API/AddImage2"
  );
  const [rfqTaskList, setRfqTaskList] = useState([]);
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);
  const [documentName, setDocumentName] = useState("");

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event, rfqid) => {
    hiddenFileInput.current.click();
    setRfqId(rfqid);
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    let response;
    let random = Math.floor(Math.random() * 1000000);
    const fileUploaded = event.target.files[0];
    Notiflix.Loading.dots("Uploading document");
    const form = new FormData();
    form.append("file", fileUploaded);
    form.append("foldername", "VendorRFQ");
    form.append("filename", rfqId + "-" + random + "-" + fileUploaded.name);
    response = fetch(ImageApiUrl, {
      method: "POST",
      body: form,
      mode: "cors",
    })
      .then((response) => response.json())
      .then(() => {
        Notiflix.Loading.dots("Saving document");
        PostApiCall.postRequest(
          {
            id: location.state === undefined ? 0 : location.state.id,
            rfqid: rfqId,
            taskid: 0,
            quotationdoc:
              "https://admin.vahanischolarship.in/Images/VendorRFQ/" +
              rfqId +
              "-" +
              random +
              "-" +
              fileUploaded.name,
            comments: "",
            status: "New",
            userid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
            filename: fileUploaded.name,
          },
          "UpdateQuotations"
        ).then((results) =>
          results.json().then(() => {
            if (results.status == 200 || results.status == 201) {
              Notiflix.Notify.success("Quotation uploaded successfully ");

              Notiflix.Loading.remove();
              history.push("/quotation-list");
            }
          })
        );
        Notiflix.Loading.remove();
      });
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    Notiflix.Loading.dots("Getting RFQ Details");
    if (location.state === undefined ? true : location.state.id == null) {
      PostApiCall.postRequest(
        {
          whereClause: ` where isnull(fld_quotation_status,'New') != 'Quotation Uploaded' order by fld_rfq_no desc`,
        },
        "GetRFQMasters"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            setRfqTaskList(obj.data);
            Notiflix.Loading.remove();
          }
        })
      );
    } else {
      PostApiCall.postRequest(
        {
          whereClause: `  where udv_rfq_master.fld_id in (select qo.fld_rfq_id from udv_quotations qo where qo.fld_id = ${location.state === undefined ? 0 : location.state.id
            } ) order by fld_rfq_no desc`,
        },
        "GetRFQMasters"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            setRfqTaskList(obj.data);
            setDocumentUrl(obj.data[0]?.fld_quotation_doc);
            setDocumentName(obj.data[0]?.fld_filename);
            setRfqId(obj.data[0].fld_id);
            Notiflix.Loading.remove();
          }
        })
      );
    }
  }, []);
  const data = {
    columns: [
      {
        label: "S.No.",
        field: "serial",
        sort: "disabled",
        width: 150,
      },
      {
        label: "RFQ Number",
        field: "rfqnumber",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Vendor Name",
        field: "Vendorname",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Created On",
        field: "createdon",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Created By",
        field: "createdby",
        sort: "asc",
        width: 150,
      },
      {
        label: "Quotation",
        field: "action",
        width: 150,
        sort: "asc",
      },
    ],
    rows: rfqTaskList.map((data, i) => {
      return {
        serial: i + 1,
        Vendorname: data.vendor_name,
        rfqnumber: data.fld_rfq_no,
        createdon: moment(data.fld_rfq_date).format("DD-MM-YYYY"),
        status: data.fld_rfq_status,
        createdby: data.created_by,
        action:
          location.state?.id == null ? (
            <div className="d-flex">
              <Button
                variant="contained"
                id={data.fld_id}
                onClick={(e) => handleClick(e, data.fld_id)}
              >
                Upload
              </Button>
              <input
                type="file"
                variant="contained"
                id={data.fld_id}
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: "none" }} // Make the file input element invisible
              />
            </div>
          ) : (
            <div className="d-flex justify-content-between">
              <Button
                variant="contained"
                id={data.fld_id}
                onClick={(e) => handleClick(e, data.fld_id)}
              >
                Upload
              </Button>
              <input
                type="file"
                variant="contained"
                id={data.fld_id}
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: "none" }} // Make the file input element invisible
              />

              <Button
                variant="contained"
                onClick={() =>
                  data.fld_filename.split(".")[1] != "pdf"
                    ? setOpenPreview(true)
                    : setPdfPreview(true)
                }
              >
                View
              </Button>
            </div>
          ),
      };
    }),
  };

  function getExtension(filename) {
    return filename.split(".").pop();
  }

  return (
    <>
      <div>
        <div className="form-head ">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Add Quotation</h4>
          </div>
        </div>
        {/* sort and filter code */}
        {/* <div className="personal-info m-0 ps-md-1 pe-md-3 pb-0 d-lg-flex align-items-center justify-content-between">
          <div className="col-xxl-6 col-12 mt-3">
            <div className="row px-0">
              <TextField
                type="date"
                label="From Date"
                id="outlined-size-small"
                size="small"
                value={dateData.from}
                onChange={(e) =>
                  setdateData({ ...dateData, from: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className="mb-3 mb-md-0 px-2 col-md-4"
              />
              <TextField
                type="date"
                label="To Date"
                id="outlined-size-small"
                size="small"
                value={dateData.to}
                onChange={(e) =>
                  setdateData({ ...dateData, to: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className="px-2 col-md-4 mb-3 mb-md-0"
              />
              <div className="px-2 col-md-2">
                <Button
                  className="sort-filter-size w-100"
                  variant="contained"
                  onClick={() => {
                    if (dateData.from != "") {
                      if (dateData.to != "") {
                        QuotationListData(ConditionType);
                      } else {
                        Notiflix.Notify.failure("Enter To date");
                      }
                    } else {
                      Notiflix.Notify.failure("Enter From date");
                    }
                  }}>
                  Filter
                </Button>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 px-lg-0 col-12 mt-3 px-0">
            <div className="row m-0 px-0 align-items-lg-start justify-content-lg-end">
              <div className="col-sm-3 mb-sm-0 mb-3 col-12 pe-lg-0">
                <select
                  class="form-select form-select-sm sort-filter-size"
                  aria-label=".form-select-sm example"
                  onChange={(e) =>
                    setSortData({ ...SortData, value: e.target.value })
                  }>
                  <option value="fld_id">Sort By</option>
                  <option value="trim(fld_rfq_no)">RFQ Number</option>
                  <option value="cast(fld_created_on as date)">
                    Raised On
                  </option>
                  <option value="trim(fld_rfq_status)">Status</option>
                </select>
              </div>
              <div className="col-sm-3 col-12 mb-sm-0 mb-3 ">
                <select
                  class="form-select form-select-sm sort-filter-size"
                  aria-label=".form-select-sm example"
                  onChange={(e) =>
                    setSortData({ ...SortData, type: e.target.value })
                  }>
                  <option value="desc">Order</option>
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending </option>
                </select>
              </div>
              <div className="col-sm-2 col-12 mb-sm-0 mb-3 px-lg-0">
                <Button
                  className="w-100"
                  variant="contained"
                  onClick={() => {
                    QuotationListData(ConditionType);
                  }}>
                  Sort
                </Button>


              </div>
            </div>
          </div>
        </div> */}
      </div>

      {/* ***** Show All RFQ Selected Tasks *********** */}

      <>
        <div className="form-head mb-lg-2 mb-3"></div>
        <div className="card-vahani p-0 mt-2">
          <p className="personal-info m-0 left-line-card p-4 d-flex align-items-center justify-content-between">
            RFQs{" "}
          </p>
          <div className="p-3">
            {rfqTaskList.length > 0 ? (
              <MDBDataTable
                responsive
                hover
                disableRetreatAfterSorting={true}
                data={data}
                entriesOptions={[10, 25, 50, 100]}
              >
                <MDBTableHead columns={data.columns} />
                <MDBTableBody rows={data.rows} />
              </MDBDataTable>
            ) : (
              <Col lg={12}>
                <div className="text-center">
                  <div className="justify-content-center no-data-icon mt-4">
                    <AiOutlineFileSearch className="no-found-data-size" />
                  </div>
                  <h4>No data found</h4>
                </div>
              </Col>
            )}
          </div>
        </div>
      </>
      {/* ******** Preview Doc********** */}

      <Modal
        centered
        open={openPreview}
        onOk={() => setOpenPreview(false)}
        onCancel={() => setOpenPreview(false)}
        width={500}
      >
        <h4>Preview Document</h4>

        <div className="row">
          <div className="col-12 col-md-12">
            <div className="mx-2 my-3">
              <div class="card me-3 quotaion-doc-slider position-relative">
                <div class="card-body p-0">
                  <div className="col-12 col-md-12 m-auto d-flex p-0 quotation-img-preview">
                    <FileViewer
                      fileType={getExtension(documentName)}
                      filePath={documentUrl}
                      className="file-viewer-style"
                    />
                  </div>
                  <p className="mt-3 text-center px-1">{data.fld_filename}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={PdfPreview}
        onOk={() => setPdfPreview(false)}
        onCancel={() => setPdfPreview(false)}
        className="quotation-pdf-preview-modal"
        width={1000}
        centered
      >
        <Box>
          <iframe src={documentUrl} />
        </Box>
      </Modal>

      {/* ***** Save button ***** */}
      {/* <div className="card-vahani p-0 mt-5">
        <div className="justify-content-end d-flex m-3">
          <Button
            variant="contained"
            onClick={() => {
              history.push("/quotation-list");
            }}
          >
            Save
          </Button>
        </div>
      </div> */}
    </>
  );
}
