import React from "react";
import {
  MenuItem,
  FormControl,
  Select,
  Button,
  InputLabel,
  TextField,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Notiflix from "notiflix";
import PostApiCall from "../../../assets/API/PostApi";
import GetApiCall from "../../../assets/API/GetApi";
let CourseData = [
  { value: "Select Course", label: "Select Course" },
  { value: "Bachelor of Science - B.Sc", label: "Bachelor of Science - B.Sc" },
  {
    value: "Bachelor of Physiotherapy - B.P.T",
    label: "Bachelor of Physiotherapy - B.P.T",
  },
  {
    value: "Bachelor of Engineering / Bachelor of Technology - B.E./B.Tech",
    label: "Bachelor of Engineering / Bachelor of Technology - B.E./B.Tech",
  },
  { value: "Bachelor of Arts - B.A", label: "Bachelor of Arts - B.A" },
  {
    value: "Bachelor of Commerce - B.Com",
    label: "Bachelor of Commerce - B.Com",
  },
  {
    value: " Bachelor of Medicine and Bachelor of Surgery - MBBS",
    label: " Bachelor of Medicine and Bachelor of Surgery - MBBS",
  },
  {
    value: "Bachelor of Pharmacy - B.Pharm / B.Pharma.",
    label: "Bachelor of Pharmacy - B.Pharm / B.Pharma.",
  },
  {
    value: "Bachelor of Management Studies(BMS)",
    label: "Bachelor of Management Studies(BMS)",
  },
  {
    value: "Bachelor of Mass Communications - B.M.C. / B.M.M.",
    label: "Bachelor of Mass Communications - B.M.C. / B.M.M.",
  },
  {
    value: "Bachelor of Business Management (BBM)",
    label: "Bachelor of Business Management (BBM)",
  },
  {
    value: "Bachelor of Business Administration(BBA)",
    label: "Bachelor of Business Administration(BBA)",
  },
  {
    value: "Bachelor of Business Studies(BBS)",
    label: "Bachelor of Business Studies(BBS)",
  },
  { value: "Mass Communication", label: "Mass Communication" },
  { value: "Bachelor of Laws - L.L.B.", label: "Bachelor of Laws - L.L.B." },
  { value: "B.Des Fashion Design", label: "B.Des Fashion Design" },
  {
    value: "Bachelor of Architecture - B.Arch",
    label: "Bachelor of Architecture - B.Arch",
  },
  { value: "Master of Science - M.Sc", label: "Master of Science - M.Sc" },
  {
    value: "Master of Physiotherapy - M.P.T.",
    label: "Master of Physiotherapy - M.P.T.",
  },
  {
    value: "Master of Engineering / Master of Technology - M.E./ M.Tech",
    label: "Master of Engineering / Master of Technology - M.E./ M.Tech",
  },
  { value: "Master of Arts - M.A", label: "Master of Arts - M.A" },
  { value: "Master of Commerce - M.Com", label: "Master of Commerce - M.Com" },
  {
    value: "Master of Pharmacy - M.Pharm ",
    label: "Master of Pharmacy - M.Pharm",
  },
  {
    value: "Master of Mass Communications / Mass Media - M.M.C / M.M.M",
    label: "Master of Mass Communications / Mass Media - M.M.C / M.M.M",
  },
  { value: "Master of Laws - L.L.M", label: "Master of Laws - L.L.M" },
  {
    value: "Master of Business Administration - M.B.A",
    label: "Master of Business Administration - M.B.A",
  },
  {
    value: "Master of Design - M.Des./ M.Design",
    label: "Master of Design - M.Des./ M.Design",
  },
  {
    value: "Master of Architecture - M.Arch",
    label: "Master of Architecture - M.Arch",
  },
  { value: "Others, Please specify", label: "Others, Please specify" },
];
const CourseInformation = ({ stdid, action }) => {
  var login = stdid
    ? { fld_id: stdid }
    : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const onSave = (data) => {
    //
    Notiflix.Loading.dots();
    var detail = JSON.parse(localStorage.getItem("LoginDetail"))[0];
    PostApiCall.postRequest(
      {
        courseid: data.fld_id == undefined ? null : data.fld_id,
        studentId: login.fld_id,
        courseName: data.fld_courseName,
        specialization: data.fld_specialization,
        duration: data.fld_duration,
        collegeFee: data.fld_collegeFee,
        courseStartDate: data.fld_courseStartDate,
        courseEndDate: data.fld_courseEndDate,
        securityDepositeAmount: data.fld_securityDepositeAmount,
        securityDepositeAmountDueDate: data.fld_securityDepositeAmountDueDate,
        updatedby: detail.fld_id,
        updatedon: data.updatedon,
        specifyCourse: data.fld_specifyCourse,
      },
      "UpdateScholarCourseInformation"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Saved and Updated Successfully");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  React.useEffect(() => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "GetScholarCourseInformation_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //
          reset(obj.data[0]);
          // setformdata({course_name: "Bachelor of Arts - B.A"})
        }
      })
    );
  }, []);
  //
  return (
    <>
      <div className="form-head ">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Course Information</h4>
        </div>
      </div>
      <form className="row m-0" onSubmit={handleSubmit(onSave)}>
        <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
          <p className="personal-info left-line-card px-lg-4 px-4 py-4">
            Course Information Details
          </p>
          <div className="row m-0 p-lg-4 p-3">
            <div className="col-md-4 mb-3">
              <FormControl
                fullWidth
                error={Boolean(errors.fld_courseName)}
                className="mobile-personal"
              >
                <InputLabel id="demo-simple-select-label">
                  Course Name*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select2"
                  label="Course Name*"
                  {...register("fld_courseName", {
                    required: "This field is required",
                  })}
                  error={errors?.fld_courseName}
                  value={watch("fld_courseName") ? watch("fld_courseName") : ""}
                  onChange={(e) => setValue("fld_courseName", e.target.value)}
                >
                  {CourseData.map((record) => {
                    return (
                      <MenuItem value={record.value}>{record.label}</MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>
                  {errors?.course_name ? errors.course_name.message : null}{" "}
                </FormHelperText>
              </FormControl>
            </div>
            {watch("fld_courseName") == "Others, Please specify" && (
              <div className="col-md-4 mb-3">
                <TextField
                  fullWidth
                  label=" Please Specify the Other Course*"
                  className="text-field"
                  {...register("fld_specifyCourse", {
                    required: "This field is required",
                  })}
                  error={errors?.fld_specifyCourse}
                  value={
                    watch("fld_specifyCourse") ? watch("fld_specifyCourse") : ""
                  }
                  helperText={
                    errors?.fld_specifyCourse
                      ? errors.fld_specifyCourse.message
                      : null
                  }
                />
              </div>
            )}
            <div className="col-md-4 mb-3">
              <TextField
                fullWidth
                label="Specialization if any"
                value={
                  watch("fld_specialization") ? watch("fld_specialization") : ""
                }
                className="text-field"
                {...register("fld_specialization")}
              />
            </div>
            <div className="col-md-4 mb-3">
              <FormControl fullWidth error={Boolean(errors.fld_duration)}>
                <InputLabel>Duration of Course (in Years)*</InputLabel>
                <Select
                  labelId="label-state"
                  id="id-state"
                  label="Duration of Course (in Years) *"
                  {...register("fld_duration", {
                    required: "This field is required",
                  })}
                  error={errors?.fld_duration}
                  value={watch("fld_duration") ? watch("fld_duration") : ""}
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                </Select>
                <FormHelperText>
                  {" "}
                  {errors?.state ? errors.state.message : null}{" "}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-md-4 mb-3">
              <TextField
                type="month"
                fullWidth
                label="Start Month & Year of Course*"
                className="text-field"
                {...register("fld_courseStartDate", {
                  required: "This field is required.",
                })}
                error={errors?.fld_courseStartDate}
                helperText={
                  errors?.fld_courseStartDate
                    ? errors.fld_courseStartDate.message
                    : null
                }
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-md-4 mb-3">
              <TextField
                type="month"
                fullWidth
                label="End Month & Year of Course*"
                {...register("fld_courseEndDate", {
                  required: "This field is required.",
                })}
                error={errors?.fld_courseEndDate}
                helperText={
                  errors?.fld_courseEndDate
                    ? errors.fld_courseEndDate.message
                    : null
                }
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-md-4 mb-3">
              <FormControl fullWidth error={Boolean(errors.fld_collegeFee)}>
                <InputLabel>College Fees*</InputLabel>
                <Select
                  labelId="label-id-city"
                  id="id-city"
                  label="College Fees "
                  {...register("fld_collegeFee", {
                    required: "This field is required",
                  })}
                  error={errors?.fld_collegeFee}
                  value={watch("fld_collegeFee") ? watch("fld_collegeFee") : ""}
                >
                  <MenuItem value="Semester">Semester</MenuItem>
                  <MenuItem value="Annual">Annual</MenuItem>
                </Select>
                <FormHelperText>
                  {" "}
                  {errors?.fees ? errors.fees.message : null}{" "}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-md-4 mb-3">
              <TextField
                fullWidth
                type="number"
                value={
                  watch("fld_securityDepositeAmount")
                    ? watch("fld_securityDepositeAmount")
                    : ""
                }
                label="Security Deposit"
                {...register("fld_securityDepositeAmount")}
                InputProps={{ inputProps: { min: 1 } }}
              />
            </div>
            <div className="col-md-4 mb-3">
              <TextField
                type="date"
                fullWidth
                label="Security Deposit Payment Date"
                {...register("fld_securityDepositeAmountDueDate")}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>
        </div>
        {action != "view" && (
          <div className="col-12 text-end">
            <div className="card-vahani" style={{ marginTop: "26px" }}>
              <div className="d-flex justify-content-end">
                <div className="but-width-form">
                  <Button
                    variant="contained"
                    className="but-login-vahani"
                    type="submit"
                  >
                    Save and update
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
};
export default CourseInformation;
