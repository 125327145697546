import "../Personalinfo/Personalinfo.css";
import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
// import GetApiCall from "../../../assets/API/GetApi";
// import moment from "moment";
import Notiflix from "notiflix";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
export default function ResetPassword() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  var salt = JSON.parse(localStorage.getItem("Salt"))?.[0];

  var password = JSON.parse(localStorage.getItem("OldPassword"));
  // const [detail, setdetail] = useState(null)
  // useEffect(() => {
  //   PostApiCall.postRequest(
  //     {
  //       studentId: login.fld_id
  //     },
  //     "GetStudentStatusAndInformation_ById"
  //   ).then((results) =>
  //     results.json().then((obj) => {
  //       if (results.status == 200 || results.status == 201) {
  //         setdetail(obj.data[0])
  //
  //       }
  //     }
  //     ))
  // }, [])
  const onSubmit = (data) => {
    //
    if (data.NewPasword) {
      if (data.ConfirmNewPassword) {
        if (data.NewPasword == data.ConfirmNewPassword) {
          Notiflix.Loading.dots();
          PostApiCall.postRequest(
            {
              email: login.fld_email,
              password: data.NewPasword,
              salt: salt.fld_salt,
              updatedon: null,
              updatedby: login.fld_id,
            },
            "ChangeStudentPassword"
          ).then((result) =>
            result.json().then((obj) => {
              if (result.status == 200 || result.status == 201) {
                Notiflix.Notify.success("Password Changed Successfully.");
                window.localStorage.setItem("OldPassword", data.NewPasword);
                Notiflix.Loading.remove();
                localStorage.clear();
                window.location.href = "/";
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(
                  "There seems to be an Error. Please retry!"
                );
              }
            })
          );
        } else {
          Notiflix.Notify.failure(
            "New Password and Confirm Password does not match."
          );
        }
      } else {
        Notiflix.Notify.failure("Please Enter Confirm Password.");
      }
    } else {
      Notiflix.Notify.failure("Please Enter New Password.");
    }
    //
  };
  return (
    <>
      <div className="form-head">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Change Password</h4>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
          <p className="personal-info left-line-card px-lg-4 px-3 py-4">
            Reset Password
          </p>
          <div className="row m-0 p-lg-4 p-2">
            <div className="col-lg-6 mb-3">
              <TextField
                disabled
                type="password"
                fullWidth
                label="Old Password*"
                value={password}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <TextField
                fullWidth
                label="New Password*"
                {...register("NewPasword", {
                  required: "Enter your new password",
                })}
                error={errors?.NewPassword}
                helperText={
                  errors?.NewPassword ? errors.NewPassword.message : null
                }
                // InputLabelProps={{ required: true }}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <TextField
                fullWidth
                label="Confirm New Password*"
                {...register("ConfirmNewPassword", {
                  required: "Enter your Confirm password",
                })}
                error={errors?.ConfirmNewPassword}
                helperText={
                  errors?.ConfirmNewPassword
                    ? errors.ConfirmNewPassword.message
                    : null
                }
                // InputLabelProps={{ required: true }}
              />
            </div>
          </div>
        </div>
        <div className="card-vahani" style={{ marginTop: "26px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div className="but-width-form">
              <Button
                variant="contained"
                className="but-login-vahani"
                type="submit"
              >
                Save and update
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
