import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import PostApiCall from '../../../assets/API/PostApi'
const { decode } = require('url-encode-decode');
const StudentDetailUrl = () => {
    const { data } = useParams()
    // let student_Id = Number(10042);
    const [expire, setexpire] = useState(false)
    const [student_Id, setstudent_Id] = useState(null)
    const [personal, setpersonal] = useState(null)
    const [address, setaddress] = useState(null)
    const [family, setfamily] = useState(null)
    const [addfamily, setaddfamily] = useState(null)
    const [school, setschool] = useState(null)
    const [acadmic, setacadmic] = useState([])
    const [essay, setessay] = useState(null)
    const [extra, setextra] = useState(null)
    const [prefrence, setprefrence] = useState([])
    useLayoutEffect(() => {
        //
        let d = decode(data).split(",").map(val => val.split("=")[1])
        var Difference_In_Time = new Date().getTime() - d[1];
        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 120);
        if (Difference_In_Days < 6) {
            getData(d[0])
        } else {
            setexpire(true)
        }
    }, [])
    const getData = async (student_Id) => {
        const personalData = await PostApiCall.postRequest({ studentId: student_Id, }, "GetStudentPersonalInformation_ByIdV2")
        const personalResult = await personalData.json()
        setpersonal(personalResult.data[0])
        const addressData = await PostApiCall.postRequest({ studentId: student_Id, }, "GetStudentAddressInformation_ByIdV2")
        const addressResult = await addressData.json()
        setaddress(addressResult.data[0])
        const familyData = await PostApiCall.postRequest({ studentId: student_Id }, "Get_StudentFamilyInformation_ByStudentIdV2")
        const familyResult = await familyData.json()
        setfamily(familyResult.data[0])
        const additionalfamilyData = await PostApiCall.postRequest({ studentId: student_Id, }, "Get_StudentFamilyAdditionalInfo_ByIdV2")
        const additionalfamilyResult = await additionalfamilyData.json()
        setaddfamily(additionalfamilyResult.data[0])
        const schoolData = await PostApiCall.postRequest({ studentId: student_Id, }, "GetStudentSchoolAndAcademicInformation_ByIdV2")
        const schoolResult = await schoolData.json()
        setschool(schoolResult.data[0])
        const acadmicData = await PostApiCall.postRequest({ studentId: student_Id, }, "Get_StudentAcademicAchievmentInformation_ByStudentIdV2")
        const acadmicResult = await acadmicData.json()
        setacadmic(acadmicResult.data)
        const essayData = await PostApiCall.postRequest({ studentId: student_Id, }, "GetStudentEssayInformation_ByIdV2")
        const essayResult = await essayData.json()
        setessay(essayResult.data[0])
        const extraData = await PostApiCall.postRequest({ studentId: student_Id, }, "GetStudentExtraCurricularActivitiesInformation_ByIdV2")
        const extraResult = await extraData.json()
        setextra(extraResult.data[0])
        const prefrenceData = await PostApiCall.postRequest({ studentId: student_Id, }, "Get_StudentCollegePreferenceInformation_ByStudentIdV2")
        const prefrenceResult = await prefrenceData.json()
        setprefrence(prefrenceResult.data)
    }
    if (expire) {
        return (
            <div className='container-fluid p-0'>
                <h3 className='text-danger text-center mt-5'>Your link has been Expired!</h3>
                <h3 className='text-danger text-center mt-2'>Please Generate New Link.</h3>
            </div>
        )
    } else {
        return (
            <div className="container-fluid parentdiv my-4 px-4">
                {/* ------------------------------Personal information---------------------------------- */}
                <div className="row rowpadding">
                    <div
                        className="col-12 mb-4 d-flex"
                        style={{
                            borderLeft: "4px solid rgb(32, 101, 209)",
                            paddingLeft: "10px",
                        }}
                    >
                        <h3>
                            <strong className="text-dark">Personal Information</strong>
                        </h3>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="row text-center">
                            <div className="col-lg-6 col-md-12 col-12 px-0">
                                <img
                                    className="imgsize"
                                    src={personal?.fld_photo}
                                    style={{ width: "250px", height: "250px", borderRadius: "50%", objectFit: "cover" }}
                                />
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <h5>
                                            <strong className="text-primary">{personal?.fld_firstName} {personal?.fld_middleName} {personal?.fld_lastName} </strong>
                                        </h5>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <h6 className="text-dark">{personal?.fld_email}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <h6 className="text-dark">{personal?.fld_mobile}, {personal?.fld_emergencyContactNumber} </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-12 px-0">
                        <div className="row">
                            <div className="col-5">
                                <h6>
                                    <strong className="text-dark">
                                        Student Identification Number
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-7">
                                <h6>{personal?.fld_studentId}</h6>
                            </div>
                        </div>
                        {/* <div className="row mt-2">
                        <div className="col-5">
                            <h6 className="text-dark">
                                <strong>Mobile Number</strong>
                            </h6>
                        </div>
                        <div className="col-7">
                            <h6 className="text-dark">{personal?.fld_mobile}</h6>
                        </div>
                    </div> */}
                        <div className="row mt-2">
                            <div className="col-5">
                                <h6 className="text-dark">
                                    <strong>Date of Birth</strong>
                                </h6>
                            </div>
                            <div className="col-7">
                                <h6 className="text-dark">{personal?.fld_dob}</h6>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-5">
                                <h6 className="text-dark">
                                    <strong>Birth Of Place</strong>
                                </h6>
                            </div>
                            <div className="col-7">
                                <h6 className="text-dark">{personal?.fld_city_name}, {personal?.fld_state_name}</h6>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-5">
                                <h6>
                                    <strong className="text-dark">Gender</strong>
                                </h6>
                            </div>
                            <div className="col-7">
                                <h6>{personal?.fld_gender}</h6>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-5">
                                <h6>
                                    <strong className="text-dark">Blood Group</strong>
                                </h6>
                            </div>
                            <div className="col-7">
                                <h6>{personal?.fld_bloodgroup}</h6>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-5">
                                <h6>
                                    <strong className="text-dark">Aadhar Card Number</strong>
                                </h6>
                            </div>
                            <div className="col-7">
                                <h6>{personal?.fld_aadhar}</h6>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-5">
                                <h6>
                                    <strong className="text-dark">PAN Card Number</strong>
                                </h6>
                            </div>
                            <div className="col-7">
                                <h6>{personal?.fld_pan}</h6>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-5">
                                <h6>
                                    <strong className="text-dark">
                                        Which year will you start your college ?
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-7">
                                <h6>{personal?.fld_startbatch}</h6>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-5">
                                <h6>
                                    <strong className="text-dark">Current Address</strong>
                                </h6>
                            </div>
                            <div className="col-7">
                                <h6>{address?.fld_currentAddress}, {address?.fld_current_city_name}, {address?.fld_current_state_name}, {address?.fld_current_country_name} - {address?.fld_currentPincode}</h6>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-5">
                                <h6>
                                    <strong className="text-dark">Permanent Address</strong>
                                </h6>
                            </div>
                            <div className="col-7">
                                <h6>{address?.fld_permanentAddress}, {address?.fld_permanent_city_name}, {address?.fld_permanent_state_name}, {address?.fld_permanent_country_name} - {address?.fld_permanentPincode}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* --------------------------- Other information -------------------------------------- */}
                <hr className='hrsize' />
                <div className="row rowpadding mt-4">
                    <div
                        className="col-12 mb-4 d-flex"
                        style={{
                            borderLeft: "4px solid rgb(32, 101, 209)",
                            paddingLeft: "10px",
                        }}
                    >
                        <h3>
                            <strong className="text-dark">Other Details</strong>
                        </h3>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <h5>
                                    <strong className="text-dark">
                                        How did you come to know about Vahani ?
                                    </strong>
                                </h5>
                            </div>
                            <div className="col-12">{personal?.fld_aboutVahani}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <h5>
                                    <strong className="text-dark">
                                        Do you know any senior scholar in Vahani ?
                                    </strong>
                                </h5>
                            </div>
                            <div className="col-12">{personal?.fld_knowVahani == "Yes" ? personal?.fld_scholarName : personal?.fld_knowVahani}</div>
                        </div>
                    </div>
                </div>
                {/* -------------------------Father details--------------------------------------------- */}
                <hr className='hrsize' />
                <div className="row rowpadding ">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="row">
                            <div
                                className="col-12 mb-4 d-flex"
                                style={{
                                    borderLeft: "4px solid rgb(32, 101, 209)",
                                    paddingLeft: "10px",
                                }}
                            >
                                <h3>
                                    <strong className="text-dark">Father's Details</strong>
                                </h3>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">Name</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_fatherName}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">Contact Number</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_fathermobile}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">
                                                Educational Qualification
                                            </strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_fatherEducation}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">Occupation</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_fatherOccupation}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">Email Id</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_fatherEmail}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">PAN Card Number</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_fatherPan}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">Aadhar Card</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_fatherAadhar}</h6>
                                    </div>
                                </div>
                                {/* <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">
                                            Upload Signature (Maximum Size 6Mb | File Format .pdf)
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-6">
                                    {family?.fld_fatherSign == null ?
                                        <button className='btn btn-primary' disabled >View</button> :
                                        <a href={family?.fld_fatherSign} target="_blank" className='btn btn-primary'>View</a>
                                    }
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    {/* -------------------------Mother details--------------------------------------------- */}
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="row">
                            <div
                                className="col-12 mb-4 d-flex"
                                style={{
                                    borderLeft: "4px solid rgb(32, 101, 209)",
                                    paddingLeft: "10px",
                                }}
                            >
                                <h3>
                                    <strong className="text-dark">Mother's Details</strong>
                                </h3>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">Name</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_motherName}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">Contact Number</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_mothermobile}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">
                                                Educational Qualification
                                            </strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_motherEducation}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">Occupation</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_motherOccupation}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">Email Id</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_motherEmail}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">PAN Card Number</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_motherPan}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">Aadhar Card</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_motherAadhar}</h6>
                                    </div>
                                </div>
                                {/* <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">
                                            Upload Signature (Maximum Size 6Mb | File Format .pdf)
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-6">
                                    {family?.fld_motherSign == null ?
                                        <button className='btn btn-primary' disabled >View</button> :
                                        <a href={family?.fld_fatherSign} target="_blank" className='btn btn-primary'>View</a>
                                    }
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    {/* -------------------------Guardian's Details--------------------------------------------- */}
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="row">
                            <div
                                className="col-12 mb-4 d-flex"
                                style={{
                                    borderLeft: "4px solid rgb(32, 101, 209)",
                                    paddingLeft: "10px",
                                }}
                            >
                                <h3>
                                    <strong className="text-dark">Guardian's Details</strong>
                                </h3>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">Name</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_guardianName}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">Contact Number</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_guardianmobile}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">
                                                Relationship With Student
                                            </strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_guardianRelationWithStudent}</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6>
                                            <strong className="text-dark">Address</strong>
                                        </h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{family?.fld_guardianAddress} {family?.fld_guardian_city_name} {family?.fld_guardian_state_name} {family?.fld_guardian_country_name} {family?.fld_guardianPincode}</h6>
                                    </div>
                                </div>
                                {/* <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">
                                            Upload Signature (Maximum Size 6Mb | File Format .pdf)
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-6">
                                    {family?.fld_guardianSign == null ?
                                        <button className='btn btn-primary' disabled >View</button> :
                                        <a href={family?.fld_fatherSign} target="_blank" className='btn btn-primary'>View</a>
                                    }
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    {/* -------------------------Upload Documents--------------------------------------------- */}
                    {/* <hr className="my-4 hrsize" /> */}
                    {/* <div className="col-12">
                    <div className="row">
                        <div
                            className="col-12 mb-4 d-flex"
                            style={{
                                borderLeft: "4px solid rgb(32, 101, 209)",
                                paddingLeft: "10px",
                            }}
                        >
                            <h3>
                                <strong className="text-dark">Upload Documents</strong>
                            </h3>
                        </div>
                        <div className="col-12 mb-5">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                    <img
                                        for="photo-upload2"
                                        src={family?.fld_laptop_image == null ? "https://admin.vahanischolarship.in/upload.jpg" : family?.fld_laptop_image}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "5%",
                                        }}
                                    />
                                    <h6>
                                        {" "}
                                        Upload Your Laptop Picture (Maximum Size 2MB | File Format
                                        *.jpeg, *.jpg, *.png){" "}
                                    </h6>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                    <img
                                        for="photo-upload2"
                                        src={family?.fld_family_image == null ? "https://admin.vahanischolarship.in/upload.jpg" : family?.fld_family_image}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "5%",
                                        }}
                                    />
                                    <h6>
                                        Upload Your Family Picture (Maximum Size 2MB | File Format
                                        *.jpeg, *.jpg, *.png)
                                    </h6>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                    <img
                                        for="photo-upload2"
                                        src={family?.fld_mobile_image == null ? "https://admin.vahanischolarship.in/upload.jpg" : family?.fld_mobile_image}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "5%",
                                        }}
                                    />
                                    <h6>
                                        Upload Your Mobile Picture (Maximum Size 2MB | File Format
                                        *.jpeg, *.jpg, *.png)
                                    </h6>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                    <img
                                        for="photo-upload2"
                                        src={family?.fld_vehicle_image == null ? "https://admin.vahanischolarship.in/upload.jpg" : family?.fld_vehicle_image}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "5%",
                                        }}
                                    />
                                    <h6>
                                        Upload Your Vehicle Picture (Maximum Size 2MB | File Format
                                        *.jpeg, *.jpg, *.png)
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                    {/* --------------------------- Additional Family Information -------------------------------------- */}
                    <hr class="my-4 hrsize" />
                    <div className="row mt-3">
                        <div
                            className="col-12 mb-4 d-flex"
                            style={{
                                borderLeft: "4px solid rgb(32, 101, 209)",
                                paddingLeft: "10px",
                            }}
                        >
                            <h3>
                                <strong className="text-dark">
                                    Additional Family Information
                                </strong>
                            </h3>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">Ration Card Details</strong>
                                    </h6>
                                </div>
                                <div className="col-6">{addfamily?.fld_rationCard}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">
                                            Number of Earning Members In The Family
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-6">{addfamily?.fld_earningMember}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">Annual Family Income</strong>
                                    </h6>
                                </div>
                                <div className="col-6">{addfamily?.fld_annualIncome}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">
                                            Number of Financially Dependent Children In Family
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-6">{addfamily?.fld_financiallyDependent}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">Type of Family</strong>
                                    </h6>
                                </div>
                                <div className="col-6">{addfamily?.fld_typeOfFamily}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">
                                            Does Your Family Own A House
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-6">{addfamily?.fld_familyHouse}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">
                                            Does Your Family Own A Vehicle
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-6">{addfamily?.fld_familyVehicle}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">
                                            Do You Live In The House That Your Family Owns
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-6">{addfamily?.fld_liveInFamilyHouse}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">
                                            Is Anyone Else Supporting Your Education
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-6">{addfamily?.fld_anyoneElseSupporting}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">
                                            Total Annual Income of People Supporting Your Education
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-6">{addfamily?.fld_annualIncomeSupportingPeople}</div>
                            </div>
                            {/* <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">
                                        Income Certificate - Salary Slip (Maximum Size 6Mb | File
                                        Format .pdf)
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-6">
                                {addfamily?.fld_salarySlip == null ?
                                    <button className='btn btn-primary' disabled >View</button> :
                                    <a href={addfamily?.fld_salarySlip} target="_blank" className='btn btn-primary'>View</a>
                                }
                            </div>
                        </div> */}
                        </div>
                    </div>
                    {/* --------------------------- School Details -------------------------------------- */}
                    <hr class="my-4 hrsize" />
                    <div className="row mt-3">
                        <div
                            className="col-12 mb-4 d-flex"
                            style={{
                                borderLeft: "4px solid rgb(32, 101, 209)",
                                paddingLeft: "10px",
                            }}
                        >
                            <h3>
                                <strong className="text-dark">School Details</strong>
                            </h3>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">School Name</strong>
                                    </h6>
                                </div>
                                <div className="col-6">{school?.fld_schoolName}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">Address Of School</strong>
                                    </h6>
                                </div>
                                <div className="col-6">{school?.fld_address}, {school?.fld_city_name}, {school?.fld_state_name}, {school?.fld_country_name} - {school?.fld_pincode}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">
                                            Level of English Knowledge
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-6">{school?.fld_englishKnowledge}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">
                                            Level of Computer Knowledge
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-6">{school?.fld_computerKnowledge}</div>
                            </div>
                        </div>
                    </div>
                    {/* --------------------------- Academic (X) Achievements -------------------------------------- */}
                    <table class="table table-bordered mt-4">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col"> Board / Examination / Stream</th>
                                <th scope="col">Year of Passing</th>
                                <th scope="col">Max Marks</th>
                                <th scope="col">Marks Obtained</th>
                                <th scope="col">Percent(%) of Marks</th>
                                <th scope="col">Principal's Email</th>
                                <th scope="col">Principal's Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {acadmic.map((item, i) => {
                                return (
                                    <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{item.fld_board}</td>
                                        <td>{item.fld_yearofpassing}</td>
                                        <td>{item.fld_maxmarks}</td>
                                        <td>{item.fld_marksobtained}</td>
                                        <td>{item.fld_permarks}</td>
                                        <td>{item.fld_principal_email_id}</td>
                                        <td>{item.fld_principal_contact}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    {/* <div className="row mt-3">
                    <div
                        className="col-12 mb-4 d-flex"
                        style={{
                            borderLeft: "4px solid rgb(32, 101, 209)",
                            paddingLeft: "10px",
                        }}
                    >
                        <h3>
                            <strong className="text-dark">Class X</strong>
                        </h3>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">
                                        Board / Examination / Stream
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[0]?.fld_board}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Year of Passing</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[0]?.fld_yearofpassing}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Max Marks</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[0]?.fld_maxmarks}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Marks Obtained</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[0]?.fld_marksobtained}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Percent(%) of Marks</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[0]?.fld_permarks}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Principals Email id</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[0]?.fld_principal_email_id}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">
                                        Principal's Contact Number
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[0]?.fld_principal_contact}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">
                                        Upload Marksheet (Maximum Size 6MB | File Format .pdf)
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-6">
                                {(acadmic[2]?.fld_marksheet == null || acadmic[2]?.fld_marksheet == "") ?
                                    <button className='btn btn-primary' disabled >View</button> :
                                    <a href={addfamily?.fld_marksheet} target="_blank" className='btn btn-primary'>View</a>
                                }
                            </div>
                        </div>
                    </div>
                </div> */}
                    {/* --------------------------- Academic (XI) Achievements -------------------------------------- */}
                    {/* <hr class="my-4 hrsize" /> */}
                    {/* <div className="row mt-3">
                    <div
                        className="col-12 mb-4 d-flex"
                        style={{
                            borderLeft: "4px solid rgb(32, 101, 209)",
                            paddingLeft: "10px",
                        }}
                    >
                        <h3>
                            <strong className="text-dark">Class XI</strong>
                        </h3>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">
                                        Board / Examination / Stream
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[1]?.fld_board}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Year of Passing</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[1]?.fld_yearofpassing}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Max Marks</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[1]?.fld_maxmarks}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Marks Obtained</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[1]?.fld_marksobtained}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Percent(%) of Marks</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[1]?.fld_permarks}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">
                                        What is your current subject discipline ?
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[1]?.fld_cuurent_sub_discipline}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Principals Email id</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[1]?.fld_principal_email_id}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">
                                        Principal's Contact Number
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[1]?.fld_principal_contact}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">
                                        Upload Marksheet (Maximum Size 6MB | File Format .pdf)*
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-6">
                                {(acadmic[1]?.fld_marksheet == null || acadmic[1]?.fld_marksheet == "") ?
                                    <button className='btn btn-primary' disabled >View</button> :
                                    <a href={addfamily?.fld_marksheet} target="_blank" className='btn btn-primary'>View</a>
                                }
                            </div>
                        </div>
                    </div>
                </div> */}
                    {/* --------------------------- Academic (XII) Achievements -------------------------------------- */}
                    {/* <hr class="my-4 hrsize" /> */}
                    {/* <div className="row mt-3">
                    <div
                        className="col-12 mb-4 d-flex"
                        style={{
                            borderLeft: "4px solid rgb(32, 101, 209)",
                            paddingLeft: "10px",
                        }}
                    >
                        <h3>
                            <strong className="text-dark">Class XII</strong>
                        </h3>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">
                                        Board / Examination / Stream
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[2]?.fld_board}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Year of Passing</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[2]?.fld_yearofpassing}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Max Marks</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[2]?.fld_maxmarks}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Marks Obtained</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[2]?.fld_marksobtained}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Percent(%) of Marks</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[2]?.fld_permarks}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">
                                        What is your current subject discipline ?
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[2]?.fld_cuurent_sub_discipline}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">Principals Email id</strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[2]?.fld_principal_email_id}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">
                                        Principal's Contact Number
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-6">{acadmic[2]?.fld_principal_contact}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <h6>
                                    <strong className="text-dark">
                                        Upload Marksheet (Maximum Size 6MB | File Format .pdf)*
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-6">
                                {(acadmic[2]?.fld_marksheet == null || acadmic[2]?.fld_marksheet == "") ?
                                    <button className='btn btn-primary' disabled >View</button> :
                                    <a href={addfamily?.fld_marksheet} target="_blank" className='btn btn-primary'>View</a>
                                }
                            </div>
                        </div>
                    </div>
                </div> */}
                    {/* --------------------------- Extra Curricular Activities Details -------------------------------------- */}
                    <hr class="my-4 hrsize" />
                    <div className="row mt-3">
                        <div
                            className="col-12 mb-4 d-flex"
                            style={{
                                borderLeft: "4px solid rgb(32, 101, 209)",
                                paddingLeft: "10px",
                            }}
                        >
                            <h3>
                                <strong className="text-dark">Co-Scholastic Activities</strong>
                            </h3>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">Sports</strong>
                                    </h6>
                                </div>
                                <div className="col-6">{extra?.fld_sports}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">Community Service</strong>
                                    </h6>
                                </div>
                                <div className="col-6">{extra?.fld_communityService}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">Performing Arts</strong>
                                    </h6>
                                </div>
                                <div className="col-6">{extra?.fld_performingArts}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">Student Leadership Arts</strong>
                                    </h6>
                                </div>
                                <div className="col-6">{extra?.fld_studentLeadership}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">Special Honours</strong>
                                    </h6>
                                </div>
                                <div className="col-6">{extra?.fld_specialHonours}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <h6>
                                        <strong className="text-dark">
                                            Others(Any Projects / Extra Activities)
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-6">{extra?.fld_others}</div>
                            </div>
                        </div>
                    </div>
                    {/* --------------------------- University / College Preference -------------------------------------- */}
                    <hr class="my-4 hrsize" />
                    <div className="row mt-3">
                        <div
                            className="col-12 mb-4 d-flex"
                            style={{
                                borderLeft: "4px solid rgb(32, 101, 209)",
                                paddingLeft: "10px",
                            }}
                        >
                            <h3>
                                <strong className="text-dark">
                                    University / College Preference List
                                </strong>
                            </h3>
                        </div>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Sr. No.</th>
                                    <th scope="col">Preference</th>
                                    <th scope="col">Name of Institute</th>
                                    <th scope="col">City</th>
                                    <th scope="col">State</th>
                                    <th scope="col">Degree</th>
                                </tr>
                            </thead>
                            <tbody>
                                {prefrence.map((item, i) => {
                                    return (
                                        <tr>
                                            <th scope="row">{i + 1}</th>
                                            <td>{item.fld_preference}</td>
                                            <td>{item.fld_institutename}</td>
                                            <td>{item.fld_city_name}</td>
                                            <td>{item.fld_state_name}</td>
                                            <td>{item.fld_degree}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* --------------------------- Essay -------------------------------------- */}
                    <hr class="my-4 hrsize" />
                    <div className="row mt-3">
                        <div
                            className="col-12 mb-4 d-flex"
                            style={{
                                borderLeft: "4px solid rgb(32, 101, 209)",
                                paddingLeft: "10px",
                            }}
                        >
                            <h3>
                                <strong className="text-dark">Essay</strong>
                            </h3>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <h6>
                                        <strong className="text-dark">
                                            1. Why are you interested in pursuing the undergraduate
                                            course in your preferred institution? How will it enable you
                                            to achieve your future career goal? (Min 200 & Max. 750
                                            words).
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-12 essayalignment">{essay?.fld_questionOne}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <h6>
                                        <strong className="text-dark">
                                            2. Write about your family background. You can include
                                            information like where you grew up, how many siblings you
                                            have and any extenuating circumstances. (Min 200 & Max. 750
                                            words )
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-12 essayalignment">{essay?.fld_questionTwo}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <h6>
                                        <strong className="text-dark">
                                            3. What has been the biggest challenge in your life and how
                                            did you address the challenge? (Min 200 & Max. 750 words) ?
                                        </strong>
                                    </h6>
                                </div>
                                <div className="col-12 essayalignment">{essay?.fld_questionThree}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default StudentDetailUrl
