import React, { useContext, useState } from "react";
import {
  MenuItem,
  FormControl,
  Select,
  Button,
  InputLabel,
  TextField,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import moment from "moment";
import PostApiCall from "../../../assets/API/PostApi";
import GetApiCall from "../../../assets/API/GetApi";
import Notiflix from "notiflix";
import store from "../../../store";
import { useHistory } from "react-router-dom";
const MenuProps = {
  PaperProps: { style: { maxHeight: 48 * 4.5 + 10, width: 250 } },
};
const names = ["Food", "Electricity / Wifi"];
const AddAccomodation = () => {
  const history = useHistory();
  const { accomodationData } = useContext(store);
  const [type, settype] = React.useState(null);
  const [Country, setCountry] = React.useState([]);
  const [State, setState] = React.useState([]);
  const [City, setCity] = React.useState([]);
  const [ValidationList, setValidationList] = useState(
    JSON.parse(localStorage.getItem("CommonValidation"))
  );
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const onSave = (data) => {
    //
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        accomodationid: data.fld_id == undefined ? null : data.fld_id,
        studentId: login.fld_id,
        accomodationType: type,
        securityDeposit:
          data.fld_securityDeposit == undefined
            ? null
            : data.fld_securityDeposit,
        rentType: data.fld_rentType == undefined ? null : data.fld_rentType,
        additionalService:
          data.fld_additionalService == undefined
            ? null
            : data.fld_additionalService.join(","),
        rentAmount:
          data.fld_rentAmount == undefined ? null : data.fld_rentAmount,
        dueDate: data.fld_DueDate == undefined ? null : data.fld_DueDate,
        startToStay:
          data.fld_startToStay == undefined ? null : data.fld_startToStay,
        ownerOrWardenName:
          data.fld_ownerOrWardenName == undefined
            ? null
            : data.fld_ownerOrWardenName,
        pgOrHostelName:
          data.fld_pgOrHostelName == undefined ? null : data.fld_pgOrHostelName,
        pgOrHosteladdress:
          data.fld_pgOrHosteladdress == undefined
            ? null
            : data.fld_pgOrHosteladdress,
        pgOrHostelcountry:
          data.fld_pgOrHostelcountry == undefined
            ? null
            : data.fld_pgOrHostelcountry,
        pgOrHostelstate:
          data.fld_pgOrHostelstate == undefined
            ? null
            : data.fld_pgOrHostelstate,
        pgOrHostelcity:
          data.fld_pgOrHostelcity == undefined ? null : data.fld_pgOrHostelcity,
        pgOrHostelpincode:
          data.fld_pgOrHostelpincode == undefined
            ? null
            : data.fld_pgOrHostelpincode,
        contactMobile:
          data.fld_contactMobile == undefined ? null : data.fld_contactMobile,
        contactEmail:
          data.fld_contactEmail == undefined ? null : data.fld_contactEmail,
        contactPan:
          data.fld_contactPan == undefined ? null : data.fld_contactPan,
        contactAadhar:
          data.fld_contactAadhar == undefined ? null : data.fld_contactAadhar,
        updatedBy: login.fld_id,
        updatedOn: moment().format("lll"),
      },
      "Update_ScholarAccomodationInformation"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          //
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Accomodation Details Updated successfully.");
          history.push("/accomodation-list");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  React.useEffect(() => {
    //
    // const paramId = new URLSearchParams(history.location.search).get('id')
    GetApiCall.getRequest("GetCountry").then((resultdes) =>
      resultdes.json().then((obj) => {
        setCountry(obj.data);
      })
    );
    if (accomodationData == null) {
      history.push("/accomodation-list");
    } else if (
      accomodationData.action == "edit" ||
      accomodationData.action == "view"
    ) {
      settype(accomodationData.data.fld_accomodationType);
      reset(accomodationData.data);
      setValue(
        "fld_additionalService",
        accomodationData.data.fld_additionalService == null
          ? []
          : accomodationData.data.fld_additionalService.split(",")
      );
      onCountryChange(accomodationData.data.fld_pgOrHostelcountry);
      onStateChange(accomodationData.data.fld_pgOrHostelstate);
    }
  }, []);
  const onCountryChange = (data) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        countryid: data,
      },
      "GetState"
    ).then((results) =>
      results.json().then((objstate) => {
        if (results.status == 200 || results.status == 201) {
          setState(objstate.data);
        }
        Notiflix.Loading.remove();
      })
    );
  };
  const onStateChange = (data) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        stateid: data,
      },
      "GetCity"
    ).then((results) =>
      results.json().then((objcity) => {
        if (results.status == 200 || results.status == 201) {
          setCity(objcity.data);
        }
        Notiflix.Loading.remove();
      })
    );
  };
  //
  React.useEffect(() => {
    Notiflix.Loading.dots();
    fetchData();
    // statusupdate
  }, []);
  function fetchData() {
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetCommonValidation"
    ).then((results10) =>
      results10.json().then((obj10) => {
        if (results10.status === 200 || results10.status === 201) {
          //
          setValidationList(obj10.data);
          Notiflix.Loading.remove();
        }
        Notiflix.Loading.remove();
      })
    );
  }
  return (
    <>
      <div className="form-head">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Add Accomodation</h4>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSave)}>
        <div className="card-vahani p-0">
          <p className="personal-info left-line-card px-lg-4 px-4 py-4">
            Accomodation Form
          </p>
          <div className="row m-0 p-4">
            <div className="col-lg-6 mb-lg-3 mobile-personal">
              <FormControl
                fullWidth
                disabled={
                  accomodationData.data == null
                    ? null
                    : accomodationData.data.fld_id != null
                }
              >
                <InputLabel id="demo-simple-select-label">
                  Please Select
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type ? type : ""}
                  label="Please Select"
                  onChange={(e) => {
                    settype(e.target.value);
                    reset();
                  }}
                >
                  <MenuItem value="Home">Home</MenuItem>
                  <MenuItem value="Hostel">Hostel</MenuItem>
                  <MenuItem value="PG">PG</MenuItem>
                </Select>
              </FormControl>
            </div>
            {type != null && (
              <div className=" row m-0 p-0">
                {(type == "Hostel" || type == "PG") && (
                  <>
                    <div className="col-lg-4 mb-lg-3 mobile-personal">
                      <FormControl
                        fullWidth
                        error={Boolean(errors.fld_rentType)}
                        className="mobile-personal"
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          error={Boolean(errors.fld_rentType)}
                        >
                          Rent Type*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select2"
                          label="Rent Type*"
                          {...register("fld_rentType", {
                            required: "This field is required",
                          })}
                          error={errors?.fld_rentType}
                          value={
                            watch("fld_rentType") ? watch("fld_rentType") : ""
                          }
                        >
                          <MenuItem value="Monthly">Monthly</MenuItem>
                          <MenuItem value="Semester">Semester</MenuItem>
                          <MenuItem value="Yearly">Yearly</MenuItem>
                        </Select>
                        <FormHelperText>
                          {errors?.fld_rentType
                            ? errors?.fld_rentType.message
                            : null}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div className="col-lg-4 mb-lg-3 mobile-personal">
                      <TextField
                        fullWidth
                        type="number"
                        label="Security Deposit*"
                        className="text-field capitalonly"
                        // InputProps={{ inputProps: { min: 1 } }}
                        {...register("fld_securityDeposit", {
                          required: "Please Enter Security Amount.",
                        })}
                        value={
                          watch("fld_securityDeposit")
                            ? watch("fld_securityDeposit")
                            : ""
                        }
                        error={errors?.fld_securityDeposit}
                        helperText={
                          errors?.fld_securityDeposit
                            ? errors?.fld_securityDeposit.message
                            : null
                        }
                      />
                    </div>
                    <div className="col-lg-4 mb-lg-3 mobile-personal">
                      <TextField
                        fullWidth
                        label="Rent Amount*"
                        className="text-field capitalonly"
                        type="number"
                        {...register("fld_rentAmount", {
                          required: "Please Enter Rent Amount.",
                        })}
                        value={
                          watch("fld_rentAmount") ? watch("fld_rentAmount") : ""
                        }
                        error={errors?.fld_rentAmount}
                        helperText={
                          errors?.fld_rentAmount
                            ? errors?.fld_rentAmount.message
                            : null
                        }
                        InputProps={{ inputProps: { min: 1 } }}
                      />
                    </div>
                    <div className="col-lg-4 mb-lg-3 mobile-personal">
                      <TextField
                        type="date"
                        fullWidth
                        label="Start To Stay*"
                        className="text-field capitalonly"
                        {...register("fld_startToStay", {
                          required: "Please Enter Stay Date.",
                        })}
                        value={
                          watch("fld_startToStay")
                            ? watch("fld_startToStay")
                            : ""
                        }
                        error={errors?.fld_startToStay}
                        helperText={
                          errors?.fld_startToStay
                            ? errors.fld_startToStay.message
                            : null
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="col-lg-4 mb-lg-3 mobile-personal">
                      <TextField
                        type="date"
                        fullWidth
                        label="Rent Due Date*"
                        className="text-field capitalonly"
                        {...register("fld_DueDate", {
                          required: "Please Enter Due Date",
                        })}
                        value={watch("fld_DueDate") ? watch("fld_DueDate") : ""}
                        error={errors?.fld_DueDate}
                        helperText={
                          errors?.fld_DueDate
                            ? errors.fld_DueDate.message
                            : null
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="col-12 mb-lg-3 mobile-personal">
                      <FormControl fullWidth>
                        <InputLabel id="demo-multiple-chip-label">
                          Additional Services
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          {...register("fld_additionalService", {
                            required: "Please Select Additional Services",
                          })}
                          value={
                            watch("fld_additionalService")
                              ? watch("fld_additionalService")
                              : []
                          }
                          onChange={(e) =>
                            setValue(
                              "fld_additionalService",
                              typeof e.target.value === "string"
                                ? e.target.value.split(",")
                                : e.target.value
                            )
                          }
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Additional Services"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              {" "}
                              {name}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-6 mb-lg-3 mobile-personal">
                      <TextField
                        fullWidth
                        label="PG / Hostel Name*"
                        multiline
                        maxRows={4}
                        variant="outlined"
                        style={{ width: "100%" }}
                        {...register("fld_pgOrHostelName", {
                          required: "This field is required",
                        })}
                        value={
                          watch("fld_pgOrHostelName")
                            ? watch("fld_pgOrHostelName")
                            : ""
                        }
                        error={errors?.fld_pgOrHostelName}
                        helperText={
                          errors?.fld_pgOrHostelName
                            ? errors.fld_pgOrHostelName.message
                            : null
                        }
                      />
                    </div>
                    <div className="col-lg-6 mb-lg-3 mobile-personal">
                      <TextField
                        fullWidth
                        label="Owner / Hostel Warden Name*"
                        multiline
                        maxRows={4}
                        variant="outlined"
                        style={{ width: "100%" }}
                        {...register("fld_ownerOrWardenName", {
                          required: "This field is required",
                          pattern: {
                            value: new RegExp(
                              ValidationList[0].fld_reg_ex,
                              "i"
                            ),
                            message: "Enter a valid name",
                          },
                        })}
                        value={
                          watch("fld_ownerOrWardenName")
                            ? watch("fld_ownerOrWardenName")
                            : ""
                        }
                        error={errors?.fld_ownerOrWardenName}
                        helperText={
                          errors?.fld_ownerOrWardenName
                            ? errors.fld_ownerOrWardenName.message
                            : null
                        }
                      />
                    </div>
                  </>
                )}
                <div className="col-12 mb-lg-3 mobile-personal">
                  <TextField
                    fullWidth
                    label="Address*"
                    multiline
                    maxRows={4}
                    {...register("fld_pgOrHosteladdress", {
                      required: "This field is required",
                      pattern: {
                        value: new RegExp(ValidationList[3].fld_reg_ex, "i"),
                        message: "Enter a valid address",
                      },
                    })}
                    value={
                      watch("fld_pgOrHosteladdress")
                        ? watch("fld_pgOrHosteladdress")
                        : ""
                    }
                    error={errors?.fld_pgOrHosteladdress}
                    helperText={
                      errors?.fld_pgOrHosteladdress
                        ? errors.fld_pgOrHosteladdress.message
                        : null
                    }
                  />
                </div>
                <div className="col-lg-3  mb-lg-3 mobile-personal">
                  <FormControl
                    fullWidth
                    error={Boolean(errors.fld_pgOrHostelcountry)}
                    className="mobile-personal"
                  >
                    <InputLabel id="demo-simple-select-label">
                      Country*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select2"
                      label="Country *"
                      {...register("fld_pgOrHostelcountry", {
                        required: "This field is required",
                      })}
                      error={errors?.fld_pgOrHostelcountry}
                      onChange={(e) => {
                        onCountryChange(e.target.value);
                        setValue("fld_pgOrHostelcountry", e.target.value);
                        setValue("fld_pgOrHostelstate", "");
                        setValue("fld_pgOrHostelcity", "");
                        setCity([]);
                      }}
                      value={
                        watch("fld_pgOrHostelcountry")
                          ? watch("fld_pgOrHostelcountry")
                          : ""
                      }
                    >
                      {Country.map((record) => {
                        return (
                          <MenuItem value={record.value}>
                            {record.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {" "}
                      {errors?.fld_pgOrHostelcountry
                        ? errors.fld_pgOrHostelcountry.message
                        : null}{" "}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-lg-3 mb-lg-3 mobile-personal">
                  <FormControl
                    fullWidth
                    error={Boolean(errors.fld_pgOrHostelstate)}
                  >
                    <InputLabel id="demo-simple-select-label" required>
                      State
                    </InputLabel>
                    <Select
                      labelId="label-state"
                      id="id-state"
                      label="State *"
                      {...register("fld_pgOrHostelstate", {
                        required: "This field is required",
                      })}
                      error={errors?.fld_pgOrHostelstate}
                      onChange={(e) => {
                        onStateChange(e.target.value);
                        setValue("fld_pgOrHostelstate", e.target.value);
                        setValue("fld_pgOrHostelcity", "");
                      }}
                      value={
                        watch("fld_pgOrHostelstate")
                          ? watch("fld_pgOrHostelstate")
                          : ""
                      }
                    >
                      {State.map((record, item) => {
                        return (
                          <MenuItem value={record.value}>
                            {record.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {" "}
                      {errors?.fld_pgOrHostelstate
                        ? errors.fld_pgOrHostelstate.message
                        : null}{" "}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-lg-3 mb-lg-3 mobile-personal">
                  <FormControl
                    fullWidth
                    error={Boolean(errors.fld_pgOrHostelcity)}
                  >
                    <InputLabel id="label-id-city" required>
                      City
                    </InputLabel>
                    <Select
                      labelId="label-id-city"
                      id="id-city"
                      {...register("fld_pgOrHostelcity", {
                        required: "This field is required",
                      })}
                      error={errors?.fld_pgOrHostelcity}
                      value={
                        watch("fld_pgOrHostelcity")
                          ? watch("fld_pgOrHostelcity")
                          : ""
                      }
                      label="City "
                    >
                      {City.map((record) => {
                        return (
                          <MenuItem key={record.label} value={record.value}>
                            {record.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {" "}
                      {errors?.fld_pgOrHostelcity
                        ? errors.fld_pgOrHostelcity.message
                        : null}{" "}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-lg-3 mb-lg-3 mobile-personal">
                  <TextField
                    fullWidth
                    type="number"
                    label="Pincode"
                    className="text-field mobile-personal"
                    {...register("fld_pgOrHostelpincode", {
                      required: "This field is required",
                      minLength: {
                        value: 6,
                        message: "Enter minimum 6-digit Pincode",
                      },
                      maxLength: {
                        value: 6,
                        message: "Enter maximum 6-digit Pincode",
                      },
                      pattern: {
                        value: new RegExp(ValidationList[4].fld_reg_ex, "i"),
                        message: "Enter a valid pincode",
                      },
                    })}
                    value={
                      watch("fld_pgOrHostelpincode")
                        ? watch("fld_pgOrHostelpincode")
                        : ""
                    }
                    error={errors?.fld_pgOrHostelpincode}
                    helperText={
                      errors?.fld_pgOrHostelpincode
                        ? errors.fld_pgOrHostelpincode.message
                        : null
                    }
                  />
                </div>
                <div className="col-lg-6 mb-lg-3 mobile-personal">
                  <TextField
                    type="number"
                    fullWidth
                    label="Mobile Number"
                    className="text-field mobile-personal"
                    {...register("fld_contactMobile", {
                      required: false,
                      pattern: {
                        value: new RegExp(ValidationList[2].fld_reg_ex, "i"),
                        message: "Enter a valid mobile number",
                      },
                      minLength: {
                        value: 10,
                        message: "Enter minimum 10-digit number",
                      },
                      maxLength: {
                        value: 10,
                        message: "Enter maximum 10-digit number",
                      },
                    })}
                    value={
                      watch("fld_contactMobile")
                        ? watch("fld_contactMobile")
                        : ""
                    }
                    error={errors?.fld_contactMobile}
                    helperText={
                      errors?.fld_contactMobile
                        ? errors.fld_contactMobile.message
                        : null
                    }
                  />
                </div>
                <div className="col-lg-6 mb-lg-3 mobile-personal">
                  <TextField
                    label="Email"
                    fullWidth
                    {...register("fld_contactEmail", {
                      required: false,
                      pattern: {
                        value: new RegExp(ValidationList[1].fld_reg_ex, "i"),
                        message: "Enter a valid email id",
                      },
                    })}
                    value={
                      watch("fld_contactEmail") ? watch("fld_contactEmail") : ""
                    }
                  />
                </div>
                <div className="col-lg-6 mb-lg-3 mobile-personal">
                  <TextField
                    fullWidth
                    type="number"
                    label="Adhaar Card Number"
                    className="text-field mobile-personal"
                    {...register("fld_contactAadhar", {
                      required: false,
                      pattern: {
                        value: new RegExp(ValidationList[9].fld_reg_ex, "i"),
                        message: "Enter valid Aadhaar card number",
                      },
                      minLength: {
                        value: 12,
                        message: "Enter a valid aadhaar number",
                      },
                      maxLength: {
                        value: 12,
                        message: "Enter maximum 12-digit number",
                      },
                    })}
                    value={
                      watch("fld_contactAadhar")
                        ? watch("fld_contactAadhar")
                        : ""
                    }
                    error={errors?.fld_contactAadhar}
                    helperText={
                      errors?.fld_contactAadhar
                        ? errors.fld_contactAadhar.message
                        : null
                    }
                  />
                </div>
                <div className="col-lg-6 mb-lg-3 mobile-personal">
                  <TextField
                    fullWidth
                    label="Pan Card Number"
                    className="text-field capitalonly"
                    {...register("fld_contactPan", {
                      required: false,
                      pattern: {
                        value: new RegExp(ValidationList[8].fld_reg_ex, "i"),
                        message: "Enter a valid PAN number",
                      },
                    })}
                    value={
                      watch("fld_contactPan") ? watch("fld_contactPan") : ""
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {accomodationData.action != "view" && (
          <div className="card-vahani" style={{ marginTop: "26px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <div className="but-width-form">
                <Button
                  variant="contained"
                  className="but-login-vahani"
                  type="submit"
                >
                  Save and update
                </Button>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
};
export default AddAccomodation;
