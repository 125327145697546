import "../Personalinfo/Personalinfo.css";
import React, { useState, useContext, useEffect } from "react";
import {
  FormControl,
  Button,
  Select,
  TextField,
  MenuItem,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
// import GetApiCall from "../../../assets/API/GetApi";
import moment from "moment";
import Notiflix from "notiflix";
import databoard from "../../../assets/API/Data/Data.json";
// import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Academic Achievement/AcademicAchievement.css";
// import IconButton from "@mui/material/IconButton";
// import { FaEdit } from "react-icons/fa";
// import { StepperContext } from "@mui/material";
// import { preProcessFile } from "typescript";
export default function AcademicAchievement({ stdid, action }) {
  var otp = Math.floor(100000 + Math.random() * 900000);
  var otp2 = Math.floor(100000 + Math.random() * 900000);
  var otp3 = Math.floor(100000 + Math.random() * 900000);
  var DecimalRegex = "/^(d*.?d{0,2}|.d{0,9})$/";
  var login = stdid
    ? { fld_id: stdid }
    : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  var ValidationList = JSON.parse(localStorage.getItem("Validation"));
  const [Xmarksheet, setXmarksheet] = useState([]);
  const [MarksheetUrl, setMarksheetUrl] = useState(
    "https://admin.vahanischolarship.in/API/AddPdf2"
  );
  const [XImarksheet, setXImarksheet] = useState([]);
  const [XIImarksheet, setXIImarksheet] = useState([]);
  const [marksheetpdf, setmarksheetpdf] = React.useState("");
  const [marksheetpdf11, setmarksheetpdf11] = React.useState("");
  const [marksheetpdf12, setmarksheetpdf12] = React.useState("");
  const [stuid1, setstudid1] = React.useState(null);
  const [stuid2, setstudid2] = React.useState(null);
  const [stuid3, setstudid3] = React.useState(null);
  const [stu, setstudent] = React.useState([]);
  var year = [];
  for (var i = 2000; i <= 2050; i++) {
    year.push({
      label: i,
      value: i,
    });
  }

  const [disciples, setDisciplines] = useState([
    "Arts/Humanities",
    "Science",
    "Commerce"
  ])

  const [values, setValues] = React.useState({
    fld_board: "",
    fld_yearofpassing: "",
    fld_board_11: "",
    fld_yearofpassing_11: "",
    fld_board_12: "",
    fld_yearofpassing_12: "",
    fld_discipline11: "",
    fld_discipline12: "",
  });
  const onChangeHandler = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    //
  };
  React.useEffect(() => {
    Notiflix.Loading.dots();
    GetStudentPersonalInfo();
    // statusupdate
  }, []);
  // const GetStudentPersonalInfo_byid = (e) => {
  //   setedit(true);
  //   PostApiCall.postRequest(
  //     {
  //       id: e,
  //     },
  //     "Get_StudentAcademicAchievmentInformation_ById"
  //   ).then((results) =>
  //     results.json().then((obj) => {
  //       if (results.status === 200 || results.status === 201) {
  //       }
  //     })
  //   );
  // };
  const GetStudentPersonalInfo = () => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentAcademicAchievmentInformation_ByStudentId"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Loading.remove();
          //
          if (obj.data.length !== 0) {
            const discipline11 = obj.data[1].fld_cuurent_sub_discipline
            const discipline12 = obj.data[2].fld_cuurent_sub_discipline

            setDisciplines(prevState => {
              const updatedDisciplineDropdown = [...prevState];

              if (discipline11 != null && !updatedDisciplineDropdown.includes(discipline11)) {
                updatedDisciplineDropdown.push(discipline11);
              }
              if (discipline12 != null && !updatedDisciplineDropdown.includes(discipline12)) {
                updatedDisciplineDropdown.push(discipline12);
              }
              return updatedDisciplineDropdown;
            });

            setstudent(obj.data);
            setstudid1(obj.data[0].fld_id);
            setstudid2(obj.data[1].fld_id);
            setstudid3(obj.data[2].fld_id);
            setmarksheetpdf(obj.data[0].fld_marksheet);
            setmarksheetpdf11(obj.data[1].fld_marksheet);
            setmarksheetpdf12(obj.data[2].fld_marksheet);
            reset({
              fld_class: obj.data[0].fld_class,
              fld_marksobtained: obj.data[0].fld_marksobtained,
              fld_maxmarks: obj.data[0].fld_maxmarks,
              fld_permarks: obj.data[0].fld_permarks,
              fld_principal_email_id: obj.data[0].fld_principal_email_id,
              fld_principal_contact: obj.data[0].fld_principal_contact,
              fld_marksheet: obj.data[0].fld_marksheet,
              fld_class_11: obj.data[1].fld_class,
              fld_marksobtained_11: obj.data[1].fld_marksobtained,
              fld_maxmarks_11: obj.data[1].fld_maxmarks,
              fld_permarks_11: obj.data[1].fld_permarks,
              fld_principal_email_id_11: obj.data[1].fld_principal_email_id,
              fld_principal_contact_11: obj.data[1].fld_principal_contact,
              fld_marksheet11: obj.data[1].fld_marksheet,
              fld_cuurent_sub_discipline_11:
                obj.data[1].fld_cuurent_sub_discipline,
              fld_class_12: obj.data[2].fld_class,
              fld_marksobtained_12: obj.data[2].fld_marksobtained,
              fld_maxmarks_12: obj.data[2].fld_maxmarks,
              fld_permarks_12: obj.data[2].fld_permarks,
              fld_principal_email_id_12: obj.data[2].fld_principal_email_id,
              fld_principal_contact_12: obj.data[2].fld_principal_contact,
              fld_cuurent_sub_discipline_12:
                obj.data[2].fld_cuurent_sub_discipline,
              fld_marksheet12: obj.data[2].fld_marksheet,
            });
            setValues({
              fld_board: obj.data[0].fld_board,
              fld_yearofpassing: obj.data[0].fld_yearofpassing,
              fld_board_11: obj.data[1].fld_board,
              fld_yearofpassing_11: obj.data[1].fld_yearofpassing,
              fld_board_12: obj.data[2].fld_board,
              fld_yearofpassing_12: obj.data[2].fld_yearofpassing,
              fld_discipline11: obj.data[1].fld_cuurent_sub_discipline,
              fld_discipline12: obj.data[2].fld_cuurent_sub_discipline,
            });
          }
        }
      })
    );
  };
  const onPost = (Academic) => {
    if (Xmarksheet.length != 0) {
      Notiflix.Loading.dots();
      const form = new FormData();
      //
      form.append("file", Xmarksheet);
      form.append("foldername", "AcademicMarksheet");
      form.append("filename", otp + "-10thMarksheet-" + Xmarksheet.name);
      fetch(MarksheetUrl, {
        method: "POST",
        body: form,
      }).then((image) => {
        image
          .json()
          .then((data) => ({
            data: data,
            status: image.status,
          }))
          .then((res) => {
            onPost11Marksheet(Academic);
          });
      });
    } else {
      onPost11Marksheet(Academic);
    }
  };
  const onPost11Marksheet = (Academic) => {
    if (XImarksheet.length != 0) {
      Notiflix.Loading.dots();
      const form = new FormData();
      //
      form.append("file", XImarksheet);
      form.append("foldername", "AcademicMarksheet");
      form.append("filename", otp2 + "-11thmarksheet" + "-" + XImarksheet.name);
      fetch(MarksheetUrl, {
        method: "POST",
        body: form,
      }).then((image) => {
        image
          .json()
          .then((data) => ({
            data: data,
            status: image.status,
          }))
          .then((res) => {
            onPost12Marksheet(Academic);
          });
      });
    } else {
      onPost12Marksheet(Academic);
    }
  };
  const onPost12Marksheet = (Academic) => {
    if (XIImarksheet.length != 0) {
      Notiflix.Loading.dots();
      const form = new FormData();
      //
      form.append("file", XIImarksheet);
      form.append("foldername", "AcademicMarksheet");
      form.append(
        "filename",
        otp3 + "-12thmarksheet" + "-" + XIImarksheet.name
      );
      fetch(MarksheetUrl, {
        method: "POST",
        body: form,
      }).then((image) => {
        image
          .json()
          .then((data) => ({
            data: data,
            status: image.status,
          }))
          .then((res) => {
            onPostAcademicInfo(Academic);
          });
      });
    } else {
      onPostAcademicInfo(Academic);
    }
  };
  const onPostAcademicInfo = (Academic) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: stuid1,
        studentid: login.fld_id,
        sclass: "Class X",
        board: Academic.fld_board,
        yearofpassing: Academic.fld_yearofpassing,
        maxmarks: Academic.fld_maxmarks,
        marksobtained: Academic.fld_marksobtained,
        permarks: Academic.fld_permarks,
        marksheet:
          Xmarksheet.length == 0
            ? marksheetpdf
            : "https://admin.vahanischolarship.in/Images/" +
            "AcademicMarksheet/" +
            otp +
            "-10thMarksheet-" +
            Xmarksheet.name,
        createdon: moment().format("lll"),
        cuurentsubdiscipline: Academic.fld_cuurent_sub_discipline,
        principalemailid: Academic.fld_principal_email_id,
        principalcontact: Academic.fld_principal_contact,
      },
      "UpdateStudentAcademicAchievmentInformation"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          PostApiCall.postRequest(
            {
              id: stuid2,
              studentid: login.fld_id,
              sclass: "Class XI",
              marksheet:
                XImarksheet.length == 0
                  ? marksheetpdf11
                  : "https://admin.vahanischolarship.in/Images/" +
                  "AcademicMarksheet/" +
                  otp2 +
                  "-11thmarksheet" +
                  "-" +
                  XImarksheet.name,
              board: Academic.fld_board_11,
              yearofpassing: Academic.fld_yearofpassing_11,
              maxmarks: Academic.fld_maxmarks_11,
              marksobtained: Academic.fld_marksobtained_11,
              permarks: Academic.fld_permarks_11,
              // marksheet: Academic.marksheet,
              createdon: moment().format("lll"),
              cuurentsubdiscipline: Academic.fld_cuurent_sub_discipline_11,
              principalemailid: Academic.fld_principal_email_id_11,
              principalcontact: Academic.fld_principal_contact_11,
            },
            "UpdateStudentAcademicAchievmentInformation"
          ).then((result) =>
            result.json().then((obj) => {
              if (result.status == 200 || result.status == 201) {
                // 12TH

                PostApiCall.postRequest(
                  {
                    id: stuid3,
                    studentid: login.fld_id,
                    sclass: "Class XII",
                    board: Academic.fld_board_12,
                    yearofpassing: Academic.fld_yearofpassing_12,
                    maxmarks:
                      Academic.fld_maxmarks_12 == ""
                        ? null
                        : Academic.fld_maxmarks_12,
                    marksobtained:
                      Academic.fld_marksobtained_12 == ""
                        ? null
                        : Academic.fld_marksobtained_12,
                    permarks:
                      Academic.fld_permarks_12 == ""
                        ? null
                        : Academic.fld_permarks_12,
                    marksheet:
                      XIImarksheet.length == 0
                        ? marksheetpdf12
                        : "https://admin.vahanischolarship.in/Images/" +
                        "AcademicMarksheet/" +
                        otp3 +
                        "-12thmarksheet" +
                        "-" +
                        XIImarksheet.name,
                    createdon: moment().format("lll"),
                    cuurentsubdiscipline:
                      Academic.fld_cuurent_sub_discipline_12,
                    principalemailid: Academic.fld_principal_email_id_12,
                    principalcontact: Academic.fld_principal_contact_12,
                  },
                  "UpdateStudentAcademicAchievmentInformation"
                ).then((result) =>
                  result.json().then((obj) => {
                    if (result.status == 200 || result.status == 201) {
                      GetStudentPersonalInfo();
                      Notiflix.Loading.remove();
                      Notiflix.Notify.success("Saved and Updated Successfully");
                    } else {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure(
                        "There seems to be an Error. Please retry!"
                      );
                    }
                  })
                );
                Notiflix.Loading.remove();
              } else {
                Notiflix.Loading.remove();
              }
            })
          );
          // -------------------test--------------------
          GetStudentPersonalInfo();
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      })
    );
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();
  var totalmarks1 = watch("fld_maxmarks_11");
  var marksobtained1 = watch("fld_marksobtained_11");
  var percentage1 = (marksobtained1 / totalmarks1) * 100;
  var totalmarks2 = watch("fld_maxmarks_12");
  var marksobtained2 = watch("fld_marksobtained_12");
  var percentage2 = (marksobtained2 / totalmarks2) * 100;
  // //
  const onSubmit = (Academic) => {
    //
    // e.preventDe
    onPost(Academic);
  };
  return (
    <>
      <div>
        <div className="form-head">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Academic Achievements</h4>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p className="personal-info left-line-card px-lg-4 px-3 py-4">
              Class X
            </p>
            <div className="row m-0 p-lg-4 p-2">
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <FormControl fullWidth error={Boolean(errors.fld_board)}>
                  <InputLabel id="label-id-board">
                    Board / Examination / Stream*
                  </InputLabel>
                  <Select
                    labelId="label-board"
                    id="id-boadrd"
                    label=" Board / Examination / Stream*"
                    {...register("fld_board", {
                      required: "This field is required",
                    })}
                    error={!!errors?.fld_board}
                    value={values.fld_board}
                    onChange={onChangeHandler}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {databoard.map((record) => (
                      <MenuItem value={record.label}>{record.label}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_board ? errors.fld_board.message : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <FormControl
                  fullWidth
                  error={Boolean(errors.fld_yearofpassing)}
                >
                  <InputLabel id="demo-simple-select-label">
                    Year of Passing*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    label="Year of Passing*"
                    {...register("fld_yearofpassing", {
                      required: "This field is required",
                    })}
                    error={!!errors?.fld_yearofpassing}
                    value={values.fld_yearofpassing}
                    onChange={onChangeHandler}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {year.map((record) => (
                      <MenuItem value={record.value}>{record.value}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_yearofpassing
                      ? errors.fld_yearofpassing.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Max Marks*"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_maxmarks", {
                    required: "This field is required",
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: "Enter valid number",
                    },
                  })}
                  onChange={(e) => {
                    if (e.target.value > 0) {
                      setValue("fld_maxmarks", e.target.value);
                    } else {
                      Notiflix.Notify.failure("Marks must be greater than 0.");
                    }
                  }}
                  error={errors?.fld_maxmarks}
                  helperText={
                    errors?.fld_maxmarks ? errors.fld_maxmarks.message : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Marks Obtained*"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_marksobtained", {
                    required: "This field is required",
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: "Enter valid number",
                    },
                  })}
                  error={errors?.fld_marksobtained}
                  helperText={
                    errors?.fld_marksobtained
                      ? errors.fld_marksobtained.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    if (
                      Number(e.target.value) <= Number(watch("fld_maxmarks"))
                    ) {
                      let per = (e.target.value / watch("fld_maxmarks")) * 100;
                      setValue("fld_permarks", per % 1 ? per.toFixed(2) : per);
                    } else {
                      Notiflix.Notify.failure(
                        "Marks Obtained must be less than Max. Marks"
                      );
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Percent(%) of Marks"
                  disabled
                  variant="filled"
                  style={{ width: "100%" }}
                  {...register("fld_permarks", {
                    required: false,
                  })}
                  error={errors?.fld_permarks}
                  helperText={
                    errors?.fld_permarks ? errors.fld_permarks.message : null
                  }
                  InputLabelProps={{ shrink: true }}
                  value={watch("fld_permarks") ? watch("fld_permarks") : ""}
                />
              </div>
              <div
                className={
                  marksheetpdf == ""
                    ? "col-lg-6 col-12 mb-lg-3 mobile-personal"
                    : "col-lg-5 mb-lg-3 mobile-personal"
                }
              >
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Upload Marksheet (Maximum Size 6MB | File Format .pdf .jpeg)*"
                  variant="outlined"
                  className="text-field"
                  type="File"
                  {...register("marksheet", {
                    required: marksheetpdf ? false : "This field is required",
                  })}
                  onChange={(e) => {
                    if (e.target.files[0].size < 6000000) {
                      const reader = new FileReader();
                      const file = e.target.files[0];
                      reader.onloadend = () => {
                        // //
                        setXmarksheet(file);
                      };
                      // //
                      reader.readAsDataURL(file);
                    } else {
                      Notiflix.Notify.failure(
                        "File too large, upload file less than 6 MB."
                      );
                    }
                  }}
                  error={!!errors?.marksheet}
                  helperText={
                    errors?.marksheet ? errors.marksheet.message : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ accept: "application/pdf, image/jpeg" }}
                />
              </div>
              <div
                className="col-lg-1 mb-lg-3 mobile-personal  mt-1"
                style={{
                  display: marksheetpdf == "" ? "none" : "block",
                  textAlign: "center",
                }}
              >
                <a
                  target="_blank"
                  className="but-login-vahani"
                  href={marksheetpdf}
                >
                  View
                </a>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Principals Email id*"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_principal_email_id", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[1].fld_reg_ex, "i"),
                      message: "Enter a valid email id",
                    },
                  })}
                  error={!!errors?.fld_principal_email_id}
                  helperText={
                    errors?.fld_principal_email_id
                      ? errors.fld_principal_email_id.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Principal's Contact Number*"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_principal_contact", {
                    required: "This field is required",
                    maxLength: {
                      value: 10,
                      message: "Enter maximum 10-digit number",
                    },
                    minLength: {
                      value: 10,
                      message: "Enter minimum 10-digit number",
                    },
                    pattern: {
                      value: new RegExp(ValidationList[2].fld_reg_ex, "i"),
                      message: "Enter a valid mobile number",
                    },
                  })}
                  error={!!errors?.fld_principal_contact}
                  helperText={
                    errors?.fld_principal_contact
                      ? errors.fld_principal_contact.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </div>
          {/* XI */}
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            {/* <p className="personal-info">Board / Examination / Stream</p> */}
            <p className="personal-info left-line-card px-lg-4 px-3 py-4">
              Class XI
            </p>
            <div className="row m-0 p-lg-4 p-2">
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <FormControl fullWidth error={Boolean(errors.fld_board_11)}>
                  <InputLabel id="label-id-board">
                    Board / Examination / Stream*
                  </InputLabel>
                  <Select
                    labelId="label-board"
                    id="id-boadrd"
                    label=" Board / Examination / Stream*"
                    {...register("fld_board_11", {
                      required: "This field is required",
                    })}
                    error={!!errors?.fld_board_11}
                    value={values.fld_board_11}
                    onChange={onChangeHandler}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {databoard.map((record) => (
                      <MenuItem value={record.label}>{record.label}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_board_11 ? errors.fld_board_11.message : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6  col-12 mb-lg-3 mobile-personal">
                <FormControl
                  fullWidth
                  error={Boolean(errors.fld_yearofpassing_11)}
                >
                  <InputLabel id="demo-simple-select-label">
                    Year of Passing*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    label="Year of Passing*"
                    {...register("fld_yearofpassing_11", {
                      required: "This field is required",
                    })}
                    error={!!errors?.fld_yearofpassing_11}
                    value={values.fld_yearofpassing_11}
                    onChange={onChangeHandler}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {year.map((record) => (
                      <MenuItem value={record.value}>{record.value}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_yearofpassing_11
                      ? errors.fld_yearofpassing_11.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Max Marks*"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_maxmarks_11", {
                    required: "This field is required",
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: "Enter valid number",
                    },
                  })}
                  error={!!errors?.fld_maxmarks_11}
                  helperText={
                    errors?.fld_maxmarks_11
                      ? errors.fld_maxmarks_11.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    if (e.target.value > 0) {
                      setValue("fld_maxmarks_11", e.target.value);
                    } else {
                      Notiflix.Notify.failure("Marks must be greater than 0.");
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Marks Obtained*"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_marksobtained_11", {
                    required: "This field is required",
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: "Enter valid number",
                    },
                  })}
                  error={!!errors?.fld_marksobtained_11}
                  helperText={
                    errors?.fld_marksobtained_11
                      ? errors.fld_marksobtained_11.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    if (
                      Number(e.target.value) <= Number(watch("fld_maxmarks_11"))
                    ) {
                      let per =
                        (e.target.value / watch("fld_maxmarks_11")) * 100;
                      setValue(
                        "fld_permarks_11",
                        per % 1 ? per.toFixed(2) : per
                      );
                    } else {
                      Notiflix.Notify.failure(
                        "Marks Obtained must be less than Max. Marks"
                      );
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Percent(%) of Marks"
                  disabled
                  variant="filled"
                  style={{ width: "100%" }}
                  {...register("fld_permarks_11", {
                    required: false,
                  })}
                  error={!!errors?.fld_permarks_11}
                  helperText={
                    errors?.fld_permarks_11
                      ? errors.fld_permarks_11.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    watch("fld_permarks_11") ? watch("fld_permarks_11") : ""
                  }
                />
              </div>
              <div
                className={
                  marksheetpdf11 == ""
                    ? "col-lg-6 col-12 mb-lg-3 mobile-personal"
                    : "col-lg-5 mb-lg-3 mobile-personal "
                }
              >
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Upload Marksheet (Maximum Size 6MB | File Format .pdf .jpeg)*"
                  variant="outlined"
                  className="text-field"
                  type="File"
                  {...register("marksheet_11", {
                    required: marksheetpdf11 ? false : "This field is required",
                  })}
                  onChange={(e) => {
                    if (e.target.files[0].size < 6000000) {
                      const reader = new FileReader();
                      const file = e.target.files[0];
                      reader.onloadend = () => {
                        setXImarksheet(file);
                      };
                      reader.readAsDataURL(file);
                    } else {
                      Notiflix.Notify.failure(
                        "File too large, upload file less than 6 MB."
                      );
                    }
                  }}
                  error={!!errors?.marksheet_11}
                  helperText={
                    errors?.marksheet_11 ? errors.marksheet_11.message : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ accept: "application/pdf, image/jpeg" }}
                />
              </div>
              <div
                className="col-lg-1 mb-lg-3 mobile-personal mt-1"
                style={{
                  display: marksheetpdf11 == "" ? "none" : "block",
                  textAlign: "center",
                }}
              >
                <a
                  target="_blank"
                  href={marksheetpdf11}
                  className="but-login-vahani"
                >
                  View
                </a>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal ">
                {/* <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="What is your current subject discipline?*"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_cuurent_sub_discipline_11", {
                    required: "This field is required",
                  })}
                  error={!!errors?.fld_cuurent_sub_discipline_11}
                  helperText={
                    errors?.fld_cuurent_sub_discipline_11
                      ? errors.fld_cuurent_sub_discipline_11.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}
                <FormControl fullWidth error={Boolean(errors.fld_cuurent_sub_discipline_11)}>
                  <InputLabel id="demo-simple-select-label">
                    What is your current subject discipline?*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select1"
                    label="What is your current subject discipline?*"
                    {...register("fld_cuurent_sub_discipline_11", {
                      required: "This field is required ",
                    })}
                    error={!!errors?.fld_cuurent_sub_discipline_11}
                    value={values.fld_cuurent_sub_discipline_11}
                    onChange={onChangeHandler}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {disciples.map((disc) => {
                      return (
                        <MenuItem value={disc}>
                          {disc}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_cuurent_sub_discipline_11
                      ? errors.fld_cuurent_sub_discipline_11.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Principal's Email id*"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_principal_email_id_11", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[1].fld_reg_ex, "i"),
                      message: "Enter a valid email id",
                    },
                  })}
                  error={!!errors?.fld_principal_email_id_11}
                  helperText={
                    errors?.fld_principal_email_id_11
                      ? errors.fld_principal_email_id_11.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Principal's Contact Number*"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_principal_contact_11", {
                    required: "This field is required",
                    maxLength: {
                      value: 10,
                      message: "Enter maximum 10-digit number",
                    },
                    minLength: {
                      value: 10,
                      message: "Enter minimum 10-digit number",
                    },
                    pattern: {
                      value: new RegExp(ValidationList[2].fld_reg_ex, "i"),
                      message: "Enter a valid contact number",
                    },
                  })}
                  error={!!errors?.fld_principal_contact_11}
                  helperText={
                    errors?.fld_principal_contact_11
                      ? errors.fld_principal_contact_11.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </div>
          {/* xii */}
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p className="personal-info left-line-card px-lg-4 px-3 py-4">
              Class XII (To be updated after results are declared)
            </p>
            <div className="row m-0 p-lg-4 p-2">
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <FormControl fullWidth error={Boolean(errors.fld_board_11)}>
                  <InputLabel id="label-id-board">
                    Board / Examination / Stream*
                  </InputLabel>
                  <Select
                    labelId="label-board"
                    id="id-boadrd"
                    label=" Board / Examination / Stream*"
                    {...register("fld_board_12", {
                      required: "This field is required",
                    })}
                    error={!!errors?.fld_board_12}
                    value={values.fld_board_12}
                    onChange={onChangeHandler}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {databoard.map((record) => (
                      <MenuItem value={record.label}>{record.label}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_board_12 ? errors.fld_board_12.message : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <FormControl
                  fullWidth
                  error={Boolean(errors.fld_yearofpassing_12)}
                >
                  <InputLabel id="demo-simple-select-label">
                    Year of Passing
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    label="Year of Passing"
                    {...register("fld_yearofpassing_12", { required: false })}
                    error={!!errors?.fld_yearofpassing_12}
                    value={values.fld_yearofpassing_12}
                    onChange={onChangeHandler}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {year.map((record) => (
                      <MenuItem value={record.value}>{record.value}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_yearofpassing_12
                      ? errors.fld_yearofpassing_12.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Max Marks"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_maxmarks_12", {
                    required: false,
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: "Enter valid  number",
                    },
                  })}
                  error={!!errors?.fld_maxmarks_12}
                  helperText={
                    errors?.fld_maxmarks_12
                      ? errors.fld_maxmarks_12.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    if (e.target.value > 0) {
                      setValue("fld_maxmarks_12", e.target.value);
                    } else {
                      Notiflix.Notify.failure("Marks must be greater than 0.");
                      setValue("fld_permarks_12", "");
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Marks Obtained"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_marksobtained_12", {
                    required: false,
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: "Enter valid  number",
                    },
                  })}
                  error={!!errors?.fld_marksobtained_12}
                  helperText={
                    errors?.fld_marksobtained_12
                      ? errors.fld_marksobtained_12.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    if (
                      Number(e.target.value) <= Number(watch("fld_maxmarks_12"))
                    ) {
                      let per =
                        (e.target.value / watch("fld_maxmarks_12")) * 100;
                      setValue(
                        "fld_permarks_12",
                        per % 1 ? per.toFixed(2) : per
                      );
                    } else {
                      Notiflix.Notify.failure(
                        "Marks Obtained must be less than Max. Marks"
                      );
                      setValue("fld_permarks_12", "");
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Percent(%) of Marks"
                  // variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_permarks_12", {
                    required: false,
                  })}
                  error={errors?.fld_permarks_12}
                  helperText={
                    errors?.fld_permarks_12
                      ? errors.fld_permarks_12.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    watch("fld_permarks_12") ? watch("fld_permarks_12") : ""
                  }
                  disabled
                  variant="filled"
                />
              </div>
              <div
                className={
                  marksheetpdf12 == ""
                    ? "col-lg-6 col-12 mb-lg-3 mobile-personal "
                    : "col-lg-5 mb-lg-3 mobile-personal"
                }
              >
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Upload Marksheet (Maximum Size 6MB | File Format .pdf .jpeg)"
                  variant="outlined"
                  className="text-field"
                  type="File"
                  {...register("marksheet_12", {
                    required: false,
                  })}
                  onChange={(e) => {
                    if (e.target.files[0].size < 6000000) {
                      const reader = new FileReader();
                      const file = e.target.files[0];
                      reader.onloadend = () => {
                        setXIImarksheet(file);
                      };
                      reader.readAsDataURL(file);
                    } else {
                      Notiflix.Notify.failure(
                        "File too large, upload file less than 6 MB."
                      );
                    }
                  }}
                  error={!!errors?.marksheet_12}
                  helperText={
                    errors?.marksheet_12 ? errors.marksheet_12.message : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ accept: "application/pdf, image/jpeg" }}
                />
              </div>
              <div
                className="col-lg-1 mb-lg-3 mobile-personal mt-1"
                style={{
                  display: marksheetpdf12 == "" ? "none" : "block",
                  textAlign: "center",
                }}
              >
                <a
                  target="_blank"
                  href={marksheetpdf12}
                  className="but-login-vahani"
                >
                  View
                </a>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                {/* <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="What is your current subject discipline?*"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_cuurent_sub_discipline_12", {
                    required: "This field is required",
                  })}
                  error={!!errors?.fld_cuurent_sub_discipline_12}
                  helperText={
                    errors?.fld_cuurent_sub_discipline_12
                      ? errors.fld_cuurent_sub_discipline_12.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}
                <FormControl fullWidth error={Boolean(errors.fld_cuurent_sub_discipline_12)}>
                  <InputLabel id="demo-simple-select-label">
                    What is your current subject discipline?*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select1"
                    label="What is your current subject discipline?*"
                    {...register("fld_cuurent_sub_discipline_12", {
                      required: "This field is required ",
                    })}
                    error={!!errors?.fld_cuurent_sub_discipline_12}
                    value={values.fld_cuurent_sub_discipline_12}
                    onChange={onChangeHandler}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {disciples.map((disc) => {
                      return (
                        <MenuItem value={disc}>
                          {disc}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_cuurent_sub_discipline_12
                      ? errors.fld_cuurent_sub_discipline_12.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  id="standard-multiline-flexible"
                  label="Principal's Email id*"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_principal_email_id_12", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[1].fld_reg_ex, "i"),
                      message: "Enter a valid email id",
                    },
                  })}
                  error={!!errors?.fld_principal_email_id_12}
                  helperText={
                    errors?.fld_principal_email_id_12
                      ? errors.fld_principal_email_id_12.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Principal's Contact Number*"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_principal_contact_12", {
                    required: "This field is required",
                    maxLength: {
                      value: 10,
                      message: "Enter maximum 10-digit number",
                    },
                    minLength: {
                      value: 10,
                      message: "Enter minimum 10-digit number",
                    },
                    pattern: {
                      value: new RegExp(ValidationList[2].fld_reg_ex, "i"),
                      message: "Enter valid contact number",
                    },
                  })}
                  error={!!errors?.fld_principal_contact_12}
                  helperText={
                    errors?.fld_principal_contact_12
                      ? errors.fld_principal_contact_12.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </div>
          {action != "view" && (
            <div className="card-vahani" style={{ marginTop: "26px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  // padding: "24px 0px",
                }}
              >
                <div className="but-width-form">
                  <Button
                    variant="contained"
                    className="but-login-vahani"
                    type="submit"
                  >
                    Save And Update
                  </Button>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
