import React from "react";
import { Route, Switch } from "react-router-dom";
import ResetPassword from "../Components/Form/Password/Password";
import TicketList from "../Components/Form/TicketManagement/TicketList";
import HrmsSidebar from "../Components/Sidebar/Hrms";
import { default as ApprovalList, default as ApprovalListSearch } from "../pages/AdminManagement/Approval/ApprovalList";
import PaymentsExtract from "../pages/AdminManagement/Approval/PaymentsExtract";
import Dashboard from "../pages/AdminManagement/Dashboard/Dashboard";
import ExpenseMaster from "../pages/AdminManagement/FormMaster/ExpenseMaster";
import PreviewExpenseMaster from "../pages/AdminManagement/FormMaster/PreviewExpenseForm";
import ValidationMaster from "../pages/AdminManagement/FormMaster/ValidationMaster";
import AddNotification from "../pages/AdminManagement/Notification/AddNotification";
import NotificationList from "../pages/AdminManagement/Notification/NotificationList";
import ReportList from "../pages/AdminManagement/Reports/ReportList";
import { default as ScholarList, default as ScholarListSearch } from "../pages/AdminManagement/Scholars/ScholarList";
import ViewStudentDetail from "../pages/AdminManagement/Students/ViewStudentDetail";
import ViewStudentList from "../pages/AdminManagement/Students/ViewStudentList";
import PageNotFound from "../pages/PageNotFound";
import AddAccomodation from "../pages/ScholarManagement/Accomodation/AddAccomodation";
import ExpenseForm from "../pages/ScholarManagement/Expense/ExpenseForm";

import AskQuestion from "../pages/DiscussionForum/AskQuestion";
import DiscussionForum from "../pages/DiscussionForum/DiscussionForum";

const AdminRouter = () => {
  // var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  return (
    <Switch>
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/approval-list" component={ApprovalList} />
      <Route exact path="/approval-list-search" component={ApprovalListSearch} />
      <Route exact path="/payments" component={PaymentsExtract} />
      <Route exact path="/expense" component={ExpenseForm} />
      <Route exact path="/validation-master" component={ValidationMaster} />
      <Route exact path="/expense-master" component={ExpenseMaster} />
      <Route
        exact
        path="/expense-master-preview/:id"
        component={PreviewExpenseMaster}
      />
      <Route exact path="/ticket-list" component={TicketList} />
      {/* <Route path="/view-ticket" component={ViewTicket} exact /> */}
      <Route exact path="/notification-list" component={NotificationList} />
      <Route exact path="/add-notification" component={AddNotification} />
      <Route exact path="/student-list" component={ViewStudentList} />
      <Route exact path="/student-list-search" component={ViewStudentList} />
      <Route exact path="/scholar-list" component={ScholarList} />
      <Route exact path="/scholar-list-search" component={ScholarListSearch} />
      <Route exact path="/view-student" component={ViewStudentDetail} />
      <Route exact path="/report-list" component={ReportList} />
      <Route exact path="/change-password" component={ResetPassword} />
      <Route exact path="/add-accomodation" component={AddAccomodation} />
      <Route exact path="/hrms" component={HrmsSidebar} />

      <Route exact path="/discussion-forum" component={DiscussionForum} />
      <Route exact path="/add-question" component={AskQuestion} />

      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};
export default AdminRouter;
