import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Eye } from "react-feather";
import Notiflix from "notiflix";
import PostApiCall from "../../../../assets/API/PostApi";
import GetApiCall from "../../../../assets/API/GetApi";
import moment from "moment";
import { MDBDataTable } from "mdbreact";
import Calendar from "./Calender";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Button,
  Select,
  Menu,
} from "@mui/material";

const Attendance = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [EmployeeId, setEmployeeId] = useState("");
  const [list, setList] = useState([]);
  const [ShowViewModal, setShowViewModal] = useState(null);
  const [NoofPresent, setNoofPresent] = useState([]);
  const [NoofAbsent, setNoofAbsent] = useState([]);
  const [searchInput, setsearchInput] = React.useState("");
  const [SortData, setSortData] = useState({
    type: "desc",
    value: "fld_id",
  });
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("Get_Employee_List").then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          var allobj = [];
          obj.data.map((emp, i) => {
            if (
              emp.fld_employee_full_name != "" &&
              emp.fld_employee_full_name != null
            ) {
              Notiflix.Loading.dots();
              // console.log(emp.fld_absent, emp.fld_present)
              PostApiCall.postRequest(
                {
                  whereClause:
                    "where [fld_employee_id]='" + emp.fld_employee_id + "'",
                },
                "GetPresentAbsent"
              ).then((resultattendance) =>
                resultattendance.json().then((obj) => {
                  if (
                    resultattendance.status == 200 ||
                    resultattendance.status == 201
                  ) {
                    emp.fld_present = obj.datapresent.length;
                    emp.fld_absent = obj.dataabsent.length;
                  } else {
                    Notiflix.Notify.error("Error Occurred");
                  }
                })
              );
              allobj.push(emp);
            }
          });

          setList(allobj);
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);

  function getSortAttendanceList() {
    if (SortData.value == "Employee Id") {
      if (SortData.type == "asc") {
        var newList = list.slice();
        newList.sort(function (a, b) {
          return (
            a.fld_employee_id.split("-")[0] - b.fld_employee_id.split("-")[0]
          );
        });
        setList(newList);
      } else {
        var newList = list.slice();
        newList.sort(function (a, b) {
          return (
            a.fld_employee_id.split("-")[0] - b.fld_employee_id.split("-")[0]
          );
        });
        newList.reverse();
        setList(newList);
      }
    } else if (SortData.value == "No. of Present") {
      if (SortData.type == "asc") {
        var newList = list.slice();
        newList.sort(function (a, b) {
          return a.fld_present - b.fld_present;
        });
        setList(newList);
      } else {
        var newList = list.slice();
        newList.sort(function (a, b) {
          return a.fld_present - b.fld_present;
        });
        newList.reverse();
        setList(newList);
      }
    } else if (SortData.value == "No. of Absent") {
      if (SortData.type == "asc") {
        var newList = list.slice();
        newList.sort(function (a, b) {
          return a.fld_absent - b.fld_absent;
        });
        setList(newList);
      } else {
        var newList = list.slice();
        newList.sort(function (a, b) {
          return a.fld_absent - b.fld_absent;
        });
        newList.reverse();
        setList(newList);
      }
    }
  }

  function seachBarHandler(e) {
    setsearchInput(...this.state, e.target.value);
  }

  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Employee's Attendance Records </h4>
        </div>
      </div>
      <div className="card-vahani p-0 mt-2">
        <div className="row m-0 justify-content-between align-items-center left-line-card px-4 pt-3  ">
          <div className="personal-info m-0 p-0 col-auto mb-3 ">
            Attendance List{" "}
          </div>
          <div className="col-md-6 text-end col-12 mb-3 ms-auto">
            <div className="row justify-content-end">
              <div className="col-lg-6 col-lg-4 mb-lg-0 my-2">
                {login.fld_admin == "Yes" && (
                  <Button variant="contained" className="w-100">
                    <Link to="/attendance" className="text-white">
                      Show Your Attendance
                    </Link>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-auto col-12 row m-0 mt-3">
          <div className="col-sm-auto mb-sm-0 mb-2 col-12 p-0">
            <select
              class="form-select form-select-sm "
              aria-label=".form-select-sm example"
              onChange={(e) => {
                setSortData({ ...SortData, value: e.target.value });
              }}
            >
              <option value="Employee Id">Sort By</option>
              <option value="Employee Id">Employee ID</option>
              <option value="No. of Present">No. of Present</option>
              <option value="No. of Absent">No. of Absent</option>
            </select>
          </div>
          <div className="col-sm-auto col-12 p-0 mx-sm-3 mx-0 mb-sm-0 mb-3 ">
            <select
              class="form-select form-select-sm "
              aria-label=".form-select-sm example"
              onChange={(e) =>
                setSortData({ ...SortData, type: e.target.value })
              }
            >
              <option value="desc">Order</option>
              <option value="asc">Ascending</option>
              <option value="desc">Descending </option>
            </select>
          </div>
          <Button
            className="col-sm-auto col-12 mb-sm-0 mb-3 "
            variant="contained"
            onClick={() => {
              getSortAttendanceList();
            }}
          >
            Sort
          </Button>
        </div>
        <div className="p-3">
          {list.length > 0 ? (
            <MDBDataTable
              disableRetreatAfterSorting={true}
              responsive
              hover
              //   striped
              data={{
                columns: [
                  {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 150,
                  },
                  {
                    label: "Employee Id",
                    field: "empId",
                    sort: "disabled",
                    width: 400,
                  },
                  {
                    label: "Employee Name",
                    field: "empName",
                    sort: "disabled",
                    width: 150,
                  },
                  {
                    label: "Employee Designation",
                    field: "empDesignation",
                    sort: "disabled",
                    width: 150,
                  },
                  {
                    label: "No. Days of Present",
                    field: "empnoofpresent",
                    sort: "disabled",
                    width: 100,
                  },
                  {
                    label: "No. Days of Absent",
                    field: "empnoofabsent",
                    sort: "disabled",
                    width: 100,
                  },
                  {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 100,
                  },
                ],
                rows: list.map((data, i) => {
                  return {
                    serial: i + 1,

                    serial: i + 1,
                    empId: data.fld_employee_id,
                    empName: data.fld_employee_full_name,
                    empDesignation: data.fld_designation,
                    empnoofpresent: data.fld_present,
                    empnoofabsent: data.fld_absent,

                    action: (
                      <Eye
                        className="text-primary "
                        onClick={() => {
                          history.push({
                            pathname: "/calender",
                            state: { data: data.fld_employee_id },
                          });
                        }}
                      />
                    ),
                  };
                }),
              }}
              entriesOptions={[10, 25, 50, 100]}
            />
          ) : (
            <p className="m-3">No Data Found</p>
          )}
        </div>
      </div>
    </>
  );
};
export default Attendance;
