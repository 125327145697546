import React, { Component, useState, useEffect } from "react";
import Notiflix from "notiflix";
import PostApiCall from "../../../assets/API/PostApi";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import capitalize from "capitalize";
// import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { CardBody } from "reactstrap";
import {
  MenuItem,
  TextField,
  Checkbox,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput,
  Button,
  Select,
  InputLabel,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Description } from "@mui/icons-material";

export default function AddProject() {
  let history = useHistory();
  const location = useLocation();
  const { projectData } = location.state || {}; //sate from project list

  // check state data avvailability
  const [projectname, setProjectName] = useState(
    projectData ? projectData.fld_name : ""
  );
  const [projectId, setProjectId] = useState(
    projectData ? projectData.fld_id : null
  );
  const [city, setCity] = useState(projectData ? projectData.fld_city : "");
  const [startdate, setStartDate] = useState(
    projectData ? moment(projectData.fld_start_date).format("YYYY-MM-DD") : ""
  );
  const [enddate, setEndDate] = useState(
    projectData ? moment(projectData.fld_end_date).format("YYYY-MM-DD") : ""
  );
  const [description, setDescription] = useState(
    projectData ? projectData.fld_desc : ""
  );
  const [reason, setReason] = useState(
    projectData ? projectData.fld_reason : ""
  );

  const [status, setStatus] = useState(
    projectData ? projectData.fld_status : "On Hold"
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const login = JSON.parse(localStorage.getItem("LoginDetail"))[0]


  // function to add a project
  const onAddProject = (requiredField) => {
    let allvalidatedfield = [];
    let allFieldValidationCheck = false;

    for (var i = 0; i < requiredField.length; i++) {
      if (requiredField[i] !== "" || requiredField !== 0) {
        allvalidatedfield.push(requiredField[i]);
        allFieldValidationCheck = true;
      } else {
        Notiflix.Notify.failure("Please Enter the all Mandatory Fields!");
        allFieldValidationCheck = false;
        break;
      }
    }
    if (allFieldValidationCheck === true) {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          id: projectId,
          name: projectname,
          startdate: startdate,
          enddate: enddate,
          city: city,
          reason: reason,
          desc: description,
          status: projectId == null ? "New" : status,
          createdby: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
        },
        "UpdateProjectsMaster"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            history.push("/project-list"); // route back to project list
            {
              projectData
                ? Notiflix.Notify.success("Data updated successfully")
                : Notiflix.Notify.success("Data saved successfully");
            }
          } else {
            Notiflix.Loading.remove();
            Notiflix.Report.error("Failed");
          }
        })
      );
    }
  };
  // else {
  //       Notiflix.Notify.failure("please enter start date");
  //     }
  //   } else {
  //     Notiflix.Notify.failure("please enter end date");
  //   }
  // };
  // else {
  //       Notiflix.Notify.failure("please enter description");
  //     }
  //   }
  //   else {
  //     Notiflix.Notify.failure("please enter reason");
  //   }
  // };

  // capitalize city and project name
  const capitalizeNameAndCity = (input) => {
    return typeof input === "string"
      ? input
        .split(" ")
        .map((word) => capitalize(word))
        .join(" ")
      : input;
  };

  return (
    <>
      {projectData ? (
        <div>
          <div className="form-head ">
            <div
              style={{
                borderLeft: "10px solid rgb(32, 101, 209)",
                paddingLeft: "10px",
              }}
            >
              <h4>Update Project</h4>
            </div>
          </div>
          <div className="card-vahani p-0 pb-4 ">
            <p className="personal-info left-line-card px-lg-4 px-3 py-4">
              Project Details
            </p>

            <div class="toast-body">
              <div class="row">
                <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row ps-2 pe-3">
                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="text"
                            // required={true}
                            disabled={login.fld_type === "Accountant"}
                            label="Project Name"
                            name={projectname}
                            value={capitalizeNameAndCity(projectname)}
                            onChange={(e) => {
                              setProjectName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="text"
                            // required={true}
                            disabled={login.fld_type == "Accountant"}
                            label="City"
                            value={capitalizeNameAndCity(city)}
                            name={city}
                            onChange={(e) => {
                              setCity(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="date"
                            // required={true}
                            disabled={login.fld_type == "Accountant"}
                            label="Start Date"
                            name={startdate}
                            value={startdate}
                            onChange={(e) => {
                              setStartDate(e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="date"
                            // required={true}
                            disabled={login.fld_type == "Accountant"}
                            label="End Date"
                            name={enddate}
                            value={enddate}
                            onChange={(e) => {
                              setEndDate(e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>

                        <div className="col-12 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Description"
                            // required={true}
                            disabled={login.fld_type == "Accountant"}
                            multiline
                            rows={7}
                            className="text-field capitalonly"
                            name={description}
                            value={description}
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />
                        </div>

                        <div className="col-12 mobile-personal">
                          <FormControl className="ps-2">
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Status
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={status}
                            >
                              <FormControlLabel
                                value="On Hold"
                                control={<Radio />}
                                label="On Hold"
                                onChange={(e) => {
                                  setStatus(e.target.value);
                                }}
                                disabled={login.fld_type == "Accountant"}
                              />
                              <FormControlLabel
                                value="On Hold"
                                control={<Radio />}
                                label="On Hold"
                                onChange={(e) => {
                                  setStatus(e.target.value);
                                }}
                                disabled={login.fld_type == "Accountant"}

                              />
                              <FormControlLabel
                                value="Cancelled"
                                control={<Radio />}
                                label="Cancelled"
                                onChange={(e) => {
                                  setStatus(e.target.value);
                                }}
                                disabled={login.fld_type == "Accountant"}

                              />
                            </RadioGroup>
                          </FormControl>
                        </div>

                        <div className="col-lg-12 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Reason for Hold / Cancellation"
                            // required={true}
                            disabled={login.fld_type == "Accountant"}
                            multiline
                            rows={7}
                            className="text-field capitalonly"
                            name={reason}
                            value={reason}
                            onChange={(e) => {
                              setReason(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-toggle="toast"
            >
              <div
                className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-3"
                style={{ float: "right" }}
              >
                {login.fld_type != "Accountant" &&
                  <div
                    className="justify-content-end"
                    style={{ display: "flex" }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        // validation function
                        let allrequiredfeild = [
                          projectname,
                          city,
                          startdate,
                          enddate,
                          description,
                          reason,
                        ];
                        allrequiredfeild.push();
                        onAddProject(allrequiredfeild);
                      }}
                    >
                      Update
                    </Button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="form-head ">
            <div
              style={{
                borderLeft: "10px solid rgb(32, 101, 209)",
                paddingLeft: "10px",
              }}
            >
              <h4>Add Project</h4>
            </div>
          </div>
          <div className="card-vahani p-0 pb-4 ">
            <p className="personal-info left-line-card px-lg-4 px-3 py-4">
              Project Details
            </p>

            <div class="toast-body">
              <div class="row">
                <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row ps-2 pe-3">
                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="text"
                            // required={true}
                            label="Project Name"
                            name={projectname}
                            value={capitalizeNameAndCity(projectname)}
                            onChange={(e) => {
                              setProjectName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="text"
                            // required={true}
                            label="City"
                            value={capitalizeNameAndCity(city)}
                            name={city}
                            onChange={(e) => {
                              setCity(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="date"
                            // required={true}
                            label="Start Date"
                            name={startdate}
                            value={startdate}
                            onChange={(e) => {
                              setStartDate(e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="date"
                            // required={true}
                            label="End Date"
                            name={enddate}
                            value={enddate}
                            onChange={(e) => {
                              setEndDate(e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>

                        <div className="col-12 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Description"
                            // required={true}
                            multiline
                            rows={7}
                            className="text-field capitalonly"
                            name={description}
                            value={description}
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />
                        </div>

                        {/* <div className="col-12 mobile-personal">
                        <FormControl className="ps-2">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            Status
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            defaultValue="New"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="New"
                              control={<Radio />}
                              label="On Hold"
                            />
                            <FormControlLabel
                              value="In Progress"
                              control={<Radio />}
                              label="Cancelled"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div> */}

                        {/* 
                      <div className="col-lg-12 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          type="text"
                          label="Reason for Hold / Cancellation"
                          // required={true}
                          multiline
                          rows={7}
                          className="text-field capitalonly"
                          name={reason}
                          value={reason}
                          onChange={(e) => {
                            setReason(e.target.value);
                          }}
                        />
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-toggle="toast"
            >
              <div
                className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-3"
                style={{ float: "right" }}
              >
                <div
                  className="justify-content-end"
                  style={{ display: "flex" }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      // validation function
                      let allrequiredfeild = [
                        projectname,
                        city,
                        startdate,
                        enddate,
                        description,
                        reason,
                      ];
                      allrequiredfeild.push();
                      onAddProject(allrequiredfeild);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
