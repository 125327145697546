import {
  Breadcrumbs, Button
} from "@mui/material";
import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import PostApiCall from "../../../assets/API/PostApi";
import store from "../../../store";
// import "../Adminpanel/Slider/Slider.css";
// import store from "../../../store";
// import Table from "../../../Components/Adminpanel/Mainsection/maincontent/Table/Tablesimple";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import GetApiCall from "../../../assets/API/GetApi";
import ListRegistrationIcon from "../../../assets/icons/list_registration.svg";
import NewRegistrationIcon from "../../../assets/icons/new_register.svg";
import TotalScholarsIcon from "../../../assets/icons/totalscholars.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function VendorDashboard() {
  const history = useHistory();
  let location = useLocation();
  const login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const { setexpenseData, NotificationData, setVendorAccess } = useContext(store);
  const [allCounts, setAllCounts] = useState({});
  const [courseRatio, setCourseRatio] = useState({});
  const [stateRatio, setStateRatio] = useState({});
  const [genderRatio, setGenderRatio] = useState({});
  const [registrationCount, setRegistrationCount] = useState({});
  const [expenstBarGraphData, setexpenstBarGraphData] = useState(null);
  const [NewExpenseList, setNewExpenseList] = useState([]);
  const [expenseFields, setexpenseFields] = useState([]);
  const [expenseDetail, setexpenseDetail] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const [filterExpenseList, setfilterExpenseList] = useState([]);
  const [SelectfilterExpenseList, setSelectfilterExpenseList] = useState("1");
  const [latestQuestions, setLatestQuestions] = useState([]);
  const [orderInvoiceDetails, setOrderInvoiceDetails] = useState([]);
  const [tab, setTab] = useState(0);
  const [SelectYearMonth, setSelectYearMonth] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  const [addProjectData, setAddProjectData] = useState([]); // holds the project data
  const [showHideModal, setshowHideModal] = useState(false);
  const [holdReason, setholdReason] = useState("");
  const [orderPayments, setOrderPayments] = useState([]);
  const [showRaisePayment, setShowRaisePayment] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [latestPurchaseOrderData, setLatestPurchaseOrderData] = useState([]);
  const [vendorDashboardCount, setVendorDashboardCount] = useState([]);

  // Filter Data
  const [RFQData, setRFQData] = useState([]);
  const [dateData, setdateData] = React.useState({ from: "", to: "" });
  const [ConditionType, setConditionType] = useState("New");
  const [purchasOrderId, setPurchaseOrderId] = useState(null);
  const [SortData, setSortData] = React.useState({
    type: "desc",
    value: "fld_id",
  });

  // **** Add Po Modal *****
  const [open, setOpen] = React.useState(false);
  const [invoiceListopen, setInvoiceListOpen] = React.useState(false);
  const [invoiceListData, setInvoiceListData] = React.useState([]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenInvoiceList = () => {
    setInvoiceListOpen(true);
  };
  const handleCloseInvoiceList = () => {
    setInvoiceListOpen(false);
  };
  let yearData = [];
  for (var i = new Date().getFullYear(); i >= 2016; i--) {
    yearData.push(i);
  }
  const getScholarExpenseDetails = (data) => {
    PostApiCall.postRequest(
      {
        whereClause: `where expense_master_id=${data.fld_id} and fld_studentId=${data.fld_studentId} order by fld_sequence asc`,
      },
      "Get_Scholar_Expense_Details"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          //
          setexpenseFields(
            obj.data.map((val) => ({
              fld_field_name: val.fld_field_name,
              fld_value: val.fld_answer,
            }))
          );
          Notiflix.Loading.remove();
        }
      })
    );
  };
  const getApprovalList = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        // whereClause: `where exists(select 1 from udv_expenseapprovalhistory eh where eh.fld_expense_id = ei.fld_id and eh.fld_pending_with='${login.fld_type}' and eh.fld_status = 'Open') order by fld_id desc`,
        whereClause: `where fld_approval_status='${login.fld_type == "Checker"
          ? "Pending"
          : login.fld_type == "Approver"
            ? "Verified"
            : login.fld_type == "Accountant"
              ? "Approved"
              : null
          }' order by fld_id desc `,
      },
      "Get_ScholarExpenseInformation"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          //
          setNewExpenseList(obj.data);
        }
        Notiflix.Loading.remove();
      })
    );
  };

  const getExpenseReportList = (data) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause:
          data == 2
            ? `where datepart(week,fld_requestedon) =datepart(week ,getdate())`
            : data == 3
              ? `where datepart(month,fld_requestedon) =datepart(month,getdate())`
              : data == 4
                ? `where datepart(year,fld_requestedon) =datepart(year ,getdate())`
                : `where fld_requestedon =concat(convert(varchar, getdate(), 107), ' ', Format(GetDate(), 'hh:mm tt'))`,
      },
      "Admin_Get_Expense_Report_List"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          //
          setfilterExpenseList(obj.data);
        }
        Notiflix.Loading.remove();
      })
    );
  };

  const getLatestQuestions = () => {
    GetApiCall.getRequest("GetQuestions").then((results) =>
      results.json().then((obj) => {
        var objdata = obj.data;

        setLatestQuestions(objdata.reverse());
        Notiflix.Loading.remove();
      })
    );
  };
  useEffect(() => {
    setVendorAccess("true");
    localStorage.setItem("vendoraccess", "true");
    getApprovalList();
    getExpenseReportList();
    getLatestQuestions();
    if (login.fld_type == "Approver") {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          invoiceid: null,
        },
        "Get_PurchaseOrderPaymentsHistory"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            var pendingInvoice;
            if (login.fld_id == 4) {
              pendingInvoice = obj.data.filter((item) => {
                return item.fld_status == "Pending";
              });
            } else {
              pendingInvoice = obj.data.filter((item) => {
                return item.fld_status == "Verified";
              });
            }

            if (obj.data[obj.data.length - 1].fld_amount_remaining > 0) {
              setShowRaisePayment(true);
              setRemainingAmount(
                obj.data[obj.data.length - 1].fld_amount_remaining
              );
            } else {
              setShowRaisePayment(false);
            }

            setOrderPayments(pendingInvoice);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Error Occurred!");
          }
        })
      );
    }

    // get all projects
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetProjectsMaster"
      // "GetProjectsTasks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setAddProjectData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Notify.failure("error occurred!");
          Notiflix.Loading.remove();
        }
      })
    );

    // get all POs
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetPurchaseOrders"
      // "GetProjectsTasks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setLatestPurchaseOrderData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      })
    );

    // All the card data for vendor 
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "Get_vendor_Dashboard_Count"
      // "GetProjectsTasks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setVendorDashboardCount(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);
  useEffect(() => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        year: SelectYearMonth.year,
        month: SelectYearMonth.month,
      },
      "Admin_Get_Expense_Report_Dashboard"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          //
          setexpenstBarGraphData(
            obj.data.length > 0
              ? {
                name: obj.data.map((val) => val.fld_expense_type),
                value: obj.data.map((val) => val.fee_amount),
              }
              : null
          );
        }
        Notiflix.Loading.remove();
      })
    );
  }, [SelectYearMonth]);
  useEffect(() => {
    GetApiCall.getRequest("GetDashboardCountByDate").then((resultdes) =>
      resultdes.json().then((objuser) => {
        setAllCounts({
          listOfRegistration: objuser.data[0].TotalStudents,
          publishedStudents: objuser.data[0].PublishedStudents,
          shortedStudents: objuser.data[0].ShortedStudents,
          totalScholars: objuser.data[0].TotalScholars,
          rejectedStudents: objuser.data[0].RejectedStudents,
          newAllRegistratuion: objuser.data[0].currentyearstudent,
        });
      })
    );
  }, []);
  // state ratio
  useEffect(() => {
    GetApiCall.getRequest("Report_Get_StateRatio").then((resultdes) =>
      resultdes.json().then((objuser) => {
        //
        let serie = [];
        let option = [];
        objuser.data.map((data) => {
          serie.push(data.StateCount);
          option.push(data.name);
        });
        let data = {
          stateOption: {
            chart: {
              type: "donut",
            },
            labels: option,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  fill: {
                    type: "gradient",
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
          series: serie,
        };
        //
        setStateRatio(data);
      })
    );
  }, []);
  // course ratio
  useEffect(() => {
    GetApiCall.getRequest("Report_Get_CourseRatio").then((resultdes) =>
      resultdes.json().then((objuser) => {
        let serie = [];
        let option = [];
        objuser.data.map((data) => {
          serie.push(data.CountOf);
          option.push(`${data.fld_courseName.slice(0, 20)}` + "...");
        });
        let data = {
          courseOption: {
            chart: {
              type: "donut",
            },
            labels: option,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  fill: {
                    type: "gradient",
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
          series: serie,
        };
        setCourseRatio(data);
      })
    );
  }, []);
  // gender ratio
  useEffect(() => {
    GetApiCall.getRequest("Report_Get_GenderRatioReport").then((resultdes) =>
      resultdes.json().then((objuser) => {
        let data = {
          genderOption: {
            chart: {
              type: "donut",
            },
            labels: ["Total Male", "Total Female"],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  fill: {
                    type: "gradient",
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
          series: [objuser.data[0].male, objuser.data[0].female],
        };
        setGenderRatio({
          ...data,
        });
      })
    );
  }, []);
  useEffect(() => {
    GetApiCall.getRequest("GetDashboardCountByDate").then((resultdes) =>
      resultdes.json().then((objuser) => {
        let registration = {
          registrationOption: {
            chart: {
              type: "donut",
            },
            labels: [
              "Total Registration",
              "Total Scholars",
              "Publish Registration",
              "Rejected Registration",
              "Shorted Registration",
            ],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  fill: {
                    type: "gradient",
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
          series: [
            objuser.data[0].TotalStudents,
            objuser.data[0].TotalScholars,
            objuser.data[0].PublishedStudents,
            objuser.data[0].RejectedStudents,
            objuser.data[0].ShortedStudents,
          ],
        };
        setRegistrationCount({
          ...registration,
        });
      })
    );
  }, []);
  //
  const HoldExpense = (status = null) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        expenseid: expenseDetail.fld_id,
        userid: login.fld_id,
        comments: status == null || holdReason == "" ? null : holdReason,
        status: status,
      },
      "Add_Admin_Expense_Hold"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Expense Hold Successfully.");
          window.location.reload();
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      })
    );
  };

  const data = {
    columns: [
      {
        label: "S.No.",
        field: "serial",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Po Number",
        field: "ponumber",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Invoice No",
        field: "invoiceno",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Amount Payable",
        field: "payable",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Requested Amount",
        field: "requestedamount",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Remaining Amount",
        field: "remainingamount",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Created On",
        field: "createdon",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
        sort: "asc",
      },
    ],
    rows: orderPayments.map((data, i) => {
      return {
        serial: i + 1,
        ponumber: data.fld_po_no,
        invoiceno: data.fld_invoice_no,
        payable: data.fld_amount_payable,
        requestedamount: data.fld_amount_paid,
        remainingamount: data.fld_amount_remaining,
        status: data.fld_status,
        createdon: moment(data.fld_created_on).format("DD/MM/YYYY"),
        action: (
          <div className="d-flex">
            <Link
              className="m-auto"
              to={{
                pathname: `/add-purchase-Order-invoice`,
                state: {
                  id: data.fld_id,
                  poid: data.fld_invoice_id,
                  frominvoce: "Yes",
                  remainingAmount: remainingAmount,
                },
              }}
            >
              <FaEdit className="edit-icon edit-icon-font-size" />
            </Link>
          </div>
        ),
      };
    }),
  };

  const projectData = {
    columns: [
      {
        label: "S.No.",
        field: "serial",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Project Name",
        field: "projectName",
        sort: "disabled",
        width: 400,
      },
      {
        label: "City",
        field: "city",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Start Date",
        field: "startDate",
        sort: "disabled",
        width: 150,
      },
      {
        label: "End Date",
        field: "endDate",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Created By",
        field: "createdby",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Updated By",
      //   field: "UpdatedBy",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Action",
        field: "action",
        width: 150,
        sort: "asc",
      },
    ],
    rows: addProjectData.slice(0, 5).map((data, i) => {
      return {
        serial: i + 1,
        projectName: data.fld_name,
        city: data.fld_city,
        startDate: moment(data.fld_start_date).format("DD-MM-YYYY"),
        endDate: moment(data.fld_end_date).format("DD-MM-YYYY"),
        description: data.fld_desc,
        reason: data.fld_reason,
        status: data.fld_status,
        createdby: data.created_by_name,
        //UpdatedBy: data.updated_by_name,
        action: (
          <div className="d-flex">
            {/* Edit a project */}
            <Link
              className="m-auto"
              to={{
                pathname: `/add-project/${data.fld_id}`,
                state: { projectData: data }, // sending data as a state
              }}
            >
              <FaEdit className="edit-icon edit-icon-font-size" />
            </Link>
            {data.fld_task_count > 0 ? (
              <Link
                to={{
                  pathname: `/task-list/${data.fld_id}`,
                  state: { projectData: data }, // sending data as a state
                }}
                className="task-btn-color ms-2"
              >
                View Tasks
              </Link>
            ) : (
              <Link
                to={{
                  pathname: `/task-list/${data.fld_id}`,
                  state: { projectData: data }, // sending data as a state
                }}
                className="task-btn-color ms-2"
              >
                Add Tasks
              </Link>
            )}
          </div>
        ),
      };
    }),
  };

  const purchaseOrderCols = [
    {
      label: "S.No.",
      field: "serial",
      sort: "disabled",
      width: 150,
    },
    {
      label: "PO Number",
      field: "ponumber",
      sort: "disabled",
      width: 400,
    },
    {
      label: "Vendor Name",
      field: "vendorname",
      sort: "disabled",
      width: 400,
    },
    {
      label: "Vendor Address",
      field: "vendoraddress",
      sort: "disabled",
      width: 400,
    },
    {
      label: "Vendor Contact Details",
      field: "vendorcontactdetails",
      sort: "disabled",
      width: 400,
    },
    {
      label: "Status",
      field: "status",
      sort: "disabled",
      width: 150,
    },
    {
      label: "Created By",
      field: "createdby",
      sort: "asc",
      width: 150,
    },
    {
      label: "Updated By",
      field: "UpdatedBy",
      sort: "asc",
      width: 150,
    },
    {
      label: "Invoice",
      field: "createpo",
      width: 150,
      sort: "asc",
    },
    {
      label: "Action",
      field: "action",
      width: 150,
      sort: "asc",
    },
  ]

  const purchaseOrderData = {
    columns: purchaseOrderCols.filter((col) => {
      // console.log("function running", login.fld_type)
      if (login.fld_type == "Accountant") {
        // console.log("Working for an accountant")
        return col.label !== "Invoice"
      } else {
        // console.log("hello I am not an accountant")
        return col
      }
    }),
    rows: latestPurchaseOrderData.slice(0, 5).map((data, i) => {
      return {
        serial: i + 1,
        ponumber: data.fld_po_no,
        vendorname: data.fld_name,
        vendoraddress: (
          <div
            style={{ fontWeight: "400" }}
            dangerouslySetInnerHTML={{ __html: data.fld_po_address }}
          ></div>
        ),
        vendorcontactdetails: (
          <div
            style={{ fontWeight: "400" }}
            dangerouslySetInnerHTML={{ __html: data.fld_po_contact_person }}
          ></div>
        ),
        status: data.fld_po_status,
        createdby: data.created_by_name,
        UpdatedBy: data.updated_by_name,
        createpo: (
          <>
            {login.fld_type !== "Accountant" &&
              (

                <div className="d-flex">
                  {data.fld_invoice_count > 0 ? (
                    <Button
                      className="task-btn-color ms-2"
                      onClick={() => {
                        Notiflix.Loading.dots();
                        setPurchaseOrderId(data.fld_id);
                        PostApiCall.postRequest(
                          {
                            whereClause: `where fld_po_id=${data.fld_id}`,
                          },
                          "Get_Vendor_Invoice_History"
                          // "GetProjectsTasks"
                        ).then((results) =>
                          results.json().then((obj) => {
                            if (results.status == 200 || results.status == 201) {
                              setInvoiceListData(obj.data);
                              setInvoiceListOpen(true);
                              Notiflix.Loading.remove();
                            } else {
                              Notiflix.Loading.remove();
                            }
                          })
                        );
                      }}
                    >
                      View Purchase Order
                    </Button>
                  ) : (
                    <Link
                      to={{
                        pathname: `/purchaseorderprint`,
                        state: {
                          id: data.fld_id,
                        },
                      }}
                      className="task-btn-color ms-2"
                    >
                      Generate Purchase Order
                    </Link>
                  )}
                </div>
              )
            }
          </>
        ),
        action: (
          <>
            <div className="d-flex">
              <Link
                to={{
                  pathname: `/add-purchase-Order`,
                  state: {
                    id: data.fld_id,
                  },
                }}
              >
                <Button variant="contained">
                  {login.fld_type == "Accountant" ?

                    "View" : "Edit"
                  }
                </Button>
              </Link>
            </div>
          </>
        ),
      };
    }),
  };

  return (
    <>
      <div className="form-head">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Hi {login.full_name}, Welcome back!</h4>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="text.primary"
              href="/vendor-dashboard"
              aria-current="page"
            >
              {" "}
              Dashboard{" "}
            </Link>
          </Breadcrumbs>
        </div>
      </div>
      {/* ----------------------- Cards for vendor ------------------------------------- */}
      <div className="row mt-5">
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-3">
          <div className="card-hover p-3">
            <div>
              <div className="d-flex justify-content-between align-content-center">
                <h6 className="personal-info p-0">Total Projects</h6>
                {/* <PeopleAltIcon /> */}
                <img src={NewRegistrationIcon} className="dash-card-icon" />
              </div>
              <h3 className="font-weight-bold" style={{ color: "#fdd999" }}>
                {vendorDashboardCount[0]?.fld_projects}
              </h3>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-3">
          <div className="card-hover p-3">
            <div className="d-flex justify-content-between align-content-center">
              <h6 className="personal-info p-0">Total Vendor</h6>
              {/* <SchoolIcon /> */}
              <img src={TotalScholarsIcon} className="dash-card-icon orange" />
            </div>
            <div>
              <h3 className="font-weight-bold" style={{ color: "#ED6C02" }}>
                {vendorDashboardCount[0]?.fld_vendor}
              </h3>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-3">
          <div className="card-hover p-3">
            <div>
              <div className="d-flex justify-content-between align-content-center">
                <h6 className="personal-info p-0">RFQ</h6>
                {/* <PeopleAltIcon /> */}
                <img
                  src={ListRegistrationIcon}
                  className="dash-card-icon gray"
                />
              </div>
              <h3 className="font-weight-bold" style={{ color: "red" }}>
                {vendorDashboardCount[0]?.fld_rfq}
              </h3>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-3">
          <div className="card-hover p-3">
            <div>
              <div className="d-flex justify-content-between align-content-center">
                <h6 className="personal-info p-0">Quotation</h6>
                {/* <PeopleAltIcon /> */}
                <img
                  src={ListRegistrationIcon}
                  className="dash-card-icon gray"
                />
              </div>
              <h3 className="font-weight-bold" style={{ color: "pink" }}>
                {vendorDashboardCount[0]?.fld_quotation}
              </h3>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-3">
          <div className="card-hover p-3">
            <div>
              <div className="d-flex justify-content-between align-content-center">
                <h6 className="personal-info p-0">Purchase Order</h6>
                {/* <PeopleAltIcon /> */}
                <img
                  src={ListRegistrationIcon}
                  className="dash-card-icon gray"
                />
              </div>
              <h3 className="font-weight-bold" style={{ color: "blue" }}>
                {vendorDashboardCount[0]?.fld_purchase_order}
              </h3>
            </div>
          </div>
        </div>
      </div>
      {login.fld_type == "Approver" && (
        <div className="card-vahani p-0 pb-4 mt-5">
          <div className="personal-info left-line-card row m-0 align-items-lg-center justify-content-between px-2 py-4 mb-2 w-100">
            <div className="col-4 col-md-6 mb-2 mb-md-0">
              Pending Payment Approval's
            </div>
            <Link
              to="/quotation-list"
              className="col-8 col-md-6 mb-sm-0 text-white text-end"
            >
              <Button variant="contained">View</Button>
            </Link>
          </div>
          <div className="p-3">
            {/* {addProjectData.length > 0 ? ( */}
            <>
              <MDBDataTable
                className="po-invoice"
                responsive
                hover
                disableRetreatAfterSorting={true}
                data={data}
                entriesOptions={[10, 25, 50, 100]}
              >
                <MDBTableHead columns={data.columns} />
                <MDBTableBody rows={data.rows} />
              </MDBDataTable>
            </>
            {/* ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )} */}
          </div>
        </div>
      )}

      <div className="card-vahani p-0 pb-4 mt-5">
        <div className="personal-info left-line-card row m-0 align-items-lg-center justify-content-between px-2 py-4 mb-2 w-100">
          <div className="col-4 col-md-6 mb-2 mb-md-0">Projects</div>
          <Link
            to="/project-list"
            className="col-8 col-md-6 mb-sm-0 text-white text-end"
          >
            <Button variant="contained">View</Button>
          </Link>
        </div>
        <div className="p-3">
          {addProjectData.length > 0 ? (
            <>
              <MDBDataTable
                responsive
                hover
                disableRetreatAfterSorting={true}
                data={projectData}
                entriesOptions={[10, 25, 50, 100]}
              >
                <MDBTableHead columns={projectData.columns} />
                <MDBTableBody rows={projectData.rows} />
              </MDBDataTable>
            </>
          ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )}
        </div>
      </div>
      {/* <div className="card-vahani p-0 pb-4 mt-5">
        <div className="personal-info left-line-card row m-0 align-items-lg-center justify-content-between px-2 py-4 mb-2 w-100">
          <div className="col-4 col-md-6 mb-2 mb-md-0">Task</div>
          <Link
            to="/task-list"
            className="col-8 col-md-6 mb-sm-0 text-white text-end"
          >
            <Button variant="contained">View</Button>
          </Link>
        </div>
        {/* <div className="px-4">
        {addProjectData.length > 0 ? (
            <>
              <MDBDataTable
                responsive
                hover
                disableRetreatAfterSorting={true}
                data={data}
                entriesOptions={[10, 25, 50, 100]}>
                <MDBTableHead columns={data.columns} />
                <MDBTableBody rows={data.rows} />
              </MDBDataTable>
            </>
          ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )} */}
      {/* </div>
      </div>
      <div className="card-vahani p-0 pb-4 mt-5">
        <div className="personal-info left-line-card row m-0 align-items-lg-center justify-content-between px-2 py-4 mb-2 w-100">
          <div className="col-4 col-md-6 mb-2 mb-md-0">RFQ</div>
          <Link
            to="/rfq-list"
            className="col-8 col-md-6 mb-sm-0 text-white text-end"
          >
            <Button variant="contained">View</Button>
          </Link>
        </div>
        {/* <div className="px-4">
        {addProjectData.length > 0 ? (
            <>
              <MDBDataTable
                responsive
                hover
                disableRetreatAfterSorting={true}
                data={data}
                entriesOptions={[10, 25, 50, 100]}>
                <MDBTableHead columns={data.columns} />
                <MDBTableBody rows={data.rows} />
              </MDBDataTable>
            </>
          ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )} */}
      {/* </div>
      </div>
      <div className="card-vahani p-0 pb-4 mt-5">
        <div className="personal-info left-line-card row m-0 align-items-lg-center justify-content-between px-2 py-4 mb-2 w-100">
          <div className="col-4 col-md-6 mb-2 mb-md-0">Quotation</div>
          <Link
            to="/quotation-list"
            className="col-8 col-md-6 mb-sm-0 text-white text-end"
          >
            <Button variant="contained">View</Button>
          </Link>
        </div>
         <div className="px-4">
        {addProjectData.length > 0 ? (
            <>
              <MDBDataTable
                responsive
                hover
                disableRetreatAfterSorting={true}
                data={data}
                entriesOptions={[10, 25, 50, 100]}>
                <MDBTableHead columns={data.columns} />
                <MDBTableBody rows={data.rows} />
              </MDBDataTable>
            </>
          ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )}
         </div>
      </div> */}
      <div className="card-vahani p-0 pb-4 mt-5">
        <div className="personal-info left-line-card row m-0 align-items-lg-center justify-content-between px-2 py-4 mb-2 w-100">
          <div className="col-4 col-md-6 mb-2 mb-md-0">Purchase Order</div>
          <Link
            to="/purchase-order-list"
            className="col-8 col-md-6 mb-sm-0 text-white text-end"
          >
            <Button variant="contained">View</Button>
          </Link>
        </div>
        <div className="p-4">
          <MDBDataTable
            responsive
            hover
            disableRetreatAfterSorting={true}
            data={purchaseOrderData}
            entriesOptions={[10, 25, 50, 100]}
          >
            <MDBTableHead columns={purchaseOrderData.columns} />
            <MDBTableBody rows={purchaseOrderData.rows} />
          </MDBDataTable>
        </div>
        {/* <div className="px-4">
        {addProjectData.length > 0 ? (
            <>
              <MDBDataTable
                responsive
                hover
                disableRetreatAfterSorting={true}
                data={data}
                entriesOptions={[10, 25, 50, 100]}>
                <MDBTableHead columns={data.columns} />
                <MDBTableBody rows={data.rows} />
              </MDBDataTable>
            </>
          ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )} */}
        {/* </div> */}
      </div>

      {/* <div className="row mt-4 mb-lg-3 mobile-personal">
        <div className="col-md-12 mb-lg-4">
          <div className="card-vahani p-0">
            <div className="row justify-content-between align-items-center m-0 left-line-card px-4 pb-1 pt-3">
              <p className="personal-info p-0 col-auto ">Expense Graph</p>
              <div className="col-auto">
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  className="col-auto"
                  size="small"
                >
                  <InputLabel>Month</InputLabel>
                  <Select
                    label="Month"
                    value={SelectYearMonth.month}
                    onChange={(e) => {
                      setSelectYearMonth({
                        ...SelectYearMonth,
                        month: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="1">Jan</MenuItem>
                    <MenuItem value="2">Feb</MenuItem>
                    <MenuItem value="3">Mar</MenuItem>
                    <MenuItem value="4">Apr</MenuItem>
                    <MenuItem value="5">May</MenuItem>
                    <MenuItem value="6">Jun</MenuItem>
                    <MenuItem value="7">Jul</MenuItem>
                    <MenuItem value="8">Aug</MenuItem>
                    <MenuItem value="9">Sep</MenuItem>
                    <MenuItem value="10">Oct</MenuItem>
                    <MenuItem value="11">Nov</MenuItem>
                    <MenuItem value="12">Dec</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  className="col-auto"
                  size="small"
                >
                  <InputLabel>Year</InputLabel>
                  <Select
                    value={new Date().getFullYear()}
                    label="Filter By"
                    onChange={(e) => {
                      setSelectYearMonth({
                        ...SelectYearMonth,
                        year: e.target.value,
                      });
                    }}
                  >
                    {yearData.map((val) => (
                      <MenuItem value={val}>{val}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div id="chart">
              {expenstBarGraphData != null ? (
                <ReactApexChart
                  options={{
                    chart: { type: "bar" },
                    plotOptions: {
                      bar: { columnWidth: "50%", distributed: true },
                    },
                    legend: { show: false },
                    dataLabels: { enabled: false },
                    xaxis: {
                      categories: expenstBarGraphData.name,
                      labels: { style: { fontSize: "12px" } },
                    },
                  }}
                  series={[{ data: expenstBarGraphData.value }]}
                  type="bar"
                  height={300}
                />
              ) : (
                <p className="p-3">No Data Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div className="col-xl-6 col-lg-6 mb-lg-3 mobile-personal">
          <div className="card-vahani p-0">
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              Total Expense
            </p>
            <div id="chart">
              {registrationCount.series && (
                <ReactApexChart
                  options={registrationCount.registrationOption}
                  series={registrationCount.series}
                  type="donut"
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 mb-lg-3 mobile-personal">
          <div className="card-vahani p-0">
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              {" "}
              Registration Status
            </p>
            <div id="chart">
              {registrationCount.series && (
                <ReactApexChart
                  options={registrationCount.registrationOption}
                  series={registrationCount.series}
                  type="donut"
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 mb-lg-3 mobile-personal">
          <div className="card-vahani p-0">
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              Course Ratio
            </p>
            <div id="chart">
              {courseRatio.series && (
                <ReactApexChart
                  options={courseRatio.courseOption}
                  series={courseRatio.series}
                  type="donut"
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 mb-lg-3 mobile-personal">
          <div className="card-vahani p-0">
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              {" "}
              State Ratio
            </p>
            <div id="chart">
              {stateRatio.series && (
                <ReactApexChart
                  options={stateRatio.stateOption}
                  series={stateRatio.series}
                  type="donut"
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 mb-lg-3 mobile-personal">
          <div className="card-vahani p-0">
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              Gender Ratio Status
            </p>
            <div id="chart">
              {genderRatio.series && (
                <ReactApexChart
                  options={genderRatio.genderOption}
                  series={genderRatio.series}
                  type="donut"
                />
              )}
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
