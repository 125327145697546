import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Link } from "react-router-dom";
import { Eye, Download } from "react-feather";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Parser from "html-react-parser";
import Notiflix from "notiflix";
import GetApiCall from "../../../../assets/API/GetApi";
import PostApiCall from "../../../../assets/API/PostApi";
import moment from "moment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Button,
  Select,
  Menu,
} from "@mui/material";
import logo from "../../../../assets/Image/vahanilogo2.png";
import { letterSpacing } from "@mui/system";

class SalarySlipRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,

      SalarySlipData: [],
      Id: "",
      searchInput: "",
      Details: [],
      list: [],
      ShowViewModal: null,
      DownloadSalarySlipData: [],
      SelectedYear: "",
      SelectedMonth: "",
      Months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  }

  componentDidMount() {
    Notiflix.Loading.init({
      svgColor: "rgb(6, 10, 74)",
    });
    Notiflix.Loading.dots("Please wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);

    this.setState({
      Details: details[0],
    });

    GetApiCall.getRequest("GetCurrMonthSalarySlip").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          list: obj.data,
          SelectedMonth: this.state.Months[new Date().getMonth() - 1],
          SelectedYear: new Date().getFullYear(),
        });
        Notiflix.Loading.remove();
      })
    );
  }

  DownloadSalarySlipPdf = () => {
    var html2pdf = require("html2pdf.js");
    var element = document.getElementById("salarySlipForm");
    var opt = {
      margin: 10,
      filename: "vahani-salaryslip.pdf",
      image: { type: "png", quality: 0.98 },
      html2canvas: {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        proxy: null,
        dpi: 192,
      },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "portrait",
        compress: true,
      },
    };
    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        Notiflix.Loading.remove();
      });
  };

  GetFilterSalarySlip = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        month: this.state.Months.indexOf(this.state.SelectedMonth) + 1,
        year: parseInt(this.state.SelectedYear),
      },
      "GetFilterSalarySlips"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({ list: obj.data });
          Notiflix.Loading.remove();
        }
        Notiflix.Loading.remove();
      })
    );
  };

  render() {
    let YearsData = [];
    for (let year = new Date().getFullYear(); year !== null; ) {
      for (let i = 0; i < year; i++, year = year > 2000 ? year - 1 : null) {
        YearsData.push(year);
      }
    }
    return (
      <>
        <div className="form-head mb-lg-2 mb-3">
          <div
            className="col-auto "
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>All Employee's Salary Slip Records</h4>
          </div>
        </div>
        <div className="card-vahani p-0 mt-2">
          <div className="row m-0 justify-content-between align-items-center left-line-card px-4 pt-3 pb-3">
            <div className="personal-info m-0 p-0 col-auto">
              Salary Slip Records{" "}
            </div>
            <div className="col-md-6 text-end col-12 mb-3 ms-auto">
              <div className="row justify-content-end">
                <div className="col-lg-8 col-lg-4 mb-lg-0 mb-2">
                  <Button variant="contained" className="w-100">
                    <Link
                      to="/salarysliprecordsemployee"
                      className="text-white"
                    >
                      Show Your Salary Slip Records
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 justify-content-start align-items-center border-top border-bottom border-secondary px-4 pt-3 pb-3 ps-0">
            <div className="col-md-4 mb-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Month"
                  value={this.state.SelectedMonth}
                  onChange={(e) => {
                    this.setState({ SelectedMonth: e.target.value });
                  }}
                >
                  <MenuItem value="All">Choose Month</MenuItem>
                  <MenuItem value="January">January</MenuItem>
                  <MenuItem value="February">February</MenuItem>
                  <MenuItem value="March">March</MenuItem>
                  <MenuItem value="April">April</MenuItem>
                  <MenuItem value="May">May</MenuItem>
                  <MenuItem value="June">June</MenuItem>
                  <MenuItem value="July">July</MenuItem>
                  <MenuItem value="August">August</MenuItem>
                  <MenuItem value="September">September</MenuItem>
                  <MenuItem value="October">October</MenuItem>
                  <MenuItem value="November">November</MenuItem>
                  <MenuItem value="December">December</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-4 mb-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Year"
                  value={this.state.SelectedYear}
                  onChange={(e) => {
                    this.setState({ SelectedYear: e.target.value });
                  }}
                >
                  <MenuItem value="All">Choose Year</MenuItem>
                  {YearsData.map((year, i) => {
                    return <MenuItem value={year}>{year}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-4 text-end col-6 mb-3 ms-auto">
              <div className="row justify-content-end">
                <div className="col-lg-6 col-lg-4 mb-lg-0 mb-2">
                  <Button
                    variant="contained"
                    className="w-100"
                    onClick={() => {
                      this.GetFilterSalarySlip();
                    }}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-end col-12 mb-3 ms-auto">
              <div className="row justify-content-end">
                <div className="col-lg-4 col-lg-4 mb-lg-0 mb-2">
                  <Button
                    variant="contained"
                    className="w-100"
                    onClick={() => {
                      Notiflix.Loading.dots();
                      GetApiCall.getRequest("GetCurrMonthSalarySlip").then(
                        (resultdes) =>
                          resultdes.json().then((obj) => {
                            this.setState({
                              list: obj.data,
                              SelectedMonth:
                                this.state.Months[new Date().getMonth() - 1],
                              SelectedYear: new Date().getFullYear(),
                            });
                            Notiflix.Loading.remove();
                          })
                      );
                    }}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3 salary-slip-table">
            {this.state.list.length > 0 ? (
              <MDBDataTable
                disableRetreatAfterSorting={true}
                responsive
                hover
                //   striped
                data={{
                  columns: [
                    {
                      label: "S.No.",
                      field: "serial",
                      sort: "disabled",
                    },
                    {
                      label: "Employee Id",
                      field: "employeeid",
                      sort: "disabled",
                    },
                    {
                      label: "Employee Name",
                      field: "empname",
                      sort: "disabled",
                    },

                    {
                      label: "Employment Type",
                      field: "empType",
                      sort: "disabled",
                    },
                    {
                      label: "Action",
                      field: "action",
                      sort: "disabled",
                      width: 80,
                    },
                  ],
                  rows: this.state.list.map((data, i) => {
                    return {
                      serial: i + 1,
                      employeeid: data.fld_employee_id,
                      empname: data.fld_employee_full_name,
                      empType: data.fld_emp_type,
                      action:
                        data.salary_slip_generated == "Yes" ? (
                          <p className="d-flex algin-content-center justify-content-start">
                            <Link
                              to={{
                                pathname: `/viewsalaryslip`,
                                state: {
                                  salaryslipid: data.fld_employee_id,
                                },
                              }}
                            >
                              <Eye />
                            </Link>

                            <span
                              className="ms-3 btn p-0 download-btn"
                              onClick={() => {
                                PostApiCall.postRequest(
                                  {
                                    whereClause: `where fld_id=${data.id}`,
                                  },
                                  "GetSalarySlipById"
                                ).then((results) =>
                                  results.json().then((obj) => {
                                    Notiflix.Loading.dots("Please wait...");
                                    if (
                                      results.status == 200 ||
                                      results.status == 201
                                    ) {
                                      if (obj.data.length != 0) {
                                        this.setState(
                                          {
                                            ...this.state
                                              .DownloadSalarySlipData,
                                            DownloadSalarySlipData: obj.data,
                                          },

                                          () => {
                                            this.DownloadSalarySlipPdf();
                                          }
                                        );

                                        Notiflix.Loading.remove();
                                      } else {
                                        Notiflix.Loading.remove();
                                        Notiflix.Notify.failure(
                                          "Data Doesn't Exit"
                                        );
                                      }
                                    }
                                    Notiflix.Loading.remove();
                                  })
                                );
                              }}
                            >
                              <Download />
                            </span>
                          </p>
                        ) : (
                          <p className="d-flex algin-content-center justify-content-start">
                            <Button
                              variant="contained"
                              endIcon={<AddCircleIcon />}
                            >
                              <Link
                                to={{
                                  pathname: `/addsalaryslip`,
                                  state: {
                                    id: data.fld_employee_id,
                                    emptype: data.fld_emp_type,
                                    month: this.state.SelectedMonth,
                                    year: this.state.SelectedYear,
                                  },
                                }}
                                className="text-white"
                              >
                                Create Salary Slip
                              </Link>
                            </Button>
                          </p>
                        ),
                    };
                  }),
                }}
                entriesOptions={[10, 25, 50, 100]}
              />
            ) : (
              <p className="m-3">No Data Found</p>
            )}
          </div>
        </div>
        <div style={{ display: "none" }}>
          <div id="salarySlipForm">
            <table style={{ width: "100%", maxWidth: "1300px" }}>
              <tbody style={{ borderWidth: "1px" }}>
                <tr style={{ borderWidth: "1px" }}>
                  <td
                    colspan="2"
                    style={{
                      textAlign: "left",
                      border: "none",
                      padding: "0 1rem",
                      // display: "flex",
                      // flexWrap: "wrap",
                      // justifyContent: "space-between",
                      // width: "100%",
                    }}
                  >
                    <img
                      src={logo}
                      alt="logoinvoiceform"
                      style={{
                        width: "50%",
                        // marginRight: "auto",
                        // marginLeft: "auto",
                        verticalAlign: "middle",
                      }}
                    />{" "}
                  </td>
                  <td
                    colSpan={2}
                    style={{
                      textAlign: "right",
                      border: "none",
                      padding: "0 1rem",
                      fontWeight: "bold",
                      fontSize: "1.6rem",
                    }}
                  >
                    Vahani Scholarship Trust
                  </td>
                </tr>
                <tr>
                  <th
                    colspan="4"
                    style={{
                      textAlign: "center",
                      padding: "5px",
                      borderWidth: "1px",
                    }}
                  >
                    HO- C- 20, First Floor, Defence Colony,
                    <br /> New Delhi 110024. INDIA.
                    <br />
                    Phone: +91 99 99361969
                  </th>
                </tr>
                <tr>
                  <th
                    colspan="4"
                    style={{
                      textAlign: "center",
                      padding: "5px",
                    }}
                  >
                    Email: info@vahanischolarship.com | Website:
                    www.vahanischolarship.com
                  </th>
                </tr>
                {this.state.DownloadSalarySlipData.map((data, i) => {
                  if (i == 0) {
                    return (
                      <>
                        {data.fld_emp_type == "Full Time" ? (
                          <>
                            <tr className="salary-slip-heading">
                              <th
                                colspan="4"
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  backgroundColor: "grey",
                                  color: "black",
                                }}
                              >
                                PAY SLIP FOR THE MONTH OF{" "}
                                {data.fld_month.toUpperCase()} {data.fld_year}
                              </th>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Name
                              </th>
                              <td
                                colspan="3"
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_employee_name}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Code
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_employee_id}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Date of Joining
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_date_of_joining}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Designation
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_designation}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Gross Salary
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_gross_salary}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Department
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_department}
                              </td>

                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                No. of Days in Month
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_no_of_days_month}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Location
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_location}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                No. of Days Absent
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_no_of_days_absent}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                PAN
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_pan}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                No. of Working Days
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_no_of_working_days}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Account Holder Name
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_acc_holder_name}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Bank Account No.
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_bank_acc_no}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                IFSC Code
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_ifsc_code}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Branch Name
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_branch_name}
                              </td>
                            </tr>
                            <tr
                              style={{
                                backgroundColor: "grey",
                                color: "black",
                              }}
                            >
                              <th
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Earnings
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Amount
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Deductions
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Amount
                              </th>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                Basic Salary
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_basic_salary}
                              </td>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                Leave Deductions
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_leave_deductions}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                HRA
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_hra}
                              </td>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                Advance Paid
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_advances}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                CCA
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_cca}
                              </td>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                Tax Deductions
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_tax_deductions}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                Reimbursement
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_reimbursement == null
                                  ? 0
                                  : data.fld_reimbursement}
                              </td>

                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                Other Deductions
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_other_deductions}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                Other Allowance
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_other_allowance}
                              </td>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              ></th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              ></td>
                            </tr>
                          </>
                        ) : data.fld_emp_type == "Part Time" ? (
                          <>
                            <tr className="salary-slip-heading">
                              <th
                                colspan="4"
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  backgroundColor: "grey",
                                  color: "black",
                                }}
                              >
                                PAY SLIP FOR THE MONTH OF{" "}
                                {data.fld_month.toUpperCase()} {data.fld_year}
                              </th>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Name
                              </th>
                              <td
                                colspan="3"
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_employee_name}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Code
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_employee_id}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Date of Joining
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_date_of_joining}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Designation
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_designation}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Gross Salary
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_gross_salary}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Department
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                                colspan="3"
                              >
                                {data.fld_department}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                                colspan="3"
                              >
                                Location
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_location}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                                colspan="3"
                              >
                                PAN
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_pan}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Account Holder Name
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_acc_holder_name}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Bank Account No.
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_bank_acc_no}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                IFSC Code
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_ifsc_code}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Branch Name
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_branch_name}
                              </td>
                            </tr>
                            <tr
                              style={{
                                backgroundColor: "grey",
                                color: "black",
                              }}
                            >
                              <th
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Earnings
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Amount
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Deductions
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Amount
                              </th>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                Salary
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_basic_salary}
                              </td>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                Leave Deductions
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_leave_deductions}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                Reimbursement
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_reimbursement == null
                                  ? 0
                                  : data.fld_reimbursement}
                              </td>

                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                Other Deductions
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_other_deductions}
                              </td>
                            </tr>
                          </>
                        ) : data.fld_emp_type == "Contract" ? (
                          <>
                            <tr className="salary-slip-heading">
                              <th
                                colspan="4"
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  backgroundColor: "grey",
                                  color: "black",
                                }}
                              >
                                PAY SLIP FOR THE MONTH OF{" "}
                                {data.fld_month.toUpperCase()} {data.fld_year}
                              </th>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Name
                              </th>
                              <td
                                colspan="3"
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_employee_name}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Code
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_employee_id}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Date of Joining
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_date_of_joining}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Designation
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_designation}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Gross Salary
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_gross_salary}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Department
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_department}
                              </td>

                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                No. of Days in Month
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_no_of_days_month}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Location
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_location}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                No. of Days Absent
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_no_of_days_absent}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                PAN
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_pan}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                No. of Working Days
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_no_of_working_days}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Account Holder Name
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_acc_holder_name}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Bank Account No.
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_bank_acc_no}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                IFSC Code
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_ifsc_code}
                              </td>
                              <th
                                style={{
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Branch Name
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  width: "25%",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data.fld_branch_name}
                              </td>
                            </tr>
                            <tr
                              style={{
                                backgroundColor: "grey",
                                color: "black",
                              }}
                            >
                              <th
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Earnings
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Amount
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Deductions
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderWidth: "1px",
                                  width: "25%",
                                }}
                              >
                                Amount
                              </th>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                Salary
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_basic_salary}
                              </td>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                Tax Deductions
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_tax_deductions}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                Reimbursement
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_reimbursement == null
                                  ? 0
                                  : data.fld_reimbursement}
                              </td>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                Advance Paid
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_advances}
                              </td>
                            </tr>
                            <tr style={{ borderWidth: "1px" }}>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              ></th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              ></td>
                              <th
                                style={{
                                  borderWidth: "1px",
                                  padding: "5px",
                                  width: "25%",
                                }}
                              >
                                Other Deductions
                              </th>
                              <td
                                style={{
                                  borderWidth: "1px",
                                  textAlign: "right",
                                  width: "25%",
                                  paddingRight: "4px",
                                }}
                              >
                                {data.fld_other_deductions}
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        <tr style={{ borderWidth: "1px" }}>
                          <th
                            style={{
                              borderWidth: "1px",
                              padding: "5px",
                              width: "25%",
                            }}
                          >
                            Total Earnings (A)
                          </th>
                          <td
                            style={{
                              borderWidth: "1px",
                              textAlign: "right",
                              width: "25%",
                              paddingRight: "4px",
                            }}
                          >
                            {data.fld_total_earnings}
                          </td>
                          <th
                            style={{
                              borderWidth: "1px",
                              padding: "5px",
                              width: "25%",
                            }}
                          >
                            Total Deductions (B)
                          </th>
                          <td
                            style={{
                              borderWidth: "1px",
                              textAlign: "right",
                              width: "25%",
                              paddingRight: "4px",
                            }}
                          >
                            {data.fld_total_deductions}
                          </td>
                        </tr>
                        <tr
                          style={{
                            backgroundColor: "grey",
                            color: "black",
                            borderWidth: "1px",
                          }}
                        >
                          <th
                            style={{
                              borderWidth: "1px",
                              textAlign: "right",
                              padding: "5px",
                              fontWeight: "bolder",
                              fontSize: "1.2rem",
                              borderRightColor: "black",
                            }}
                            colspan="3"
                          >
                            Net Pay (A-B)
                          </th>
                          <td
                            style={{
                              borderWidth: "1px",
                              textAlign: "right",
                              paddingRight: "4px",
                            }}
                          >
                            {data.fld_net_pay}
                          </td>
                        </tr>
                      </>
                    );
                  }
                })}

                <tr>
                  <td style={{ paddingLeft: "5px" }} colspan="4">
                    This is a computer generated salary slip.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}
export default SalarySlipRecords;
