import React, { Component } from "react";

import Notiflix from "notiflix";
import GetApiCall from "../../../../assets/API/GetApi";
import PostApiCall from "../../../../assets/API/PostApi";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";

import {
  MenuItem,
  FormControl,
  Select,
  Button,
  InputLabel,
  TextField,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SwipeableViews from "react-swipeable-views";
import Typography from "@mui/material/Typography";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class AddSalarySlip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PageTitle: "1",
      Page1: "Pending",
      Question: "",
      Answer: "",
      Data: [],
      Details: [],
      QuestionId: [],
      InformationData: [],
      Name: "",
      Type: "",
      Notes: "",
      Days: "",
      StartDate: "",
      EndDate: "",
      SalarySlipId: null,
      EmpData: [],
      NameData: [],
      NumRegex: /^[0-9]*$/,
      LeaveId: null,
      EmpName: "",
      BankAccount: "",
      DateOfJoining: "",
      Month: "",
      NumberofDays: "",
      TotalPaidDays: 0,
      TotalNonPaidDays: 0,
      GrossMonthlyPay: 0,
      taxdeducted: 0,
      Advances: 0,
      OtherDeductions: 0,
      TotalEarnings: 0,
      TotalDeductions: 0,
      NetSalary: 0,
      AllEmpJoiningDate: [],
      BankAccountNo: "",
      EmployeeName: "",
      MonthData: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      EmployeeId: "",
      AllEmployee: [],
      EmpData: [],
      DropDownvalue: null,
      InputValue: "",
      Designation: "",
      City: "",
      Pan: "",
      EmpId: "",
      Month: "Choose Month",
      Year: "",
      AccountHolderName: "",
      IFSCCode: "",
      BranchName: "",
      BasicSalary: 0,
      HRA: 0,
      LeaveDeductions: 0,
      Advances: 0,
      CCA: 0,
      TaxDeductions: 0,
      Reimbursement: 0,
      OtherAllowance: 0,
      Department: "",
      Location: "",
      tab: 0,
      AllFulltimeEmp: [],
      AllPartTimeEmp: [],
      AllContractEmp: [],
      EmpType: "",
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "/assets/js/pages/form-wizard.init.js";
    script.async = true;
    document.body.appendChild(script);
    this.setState({
      Year: this.props.location.state.year,
    });
    if (this.props.location.state.emptype == "Full Time") {
      Notiflix.Loading.dots("Please wait...");
      PostApiCall.postRequest(
        {
          whereClause: `where [fld_employee_id]='${this.props.location.state.id}'`,
        },
        "GetEmployeeById"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            this.setState({
              DateOfJoining: moment(obj.data[0].fld_date_of_joining).format(
                "DD/MM/YYYY"
              ),
              Designation: obj.data[0].fld_designation,
              BankAccountNo: obj.data[0].fld_bank_account_number,
              AccountHolderName: obj.data[0].fld_account_holder_name,
              IFSCCode: obj.data[0].fld_ifsc_code,
              BranchName: obj.data[0].fld_branch_name,
              EmployeeName: obj.data[0].fld_employee_full_name,
              EmpId: obj.data[0].fld_employee_id,
              GrossMonthlyPay: obj.data[0].fld_monthly_salary,
              EmployeeId: obj.data[0].fld_employee_id,
              Pan: obj.data[0].fld_pan_card_number,
              BasicSalary: obj.data[0].fld_monthly_salary * 0.5,
              HRA: obj.data[0].fld_monthly_salary * 0.2,
              CCA: obj.data[0].fld_monthly_salary * 0.15,
              OtherAllowance: obj.data[0].fld_monthly_salary * 0.15,
              Department: obj.data[0].fld_department,
              Location: obj.data[0].fld_present_state,
              EmpType: obj.data.fld_emp_type,
            });
            Notiflix.Loading.remove();
          }
        })
      );
      PostApiCall.postRequest(
        {
          whereClause: `where [fld_employee_id]='${this.props.location.state.id}'`,
        },
        "GetAttendance"
      ).then((resultattendance) =>
        resultattendance.json().then((obj) => {
          var totalworkingdays = 0;
          var totalnonworkingdays = 0;
          obj.data.map((data, i) => {
            if (
              this.state.MonthData[new Date(data.fld_end_date).getMonth()] ==
              this.props.location.state.month &&
              (data.fld_title == "Present" || data.fld_title == "Leave")
            ) {
              totalworkingdays++;
            } else if (
              this.state.MonthData[new Date(data.fld_end_date).getMonth()] ==
              this.props.location.state.month &&
              data.fld_title == "Absent"
            ) {
              totalnonworkingdays++;
            }
          });
          this.setState({
            TotalPaidDays: totalworkingdays,
            TotalNonPaidDays: totalnonworkingdays,
          });
        })
      );
    } else if (this.props.location.state.emptype == "Part Time") {
      Notiflix.Loading.dots("Please wait...");
      PostApiCall.postRequest(
        {
          whereClause: `where [fld_employee_id]='${this.props.location.state.id}'`,
        },
        "GetEmployeeById"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            this.setState({
              DateOfJoining: moment(obj.data[0].fld_date_of_joining).format(
                "DD/MM/YYYY"
              ),
              Designation: obj.data[0].fld_designation,
              BankAccountNo: obj.data[0].fld_bank_account_number,
              AccountHolderName: obj.data[0].fld_account_holder_name,
              IFSCCode: obj.data[0].fld_ifsc_code,
              BranchName: obj.data[0].fld_branch_name,
              EmployeeName: obj.data[0].fld_employee_full_name,
              EmpId: obj.data[0].fld_employee_id,
              GrossMonthlyPay: obj.data[0].fld_monthly_salary,
              EmployeeId: obj.data[0].fld_employee_id,
              Pan: obj.data[0].fld_pan_card_number,
              BasicSalary: obj.data[0].fld_monthly_salary,
              Department: obj.data[0].fld_department,
              Location: obj.data[0].fld_present_state,
              EmpType: obj.data.fld_emp_type,
            });
            Notiflix.Loading.remove();
          }
        })
      );
    } else {
      Notiflix.Loading.dots("Please wait...");
      PostApiCall.postRequest(
        {
          whereClause: `where [fld_employee_id]='${this.props.location.state.id}'`,
        },
        "GetEmployeeById"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            this.setState({
              DateOfJoining: moment(obj.data[0].fld_date_of_joining).format(
                "DD/MM/YYYY"
              ),
              Designation: obj.data[0].fld_designation,
              BankAccountNo: obj.data[0].fld_bank_account_number,
              AccountHolderName: obj.data[0].fld_account_holder_name,
              IFSCCode: obj.data[0].fld_ifsc_code,
              BranchName: obj.data[0].fld_branch_name,
              EmployeeName: obj.data[0].fld_employee_full_name,
              EmpId: obj.data[0].fld_employee_id,
              GrossMonthlyPay: obj.data[0].fld_monthly_salary,
              EmployeeId: obj.data[0].fld_employee_id,
              Pan: obj.data[0].fld_pan_card_number,
              BasicSalary: obj.data[0].fld_monthly_salary,
              Department: obj.data[0].fld_department,
              Location: obj.data[0].fld_present_state,
              EmpType: obj.data.fld_emp_type,
            });
            Notiflix.Loading.remove();
          }
        })
      );

      PostApiCall.postRequest(
        {
          whereClause: `where [fld_employee_id]='${this.props.location.state.id}'`,
        },
        "GetAttendance"
      ).then((resultattendance) =>
        resultattendance.json().then((obj) => {
          var totalworkingdays = 0;
          var totalnonworkingdays = 0;
          obj.data.map((data, i) => {
            if (
              this.state.MonthData[new Date(data.fld_end_date).getMonth()] ==
              this.props.location.state.month &&
              (data.fld_title == "Present" || data.fld_title == "Leave")
            ) {
              totalworkingdays++;
            } else if (data.fld_title == "Absent") {
              totalnonworkingdays++;
            }
          });
          this.setState({
            TotalPaidDays: totalworkingdays,
            TotalNonPaidDays: totalnonworkingdays,
          });
        })
      );
    }

    var date = new Date();
    this.setState({
      Month: this.props.location.state.month,
      NumberofDays: new Date(
        date.getFullYear(),
        this.state.MonthData.indexOf(this.props.location.state.month) + 1,
        0
      ).getDate(),
    });

    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
  }

  SaveSalarySlip(emptype) {
    if (
      this.state.BasicSalary +
      this.state.HRA +
      this.state.CCA +
      parseInt(this.state.Reimbursement) +
      this.state.OtherAllowance -
      (parseInt(this.state.LeaveDeductions) +
        parseInt(this.state.Advances) +
        parseInt(this.state.TaxDeductions) +
        parseInt(this.state.OtherDeductions)) !=
      NaN
    ) {
      this.onPost(emptype);
    }
  }

  onPost = (emptype) => {
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    Notiflix.Loading.dots("Please wait...");
    PostApiCall.postRequest(
      {
        emptype: emptype,
        salaryslipid: this.state.SalarySlipId,
        employeeid: this.state.EmpId,
        employeename: this.state.EmployeeName,
        dateofjoining: this.state.DateOfJoining,
        designation: this.state.Designation,
        grosssalary: this.state.GrossMonthlyPay,
        department: this.state.Department,
        month: this.state.Month,
        noofdaysinmonth: this.state.NumberofDays,
        location: this.state.Location,
        noofdaysabsent: this.state.TotalNonPaidDays,
        pancard: this.state.Pan,
        noofworkingdays: this.state.TotalPaidDays,
        bankaccountno: this.state.BankAccountNo,
        accholdername: this.state.AccountHolderName,
        ifsc: this.state.IFSCCode,
        branchname: this.state.BranchName,
        basicsalary: this.state.BasicSalary,
        leavedeductions: this.state.LeaveDeductions,
        hra: this.state.HRA,
        advances: this.state.Advances,
        cca: this.state.CCA,
        taxdeductions: this.state.TaxDeductions,
        reimbursement: this.state.Reimbursement,
        otherallowance: this.state.OtherAllowance,
        otherdeductions: this.state.OtherDeductions,
        totalearnings: this.state.TotalEarnings,
        totaldeductions: this.state.TotalDeductions,
        totalearnings:
          this.state.BasicSalary +
          this.state.HRA +
          this.state.CCA +
          parseInt(this.state.Reimbursement) +
          this.state.OtherAllowance,
        totaldeductions:
          parseInt(this.state.LeaveDeductions) +
          parseInt(this.state.Advances) +
          parseInt(this.state.TaxDeductions) +
          parseInt(this.state.OtherDeductions),
        netpay:
          this.state.BasicSalary +
          this.state.HRA +
          this.state.CCA +
          parseInt(this.state.Reimbursement) +
          this.state.OtherAllowance -
          (parseInt(this.state.LeaveDeductions) +
            parseInt(this.state.Advances) +
            parseInt(this.state.TaxDeductions) +
            parseInt(this.state.OtherDeductions)),
      },
      "AddSalarySlip"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Salary Slip Successfully Submitted.");
          window.history.go(-1);
        } else {
          Notiflix.Notify.failure("Error Occured");
        }
      })
    );
  };

  render() {
    return (
      <>
        {this.props.location.state.emptype == "Full Time" ? (
          <>
            <div className="form-head mb-lg-2 mb-3">
              <div
                className="col-auto "
                style={{
                  borderLeft: "10px solid rgb(32, 101, 209)",
                  paddingLeft: "10px",
                }}
              >
                <h4>Add Salary Slip</h4>
              </div>
            </div>
            <div className="card-vahani p-0 mt-2">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div id="smartwizard-arrows">
                        <p className="personal-info m-0 left-line-card p-4">
                          Full Time Salary Slip
                        </p>

                        <div className="p-3" style={{ minHeight: "0px" }}>
                          <div
                            id="sw-arrows-step-1"
                            className="tab-pane step-content"
                            style={{
                              display:
                                this.state.PageTitle == "1" ? "block" : "none",
                            }}
                          >
                            <form
                              className="needs-validation"
                              novalidate
                              onSubmit={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <table className="w-100 salary-slip-form">
                                <tbody>
                                  <tr>
                                    <th>Name</th>
                                    <td colSpan={3}>
                                      <input
                                        type="text"
                                        value={this.state.EmployeeName}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Code</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.EmpId}
                                      />
                                    </td>
                                    <th>Date of Joining</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.DateOfJoining}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Designation</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Designation}
                                      />
                                    </td>
                                    <th>Gross Salary</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.GrossMonthlyPay}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Month</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Month}
                                      />
                                    </td>
                                    <th>Year</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Year}
                                      />
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Department</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Department}
                                      />
                                    </td>

                                    <th>No. of Days in Month</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.NumberofDays}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Location</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Location}
                                      />
                                    </td>
                                    <th>No. of Days Absent</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.TotalNonPaidDays}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>PAN</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Pan}
                                      />
                                    </td>
                                    <th>No. of Working Days</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.TotalPaidDays}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Account Holder Name</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.AccountHolderName}
                                      />
                                    </td>
                                    <th>Bank Account No.</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.BankAccountNo}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>IFSC Code</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.IFSCCode}
                                      />
                                    </td>
                                    <th>Branch Name</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.BranchName}
                                      />
                                    </td>
                                  </tr>

                                  <tr className="salary-slip-heading">
                                    <th className="text-center">Earnings</th>
                                    <th className="text-center">Amount</th>
                                    <th className="text-center">Deductions</th>
                                    <th className="text-center">Amount</th>
                                  </tr>
                                  <tr>
                                    <th>Basic Salary</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.BasicSalary}
                                      />
                                    </td>
                                    <th>Leave Deductions</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.LeaveDeductions}
                                        onChange={(e) => {
                                          this.setState({
                                            LeaveDeductions: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>HRA</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.HRA}
                                      />
                                    </td>
                                    <th>Advance Paid</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.Advances}
                                        onChange={(e) => {
                                          this.setState({
                                            Advances: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>CCA</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.CCA}
                                      />
                                    </td>
                                    <th>Tax Deductions</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.TaxDeductions}
                                        onChange={(e) => {
                                          this.setState({
                                            TaxDeductions: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Reimbursement</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.Reimbursement}
                                        onChange={(e) => {
                                          this.setState({
                                            Reimbursement: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>

                                    <th>Other Deductions</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.OtherDeductions}
                                        onChange={(e) => {
                                          this.setState({
                                            OtherDeductions: e.target.value,
                                            TotalEarnings:
                                              this.state.BasicSalary +
                                              this.state.HRA +
                                              this.state.CCA +
                                              parseInt(
                                                this.state.Reimbursement
                                              ) +
                                              this.state.OtherAllowance,
                                            TotalDeductions:
                                              parseInt(
                                                this.state.LeaveDeductions
                                              ) +
                                              parseInt(this.state.Advances) +
                                              parseInt(
                                                this.state.TaxDeductions
                                              ) +
                                              parseInt(
                                                this.state.OtherDeductions
                                              ),
                                          });
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Other Allowance</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.OtherAllowance}
                                      />
                                    </td>
                                    <th></th>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <th>Total Earnings (A)</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={
                                          this.state.BasicSalary +
                                          this.state.HRA +
                                          this.state.CCA +
                                          parseInt(this.state.Reimbursement) +
                                          this.state.OtherAllowance
                                        }
                                        onChange={(e) => {
                                          this.setState({
                                            TotalEarnings: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                    <th>Total Deductions (B)</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={
                                          parseInt(this.state.LeaveDeductions) +
                                          parseInt(this.state.Advances) +
                                          parseInt(this.state.TaxDeductions) +
                                          parseInt(this.state.OtherDeductions)
                                        }
                                        onChange={(e) => {
                                          this.setState({
                                            TotalDeductions: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr className="salary-slip-heading">
                                    <th className="text-end fs-5" colSpan={3}>
                                      Net Pay (A-B)
                                    </th>
                                    <td className="text-right">
                                      {this.state.BasicSalary +
                                        this.state.HRA +
                                        this.state.CCA +
                                        parseInt(this.state.Reimbursement) +
                                        this.state.OtherAllowance -
                                        (parseInt(this.state.LeaveDeductions) +
                                          parseInt(this.state.Advances) +
                                          parseInt(this.state.TaxDeductions) +
                                          parseInt(this.state.OtherDeductions))}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div
                                role="alert"
                                aria-live="assertive"
                                aria-atomic="true"
                                data-toggle="toast"
                              >
                                <div
                                  className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3"
                                  style={{ float: "right" }}
                                >
                                  <div
                                    className=" justify-content-end"
                                    style={{ display: "flex" }}
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={() => {
                                        this.SaveSalarySlip("Full Time");
                                      }}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : this.props.location.state.emptype == "Part Time" ? (
          <>
            <div className="form-head mb-lg-2 mb-3">
              <div
                className="col-auto "
                style={{
                  borderLeft: "10px solid rgb(32, 101, 209)",
                  paddingLeft: "10px",
                }}
              >
                <h4>Add Salary Slip</h4>
              </div>
            </div>
            <div className="card-vahani p-0 mt-2">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div id="smartwizard-arrows">
                        <p className="personal-info m-0 left-line-card p-4">
                          Part Time Salary Slip
                        </p>

                        <div className="p-3" style={{ minHeight: "0px" }}>
                          <div
                            id="sw-arrows-step-1"
                            className="tab-pane step-content"
                            style={{
                              display:
                                this.state.PageTitle == "1" ? "block" : "none",
                            }}
                          >
                            <form
                              className="needs-validation"
                              novalidate
                              onSubmit={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <table className="w-100 salary-slip-form">
                                <tbody>
                                  <tr>
                                    <th>Name</th>
                                    <td colSpan={3}>
                                      <td colSpan={3}>
                                        <input
                                          type="text"
                                          value={this.state.EmployeeName}
                                        />
                                      </td>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Code</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.EmpId}
                                      />
                                    </td>
                                    <th>Date of Joining</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.DateOfJoining}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Designation</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Designation}
                                      />
                                    </td>

                                    <th>Gross Salary</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.GrossMonthlyPay}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Month</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Month}
                                      />
                                    </td>
                                    <th>Year</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Year}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Department</th>
                                    <td colSpan={3}>
                                      <input
                                        type="text"
                                        value={this.state.Department}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Location</th>
                                    <td colSpan={3}>
                                      <input
                                        type="text"
                                        value={this.state.Location}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>PAN</th>
                                    <td colSpan={3}>
                                      <input
                                        type="text"
                                        value={this.state.Pan}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Account Holder Name</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.AccountHolderName}
                                      />
                                    </td>
                                    <th>Bank Account No.</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.BankAccountNo}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>IFSC Code</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.IFSCCode}
                                      />
                                    </td>
                                    <th>Branch Name</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.BranchName}
                                      />
                                    </td>
                                  </tr>

                                  <tr className="salary-slip-heading">
                                    <th className="text-center">Earnings</th>
                                    <th className="text-center">Amount</th>
                                    <th className="text-center">Deductions</th>
                                    <th className="text-center">Amount</th>
                                  </tr>
                                  <tr>
                                    <th>Salary</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.BasicSalary}
                                      />
                                    </td>
                                    <th>Leave Deductions</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.LeaveDeductions}
                                        onChange={(e) => {
                                          this.setState({
                                            LeaveDeductions: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Reimbursement</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.Reimbursement}
                                        onChange={(e) => {
                                          this.setState({
                                            Reimbursement: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>

                                    <th>Other Deductions</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.OtherDeductions}
                                        onChange={(e) => {
                                          this.setState({
                                            OtherDeductions: e.target.value,
                                            TotalEarnings:
                                              this.state.BasicSalary +
                                              this.state.HRA +
                                              this.state.CCA +
                                              parseInt(
                                                this.state.Reimbursement
                                              ) +
                                              this.state.OtherAllowance,
                                            TotalDeductions:
                                              parseInt(
                                                this.state.LeaveDeductions
                                              ) +
                                              parseInt(this.state.Advances) +
                                              parseInt(
                                                this.state.TaxDeductions
                                              ) +
                                              parseInt(
                                                this.state.OtherDeductions
                                              ),
                                          });
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Total Earnings (A)</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={
                                          this.state.BasicSalary +
                                          this.state.HRA +
                                          this.state.CCA +
                                          parseInt(this.state.Reimbursement) +
                                          this.state.OtherAllowance
                                        }
                                        onChange={(e) => {
                                          this.setState({
                                            TotalEarnings: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                    <th>Total Deductions (B)</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={
                                          parseInt(this.state.LeaveDeductions) +
                                          parseInt(this.state.Advances) +
                                          parseInt(this.state.TaxDeductions) +
                                          parseInt(this.state.OtherDeductions)
                                        }
                                        onChange={(e) => {
                                          this.setState({
                                            TotalDeductions: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr className="salary-slip-heading">
                                    <th className="text-end fs-5" colSpan={3}>
                                      Net Pay (A-B)
                                    </th>
                                    <td className="text-right">
                                      {this.state.BasicSalary +
                                        this.state.HRA +
                                        this.state.CCA +
                                        parseInt(this.state.Reimbursement) +
                                        this.state.OtherAllowance -
                                        (parseInt(this.state.LeaveDeductions) +
                                          parseInt(this.state.Advances) +
                                          parseInt(this.state.TaxDeductions) +
                                          parseInt(this.state.OtherDeductions))}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div
                                role="alert"
                                aria-live="assertive"
                                aria-atomic="true"
                                data-toggle="toast"
                              >
                                <div
                                  className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3"
                                  style={{ float: "right" }}
                                >
                                  <div
                                    className=" justify-content-end"
                                    style={{ display: "flex" }}
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={() => {
                                        this.SaveSalarySlip("Part Time");
                                      }}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : this.props.location.state.emptype == "Contract" ? (
          <>
            <div className="form-head mb-lg-2 mb-3">
              <div
                className="col-auto "
                style={{
                  borderLeft: "10px solid rgb(32, 101, 209)",
                  paddingLeft: "10px",
                }}
              >
                <h4>Add Salary Slip</h4>
              </div>
            </div>
            <div className="card-vahani p-0 mt-2">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div id="smartwizard-arrows">
                        <p className="personal-info m-0 left-line-card p-4">
                          Contract Salary Slip
                        </p>

                        <div className="p-3" style={{ minHeight: "0px" }}>
                          <div
                            id="sw-arrows-step-1"
                            className="tab-pane step-content"
                            style={{
                              display:
                                this.state.PageTitle == "1" ? "block" : "none",
                            }}
                          >
                            <form
                              className="needs-validation"
                              novalidate
                              onSubmit={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <table className="w-100 salary-slip-form">
                                <tbody>
                                  <tr>
                                    <th>Name</th>
                                    <td colSpan={3}>
                                      <input
                                        type="text"
                                        value={this.state.EmployeeName}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Code</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.EmpId}
                                      />
                                    </td>
                                    <th>Date of Joining</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.DateOfJoining}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Designation</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Designation}
                                      />
                                    </td>
                                    <th>Gross Salary</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.GrossMonthlyPay}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Month</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Month}
                                      />
                                    </td>
                                    <th>Year</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Year}
                                      />
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Department</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Department}
                                      />
                                    </td>

                                    <th>No. of Days in Month</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.NumberofDays}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Location</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Location}
                                      />
                                    </td>
                                    <th>No. of Days Absent</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.TotalNonPaidDays}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>PAN</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.Pan}
                                      />
                                    </td>
                                    <th>No. of Working Days</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.TotalPaidDays}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Account Holder Name</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.AccountHolderName}
                                      />
                                    </td>
                                    <th>Bank Account No.</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.BankAccountNo}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>IFSC Code</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.IFSCCode}
                                      />
                                    </td>
                                    <th>Branch Name</th>
                                    <td>
                                      <input
                                        type="text"
                                        value={this.state.BranchName}
                                      />
                                    </td>
                                  </tr>

                                  <tr className="salary-slip-heading">
                                    <th className="text-center">Earnings</th>
                                    <th className="text-center">Amount</th>
                                    <th className="text-center">Deductions</th>
                                    <th className="text-center">Amount</th>
                                  </tr>
                                  <tr>
                                    <th>Salary</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.BasicSalary}
                                      />
                                    </td>
                                    <th>Tax Deductions</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.TaxDeductions}
                                        onChange={(e) => {
                                          this.setState({
                                            TaxDeductions: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Reimbursement</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.Reimbursement}
                                        onChange={(e) => {
                                          this.setState({
                                            Reimbursement: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>

                                    <th>Advance Paid</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.Advances}
                                        onChange={(e) => {
                                          this.setState({
                                            Advances: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th></th>
                                    <td></td>
                                    <th>Other Deductions</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={this.state.OtherDeductions}
                                        onChange={(e) => {
                                          this.setState({
                                            OtherDeductions: e.target.value,
                                            TotalEarnings:
                                              this.state.BasicSalary +
                                              this.state.HRA +
                                              this.state.CCA +
                                              parseInt(
                                                this.state.Reimbursement
                                              ) +
                                              this.state.OtherAllowance,
                                            TotalDeductions:
                                              parseInt(
                                                this.state.LeaveDeductions
                                              ) +
                                              parseInt(this.state.Advances) +
                                              parseInt(
                                                this.state.TaxDeductions
                                              ) +
                                              parseInt(
                                                this.state.OtherDeductions
                                              ),
                                          });
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Total Earnings (A)</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={
                                          this.state.BasicSalary +
                                          this.state.HRA +
                                          this.state.CCA +
                                          parseInt(this.state.Reimbursement) +
                                          this.state.OtherAllowance
                                        }
                                        onChange={(e) => {
                                          this.setState({
                                            TotalEarnings: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                    <th>Total Deductions (B)</th>
                                    <td>
                                      <input
                                        className="text-right"
                                        type="text"
                                        value={
                                          parseInt(this.state.LeaveDeductions) +
                                          parseInt(this.state.Advances) +
                                          parseInt(this.state.TaxDeductions) +
                                          parseInt(this.state.OtherDeductions)
                                        }
                                        onChange={(e) => {
                                          this.setState({
                                            TotalDeductions: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr className="salary-slip-heading">
                                    <th className="text-end fs-5" colSpan={3}>
                                      Net Pay (A-B)
                                    </th>
                                    <td className="text-right">
                                      {this.state.BasicSalary +
                                        this.state.HRA +
                                        this.state.CCA +
                                        parseInt(this.state.Reimbursement) +
                                        this.state.OtherAllowance -
                                        (parseInt(this.state.LeaveDeductions) +
                                          parseInt(this.state.Advances) +
                                          parseInt(this.state.TaxDeductions) +
                                          parseInt(this.state.OtherDeductions))}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div
                                role="alert"
                                aria-live="assertive"
                                aria-atomic="true"
                                data-toggle="toast"
                              >
                                <div
                                  className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3"
                                  style={{ float: "right" }}
                                >
                                  <div
                                    className=" justify-content-end"
                                    style={{ display: "flex" }}
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={() => {
                                        this.SaveSalarySlip("Contract");
                                      }}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default AddSalarySlip;
