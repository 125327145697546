import React from "react";
import { Route, Switch } from "react-router-dom";
import ExpenseForm from "../pages/ScholarManagement/Expense/ExpenseForm";
import ExpenseList from "../pages/ScholarManagement/Expense/ExpenseList";
import Familyinfo from "../Components/Form/Familyinfo/Familyinfo";
import AdditionalFamilyInfo from "../Components/Form/Familyinfo/AdditionalFamilyInfo";
import PersonalInfo from "../Components/Form/Personalinfo/Personalinfo";
import Address from "../Components/Form/Address/Address";
import PersonalBank from "../pages/ScholarManagement/BankDetails/PersonalBank";
import ParentBank from "../pages/ScholarManagement/BankDetails/ParentsBank";
import Academic from "../Components/Form/Academic Achievement/AcademicAch";
import SchoolInfo from "../Components/Form/SchoolForm/SchoolInfo";
import Extra from "../Components/Form/ExtraCurriculation/ExtraCurriculation";
import University from "../Components/Form/University/University-College";
import Essay from "../Components/Form/Essay/Essay";
import Additional from "../Components/Form/AdditionalApplication/AdditionalApplication";
import SubmitTicket from "../Components/Form/TicketManagement/SubmitTicket";
import TicketHistory from "../Components/Form/TicketManagement/TicketHistory";
import ViewTicket from "../Components/Form/TicketManagement/ViewTicket";
import AddAccomodation from "../pages/ScholarManagement/Accomodation/AddAccomodation";
import CollegeInformation from "../pages/ScholarManagement/Information/CollegeInformation";
import CourseInformation from "../pages/ScholarManagement/Information/CourseInformation";
import AccomodationList from "../pages/ScholarManagement/Accomodation/AccomodationList";
import ResultHistory from "../pages/ScholarManagement/Result/ResultHistory";
import CollegeBank from "../pages/ScholarManagement/BankDetails/CollegeBank";
import HostelBank from "../pages/ScholarManagement/BankDetails/HostelBank";
import MessBank from "../pages/ScholarManagement/BankDetails/MessBank";
import PageNotFound from "../pages/PageNotFound";
import ViewNotification from "../pages/ScholarManagement/Notification/ViewNotifcation";
import ResetEmail from "../Components/Form/Email/Email";
import ResetPassword from "../Components/Form/Password/Password";
import DiscussionForum from "../pages/DiscussionForum/DiscussionForum";
import AskQuestion from "../pages/DiscussionForum/AskQuestion";
const Dashboard = React.lazy(() =>
  import("../pages/ScholarManagement/Dashboard/Dashboard")
);
const ScholarRouter = () => {
  return (
    <Switch>
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/expense" component={ExpenseForm} />
      <Route exact path="/expense-list" component={ExpenseList} />
      <Route exact path="/personalinformation" component={PersonalInfo} />
      <Route exact path="/familyinformation" component={Familyinfo} />
      <Route exact path="/additionalfamily" component={AdditionalFamilyInfo} />
      <Route path="/address" component={Address} exact />
      <Route path="/personal-bank" component={PersonalBank} exact />
      <Route path="/parent-bank" component={ParentBank} exact />
      <Route path="/college-bank" component={CollegeBank} exact />
      <Route path="/Hostel-bank" component={HostelBank} exact />
      <Route path="/Mess-bank" component={MessBank} exact />
      <Route path="/schoolinfo" component={SchoolInfo} exact />
      <Route path="/academic-achievment" component={Academic} exact />
      <Route path="/extra-curricular-activities" component={Extra} exact />
      <Route
        path="/university-college-preference"
        component={University}
        exact
      />
      <Route path="/essay" component={Essay} exact />
      <Route path="/additional-applicant" component={Additional} exact />
      <Route path="/submit-ticket" component={SubmitTicket} exact />
      <Route path="/ticket-history" component={TicketHistory} exact />
      <Route path="/view-ticket" component={ViewTicket} exact />
      <Route exact path="/accomodation-list" component={AccomodationList} />
      <Route exact path="/add-accomodation" component={AddAccomodation} />
      <Route exact path="/college-information" component={CollegeInformation} />
      <Route exact path="/course-information" component={CourseInformation} />
      <Route exact path="/exam-result" component={ResultHistory} />
      <Route exact path="/view-notification" component={ViewNotification} />
      <Route exact path="/change-email" component={ResetEmail} />
      <Route exact path="/change-password" component={ResetPassword} />
      <Route exact path="/discussion-forum" component={DiscussionForum} />
      <Route exact path="/add-question" component={AskQuestion} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};
export default ScholarRouter;
