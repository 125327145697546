import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Notiflix from "notiflix";
import * as React from "react";
import { useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import PostApiCall from "../../../assets/API/PostApi";
import AcademicAchievement from "../../../Components/Form/Academic Achievement/AcademicAch";
import Address from "../../../Components/Form/Address/Address";
import Cv from "../../../Components/Form/Cv/Cv";
import Essay from "../../../Components/Form/Essay/Essay";
import ExtraCurriculation from "../../../Components/Form/ExtraCurriculation/ExtraCurriculation";
import AdditionalFamilyInfo from "../../../Components/Form/Familyinfo/AdditionalFamilyInfo";
import Familyinfo from "../../../Components/Form/Familyinfo/Familyinfo";
import PersonalInfo from "../../../Components/Form/Personalinfo/Personalinfo";
import SchoolInfo from "../../../Components/Form/SchoolForm/SchoolInfo";
import Preference from "../../../Components/Form/University/University-College";
import store from "../../../store";
import AccomodationList from "../../ScholarManagement/Accomodation/AccomodationList";
import CollegeBank from "../../ScholarManagement/BankDetails/CollegeBank";
import HostelBank from "../../ScholarManagement/BankDetails/HostelBank";
import MessBank from "../../ScholarManagement/BankDetails/MessBank";
import ParentBank from "../../ScholarManagement/BankDetails/ParentsBank";
import PersonalBank from "../../ScholarManagement/BankDetails/PersonalBank";
import ViewExpense from "../../ScholarManagement/Expense/ViewExpense";
import CollegeInformation from "../../ScholarManagement/Information/CollegeInformation";
import CourseInformation from "../../ScholarManagement/Information/CourseInformation";
import ResultHistory from "../../ScholarManagement/Result/ResultHistory";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default function ViewStudentDetail() {
  const history = useHistory();
  const { studentData } = React.useContext(store);
  //
  const theme = useTheme();
  const [tab, settab] = React.useState(0);
  const UpdateSorted = (data) => {
    PostApiCall.postRequest(
      {
        studentId: data.fld_id,
        studentStatus: "Sorted",
        type: "Student",
        typeLogin: "Student",
        email: data.fld_email,
        sortedDate: moment().format("lll"),
      },
      "Admin_Update_AdminApprovedStatus"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Student Shortlisted.");
          history.goBack();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  const UpdateApproved = (data) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        studentId: data.fld_id,
        studentStatus: "Approved",
        type: "Scholar",
        typeLogin: "Scholar",
        email: data.fld_email,
        sortedDate: moment().format("lll"),
      },
      "Admin_Update_AdminApprovedStatus"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Student Approved.");
          history.goBack();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  const UpdateReject = (data) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        studentId: data.fld_id,
        studentStatus: "Rejected",
        type: "Rejected",
        typeLogin: "Rejected",
        email: data.fld_email,
      },
      "Admin_Update_AdminRejectStatus"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Student Rejected.");
          history.goBack();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  if (studentData?.type == "student") {
    return (
      <>

        <div className="card-vahani mb-4 d-flex justify-content-between ">
          <h5>Student Id : {studentData.student.fld_studentId} | Name : {studentData.student.fld_full_name} </h5>
          <span
            className="btn btn-primary"
            style={{
              color: "white",
              backgroundColor: "rgb(32, 101, 209)",
              borderRadius: "10px",
              border: "none",
            }}
            onClick={() => history.push('/student-list-search')}
          >
            Back
          </span>
        </div>
        <AppBar position="static">
          <Tabs
            // className="css-1hw9j7s"
            value={tab}
            onChange={(event, newValue) => settab(newValue)}
            textColor="inherit"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Personal Details" />
            <Tab label="Family Details" />
            <Tab label="Address" />
            <Tab label="Academic" />
            <Tab label="Activities" />
            <Tab label="Preference" />
            <Tab label="Essay" />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={tab}
          onChangeIndex={(index) => settab(index)}
        >
          <TabPanel value={tab} index={0} dir={theme.direction}>
            <PersonalInfo
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
          </TabPanel>
          <TabPanel value={tab} index={1} dir={theme.direction}>
            <Familyinfo
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
            <div className="mt-5">
              <AdditionalFamilyInfo
                stdid={studentData?.student.fld_id}
                action={studentData?.action}
              />
            </div>
          </TabPanel>
          <TabPanel value={tab} index={2} dir={theme.direction}>
            <Address
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
          </TabPanel>
          <TabPanel value={tab} index={3} dir={theme.direction}>
            <SchoolInfo
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
            <div className="mt-5">
              <AcademicAchievement
                stdid={studentData?.student.fld_id}
                action={studentData?.action}
              />
            </div>
          </TabPanel>
          <TabPanel value={tab} index={4} dir={theme.direction}>
            <ExtraCurriculation
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
          </TabPanel>
          <TabPanel value={tab} index={5} dir={theme.direction}>
            <Preference
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
          </TabPanel>
          <TabPanel value={tab} index={6} dir={theme.direction}>
            <Essay
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
            <div className=" card-vahani mt-5 d-flex justify-content-end align-items-center">
              <div className="col-auto">
                {studentData.page == "New" && (
                  <button
                    className="btn btn-primary"
                    onClick={() => UpdateSorted(studentData?.student)}
                  >
                    Shortlist
                  </button>
                )}
                {(studentData.page == "Sortlisted" ||
                  studentData.page == "Rejected") && (
                    <button
                      className="btn btn-success mx-3"
                      onClick={() => UpdateApproved(studentData?.student)}
                    >
                      Approved
                    </button>
                  )}
                {(studentData.page == "Sortlisted" ||
                  studentData.page == "Approved") && (
                    <button
                      className="btn btn-danger"
                      onClick={() => UpdateReject(studentData?.student)}
                    >
                      Reject
                    </button>
                  )}
              </div>
            </div>
          </TabPanel>
        </SwipeableViews>
      </>
    );
  } else if (studentData?.type == "scholar") {
    return (
      <>

        <div className="card-vahani mb-4 d-flex justify-content-between ">
          <h5>Student Id : {studentData.student.fld_studentId} | Name : {studentData.student.fld_full_name} </h5>
          <span
            className="btn btn-primary"
            style={{
              color: "white",
              backgroundColor: "rgb(32, 101, 209)",
              borderRadius: "10px",
              border: "none",
            }}
            onClick={() => history.push('/scholar-list-search')}
          >
            Back
          </span>
        </div>
        <AppBar position="static">
          <Tabs
            // className="p-0 m-0"
            value={tab}
            onChange={(event, newValue) => settab(newValue)}
            textColor="inherit"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Personal Details" />
            <Tab label="Family Details" />
            <Tab label="Address" />
            <Tab label="Bank Details" />
            <Tab label="Academic" />
            <Tab label="Course/College Info" />
            <Tab label="Exam Result" />
            <Tab label="CV" />
            <Tab label="Accomodation" />
            <Tab label="Expense" />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={tab}
          onChangeIndex={(index) => settab(index)}
        >
          <TabPanel value={tab} index={0} dir={theme.direction}>
            <PersonalInfo
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
          </TabPanel>
          <TabPanel value={tab} index={1} dir={theme.direction}>
            <Familyinfo
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
            <div className="mt-5">
              <AdditionalFamilyInfo
                stdid={studentData?.student.fld_id}
                action={studentData?.action}
              />
            </div>
          </TabPanel>
          <TabPanel value={tab} index={2} dir={theme.direction}>
            <Address
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
          </TabPanel>
          <TabPanel value={tab} index={3} dir={theme.direction}>
            <PersonalBank
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
            <div className="mt-5">
              <ParentBank
                stdid={studentData?.student.fld_id}
                action={studentData?.action}
              />
            </div>
            <div className="mt-5">
              <CollegeBank
                stdid={studentData?.student.fld_id}
                action={studentData?.action}
              />
            </div>
            <div className="mt-5">
              <HostelBank
                stdid={studentData?.student.fld_id}
                action={studentData?.action}
              />
            </div>
            <div className="mt-5">
              <MessBank
                stdid={studentData?.student.fld_id}
                action={studentData?.action}
              />
            </div>
          </TabPanel>
          <TabPanel value={tab} index={4} dir={theme.direction}>
            <SchoolInfo
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
            <div className="mt-5">
              <AcademicAchievement
                stdid={studentData?.student.fld_id}
                action={studentData?.action}
              />
            </div>
          </TabPanel>
          <TabPanel value={tab} index={5} dir={theme.direction}>
            <CourseInformation
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
            <div className="mt-5">
              <CollegeInformation
                stdid={studentData?.student.fld_id}
                action={studentData?.action}
              />
            </div>
          </TabPanel>
          <TabPanel value={tab} index={6} dir={theme.direction}>
            <ResultHistory
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
          </TabPanel>
          <TabPanel value={tab} index={7} dir={theme.direction}>
            <Cv
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
          </TabPanel>
          <TabPanel value={tab} index={8} dir={theme.direction}>
            <AccomodationList
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
          </TabPanel>
          <TabPanel value={tab} index={9} dir={theme.direction}>
            <ViewExpense
              stdid={studentData?.student.fld_id}
              action={studentData?.action}
            />
          </TabPanel>
        </SwipeableViews>
      </>
    );
  } else {
    Notiflix.Notify.failure("Invalid Data");
    history.goBack();
  }
}
