import "../Personalinfo/Personalinfo.css";
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
// import GetApiCall from "../../../assets/API/GetApi";
import moment from "moment";
import Notiflix from "notiflix";
import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import store from "../../../store";
export default function ViewTicket() {
  const history = useHistory()
  const { ticketData } = useContext(store)
  const [answer, setanswer] = React.useState("");
  const [AnswerList, setAnswerList] = React.useState([]);
  let login = JSON.parse(localStorage.getItem("LoginDetail"))[0]
  //
  const fetchTicketData = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        studentId: ticketData.fld_studentid,
        questionId: ticketData.fld_id,
      },
      "Get_TicketAnswer_ById"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          setAnswerList(obj.data)
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!")
        }
      })
    );
  };
  React.useEffect(() => {
    //
    if (ticketData != null) {
      fetchTicketData();
    } else {
      history.goBack()
    }
  }, []);
  //
  return (
    <>
      <div style={{ marginBottom: "210px" }}>
        <div className="form-head">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>View Ticket Detail</h4>
            {/* <p style={{ margin: "0px", fontSize: "0.9rem" }}>
              Drop files here or click browse thorough your machine
            </p> */}
          </div>
        </div>
        <div className="card-vahani" style={{ marginTop: "24px" }}>
          <div className="row py-2">
            <div className="border rounded-3 pt-2">
              <p className="personal-info" >Your Query</p>
              <h3 className="text-primary">{ticketData?.fld_question}</h3>
            </div>
            <div class="col-md-12 p-0" style={{ marginTop: '3.5%' }}>
              <div class="form-group mb-2">
                {
                  AnswerList.map((data, index) => (
                    <div class="card mb-4"
                      style={{ backgroundColor: data.fld_updatedby == login.fld_id ? '#f3f4f7' : '#A9A9A9' }}>
                      <div class="card-body p-0">
                        <div class="media p-3">
                          <div class="media-body2">
                            <p style={{ color: data.fld_updatedby == login.fld_id ? '' : '#fff' }}>{data.fld_answer}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <div class="row m-0 p-0" style={{ display: ticketData?.fld_status == 'Closed' ? 'none' : '' }} >
              <div class="form-group p-0 mb-4">
                <textarea type="text" class="form-control" placeholder="Write your answer..."
                  value={answer}
                  onChange={e => setanswer(e.target.value)}>
                </textarea>
              </div>
              <div className={`row m-0 ${login.fld_AdminAccess ? "justify-content-between" : "justify-content-end"}`}>
                {login.fld_AdminAccess &&
                  <div className="but-width-form col-md-auto mb-md-0 mb-3">
                    <Button
                      variant="contained"
                      className="but-login-vahani mb-3 bg-secondary"
                      onClick={() => {
                        Notiflix.Loading.dots();
                        PostApiCall.postRequest(
                          {
                            id: ticketData.fld_id,
                            status: 'Closed'
                          },
                          "Update_TicketMessageStatus"
                        ).then((results) =>
                          results.json().then((obj) => {
                            if (results.status == 200 || results.status == 201) {
                              Notiflix.Loading.remove()
                              Notiflix.Notify.success('Ticket Closed.')
                              history.push("/ticket-list")
                            } else {
                              Notiflix.Loading.remove()
                              Notiflix.Notify.failure("There seems to be an Error. Please retry!")
                            }
                          })
                        )
                      }}
                    >
                      Closed
                    </Button>
                  </div>
                }
                <div className="but-width-form col-md-auto">
                  <Button
                    variant="contained"
                    className="but-login-vahani"
                    onClick={() => {
                      if (answer != '') {
                        Notiflix.Loading.dots();
                        PostApiCall.postRequest({
                          studentId: ticketData.fld_studentid,
                          questionId: ticketData.fld_id,
                          answer: `${ticketData.accessType} : ${answer}`,
                          updatedOn: moment().format('lll'),
                          updatedBy: login.fld_id,
                        }, "Add_TicketAmswer").then((result) =>
                          result.json().then(obj => {
                            if (result.status == 200 || result.status == 201) {
                              Notiflix.Loading.remove();
                              setanswer("")
                              Notiflix.Notify.success('Query Submitted Successfully')
                              fetchTicketData()
                            } else {
                              Notiflix.Loading.remove();
                              Notiflix.Notify.failure("There seems to be an Error. Please retry!")
                            }
                          })
                        )
                      }
                      else {
                        Notiflix.Notify.failure('Please enter query.')
                      }
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
