import React, { useState, useEffect } from 'react';
import PostApiCall from '../../../assets/API/PostApi';
import GetApiCall from '../../../assets/API/GetApi';
import Notiflix from 'notiflix';
import { InputLabel, MenuItem, FormControl, Select, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Menu, TextField, Tooltip, Box, Chip, OutlinedInput } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useHistory } from 'react-router-dom';
const ExpenseMaster = () => {
    const history = useHistory()
    const [ExpenseList, setExpenseList] = useState([]);
    const [ExpenseDropdown, setExpenseDropdown] = useState([]);
    const [ExpenseSelect, setExpenseSelect] = useState(null);
    const [FieldDropdown, setFieldDropdown] = useState([]);
    const [FieldForm, setFieldForm] = useState(null);
    const [showModal, setshowModal] = useState(false);
    const [addExpense, setaddExpense] = useState("");
    const [ExpenseBankData, setExpenseBankData] = useState([]);
    const GetExpenseDropdown = () => {
        Notiflix.Loading.dots()
        PostApiCall.postRequest(
            {
                whereClause: ``,
            },
            "Get_ExpenseDropdown"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    //
                    Notiflix.Loading.remove();
                    setExpenseDropdown(obj.data)
                }
            })
        )
    }
    const GetFieldDropdown = () => {
        Notiflix.Loading.dots()
        GetApiCall.getRequest(
            "Get_Expense_Field_Type_Master"
        ).then((result) =>
            result.json().then((obj) => {
                if (result.status === 200 || result.status === 201) {
                    //
                    Notiflix.Loading.remove();
                    setFieldDropdown(obj.data)
                }
                // Notiflix.Loading.Remove();
            })
        );
    }
    const GetExpenseData = (id) => {
        Notiflix.Loading.dots()
        PostApiCall.postRequest(
            {
                whereClause: `where fld_expense_type=${id} order by fld_sequence asc`,
            },
            "Get_Expense_Config_Master"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    //
                    setExpenseList(obj.data)
                    Notiflix.Loading.remove();
                }
            }))
    }
    useEffect(() => {
        GetExpenseDropdown()
        GetFieldDropdown()
    }, [])
    const UpdateExpenseFormField = () => {
        Notiflix.Loading.dots()
        PostApiCall.postRequest(
            {
                configid: FieldForm.configId == undefined ? null : FieldForm.configId,
                sequence: Number(FieldForm.seq) == undefined ? null : Number(FieldForm.seq),
                expensetype: ExpenseSelect,
                fieldname: FieldForm.name == undefined ? null : FieldForm.name,
                fieldtype: FieldForm.type == undefined ? null : FieldForm.type,
                value: FieldForm.values == undefined ? null : FieldForm.values,
                helptest: FieldForm.helper == undefined ? null : FieldForm.helper,
                errormessage: FieldForm.error == undefined ? null : FieldForm.error,
                mandatory: FieldForm.required == undefined ? "No" : FieldForm.required,
                status: "Inactive",
                errortest: null,
                createdon: null,
                updatedon: null,
            },
            "Update_Expense_Config_Master"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    //
                    GetExpenseData(ExpenseSelect)
                    setFieldForm({ seq: "", name: "", type: "", values: "", helper: "", error: "", required: "" })
                    Notiflix.Loading.remove();
                }
            })
        )
    }
    //
    return (
        <div className='row'>
            <div className="form-head mb-lg-2 mb-3">
                <div className='col-auto' style={{ borderLeft: "10px solid rgb(32, 101, 209)", paddingLeft: "10px", }} >
                    <h4>Expense Master</h4>
                </div>
            </div>
            <div className='row m-0 justify-content-between align-items-center'>
                <div className='col-md-6'>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Expense</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={FieldForm?.expenseType}
                            name="expenseType"
                            label="Select Expense"
                            onChange={e => {
                                setExpenseSelect(e.target.value)
                                GetExpenseData(e.target.value)
                            }}
                        >
                            {ExpenseDropdown.map(val => <MenuItem value={val.fld_expense_head_id}>{val.fld_expense_head}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                <div className='col-auto'>
                    <Button variant="contained" onClick={() => setshowModal(true)} >Create New +</Button>
                </div>
            </div>
            {ExpenseSelect != null &&
                <>
                    <table class="table table-responsive mt-5 border p-0 rounded-3">
                        <thead style={{ backgroundColor: "rgb(32, 101, 209)", color: "white" }}>
                            <tr>
                                {/* <th scope="col">Sr.</th> */}
                                <th scope="col" style={{ width: "5%" }}>Sequence</th>
                                <th scope="col">Field Name</th>
                                <th scope="col" style={{ width: "20%" }}>Field Type</th>
                                <th scope="col">Type's Value</th>
                                <th scope="col">Helper Text</th>
                                <th scope="col">Error Message</th>
                                <th scope="col">Required</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td><input type="number" name='seq' value={FieldForm?.seq} class="form-control" onChange={e => setFieldForm({ ...FieldForm, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control" name='name' value={FieldForm?.name} onChange={e => setFieldForm({ ...FieldForm, [e.target.name]: e.target.value })} /></td>
                                <td>
                                    <select id="inputState" class="form-select" name='type' value={FieldForm?.type} onChange={e => setFieldForm({ ...FieldForm, [e.target.name]: e.target.value })}>
                                        <option value="">choose...</option>
                                        {FieldDropdown.map(val => <option value={val.Name}>{val.Name}</option>)}
                                    </select>
                                </td>
                                <td><input type="text" class="form-control" name='values' value={FieldForm?.values} onChange={e => setFieldForm({ ...FieldForm, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control" name='helper' value={FieldForm?.helper} onChange={e => setFieldForm({ ...FieldForm, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control" name='error' value={FieldForm?.error} onChange={e => setFieldForm({ ...FieldForm, [e.target.name]: e.target.value })} /></td>
                                <td>
                                    <select class="form-select" name='checkbox' value={FieldForm?.required} onChange={e => setFieldForm({ ...FieldForm, required: e.target.value })} >
                                        <option value="">choose...</option>?
                                        <option value="Yes" selected={FieldForm?.required == "Yes"}>Yes</option>
                                        <option value="No" selected={FieldForm?.required == "No"}>No</option>
                                    </select>
                                </td>
                                <td><AddCircleIcon color='primary' onClick={() => {
                                    if (FieldForm != null) {
                                        UpdateExpenseFormField()
                                    } else {
                                        Notiflix.Notify.failure("Please Fill Form")
                                    }
                                }} /></td>
                            </tr>
                            {ExpenseList.map(item => {
                                //
                                return (
                                    <tr>
                                        {/* <th scope="row">{i + 1}</th> */}
                                        <td>{item.fld_sequence}</td>
                                        <td>{item.fld_field_name}</td>
                                        <td>{item.fld_field_type}</td>
                                        <td>{item.fld_value}</td>
                                        <td>{item.fld_help_test}</td>
                                        <td>{item.fld_error_message}</td>
                                        <td>{item.fld_mandatory}</td>
                                        <td>
                                            <ModeEditOutlineIcon color='primary' onClick={() => setFieldForm({ configId: item.fld_config_id, seq: item.fld_sequence, name: item.fld_field_name, type: item.fld_field_type, values: item.fld_value, helper: item.fld_help_test, error: item.fld_error_message, required: item.fld_mandatory == "Yes" ? true : false })} />
                                            {item.fld_status == "Inactive" && <DeleteForeverIcon style={{ color: "red" }} onClick={() => {
                                                PostApiCall.postRequest(
                                                    {
                                                        configid: item.fld_config_id
                                                    },
                                                    "Delete_Expense_Config_master"
                                                ).then((results) =>
                                                    results.json().then((obj) => {
                                                        if (results.status === 200 || results.status === 201) {
                                                            //
                                                            Notiflix.Loading.remove();
                                                            GetExpenseData(ExpenseSelect)
                                                        }
                                                    })
                                                )
                                            }} />}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className='row justify-content-end m-0 mt-3'>
                        <button className=' col-auto btn btn-primary' onClick={() => history.push(`/expense-master-preview/${ExpenseSelect}`)}>Continue</button>
                    </div>
                </>
            }
            <Dialog
                open={showModal}
                maxWidth={"lg"}
                minWidth={"lg"}
                onClose={() => setshowModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className='d-flex justify-content-between' id="alert-dialog-title"> <h2>Add New Expense</h2> <CancelIcon className='cursor-pointer ms-md-5 ms-0' onClick={() => setshowModal(false)} /></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className=' row m-0 mt-1 text-center card-vahani'>
                            <TextField
                                fullWidth
                                type="text"
                                label="New Expense*"
                                value={addExpense}
                                // InputLabelProps={{ shrink: true, }}
                                onChange={e => setaddExpense(e.target.value)}
                            />
                            <FormControl fullWidth className='mt-3'>
                                <InputLabel id="demo-multiple-chip-label">Add Bank Type</InputLabel>
                                <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={ExpenseBankData}
                                    onChange={e => setExpenseBankData(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                                    input={<OutlinedInput id="select-multiple-chip" label="Add Bank Type" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (<Chip key={value} label={value} />))}
                                        </Box>
                                    )}
                                    MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 10, width: 250, } } }}
                                >
                                    <MenuItem value="Personal" >Personal</MenuItem>
                                    <MenuItem value="Parents" >Parents</MenuItem>
                                    <MenuItem value="PG" >PG</MenuItem>
                                    <MenuItem value="Collage" >College</MenuItem>
                                </Select>
                            </FormControl>
                            <div className='col-12 text-end mt-3'>
                                <button className='btn btn-primary col-auto' onClick={async () => {
                                    if (addExpense != "") {
                                        //
                                        if (ExpenseBankData.length > 0) {
                                            PostApiCall.postRequest(
                                                {
                                                    expenseid: null,
                                                    expensehead: addExpense,
                                                    description: null,
                                                    uploadfoldername: null,
                                                    banktransfer: ExpenseBankData.toString(),
                                                    status: 'Active',
                                                    updatedon: null,
                                                    createdon: null,
                                                },
                                                "Admin_Update_Expense_Master"
                                            ).then((results) =>
                                                results.json().then((obj) => {
                                                    if (results.status === 200 || results.status === 201) {
                                                        Notiflix.Loading.remove();
                                                        Notiflix.Notify.success("Saved and Updated Successfully");
                                                        setshowModal(false)
                                                        GetExpenseDropdown()
                                                        //
                                                    } else {
                                                        Notiflix.Notify.failure("There seems to be an Error. Please retry!")
                                                        Notiflix.Loading.remove();
                                                    }
                                                }))
                                        } else {
                                            Notiflix.Notify.failure("Select Any One Bank Name.")
                                        }
                                    } else {
                                        Notiflix.Notify.failure("Enter New Expense.")
                                    }
                                }}>Add</button>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default ExpenseMaster