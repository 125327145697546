import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import VerifiedIcon from "@mui/icons-material/Verified";
import Notiflix from "notiflix";
import PostApiCall from "../../../assets/API/PostApi";
import CreditCard from "../../../assets/Image/credit-card2.jpg";
import store from "../../../store";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Paper,
  Table,
  Breadcrumbs,
  Link,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  FormControl,
} from "@mui/material";
import moment from "moment";

const ApprovalBox = () => {
  const history = useHistory();
  const { setexpenseData, expenseData, NotificationData } = useContext(store);
  const [showalert, setshowalert] = useState(false);
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const [reason, setreason] = useState(null);
  const [ConditionType, setConditionType] = useState("Open");
  const [approvalList, setapprovalList] = useState([]);
  const [Cards, setCards] = useState([]);
  const [SelectCard, setSelectCard] = useState({});
  const [ReciptFile, setReciptFile] = useState(null);
  const [BankForm, setBankForm] = useState(null);
  const [ReceiptImage, setReceiptImage] = useState("/upload.jpg");
  const [expenseFields, setexpenseFields] = useState([]);
  const [expenseFields1, setexpenseFields1] = useState([]);
  const [NewExpenseList, setNewExpenseList] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [expenseDetail, setexpenseDetail] = useState(null);
  const [showHideModal, setshowHideModal] = useState(false);
  const [holdReason, setholdReason] = useState("");
  let bankdetaillist = Cards.filter(
    (ele, ind) =>
      ind ===
      Cards.findIndex(
        (elem) =>
          elem.BankOf === ele.BankOf &&
          elem.fld_accountNum === ele.fld_accountNum
      )
  );
  const BankDetailScholar = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        studentId: expenseData.data.fld_studentId,
        expenseid: null,
      },
      "Get_ScholarBankDetailAll"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setCards(obj.data);
          //
          Notiflix.Loading.remove();
        }
      })
    );
    Notiflix.Loading.remove();
  };
  const getScholarExpenseDetails = (data) => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_id=${data.fld_id} and fld_studentId=${data.fld_studentId} order by fld_sequence asc`,
      },
      "Get_Scholar_Expense_Details"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          //
          setexpenseFields1(
            obj.data.map((val) => ({
              fld_field_name: val.fld_field_name,
              fld_value: val.fld_answer_formated,
            }))
          );
          Notiflix.Loading.remove();
        }
      })
    );
  };
  const getApprovalList = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        // whereClause: `where exists(select 1 from udv_expenseapprovalhistory eh where eh.fld_expense_id = ei.fld_id and eh.fld_pending_with='${login.fld_type}' and eh.fld_status = 'Open') order by fld_id desc`,
        whereClause: `where fld_approval_status='${login.fld_type == "Checker"
          ? "Pending"
          : login.fld_type == "Approver"
            ? "Verified"
            : login.fld_type == "Accountant"
              ? "Approved"
              : null
          }' order by fld_id desc `,
      },
      "Get_ScholarExpenseInformation"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          //
          setNewExpenseList(obj.data);
        }
        Notiflix.Loading.remove();
      })
    );
  };
  const FetchApprovalHistory = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_expense_id=${expenseData.data.fld_id} order by fld_approval_id asc`,
      },
      "Get_ScholarExpenseApprovalHistory"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          //
          // var approvalArr = [];
          // for (var i = 0; i < obj.data.length; i++) {
          //   if (obj.data[i].fld_action != "Rejected") {
          //     if (obj.data[i].fld_action == null) {
          //       approvalArr.push(obj.data[i]);
          //       break;
          //     } else {
          //       approvalArr.push(obj.data[i]);
          //     }
          //   } else {
          //     approvalArr.push(obj.data[i]);
          //     break;
          //   }
          // }
          // console.log(approvalArr);
          setapprovalList(obj.data);

          // setshowApprovalBox(obj.data.find(val => val.fld_approver_id == login.fld_id)?.fld_action == null ? true : false)
        }
      })
    );
    Notiflix.Loading.remove();
  };
  const Getlast3expense = (studentid, expenseid) => {
    PostApiCall.postRequest(
      {
        studentid,
        expenseid,
      },
      "Get_Last_Three_Expense_Student"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          obj.data.map((data1, i) => {
            PostApiCall.postRequest(
              {
                whereClause: `where fld_id=${data1.fld_id} and fld_studentId=${data1.fld_studentId} order by fld_sequence asc`,
              },
              "Get_Scholar_Expense_Details"
            ).then((results) =>
              results.json().then((obj1) => {
                if (results.status === 200 || results.status === 201) {
                  obj.data[i].fld_start_date = obj1.data[0].fld_answer_formated;

                  obj.data[i].fld_end_date = obj1.data[1].fld_answer_formated;

                  Notiflix.Loading.remove();
                }
              })
            );
          });
          setexpenseFields(obj.data);
          Notiflix.Loading.remove();
        }
      })
    );
  };
  useEffect(() => {
    FetchApprovalHistory();
    BankDetailScholar();
    getScholarExpenseDetails(expenseData.data);
    getApprovalList(expenseData.data.fld_studentId, expenseData.data.fld_id);
    Getlast3expense(expenseData.data.fld_studentId, expenseData.data.fld_id);
  }, []);
  useEffect(() => {
    if (expenseData?.adminAction) {
      setConditionType(expenseData.adminAction);
      getApprovalList(expenseData.adminAction);
    } else {
      if (login.fld_type == "Checker") {
        setConditionType("Pending");
        getApprovalList("Pending");
      } else if (login.fld_type == "Approver") {
        setConditionType("Verified");
        getApprovalList("Verified");
      } else if (login.fld_type == "Accountant") {
        setConditionType("Approved");
        getApprovalList("Approved");
      }
    }
  }, []);
  // send an approval mail
  const ScholarPaymentMailer = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        transactionAmount: expenseData.data.fld_feeAmount,
        updatedOn: moment().format("DD/MM/YYYY"),
        payeenName: BankForm.fld_accountName,
        scholarName: expenseData.data.full_name,
        email: expenseData.data.fld_email,
        comments: reason,
        expenseName: expenseData.data.fld_expense_type,
        studentid: expenseData.data.fld_studentId,
        // id : request.body.id,
      },
      "Scholar_Payment_Mailer"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Mail Sent Successfully to Scholar.");
          history.push("/approval-list"); // route back to list
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Something went wrong. Please try again.");
        }
      })
    );
  };
  // send a rejection mail
  const ScholarRejectMailer = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        expenseAmount: expenseData.data.fld_feeAmount,
        expenseType: expenseData.data.fld_expense_type,
        rejectPerson: login.full_name,
        scholarName: expenseData.data.full_name,
        comments: reason,
        email: expenseData.data.fld_email,
        expenseDate: moment().format("DD/MM/YYYY"),
      },
      "Scholar_Rejected_Mailer"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Mail Sent Successfully to Scholar.");
          history.push("/approval-list"); // finally route back to the list || this part was failing because of the mailer
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Something went wrong. Please try again.");
        }
      })
    );
  };

  // function to accept ot reject a request.
  const saveForm = (action) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        expenseid: expenseData.data.fld_id,
        actionby: login.fld_id,
        post: login.fld_type,
        comments: reason,
        action: action,
      },
      "Update_ScholarExpenseStatus"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Loading.remove();
          if (action == "Paid") {
            Notiflix.Notify.success("Expense Status Updated Successfully.");
            Notiflix.Loading.remove();
            ScholarPaymentMailer();
          } else if (action == "Rejected") {
            Notiflix.Notify.success("Expense Status Updated Successfully.");
            // history.push("/approval-list") // alternative of list pushing
            Notiflix.Loading.remove();
            ScholarRejectMailer(); // currently failing
          } else {
            Notiflix.Notify.success("Expense Status Updated Successfully.");
            history.push("/approval-list"); // always on admin side (need not dynamically route to list) || currently failing
            Notiflix.Loading.remove();
          }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Something went wrong. Please try again.");
        }
      })
    );
  };

  const SaveFinanceform = ({ url }) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: expenseData.data.fld_id,
        adminReceipt: url,
        paymentStatus: "Paid",
        transferDate: Date(),
        bankHolderName: BankForm.fld_accountName,
        bankName: BankForm.fld_bankName,
        bankAccountNumber: BankForm.fld_accountNum,
        ifscCode: BankForm.fld_ifscCode,
        remarks: BankForm.Remark == undefined ? null : BankForm.Remark,
        loginId: BankForm.fld_loginId,
        password: BankForm.fld_password,
        portalUrl: BankForm.fld_portalUrl,
        updatedon: moment().format("lll"),
        updatedby: login.fld_id,
      },
      "Update_ScholarExpenseMasterFinance"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          saveForm("Paid");
          Notiflix.Loading.remove();
          history.push("/approval-list");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Something went wrong. Please try again.");
        }
      })
    );
  };
  //
  return (
    <>
      {login.fld_type == "Accountant" ? (
        expenseData.adminAction == "Approved" ? (
          <div className="row m-0 mt-5 card-vahani">
            <div className="row m-0 justify-content-between my-5">
              {bankdetaillist.map((item) => {
                return (
                  <div
                    className="bank-card col-5 p-4 text-white mb-3 "
                    onClick={() => setBankForm(item)}
                    style={{
                      backgroundImage: `url('${CreditCard}')`,
                      backgroundSize: "100% 100%",
                      height: "auto",
                    }}
                  >
                    {item.fld_accountNum == null ||
                      item.fld_accountNum == "" ? (
                      <>
                        <div className={`d-flex justify-content-between`}>
                          <div>
                            <span className="me-2">{item.BankOf}</span>
                            {BankForm?.fld_loginId == item.fld_loginId && (
                              <VerifiedIcon />
                            )}
                          </div>
                          <h6>Login Id : {item.fld_loginId}</h6>
                        </div>
                        <h6 className="text-end my-4">
                          Password : {item.fld_password}
                        </h6>
                        <h6 className="my-4" style={{ wordWrap: "break-word" }}>
                          {item.fld_portalUrl}
                        </h6>
                      </>
                    ) : (
                      <>
                        <div className={`d-flex justify-content-between`}>
                          <div>
                            <span className="me-2">{item.BankOf}</span>
                            {BankForm?.fld_accountNum ==
                              item.fld_accountNum && <VerifiedIcon />}
                          </div>
                          <h4 style={{ wordBreak: "break-word" }}>
                            {item.fld_bankName}
                          </h4>
                        </div>
                        <h5 className="text-end my-4">
                          {item.fld_accountNum != ""
                            ? item.fld_accountNum?.match(/.{1,4}/g).join(" ")
                            : ""}
                        </h5>
                        <div
                          className="row m-0 justify-content-between"
                          style={{ fontSize: "14px" }}
                        >
                          <p
                            className="col-6 p-0"
                            style={{ wordWrap: "break-word" }}
                          >
                            {item.fld_accountName}
                          </p>
                          <div className="col-6 p-0 text-end">
                            <span>Branch : {item.fld_branchName}</span>
                            <br />
                            <span>IFSC : {item.fld_ifscCode}</span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
            {BankForm && (
              <>
                {BankForm.fld_accountNum == null ||
                  BankForm.fld_accountNum == "" ? (
                  <>
                    <div className="col-12 mb-3">
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Portal Url"
                        variant="outlined"
                        disabled
                        value={BankForm.fld_portalUrl}
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Login ID"
                        variant="outlined"
                        disabled
                        value={BankForm.fld_loginId}
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        disabled
                        value={BankForm.fld_password}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-6 mb-3">
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Account Number"
                        variant="outlined"
                        disabled
                        value={BankForm.fld_accountNum}
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Account Holder Name"
                        variant="outlined"
                        disabled
                        value={BankForm.fld_accountName}
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Bank Name"
                        variant="outlined"
                        disabled
                        value={BankForm.fld_bankName}
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="IFSC Code"
                        variant="outlined"
                        disabled
                        value={BankForm.fld_ifscCode}
                      />
                    </div>
                  </>
                )}
                <div className="col-md-6 mb-3">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Remark"
                    variant="outlined"
                    multiline
                    rows="9"
                    value={BankForm.Remark}
                    onChange={(e) =>
                      setBankForm({ ...BankForm, Remark: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-6 mb-3 mx-auto">
                  <p>
                    Payment Transfer Receipt{" "}
                    <small className="text-secondary">
                      (Max Size 2MB File Format *.jpeg, *.jpg, *.png, *.pdf)
                    </small>
                  </p>
                  <div
                    style={{
                      width: "200px",
                      height: "200px",
                      border: "1px solid gray",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src={ReceiptImage}
                      width="100%"
                      height="100%"
                      onClick={() =>
                        document.querySelector("#checkphoto").click()
                      }
                    />
                  </div>

                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="checkphoto"
                    onChange={(e) => {
                      //
                      setReciptFile(e.target.files[0]);
                      setReceiptImage(URL.createObjectURL(e.target.files[0]));
                    }}
                  />
                </div>
              </>
            )}
            {bankdetaillist.length > 0 ? (
              <div className="col-12 text-end">
                <Button
                  variant="contained"
                  className="btn btn-danger"
                  onClick={async () => {
                    if (ReciptFile != null) {
                      if (ReciptFile.size < 6000000) {
                        if (
                          ReciptFile.type == "image/png" ||
                          ReciptFile.type == "image/jpg" ||
                          ReciptFile.type == "image/jpeg" ||
                          ReciptFile.type == "application/pdf"
                        ) {
                          Notiflix.Loading.dots();
                          const form = new FormData();
                          let path = `${expenseData.data.fld_id}-${Math.floor(
                            Math.random() * 1000000
                          )}-${ReciptFile.name}`;
                          form.append("file", ReciptFile);
                          form.append("foldername", "FinanceAccounts");
                          form.append("filename", path);
                          const res = await fetch(
                            "https://admin.vahanischolarship.in/API/AddImage2",
                            { method: "POST", body: form }
                          );
                          const response = await res.json();
                          if (response) {
                            Notiflix.Loading.remove();
                            Notiflix.Notify.success(
                              "Receipt Uploaded Successfully."
                            );
                            SaveFinanceform({
                              url: `https://admin.vahanischolarship.in/Images/FinanceAccounts/${path}`,
                            });
                          } else {
                            Notiflix.Loading.remove();
                            Notiflix.Notify.failure("Receipt is not uploaded.");
                          }
                        } else {
                          Notiflix.Notify.failure(
                            "Image should be in jpeg, jpg or png format"
                          );
                        }
                      } else {
                        Notiflix.Notify.failure(
                          "File too large, upload file less than 2 MB."
                        );
                      }
                    } else {
                      Notiflix.Notify.failure("Please Upload Receipt.");
                    }
                  }}
                >
                  {" "}
                  Submit{" "}
                </Button>
              </div>
            ) : (
              <div className="text-danger fs-3 ">
                No Bank Details Found regarding this Expense
              </div>
            )}
          </div>
        ) : (
          <>
            {expenseData.adminAction == "Receipt Uploaded" && (
              <div className="row m-0 mt-4 card-vahani">
                <div className="col-md-6 mb-3 text-center">
                  <p>Payment Transfer Receipt </p>
                  <div className="border border-2 border-secondary p-2 rounded-2">
                    <img
                      src={expenseData.data.fld_adminReceipt}
                      width="100%"
                      height="100%"
                    />
                    <a
                      className="btn btn-primary mt-2"
                      href={expenseData.data.fld_adminReceipt}
                      target="_blank"
                    >
                      View
                    </a>
                  </div>
                </div>
                <div className="col-md-6 mb-3 text-center">
                  <p>Payment Bill Receipt </p>
                  <div className="border border-2 border-secondary p-2 rounded-2">
                    <img
                      src={expenseData.data.fld_billReceipt}
                      width="100%"
                      height="100%"
                    />
                    <a
                      className="btn btn-primary mt-2 "
                      href={expenseData.data.fld_billReceipt}
                      target="_blank"
                    >
                      View
                    </a>
                  </div>
                </div>
                <div className="row m-0 mt-3 justify-content-between card-vahani ">
                  <div className="col-md-6 mb-3">
                    <TextField
                      id="outlined-basic"
                      label="Reason.."
                      value={reason}
                      onChange={(e) => setreason(e.target.value)}
                      variant="outlined"
                      rows={4}
                      multiline
                      fullWidth
                    />
                  </div>
                  <div className="col-md-5 row m-0 justify-content-between align-items-start ">
                    <Button
                      className="col-md-auto text-capitalize"
                      variant="contained"
                      color="success"
                      onClick={() => {
                        saveForm("Closed");
                      }}
                    >
                      {" "}
                      Confirm And Closed{" "}
                    </Button>
                    <Button
                      className="col-md-auto mt-md-0 mt-3 text-capitalize"
                      variant="contained"
                      color="error"
                      onClick={() => {
                        if (reason != null) {
                          Notiflix.Loading.dots();
                          PostApiCall.postRequest(
                            {
                              approvalid: null,
                              expenseid: expenseData.data.fld_id,
                              approverid: login.fld_id,
                              action: "Receipt Not Approved",
                              comments: reason,
                              actiondate: null,
                              pendingwith: "Student",
                              sequence: 0,
                            },
                            "Update_ScholarExpenseApprovalHistory"
                          ).then((results) =>
                            results.json().then((obj) => {
                              if (
                                results.status == 200 ||
                                results.status == 201
                              ) {
                                Notiflix.Loading.remove();
                                history.push("/approval-list");
                              }
                              Notiflix.Loading.remove();
                            })
                          );
                          Notiflix.Loading.remove();
                        } else {
                          Notiflix.Notify.failure("Enter Reason");
                        }
                      }}
                    >
                      {" "}
                      Receipt Not Approved{" "}
                    </Button>
                  </div>
                </div>
                {/* <div className='row m-0 justify-content-between'>
                                    <div className='col-md-4'>
                                    <TextField id="outlined-basic" label="Reason.." value={reason} onChange={e => setreason(e.target.value)} variant="outlined" rows={4} multiline fullWidth />
                                    </div>
                                    <button className='btn btn-success col-auto' onClick={() => { saveForm("Closed") }}>Confirm And Closed</button>
                                </div> */}
              </div>
            )}
          </>
        )
      ) : (
        ((login.fld_type == "Checker" &&
          expenseData.adminAction == "Pending") ||
          (login.fld_type == "Approver" &&
            expenseData.adminAction == "Verified")) && (
          <div className="row m-0 mt-5 justify-content-between card-vahani ">
            <div className="col-md-6 mb-3">
              <TextField
                id="outlined-basic"
                label="Reason.."
                value={reason}
                onChange={(e) => setreason(e.target.value)}
                variant="outlined"
                rows={4}
                multiline
                fullWidth
              />
            </div>
            {expenseData.data.fld_hold == "true" ? (
              <div className="col-md-3 d-flex justify-content-between align-items-start ">
                <button className="btn btn-success" disabled>
                  Approve
                </button>
                <button className="btn btn-danger" disabled>
                  Reject
                </button>
              </div>
            ) : (
              <div className="col-md-3 d-flex justify-content-between align-items-start ">
                <button
                  className="btn btn-success"
                  onClick={() => setshowalert(true)}
                >
                  {" "}
                  Approve{" "}
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    if (reason != null) {
                      saveForm("Rejected");
                    } else {
                      Notiflix.Notify.failure("Enter Reason");
                    }
                  }}
                >
                  {" "}
                  Reject{" "}
                </button>
              </div>
            )}
          </div>
        )
      )}
      <div className=" mt-5 card-vahani p-0 ">
        <div className="personal-info left-line-card px-lg-4 px-4 py-4">
          Last 3 Requests of {expenseData.data.fld_expense_type}
        </div>
        <div className="p-4 row m-0 ">
          {expenseFields.length > 0 ? (
            expenseFields.map((val, i) => {
              return (
                <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-3">
                  <div className="card-hover p-3 last-expense-card w-100">
                    <div className="w-100">
                      <div className="d-flex justify-content-between quick-view">
                        <h6 className="personal-info p-0">
                          {val.fld_expense_type}
                        </h6>
                        <h6
                          style={{ fontWeight: 700, fontSize: "20px" }}
                          className={` ${val.fld_approval_status == "Closed"
                            ? "text-success"
                            : val.fld_approval_status == "Rejected"
                              ? "text-danger"
                              : "text-warning"
                            }`}
                        >
                          {val.fld_approval_status}{" "}
                        </h6>
                        {/* <Tooltip title="Quick View">
                          <VisibilityIcon
                            className="text-primary cursor-pointer mx-2"
                            onClick={() => {
                              getScholarExpenseDetails(val);
                              setshowModal(true);
                              setexpenseDetail(val);
                            }}
                          />
                        </Tooltip> */}
                      </div>
                      <span style={{ fontSize: "14px", color: "GrayText" }}>
                        Txn ID : {val.fld_id}
                      </span>
                      <br />
                      <span style={{ fontSize: "14px", color: "GrayText", whiteSpace: "pre" }}>
                        Txn Payment Date : {val.fld_transferDate}
                      </span>
                      <br />

                      <span style={{ fontSize: "14px", color: "GrayText" }}>
                        Start Date : {val?.fld_start_date}
                      </span>
                      <br />
                      <span style={{ fontSize: "14px", color: "GrayText" }}>
                        End Date : {val?.fld_end_date}
                      </span>
                      <br />

                      <span style={{ fontSize: "14px", color: "GrayText" }}>
                        Amount (₹) : {val.fld_feeAmount}/-
                      </span>
                      <br />

                      <span style={{ fontSize: "14px", color: "GrayText" }}>
                        Amount In Words: {val.amtInWords}/-
                      </span>
                      <br />
                      <span style={{ fontSize: "14px", color: "GrayText" }}>
                        Pay To : {val.payto}
                      </span>
                      <br />
                      <span style={{ fontSize: "14px", color: "GrayText" }}>
                        Raised On :{" "}
                        {moment(val.fld_requestedon).format("DD/MM/YYYY")}
                      </span>
                      <br />
                      <span style={{ fontSize: "14px", color: "GrayText" }}>
                        Verified On : {moment(val.fld_verified_date).format("DD/MM/YYYY")}
                      </span>
                      <br />
                      <span style={{ fontSize: "14px", color: "GrayText" }}>
                        Approved By : {val.fld_approved_by} <br /> Approval Date
                        : {moment(val.fld_approval_date).format("DD/MM/YYYY")}
                      </span>
                      <br />
                      <span style={{ fontSize: "14px", color: "GrayText" }}>
                        Receipt Uploaded : {val.payto}
                      </span>
                      <br />
                      <span style={{ fontSize: "14px", color: "GrayText" }}>
                        Description : {val.fld_details}
                      </span>
                      <br />
                      <span style={{ fontSize: "14px", color: "GrayText" }}>
                        Comments : {val.fld_comments}
                      </span>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No Last 3 Expense Found</p>
          )}
        </div>
      </div>
      <Dialog
        open={showModal}
        maxWidth={"sm"}
        onClose={() => setshowModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className="d-flex justify-content-between"
          id="alert-dialog-title"
        >
          <h4 className="px-2" style={{ borderBottom: "5px solid blue" }}>
            Quick View
          </h4>{" "}
          <CancelIcon onClick={() => setshowModal(false)} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="row m-0 border  border-secondary rounded-3">
              <h5 className=" mt-2 mb-4 text-center">
                <b>Expense Type - </b>
                {expenseDetail?.fld_expense_type}
              </h5>

              <div className=" row m-0 justify-content-between mb-2 border-secondary border-bottom">
                <b className="col-6">Txn ID</b>
                <div className="col-6"> {expenseDetail?.fld_id}</div>
              </div>

              <div className=" row m-0 justify-content-between mb-2 border-secondary border-bottom">
                <b className="col-6">Txn Date</b>
                <div className="col-6"> {expenseDetail?.fld_transferDate}</div>
              </div>
              {expenseFields1.map((val) => {
                //
                return (
                  <div
                    className="row m-0 justify-content-between mb-2 border-secondary border-bottom"
                    style={{ wordBreak: "break-all" }}
                  >
                    <b className="col-6">{val?.fld_field_name}</b>
                    <div className="col-6">{val?.fld_value} </div>
                    {/* val?.fld_field_name.includes("Date") ? moment(val?.fld_value).format("DD/MM/YYYY") : */}
                  </div>
                );
              })}

              <div className=" row m-0 justify-content-between mb-2 border-secondary border-bottom">
                <b className="col-6">Amount (₹)</b>
                <div className="col-6">
                  {" "}
                  : {expenseDetail?.fld_feeAmount}/-{" "}
                </div>
              </div>
              <div className=" row m-0 justify-content-between mb-2 border-secondary border-bottom">
                <b className="col-6">Amount In Words</b>
                <div className="col-6"> : {expenseDetail?.amtInWords} </div>
              </div>
              <div className=" row m-0 justify-content-between mb-2 border-secondary border-bottom">
                <b className="col-6">Pay To</b>
                <div className="col-6"> : {expenseDetail?.payto} </div>
              </div>
              <div className=" row m-0 justify-content-between mb-2 border-secondary border-bottom">
                <b className="col-6">Description</b>
                <div className="col-6"> : {expenseDetail?.fld_details} </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {login.fld_type != "Accountant" && (
        <div className="row m-0 justify-content-between my-5 card-vahani">
          {bankdetaillist.map((item) => {
            return (
              <div
                className="bank-card col-lg-5 p-4 text-white mb-3"
                style={{
                  backgroundImage: `url('${CreditCard}')`,
                  backgroundSize: "100% 100%",
                  height: "auto",
                }}
              >
                {item.fld_accountNum == null || item.fld_accountNum == "" ? (
                  <>
                    <div className={`d-flex justify-content-between`}>
                      <span className="me-2">{item.BankOf}</span>
                      <h6>Login Id : {item.fld_loginId}</h6>
                    </div>
                    <h6 className="text-end my-4">
                      Password : {item.fld_password}
                    </h6>
                    <h6 className="my-4" style={{ wordWrap: "break-word" }}>
                      {item.fld_portalUrl}
                    </h6>
                  </>
                ) : (
                  <>
                    <div className={`d-flex justify-content-between`}>
                      <span className="me-2">{item.BankOf}</span>
                      <h4 style={{ wordBreak: "break-word" }}>
                        {item.fld_bankName}
                      </h4>
                    </div>
                    <h5 className="text-end my-4">
                      {item.fld_accountNum != ""
                        ? item.fld_accountNum?.match(/.{1,4}/g).join(" ")
                        : ""}
                    </h5>
                    <div
                      className="row m-0 justify-content-between"
                      style={{ fontSize: "14px" }}
                    >
                      <p
                        className="col-6 p-0"
                        style={{ wordWrap: "break-word" }}
                      >
                        {item.fld_accountName}
                      </p>
                      <div className="col-6 p-0 text-end">
                        <span>Branch : {item.fld_branchName}</span>
                        <br />
                        <span>IFSC : {item.fld_ifscCode}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      )}
      <div className=" mt-5 card-vahani p-0 ">
        <div className="personal-info left-line-card px-lg-4 px-4 py-4">
          Approval History
        </div>
        <div className="p-4 table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Sr. No.</th>
                <th scope="col">Expense Created On</th>
                <th scope="col">Approval Level</th>
                <th scope="col">Approval Status</th>
                <th scope="col">Approved/Rejected On</th>
                <th scope="col">Comments</th>
              </tr>
            </thead>
            <tbody>
              {approvalList.map((val, i) => {
                return (
                  <tr>
                    <th scope="row">{i + 1}</th>
                    <td>{moment(val.fld_created_on).format("DD/MM/YYYY")}</td>
                    <td>{val.fld_pending_with}</td>
                    <td>
                      <div>
                        <span>
                          {val.fld_action == null ? "Pending" : val.fld_action}
                        </span>
                        <br />
                        {val.approved_by != null && (
                          <span> By {val.approved_by}</span>
                        )}
                      </div>
                    </td>
                    <td>
                      {val.fld_action_date == null
                        ? ""
                        : moment(val.fld_action_date).format("DD/MM/YYYY")}
                    </td>
                    <td>{val.fld_comments}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {showalert && (
        <SweetAlert
          title="Are you sure you want to Approve ? "
          custom
          showCancel
          showCloseButton
          onCancel={() => setshowalert(false)}
          customButtons={
            <React.Fragment>
              <button
                onClick={() => setshowalert(false)}
                className="btn btn-danger mx-2"
              >
                Cancel
              </button>
              <button
                className="btn btn-success"
                onClick={() => {
                  saveForm(
                    login.fld_type == "Checker" ? "Verified" : "Approved"
                  );
                }}
              >
                Yes
              </button>
            </React.Fragment>
          }
        >
          {" "}
        </SweetAlert>
      )}
    </>
  );
};
export default ApprovalBox;
