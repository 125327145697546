import React, { useState, useEffect, useContext, createContext } from "react";
import Notiflix from "notiflix";
import moment from "moment";
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  Badge,
  AppBar,
} from "@mui/material";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { BsClockHistory } from "react-icons/bs";
import "../Adminpanel/Slider/Slider.css";
// import { BiSearch } from "react-icons/bi";
// import styled from "styled-components";
import "./Header.css";
import { FaBars } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import store from "../../store";
import logo from "../../assets/Image/vahanilogo2.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import LogoutIcon from '@mui/icons-material/Logout';
import GroupsIcon from "@mui/icons-material/Groups";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import PostApiCall from "../../assets/API/PostApi";
import { useHistory } from "react-router-dom";
import TrackQueryIcon from "../../assets/icons/help_desk.svg";
import NotificationIcon from "../../assets/icons/notification.svg";
import LogoutIcon from "../../assets/icons/logout.svg";
import HRMS from "../../assets/icons/hrm.svg";
import ChangeEmailIcon from "../../assets/icons/change_email.svg";
import ChangePasswordIcon from "../../assets/icons/change_password.svg";
import Login from "../../pages/Login/Login";
export const showd = createContext();

export default function EmployeeHeader() {
  const history = useHistory();
  const { setexpenseData, NotificationData } = useContext(store);
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const { drawerWidth, Showdraw, setShowdraw } = useContext(store);
  const [header, setHeader] = useState("header");
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open1 = Boolean(anchorEl);
  const [anchorE2, setAnchorE2] = React.useState(false);
  const open2 = Boolean(anchorE2);
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  const [salt, setSalt] = useState("");
  const handleClick = () => {
    setAnchorEl(!anchorEl);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClicknotification = () => {
    setAnchorE2(!anchorE2);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };
  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("header");
    } else if (window.scrollY > 70) {
      return setHeader("header2");
    }
  };
  const showdrawer = () => {
    setShowdraw(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  return (
    <>
      <div className="appbar-desktop">
        <AppBar
          className={header}
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
          }}
        >
          <div className="d-flex justify-content-end align-items-center">
            {/* {JSON.parse(localStorage.getItem("AccessAdmin")) == "Yes" ? (
              <>
                <Tooltip title="Tickets" className="mx-2">
                  <img
                    src={TrackQueryIcon}
                    className="sidebar-icon panel-header"
                    onClick={() => history.push("/ticket-list")}
                  />
                </Tooltip>
                {/* <Tooltip title="HRMS" className="mx-2">
                  <IconButton
                    onClick={() => {
                      // onLogin()
                      // window.location.href = "/"
                      // localStorage.clear()
                    }}
                    size="small"
                    aria-controls={open1 ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                  >
                    <img
                      src={HRMS}
                      className="sidebar-icon panel-header" />
                  </IconButton>
                </Tooltip> 
                <Tooltip title="Notifications" className="mx-2">
                  <IconButton
                    className="badge"
                    onClick={handleClicknotification}
                    size="small"
                    aria-controls={open2 ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                  >
                    <Badge
                      badgeContent={NotificationData.length}
                      color="primary"
                    >
                      <img
                        src={NotificationIcon}
                        className="sidebar-icon panel-header"
                      />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title="Track Your Query" className="mx-2">
                  <img
                    src={TrackQueryIcon}
                    className="sidebar-icon panel-header"
                    onClick={() => history.push("/ticket-history")}
                  />
                </Tooltip>
                {login.fld_type != "Student" && (
                  <Tooltip title="Notifications" className="mx-2">
                    <img
                      src={NotificationIcon}
                      className="sidebar-icon panel-header panel-header-notification"
                      onClick={() => history.push("/view-notification")}
                    />
                  </Tooltip>
                )}
              </>
            )} */}
            <Tooltip title="My Account">
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open1 ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
              >
                {/* <Avatar >{login.profile_pic}</Avatar> */}
                <Avatar
                  sx={{ width: 40, height: 40 }}
                  alt={login.fld_employee_full_name}
                  src={login.fld_employee_image}
                />
              </IconButton>
            </Tooltip>
          </div>
        </AppBar>
      </div>
      <Menu
        className="profile-popup"
        anchorEl={anchorEl}
        id="account-menu"
        open={open1}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 2,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div>
          <div className="profile-name1 text-center ">
            <p>You are Logged in as a</p>
            <h6>{login.fld_type}</h6>
          </div>
          <Divider />
          <div className="profile-name1">
            <p>{login.fld_employee_full_name}</p>
            <p>{login.fld_email}</p>
          </div>
          <Divider />
          <MenuItem
            onClick={() => {
              // console.log(login[0].fld_email);
              history.push("/change-password");
            }}
          >
            <img
              src={ChangePasswordIcon}
              className="sidebar-icon panel-header me-2"
            />
            Change Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              localStorage.clear();
              window.location.href = "/";
            }}
          >
            <img
              src={LogoutIcon}
              className="panel-header panel-header-notification gray me-2"
            />
            Logout{" "}
          </MenuItem>
        </div>
      </Menu>
      <Menu
        className="profile-popup2 scr-bar"
        anchorEl={anchorE2}
        id="account-menu"
        open={open2}
        onClose={handleClose2}
        onClick={handleClose2}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 2,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="profile-name1">
          <h6>Notification</h6>
          <p>You have {NotificationData.length} unread messages</p>
        </div>
        {/* <hr className="hr-di" /> */}
        <div className="hr-di"></div>
        {NotificationData.map((val) => {
          //
          return (
            <MenuItem
              className="border-bottom border-secondary"
              onClick={() => {
                Notiflix.Loading.dots();
                PostApiCall.postRequest(
                  {
                    whereClause: `where fld_id=${val.fld_expense_id} order by fld_id desc`,
                  },
                  "Get_ScholarExpenseInformation"
                ).then((results) =>
                  results.json().then((expobj) => {
                    if (results.status === 200 || results.status === 201) {
                      //
                      setexpenseData({
                        data: expobj.data[0],
                        action: "view",
                        source: "admin",
                        adminAction: "Open",
                      });
                      PostApiCall.postRequest(
                        {
                          id: val.fld_id,
                          status: "Closed",
                        },
                        "Update_Expense_Notification"
                      ).then((results) =>
                        results.json().then((expobj) => {
                          if (
                            results.status === 200 ||
                            results.status === 201
                          ) {
                            // //
                            history.push("/expense");
                            Notiflix.Loading.remove();
                          }
                        })
                      );
                    }
                    Notiflix.Loading.remove();
                  })
                );
              }}
            >
              <div>
                <h6 className="fw-bold" style={{ fontSize: "14px" }}>
                  {val.fld_title}
                </h6>
                <span className="d-block text-end" style={{ fontSize: "12px" }}>
                  {moment(val.fld_updatedOn).calendar()}
                </span>
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
