import React, { useState, useContext } from "react";
import { Table, Button, Select, FormControlLabel, Menu, MenuItem, FormHelperText, InputLabel, TextField, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow, Box, IconButton, CircularProgress, Typography } from "@mui/material";
import { MDBDataTable } from 'mdbreact';
import Notiflix from "notiflix";
import moment from 'moment'
import PostApiCall from "../../../assets/API/PostApi";
import GetApiCall from "../../../assets/API/GetApi";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useHistory, Link } from "react-router-dom";
import store from "../../../store";
export default function AccomodationList({ stdid, action }) {
    const history = useHistory();
    const { setaccomodationData } = useContext(store);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    var login = stdid ? { fld_id: stdid } : JSON.parse(localStorage.getItem("LoginDetail"))[0];
    const [list, setlist] = React.useState([]);
    const [ShowViewModal, setShowViewModal] = React.useState(null);
    const [SelectData, setSelectData] = React.useState(null);
    const [SearchInput, setSearchInput] = React.useState("");
    React.useEffect(() => {
        Notiflix.Loading.init({
            svgColor: "#060a4a",
        });
        GetNewStudentList()
    }, []);
    const GetNewStudentList = () => {
        Notiflix.Loading.dots();
        PostApiCall.postRequest(
            {
                studentId: login.fld_id
            },
            "Scholar_Get_AccomodationInformationListByID"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    //
                    setlist(obj.data)
                    Notiflix.Loading.remove();
                }
            }
            ))
    }
    return (
        <>
            <div className="form-head">
                <div style={{ borderLeft: "10px solid rgb(32, 101, 209)", paddingLeft: "10px", }} >
                    <h4>Accomodation List</h4>
                </div>
            </div>
            <div className="card-vahani p-0">
                <div className="row justify-content-between align-items-center m-0 left-line-card px-4 py-3 ">
                    <p className="personal-info m-0 p-0 col-auto ">Accomodation List</p>
                    {action != "view" && <Link className=' btn btn-primary col-auto' onClick={() => setaccomodationData({ action: "add" })} to='/add-accomodation' > Create New +</Link>}
                </div>
                <div className='px-4'>
                    {list.length > 0 ?
                        <MDBDataTable
                            disableRetreatAfterSorting={true}
                            responsive
                            hover
                            striped
                            data={
                                {
                                    columns: [
                                        {
                                            label: 'Accomodation Type',
                                            field: 'accType',
                                            sort: 'disabled',
                                        },
                                        {
                                            label: 'Rent Tpe',
                                            field: 'rentType',
                                            sort: 'disabled',
                                        },
                                        {
                                            label: 'Security Amount (₹)',
                                            field: 'securityamount',
                                            sort: 'disabled',
                                        },
                                        {
                                            label: 'Rent Amount (₹)',
                                            field: 'rentamount',
                                            sort: 'disabled',
                                        },
                                        {
                                            label: 'Start Date of Stay',
                                            field: 'startDate',
                                            sort: 'disabled',
                                        },
                                        {
                                            label: 'Due date of Rent',
                                            field: 'dueDate',
                                            sort: 'disabled',
                                        },
                                        {
                                            label: 'Action',
                                            field: 'action',
                                            sort: 'disabled',
                                        }
                                    ],
                                    rows: list.map((data, i) => {
                                        return {
                                            accType: data.fld_accomodationType,
                                            rentType: data.fld_rentType,
                                            securityamount: <p className="text-center">{data.fld_securityDeposit == null ? "" : `${data.fld_securityDeposit}/-`}</p>,
                                            rentamount: <p className="text-center">{data.fld_rentAmount == null ? "" : `${data.fld_rentAmount}/-`}</p>,
                                            startDate: data.fld_startToStay != null && moment(data.fld_startToStay).format('DD/MM/YYYY'),
                                            dueDate: data.fld_DueDate != null && moment(data.fld_DueDate).format('DD/MM/YYYY'),
                                            action: <Button id="basic-button" aria-controls={ShowViewModal ? 'basic-menu' : undefined}
                                                aria-haspopup="true" aria-expanded={ShowViewModal ? 'true' : undefined}
                                                onClick={e => {
                                                    setShowViewModal(e.currentTarget)
                                                    setSelectData(data)
                                                }}
                                            > <MoreHorizIcon />
                                            </Button>
                                        }
                                    })
                                }
                            }
                            entriesOptions={[10, 25, 50, 100]}
                        />
                        :
                        <p className="m-3">No Data Found</p>
                    }
                    <Menu
                        id="basic-menu"
                        anchorEl={ShowViewModal}
                        open={ShowViewModal}
                        onClose={() => setShowViewModal(null)}
                        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                    >
                        <MenuItem
                            onClick={() => {
                                setaccomodationData({ data: SelectData, action: "view" })
                                history.push(`/add-accomodation`)
                            }}
                        >View</MenuItem>
                        <MenuItem
                            onClick={() => {
                                setaccomodationData({ data: SelectData, action: "edit" })
                                history.push(`/add-accomodation`)
                            }}
                        >Edit</MenuItem>
                    </Menu>
                </div>
            </div>
        </>
    )
}
