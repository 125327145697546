import "../Personalinfo/Personalinfo.css";
import React, { useState, useEffect, } from "react";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
// import GetApiCall from "../../../assets/API/GetApi";
import Notiflix from "notiflix";
import { useHistory } from "react-router-dom";
import { TextField, Button, Dialog, DialogTitle, DialogContent } from "@mui/material";
export default function ResetEmail() {
  const history = useHistory()
  var login = JSON.parse(localStorage.getItem('LoginDetail'))[0];
  const [OtpData, setOtpData] = useState({ otp: "", confirmotp: "", otpModal: false });
  const [UserData, setUserData] = useState(null);
  const [ValidationList, setValidationList] = useState(JSON.parse(localStorage.getItem("CommonValidation")));
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id
      },
      "GetStudentStatusAndInformation_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setUserData(obj.data[0])
          setValue("OldEmail", obj.data[0].fld_email)
        }
      }
      ))
  }, [])
  const onSubmit = (data) => {
    if (data.NewEmail == data.ConfirmEmail) {
      if (data.ConfirmEmail != data.OldEmail) {
        Notiflix.Loading.dots();
        PostApiCall.postRequest({
          email: data.ConfirmEmail,
        }, "CheckEmailId").then((result) =>
          result.json().then(obj => {
            if (result.status == 200 || result.status == 201) {
              //
              SendOTP(data);
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure('Email id already register.')
            }
          })
        )
      } else {
        Notiflix.Notify.failure("New & Old Email should not be same. Enter another Email.")
      }
    } else {
      Notiflix.Notify.failure("New Email & Confirm Email does not match.")
    }
  };
  const SendOTP = data => {
    Notiflix.Loading.dots();
    let genOTP = Math.floor(100000 + Math.random() * 900000)
    //
    PostApiCall.postRequest({
      OTP: genOTP,
      name: UserData.fld_firstName + ' ' + UserData.fld_middleName + ' ' + UserData.fld_lastName,
      email: data.ConfirmEmail,
    }, "Mailer").then((result) =>
      result.json().then(obj => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success('OTP is sent successfully to your new email address.')
          setOtpData({ ...OtpData, otp: genOTP, otpModal: true })
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!")
        }
      })
    )
  }
  React.useEffect(() => {
    Notiflix.Loading.dots();
    fetchData();
    // statusupdate
  }, []);
  function fetchData() {
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetCommonValidation"
    ).then((results10) =>
      results10.json().then((obj10) => {
        if (results10.status === 200 || results10.status === 201) {
          //
          setValidationList(obj10.data)
          Notiflix.Loading.remove();
        }
        Notiflix.Loading.remove();
      })
    );
  };
  return (
    <>
      <div className="form-head">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Change Old Email Address</h4>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
          <p className="personal-info left-line-card px-lg-4 px-3 py-4">Change Old Email</p>
          <div className="row m-0 p-lg-4 p-2">
            <div className="col-lg-6 mb-3">
              <TextField
                disabled
                fullWidth
                label="Old Email*"
                {...register("OldEmail", {
                  required: "Please enter your Old Email",
                  pattern: {
                    value: new RegExp(ValidationList[1].fld_reg_ex, 'i'),
                    message: "Enter a valid email id",
                  }
                })}
                error={errors?.OldEmail}
                helperText={errors?.OldEmail ? errors.OldEmail.message : null}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <TextField
                fullWidth
                label="New Email*"
                {...register("NewEmail", {
                  required: "Enter your new email",
                  pattern: {
                    value: new RegExp(ValidationList[1].fld_reg_ex, 'i'),
                    message: "Enter a valid email id",
                  }
                })}
                error={errors?.NewEmail}
                helperText={errors?.NewEmail ? errors.NewEmail.message : null}
              // InputLabelProps={{ required: true }}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <TextField
                fullWidth
                label="Confirm New Email*"
                {...register("ConfirmEmail", {
                  required: "Enter your Confirm email",
                  pattern: {
                    value: new RegExp(ValidationList[1].fld_reg_ex, 'i'),
                    message: "Enter a valid email id",
                  }
                })}
                error={errors?.ConfirmEmail}
                helperText={errors?.ConfirmEmail ? errors.ConfirmEmail.message : null}
              // InputLabelProps={{ required: true }}
              />
            </div>
          </div>
        </div>
        <div className="card-vahani" style={{ marginTop: "26px" }}>
          <div style={{ display: "flex", justifyContent: "end", }} >
            <div className="but-width-form">
              <Button
                variant="contained"
                className="but-login-vahani"
                type="submit"
              >
                Save and update
              </Button>
            </div>
          </div>
        </div>
      </form>
      <Dialog onClose={() => setOtpData({ ...OtpData, otpModal: false })} open={OtpData.otpModal} fullWidth >
        <DialogTitle>Verify Your OTP</DialogTitle>
        <DialogContent dividers>
          <div className="row m-0 mt-3 justify-content-between align-items-center">
            <TextField label="Enter OTP" className="col-md-8" onChange={e => setOtpData({ ...OtpData, confirmotp: e.target.value })} />
            <button className="col-auto btn btn-primary mt-md-0 mt-3" onClick={() => {
              //
              if (OtpData.otp == OtpData.confirmotp) {
                if (OtpData.otp == OtpData.confirmotp) {
                  PostApiCall.postRequest({
                    studentId: login.fld_id,
                    updatedon: null,
                    email: watch("ConfirmEmail"),
                    updatedby: login.fld_id
                  }, "Update_StudentEmailId").then((result) =>
                    result.json().then(obj => {
                      //  ////
                      if (result.status == 200 || result.status == 201) {
                        Notiflix.Loading.remove();
                        Notiflix.Notify.success('New email address is Updated Successfully.')
                        localStorage.clear()
                        window.location.href = "/"
                      } else {
                        Notiflix.Loading.remove();
                        Notiflix.Notify.failure("There seems to be an Error. Please retry!")
                      }
                    })
                  )
                } else {
                  Notiflix.Notify.failure("You OTP does not match. Please Enter Correct OTP. ");
                }
              } else {
                Notiflix.Notify.failure("Please Enter OTP.");
              }
            }}>Confirm OTP</button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
