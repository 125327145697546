import {
  AppBar,
  Avatar,
  Badge,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import Notiflix from "notiflix";
import React, { createContext, useContext, useEffect, useState } from "react";
import "../Adminpanel/Slider/Slider.css";
// import { BiSearch } from "react-icons/bi";
// import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import logo from "../../assets/Image/vahanilogo2.png";
import store from "../../store";
import "./Header.css";
// import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory } from "react-router-dom";
import PostApiCall from "../../assets/API/PostApi";
import ChangeEmailIcon from "../../assets/icons/change_email.svg";
import ChangePasswordIcon from "../../assets/icons/change_password.svg";
import TrackQueryIcon from "../../assets/icons/help_desk.svg";
import LogoutIcon from "../../assets/icons/logout.svg";
import NotificationIcon from "../../assets/icons/notification.svg";
import Switch from "@mui/material/Switch";
import GetApiCall from "../../assets/API/GetApi";

export const showd = createContext();

// dyhamic header according to the roles
export default function Header() {
  const label = { inputProps: { "aria-label": "Size switch demo" } }; // Toggle specific
  const history = useHistory();
  const { setexpenseData, NotificationData, vendorAccess, setVendorAccess, scolarNotificationResponse, setScolarNotificationResponse } =
    useContext(store);
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];

  const { drawerWidth, Showdraw, setShowdraw } = useContext(store);
  const [header, setHeader] = useState("header");

  const [regStatus, setRegStatus] = useState(false); // state to change the registaration state

  const [anchorEl, setAnchorEl] = React.useState(false);
  const open1 = Boolean(anchorEl);
  const [anchorE2, setAnchorE2] = React.useState(false);
  const [showModal, setshowModal] = useState(false);
  const [SelectData, setSelectData] = useState(null);
  const open2 = Boolean(anchorE2);
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];

  const [salt, setSalt] = useState("");
  const filteredData = NotificationData.filter((data) => data.fld_status === "New"); // helper data to track new notifications




  // const [filteredNotifications, setFilteredNotifications] = useState([])
  const handleClick = () => {
    setAnchorEl(!anchorEl);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClicknotification = () => {
    setAnchorE2(!anchorE2);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };
  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("header");
    } else if (window.scrollY > 70) {
      return setHeader("header2");
    }
  };

  const showdrawer = () => {
    setShowdraw(true);
  };

  // handler to set the salt and set it to localstorage
  const GetSalt = () => {
    PostApiCall.postRequest(
      {
        email: login.fld_email,
      },
      "Get_SaltByEmailId_ForgotPassword"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setSalt(obj.data[0].fld_salt);
          // console.log(obj.data[0].fld_salt);
          localStorage.setItem("Salt", JSON.stringify(obj.data));
        }
      })
    );
  };
  const getRegStatus = () => {
    GetApiCall.getRequest("Get_RegistrationMaster").then((results) =>
      results.json().then((obj) => {
        if (
          results.status === 200 ||
          results.status === 201
        ) {
          setRegStatus(obj.data[0].fld_registrationStatus == "YES" ? true : false)
        }
      })
    );
  }

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    getRegStatus()
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.init({
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      timeout: 4000
    });
  }, []);

  // Handle registration status
  const handleChangeRegstatus = (value) => {
    const newToggleState = !regStatus;
    setRegStatus(newToggleState)
    const payload = newToggleState ? 'YES' : 'NO';
    PostApiCall.postRequest(
      {
        registrationStatus: payload
      },
      "Update_RegistrationMaster"
    ).then((results) =>
      results.json().then((expobj) => {
        if (
          results.status === 200 ||
          results.status === 201
        ) {
          getRegStatus();
          // setRegStatus(!regStatus)
          Notiflix.Notify.success("Registration status has been changed!")
        }
      }))
  }

  // Read and clear a specific notification
  const handleReadNotification = (val) => {
    PostApiCall.postRequest({
      id: val.fld_id,
      status: 'Closed'
    },
      "Update_Expense_Notification"
    ).then((results) =>
      results.json().then((expobj) => {
        if (
          results.status === 200 ||
          results.status === 201
        ) {
          Notiflix.Loading.remove();
          setScolarNotificationResponse(!scolarNotificationResponse)
        }
      })
    );
  }

  const onLogin = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        email: "preeti.bhatia@vahanischolarship.com",
        password: "123",
      },
      "AuthenticateHrmsLogin"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          localStorage.removeItem("LoginDetail");
          localStorage.removeItem("AccessAdmin");
          //
          // localStorage.setItem('OldPassword', JSON.stringify(this.state.Password))
          localStorage.setItem("LoginDetail", JSON.stringify(obj.data));
          Notiflix.Loading.remove();
          window.location.href = "/dashboard";
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );

    // }
    // else {
    //   Notiflix.Notify.Failure('Please enter your password.');
    // }
  };
  // const GetAdminNotification = () => {
  //   PostApiCall.postRequest({
  //     whereClause: `where fld_admin_id=${login.fld_id} and fld_status='New'`
  //   }, "Get_Admin_Notification").then((results) =>
  //     // const objs = JSON.parse(result._bodyText)
  //     results.json().then(obj => {
  //       if (results.status == 200 || results.status == 201) {
  //         //
  //         setNotificationData(obj.data)
  //       }
  //     })
  //   )
  // }



  return (
    <>
      <div className="appbar-desktop">
        <AppBar
          className={header}
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
          }}
        >
          <div className="d-flex justify-content-end align-items-center">
            {/* ADMIN headder */}
            {JSON.parse(localStorage.getItem("AccessAdmin")) == "Yes" &&
              vendorAccess == "false" ? (
              <>
                {/* <Tooltip title="Tickets" className="mx-2">
                  <img
                    src={TrackQueryIcon}
                    className="sidebar-icon panel-header"
                    onClick={() => history.push("/ticket-list")}
                  />
                </Tooltip> */}

                <Tooltip title="Registrations" className="mx-2">
                  <Switch {...label} size="small"
                    checked={regStatus}
                    onChange={(e) => handleChangeRegstatus(e.target.value)}
                    value={regStatus}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Tooltip>

                <Tooltip title="Vendor Management" className="mx-2">
                  <img
                    src={TrackQueryIcon}
                    className="sidebar-icon panel-header"
                    onClick={() => {
                      setVendorAccess("true");
                      localStorage.setItem("vendoraccess", "true");
                      history.push("/vendor-dashboard");
                    }}
                  />
                </Tooltip>

                <Tooltip title="Notifications" className="mx-2">
                  <IconButton
                    className="badge"
                    onClick={handleClicknotification}
                    size="small"
                    aria-controls={open2 ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                  >
                    <Badge
                      badgeContent={NotificationData.length}
                      color="primary"
                    >
                      <img
                        src={NotificationIcon}
                        className="sidebar-icon panel-header"
                      />
                    </Badge>
                  </IconButton>
                </Tooltip>

              </>
              // for super ADMIN - Admin but cannot access header
            ) : JSON.parse(localStorage.getItem("AccessAdmin")) == "Yes" &&
              vendorAccess == "true" ? (
              <>
                {/* <Tooltip title="Tickets" className="mx-2">
                  <img
                    src={TrackQueryIcon}
                    className="sidebar-icon panel-header"
                    onClick={() => history.push("/ticket-list")}
                  />
                </Tooltip> */}
                {/* hide the scholar management icon for vendor */}
                {login.fld_type !== "Vendor Management" && (
                  <Tooltip title="Scholar Management" className="mx-2">
                    <img
                      src={TrackQueryIcon}
                      className="sidebar-icon panel-header"
                      onClick={() => {
                        setVendorAccess("false");
                        localStorage.setItem("vendoraccess", "false");
                        history.push("/dashboard");
                      }}
                    />
                  </Tooltip>
                )}
                <Tooltip title="Notifications" className="mx-2">
                  <IconButton
                    className="badge"
                    onClick={handleClicknotification}
                    size="small"
                    aria-controls={open2 ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                  >
                    <Badge
                      // Notification count
                      badgeContent={NotificationData.length}
                      color="primary"
                    >
                      <img
                        src={NotificationIcon}
                        className="sidebar-icon panel-header"
                      />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                {/* for student */}
                <Tooltip title="Track Your Query" className="mx-2">
                  <img
                    src={TrackQueryIcon}
                    className="sidebar-icon panel-header"
                    onClick={() => history.push("/ticket-history")}
                  />
                </Tooltip>
                {login.fld_type != "Student" && (
                  <Tooltip title="Notifications" className="mx-2">
                    <IconButton
                      className="badge"
                      onClick={handleClicknotification}
                      size="small"
                      aria-controls={open2 ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open2 ? "true" : undefined}
                    >
                      <Badge
                        badgeContent={filteredData.length}
                        color="primary"
                      >
                        <img
                          src={NotificationIcon}
                          className="sidebar-icon panel-header"
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            <Tooltip title="My Account">
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open1 ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
              >
                {/* <Avatar >{login.profile_pic}</Avatar> */}
                <Avatar
                  sx={{ width: 40, height: 40 }}
                  alt={login.full_name}
                  src={login.profile_pic}
                />
              </IconButton>
            </Tooltip>
          </div>
        </AppBar>
      </div>
      <div className="appbar-mobile">
        <AppBar
          className={header}
          position="fixed"
          sx={{
            width: `calc(100%)`,
          }}
        >
          <div className="header-pd">
            <div>
              <IconButton>
                <FaBars onClick={() => setShowdraw(true)} />
              </IconButton>
              <IconButton className="bisearch">
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    width: "70px",
                    cursor: "pointer",
                    // marginLeft: "10px",
                  }}
                />
              </IconButton>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              {JSON.parse(localStorage.getItem("AccessAdmin")) == "Yes" ? (
                <>
                  <Tooltip title="Tickets" className="mx-2">
                    <img
                      src={TrackQueryIcon}
                      className="sidebar-icon panel-header"
                      onClick={() => history.push("/ticket-list")}
                    />
                  </Tooltip>
                  {/* <Tooltip title="HRMS" className="mx-2">
                    <IconButton
                      onClick={() => {
                        // onLogin()
                        // window.location.href = "/"
                        // localStorage.clear()
                      }}
                      size="small"
                      aria-controls={open1 ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open1 ? "true" : undefined}
                    >
                      <img
                        src={HRMS}
                        className="sidebar-icon panel-header" />
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title="Notifications" className="mx-2">
                    <IconButton
                      className="badge"
                      onClick={handleClicknotification}
                      size="small"
                      aria-controls={open2 ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open2 ? "true" : undefined}
                    >
                      <Badge
                        badgeContent={NotificationData.length}
                        color="primary"
                      >
                        <img
                          src={NotificationIcon}
                          className="sidebar-icon panel-header"
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Track Your Query" className="mx-2">
                    <img
                      src={TrackQueryIcon}
                      className="sidebar-icon panel-header"
                      onClick={() => history.push("/ticket-history")}
                    />
                  </Tooltip>
                  {login.fld_type != "Student" && (
                    <Tooltip title="Notifications" className="mx-2">
                      <img
                        src={NotificationIcon}
                        className="sidebar-icon panel-header panel-header-notification"
                        onClick={() => history.push("/view-notification")}
                      />
                    </Tooltip>
                  )}
                </>
              )}
              <Tooltip title="My Account">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={open1 ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open1 ? "true" : undefined}
                >
                  {/* <Avatar >{login.profile_pic}</Avatar> */}
                  <Avatar
                    sx={{ width: 40, height: 40 }}
                    alt={login.full_name}
                    src={login.profile_pic}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </AppBar>
      </div>

      {/* My profile modal structure */}
      <Menu
        className="profile-popup"
        anchorEl={anchorEl}
        id="account-menu"
        open={open1}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 2,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* My account popup */}
        <div>
          <div className="profile-name1 text-center ">
            <p>You are Logged in as a</p>
            <h6>{login.fld_type}</h6>
          </div>
          <Divider />
          <div className="profile-name1">
            <p>{login.full_name}</p>
            <p>{login.fld_email}</p>
          </div>
          <Divider />
          {JSON.parse(localStorage.getItem("AccessAdmin")) != "Yes" && (
            <MenuItem onClick={() => history.push("/change-email")}>
              <img
                src={ChangeEmailIcon}
                className="sidebar-icon panel-header me-2"
              />
              Change Email
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              // console.log(login[0].fld_email);
              GetSalt();
              history.push("/change-password");
            }}
          >
            <img
              src={ChangePasswordIcon}
              className="sidebar-icon panel-header me-2"
            />
            Change Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              localStorage.clear();
              window.location.href = "/";
            }}
          >
            <img
              src={LogoutIcon}
              className="panel-header panel-header-notification gray me-2"
            />
            Logout{" "}
          </MenuItem>
        </div>
      </Menu>
      {JSON.parse(localStorage.getItem("AccessAdmin")) == "Yes" ? (
        <Menu
          className="profile-popup2 scr-bar"
          anchorEl={anchorE2}
          id="account-menu"
          open={open2}
          onClose={handleClose2}
          onClick={handleClose2}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 2,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div className="profile-name1">
            <h6>Notification</h6>
            <p>You have {NotificationData.length} unread messages</p>
          </div>
          {/* <hr className="hr-di" /> */}
          <div className="hr-di"></div>
          {NotificationData.map((val) => {
            return (
              <MenuItem
                className="border-bottom border-secondary"
                onClick={() => {
                  Notiflix.Loading.dots();
                  PostApiCall.postRequest(
                    {
                      whereClause: `where fld_id=${val.fld_expense_id} order by fld_id desc`,
                    },
                    "Get_ScholarExpenseInformation"
                  ).then((results) =>
                    results.json().then((expobj) => {
                      if (results.status === 200 || results.status === 201) {
                        //
                        setexpenseData({
                          data: expobj.data[0],
                          action: "view",
                          source: "admin",
                          adminAction: "Open",
                        });
                        PostApiCall.postRequest(
                          {
                            id: val.fld_id,
                            status: "Closed",
                          },
                          "Update_Expense_Notification"
                        ).then((results) =>
                          results.json().then((expobj) => {
                            if (
                              results.status === 200 ||
                              results.status === 201
                            ) {
                              // //
                              history.push("/expense");
                              Notiflix.Loading.remove();
                            }
                          })
                        );
                      }
                      Notiflix.Loading.remove();
                    })
                  );
                }}
              >
                <div>
                  <h6 className="fw-bold" style={{ fontSize: "14px" }}>
                    {val.fld_title}
                  </h6>
                  <span
                    className="d-block text-end"
                    style={{ fontSize: "12px" }}
                  >
                    {moment(val.fld_updatedOn).calendar()}
                  </span>
                </div>
              </MenuItem>
            );
          })}
        </Menu>
        // scholar header section
      ) : (
        <>
          {login.fld_type != "Student" && (
            <Menu
              className="profile-popup2 scr-bar"
              anchorEl={anchorE2}
              id="account-menu"
              open={open2}
              onClose={handleClose2}
              onClick={handleClose2}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 2,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <div className="profile-name1">
                <h6>Notification</h6>
                <p>You have {filteredData.length} unread messages</p>
              </div>
              <div className="hr-di"></div>
              {filteredData.filter((data) => {
                if (data.fld_status == "New") {
                  return data
                }
              })
                .map((val) => {
                  return (
                    <MenuItem
                      className="border-bottom border-secondary"
                      onClick={() => {
                        Notiflix.Loading.dots();
                        setshowModal(true);  // change it here
                        setSelectData(val);
                        Notiflix.Loading.remove();
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <h6 className="fw-bold" style={{ fontSize: "14px" }}>
                          {val.fld_title}
                        </h6>
                        <span
                          className="d-block text-end"
                          style={{ fontSize: "12px" }}
                        >
                          {moment(val.fld_updatedOn).format("DD/MM/YYYY")}
                        </span>
                      </div>
                    </MenuItem>
                  );
                })}
            </Menu>
          )}
        </>
      )}
      <Dialog
        open={showModal}
        maxWidth={"md"}
        onClose={() => {
          setshowModal(false)
          handleReadNotification(SelectData)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className="d-flex justify-content-between align-items-center"
          id="alert-dialog-title"
        >
          <h2>Notification Detail</h2>
          <CancelIcon
            className="cursor-pointer ms-3"
            onClick={() => {
              setshowModal(false)
              handleReadNotification(SelectData)
            }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              className="card-vahani text-white "
              style={{
                backgroundImage:
                  "url('https://media.istockphoto.com/photos/cork-board-background-picture-id848498476?b=1&k=20&m=848498476&s=170667a&w=0&h=jPpRWw52eQxqOg4-ygMEekauGcoY0ye-UlrY-SFJsBQ=')",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <h1 className="mb-5">{SelectData?.fld_title}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: SelectData?.fld_description,
                }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
