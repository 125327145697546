import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import PostApiCall from "../../../assets/API/PostApi";
import Notiflix from "notiflix";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

// Main invoice component
export default function PurchaseOrderInvoiceList() {
  const [orderInvoiceDetails, setOrderInvoiceDetails] = useState([]);
  let location = useLocation();
  const login = JSON.parse(localStorage.getItem("LoginDetail"))[0];


  // get all the invoices from approver's end 
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetPurchaseInvoices"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setOrderInvoiceDetails(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred!");
        }
      })
    );
  }, []);

  const data = {
    columns: [
      {
        label: "S.No.",
        field: "serial",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Po Number",
        field: "ponumber",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Invoice No",
        field: "invoiceno",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Invoice Amount",
        field: "invoiceamount",
        sort: "disabled",
        width: 400,
      },
      {
        label: <div className="text-center">Tax</div>,
        field: "tax",
        sort: "disabled",
        width: 400,
      },
      {
        label: "TDS",
        field: "tds",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Amount Payable",
        field: "amountpayable",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Payment Status",
        field: "paymentstatus",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Created By",
        field: "createdby",
        sort: "asc",
        width: 150,
      },
      {
        label: "Updated By",
        field: "UpdatedBy",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
        sort: "asc",
      },
    ],
    rows: orderInvoiceDetails.map((data, i) => {
      return {
        serial: i + 1,
        ponumber: data.fld_po_no,
        invoiceno: data.fld_invoice_no,
        invoiceamount: `₹ ${parseFloat(data.fld_amount).toFixed(2)}`,
        tax: (
          <div>
            <div className="d-flex justify-content-between pb-1">
              <strong> GST% </strong> ₹ {parseFloat(data.fld_gst).toFixed(2)}
            </div>
            <div className="d-flex justify-content-between pb-1">
              <strong>SGST </strong> ₹ {parseFloat(data.fld_sgst).toFixed(2)}
            </div>
            <div className="d-flex justify-content-between pb-1">
              <strong>CGST </strong> ₹ {parseFloat(data.fld_cgst).toFixed(2)}
            </div>
            <div className="d-flex justify-content-between pb-1">
              <strong>IGST </strong> ₹ {parseFloat(data.fld_igst).toFixed(2)}
            </div>
          </div>
        ),
        tds: (
          <div>
            <div className="d-flex justify-content-between pb-1">
              <strong> TDS% </strong> {data.fld_tds}
            </div>
            <div className="d-flex justify-content-between pb-1">
              <strong>TDS Amount </strong> ₹{" "}
              {parseFloat(data.fld_tds_amount).toFixed(2)}
            </div>
          </div>
        ),
        amountpayable: `₹ ${parseFloat(data.fld_payable_amount).toFixed(2)}`,
        paymentstatus: data.fld_payment_status,
        createdby: data.created_by,
        UpdatedBy: data.updated_by,
        action: (
          <div className="d-flex">
            {/* <Link
              className="m-auto"
              to={{
                pathname: `/add-purchase-Order-invoice`,
                state: {
                  id: data.fld_id,
                },
              }}
            >
              <FaEdit className="edit-icon edit-icon-font-size" />
            </Link> */}

            <Link
              className="m-auto"
              to={{
                pathname: `/purchaseorderinvoicepayments`,
                state: {
                  id: data.fld_id,
                },
              }}
            >
              <Button className="sort-filter-size" variant="contained">
                Payments
              </Button>
            </Link>
          </div>
        ),
      };
    }),
  };

  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Purchase Order Invoice List </h4>
        </div>
      </div>
      <div className="card-vahani p-0 mt-2">
        <p className="personal-info m-0 left-line-card p-4 d-flex align-items-center justify-content-between">
          Purchase Order Invoice List{" "}
          <div className="col-md-2 ps-0 pe-0">
            {login.fld_type !== "Accountant" &&
              <Button variant="contained" className="w-100">
                <Link
                  to={{
                    pathname: `/add-purchase-Order-invoice`,
                    state: {
                      id: null,
                      poid: null,
                    },
                  }}
                  className="text-white"
                >
                  Raise New Payment Request
                </Link>
              </Button>
            }
          </div>
        </p>
        <div className="p-3">
          {/* {addProjectData.length > 0 ? ( */}
          <>
            <MDBDataTable
              className="po-invoice"
              responsive
              hover
              disableRetreatAfterSorting={true}
              data={data}
              entriesOptions={[10, 25, 50, 100]}
            >
              <MDBTableHead columns={data.columns} />
              <MDBTableBody rows={data.rows} />
            </MDBDataTable>
          </>
          {/* ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )} */}
        </div>
      </div>
    </>
  );
}
