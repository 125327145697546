import React, { Component, useState } from "react";
import Notiflix from "notiflix";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  MenuItem,
  TextField,
  Checkbox,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput,
  Button,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import PostApiCall from "../../../assets/API/PostApi";
import moment from "moment";
import capitalize from "capitalize";

export default function AddTask({ match }) {
  let history = useHistory();
  const location = useLocation();
  const { taskData } = location.state || {}; // srtting taskData to edit a task specific to a project
  const projectId = taskData ? taskData.fld_project_id : match.params.fld_id; // fld_id becomes taskId
  const [taskName, setTaskName] = useState(taskData ? taskData.fld_task : "");
  const [description, setDescription] = useState(
    taskData ? taskData.fld_desc : ""
  );
  const [reason, setReason] = useState(taskData ? taskData.fld_reason : "");
  const [deadline, setDeadline] = useState(
    taskData ? moment(taskData.fld_deadline).format("YYYY-MM-DD") : null
  );
  const [startDate, setStartDate] = useState(
    taskData ? moment(taskData.fld_start_date).format("YYYY-MM-DD") : null
  );
  const [endDate, setEndDate] = useState(
    taskData ? moment(taskData.fld_end_date).format("YYYY-MM-DD") : null
  );
  const [budget, setBudget] = useState(taskData ? taskData.fld_budget : "");
  const [taskId, setTaskId] = useState(taskData ? taskData.fld_id : null);
  const [status, setStatus] = useState(
    taskData ? taskData.fld_status : "On Hold"
  );
  const todayDate = moment().format('YYYY-MM-DD');
  const login = JSON.parse(localStorage.getItem("LoginDetail"))[0]

  // new validation function before task creation 
  const onSaveValidate = (action) => {
    if (taskName == "") {
      Notiflix.Notify.failure("Task Name is required")
      return
    }
    if (description == "") {
      Notiflix.Notify.failure("Description is required")
      return
    }
    if (!deadline) {
      Notiflix.Notify.failure("Deadline is required")
      return
    } else if (deadline < todayDate || deadline < startDate || deadline < endDate) {
      Notiflix.Notify.failure("Invalid Deadline")
      return
    }

    if (!startDate) {
      Notiflix.Notify.failure("Start Date is required")
      return
    } else if (startDate < todayDate) {
      Notiflix.Notify.failure("Invalid Start Date")
      return
    }

    if (!endDate) {
      Notiflix.Notify.failure("End Date is required")
      return
    } else if (endDate < startDate || endDate < todayDate) {
      Notiflix.Notify.failure("Invalid End Date")
      return
    }
    if (budget == "") {
      Notiflix.Notify.failure("Budget is required")
      return
    }

    // Notiflix.Notify.success("To add task")
    onAddTask()
  }

  // handler to add task
  const onAddTask = (action) => {
    // if (taskName !== "") {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: taskId,
        projectid: projectId,
        task: taskName,
        startdate: startDate,
        enddate: endDate,
        desc: description,
        reason: reason,
        status: taskId == null ? "New" : status,
        budget: budget,
        deadline: deadline,
        createdby: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
      },
      "UpdateProjectsTasks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          history.push(`/task-list/${projectId}`); // route back to task list
          {
            taskData
              ? Notiflix.Notify.success("Data Updated successfully")
              : Notiflix.Notify.success("Data saved successfully");
          }
        }
        Notiflix.Loading.remove();
      })
    );
    // } 
    // else {
    //   Notiflix.Notify.failure("Please enter task name");
    // }
  };

  const capitalizeTaskName = (input) => {
    return typeof input === "string"
      ? input
        .split(" ")
        .map((word) => capitalize(word))
        .join(" ")
      : input;
  };

  return (
    <>
      {taskData ? (
        <div>
          <div className="form-head ">
            <div
              style={{
                borderLeft: "10px solid rgb(32, 101, 209)",
                paddingLeft: "10px",
              }}
            >
              <h4>{"Update Task"}</h4>
            </div>
          </div>
          <div className="card-vahani p-0 pb-4 ">
            <p className="personal-info left-line-card px-lg-4 px-3 py-4">
              Task Details
            </p>
            <div class="toast-body">
              <div class="row">
                <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row ps-2 pe-3">
                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Task"
                            // required={true}
                            disabled={login.fld_type == "Accountant"}
                            multiline
                            rows={8}
                            className="text-field capitalonly"
                            value={capitalizeTaskName(taskName)}
                            name={taskName}
                            onChange={(e) => setTaskName(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Description"
                            // required={true}
                            disabled={login.fld_type == "Accountant"}
                            multiline
                            rows={8}
                            className="text-field capitalonly"
                            name={description}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>

                        <div className="col-12 col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="date"
                            // required={true}
                            disabled={login.fld_type == "Accountant"}
                            label="Deadline"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name={deadline}
                            value={deadline}
                            onChange={(e) => setDeadline(e.target.value)}
                          />
                        </div>

                        <div className="col-12 col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="date"
                            // required={true}
                            disabled={login.fld_type == "Accountant"}
                            label="Start Date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name={startDate}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>

                        <div className="col-12 col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="date"
                            // required={true}
                            disabled={login.fld_type == "Accountant"}
                            label="End Date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name={endDate}
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>

                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Budget In Rupees"
                            // required={true}
                            disabled={login.fld_type == "Accountant"}
                            className="text-field capitalonly"
                            name={budget}
                            value={budget}
                            onChange={(e) => setBudget(e.target.value)}
                          />
                        </div>

                        <div className="col-12 mobile-personal">
                          <FormControl className="ps-2">
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Status
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={status}
                            >
                              <FormControlLabel
                                value="On Hold"
                                control={<Radio />}
                                label="On Hold"
                                onChange={(e) => {
                                  setStatus(e.target.value);
                                }}
                                disabled={login.fld_type == "Accountant"}
                              />
                              <FormControlLabel
                                value="Cancelled"
                                control={<Radio />}
                                label="Cancelled"
                                onChange={(e) => {
                                  setStatus(e.target.value);
                                }}
                                disabled={login.fld_type == "Accountant"}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>

                        <div className="col-lg-12 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Reason for Hold / Cancellation"
                            // required={true}
                            disabled={login.fld_type == "Accountant"}
                            multiline
                            rows={7}
                            className="text-field capitalonly"
                            name={reason}
                            value={reason}
                            onChange={(e) => {
                              setReason(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-toggle="toast"
            >
              {login.fld_type != "Accountant" &&
                <div
                  className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-3"
                  style={{ float: "right" }}
                >
                  <div
                    className=" justify-content-end"
                    style={{ display: "flex" }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        // onSaveValidate()
                        onAddTask();
                      }}
                    >
                      {"Update"}
                    </Button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="form-head ">
            <div
              style={{
                borderLeft: "10px solid rgb(32, 101, 209)",
                paddingLeft: "10px",
              }}
            >
              <h4>{"Add New Task"}</h4>
            </div>
          </div>
          <div className="card-vahani p-0 pb-4 ">
            <p className="personal-info left-line-card px-lg-4 px-3 py-4">
              Task Details
            </p>
            <div class="toast-body">
              <div class="row">
                <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row ps-2 pe-3">
                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Task"
                            // required={true}
                            multiline
                            rows={8}
                            className="text-field capitalonly"
                            value={capitalizeTaskName(taskName)}
                            name={taskName}
                            onChange={(e) => setTaskName(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Description"
                            // required={true}
                            multiline
                            rows={8}
                            className="text-field capitalonly"
                            name={description}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>

                        <div className="col-12 col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="date"
                            // required={true}
                            label="Deadline"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name={deadline}
                            value={deadline}
                            onChange={(e) => setDeadline(e.target.value)}
                          />
                        </div>

                        <div className="col-12 col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="date"
                            // required={true}
                            label="Start Date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name={startDate}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>

                        <div className="col-12 col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="date"
                            // required={true}
                            label="End Date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name={endDate}
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>

                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Budget In Rupees"
                            // required={true}
                            className="text-field capitalonly"
                            name={budget}
                            value={budget}
                            onChange={(e) => setBudget(e.target.value)}
                          />
                        </div>

                        {/* <div className="col-12 mobile-personal">
                          <FormControl className="ps-2">
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Status
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              defaultValue="New"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                value="New"
                                control={<Radio />}
                                label="On Hold"
                              />
                              <FormControlLabel
                                value="In Progress"
                                control={<Radio />}
                                label="Cancelled"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div> */}

                        {/* <div className="col-lg-12 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Reason for Hold / Cancellation"
                            // required={true}
                            multiline
                            rows={7}
                            className="text-field capitalonly"
                            name={reason}
                            value={reason}
                            onChange={(e) => {
                              setReason(e.target.value);
                            }}
                          />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-toggle="toast"
            >
              <div
                className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-3"
                style={{ float: "right" }}
              >
                <div
                  className=" justify-content-end"
                  style={{ display: "flex" }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      // onSaveValidate()
                      onAddTask();
                    }}
                  >
                    {"Save"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
