import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, Menu, MenuItem } from "@mui/material";
import { MDBDataTable } from "mdbreact";
import moment from 'moment';
import Notiflix from "notiflix";
import React, { useContext, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import PostApiCall from "../../../assets/API/PostApi";
import store from "../../../store";
export default function RejectStudentList() {
    const history = useHistory()
    const { setstudentData } = useContext(store)
    const [ListAll, setListAll] = React.useState(false);
    const [SortData, setSortData] = React.useState({ type: "desc", value: "fld_id" });
    var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
    const [list, setlist] = React.useState([]);
    const [ShowViewModal, setShowViewModal] = React.useState(null);
    const [Showcheck, setShowcheck] = React.useState(false);
    const [SelectCheck, setSelectCheck] = React.useState([]);
    const [searchInput, setSearchinput] = useState("")
    const [filteredData, setFilteredData] = useState([])
    const [SelectData, setSelectData] = React.useState(null);
    const [SearchData, setSearchData] = React.useState({ id: "", name: "", email: "", mobile: "", fromDate: "", toDate: "" });
    React.useEffect(() => {
        Notiflix.Loading.init({
            svgColor: "#060a4a",
        });
        GetRejectStudentList()
        // prefill the search input
        if (window.location.pathname === '/student-list-search') {
            if (localStorage.getItem("rejectedstudentslist") != null) {
                const value = localStorage.getItem("rejectedstudentslist");
                setSearchinput(value);

                const filtered = list.filter((item) => {
                    return Object.keys(item).some((key) =>
                        item[key].toString().toLowerCase().includes(value)
                    );
                });
                setFilteredData(filtered);
            }
        }
    }, []);
    const GetRejectStudentList = (data) => {
        Notiflix.Loading.dots();
        PostApiCall.postRequest({ whereClause: data == undefined ? `where fld_studentStatus = 'Rejected' and cast(fld_updatedOn as date) between '${new Date().getFullYear()}/09/01' and '${new Date().getFullYear() + 1}/08/31' order by ${SortData.value} ${SortData.type}` : data }, "Get_StudentRegistraionList").then(resultdes =>
            resultdes.json().then(obj => {
                //
                setlist(obj.data)
                Notiflix.Loading.remove()
            })
        )
    }
    const UpdateApproved = data => {
        Notiflix.Loading.dots();
        PostApiCall.postRequest({
            studentId: data.fld_id,
            studentStatus: 'Approved',
            type: 'Scholar',
            typeLogin: 'Scholar',
            email: data.fld_email,
            sortedDate: moment().format('lll'),
        }, "Admin_Update_AdminApprovedStatus").then((result) =>
            result.json().then(obj => {
                if (result.status == 200 || result.status == 201) {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.success('Student Approved.')
                    setSelectCheck([])
                    GetRejectStudentList()
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure("There seems to be an Error. Please retry!")
                }
            })
        )
    }
    return (
        <div className="card-vahani p-0" >
            <p className="personal-info m-0 left-line-card p-4 ">Rejected Students' List</p>
            <div className="row m-0 justify-content-between py-4  border-top border-bottom border-secondary">
                <div className="col-md-3 mb-3"><input type="number" value={SearchData.id} onChange={e => setSearchData({ ...SearchData, id: e.target.value })} class="form-control " placeholder="Enter Student ID" /></div>
                <div className="col-md-3 mb-3"><input type="text" value={SearchData.name} onChange={e => setSearchData({ ...SearchData, name: e.target.value })} class="form-control " placeholder="Enter Name" /></div>
                <div className="col-md-3 mb-3"><input type="email" value={SearchData.email} onChange={e => setSearchData({ ...SearchData, email: e.target.value })} class="form-control " placeholder="Enter Email" /></div>
                <div className="col-md-3 mb-3"><input type="number" value={SearchData.mobile} onChange={e => setSearchData({ ...SearchData, mobile: e.target.value })} class="form-control " placeholder="Enter Mobile No." /></div>
                <div className="col-md-3 mb-3"><input type="text" onFocus={e => e.target.type = "date"} value={SearchData.fromDate} onChange={e => setSearchData({ ...SearchData, fromDate: e.target.value })} class="form-control " placeholder="Enter From Date" /></div>
                <div className="col-md-3 mb-3"><input type="text" onFocus={e => e.target.type = "date"} value={SearchData.toDate} onChange={e => setSearchData({ ...SearchData, toDate: e.target.value })} class="form-control " placeholder="Enter To Date" /></div>
                <div className="col-md-6 text-end col-12 mb-3">
                    <Button variant="contained" onClick={() => {
                        let whereClause = `where fld_studentStatus = 'Rejected' ${SearchData.id == "" ? "" : `AND UPPER(fld_studentId) like UPPER('%${SearchData.id}%')`} ${SearchData.name == "" ? "" : `AND UPPER(fld_full_name) like UPPER('%${SearchData.name}%')`} ${SearchData.email == "" ? "" : `AND UPPER(fld_email) like UPPER('%${SearchData.email}%')`} ${SearchData.mobile == "" ? "" : `AND UPPER(fld_mobile) like UPPER('%${SearchData.mobile}%')`}  ${(SearchData.fromDate == "" && SearchData.toDate == "") ? "" : `AND cast(fld_updatedOn as date) between '${moment(SearchData.fromDate).format("YYYY/MM/DD")}' and '${moment(SearchData.toDate).format("YYYY/MM/DD")}'`} order by ${SortData.value} ${SortData.type}`
                        GetRejectStudentList(whereClause)
                    }} >Search</Button>
                    <Button variant="contained" className="mx-3" onClick={() => {
                        setListAll(false)
                        GetRejectStudentList()
                    }} >Refresh</Button>
                    <Button variant="contained" onClick={() => {
                        setListAll(true)
                        GetRejectStudentList(`where fld_studentStatus = 'Rejected' order by ${SortData.value} ${SortData.type}`)
                    }} >List All</Button>
                </div>
                <div className="row m-0 border-top pt-2 justify-content-end ">
                    <div className="col-sm-3 col-6">
                        <select class="form-select form-select-sm " aria-label=".form-select-sm example" onChange={e => setSortData({ ...SortData, value: e.target.value })}>
                            <option value="fld_id">Sort By</option>
                            <option value="fld_studentId">Student ID</option>
                            <option value="trim(fld_full_name)">Name</option>
                            <option value="trim(fld_email)">Email</option>
                            <option value="trim(fld_mobile)">Mobile</option>
                        </select>
                    </div>
                    <div className="col-sm-2 col-6">
                        <select class="form-select form-select-sm " aria-label=".form-select-sm example" onChange={e => setSortData({ ...SortData, type: e.target.value })}>
                            <option value="desc">Order</option>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending </option>
                        </select>
                    </div>
                    <Button variant="contained" className="col-sm-auto mt-sm-0 ms-2 mt-2 col-12" onClick={() => {
                        GetRejectStudentList(`where fld_studentStatus = 'Rejected' ${ListAll ? "" : `and cast(fld_updatedOn as date) between '${new Date().getFullYear()}/09/01' and '${new Date().getFullYear() + 1}/08/31'`} order by ${SortData.value} ${SortData.type}`)
                    }} >Sort</Button>
                    <Button variant="contained" className="text-white ms-auto col-sm-auto col-12 mt-sm-0 mt-2" ><CSVLink className="text-white" filename={"Rejected-Student-List.csv"} data={list} >Download List <span style={{ textTransform: "lowercase" }}>(.csv)</span> </CSVLink></Button>
                </div>
            </div>
            {SelectCheck.length > 0 &&
                <div className="d-flex py-3 justify-content-between align-items-center" style={{ backgroundColor: "#cacaca" }}>
                    <div className="col-auto"> {SelectCheck.length} Selected </div>
                    <div className="col-auto">
                        <button className="btn btn-success" onClick={() => SelectCheck.forEach(val => UpdateApproved(val))}>Approved</button>
                    </div>
                </div>
            }
            <div className='p-3 approval-search-mdbtable-hide'>
                {/* input box here */}
                {list.length > 0 &&
                    <div className="col-sm-auto mb-sm-0 mb-2 col-6 p-0 mdb-replace-custom-common-studene-searchbar">
                        <input
                            className="form-control form-control-sm ml-0 common-search-bar"
                            type="text"
                            placeholder="Search"
                            aria-label="Search"
                            onChange={(e) => {
                                setSearchinput(e.target.value);
                                localStorage.setItem("rejectedstudentslist", e.target.value); // save to pre-fill
                            }}
                            value={searchInput}
                        />
                    </div>}


                {list.length > 0 ?
                    <MDBDataTable
                        disableRetreatAfterSorting={true}
                        responsive
                        hover
                        striped
                        data={
                            {
                                columns: [
                                    {
                                        label:
                                            <input type="checkbox" checked={SelectCheck.length == list.length}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        setSelectCheck(list)
                                                    } else {
                                                        setSelectCheck([])
                                                    }
                                                }}
                                            />,
                                        field: 'check',
                                        sort: 'disabled',
                                    },
                                    {
                                        label: 'Student ID',
                                        field: 'studentid',
                                        sort: 'disabled',
                                    },
                                    {
                                        label: 'Name',
                                        field: 'name',
                                        sort: 'disabled',
                                    },
                                    {
                                        label: 'Email',
                                        field: 'email',
                                        sort: 'disabled',
                                    },
                                    {
                                        label: 'Mobile',
                                        field: 'mobile',
                                        sort: 'disabled',
                                    },
                                    {
                                        label: 'Action',
                                        field: 'action',
                                        sort: 'disabled',
                                    }
                                ],

                                rows: filteredData.filter((data) => {
                                    if (searchInput == "") {
                                        return data;
                                    }
                                    if (
                                        // searchInput.toString() !== "" &&
                                        data.fld_studentId
                                            .toString()
                                            .includes(searchInput.toString().toLowerCase()) ||
                                        `${data.fld_full_name
                                            ? data.fld_full_name.toString().toLowerCase()
                                            : ""
                                            }`
                                            .toString()
                                            .includes(searchInput.toString().toLowerCase()) ||
                                        `${data.fld_email != null
                                            ? data.fld_email.toString().toLowerCase()
                                            : null
                                            }`
                                            .toString()
                                            .includes(searchInput.toString().toLowerCase()) ||
                                        `${data.fld_mobile != null
                                            ? data.fld_mobile.toString().toLowerCase()
                                            : null
                                            }`
                                            .toString()
                                            .includes(searchInput.toString().toLowerCase()) ||
                                        `${data.fld_updatedOn != null
                                            ? new Date(data.fld_updatedOn).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric"
                                            }).toString().toLowerCase()
                                            : null
                                            }`
                                            .includes(searchInput.toString().toLowerCase())
                                    ) {
                                        return data;
                                    }
                                })
                                    .map((row, i) => {
                                        //
                                        return {
                                            check: <input type="checkbox" checked={SelectCheck.find(val => val.fld_id == row.fld_id) ? true : false} onChange={e => {
                                                if (e.target.checked) { setSelectCheck([...SelectCheck, row]) }
                                                else { setSelectCheck(SelectCheck.filter(val => val.fld_id != row.fld_id)) }
                                            }}
                                            />,
                                            studentid: row.fld_studentId,
                                            name: row.fld_full_name,
                                            email: row.fld_email,
                                            mobile: row.fld_mobile,
                                            action:
                                                <Button id="basic-button" aria-controls={ShowViewModal ? 'basic-menu' : undefined}
                                                    aria-haspopup="true" aria-expanded={ShowViewModal ? 'true' : undefined}
                                                    onClick={(e) => {
                                                        setShowViewModal(e.currentTarget)
                                                        setSelectData(row)
                                                    }} >
                                                    <MoreHorizIcon />
                                                </Button>
                                        }
                                    })
                            }
                        }
                        entriesOptions={[10, 25, 50, 100]}
                    />
                    :
                    <p className='m-3'>No Data Found</p>
                }
                <Menu
                    id="basic-menu"
                    anchorEl={ShowViewModal}
                    open={ShowViewModal}
                    onClose={() => setShowViewModal(null)}
                    MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                >
                    <MenuItem onClick={() => {
                        setstudentData({ student: SelectData, page: "Rejected", action: "view", type: "student" })
                        history.push("/view-student")
                    }} >View</MenuItem>
                    <MenuItem onClick={() => UpdateApproved(SelectData)} >Approved</MenuItem>
                </Menu>
            </div>
            {/* <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                <TableHead className="tablesimple-head  back-color">
                    <TableRow>
                        {Showcheck && <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                checked={SelectCheck.length == list.length}
                                onChange={e => {
                                    if (e.target.checked) {
                                        setSelectCheck(list)
                                    } else {
                                        setSelectCheck([])
                                    }
                                }}
                            />
                        </TableCell>}
                        <TableCell className="tablecell-head">Student Id</TableCell>
                        <TableCell className="tablecell-head">Name</TableCell>
                        <TableCell className="tablecell-head">Email</TableCell>
                        <TableCell className="tablecell-head">Mobile</TableCell>
                        <TableCell className="tablecell-head">Status</TableCell>
                        <TableCell className="tablecell-head">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody style={{ position: "relative" }}>
                    {Showcheck &&
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} className="back-color" >
                            <TableCell colSpan={6} className="tablecell-head"> {SelectCheck.length} Selected </TableCell>
                            <TableCell className="tablecell-head"> <button className="btn btn-success" onClick={() => SelectCheck.forEach(val => UpdateApproved(val))}>Approved</button></TableCell>
                        </TableRow>
                    }
                    {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, i) => {
                        return (
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} >
                                {Showcheck && <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        checked={SelectCheck.find(val => val.fld_id == data.fld_id) ? true : false}
                                        onChange={e => {
                                            if (e.target.checked) { setSelectCheck([...SelectCheck, data]) }
                                            else { setSelectCheck(SelectCheck.filter(val => val.fld_id != data.fld_id)) }
                                        }}
                                    />
                                </TableCell>}
                                <TableCell style={{ border: "0px" }}> {data.fld_studentId}</TableCell>
                                <TableCell style={{ border: "0px" }}> {data.fld_full_name} </TableCell>
                                <TableCell style={{ border: "0px" }}> {data.fld_email} </TableCell>
                                <TableCell style={{ border: "0px" }}> {data.fld_mobile} </TableCell>
                                <TableCell style={{ border: "0px" }}> {data.fld_studentStatus} </TableCell>
                                <TableCell style={{ border: "0px" }} >
                                    <div>
                                        <Button id="basic-button" aria-controls={ShowViewModal ? 'basic-menu' : undefined}
                                            aria-haspopup="true" aria-expanded={ShowViewModal ? 'true' : undefined}
                                            onClick={(e) => {
                                                setShowViewModal(e.currentTarget)
                                                setSelectData(data)
                                            }} >
                                            <MoreHorizIcon />
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <Menu
                    id="basic-menu"
                    anchorEl={ShowViewModal}
                    open={ShowViewModal}
                    onClose={() => setShowViewModal(null)}
                    MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                >
                    <MenuItem onClick={() => {
                        setstudentData({ student: SelectData, action: "view", type: "student" })
                        history.push("/view-student")
                    }} >View</MenuItem>
                    <MenuItem onClick={() => UpdateApproved(SelectData)} >Approved</MenuItem>
                </Menu>
            </Table> */}
        </div>
    )
}
