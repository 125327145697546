import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import { Dialog, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import PostApiCall from "../../../assets/API/PostApi";
import ApprovalBox from "../../../pages/AdminManagement/Approval/ApprovalBox";
import store from "../../../store";
import ViewExpense from "./ViewExpense";

import moment from "moment";

function ExpenseForm() {
  const location = useLocation();
  const history = useHistory();
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const { expenseData } = useContext(store);
  const [expense_id, setexpense_id] = useState(null);
  const [materialType, setMaterialType] = useState("");
  const [expenseType, setExpenseType] = useState(""); // current expense type (show in droptown)
  const [fields, setFields] = useState([]);
  const [expenseFields, setexpenseFields] = useState([]); // dynamic form fields for expense form 
  const [fieldValue, setFieldValue] = useState("");
  const [attributeValue, setAttributeValue] = useState([]);
  const [dateValue, setDateValue] = useState(null);
  const [expenseHeadData, setExpenseHeadData] = useState([]); // expense types || dropdown 
  const [dataType, setdataType] = useState("");
  const [showalert, setshowalert] = useState(false);
  const [expensealert, setexpensealert] = useState(false);
  const [submitType, setsubmitType] = useState("");
  const [formData, setformData] = useState("");
  const [showExpenseViewModal, setShowExpenseViewModal] = useState(false);
  const [adjustSecurity, setAdjustSecurity] = useState(false);
  const [amntReviewedByStudent, setAmntReviewedByStudent] = useState(false);
  const [amtInWords, setAmtInWords] = useState("");
  const [scholarBankDetails, setScholarBankDetails] = useState([]);
  var today = new Date();


  const onSubmit = (data) => {
    if (submitType == "approval") {
      if (amntReviewedByStudent == true) {
        if (scholarBankDetails.length > 0) {
          setshowalert(true);
          setformData(data);
        } else {
          Notiflix.Notify.failure("Please add bank details");
        }
      } else {
        Notiflix.Notify.failure("Please check the review amount checkbox");
      }
    } else {
      onSaveMaster(data);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm();

  // expense dropdown data
  const getExpenseDropdown = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: ``,
      },
      "Get_ExpenseDropdown"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setExpenseHeadData(obj.data);
        }
        Notiflix.Loading.remove();
      })
    );
  };

  useEffect(() => {
    //  prefill the data in case of edit, view or copy
    if (expenseData != null) {
      getExpenseDropdown(); // get the dropdown
      if (
        expenseData.action == "view" ||
        expenseData.action == "edit" ||
        expenseData.action == "copy"
      ) {
        PostApiCall.postRequest(
          {
            whereClause: `where expense_master_id=${expenseData.data.fld_id} and fld_studentId=${expenseData.data.fld_studentId} order by fld_sequence asc`,
          },
          "Get_Scholar_Expense_Details"
        ).then((results) =>
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
              if (obj.data.length > 0) {
                obj.data.map((val) =>
                  setValue(`${val.fld_config_id}`, val.fld_answer)
                );
                setValue("Amount", expenseData.data.fld_feeAmount);
                setValue("AmountInWords", expenseData.data.amtInWords);
                setAmtInWords(expenseData.data.amtInWords);
                setValue("Pay_To", expenseData.data.payto);
                setValue("Description", expenseData.data.fld_details);
                setexpense_id(
                  expenseData.action == "copy" ? null : expenseData.data.fld_id
                );
                setexpenseFields(obj.data);
                setExpenseType(expenseData.data.fld_expense_head_id);
                Notiflix.Loading.remove();
              } else {
                getExpenseFields({
                  stdid: expenseData.data.fld_studentId,
                  expid: expenseData.data.fld_expense_head_id,
                });
                setValue("Amount", expenseData.data.fld_feeAmount);
                setValue("AmountInWords", expenseData.data.amtInWords);
                setValue("Pay_To", expenseData.data.payto);
                setValue("Description", expenseData.data.fld_details);
                setexpense_id(
                  expenseData.action == "copy" ? null : expenseData.data.fld_id
                );
                setexpenseFields(obj.data);
                setExpenseType(expenseData.data.fld_expense_head_id);
                Notiflix.Loading.remove();
              }
            }
          })
        );
      }
      BankDetailScholar();
    } else {
      Notiflix.Notify.failure("Invalid Data");
      history.goBack();
    }
  }, []);

  const onSaveMaster = async (data) => {
    const { Amount, Pay_To, Description, AmountInWords, ...detailData } = data;
    console.log(data);
    Notiflix.Loading.dots();
    const CheckExpense = await PostApiCall.postRequest(
      {
        expenseid: expenseType,
        studentid:
          expenseData.data == undefined
            ? login.fld_id
            : expenseData.data.fld_studentId,
      },
      "Get_ExpenseDetails"
    );
    if (CheckExpense.status == 200 || CheckExpense.status == 201) {
      const AddExpenseHeader = await axios.post(
        "https://admin.vahanischolarship.in/API/Update_ScholarExpenseMaster",
        {
          fld_id: expense_id,
          studentid:
            expenseData.data == undefined
              ? login.fld_id
              : expenseData.data.fld_studentId,
          expensetype: expenseType,
          amtInWords: AmountInWords,
          payto: Pay_To,
          amount: Number(Amount),
          details: Description,
          updatedBy: null,
        }
      );
      if (AddExpenseHeader.data.data[0].fld_exp_id) {
        if (Object.entries(detailData).length > 0) {
          const addDetailExpense = await axios.post(
            "https://admin.vahanischolarship.in/API/Update_ScholarExpenseDetails",
            {
              fld_id: null,
              expenseid: AddExpenseHeader.data.data[0].fld_exp_id,
              data: detailData,
              updatedBy: null,
            }
          );
          if (submitType == "approval") {
            const sendForAppoval = await axios.post(
              "https://admin.vahanischolarship.in/API/Update_ScholarExpenseSendApproval",
              {
                expenseid: AddExpenseHeader.data.data[0].fld_exp_id,
              }
            );
            Notiflix.Notify.success("Request Sent Successfully");
            Notiflix.Loading.remove();
            history.push("/expense-list");
          } else {
            Notiflix.Notify.success("Expense Saved Successfully as Draft.");
            Notiflix.Loading.remove();
            history.push("/expense-list");
          }
        } else {
          if (submitType == "approval") {
            const sendForAppoval = await axios.post(
              "https://admin.vahanischolarship.in/API/Update_ScholarExpenseSendApproval",
              {
                expenseid: AddExpenseHeader.data.data[0].fld_exp_id,
              }
            );
            Notiflix.Notify.success("Request Sent Successfully");
            Notiflix.Loading.remove();
            history.push("/expense-list");
          } else {
            Notiflix.Notify.success("Expense Saved Successfully as Draft.");
            Notiflix.Loading.remove();
            history.push("/expense-list");
          }
        }
      } else {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure("There seems to be an Error. Please retry!");
      }
    } else {
      let error = await CheckExpense.json();
      setshowalert(false);
      setexpensealert(error.data);
      Notiflix.Loading.remove();
    }
  };

  const BankDetailScholar = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
        expenseid: null,
      },
      "Get_ScholarBankDetailAll"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setScholarBankDetails(obj.data);
          //
          Notiflix.Loading.remove();
        }
      })
    );
    Notiflix.Loading.remove();
  };

  // dynamically get the form fields
  const getExpenseFields = ({ stdid, expid }) => {
    if (expenseData?.source != "admin") {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          expenseid: expid,
          studentid: stdid,
        },
        "Get_ExpenseDetails"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setExpenseType(expid);
            setexpenseFields(obj.data);
          } else {
            if (expenseData?.source != "admin") {
              setexpensealert(obj.data);
            }
          }
          Notiflix.Loading.remove();
        })
      );
    }
  };

  const convertNumberToWords = (num) => {
    var ones = [
      "",
      "One ",
      "Two ",
      "Three ",
      "Four ",
      "Five ",
      "Six ",
      "Seven ",
      "Eight ",
      "Nine ",
      "Ten ",
      "Eleven ",
      "Twelve ",
      "Thirteen ",
      "Fourteen ",
      "Fifteen ",
      "Sixteen ",
      "Seventeen ",
      "Eighteen ",
      "Nineteen ",
    ];
    var tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    if ((num = num.toString()).length > 9)
      return "Overflow: Maximum 9 digits supported";
    var n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (ones[Number(n[1])] || tens[n[1][0]] + " " + ones[n[1][1]]) + "Crore "
        : "";
    str +=
      n[2] != 0
        ? (ones[Number(n[2])] || tens[n[2][0]] + " " + ones[n[2][1]]) + "Lakh "
        : "";
    str +=
      n[3] != 0
        ? (ones[Number(n[3])] || tens[n[3][0]] + " " + ones[n[3][1]]) +
        "Thousand "
        : "";
    str +=
      n[4] != 0
        ? (ones[Number(n[4])] || tens[n[4][0]] + " " + ones[n[4][1]]) +
        "Hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? " and " : "") +
        (ones[Number(n[5])] || tens[n[5][0]] + " " + ones[n[5][1]]) +
        ""
        : "";
    if (str.length > 0) {
      str += "Rupees Only";
    }

    setValue("AmountInWords", str);
    setAmtInWords(str);
  };


  const handkeGoBack = () => {
    if (login.fld_type !== "Scholar") {
      history.push("/approval-list")
    } else {
      history.push("/expense-list")
    }
  }

  return (
    <>
      <div className="form-head">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Expense Form</h4>
        </div>
      </div>
      {/* Modal to view expenses */}
      <Dialog
        PaperProps={{
          style: { borderRadius: 16 },
        }}
        sx={{ boxShadow: 3 }}
        className="card-vahani dialogboxscroll"
        open={showExpenseViewModal}
        maxWidth={"md"}
        onClose={() => setShowExpenseViewModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className="d-flex justify-content-between "
          id="alert-dialog-title"
        >
          {" "}
          <p className="personal-info p-0 col-auto mb-3"> Expense</p>{" "}
          <CancelIcon
            className="cursor-pointer"
            onClick={() => setShowExpenseViewModal(false)}
          />
        </DialogTitle>
        <ViewExpense stdid={expenseData?.data?.fld_studentId} action="view" />
      </Dialog>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-vahani p-0">
          <div className="row justify-content-between align-items-center m-0 left-line-card px-4 pb-3 pt-3 mb-3">
            <p className="personal-info p-0 col-auto mb-3">Expense Form</p>
            <div className="col-auto personal-info p-0 mb-3">
              {expenseData?.action != "add" && (
                <div className="view_details">
                  <span className="me-3 ">
                    Txn ID : {expenseData?.data?.fld_id}
                  </span>{" "}
                  <span>
                    Student Id :{" "}
                    {expenseData?.data?.fld_student_registration_no}
                  </span>{" "}
                  &nbsp; <span>Name : {expenseData?.data?.full_name}</span>
                  &nbsp;&nbsp;&nbsp;
                  <span
                    className="btn btn-primary"
                    style={{
                      color: "white",
                      backgroundColor: "rgb(32, 101, 209)",
                      borderRadius: "10px",
                      border: "none",
                    }}
                    onClick={() => setShowExpenseViewModal(true)}
                  >
                    View Expenses
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <span
                    className="btn btn-primary"
                    style={{
                      color: "white",
                      backgroundColor: "rgb(32, 101, 209)",
                      borderRadius: "10px",
                      border: "none",
                    }}
                    onClick={handkeGoBack}
                  >
                    Back
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Dropdown menu */}
          {/* <p className="personal-info left-line-card px-lg-4 px-4 py-4">Expense Form</p> */}
          <div className="App p-4">
            <FormControl className="drop-width mb-3" fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select"
                value={expenseType}
                onChange={(e) => {
                  reset();
                  getExpenseFields({
                    stdid: login.fld_id,
                    expid: e.target.value,
                  });
                }}
              >
                {expenseHeadData.map((data, index) => {
                  return (
                    <MenuItem value={data.fld_expense_head_id}>
                      {data.fld_expense_head}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <div>
              <Row className="mt-3">
                {expenseFields.map((item, index) => {
                  switch (item.fld_field_type) {
                    case "Input String":
                      return (
                        <div className="col-md-4 mb-3">
                          <TextField
                            fullWidth
                            type="text"
                            label={item.fld_field_name}
                            {...register(`${item.fld_config_id}`, {
                              required:
                                item.fld_mandatory == "Yes"
                                  ? item.fld_error_message
                                  : false,
                            })}
                            value={
                              watch(`${item.fld_config_id}`)
                                ? watch(`${item.fld_config_id}`)
                                : ""
                            }
                            error={errors[item.fld_config_id]}
                            helperText={
                              errors[item.fld_config_id]?.message
                                ? errors[item.fld_config_id].message
                                : item.fld_help_test
                            }
                            InputLabelProps={{
                              required:
                                item.fld_mandatory == "Yes" ? true : false,
                            }}
                          />
                        </div>
                      );
                    case "Input Numeric":
                      return (
                        <div className="col-md-4 mb-3">
                          <TextField
                            fullWidth
                            type="number"
                            label={item.fld_field_name}
                            {...register(`${item?.fld_config_id}`, {
                              required:
                                item.fld_mandatory == "Yes"
                                  ? item.fld_error_message
                                  : false,
                            })}
                            value={
                              watch(`${item.fld_config_id}`)
                                ? watch(`${item.fld_config_id}`)
                                : ""
                            }
                            error={errors[item.fld_config_id]}
                            helperText={
                              errors[item.fld_config_id]?.message
                                ? errors[item.fld_config_id].message
                                : item.fld_help_test
                            }
                            InputLabelProps={{
                              required:
                                item.fld_mandatory == "Yes" ? true : false,
                            }}
                          />
                        </div>
                      );
                    case "Calendar":
                      return (
                        <div className="col-md-4 mb-3">
                          {/* <CalenderBox item={item} ref={register} /> */}
                          <TextField
                            fullWidth
                            type="date"
                            label={item.fld_field_name}
                            {...register(`${item?.fld_config_id}`, {
                              required:
                                item.fld_mandatory == "Yes"
                                  ? item.fld_error_message
                                  : false,
                            })}
                            // value={
                            //   watch(`${item.fld_config_id}`)
                            //     ? watch(`${item.fld_config_id}`)
                            //     : null
                            // }
                            // value="Aug 14, 2022  12:00 AM"
                            error={errors[item.fld_config_id]}
                            helperText={
                              errors[item.fld_config_id]?.message
                                ? errors[item.fld_config_id].message
                                : item.fld_help_test
                            }
                            InputLabelProps={{
                              shrink: true,
                              required:
                                item.fld_mandatory == "Yes" ? true : false,
                            }}
                          />
                        </div>
                      );
                    case "CalendarMonthYear":
                      return (
                        <div className="col-md-4 mb-3">
                          {/* <CalenderBox item={item} ref={register} /> */}
                          <TextField
                            fullWidth
                            type="month"
                            label={item.fld_field_name}
                            {...register(`${item?.fld_config_id}`, {
                              required:
                                item.fld_mandatory == "Yes"
                                  ? item.fld_error_message
                                  : false,
                            })}
                            value={
                              watch(`${item.fld_config_id}`)
                                ? watch(`${item.fld_config_id}`)
                                : moment(today).format("YYYY-MM")
                            }
                            error={errors[item.fld_config_id]}
                            helperText={
                              errors[item.fld_config_id]?.message
                                ? errors[item.fld_config_id].message
                                : item.fld_help_test
                            }
                            InputLabelProps={{
                              shrink: true,
                              required:
                                item.fld_mandatory == "Yes" ? true : false,
                            }}
                          />
                        </div>
                      );
                    case "Dropdown":
                      return (
                        <>
                          <div className="col-md-4 mb-3">
                            <FormControl
                              fullWidth
                              error={errors[item.fld_config_id]}
                            >
                              <InputLabel
                                required={
                                  item.fld_mandatory == "Yes" ? true : false
                                }
                              >
                                {item.fld_field_name}
                              </InputLabel>
                              <Select
                                label={item.fld_field_name}
                                {...register(`${item?.fld_config_id}`, {
                                  required:
                                    item.fld_mandatory == "Yes"
                                      ? item.fld_error_message
                                      : false,
                                })}
                                value={
                                  watch(`${item.fld_config_id}`)
                                    ? watch(`${item.fld_config_id}`)
                                    : ""
                                }
                              >
                                {item.fld_value != null &&
                                  item.fld_value
                                    .split(",")
                                    .map((val) => (
                                      <MenuItem value={val}>{val}</MenuItem>
                                    ))}
                              </Select>
                              <FormHelperText>
                                {errors[item.fld_config_id]?.message
                                  ? errors[item.fld_config_id].message
                                  : item.fld_help_test}
                              </FormHelperText>
                            </FormControl>
                          </div>
                        </>
                      );
                    case "Radio":
                      return (
                        <div className="col-md-4 mb-3">
                          {/* <RadioBox item={item} ref={register} /> */}
                          <FormControl
                            fullWidth
                            error={errors[item.fld_config_id]}
                          >
                            <FormLabel
                              id="demo-row-radio-buttons-group-label"
                              required={
                                item.fld_mandatory == "Yes" ? true : false
                              }
                            >
                              {item.fld_field_name}
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name={item.fld_field_name}
                              {...register(`${item?.fld_config_id}`, {
                                required:
                                  item.fld_mandatory == "Yes"
                                    ? item.fld_error_message
                                    : false,
                              })}
                              value={
                                watch(`${item.fld_config_id}`)
                                  ? watch(`${item.fld_config_id}`)
                                  : ""
                              }
                            >
                              {item.fld_value != null &&
                                item.fld_value
                                  .split(",")
                                  .map((val) => (
                                    <FormControlLabel
                                      value={val}
                                      control={<Radio />}
                                      label={val}
                                    />
                                  ))}
                            </RadioGroup>
                            <FormHelperText>
                              {errors[item.fld_config_id]?.message
                                ? errors[item.fld_config_id].message
                                : item.fld_help_test}
                            </FormHelperText>
                          </FormControl>
                        </div>
                      );
                    case "Checkbox":
                      return (
                        <div className="col-md-4 mb-3">
                          <FormControl
                            fullWidth
                            variant="standard"
                            error={errors[item.fld_config_id]}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={item.fld_field_name}
                                  {...register(`${item?.fld_config_id}`, {
                                    required:
                                      item.fld_mandatory == "Yes"
                                        ? item.fld_error_message
                                        : false,
                                  })}
                                />
                              }
                              label={item.fld_field_name}
                            />
                            <FormHelperText>
                              {errors[item.fld_config_id]?.message
                                ? errors[item.fld_config_id].message
                                : item.fld_help_test}
                            </FormHelperText>
                          </FormControl>
                        </div>
                      );
                    case "ImageUpload":
                      return (
                        <div className="col-md-4 mb-3">
                          <button
                            {...register(`${item?.fld_config_id}`, {
                              required: item.fld_error_test,
                            })}
                            id={`img-${index}`}
                            className="btn btn-primary position-absolute visually-hidden "
                            style={{
                              zIndex: "999",
                              right: "20px",
                              height: "40px",
                              top: "8px",
                            }}
                          >
                            <a
                              style={{ color: "white" }}
                              href={watch(`${item?.fld_config_id}`)}
                              target="_blank"
                            >
                              View
                            </a>
                          </button>
                          <TextField
                            fullWidth
                            label={item.fld_field_name}
                            error={errors[item.fld_config_id]}
                            type="File"
                            helperText={
                              errors[item.fld_config_id]?.message
                                ? errors[item.fld_config_id].message
                                : item.fld_help_test
                            }
                            InputLabelProps={{
                              shrink: true,
                              required:
                                item.fld_mandatory == "Yes" ? true : false,
                            }}
                            inputProps={{ accept: "" }}
                            onChange={async (e) => {
                              const file = e.target.files[0];
                              if (
                                file.type == "image/png" ||
                                file.type == "image/jpg" ||
                                file.type == "image/jpeg"
                              ) {
                                if (file.size < 2000000) {
                                  const form = new FormData();
                                  let path = `${login.fld_id
                                    }-${expenseType}-${Math.floor(
                                      Math.random() * 1000000
                                    )}-${file.name}`;
                                  form.append("file", file);
                                  form.append("foldername", "FinanceAccounts");
                                  form.append("filename", path);
                                  Notiflix.Loading.dots();
                                  const res = await fetch(
                                    "https://admin.vahanischolarship.in/API/AddImage2",
                                    { method: "POST", body: form }
                                  );
                                  const response = await res.json();
                                  if (response) {
                                    Notiflix.Notify.success(
                                      "Image Uploaded Successfully."
                                    );
                                    document
                                      .querySelector(`#img-${index}`)
                                      .classList.remove("visually-hidden");
                                    setValue(
                                      `${item?.fld_config_id}`,
                                      `https://admin.vahanischolarship.in/Images/FinanceAccounts/${path}`
                                    );
                                  } else {
                                    Notiflix.Loading.remove();
                                    Notiflix.Notify.failure(
                                      "There seems to be an Error. Please retry!"
                                    );
                                  }
                                  Notiflix.Loading.remove();
                                } else {
                                  Notiflix.Notify.failure(
                                    "File too large, upload file less than 2 MB."
                                  );
                                }
                              } else {
                                Notiflix.Notify.failure(
                                  "Image should be in jpeg, jpg or png format."
                                );
                              }
                            }}
                          />
                        </div>
                      );
                    case "PDFUpload":
                      return (
                        <div className="col-md-4 mb-3">
                          <button
                            {...register(`${item?.fld_config_id}`, {
                              required: item.fld_error_test,
                            })}
                            id={`pdf-${index}`}
                            className="btn btn-primary position-absolute visually-hidden "
                            style={{
                              zIndex: "999",
                              right: "20px",
                              height: "40px",
                              top: "8px",
                            }}
                          >
                            <a
                              style={{ color: "white" }}
                              href={watch(`${item?.fld_config_id}`)}
                              target="_blank"
                            >
                              View
                            </a>
                          </button>
                          <TextField
                            fullWidth
                            label={item.fld_field_name}
                            error={errors[item.fld_config_id]}
                            type="File"
                            helperText={
                              errors[item.fld_config_id]?.message
                                ? errors[item.fld_config_id].message
                                : item.fld_help_test
                            }
                            InputLabelProps={{
                              shrink: true,
                              required:
                                item.fld_mandatory == "Yes" ? true : false,
                            }}
                            inputProps={{ accept: "application/pdf" }}
                            onChange={async (e) => {
                              const file = e.target.files[0];
                              setValue(
                                `${item?.fld_config_id}`,
                                "https://clippingpathgreat.com/wp-content/uploads/2021/04/upload-files.jpg"
                              );
                              if (file.size < 6000000) {
                                const form = new FormData();
                                let path = `${login.fld_id
                                  }-${expenseType}-${Math.floor(
                                    Math.random() * 1000000
                                  )}-${file.name}`;
                                form.append("file", file);
                                form.append("foldername", "FinanceAccounts");
                                form.append("filename", path);
                                Notiflix.Loading.dots();
                                const res = await fetch(
                                  "https://admin.vahanischolarship.in/API/AddImage2",
                                  { method: "POST", body: form }
                                );
                                const response = await res.json();
                                if (response) {
                                  Notiflix.Notify.success(
                                    "Pdf Uploaded Successfully."
                                  );
                                  setValue(
                                    `${item?.fld_config_id}`,
                                    `https://admin.vahanischolarship.in/Images/FinanceAccounts/${path}`
                                  );
                                } else {
                                  Notiflix.Loading.remove();
                                  Notiflix.Notify.failure(
                                    "There seems to be an Error. Please retry!"
                                  );
                                }
                                Notiflix.Loading.remove();
                              } else {
                                Notiflix.Notify.failure(
                                  "File too large, upload file less than 6 MB."
                                );
                              }
                            }}
                          />
                        </div>
                      );
                  }
                })}

                {expenseType != "" && (
                  <>
                    <div className="col-md-4 mb-3">
                      <TextField
                        fullWidth
                        type="number"
                        label="Amount"
                        {...register("Amount", {
                          required: "This field is required.",
                        })}
                        error={errors.Amount}
                        helperText={errors.Amount?.message}
                        onChange={(e) => {
                          convertNumberToWords(e.target.value);
                        }}
                        InputLabelProps={{ required: true }}
                        InputProps={{ inputProps: { min: 1 } }}
                      />
                    </div>
                    <div className="col-md-8 mb-3">
                      <TextField
                        fullWidth
                        type="text"
                        value={amtInWords}
                        {...register("AmountInWords", {
                          required: "This field is required.",
                        })}
                        error={errors.AmountInWords}
                        label="Amount In Words"
                        InputLabelProps={{ required: true }}
                        helperText={errors.AmountInWords?.message}
                      />
                    </div>
                    {expenseData?.source != "admin" && (
                      <div className="col-md-4 mb-3">
                        <div className="d-flex flex-column">
                          <div class="form-check form-check-inline me-auto mt-2 mb-2 ps-0">
                            <Checkbox
                              checked={amntReviewedByStudent}
                              onClick={() => {
                                setAmntReviewedByStudent(
                                  !amntReviewedByStudent
                                );
                              }}
                            />
                            <label
                              class="form-check-label"
                              for="inlineCheckbox2"
                            >
                              Please check if the amount is correct.{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-md-4 mb-3">
                      <FormControl fullWidth error={errors.Pay_To}>
                        <InputLabel required>Pay To</InputLabel>
                        <Select
                          label="Pay To"
                          {...register(`Pay_To`, {
                            required: "This field is required",
                          })}
                          value={watch("Pay_To") ? watch("Pay_To") : ""}
                        >
                          <MenuItem value="Personal">Personal</MenuItem>
                          <MenuItem value="Parents">Parents</MenuItem>
                          <MenuItem value="College">College</MenuItem>
                          <MenuItem value="PG / Hostel">PG / Hostel</MenuItem>
                          <MenuItem value="Mess">Mess</MenuItem>
                        </Select>
                        <FormHelperText>
                          {errors.Pay_To?.message}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    {expenseFields.map((item, index) => {
                      if (item.fld_help_test == "Enter Rent Type") {
                        return (
                          <div className="d-flex flex-column">
                            <div class="form-check form-check-inline me-auto mt-2 mb-2">
                              <Checkbox
                                checked={adjustSecurity}
                                onClick={() => {
                                  setAdjustSecurity(!adjustSecurity);
                                }}
                              />
                              <label
                                class="form-check-label"
                                for="inlineCheckbox2"
                              >
                                Adjust Security Deposit
                              </label>
                            </div>
                            <div
                              className="col-8 mb-3"
                              style={{
                                display:
                                  adjustSecurity == true ? "block" : "none",
                              }}
                            >
                              <TextField
                                fullWidth
                                label="Security Amount"
                                error={errors.Security_Amount}
                              />
                            </div>
                          </div>
                        );
                      }
                    })}
                    <div className="col-12 mb-3">
                      <TextField
                        fullWidth
                        label="Description"
                        multiline
                        rows={4}
                        variant="outlined"
                        {...register("Description", {
                          required: "This field is required",
                        })}
                        error={errors.Description}
                        helperText={errors.Description?.message}
                        InputLabelProps={{ required: true }}
                      />
                    </div>

                    {(expenseData?.action == "add" ||
                      expenseData?.action == "copy") && (
                        <div className="d-flex justify-content-end">
                          <div className="but-width-form">
                            <Button
                              variant="contained"
                              className="but-login-vahani"
                              type="submit"
                              onClick={() => setsubmitType("draft")}
                            >
                              Save As Draft
                            </Button>
                          </div>
                          <div
                            className="but-width-form"
                            style={{ marginLeft: "20px" }}
                          >
                            <Button
                              variant="contained"
                              className="but-login-vahani"
                              onClick={() => setsubmitType("approval")}
                              type="submit"
                            >
                              Send For Approval
                            </Button>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </Row>
            </div>

            {expenseData?.action == "edit" && (
              <div className="col-12 text-end">
                <button
                  type="submit"
                  onClick={() => setsubmitType("approval")}
                  className="col-auto btn btn-primary"
                >
                  {" "}
                  Send For Approval
                </button>
              </div>
            )}
            {/* <div className="but-width-form">
              <Button
                // style={{ display: expenseType == "" ? "none" : "block" }}
                variant="contained"
                className="but-login-vahani"
                type="submit"
              >
                Save
              </Button>
            </div> */}
          </div>
        </div>
      </form>

      {/* last 3 expenses section of the form start */}
      {expenseData?.source == "admin" && <ApprovalBox />}
      {/* last 3 expenses section of the form end */}

      {showalert && (
        <SweetAlert
          title="Are you sure you want to send it for approval?"
          custom
          showCancel
          showCloseButton
          onCancel={() => setshowalert(false)}
          customButtons={
            <React.Fragment>
              <button
                onClick={() => setshowalert(false)}
                className="btn btn-danger mx-2"
              >
                Edit
              </button>
              <button
                onClick={() => {
                  setshowalert(false);
                  onSaveMaster(formData);
                }}
                className="btn btn-success"
              >
                Yes
              </button>
            </React.Fragment>
          }
        >
          {" "}
        </SweetAlert>
      )}
      {expensealert && (
        <SweetAlert
          title={
            <p className="text-danger">
              <ErrorIcon style={{ color: "red", fontSize: "100px" }} />
            </p>
          }
          onConfirm={() => setexpensealert(false)}
        >
          {expensealert}
        </SweetAlert>
      )}
    </>
  );
}
export default ExpenseForm;
// <div className="img-wrap img-upload text-end cursor-pointer " onClick={() => document.querySelector('#pdf-upload').click()} >
// <img
//   for="photo-upload"
//   src={watch(`${item?.fld_config_id}`) ? watch(`${item?.fld_config_id}`) : "https://clippingpathgreat.com/wp-content/uploads/2021/04/upload-files.jpg"}
//   style={{ width: "100%", height: "100%", borderRadius: "5%", }}
//   alt=""
//   {...register(`${item?.fld_config_id}`, {
//     required: item.fld_error_test,
//   })}
// />
// <div className="upload-info-personal">
//   {item.fld_mandatory == "Yes" ? `${item.fld_field_name} *` : item.fld_field_name} <small>(Maximum Size 6MB)</small> <br />
//   File Format *.pdf
// </div>
// <FormHelperText className="mb-2">{errors[item.fld_config_id]?.message ? errors[item.fld_config_id].message : item.fld_help_test}</FormHelperText>
// </div>
// <input
// id="pdf-upload"
// type="file"
// onChange={e => {
//   if (e.target.files[0].size < 6000000) {
//     // setCheckUpload(e.target.files[0])
//     setValue(`${item?.fld_config_id}`, URL.createObjectURL(e.target.files[0]) )
//     // setCheckUploadUrl(URL.createObjectURL(e.target.files[0]))
//   } else {
//     Notiflix.Notify.failure("File too large, upload file less than 6 Mb.");
//   }
// }}
// accept="application/pdf"
// className="d-none"
// />
