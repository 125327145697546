import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import imageCompression from "browser-image-compression";
import "cropperjs/dist/cropper.css";
import moment from "moment";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
// import { NavLink } from "react-router-dom";
// import axios from "axios";
// import { FaEdit } from "react-icons/fa";
import "../Familyinfo/Familyinfo.css";
import "../Personalinfo/Personalinfo.css";
export default function Familyinfo({ stdid, action }) {
  var otp = Math.floor(100000 + Math.random() * 900000);
  var otp2 = Math.floor(100000 + Math.random() * 900000);
  var otp3 = Math.floor(100000 + Math.random() * 900000);
  var otp4 = Math.floor(100000 + Math.random() * 900000);
  var otp5 = Math.floor(100000 + Math.random() * 900000);

  var login = stdid
    ? { fld_id: stdid }
    : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  var ValidationList = JSON.parse(localStorage.getItem("Validation"));
  const [country, setCountry] = useState([]);
  const [countryid, setCountryid] = useState("");
  const [st, setSt] = useState([]);
  const [stateid, setStateid] = useState("");
  const [city, setCity] = useState([]);
  const [cityid, setCityid] = useState("");
  // const [ValidationList, setValidationList] = useState([]);
  const [stuid1, setstudid1] = React.useState(null);
  const [stuid2, setstudid2] = React.useState(null);
  const [stuid3, setstudid3] = React.useState(null);
  const [familyinfo, setfamilyinfo] = React.useState([]);
  const [showfamily, setshowfamily] = React.useState(false);
  const [showadd, setshowadd] = React.useState(true);
  const [stuid, setstudid] = React.useState(null);
  const [FatherSign, setFatherSign] = React.useState([]);
  const [MotherSign, setMotherSign] = React.useState([]);
  const [GuardianSign, setGuardianSign] = React.useState([]);
  const [originalImageLaptop, setoriginalImageLaptop] = useState([]);
  const [originalImageFamily, setoriginalImageFamily] = useState([]);
  const [originalImageMobile, setoriginalImageMobile] = useState([]);
  const [originalImageVehicle, setoriginalImageVehicle] = useState([]);
  const [uploadImageLaptop, setuploadImageLaptop] = useState(false);
  const [uploadImageMobile, setuploadImageMobile] = useState(false);
  const [uploadImageVehicle, setuploadImageVehicle] = useState(false);
  const [uploadImageFamily, setuploadImageFamily] = useState(false);
  const [outputFileNameLaptop, setoutputFileNameLaptop] = useState("");
  const [outputFileNameMobile, setoutputFileNamemobile] = useState("");
  const [outputFileNameVehicle, setoutputFileNameVehicle] = useState("");
  const [outputFileNameFamily, setoutputFileNameFamily] = useState("");
  const [fathersignvalue, setSignFather] = useState(null);
  const [mothersign, setSignMother] = useState(null);
  const [guardiansign, setSignGuardian] = useState(null);
  const [imageAPIURL, setimageAPIURL] = useState(
    "https://admin.vahanischolarship.in/API/AddImage2"
  );
  const [previewUrlLaptop, setPreviewUrlLaptop] = React.useState("/upload.jpg");
  const [previewUrlFamily, setPreviewUrlFamily] = React.useState("/upload.jpg");
  const [previewUrlMobile, setPreviewUrlMobile] = React.useState("/upload.jpg");
  const [previewUrlVehicle, setPreviewUrlVehicle] =
    React.useState("/upload.jpg");

  const [previewFourWheelerVehicle, setPreviewFourWheelerVehicle] =
    React.useState("/upload.jpg");
  const [originalImageFourWheel, setoriginalImageFourWheel] = useState([]);
  const [outputFileNameFourWheel, setoutputFileNameFourWheel] = useState("");
  const [uploadImageFourWheel, setuploadImageFourWheel] = useState(false);
  const [SignAPIUrl, setSignAPIUrl] = React.useState(
    "https://admin.vahanischolarship.in/API/AddPdf2"
  );
  const [educationQaDropdown, setEducationQaDropdown] = useState([
    "Illiterate",
    "10th pass",
    "12th Pass",
    "Graduation",
    "Post graduation ",
    "Diploma",
  ])

  const [guardianRelation, setGuradianRelation] = useState([
    "Family",
    "Friend",
    "School/Hostel Management",
    "Others"
  ])

  const [values, setValues] = React.useState({
    fld_Country: "",
    fld_State: "",
    fld_City: "",
    fld_Occupation: "",
    fld_Education: "",
    fld_Occupation_mother: "",
    fld_Education_mother: "",
    fld_relation_with_student: ""
  });
  //-----------------------Image Upload----------------------------------
  const ImgUpload = ({ onChange, onCancel, src }) => (
    <label
      htmlFor="photo-upload"
      className="custom-file-upload fas text-center"
      style={{ width: "100%" }}
    >
      <div className="img-wrap img-upload">
        <img
          for="photo-upload"
          src={src}
          style={{ width: "100%", height: "100%", borderRadius: "5%" }}
          alt=""
        />
      </div>
      <input
        id="photo-upload"
        type="file"
        onChange={onChange}
        accept="image/*"
        style={{ display: "none" }}
      />
      {src != "/upload.jpg" && (
        <a href={src} target="_blank" className="btn btn-primary mt-2">
          View
        </a>
      )}
      {/* <XSquare class='product-img'
        onClick={onCancel2}
      ></XSquare> */}
    </label>
  );
  const ImgUploadFamilyPicture = ({ onChange1, onCancel1, src1 }) => (
    <label
      htmlFor="photo-upload1"
      className="custom-file-upload fas text-center"
      style={{ width: "100%" }}
    >
      <div className="img-wrap img-upload">
        <img
          for="photo-upload1"
          src={src1}
          style={{ width: "100%", height: "100%", borderRadius: "5%" }}
          alt="photo"
        />
      </div>
      <input
        id="photo-upload1"
        type="file"
        onChange={onChange1}
        accept="image/*"
        style={{ display: "none" }}
      />
      {src1 != "/upload.jpg" && (
        <a href={src1} target="_blank" className="btn btn-primary mt-2">
          View
        </a>
      )}
    </label>
  );
  const ImgUploadLaptop = ({ onChange2, onCancel2, src2 }) => (
    <label
      htmlFor="photo-upload2"
      className="custom-file-upload fas text-center"
      style={{ width: "100%" }}
    >
      <div className="img-wrap img-upload">
        <img
          for="photo-upload2"
          src={src2}
          style={{ width: "100%", height: "100%", borderRadius: "5%" }}
        />
      </div>
      <input
        id="photo-upload2"
        type="file"
        onChange={onChange2}
        accept="image/*"
        style={{ display: "none" }}
      />
      {src2 != "/upload.jpg" && (
        <a href={src2} target="_blank" className="btn btn-primary mt-2">
          View
        </a>
      )}
      {/* <XSquare class='product-img'
        onClick={onCancel2}
      ></XSquare> */}
    </label>
  );
  const ImgUploadVehicle = ({ onChange3, onCancel3, src3 }) => (
    <label
      htmlFor="photo-upload3"
      className="custom-file-upload fas text-center"
      style={{ width: "100%" }}
    >
      <div className="img-wrap img-upload">
        <img
          for="photo-upload3"
          src={src3}
          style={{ width: "100%", height: "100%", borderRadius: "5%" }}
        />
      </div>
      <input
        id="photo-upload3"
        type="file"
        onChange={onChange3}
        accept="image/*"
        style={{ display: "none" }}
      />
      {src3 != "/upload.jpg" && (
        <a href={src3} target="_blank" className="btn btn-primary mt-2">
          View
        </a>
      )}
      {/* <XSquare class='product-img'
        onClick={onCancel3}
      ></XSquare> */}
    </label>
  );
  const FourWheelerImgUpload = ({ onChange4, src4 }) => (
    <label
      htmlFor="photo-upload4"
      className="custom-file-upload fas text-center"
      style={{ width: "100%" }}
    >
      <div className="img-wrap img-upload">
        <img
          for="photo-upload4"
          src={src4}
          style={{ width: "100%", height: "100%", borderRadius: "5%" }}
        />
      </div>
      <input
        id="photo-upload4"
        type="file"
        onChange={onChange4}
        accept="image/*"
        style={{ display: "none" }}
      />
      {src4 != "/upload.jpg" && (
        <a href={src4} target="_blank" className="btn btn-primary mt-2">
          View
        </a>
      )}
      {/* <XSquare class='product-img'
        onClick={onCancel3}
      ></XSquare> */}
    </label>
  );



  // ----------------------country-state-city-----------------------------
  React.useEffect(() => {
    const getcountry = async () => {
      const rescountry = await fetch(
        "https://admin.vahanischolarship.in/API/GetCountry"
      );
      const rescon = await rescountry.json();
      setCountry(await rescon.data);
    };
    getcountry();
  }, [countryid]);
  const handlecountry = (event) => {
    const getcountryid = event.target.value;
    setCountryid(getcountryid);
  };
  React.useEffect(() => {
    if (countryid) {
      const asyncPostCall = async () => {
        try {
          const response = await fetch(
            "https://admin.vahanischolarship.in/API/GetState",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                countryid: countryid,
              }),
            }
          );
          const data = await response.json();
          //
          setSt(data.data);
        } catch (error) {
          //
        }
      };
      asyncPostCall();
    }
  }, [countryid]);
  const handlestate = (event) => {
    const getstateid = event.target.value;
    setStateid(getstateid);
  };
  React.useEffect(() => {
    if (stateid) {
      const asyncPostCall = async () => {
        try {
          const response = await fetch(
            "https://admin.vahanischolarship.in/API/GetCity",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                stateid: stateid,
              }),
            }
          );
          const data = await response.json();
          //
          setCity(data.data);
        } catch (error) {
          //
        }
      };
      asyncPostCall();
    }
  }, [stateid]);
  const handlecity = (e) => {
    const getcityid = e.target.value;
    setCityid(getcityid);
    //
  };
  const onChangeHandler = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  React.useEffect(() => {
    Get_StudentFamilyInformation_ByStudentId();
    // statusupdate
  }, []);
  const Get_StudentFamilyInformation_ByStudentId = () => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentFamilyInformation_ByStudentId"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          //
          if (obj.data.length !== 0) {

            const fatherEducation = obj.data[0].fld_fatherEducation;
            const motherEducation = obj.data[0].fld_motherEducation;
            const relationWithGuardian = obj.data[0].fld_guardianRelationWithStudent;

            setEducationQaDropdown(prevState => {
              const updatedDropdown = [...prevState];

              if (!updatedDropdown.includes(fatherEducation)) {
                updatedDropdown.push(fatherEducation);
              }
              if (!updatedDropdown.includes(motherEducation)) {
                updatedDropdown.push(motherEducation);
              }
              return updatedDropdown;
            });

            setGuradianRelation(prevState => {
              const updatedRelationDropdown = [...prevState];

              if (relationWithGuardian != "" && !updatedRelationDropdown.includes(relationWithGuardian)) {
                updatedRelationDropdown.push(relationWithGuardian);
              }
              return updatedRelationDropdown;
            });




            setfamilyinfo(obj.data);
            setstudid(obj.data[0].fld_id);
            // setstudid1(obj.data[0].fld_id);
            // setstudid2(obj.data[1].fld_id);
            // setstudid3(obj.data[2].fld_id);
            setCountryid(obj.data[0].fld_guardianCountry);
            setStateid(obj.data[0].fld_guardianState);
            setCityid(obj.data[0].fld_guardianCity);
            setSignFather(obj.data[0].fld_fatherSign);
            setSignMother(obj.data[0].fld_motherSign);
            setSignGuardian(obj.data[0].fld_guardianSign);
            setPreviewUrlLaptop(
              obj.data[0].fld_laptop_image == null
                ? "/upload.jpg"
                : obj.data[0].fld_laptop_image
            );
            setPreviewFourWheelerVehicle(
              obj.data[0].fld_four_wheeler_image == null
                ? "/upload.jpg"
                : obj.data[0].fld_four_wheeler_image
            )
            setPreviewUrlFamily(
              obj.data[0].fld_family_image == null
                ? "/upload.jpg"
                : obj.data[0].fld_family_image
            );
            setPreviewUrlMobile(
              obj.data[0].fld_mobile_image == null
                ? "/upload.jpg"
                : obj.data[0].fld_mobile_image
            );
            setPreviewUrlVehicle(
              obj.data[0].fld_vehicle_image == null
                ? "/upload.jpg"
                : obj.data[0].fld_vehicle_image
            );
            setValues({
              fld_Occupation: obj.data[0].fld_fatherOccupation,
              fld_Education: obj.data[0].fld_fatherEducation,
              fld_Occupation_mother: obj.data[0].fld_motherOccupation,
              fld_Education_mother: obj.data[0].fld_motherEducation,
              fld_relation_with_student: obj.data[0].fld_guardianRelationWithStudent
            });
            reset({
              fld_Name: obj.data[0].fld_fatherName,
              fld_mobile: obj.data[0].fld_fathermobile,
              fld_Education: obj.data[0].fld_fatherEducation,
              fld_Email: obj.data[0].fld_fatherEmail,
              fld_Pan: obj.data[0].fld_fatherPan,
              fld_Aadhar: obj.data[0].fld_fatherAadhar,
              fld_Name_mother: obj.data[0].fld_motherName,
              fld_mobile_mother: obj.data[0].fld_mothermobile,
              fld_Education_mother: obj.data[0].fld_motherEducation,
              fld_Email_mother: obj.data[0].fld_motherEmail,
              fld_Pan_mother: obj.data[0].fld_motherPan,
              fld_Aadhar_mother: obj.data[0].fld_motherAadhar,
              fld_Name_Guardian: obj.data[0].fld_guardianName,
              fld_mobile_Guardian: obj.data[0].fld_guardianmobile,
              fld_Address: obj.data[0].fld_guardianAddress,
              fld_Pincode: obj.data[0].fld_guardianPincode,
              fld_relation_with_student:
                obj.data[0].fld_guardianRelationWithStudent,
            });
          }
        }
      })
    );
  };
  const onPost = (family) => {
    if (FatherSign.length != 0) {
      const form = new FormData();
      form.append("file", FatherSign);
      form.append("foldername", "FamilyInformation");
      form.append("filename", otp + "-FatherSign" + FatherSign.name);
      fetch(SignAPIUrl, {
        method: "POST",
        body: form,
      }).then((image) => {
        image
          .json()
          .then((data) => ({
            data: data,
            status: image.status,
          }))
          .then((res) => {
            onPostMotherSign(family);
          });
      });
    } else {
      onPostMotherSign(family);
    }
  };
  const onPostMotherSign = (family) => {
    if (MotherSign.length != 0) {
      const form = new FormData();
      form.append("file", MotherSign);
      form.append("foldername", "FamilyInformation");
      form.append("filename", otp + "-MotherSign" + MotherSign.name);
      fetch(SignAPIUrl, {
        method: "POST",
        body: form,
      }).then((image) => {
        image
          .json()
          .then((data) => ({
            data: data,
            status: image.status,
          }))
          .then((res) => {
            onPostGuardianSign(family);
          });
      });
    } else {
      onPostGuardianSign(family);
    }
  };
  const onPostGuardianSign = (family) => {
    if (GuardianSign.length != 0) {
      const form = new FormData();
      form.append("file", GuardianSign);
      form.append("foldername", "FamilyInformation");
      form.append("filename", otp + "-GuardianSign" + GuardianSign.name);
      fetch(SignAPIUrl, {
        method: "POST",
        body: form,
      }).then((image) => {
        image
          .json()
          .then((data) => ({
            data: data,
            status: image.status,
          }))
          .then((res) => {
            onPostLaptopImage(family);
          });
      });
    } else {
      onPostLaptopImage(family);
    }
  };

  const onPostLaptopImage = (family) => {
    let response;
    if (outputFileNameLaptop != "") {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(originalImageLaptop, options).then((compressedFile) => {
        ////
        //this.state.CategoryName.trim().replace(/\s/g, "-") +
        //"-" +
        //compressedFile.name)
        const form = new FormData();
        form.append("file", compressedFile);
        form.append("foldername", "StudentInformation");
        form.append(
          "filename",
          otp + "-LaptopImage" + "-" + compressedFile.name
        );
        response = fetch(imageAPIURL, {
          method: "POST",
          body: form,
          mode: "cors",
        })
          .then((response) => response.json())
          .then((data) => {
            //
            Notiflix.Notify.success("Laptop Document Uploaded successfully.");
            Notiflix.Loading.remove();
            onPostMobileUpload(family);
          });
        // ////
      });
    } else {
      onPostMobileUpload(family);
    }
  };
  const onPostMobileUpload = (family) => {
    let response;
    if (outputFileNameMobile != "") {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(originalImageMobile, options).then((compressedFile) => {
        ////
        //this.state.CategoryName.trim().replace(/\s/g, "-") +
        //"-" +
        //compressedFile.name)
        const form = new FormData();
        form.append("file", compressedFile);
        form.append("foldername", "StudentInformation");
        form.append(
          "filename",
          otp2 + "-MobileImage" + "-" + compressedFile.name
        );
        response = fetch(imageAPIURL, {
          method: "POST",
          body: form,
          mode: "cors",
        })
          .then((response) => response.json())
          .then((data) => {
            //
            Notiflix.Notify.success("Mobile Document Uploaded Successfully.");
            Notiflix.Loading.remove();
            onPostVehicleUpload(family);
          });
        // ////
      });
    } else {
      onPostVehicleUpload(family);
    }
  };
  const onPostVehicleUpload = (family) => {
    let response;
    if (outputFileNameVehicle != "") {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(originalImageVehicle, options).then((compressedFile) => {
        ////
        //this.state.CategoryName.trim().replace(/\s/g, "-") +
        //"-" +
        //compressedFile.name)
        const form = new FormData();
        form.append("file", compressedFile);
        form.append("foldername", "StudentInformation");
        form.append(
          "filename",
          otp3 + "-VehicleImage" + "-" + compressedFile.name
        );
        response = fetch(imageAPIURL, {
          method: "POST",
          body: form,
          mode: "cors",
        })
          .then((response) => response.json())
          .then((data) => {
            //
            Notiflix.Notify.success("Vehicle Document Uploaded Successfully.");
            Notiflix.Loading.remove();
            onPostFamilyPictureUpload(family);
          });
        // ////
      });
    } else {
      onPostFamilyPictureUpload(family);
    }
  };


  const onPostFamilyPictureUpload = (family) => {
    let response;
    if (outputFileNameFamily != "") {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(originalImageFamily, options).then((compressedFile) => {
        ////
        //this.state.CategoryName.trim().replace(/\s/g, "-") +
        //"-" +
        //compressedFile.name)
        const form = new FormData();
        form.append("file", compressedFile);
        form.append("foldername", "StudentInformation");
        form.append(
          "filename",
          otp4 + "FamilyImage" + "-" + compressedFile.name
        );
        response = fetch(imageAPIURL, {
          method: "POST",
          body: form,
          mode: "cors",
        })
          .then((response) => response.json())
          .then((data) => {
            //
            Notiflix.Notify.success("Family Document Uploaded Successfully.");
            Notiflix.Loading.remove();
            onPostFourWheelerPictureUpload(family);
          });
      });
    } else {
      onPostFourWheelerPictureUpload(family);
    }
  };
  const onPostFourWheelerPictureUpload = (family) => {
    let response;
    if (outputFileNameFourWheel != "") {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(originalImageFourWheel, options).then((compressedFile) => {
        ////
        //this.state.CategoryName.trim().replace(/\s/g, "-") +
        //"-" +
        //compressedFile.name)
        const form = new FormData();
        form.append("file", compressedFile);
        form.append("foldername", "StudentInformation");
        form.append(
          "filename",
          otp5 + "FourWheelerImg" + "-" + compressedFile.name
        );
        response = fetch(imageAPIURL, {
          method: "POST",
          body: form,
          mode: "cors",
        })
          .then((response) => response.json())
          .then((data) => {
            //
            Notiflix.Notify.success("Family Document Uploaded Successfully.");
            Notiflix.Loading.remove();
            onPostFamily(family);
          });
        // ////
      });
    } else {
      onPostFamily(family);
    }
  };



  const onPostFamily = (family) => {
    Notiflix.Loading.dots();
    let detail = JSON.parse(localStorage.getItem("LoginDetail"))[0];
    PostApiCall.postRequest(
      {
        id: stuid,
        studentId: login.fld_id,
        fatherName: family.fld_Name,
        fathermobile: family.fld_mobile,
        fatherEducation: family.fld_Education,
        fatherOccupation: family.fld_Occupation,
        fatherEmail: family.fld_Email,
        fatherPan: family.fld_Pan,
        fatherAadhar: family.fld_Aadhar,
        fathersign:
          FatherSign.length == 0
            ? fathersignvalue
            : "https://admin.vahanischolarship.in/Images/" +
            "FamilyInformation/" +
            otp +
            "-FatherSign" +
            FatherSign.name,
        motherName: family.fld_Name_mother,
        mothermobile: family.fld_mobile_mother,
        motherEducation: family.fld_Education_mother,
        motherOccupation: family.fld_Occupation_mother,
        motherEmail: family.fld_Email_mother,
        motherPan: family.fld_Pan_mother,
        motherAadhar: family.fld_Aadhar_mother,
        mothersign:
          MotherSign.length == 0
            ? mothersign
            : "https://admin.vahanischolarship.in/Images/" +
            "FamilyInformation/" +
            otp +
            "-MotherSign" +
            MotherSign.name,
        guardianName: family.fld_Name_Guardian,
        guardianmobile: family.fld_mobile_Guardian,
        gurdiansign:
          GuardianSign.length == 0
            ? guardiansign
            : "https://admin.vahanischolarship.in/Images/" +
            "FamilyInformation/" +
            otp +
            "-GuardianSign" +
            GuardianSign.name,
        guardianAddress: family.fld_Address,
        guardianCountry: family.fld_Country,
        guardianState: family.fld_State,
        guardianCity: family.fld_City,
        guardianPincode: family.fld_Pincode,
        guardianRelationWithStudent: family.fld_relation_with_student,
        rationCard: null,
        earningMember: null,
        annualIncome: null,
        financiallyDependent: null,
        typeOfFamily: null,
        familyHouse: null,
        familyVehicle: null,
        liveInFamilyHouse: null,
        anyoneElseSupporting: null,
        annualIncomeSupportingPeople: null,
        updatedOn: moment().format("lll"),
        updatedBy: detail.fld_id,
        laptopimage:
          outputFileNameLaptop == ""
            ? previewUrlLaptop
            : "https://admin.vahanischolarship.in/Images/" +
            "StudentInformation/" +
            otp +
            "-LaptopImage" +
            "-" +
            outputFileNameLaptop,
        fourwheelerimage:
          outputFileNameFourWheel == "" ?
            previewFourWheelerVehicle :
            "https://admin.vahanischolarship.in/Images/" +
            "StudentInformation/" + otp5 +
            "FourWheelerImg" +
            "-" +
            outputFileNameFourWheel,
        vehicleimage:
          outputFileNameVehicle == ""
            ? previewUrlVehicle
            : "https://admin.vahanischolarship.in/Images/" +
            "StudentInformation/" +
            otp3 +
            "-VehicleImage" +
            "-" +
            outputFileNameVehicle,
        familypic:
          outputFileNameFamily == ""
            ? previewUrlFamily
            : "https://admin.vahanischolarship.in/Images/" +
            "StudentInformation/" +
            otp4 +
            "FamilyImage" +
            "-" +
            outputFileNameFamily,
        mobileimage:
          outputFileNameMobile == ""
            ? previewUrlMobile
            : "https://admin.vahanischolarship.in/Images/" +
            "StudentInformation/" +
            otp2 +
            "-MobileImage" +
            "-" +
            outputFileNameMobile,
      },
      "UpdateStudentFamilyInformation"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          //
          Notiflix.Notify.success("Saved and Updated Successfully");
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  const [file, setFile] = useState();
  function handleChange(e) {
    //
    setFile(URL.createObjectURL(e.target.files[0]));
  } //select
  const onSubmit = (family) => {
    // e.preventDefault();
    console.log(family)
    onPost(family); //pass down the family data to post 
    // //
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  return (
    <>
      <div>
        <div className="form-head">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Family Information</h4>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-vahani p-0 " style={{ marginTop: "24px" }}>
            <p class="personal-info left-line-card px-lg-4 px-4 py-4">
              Father's Details
            </p>
            {ValidationList.length > 0 && (
              <div className="p-lg-4 p-3 row m-0">
                <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Name*"
                    variant="outlined"
                    className="text-field capital"
                    {...register("fld_Name", {
                      required: "This field is required",
                      pattern: {
                        value: new RegExp(ValidationList[0]?.fld_reg_ex, "i"),
                        message: "Enter valid name",
                      },
                    })}
                    error={!!errors?.fld_Name}
                    helperText={
                      errors?.fld_Name ? errors.fld_Name.message : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-lg-6 col-12 mb-lg-3 form-auto mobile-personal">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Contact Number*"
                    type="text"
                    variant="outlined"
                    className="text-field"
                    {...register("fld_mobile", {
                      required: "This field is required",
                      minLength: {
                        value: 10,
                        message: "Enter minimum 10-digit number",
                      },
                      pattern: {
                        value: new RegExp(ValidationList[2]?.fld_reg_ex, "i"),
                        message: "Enter valid mobile number",
                      },
                    })}
                    error={!!errors?.fld_mobile}
                    helperText={
                      errors?.fld_mobile ? errors.fld_mobile.message : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </div>
                <div className="col-lg-6 col-12 mb-lg-3 form-auto mobile-personal">
                  <FormControl fullWidth error={Boolean(errors.fld_Education)}>
                    <InputLabel id="demo-simple-select-label">
                      Educational Qualification*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select1"
                      label="Educational Qualification*"
                      {...register("fld_Education", {
                        required: "This field is required ",
                      })}
                      error={!!errors?.fld_Education}
                      value={values.fld_Education}
                      onChange={onChangeHandler}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {educationQaDropdown.map((menuItem) => {
                        return (
                          <MenuItem value={menuItem}>
                            {menuItem}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    <FormHelperText>
                      {errors?.fld_Education
                        ? errors.fld_Education.message
                        : null}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-lg-6 col-12 mb-lg-3 form-auto mobile-personal ">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Occupation*"
                    type="text"
                    variant="outlined"
                    className="text-field"
                    {...register("fld_Occupation", {
                      required: "This field is required",
                    })}
                    error={!!errors?.fld_Occupation}
                    helperText={
                      errors?.fld_Occupation
                        ? errors.fld_Occupation.message
                        : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.fld_Occupation}
                    onChange={onChangeHandler}
                  />

                  {/* <FormControl fullWidth error={Boolean(errors.fld_Occupation)}>
                    <InputLabel id="demo-simple-select-label">
                      Occupation*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select2"
                      label="Occupation*"
                      {...register("fld_Occupation", {
                        required: "This field is required",
                      })}
                      error={!!errors?.fld_Occupation}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.fld_Occupation}
                      onChange={onChangeHandler}
                    >
                      <MenuItem value={"Salaried"}>Salaried</MenuItem>
                      <MenuItem value={"Self Employed"}>Self Employed</MenuItem>
                      <MenuItem value={"Retired"}>Retired</MenuItem>
                      <MenuItem value={"Professional"}>Professional</MenuItem>
                      <MenuItem value={"Unemployed"}>Unemployed</MenuItem>
                    </Select>
                    <FormHelperText>
                      {errors?.fld_Occupation
                        ? errors.fld_Occupation.message
                        : null}
                    </FormHelperText>
                  </FormControl> */}
                </div>
                <div className="col-lg-4 col-12 mb-lg-3 mobile-personal">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Email Id"
                    type="email"
                    variant="outlined"
                    className="text-field"
                    {...register("fld_Email", {
                      required: false,
                      pattern: {
                        value: new RegExp(ValidationList[1]?.fld_reg_ex, "i"),
                        message: "Invalid email address",
                      },
                    })}
                    error={!!errors?.fld_Email}
                    helperText={
                      errors?.fld_Email ? errors.fld_Email.message : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-lg-4 col-12 mb-lg-3 mobile-personal">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="PAN Card Number"
                    variant="outlined"
                    className="text-field"
                    {...register("fld_Pan", {
                      required: false,
                      pattern: {
                        value: new RegExp(ValidationList[8]?.fld_reg_ex),
                        message: ValidationList[8]?.fld_reg_ex_msg,
                      },
                      minLength: {
                        value: 10,
                        message: "Enter maximum 10-digit number",
                      },
                    })}
                    onChange
                    error={!!errors?.fld_Pan}
                    helperText={errors?.fld_Pan ? errors.fld_Pan.message : null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-lg-4 col-12 mb-lg-3 mobile-personal">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Aadhar Card"
                    variant="outlined"
                    className="text-field"
                    {...register("fld_Aadhar", {
                      required: false,
                      pattern: {
                        value: new RegExp(ValidationList[9]?.fld_reg_ex, "i"),
                        message: "Enter valid Aadhar card number",
                      },
                      minLength: {
                        value: 12,
                        message: "Enter minimum 12-digit number",
                      },
                      maxLength: {
                        value: 12,
                        message: "Enter maximum 12-digit number",
                      },
                    })}
                    error={!!errors?.fld_Aadhar}
                    helperText={
                      errors?.fld_Aadhar ? errors.fld_Aadhar.message : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                {/* <div
                  className={
                    fathersignvalue == null
                      ? "col-lg-6 col-12 mb-lg-3 mobile-personal"
                      : "col-lg-5 mb-lg-3 mobile-personal"
                  }
                >
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Upload Signature (Maximum Size 6Mb | File Format .pdf .jpeg)"
                    variant="outlined"
                    className="text-field"
                    type="File"
                    onChange={(e) => {
                      e.preventDefault();
                      if (e.target.files[0].size < 6000000) {
                        const reader = new FileReader();
                        const file = e.target.files[0];
                        console.log(file.name);
                        reader.onloadend = () => {
                          setFatherSign(file);
                        };
                        reader.readAsDataURL(file);
                      } else {
                        Notiflix.Notify.failure(
                          "File too large, upload file less than 6 MB."
                        );
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ accept: "application/pdf, image/jpeg" }}
                  />
                </div> */}
                {/* <div
                  className="col-lg-1 mb-lg-3 mobile-personal"
                  style={{
                    display: fathersignvalue == null ? "none" : "block",
                    textAlign: "center",
                  }}
                 >
                  <a
                    target="_blank"
                    className="but-login-vahani"
                    href={fathersignvalue}
                  >
                    View
                  </a>
                </div> */}
              </div>
            )}
          </div>
          {/* mother */}
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p class="personal-info left-line-card px-lg-4 px-3 py-4">
              Mother's Details
            </p>
            <div className="row p-lg-4 p-2 m-0">
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Name*"
                  variant="outlined"
                  className="text-field capital"
                  {...register("fld_Name_mother", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[0]?.fld_reg_ex, "i"),
                      message: "Enter valid name",
                    },
                  })}
                  error={!!errors?.fld_Name_mother}
                  helperText={
                    errors?.fld_Name_mother
                      ? errors.fld_Name_mother.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 form-auto mobile-personal">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Contact Number*"
                  type="text"
                  variant="outlined"
                  {...register("fld_mobile_mother", {
                    required: "This field is required",
                    minLength: {
                      value: 10,
                      message: "Enter minimum 10-digit number",
                    },
                    pattern: {
                      value: new RegExp(ValidationList[2]?.fld_reg_ex, "i"),
                      message: "Enter valid mobile number",
                    },
                  })}
                  error={!!errors?.fld_mobile_mother}
                  helperText={
                    errors?.fld_mobile_mother
                      ? errors.fld_mobile_mother.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 form-auto mobile-personal">
                <FormControl fullWidth error={Boolean(errors.fld_Education)}>
                  <InputLabel id="demo-simple-select-label">
                    Educational Qualification*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select1"
                    label="Educational Qualification*"
                    {...register("fld_Education_mother", {
                      required: "This field is required ",
                    })}
                    error={!!errors?.fld_Education_mother}
                    value={values.fld_Education_mother}
                    onChange={onChangeHandler}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {educationQaDropdown.map((menuItem) => {
                      return (
                        <MenuItem value={menuItem}>
                          {menuItem}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_Education_mother
                      ? errors.fld_Education_mother.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal form-auto">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Occupation*"
                  type="text"
                  variant="outlined"
                  {...register("fld_Occupation_mother", {
                    required: "This field is required",
                  })}
                  error={!!errors?.fld_Occupation_mother}
                  helperText={
                    errors?.fld_Occupation_mother
                      ? errors.fld_Occupation_mother.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.fld_Occupation_mother}
                  onChange={onChangeHandler}
                />

                {/* <FormControl
                  fullWidth
                  error={Boolean(errors.fld_Occupation_mother)}
                >
                  <InputLabel id="demo-simple-select-label">
                    Occupation*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    label="Occupation*"
                    {...register("fld_Occupation_mother", {
                      required: "This field is required",
                    })}
                    error={!!errors?.fld_Occupation_mother}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.fld_Occupation_mother}
                    onChange={onChangeHandler}
                  >
                    <MenuItem value={"Salaried"}>Salaried</MenuItem>
                    <MenuItem value={"Self Employed"}>Self Employed</MenuItem>
                    <MenuItem value={"Retired"}>Retired</MenuItem>
                    <MenuItem value={"Professional"}>Professional</MenuItem>
                    <MenuItem value={"Homemaker"}>Homemaker</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.fld_Occupation_mother
                      ? errors.fld_Occupation_mother.message
                      : null}
                  </FormHelperText>
                </FormControl> */}
              </div>
              <div className="col-lg-4 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Email Id"
                  type="email"
                  variant="outlined"
                  {...register("fld_Email_mother", {
                    required: false,
                    pattern: {
                      value: new RegExp(ValidationList[1]?.fld_reg_ex, "i"),
                      message: "Invalid email address",
                    },
                  })}
                  error={!!errors?.fld_Email_mother}
                  helperText={
                    errors?.fld_Email_mother
                      ? errors.fld_Email_mother.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-4 col-12 mb-lg-3 mobile-personal ">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="PAN Card Number"
                  variant="outlined"
                  className="text-field"
                  {...register("fld_Pan_mother", {
                    required: false,
                    pattern: {
                      value: new RegExp(ValidationList[8]?.fld_reg_ex),
                      message: ValidationList[8]?.fld_reg_ex_msg,
                    },
                    minLength: {
                      value: 10,
                      message: "Enter maximum 10-digit number",
                    },
                  })}
                  error={!!errors?.fld_Pan_mother}
                  helperText={
                    errors?.fld_Pan_mother
                      ? errors.fld_Pan_mother.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-4 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Aadhar Card"
                  variant="outlined"
                  className="text-field"
                  {...register("fld_Aadhar_mother", {
                    required: false,
                    pattern: {
                      value: new RegExp(ValidationList[9]?.fld_reg_ex, "i"),
                      message: "Enter valid Aadhar card number",
                    },
                    minLength: {
                      value: 12,
                      message: "Enter minimum 12-digit number",
                    },
                    minLength: {
                      value: 12,
                      message: "Enter maximum 12-digit number",
                    },
                  })}
                  error={!!errors?.fld_Aadhar_mother}
                  helperText={
                    errors?.fld_Aadhar_mother
                      ? errors.fld_Aadhar_mother.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              {/* <div
                className={
                  mothersign == null
                    ? "col-lg-6 col-12 mb-lg-3 mobile-personal"
                    : "col-lg-5 mb-lg-3 mobile-personal"
                }
               >
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Upload Signature (Maximum Size 6Mb | File Format .pdf .jpeg)"
                  variant="outlined"
                  className="text-field"
                  type="File"
                  onChange={(e) => {
                    e.preventDefault();

                    if (e.target.files[0].size < 6000000) {
                      const reader = new FileReader();
                      const file = e.target.files[0];
                      reader.onloadend = () => {
                        setMotherSign(file);
                      };
                      reader.readAsDataURL(file);
                    } else {
                      Notiflix.Notify.failure(
                        "File too large, upload file less than 6 MB."
                      );
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ accept: "application/pdf, image/jpeg" }}
                />
              </div>
              <div
                className="col-lg-1 mb-lg-3  mobile-personal"
                style={{
                  display: mothersign == null ? "none" : "block",
                  textAlign: "center",
                }}
              >
                <a
                  target="_blank"
                  className="but-login-vahani "
                  href={mothersign}
                >
                  View
                </a>
              </div> */}
            </div>
          </div>
          {/* mother */}
          {/* gr */}
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p class="personal-info left-line-card px-lg-4 px-4 py-4">
              Guardian's Details
            </p>
            <div className="row p-lg-4 p-2 m-0">
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  className="text-field capital"
                  {...register("fld_Name_Guardian", {
                    required: false,
                    pattern: {
                      value: new RegExp(ValidationList[0]?.fld_reg_ex, "i"),
                      message: "Enter valid name",
                    },
                  })}
                  error={errors?.fld_Name_Guardian}
                  helperText={
                    errors?.fld_Name_Guardian
                      ? errors.fld_Name_Guardian.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal form-auto ">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Contact Number"
                  type="text"
                  variant="outlined"
                  className="text-field"
                  {...register("fld_mobile_Guardian", {
                    minLength: {
                      value: 10,
                      message: "Enter minimum 10-digit number",
                    },
                    pattern: {
                      value: new RegExp(ValidationList[2]?.fld_reg_ex, "i"),
                      message: "Enter valid mobile number",
                    },
                  })}
                  error={!!errors?.fld_mobile_Guardian}
                  helperText={
                    errors?.fld_mobile_Guardian
                      ? errors.fld_mobile_Guardian.message
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </div>
              <div className="col-lg-12 col-12 mb-lg-3 form-auto mobile-personal">
                <FormControl fullWidth error={Boolean(errors.fld_relation_with_student)}>
                  <InputLabel id="demo-simple-select-label">
                    Relationship With Student
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select1"
                    label="Relationship with student*"
                    {...register("fld_relation_with_student", {
                      required: "This field is required ",
                    })}
                    error={!!errors?.fld_relation_with_student}
                    value={values.fld_relation_with_student}
                    onChange={onChangeHandler}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {guardianRelation.map((relation) => {
                      return (
                        <MenuItem value={relation}>
                          {relation}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_relation_with_student
                      ? errors.fld_relation_with_student.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>

              {/* <div
                className={
                  guardiansign == null
                    ? "col-lg-6 col-12 mb-lg-3 mobile-personal"
                    : "col-lg-5 mb-lg-3 mobile-personal"
                }
              >
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Upload Signature (Maximum Size 6Mb | File Format .pdf .jpeg)"
                  variant="outlined"
                  className="text-field"
                  type="File"
                  onChange={(e) => {
                    e.preventDefault();
                    if (e.target.files[0].size < 6000000) {
                      const reader = new FileReader();
                      const file = e.target.files[0];
                      reader.onloadend = () => {
                        setGuardianSign(file);
                      };
                      reader.readAsDataURL(file);
                    } else {
                      Notiflix.Notify.failure(
                        "File too large, upload file less than 6 MB."
                      );
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ accept: "application/pdf, image/jpeg" }}
                />
              </div> */}
              {/* <div
                className="col-lg-1 mb-lg-3 mobile-personal"
                style={{
                  display: guardiansign == null ? "none" : "block",
                  textAlign: "center",
                }}
               >
                <a
                  target="_blank"
                  href={guardiansign}
                  className="but-login-vahani"
                >
                  View
                </a>
              </div> */}
              <div className="mb-lg-3 col-lg-6 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Address"
                  multiline
                  variant="outlined"
                  {...register("fld_Address", {
                    pattern: {
                      value: new RegExp(ValidationList[3]?.fld_reg_ex, "i"),
                      message: ValidationList[3]?.fld_reg_ex_msg,
                    },
                  })}
                  error={!!errors?.fld_Address}
                  helperText={
                    errors?.fld_Address ? errors.fld_Address.message : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-3 col-12 mb-lg-3 mobile-personal">
                <FormControl fullWidth error={Boolean(errors.fld_Country)}>
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    label="Country*"
                    {...register("fld_Country")}
                    error={!!errors?.fld_Country}
                    onChange={handlecountry}
                    value={countryid}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {country.map((record, item) => {
                      return (
                        <MenuItem value={record.value}>{record.label}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_Country ? errors.fld_Country.message : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-3 col-12 mb-lg-3 mobile-personal">
                <FormControl fullWidth error={Boolean(errors.fld_State)}>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    label="State*"
                    {...register("fld_State")}
                    error={!!errors?.fld_State}
                    onChange={handlestate}
                    value={stateid}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {st.map((record, item) => {
                      return (
                        <MenuItem value={record.value}>{record.label}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_State ? errors.fld_State.message : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-3 col-12 mb-lg-3 mobile-personal">
                <FormControl fullWidth error={Boolean(errors.fld_City)}>
                  <InputLabel id="demo-simple-select-label">City</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    label="City*"
                    {...register("fld_City")}
                    error={!!errors?.fld_City}
                    onChange={handlecity}
                    value={cityid}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {city.length > 0 &&
                      city.map((record, item) => {
                        return (
                          <MenuItem value={record.value}>
                            {record.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_City ? errors.fld_City.message : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-3 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Pincode"
                  variant="outlined"
                  className="text-field"
                  {...register("fld_Pincode", {
                    pattern: {
                      value: new RegExp(ValidationList[4]?.fld_reg_ex, "i"),
                      message: "Enter valid pincode numbe",
                    },
                    minLength: {
                      value: 6,
                      message: "Enter minimum 6-digit number",
                    },
                  })}
                  error={!!errors?.fld_Pincode}
                  helperText={
                    errors?.fld_Pincode ? errors.fld_Pincode.message : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 6,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p class="personal-info left-line-card px-lg-4 px-4 py-4">
              Upload Documents
            </p>
            <div className="row m-0 p-lg-4 p-2">
              <div className="col-lg-3 mobile-personal ">
                <ImgUploadLaptop
                  onChange2={(e) => {
                    e.preventDefault();
                    const imageFile = e.target.files[0];
                    setPreviewUrlLaptop(URL.createObjectURL(imageFile));
                    setoriginalImageLaptop(imageFile);
                    setoutputFileNameLaptop(imageFile.name);
                    setuploadImageLaptop(true);
                  }}
                  src2={previewUrlLaptop}
                />
                <div className="upload-image-family text-center">
                  <span>If you have a Laptop,</span>
                  <br />
                  Upload Your Laptop Picture (Maximum Size 2MB | File Format
                  *.jpeg, *.jpg, *.png)
                </div>
              </div>
              <div className="col-lg-3 mobile-personal ">
                <ImgUploadFamilyPicture
                  onChange1={(e) => {
                    e.preventDefault();
                    const imageFile = e.target.files[0];
                    setPreviewUrlFamily(URL.createObjectURL(imageFile));
                    setoriginalImageFamily(imageFile);
                    setoutputFileNameFamily(imageFile.name);
                    setuploadImageFamily(true);
                  }}
                  src1={previewUrlFamily}

                />
                <div className="upload-image-family text-center">
                  <span>If you have a Family,</span>
                  <br />
                  Upload Your Family Picture (Maximum Size 2MB | File Format
                  *.jpeg, *.jpg, *.png)
                </div>
              </div>
              {/* <div className="col-lg-3 mobile-personal">
                <ImgUpload
                  onChange={(e) => {
                    e.preventDefault();
                    const imageFile = e.target.files[0];
                    setPreviewUrlMobile(URL.createObjectURL(imageFile));
                    setoriginalImageMobile(imageFile);
                    setoutputFileNamemobile(imageFile.name);
                    setuploadImageMobile(true);
                  }}
                  src={previewUrlMobile}
                />
                <div className="upload-image-family text-center">
                  <span>If you have a Mobile,</span>
                  <br />
                  Upload Your Mobile Picture (Maximum Size 2MB | File Format
                  *.jpeg, *.jpg, *.png)
                </div>
              </div> */}

              <div className="col-lg-3 mobile-personal">
                <ImgUploadVehicle
                  onChange3={(e) => {
                    e.preventDefault();
                    const imageFile = e.target.files[0];
                    setPreviewUrlVehicle(URL.createObjectURL(imageFile));
                    setoriginalImageVehicle(imageFile);
                    setoutputFileNameVehicle(imageFile.name);
                    setuploadImageVehicle(true);
                  }}
                  src3={previewUrlVehicle}
                />
                <div className="upload-image-family">
                  <span>If you have a two-wheeler Vehicle,</span>
                  <br />
                  Upload Your Vehicle Picture (Maximum Size 2MB | File Format
                  *.jpeg, *.jpg, *.png)
                </div>
              </div>

              <div className="col-lg-3 mobile-personal">
                <FourWheelerImgUpload
                  onChange4={(e) => {
                    e.preventDefault();
                    const imageFile = e.target.files[0];
                    console.log(imageFile)
                    setPreviewFourWheelerVehicle(URL.createObjectURL(imageFile));
                    setoriginalImageFourWheel(imageFile);
                    setoutputFileNameFourWheel(imageFile.name);
                    setuploadImageFourWheel(true);
                  }}
                  src4={previewFourWheelerVehicle}
                />
                <div className="upload-image-family">
                  <span>If you have a four-wheeler Vehicle,</span>
                  <br />
                  Upload Your Vehicle Picture  (Maximum Size 2MB | File Format
                  *.jpeg, *.jpg, *.png)
                </div>
              </div>

            </div>
          </div>
          {action != "view" && (
            <div className="card-vahani" style={{ marginTop: "26px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <div className="but-width-form">
                  <Button
                    variant="contained"
                    className="but-login-vahani"
                    type="submit"
                  >
                    Save And Update
                  </Button>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
