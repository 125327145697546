import "../Personalinfo/Personalinfo.css";
import React from "react";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
import moment from "moment";
import Notiflix from "notiflix";
import TextField from "@mui/material/TextField";
export default function ExtraCurriculation({ stdid, action }) {
  var login = stdid ? { fld_id: stdid } : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  React.useEffect(() => {
    // Notiflix.Loading.dots();
    GetStudentExtraCurricular();
  }, []);
  const GetStudentExtraCurricular = () => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "GetStudentExtraCurricularActivitiesInformation_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          // Notiflix.Loading.remove();
          reset(obj.data[0]);
        }
      })
    );
  };
  const onPost = (excuac) => {
    if (
      excuac.fld_sports != "" ||
      excuac.fld_communityService != "" ||
      excuac.fld_studentLeadership != "" ||
      excuac.fld_performingArts != "" ||
      excuac.fld_specialHonours != "" ||
      excuac.fld_others != ""
    ) {
      Notiflix.Loading.dots();
      var detail = JSON.parse(localStorage.getItem("LoginDetail"))[0];
      PostApiCall.postRequest(
        {
          studentId: login.fld_id,
          sports: excuac.fld_sports,
          communityService: excuac.fld_communityService,
          performingArts: excuac.fld_performingArts,
          studentLeadership: excuac.fld_studentLeadership,
          specialHonours: excuac.fld_specialHonours,
          others: excuac.fld_others,
          updatedby: detail.fld_id,
          updatedon: moment().format("lll"),
        },
        "UpdateStudentExtraCurricularActivitiesInformation"
      ).then((result) =>
        result.json().then((obj) => {
          if (result.status == 200 || result.status == 201) {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Saved and Updated Successfully");
            GetStudentExtraCurricular();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("There seems to be an Error. Please retry!");
          }
        })
      );
    } else {
      Notiflix.Notify.failure("Please write about others curricular activities.");
    }
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (excuac) => {
    // e.preventDefault();
    onPost(excuac);
    //
  };
  return (
    <>
      <div className="form-head">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Extra Curricular Activities Details</h4>
          {/* <p style={{ margin: "0px", fontSize: "0.9rem" }}>
              Drop files here or click browse thorough your machine
            </p> */}
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
          <p className="personal-info left-line-card px-lg-4 px-3 py-4">Co-Scholastic Activities</p>
          <div className="row m-0 p-lg-4 p-2">
            <div className="col-lg-12 col-12 mb-lg-3 mobile-personal">
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Sports"
                multiline
                {...register("fld_sports", { required: false, })}
                value={watch("fld_sports") ? watch("fld_sports") : ""}
                error={errors?.fld_sports}
                helperText={errors?.fld_sports ? errors.fld_sports.message : null}
                onChange={e => {
                  if (e.target.value.trim().split(" ").length < 501) {
                    setValue("fld_sports", e.target.value)
                  } else {
                    setError('fld_sports', { type: 'custom', message: 'Enter maximum 500 words' });
                  }
                }}
              />
            </div>
            <div className="col-lg-12 col-12 mb-lg-3 mobile-personal">
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Community Service"
                multiline
                {...register("fld_communityService", { required: false, })}
                value={watch("fld_communityService") ? watch("fld_communityService") : ""}
                error={errors?.fld_communityService}
                helperText={errors?.fld_communityService ? errors.fld_communityService.message : null}
                onChange={e => {
                  if (e.target.value.trim().split(" ").length < 501) {
                    setValue("fld_communityService", e.target.value)
                  } else {
                    setError('fld_communityService', { type: 'custom', message: 'Enter maximum 500 words' });
                  }
                }}
              />
            </div>
            <div className="col-lg-12 col-12 mb-lg-3 mobile-personal">
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Performing Arts"
                multiline
                {...register("fld_performingArts", { required: false, })}
                value={watch("fld_performingArts") ? watch("fld_performingArts") : ""}
                error={errors?.fld_performingArts}
                helperText={errors?.fld_performingArts ? errors.fld_performingArts.message : null}
                onChange={e => {
                  if (e.target.value.trim().split(" ").length < 501) {
                    setValue("fld_performingArts", e.target.value)
                  } else {
                    setError('fld_performingArts', { type: 'custom', message: 'Enter maximum 500 words' });
                  }
                }}
              />
            </div>
            <div className="col-lg-12 col-12 mb-lg-3 mobile-personal">
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Student Leadership Arts"
                multiline
                {...register("fld_studentLeadership", { required: false, })}
                value={watch("fld_studentLeadership") ? watch("fld_studentLeadership") : ""}
                error={errors?.fld_studentLeadership}
                helperText={errors?.fld_studentLeadership ? errors.fld_studentLeadership.message : null}
                onChange={e => {
                  if (e.target.value.trim().split(" ").length < 501) {
                    setValue("fld_studentLeadership", e.target.value)
                  } else {
                    setError('fld_studentLeadership', { type: 'custom', message: 'Enter maximum 500 words' });
                  }
                }}
              />
            </div>
            <div className="col-lg-12 col-12 mb-lg-3 mobile-personal">
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Special Honours"
                multiline
                {...register("fld_specialHonours", { required: false, })}
                value={watch("fld_specialHonours") ? watch("fld_specialHonours") : ""}
                error={errors?.fld_specialHonours}
                helperText={errors?.fld_specialHonours ? errors.fld_specialHonours.message : null}
                onChange={e => {
                  if (e.target.value.trim().split(" ").length < 501) {
                    setValue("fld_specialHonours", e.target.value)
                  } else {
                    setError('fld_specialHonours', { type: 'custom', message: 'Enter maximum 500 words' });
                  }
                }}
              />
            </div>
            <div className="col-lg-12 col-12 mb-lg-3 mobile-personal">
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Others(Any Projects / Extra Activities)"
                multiline
                {...register("fld_others", { required: false, })}
                value={watch("fld_others") ? watch("fld_others") : ""}
                error={errors?.fld_others}
                helperText={errors?.fld_others ? errors.fld_others.message : null}
                onChange={e => {
                  if (e.target.value.trim().split(" ").length < 501) {
                    setValue("fld_others", e.target.value)
                  } else {
                    setError('fld_others', { type: 'custom', message: 'Enter maximum 500 words' });
                  }
                }}
              />
            </div>
          </div>
        </div>
        {action != "view" &&
          <div className="card-vahani" style={{ marginTop: "26px" }}>
            <div className="d-flex justify-content-end" >
              <div className="but-width-form">
                <Button variant="contained" className="but-login-vahani" type="submit" >
                  Save and update
                </Button>
              </div>
            </div>
          </div>
        }
      </form>
    </>
  );
}
