import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { MDBDataTable } from "mdbreact";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PostApiCall from "../../../assets/API/PostApi";
// import GetApiCall from "../../../assets/API/GetApi";
import moment from "moment";
import Notiflix from "notiflix";
import store from "../../../store";
export default function ApprovalList() {
  const history = useHistory();
  let login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const { setexpenseData, expenseData, NotificationData } = useContext(store);
  const [ConditionType, setConditionType] = useState("Open");
  const [expenseDetail, setexpenseDetail] = useState(null);
  const [addTaskData, setTaskData] = useState([]);
  const [expenseFields, setexpenseFields] = useState([]);
  const [SortData, setSortData] = React.useState({
    type: "desc",
    value: "fld_id",
  });
  const [dateData, setdateData] = React.useState({ from: "", to: "" });
  const [rows, setrows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showModal, setshowModal] = useState(false);
  const [showHideModal, setshowHideModal] = useState(false);
  const [holdReason, setholdReason] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [searchInput, setSearchinput] = useState(""); // newly added state
  const [filteredData, setFilteredData] = useState([]); // newly added state
  const [totalexpenseamt, SetTotalExpenseAmt] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getScholarExpenseDetails = (data) => {
    PostApiCall.postRequest(
      {
        whereClause: `where expense_master_id=${data.fld_id} and fld_studentId=${data.fld_studentId} order by fld_sequence asc`,
      },
      "Get_Scholar_Expense_Details"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          //
          setexpenseFields(
            obj.data.map((val) => ({
              fld_field_name: val.fld_field_name,
              fld_value: val.fld_answer,
            }))
          );
          Notiflix.Loading.remove();
        }
      })
    );
  };
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckedItems([...checkedItems, value]);
      const updatedData = [...addTaskData];
      // Find the index of the object to update
      const index = updatedData.findIndex(
        (item) => item.fld_id === parseInt(event.target.value)
      );

      if (index !== -1) {
        // Update the object in the copied array
        updatedData[index] = {
          ...updatedData[index],
          is_selected: "true",
        };

        // Set state with the updated array
        setTaskData(updatedData);
      }
    } else {
      const updatedItems = checkedItems.filter((item) => item !== value);
      setCheckedItems(updatedItems);
      const updatedData = [...addTaskData];

      // Find the index of the object to update
      const index = updatedData.findIndex(
        (item) => item.fld_id === parseInt(event.target.value)
      );

      if (index !== -1) {
        // Update the object in the copied array
        updatedData[index] = {
          ...updatedData[index],
          is_selected: "false",
        };

        // Set state with the updated array
        setTaskData(updatedData);
      }
    }
  };
  const getApprovalList = (condition) => {
    //
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        // whereClause: `where ${condition == "Open" ? `exists(select 1 from udv_expenseapprovalhistory eh where eh.fld_expense_id = ei.fld_id and eh.fld_pending_with='${login.fld_type}' and eh.fld_status = 'Open')` : `fld_approval_status='${condition}'`} order by ${SortData.value} ${SortData.type}`,
        whereClause:
          condition == "All"
            ? `order by ${SortData.value} ${SortData.type}`
            : `where fld_approval_status='${condition}' ${
                dateData.from != "" && dateData.to != ""
                  ? `and fld_requestedon between '${dateData.from}' and '${dateData.to}' `
                  : ""
              } order by ${SortData.value} ${SortData.type}`,
      },
      "Get_ScholarExpenseInformation"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          //
          PostApiCall.postRequest(
            {
              status: condition,
            },
            "Get_Total_Expense_Amount"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status === 200 || results.status === 201) {
                //
                if (obj.data[0].total_amt == null) {
                  SetTotalExpenseAmt(0);
                } else {
                  SetTotalExpenseAmt(obj.data[0].total_amt);
                }
              }
            })
          );
          Notiflix.Loading.remove();
          setrows(obj.data);
          setFilteredData(obj.data);
        }
      })
    );
  };
  useEffect(() => {
    if (expenseData?.adminAction) {
      setConditionType(expenseData.adminAction);
      getApprovalList(expenseData.adminAction);
    } else {
      if (login.fld_type == "Checker") {
        setConditionType("Pending");
        getApprovalList("Pending");
      } else if (login.fld_type == "Approver") {
        setConditionType("Verified");
        getApprovalList("Verified");
      } else if (login.fld_type == "Accountant") {
        setConditionType("Approved");
        getApprovalList("Approved");
      }
    }
  }, []);
  const saveForm = () => {
    var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
    PostApiCall.postRequest(
      {
        expenseid: expenseDetail.fld_id,
        actionby: login.fld_id,
        post: login.fld_type,
        comments: null,
        action: login.fld_type == "Checker" ? "Verified" : "Approved",
      },
      "Update_ScholarExpenseStatus"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Loading.remove();
          window.location.reload();
          Notiflix.Notify.success("Expense Status Updated Successfully.");
          setshowModal(false);
          getApprovalList("Open");
        }
      })
    );
  };
  const HoldExpense = (status = null) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        expenseid: expenseDetail.fld_id,
        userid: login.fld_id,
        comments: status == null || holdReason == "" ? null : holdReason,
        status: status,
      },
      "Add_Admin_Expense_Hold"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Expense Hold Successfully.");
          window.location.reload();
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      })
    );
  };
  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Pending Payments</h4>
        </div>
      </div>
      <div className="card-vahani p-0">
        <div className="row m-0 justify-content-between align-items-center left-line-card px-4 pt-3  ">
          <div className="personal-info m-0 p-0 col-auto mb-3 ">
            Pending Payment's List{" "}
          </div>
          <div className="col-md-4 mb-3 text-center">
            <span className="fw-bolder">Total Pending Amount</span> : ₹{" "}
            {totalexpenseamt}
          </div>
        </div>
        <div className="row m-0 justify-content-between border-top p-2 ">
          <div className="col-xl-auto col-12 mt-3">
            <TextField
              type="date"
              label="From Date"
              id="outlined-size-small"
              size="small"
              value={dateData.from}
              onChange={(e) =>
                setdateData({ ...dateData, from: e.target.value })
              }
              InputLabelProps={{ shrink: true }}
              className="mb-md-0 mb-3 col-sm-auto col-12"
            />
            <TextField
              type="date"
              label="To Date"
              id="outlined-size-small"
              size="small"
              value={dateData.to}
              onChange={(e) => setdateData({ ...dateData, to: e.target.value })}
              InputLabelProps={{ shrink: true }}
              className="mx-sm-3 mx-0 mb-md-0 mb-3 col-sm-auto col-12"
            />
            <Button
              className="mx-sm-3 mx-0 mb-md-0 mb-3 col-sm-auto col-12"
              variant="contained"
              onClick={() => {
                if (dateData.from != "") {
                  if (dateData.to != "") {
                    getApprovalList(ConditionType);
                  } else {
                    Notiflix.Notify.failure("Enter To date");
                  }
                } else {
                  Notiflix.Notify.failure("Enter From date");
                }
              }}
            >
              Filter
            </Button>
            <Button
              className="col-sm-auto col-12"
              variant="contained"
              onClick={() => {
                if (checkedItems != "") {
                  getApprovalList(ConditionType);
                } else {
                  Notiflix.Notify.failure(
                    "Please select at least one payment to download the file."
                  );
                }
              }}
            >
              Download Payment File
            </Button>
          </div>
          <div className="col-xl-auto col-12 row m-0 mt-3">
            <div className="col-sm-auto mb-sm-0 mb-2 col-12 p-0">
              <select
                class="form-select form-select-sm "
                aria-label=".form-select-sm example"
                onChange={(e) =>
                  setSortData({ ...SortData, value: e.target.value })
                }
              >
                <option value="fld_id">Sort By</option>
                <option value="fld_studentId">Student ID</option>
                <option value="trim(full_name)">Name</option>
                <option value="trim(fld_expense_type)">Expense Type</option>
                <option value="fld_feeAmount">Amount</option>
                <option value="cast(fld_requestedon as date)">Raised On</option>
                <option value="trim(fld_approval_status)">Status</option>
              </select>
            </div>
            <div className="col-sm-auto col-12 p-0 mx-sm-3 mx-0 mb-sm-0 mb-3 ">
              <select
                class="form-select form-select-sm "
                aria-label=".form-select-sm example"
                onChange={(e) =>
                  setSortData({ ...SortData, type: e.target.value })
                }
              >
                <option value="desc">Order</option>
                <option value="asc">Ascending</option>
                <option value="desc">Descending </option>
              </select>
            </div>
            <Button
              className="col-sm-auto col-12 mb-sm-0 mb-3 "
              variant="contained"
              onClick={() => {
                getApprovalList(ConditionType);
              }}
            >
              Sort
            </Button>
          </div>
        </div>

        <div className="px-4 approval-search-mdbtable-hide">
          {rows.length && (
            <div className="col-sm-auto mb-sm-0 mb-2 col-12 p-0 mdb-replace-custom-payment-searchbar">
              <input
                className="form-control form-control-sm ml-0 common-search-bar"
                type="text"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => {
                  setSearchinput(e.target.value);
                }}
                value={searchInput}
              />
            </div>
          )}
          {rows.length > 0 ? (
            <MDBDataTable
              className="position-relative"
              disableRetreatAfterSorting={true}
              responsive
              hover
              striped
              data={{
                columns: [
                  {
                    label: "Txn ID",
                    field: "txn",
                    sort: "disabled",
                  },
                  {
                    label: "Student ID",
                    field: "studentid",
                    sort: "disabled",
                  },
                  {
                    label: "Name",
                    field: "name",
                    sort: "disabled",
                  },
                  {
                    label: "Expense Type",
                    field: "expenseType",
                    sort: "disabled",
                  },
                  {
                    label: "Amount (₹)",
                    field: "amount",
                    sort: "disabled",
                  },
                  {
                    label: "Raised on",
                    field: "raised",
                    sort: "disabled",
                  },

                  {
                    label: "Status",
                    field: "status",
                    sort: "disabled",
                  },
                  {
                    label: "Select",
                    field: "action",
                    sort: "disabled",
                  },
                ],
                rows: filteredData
                  .filter((data) => {
                    if (searchInput == "") {
                      return data;
                    }
                    if (
                      // searchInput.toString() !== "" &&
                      data.fld_id.toString().includes(searchInput) ||
                      `${
                        data.fld_student_registration_no
                          ? data.fld_student_registration_no
                              .toString()
                              .toLowerCase()
                          : ""
                      }`
                        .toString()
                        .includes(searchInput.toString().toLowerCase()) ||
                      `${
                        data.full_name
                          ? data.full_name.toString().toLowerCase()
                          : ""
                      }`
                        .toString()
                        .includes(searchInput.toString().toLowerCase()) ||
                      `${
                        data.fld_expense_type != null
                          ? data.fld_expense_type.toString().toLowerCase()
                          : null
                      }`
                        .toString()
                        .includes(searchInput.toString().toLowerCase()) ||
                      `${
                        data.fld_feeAmount != null
                          ? data.fld_feeAmount.toString().toLowerCase()
                          : null
                      }`
                        .toString()
                        .includes(searchInput.toString().toLowerCase()) ||
                      `${
                        data.fld_approval_status != null
                          ? data.fld_approval_status.toString().toLowerCase()
                          : null
                      }`
                        .toString()
                        .includes(searchInput.toString().toLowerCase()) ||
                      `${
                        data.fld_requestedon != null
                          ? new Date(data.fld_requestedon)
                              .toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })
                              .toString()
                              .toLowerCase()
                          : null
                      }`.includes(searchInput.toString().toLowerCase())
                    ) {
                      return data;
                    }
                  })
                  .map((row, i) => {
                    return {
                      txn: row.fld_id,
                      studentid: row.fld_student_registration_no,
                      name: row.full_name,
                      expenseType: row.fld_expense_type,
                      amount: parseFloat(row.fld_feeAmount).toFixed(2),
                      raised: moment(row.fld_requestedon).format("DD/MM/YYYY"),
                      status: row.fld_approval_status,
                      action: (
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={row.fld_id}
                            checked={row.is_selected == "true" ? true : false}
                            id={i}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                      ),
                    };
                  }),
              }}
              entriesOptions={[10, 25, 50, 100]}
            ></MDBDataTable>
          ) : (
            <p className="m-3">No Data Found</p>
          )}
        </div>
      </div>
      <Dialog
        open={showModal}
        maxWidth={"sm"}
        onClose={() => setshowModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className="d-flex justify-content-between"
          id="alert-dialog-title"
        >
          <h4 className="px-2" style={{ borderBottom: "5px solid blue" }}>
            Approval Form
          </h4>{" "}
          <CancelIcon onClick={() => setshowModal(false)} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="row m-0 border  border-secondary rounded-3">
              <h5 className=" mt-2 mb-4 text-center">
                <b>Expense Type - </b>
                {expenseDetail?.fld_expense_type}
              </h5>
              {expenseFields.map((val) => {
                //
                return (
                  <div
                    className="row m-0 justify-content-between mb-2 border-secondary border-bottom"
                    style={{ wordBreak: "break-all" }}
                  >
                    <b className="col-6">{val?.fld_field_name}</b>
                    <div className="col-6">
                      {" "}
                      :
                      {val?.fld_value.includes("http") ? (
                        <a href={val?.fld_value} target="_blank">
                          {" "}
                          {val?.fld_value}
                        </a>
                      ) : (
                        ` ${val?.fld_value}`
                      )}
                    </div>
                    {/* val?.fld_field_name.includes("Date") ? moment(val?.fld_value).format("DD/MM/YYYY") : */}
                  </div>
                );
              })}
              <div className=" row m-0 justify-content-between mb-2 border-secondary border-bottom">
                <b className="col-6">Amount (₹)</b>
                <div className="col-6">
                  {" "}
                  : {expenseDetail?.fld_feeAmount}/-{" "}
                </div>
              </div>
              <div className=" row m-0 justify-content-between mb-2 border-secondary border-bottom">
                <b className="col-6">Amount In Words</b>
                <div className="col-6"> : {expenseDetail?.amtInWords} </div>
              </div>
              <div className=" row m-0 justify-content-between mb-2 border-secondary border-bottom">
                <b className="col-6">Pay To</b>
                <div className="col-6"> : {expenseDetail?.payto} </div>
              </div>
              <div className=" row m-0 justify-content-between mb-2 border-secondary border-bottom">
                <b className="col-6">Description</b>
                <div className="col-6"> : {expenseDetail?.fld_details} </div>
              </div>
              {expenseDetail?.fld_hold == "true" ? (
                <div className="row m-0 justify-content-between mb-3">
                  <button className="col-auto btn btn-danger" disabled>
                    Reject
                  </button>
                  <button className="col-auto btn btn-success" disabled>
                    Approve
                  </button>
                </div>
              ) : (
                <div className="row m-0 justify-content-between mb-3">
                  <button
                    className="col-auto btn btn-danger"
                    onClick={() => {
                      setexpenseData({
                        data: expenseDetail,
                        action: "view",
                        source: "admin",
                        adminAction: ConditionType,
                      });
                      history.push("/expense");
                    }}
                  >
                    Reject
                  </button>
                  <button
                    className="col-auto btn btn-success"
                    onClick={saveForm}
                  >
                    Approve
                  </button>
                </div>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showHideModal}
        maxWidth={"sm"}
        onClose={() => setshowHideModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className="d-flex justify-content-between"
          id="alert-dialog-title"
        >
          <h4 className="px-2" style={{ borderBottom: "5px solid blue" }}>
            {expenseDetail?.fld_hold == "true" ? "Locked" : "Lock"} Request
          </h4>{" "}
          <CancelIcon onClick={() => setshowHideModal(false)} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="row m-0 border p-3  border-secondary rounded-3">
              <TextField
                id="outlined-basic"
                label="Reason For Locking..."
                value={holdReason}
                onChange={(e) => setholdReason(e.target.value)}
                variant="outlined"
                rows={3}
                multiline
                fullWidth
              />
              {expenseDetail?.fld_hold == "true" && (
                <>
                  <h6
                    className="personal-info p-0 mt-3"
                    style={{ fontSize: "14px" }}
                  >
                    Locked by -{" "}
                    <span className="text-secondary">
                      {expenseDetail?.hold_by}
                    </span>{" "}
                  </h6>
                  <h6
                    className="personal-info p-0"
                    style={{ fontSize: "14px", lineHeight: "10px" }}
                  >
                    On -{" "}
                    <span className="text-secondary">
                      {moment(expenseDetail?.fld_hold_date).format(
                        "DD/MM/YYYY"
                      )}
                    </span>{" "}
                  </h6>
                </>
              )}
              <div className="d-flex justify-content-start mt-3">
                {expenseDetail?.fld_hold == "true" && (
                  <button
                    className="btn btn-success me-3"
                    onClick={() => HoldExpense()}
                  >
                    Unlock
                  </button>
                )}
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    if (holdReason != "") {
                      HoldExpense("true");
                    } else {
                      Notiflix.Notify.failure("Please Enter Reason.");
                    }
                  }}
                >
                  Lock it
                </button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
