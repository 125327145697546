import React, { Component, useContext, useEffect, useState } from "react";
import Notiflix from "notiflix";
import {
  MenuItem,
  TextField,
  Checkbox,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput,
  Button,
  Select,
  InputLabel,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import VendorAddressList from "./VendorAddressList";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import PostApiCall from "../../../assets/API/PostApi";
import store from "../../../store";
import GetApiCall from "../../../assets/API/GetApi";

export default function AddVendorAddress({ setShowVendorAddressList }) {
  let history = useHistory();
  const {
    vendorGlobalId,
    addressId,
    setAddressId,
    address1,
    setAddress1,
    address2,
    setAddress2,
    address3,
    setAddress3,
    landmark,
    setLandmark,
    pincode,
    setPincode,
    default1,
    setDefault1,
    countryData,
    setCountryData,
    currCountry,
    setCurrCountry,
    stateData,
    setStateData,
    currState,
    setCurrState,
    cityData,
    setCityData,
    currCity,
    setCurrCity,
  } = useContext(store);

  useEffect(() => {
    Notiflix.Loading.init({
      svgColor: "rgb(6,10,74)",
    });
    Notiflix.Loading.dots("Please wait...");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    GetApiCall.getRequest("GetCountry").then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setCountryData(obj.data);
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);

  function getState(value) {
    PostApiCall.postRequest({ countryid: value }, "GetState").then(
      (resultcategory) =>
        resultcategory.json().then((obj) => {
          if (resultcategory.status == 200 || resultcategory.status == 201) {
            setStateData(obj.data);
          } else {
            Notiflix.Notify.failure("Error Occurred");
          }
        })
    );
  }

  function getCity(value) {
    PostApiCall.postRequest({ stateid: value }, "GetCity").then(
      (resultcategory) =>
        resultcategory.json().then((obj) => {
          if (resultcategory.status == 200 || resultcategory.status == 201) {
            setCityData(obj.data);
          } else {
            Notiflix.Notify.failure("Error Occurred");
          }
        })
    );
  }

  function onSaveAddress() {
    Notiflix.Loading.init({
      svgColor: "rgb(6,10,74)",
    });
    Notiflix.Loading.dots("Please wait...");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    PostApiCall.postRequest(
      {
        id: addressId,
        vendorid: vendorGlobalId,
        address1: address1,
        address2: address2,
        address3: address3,
        landmark: landmark,
        country: currCountry,
        state: currState,
        userid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
        city: currCity,
        pincode: pincode,
        default1: default1,
      },
      "UpdateVendorAddress"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Address added successfully");
          setShowVendorAddressList(true);
        } else {
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }
  return (
    <>
      {/* {!showVendorAddress && ( */}
      <div>
        {/* <div className="form-head ">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Add Address</h4>
          </div>
        </div> */}
        <div className="card-vahani p-0 pb-4 ">
          <>
            <p className="personal-info left-line-card px-lg-4 px-3 py-4">
              Vendor Address Details
            </p>
            <div class="toast-body">
              <div class="row">
                <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row ps-2 pe-3">
                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Address Line 1"
                            // required={true}
                            value={address1}
                            onChange={(e) => {
                              setAddress1(e.target.value);
                            }}
                            multiline
                            rows={5}
                            className="text-field capitalonly"
                          />
                        </div>
                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Address Line 2"
                            // required={true}
                            value={address2}
                            onChange={(e) => {
                              setAddress2(e.target.value);
                            }}
                            multiline
                            rows={5}
                            className="text-field capitalonly"
                          />
                        </div>
                        <div className="col-lg-6 mb-lg-4 mobile-personal vendor-address3">
                          <TextField
                            fullWidth
                            type="text"
                            label="Address Line 3"
                            value={address3}
                            onChange={(e) => {
                              setAddress3(e.target.value);
                            }}
                            // required={true}
                            multiline
                            rows={4}
                            className="text-field capitalonly"
                          />
                        </div>
                        <div className="col-lg-6 mobile-personal">
                          <div className="row">
                            <div className="col-12 mb-lg-4 mobile-personal">
                              <Box>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Country
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // required={true}
                                    value={currCountry}
                                    label="Country"
                                    onChange={(e) => {
                                      setCurrCountry(e.target.value);
                                      getState(e.target.value);
                                    }}
                                  >
                                    <MenuItem>Select Country</MenuItem>
                                    {countryData.map((country, i) => {
                                      return (
                                        <MenuItem value={country.value}>
                                          {country.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                            <div className="col-12 mb-lg-4 mobile-personal">
                              <Box>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    State
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={currState}
                                    // required={true}
                                    label="State"
                                    onChange={(e) => {
                                      setCurrState(e.target.value);
                                      getCity(e.target.value);
                                    }}
                                  >
                                    <MenuItem>Select State</MenuItem>
                                    {stateData.map((state, i) => {
                                      return (
                                        <MenuItem value={state.value}>
                                          {state.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <Box>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                City
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                value={currCity}
                                id="demo-simple-select"
                                // required={true}
                                label="City"
                                onChange={(e) => {
                                  setCurrCity(e.target.value);
                                }}
                              >
                                <MenuItem>Select City</MenuItem>
                                {cityData.map((city, i) => {
                                  return (
                                    <MenuItem value={city.value}>
                                      {city.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>

                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Pincode"
                            value={pincode}
                            onChange={(e) => {
                              setPincode(e.target.value);
                            }}
                            // required={true}
                            className="text-field capitalonly"
                          />
                        </div>

                        <div className="col-12 mb-lg-4 mobile-personal">
                          <TextField
                            fullWidth
                            className="text-field"
                            type="text"
                            value={landmark}
                            onChange={(e) => {
                              setLandmark(e.target.value);
                            }}
                            // required={true}
                            label="Landmark"
                          />
                        </div>

                        <div className="col-lg-6 mb-lg-4 mobile-personal">
                          <p className="d-flex ">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value={default1 == "true" ? true : false}
                                onChange={(e) => {
                                  if (e.target.value == "false") {
                                    setDefault1("true");
                                  } else {
                                    setDefault1("false");
                                  }
                                }}
                                id="flexCheckDefault"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckDefault"
                              >
                                Default Address{" "}
                              </label>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-toggle="toast"
            >
              <div
                className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-3"
                style={{ float: "right" }}
              >
                <div className=" justify-content-end d-flex">
                  <Button
                    className="me-3"
                    variant="contained"
                    onClick={() => {
                      setShowVendorAddressList(true);
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => {
                      onSaveAddress();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
      {/* )} */}
      {/* {showVendorAddress && (
        <VendorAddressList setShowVendorAddressList={setShowVendorAddressList} />
      )} */}
    </>
  );
}
