import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
} from "@mui/material";
import { React, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import VenBankList from "../Vendor/VendorBankList";
import store from "../../../store";
import Notiflix from "notiflix";
import PostApiCall from "../../../assets/API/PostApi";

export default function AddVendorBankDetails({ setShowVendorBankList }) {
  let history = useHistory();
  const {
    vendorGlobalId,
    setVendorGlobalId,
    bankId,
    setBankId,
    bankName,
    setBankName,
    accountHolderName,
    setAccountHolderName,
    branch,
    setBranch,
    accountNumber,
    setAccountNumber,
    ifsccode,
    setIfsccode,
    swiftCode,
    setSwiftCode,
    defaultBank,
    setDefaultBank,
  } = useContext(store);
  // const [showVendorBankList, setShowVendorBankList] = useState(false);

  function onSaveBankDetails() {
    Notiflix.Loading.init({
      svgColor: "rgb(6,10,74)",
    });
    Notiflix.Loading.dots("Please wait...");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    PostApiCall.postRequest(
      {
        id: bankId,
        vendorid: vendorGlobalId,
        bankname: bankName,
        accountnumber: accountNumber,
        branchname: branch,
        accountholdername: accountHolderName,
        swiftccode: swiftCode,
        userid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
        default1: defaultBank,
      },
      "UpdateVendorBanks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Bank added successfully");
          setShowVendorBankList(true);
        } else {
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }
  return (
    <>
      <div>
        <div className="form-head ">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Add Bank Details</h4>
          </div>
        </div>
        <div className="card-vahani p-0 pb-4 ">
          <p className="personal-info left-line-card px-lg-4 px-3 py-4">
            Vendor Bank Details
          </p>
          <div class="toast-body">
            <div class="row">
              <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row ps-2 pe-3">
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          className="text-field"
                          type="text"
                          // required={true}
                          value={bankName}
                          onChange={(e) => {
                            setBankName(e.target.value);
                          }}
                          label="Bank Name"
                        />
                      </div>
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          type="email"
                          label="Branch"
                          // required={true}
                          value={branch}
                          onChange={(e) => {
                            setBranch(e.target.value);
                          }}
                          className="text-field capitalonly"
                        />
                      </div>
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          type="text"
                          label="Account Number"
                          // required={true}
                          value={accountNumber}
                          onChange={(e) => {
                            setAccountNumber(e.target.value);
                          }}
                          className="text-field capitalonly"
                        />
                      </div>

                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          type="text"
                          label="Account Holder Name"
                          // required={true}
                          value={accountHolderName}
                          onChange={(e) => {
                            setAccountHolderName(e.target.value);
                          }}
                          className="text-field capitalonly"
                        />
                      </div>
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          type="text"
                          label="IFSC"
                          // required={true}
                          value={ifsccode}
                          onChange={(e) => {
                            setIfsccode(e.target.value);
                          }}
                          className="text-field capitalonly"
                        />
                      </div>
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          type="text"
                          label="SWIFT Code"
                          // required={true}
                          value={swiftCode}
                          onChange={(e) => {
                            setSwiftCode(e.target.value);
                          }}
                          className="text-field capitalonly"
                        />
                      </div>

                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <p className="d-flex ">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value={defaultBank == "true" ? true : false}
                              id="flexCheckDefault"
                              onChange={(e) => {
                                if (e.target.value == "false") {
                                  setDefaultBank("true");
                                } else {
                                  setDefaultBank("false");
                                }
                              }}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              Default Account*{" "}
                            </label>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
          <div
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-toggle="toast"
          >
            <div
              className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-3"
              style={{ float: "right" }}
            >
              <div className="d-flex justify-content-end">
                <Button
                  className="me-3"
                  variant="contained"
                  onClick={() => {
                    setShowVendorBankList(true);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    onSaveBankDetails();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
