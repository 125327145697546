import React, { Component } from "react";

import Notiflix from "notiflix";
import GetApiCall from "../../../../assets/API/GetApi";
import PostApiCall from "../../../../assets/API/PostApi";
import moment from "moment";
import {
  MenuItem,
  FormControl,
  Button,
  InputLabel,
  TextField,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput,
} from "@mui/material";

class Update_Holiday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PageTitle: "1",
      Page1: "Pending",
      Name: "",
      Date: "",
      Status: "Yes",
      Approval: "",
      Attendance: false,
      AttendanceList: [],
      AttendanceListData: [],
      HolidayId: null,
      HolidayName: "",
      HolidayDate: "",
      DayName: "",
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "/assets/js/pages/form-wizard.init.js";
    script.async = true;
    document.body.appendChild(script);
    this.setState({
      HolidayId:
        this.props.location.state === undefined
          ? 0
          : this.props.location.state.holidayid,
    });
    if (this.props.location.state != undefined) {
      this.getHolidaydetails();
    }
  }

  getHolidaydetails = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_id=${this.props.location.state.holidayid}`,
      },
      "GetHolidayById"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.dots("Please wait...");
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            this.setState({
              HolidayName: obj.data[0].fld_event_name,
              HolidayDate: obj.data[0].fld_holiday_date,
              DayName: obj.data[0].fld_day_name,
              Status: obj.data[0].fld_status,
            });
            Notiflix.Loading.remove();
          }
        }
        Notiflix.Loading.remove();
      })
    );
  };

  SaveAttendance() {
    if (this.state.HolidayName != "") {
      if (this.state.HolidayDate != "") {
        if (this.state.DayName != "") {
          this.onPost();
        } else {
          Notiflix.Notify.failure("Please enter Day Name");
        }
      } else {
        Notiflix.Notify.failure("Please select Holiday Date");
      }
    } else {
      Notiflix.Notify.failure("Please enter Holiday Name");
    }
  }

  onPost = () => {
    PostApiCall.postRequest(
      {
        id: this.state.HolidayId,
        name: this.state.HolidayName,
        holidaydate: this.state.HolidayDate,
        dayname: this.state.DayName,
        status: this.state.Status,
      },
      "AddHoliday"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          Notiflix.Notify.success("Holiday successfully updated.");
          window.history.go(-1);
        } else {
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  render() {
    return (
      <>
        <div className="form-head mb-lg-2 mb-3">
          <div
            className="col-auto "
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Update Holiday</h4>
          </div>
        </div>
        <div className="card-vahani p-0 mt-2">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body p-0">
                  <div id="smartwizard-arrows d-flex justify-content-between">
                    <p className="personal-info m-0 left-line-card p-4">
                      Update Holiday
                    </p>

                    <div className="p-3" style={{ minHeight: "0px" }}>
                      <div
                        id="sw-arrows-step-1"
                        className="tab-pane step-content"
                        style={{
                          display:
                            this.state.PageTitle == "1" ? "block" : "none",
                        }}
                      >
                        <form
                          className="needs-validation"
                          novalidate
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <div
                            className="vahani-card text-start"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                            data-toggle="toast"
                          >
                            <div class="toast-body">
                              <div class="row">
                                <div class="row">
                                  <div className="col-lg-6 mb-lg-3 mobile-personal">
                                    <TextField
                                      fullWidth
                                      type="text"
                                      label="Event Name*"
                                      className="text-field capitalonly"
                                      value={this.state.HolidayName}
                                      onChange={(text) => {
                                        this.setState({
                                          HolidayName: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-6 mb-lg-3 mobile-personal">
                                    <TextField
                                      fullWidth
                                      type="text"
                                      label="Day Name*"
                                      className="text-field capitalonly"
                                      value={this.state.DayName}
                                      onChange={(text) => {
                                        this.setState({
                                          DayName: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-6 mb-lg-3 mobile-personal">
                                    <label for="validationCustom05 ">
                                      Date
                                      <span className="mandatory">*</span>
                                    </label>
                                    {this.state.HolidayId === null ? (
                                      <TextField
                                        fullWidth
                                        className="text-field"
                                        type="date"
                                        value={this.state.HolidayDate}
                                        onChange={(e) => {
                                          this.setState({
                                            HolidayDate: e.target.value,
                                          });
                                        }}
                                      />
                                    ) : (
                                      <TextField
                                        fullWidth
                                        className="text-field"
                                        type="text"
                                        value={moment(
                                          this.state.HolidayDate
                                        ).format("DD/MM/YYYY")}
                                        readonly
                                      />
                                    )}
                                  </div>

                                  <div class="col-md-6">
                                    <div class="form-group mb-2">
                                      <label htmlFor="sw-arrows-first-name">
                                        Status
                                        <span className="mandatory">*</span>
                                      </label>
                                      <br />
                                      <label className="radio-inline">
                                        <input
                                          type="radio"
                                          name="optradio"
                                          checked={
                                            this.state.Status == "Yes"
                                              ? true
                                              : false
                                          }
                                          onChange={() => {
                                            this.setState({
                                              Status: "Yes",
                                            });
                                          }}
                                        />{" "}
                                        Active
                                      </label>
                                      <label className="radio-inline upload-card ml-3">
                                        <input
                                          type="radio"
                                          name="optradio"
                                          checked={
                                            this.state.Status == "No"
                                              ? true
                                              : false
                                          }
                                          onChange={() => {
                                            this.setState({
                                              Status: "No",
                                            });
                                          }}
                                        />{" "}
                                        Inactive
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    role="alert"
                                    aria-live="assertive"
                                    aria-atomic="true"
                                    data-toggle="toast"
                                  >
                                    <div
                                      className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3"
                                      style={{ float: "right" }}
                                    >
                                      <div
                                        className=" justify-content-end"
                                        style={{ display: "flex" }}
                                      >
                                        <Button
                                          variant="contained"
                                          onClick={this.SaveAttendance.bind(
                                            this
                                          )}
                                        >
                                          Save
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Update_Holiday;
