import React, { useContext, useEffect, useState } from "react";
// import "./App.css";
import { Dialog, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import PostApiCall from "../assets/API/PostApi";
import Header from "../Components/Header/Header";
import AdminSidebar from "../Components/Sidebar/AdminSidebar";
//import HRMS sidebar
import HRMSSidebar from "../Components/Sidebar/HRMSSidebar";
import ScholarSidebar from "../Components/Sidebar/ScholarSidebar";
import StudentSidebar from "../Components/Sidebar/StudentSidebar";
// import EmployeeSidebar from "../Components/Sidebar/EmployeeSidebar";
// import NewSidebar from "../Components/Sidebar/NewSidebar";
import AdminRouter from "../Router/AdminRouter";
import ScholarRouter from "../Router/ScholarRouter";
import StudentRouter from "../Router/StudentRouter";
// import EmployeeRouter from "../Router/EmployeeRouter";
import CancelIcon from "@mui/icons-material/Cancel";
import GoToBtn from "../assets/icons/gototop.svg";
import PlayBtn from "../assets/icons/playbutton.svg";
import store from "../store";
import NewStudentList from "./AdminManagement/Students/NewStudentList";

//import vendor sidebar
import VendorSideBar from "../Components/Sidebar/VendorSideBar";
import VendorRouter from "../Router/VendorRouter";

function Home() {
  const history = useHistory;
  const top = useLocation();
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  var AdminDetail = JSON.parse(localStorage.getItem("AccessAdmin"));
  const {
    drawerWidth,
    Showdraw,
    setShowdraw,
    setNotificationData,
    setScholarNotificationData,
    vendorAccess,
    setVendorAccess,
    scolarNotificationResponse
  } = useContext(store);
  const [IconStatus, setIconStatus] = useState(true);
  const [VideoToggle, setVideoToggle] = useState(false);
  const showdrawer = () => {
    setShowdraw(false);
  };
  const GetAdminNotification = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_admin_id=${login[0].fld_id} and fld_status='New'`,
      },
      "Get_Admin_Notification"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //
          setNotificationData(obj.data);
        }
      })
    );
  };

  const GetScholarNotification = () => {
    PostApiCall.postRequest(
      {
        studentId: login[0].fld_id,
        startbatch: login[0].fld_startbatch,
      },
      "Get_ScholarNotificationListById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setNotificationData(
            obj.data.sort(function (a, b) {
              return new Date(b.fld_updatedOn) - new Date(a.fld_updatedOn);
            })
          );
        }
      })
    );
  };
  window.addEventListener("scroll", () => {
    if (window.scrollY > 500) {
      setIconStatus(true);
    } else {
      setIconStatus(false);
    }
  });
  useEffect(() => {
    GoToTop();
    if (JSON.parse(localStorage.getItem("AccessAdmin")) == "Yes") {
      GetAdminNotification();
    } else {
      if (login[0].fld_type != "Student") GetScholarNotification();
    }
  }, [top, scolarNotificationResponse]);
  const GoToTop = () => {
    window.scrollTo(0, 0);
  };
  if (login == null) {
    return <Redirect exact to="/" />;
  } else {
    return (
      <div>
        <Header />
        {login[0].fld_type == "" && <NewStudentList />}
        {login[0].fld_type == "Student" && <StudentSidebar />}
        {login[0].fld_type == "Scholar" && <ScholarSidebar />}
        {login[0].fld_AdminAccess == 1 &&
          AdminDetail == "Yes" &&
          vendorAccess === "false" && <AdminSidebar />}
        {login[0].fld_AdminAccess == 1 &&
          AdminDetail == "Yes" &&
          vendorAccess === "true" && <VendorSideBar />}
        {login[0].Show_HRMS == "Yes" && <HRMSSidebar />}
        {vendorAccess == "true" && <VendorSideBar />}
        {/* {(login[0].fld_AdminAccess==1 || login[0].fld_AdminAccess==0 ) && <EmployeeSidebar /> } */}
        <Box
          className="mobile-section"
          onClick={showdrawer}
          component="main"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            bgcolor: "background.default",
          }}
        >
          <Toolbar />
          <Typography
            className="ty-pd"
            style={{ minHeight: "calc(100vh - 105px)" }}
          >
            <Switch>
              {login[0].fld_type == "Student" && <StudentRouter />}
              {login[0].fld_type == "Scholar" && <ScholarRouter />}
              {login[0].fld_AdminAccess == 1 &&
                AdminDetail == "Yes" &&
                vendorAccess === "false" && <AdminRouter />}
              {login[0].fld_AdminAccess == 1 &&
                AdminDetail == "Yes" &&
                vendorAccess == "true" && <VendorRouter />}
              {/* {(login[0].fld_AdminAccess==1 || login[0].fld_AdminAccess==0 ) && <EmployeeRouter /> } */}
            </Switch>
          </Typography>
          {/* <div
                        className="footer-div"
                        style={{
                            backgroundColor: "rgb(32 101 209)",
                            color: "white",
                            display: "flex",
                            justifyContent: "space-between",
                            // marginTop: "280px",
                            padding: "10px 48px",
                        }}
                    >
                        <div>Powered by Global Trendz</div>
                        <div>Copyright © 2022 Vahani Scholarship. All rights reserved.</div>
                    </div> */}
          <div
            style={{
              backgroundColor: "rgb(32 101 209)",
              color: "white",
            }}
            className="d-lg-flex justify-content-between align-items-center px-lg-5 login-footer mt-lg-0 mt-3 py-lg-2 py-2"
          >
            <p className="text-center mb-lg-0 mb-0">Powered by Global Trendz</p>
            <p className="text-center mb-lg-0 mb-0">
              Copyright © {new Date().getFullYear()} Vahani Scholarship.{" "}
              <span className="d-lg-inline-block d-block">
                All rights reserved.
              </span>
            </p>
          </div>
        </Box>
        {IconStatus && (
          <div
            style={{
              position: "fixed",
              display: window.scrollY > 500 ? "block" : "none",
              bottom: "100px",
              right: "50px",
              zIndex: "999999",
              padding: "0px",
              borderRadius: "50%",
              backgroundColor: "white",
            }}
          >
            <img
              src={GoToBtn}
              onClick={GoToTop}
              width="50px"
              height="50px"
              className="cursor-pointer"
            />
          </div>
        )}
        {/* to be removed when new video is available */}
        {/* {login[0].fld_type == "Student" && (
          <Tooltip title="Watch Application Tutorial" placement="left-start">
            <div
              style={{
                position: "fixed",
                bottom: "30px",
                right: "50px",
                zIndex: "999999",
                padding: "0px",
                borderRadius: "50%",
                backgroundColor: "white",
              }}
              onClick={() => setVideoToggle(true)}
            >
              <img
                src={PlayBtn}
                width="50px"
                height="50px"
                className="cursor-pointer"
              />
            </div>
          </Tooltip>
        )} */}
        <Dialog
          open={VideoToggle}
          PaperProps={{
            sx: {
              width: "100%",
              height: "auto",
            },
          }}
          onClose={() => setVideoToggle(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            className="d-flex justify-content-between"
            id="alert-dialog-title"
          >
            <h6>Vahani Scholarship Application Tutorial</h6>{" "}
            <CancelIcon
              className="cursor-pointer"
              onClick={() => setVideoToggle(false)}
            />
          </DialogTitle>
          <DialogContent>
            <div>
              <iframe
                style={{ aspectRatio: "16/9", width: "100%" }}
                width="100%"
                height="400px"
                src="https://www.youtube.com/embed/gRqcuHfcDLo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
export default Home;
