import "./Login.css";
import React, { useState, useEffect } from "react";
import scholar from "../../assets/Image/scholar.png";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import logo from "../../assets/Image/Vahanilogo.png";
import { useForm } from "react-hook-form";
import PostApiCall from "../../assets/API/PostApi";
import moment from "moment";
import Notiflix from "notiflix";
import { useHistory } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import "../../pages/FirstPage.css";
import CancelIcon from "@mui/icons-material/Cancel";

var AccessAdmin = "Yes";
export default function EmployeeLogin() {
  const history = useHistory();
  let EmailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let [email, setEmail] = useState("");
  let [data, setData] = useState([]);
  let [password, setpassword] = useState("");
  let [Registration, setRegistration] = useState({
    RegistrationData: "",
    RegistrationStatus: "",
  });
  let [passwordeye, setpasswordeye] = useState(false);
  let [ShowModal, setShowModal] = useState(false);
  let [ShowEmailBox, setShowEmailBox] = useState(false);
  let [ShowOTPBox, setShowOTPBox] = useState(false);
  let [ShowPasswordBox, setShowPasswordBox] = useState(false);
  let [newpasswordeye, setnewpasswordeye] = useState(false);
  let [confirmpasswordeye, setconfirmpasswordeye] = useState(false);
  const handlepasswordclick = () => {
    setpasswordeye(!passwordeye);
  };

  const onEmployeeLogin = (data) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        email: data.email,
        password: data.password,
      },
      "AuthenticateEmployee"
    ).then((results10) =>
      results10.json().then((obj10) => {
        if (results10.status === 200 || results10.status === 201) {
          localStorage.setItem("access", JSON.stringify(obj10.token));
          PostApiCall.postRequest(
            {
              whereClause: "where [fld_employee_id]='" + obj10.data + "'",
            },
            "GetEmployeeById"
          ).then((resultemployee) =>
            resultemployee.json().then((obj) => {
              if (
                resultemployee.status === 200 ||
                resultemployee.status === 201
              ) {
                obj.data[0].fld_type = "Employee";
                localStorage.setItem(
                  "LoginDetail",
                  JSON.stringify(obj.data[0])
                );

                window.location.href = "/employeeprofile";
              } else {
                Notiflix.Notify.failure("error occur");
              }
            })
          );
          Notiflix.Notify.success("Authentication Successful");
          Notiflix.Loading.remove();
        } else {
          Notiflix.Notify.failure("Authentication failed");
          Notiflix.Loading.remove();
        }
      })
    );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // Notiflix.Loading.dots();
    onEmployeeLogin(data);
  };

  return (
    <>
      <div className="row align-items-lg-center align-items-end m-0 firstpage-bg">
        <div
          style={{
            maxHeight: "90vh",
          }}
          className="offset-lg-1 card-vahani text-center col-md-4"
        >
          <form className="px-lg-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="text-center">
              <img
                className="login-logo"
                src={require("../../assets/Image/vahanilogo2.png")}
              />
            </div>
            <div>
              <div className="text-field">
                <TextField
                  label="Email Address*"
                  variant="outlined"
                  className="text-field-input"
                  {...register("email", {
                    required: "Enter Email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  error={!!errors?.email}
                  helperText={errors?.email ? errors.email.message : null}
                />
                <div className="position-relative">
                  <TextField
                    id="outlined-basic"
                    label="Password*"
                    variant="outlined"
                    type={passwordeye === false ? "password" : "text"}
                    className="text-field-input"
                    {...register("password", {
                      required: "Please enter password",
                    })}
                    error={errors?.password}
                    helperText={
                      errors?.password ? errors.password.message : null
                    }
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: errors?.password ? "50px" : "25px",
                      left: "calc(100% - 40px)",
                    }}
                  >
                    {!passwordeye ? (
                      <AiFillEyeInvisible
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={handlepasswordclick}
                      />
                    ) : (
                      <AiFillEye
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={handlepasswordclick}
                      />
                    )}
                  </div>
                </div>
                {/* <div className="d-flex justify-content-end">
                  <small
                    className="text-primary cursor-pointer"
                    onClick={() => {
                      setShowModal(true);
                      setShowEmailBox(true);
                    }}
                  >
                    Forgot your Password?
                  </small>
                </div> */}
                <Button
                  variant="contained"
                  className="but-login-vahani mt-3"
                  type="submit"
                >
                  Login
                </Button>
                <NavLink to="/" className="mt-2">
                  Login to Admin Panel
                </NavLink>
              </div>
            </div>
          </form>
        </div>
        <div className="position-fixed bottom-0 d-lg-flex justify-content-between align-items-center px-lg-5 login-footer mt-lg-0 mt-3 py-lg-2 py-2">
          <p className="text-center mb-lg-0 mb-0">Powered by Global Trendz</p>
          <p className="text-center mb-lg-0 mb-0">
            Copyright © {new Date().getFullYear()} Vahani Scholarship.{" "}
            <span className="d-lg-inline-block d-block">
              All rights reserved.
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
