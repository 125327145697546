import { Button, TextField } from "@mui/material";
import imageCompression from "browser-image-compression";
import capitalize from "capitalize";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import PostApiCall from "../../../assets/API/PostApi";
import UserLogo from "../../../assets/Image/user-logo.jpg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// For Vendor Details
import { useTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import store from "../../../store";
// For Image Logo Uploader
const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas m-0 img-button"
  >
    <div className="img-wrap img-upload">
      <img
        for="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input
      className="d-none"
      accept="image/*"
      id="photo-upload"
      type="file"
      onChange={onChange}
    />
  </label>
);

export default function AddVendorInfo(props) {
  let location = useLocation();
  const { vendorGlobalId, setVendorGlobalId, vendorData } = useContext(store);
  const [vendorId, setVendorId] = useState(null);
  const [vendorName, setVendorName] = useState("");
  const [industry, setIndustry] = useState("");
  const [vendorLogo, setVendorLogo] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [mobile, setMobile] = useState("");
  const [landline, setLandLine] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [tan, setTan] = useState("");
  const [panNo, setPanNo] = useState("");
  const [brochure, setBrochure] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState(UserLogo);
  const [originalImage, setOriginalImage] = useState([]);
  const [outputFileName, setOutputFilename] = useState("");
  const [broucherUploaded, setBrochureUploaded] = useState(false);
  const [broucherPath, setBrochurePath] = useState("");

  function photoUpload(e) {
    e.preventDefault();
    const imageFile = e.target.files[0];
    setImagePreviewUrl(URL.createObjectURL(imageFile));
    setOriginalImage(imageFile);
    setOutputFilename(imageFile.name);
  }

  const theme = useTheme();
  // const [value, setValue] = React.useState(0);
  const history = useHistory();
  useEffect(() => {
    if (location.state.id != null && location.state.id != undefined) {
      Notiflix.Loading.init({
        svgColor: "rgb(6, 10, 74)",
      });
      Notiflix.Loading.dots("Please wait...");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      PostApiCall.postRequest(
        {
          whereClause: `where fld_id = '${location.state.id}'`,
        },
        "GetVendorMaster"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setVendorName(obj.data[0].fld_name);
            setIndustry(obj.data[0].fld_industry);
            setImagePreviewUrl(obj.data[0].fld_vendor_logo);
            setEmail(obj.data[0].fld_email);
            setTan(obj.data[0].fld_tan);
            setPanNo(obj.data[0].fld_pan_no);
            setGstNo(obj.data[0].fld_gst_no);
            setLandLine(obj.data[0].fld_landline);
            setWebsite(obj.data[0].fld_website);
            setMobile(obj.data[0].fld_mobile);
            setBrochurePath(obj.data[0].fld_brochure);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
          }
        })
      );
    } else {
      setVendorName("");
      setIndustry("");
      setImagePreviewUrl(UserLogo);
      setEmail("");
      setTan("");
      setPanNo("");
      setGstNo("");
      setLandLine("");
      setWebsite("");
      setMobile("");
      setBrochurePath("");
    }
  }, []);

  function uploadVendorDocuments(uploadedDocument) {
    Notiflix.Loading.dots();
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    if (uploadedDocument == "profile") {
      imageCompression(originalImage, options).then((compressedFile) => {
        const form = new FormData();
        form.append("file", compressedFile);
        form.append("foldername", "VendorInformations");
        form.append("filename", compressedFile.name);
        let response = fetch(
          "https://admin.vahanischolarship.in/API/AddImage2",
          {
            method: "POST",
            body: form,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            Notiflix.Notify.success("Profile Image Uploaded Successfully.");
            Notiflix.Loading.remove();
          });
      });
    } else {
      const form = new FormData();
      form.append("file", brochure);
      form.append("foldername", "VendorInformations");
      form.append("filename", brochure.name);
      let response = fetch("https://admin.vahanischolarship.in/API/AddImage2", {
        method: "POST",
        body: form,
      })
        .then((response) => response.json())
        .then((data) => {
          Notiflix.Notify.success("Brochure Uploaded Successfully.");
          Notiflix.Loading.remove();
        });
    }
  }

  const onAddNewVendor = () => {
    // sending payload (vendor details)
    Notiflix.Loading.init({
      svgColor: "rgb(6,10,74)",
    });
    Notiflix.Loading.dots("Please wait...");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    PostApiCall.postRequest(
      {
        id: location.state.id,
        name: vendorName,
        industry: industry,
        vendorlogo:
          outputFileName.length != 0
            ? "https://admin.vahanischolarship.in/Images/VendorInformations/" +
            outputFileName
            : imagePreviewUrl,
        email: email,
        website: website,
        mobile: mobile,
        landline: landline,
        gstno: gstNo,
        tan: tan,
        panno: panNo,
        brochure:
          broucherUploaded == true
            ? "https://admin.vahanischolarship.in/Images/VendorInformations/" +
            brochure.name
            : broucherPath,
      },
      "UpdateVendorMaster"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          if (outputFileName.length != 0) {
            uploadVendorDocuments("profile");
          }
          if (broucherUploaded == true) {
            uploadVendorDocuments("brochure");
          }
          const savedVendorId = obj.data[0].fld_id;
          setVendorGlobalId(savedVendorId);
          props.setDisabledTab(false);
          props.setValue(1);
        }
      })
    );
  };

  // clear form after submission

  const capitalizeFields = (input) => {
    return typeof input === "string"
      ? input
        .split(" ")
        .map((word) => capitalize(word))
        .join(" ")
      : input;
  };

  return (
    <div className="card-vahani p-0 pb-4 ">
      <>
        {/* <div className="form-head ">
                    <div
                      style={{
                        borderLeft: "10px solid rgb(32, 101, 209)",
                        paddingLeft: "10px",
                      }}
                    >
                      <h4>Add New Vendor</h4>
                    </div>
                  </div> */}
        <p className="personal-info left-line-card px-lg-4 px-3 py-4">
          Vendor Details
        </p>
        <div class="toast-body">
          <div class="row">
            <div class="col-md-12 px-3 pb-0 pt-3">
              <div class="row">
                <div className="col-lg-3">
                  <div class="div1">
                    <div className="img-file-form1 pointer-vendor-logo">
                      <ImgUpload
                        src={imagePreviewUrl}
                        onChange={photoUpload}
                        enable
                      />
                    </div>
                    <div className="upload-info-personal">
                      Upload Vendor Logo <small>(Maximum Size 2MB)</small>{" "}
                      <br />
                      File Format *.jpeg, *.jpg, *.png
                    </div>
                  </div>
                </div>

                <div className="col-lg-9 pe-5">
                  <div className="row">
                    <div className="col-lg-6 mb-lg-4 mobile-personal">
                      <TextField
                        fullWidth
                        className="text-field"
                        type="text"
                        // required={true}
                        name={vendorName}
                        value={capitalizeFields(vendorName)}
                        label="Vendor Name"
                        onChange={(e) => {
                          setVendorName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 mb-lg-4 mobile-personal">
                      <TextField
                        fullWidth
                        className="text-field"
                        type="text"
                        // required={true}
                        name={industry}
                        value={capitalizeFields(industry)}
                        label="Industry type"
                        onChange={(e) => {
                          setIndustry(e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-lg-6 mobile-personal">
                      <TextField
                        fullWidth
                        className="text-field"
                        type="text"
                        // required={true}
                        label="Email"
                        name={email}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-lg-6 mb-lg-4 mobile-personal">
                      <TextField
                        fullWidth
                        className="text-field"
                        type="text"
                        name={website}
                        value={website}
                        // required={true}
                        label="Website"
                        onChange={(e) => {
                          setWebsite(e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-lg-6 mb-lg-4 mobile-personal">
                      <TextField
                        fullWidth
                        type="text"
                        label="Mobile No."
                        inputProps={{ maxLength: 10 }}
                        // required={true}
                        name={mobile}
                        value={mobile}
                        className="text-field capitalonly"
                        onChange={(e) => {
                          setMobile(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 mb-lg-4 mobile-personal">
                      <TextField
                        fullWidth
                        type="text"
                        label="Landline No."
                        name={landline}
                        value={landline}
                        // required={true}
                        className="text-field capitalonly"
                        onChange={(e) => {
                          setLandLine(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 mb-lg-4 mobile-personal">
                      <TextField
                        fullWidth
                        type="text"
                        label="GSTIN"
                        // required={true}
                        name={gstNo}
                        value={gstNo}
                        className="text-field capitalonly"
                        onChange={(e) => {
                          setGstNo(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 mb-lg-4 mobile-personal">
                      <TextField
                        fullWidth
                        type="text"
                        label="TAN"
                        // required={true}
                        name={tan}
                        value={tan}
                        className="text-field capitalonly"
                        onChange={(e) => {
                          setTan(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 mb-lg-4 mobile-personal">
                      <TextField
                        fullWidth
                        type="text"
                        label="PAN"
                        // required={true}
                        inputProps={{ maxLength: 10 }}
                        name={panNo}
                        value={panNo}
                        className="text-field capitalonly"
                        onChange={(e) => {
                          setPanNo(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      className={`${broucherPath != "" && broucherPath != null
                        ? "col-lg-4"
                        : "col-lg-6"
                        } mb-lg-4 mobile-personal`}
                    >
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Upload Brochure (Maximum Size 6Mb)"
                        variant="outlined"
                        className="text-field"
                        type="File"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          e.preventDefault();
                          if (e.target.files[0].size < 6000000) {
                            const reader = new FileReader();
                            const file = e.target.files[0];
                            reader.onloadend = () => {
                              setBrochure(file);
                              setBrochureUploaded(true);
                            };
                            reader.readAsDataURL(file);
                          } else {
                            Notiflix.Notify.failure(
                              "File too large, upload file less than 6 MB."
                            );
                          }
                        }}
                      />
                    </div>
                    {broucherPath != "" && broucherPath != null ? (
                      <div className="col-lg-2 mb-lg-3 mobile-personal">
                        <div className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3">
                          <div
                            className=" justify-content-end"
                            style={{ display: "flex" }}
                          >
                            <Button variant="contained">
                              <a
                                class="text-decoration-none text-reset"
                                variant="contained"
                                href={broucherPath}
                                target="_blank"
                              >
                                View
                              </a>
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
        <div
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          data-toggle="toast"
        >
          <div
            className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-4"
            style={{ float: "right" }}
          >
            <div className=" justify-content-end pe-3 d-flex">
              <Button
                className="me-3"
                variant="contained"
                onClick={() => {
                  // setShowVendorList(true);
                  history.push("/vendor-list");
                }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  onAddNewVendor();
                  // clearForm();
                  if (vendorData.length === 0) {
                    Notiflix.Notify.success("Data saved successfully");
                  }
                  Notiflix.Notify.success("Data updated successfully");

                  // setShowVendorList(true)
                  // setIsVendorDetailsFilled(true);
                }}
              >
                {location.state.id != null ? "Update" : "Save"}
              </Button>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
