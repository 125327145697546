import React, { useState, useContext } from "react";
import Notiflix from "notiflix";
import TextField from "@mui/material/TextField";
import imageCompression from "browser-image-compression";
import GetApiCall from "../../../assets/API/GetApi";
import PostApiCall from "../../../assets/API/PostApi";
import Button from "@mui/material/Button";
import moment from "moment";
import { useForm } from "react-hook-form";
export default function Cv({ stdid, action }) {
  var otp = Math.floor(100000 + Math.random() * 900000);
  var otp2 = Math.floor(100000 + Math.random() * 900000);
  var login = stdid
    ? { fld_id: stdid }
    : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  React.useEffect(() => {
    Notiflix.Loading.dots();
    GetScholarCv();
  }, []);

  const [scholarcvfile, setScholarCvFile] = useState("");
  const [scholarname, setScholarName] = useState(null);

  const GetScholarCv = () => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "GetStudentPersonalInformation_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            setScholarName(obj.data[0].fld_firstName);
            if (obj.data[0].fld_cv_file != null) {
              setScholarCvFile(obj.data[0].fld_cv_file);
            } else {
              setScholarCvFile(null);
            }
          }
        }
        Notiflix.Loading.remove();
      })
    );
  };

  const CvDownload = () => {
    fetch(scholarcvfile).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = scholarcvfile.split("/")[5];
        a.click();
      });
      //window.location.href = response.url;
    });
  };
  //
  return (
    <>
      {console.log(scholarcvfile)}
      <div>
        <div className="form-head">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Cv</h4>
          </div>
          {scholarname != "" && scholarcvfile != null ? (
            <div class="bd-highlight mb-3">
              <span className="me-3 text-wrap text-center">
                {scholarname} Cv
              </span>
              <a
                href={scholarcvfile}
                target="_blank"
                className="btn btn-primary mt-2"
              >
                View Cv
              </a>
              <button
                className="btn btn-primary mt-2 ms-2"
                onClick={() => {
                  CvDownload();
                }}
              >
                Download Cv
              </button>
            </div>
          ) : (
            <div>Cv is not uploaded</div>
          )}
        </div>
      </div>
    </>
  );
}
