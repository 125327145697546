import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Link } from "react-router-dom";
import { Edit } from "react-feather";
import Notiflix from "notiflix";
import GetApiCall from "../../../../assets/API/GetApi";
import PostApiCall from "../../../../assets/API/PostApi";
import moment from "moment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Button,
  Select,
  Menu,
} from "@mui/material";

class HolidayList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,

      HolidayListData: [],
      Id: "",
      searchInput: "",
      Details: [],
      list: [],
      ShowViewModal: null,
      SelectedMonth: "All",
    };
  }

  componentDidMount() {
    Notiflix.Loading.init({
      svgColor: "rgb(6, 10, 74)",
    });
    Notiflix.Loading.dots("Please wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);

    this.setState({
      Details: details[0],
    });
    //  ========For Admin============
    GetApiCall.getRequest("GetHolidayList").then((resultdes) =>
      resultdes.json().then((obj) => {
        obj.data.sort(function (a, b) {
          return new Date(b.fld_holiday_date) - new Date(a.fld_holiday_date);
        });

        this.setState({
          list: obj.data.reverse(),
        });
        Notiflix.Loading.remove();
      })
    );
  }

  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  GetFilterHolidayList = (month) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause:
          month == "All" ? "" : `where MONTH(fld_holiday_date)='${month}'`,
      },
      "GetFilterHolidayList"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({ list: obj.data });
          Notiflix.Loading.remove();
        }
        Notiflix.Loading.remove();
      })
    );
  };
  render() {
    return (
      <>
        <div className="form-head mb-lg-2 mb-3">
          <div
            className="col-auto "
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Holiday List </h4>
          </div>
        </div>
        <div className="card-vahani p-0 mt-2">
          <div className="row m-0 justify-content-between align-items-center border-top border-bottom border-secondary px-4 pt-3 pb-3">
            <div className="col-md-4 mb-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Month"
                  value={this.state.SelectedMonth}
                  onChange={(e) => {
                    this.setState({ SelectedMonth: e.target.value });
                    this.GetFilterHolidayList(e.target.value);
                  }}
                >
                  <MenuItem value="All">Choose Month</MenuItem>
                  <MenuItem value="1">January</MenuItem>
                  <MenuItem value="2">February</MenuItem>
                  <MenuItem value="3">March</MenuItem>
                  <MenuItem value="4">April</MenuItem>
                  <MenuItem value="5">May</MenuItem>
                  <MenuItem value="6">June</MenuItem>
                  <MenuItem value="7">July</MenuItem>
                  <MenuItem value="8">August</MenuItem>
                  <MenuItem value="9">September</MenuItem>
                  <MenuItem value="10">October</MenuItem>
                  <MenuItem value="11">November</MenuItem>
                  <MenuItem value="12">December</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6 text-end col-12 mb-3 ms-auto">
              <div className="row justify-content-end">
                <div className="col-lg-4 col-lg-4 mb-lg-0 mb-2">
                  <Button
                    variant="contained"
                    className="w-100"
                    onClick={() => {
                      Notiflix.Loading.dots();
                      GetApiCall.getRequest("GetHolidayList").then(
                        (resultdes) =>
                          resultdes.json().then((obj) => {
                            obj.data.sort(function (a, b) {
                              return (
                                new Date(b.fld_holiday_date) -
                                new Date(a.fld_holiday_date)
                              );
                            });
                            this.setState({ SelectedMonth: "All" });
                            this.setState({
                              list: obj.data.reverse(),
                            });
                            Notiflix.Loading.remove();
                          })
                      );
                    }}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3">
            {this.state.list.length > 0 ? (
              <MDBDataTable
                disableRetreatAfterSorting={true}
                responsive
                hover
                //   striped
                data={{
                  columns: [
                    {
                      label: "S.No.",
                      field: "serial",
                      sort: "disabled",
                      width: 150,
                    },
                    {
                      label: "Event",
                      field: "event",
                      sort: "disabled",
                      width: 400,
                    },

                    {
                      label: "Date",
                      field: "date",
                      sort: "disabled",
                      width: 150,
                    },
                    {
                      label: "Day",
                      field: "day",
                      sort: "disabled",
                      width: 150,
                    },
                  ],
                  rows: this.state.list.map((data, i) => {
                    return {
                      serial: i + 1,
                      event: data.fld_event_name,
                      date: moment(data.fld_holiday_date).format("DD/MM/YYYY"),
                      day: data.fld_day_name,
                    };
                  }),
                }}
                entriesOptions={[10, 25, 50, 100]}
              />
            ) : (
              <p className="m-3">No Data Found</p>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default HolidayList;
