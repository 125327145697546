import { Box } from "@mui/material";
import React, { useState, useContext } from "react";

import { useHistory } from "react-router-dom";
import AddVendeorAddress from "./AddVendorAddress";

// For Vendor Details
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import VendorAdd from "./Addvendor";
import VendorInfoList from "./VendorInfoList";

import VendorAddressList from "../Vendor/VendorAddressList";

import AddBankDetails from "../Vendor/AddVendorBankDetails";
import VendorBankList from "../Vendor/VendorBankList";
import store from "../../../store";

// Desgin for vendor details
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function VendorComponent() {
  // let history = useHistory();
  const [showVendorList, setShowVendorList] = useState(true); // state to toggle the list/form view
  const { disabledTab, setDisabledTab } = useContext(store);

  // *** Vendor Address ***
  const [showVendorAddress, setShowVendorAddressList] = useState(true);

  //  **** Vendor bank Details *******
  const [showVendorBankList, setShowVendorBankList] = useState(true);

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <div>
        <div className="card-vahani p-0 pb-4 ">
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Vendor Details" {...a11yProps(0)} />
              <Tab
                label="Address Details"
                {...a11yProps(1)}
                disabled={disabledTab}
              />
              <Tab
                label="Bank Details"
                {...a11yProps(2)}
                disabled={disabledTab}
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            {/* ***** Add Vendor ***** */}
            <TabPanel value={value} index={0} dir={theme.direction}>
              {/* {console.log(showVendorList)} */}
              {/* {showVendorList === true ? ( */}
              <VendorAdd
                setValue={setValue}
                setDisabledTab={() => setDisabledTab()}
              />
              {/* // ) : (
              //   <VendorAdd setShowVendorList={setShowVendorList} />
              // )} */}
            </TabPanel>

            {/* *****Vendor Address******* */}
            <TabPanel value={value} index={1} dir={theme.direction}>
              {showVendorAddress === true ? (
                <VendorAddressList
                  setShowVendorAddressList={setShowVendorAddressList}
                  // setIsVendorDetailsFilled={setIsVendorDetailsFilled}
                />
              ) : (
                <AddVendeorAddress
                  setShowVendorAddressList={setShowVendorAddressList}
                  // setIsVendorDetailsFilled={setIsVendorDetailsFilled}
                />
              )}
            </TabPanel>

            {/* *****Vendor Bank Details******* */}
            <TabPanel value={value} index={2} dir={theme.direction}>
              {showVendorBankList === true ? (
                <VendorBankList
                  setShowVendorBankList={setShowVendorBankList}
                  //setdisabledTab={setdisabledTab}
                />
              ) : (
                <AddBankDetails
                  setShowVendorBankList={setShowVendorBankList}
                  //setdisabledTab={setdisabledTab}
                />
              )}
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    </>
  );
}
