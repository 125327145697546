import React from "react";
import { Route, Switch } from "react-router-dom";
import PageNotFound from "../pages/PageNotFound";
// Vendor Management
import AddProject from "../pages/VendorManagement/Projects/AddProject";
import AddTask from "../pages/VendorManagement/Projects/AddTask";
import ProjectList from "../pages/VendorManagement/Projects/ProjectList";
import TaskList from "../pages/VendorManagement/Projects/TaskList";
import AddVendorAddress from "../pages/VendorManagement/Vendor/AddVendorAddress";
import AddVendorBankDetails from "../pages/VendorManagement/Vendor/AddVendorBankDetails";
import AddVendorContactDetails from "../pages/VendorManagement/Vendor/AddVendorContactDetails";
import VendorAddressList from "../pages/VendorManagement/Vendor/VendorAddressList";
import VendorBankList from "../pages/VendorManagement/Vendor/VendorBankList";
import AllVendor from "../pages/VendorManagement/Vendor/VendorComponets";
import VendorContactList from "../pages/VendorManagement/Vendor/VendorContactList";
import VendorInfoList from "../pages/VendorManagement/Vendor/VendorInfoList";

// RFQ Master
import AddRFQ from "../pages/VendorManagement/RFQ/AddRfq";
import AddRfqTask from "../pages/VendorManagement/RFQ/AddRfqTask";
import RfqList from "../pages/VendorManagement/RFQ/RfqList";
import RfqTaskList from "../pages/VendorManagement/RFQ/RfqTaskList";

// Add Quotation
import AddQuotation from "../pages/VendorManagement/Quotation/AddQuotation";
import QuotationList from "../pages/VendorManagement/Quotation/QuotationList";

// Purchase Order
import AddPurchaseOrder from "../pages/VendorManagement/PurchaseOrder/AddNewPurchaseOrder";
import PurchaseOrderList from "../pages/VendorManagement/PurchaseOrder/PurchaseOrderList";
// import AddPurchaseOrderDetails from "../pages/VendorManagement/PurchaseOrder/AddPurchaseOrderDetails";
// import AddPurchaseOrderDetailsList from "../pages/VendorManagement/PurchaseOrder/PurchaseOrderDetailsList";

// Purchase Order Invoice
import AddPurchaseOrderInvoice from "../pages/VendorManagement/Invoices/AddPurchaseOrderInvoice";
import PurchaseOrderInvoiceDetails from "../pages/VendorManagement/Invoices/PurchaseOrderInvoiceDetails";

// Payment Details
import AddPayment from "../pages/VendorManagement/Payments/AddPayments";
import PaymentDetails from "../pages/VendorManagement/Payments/PaymentDetails";
import PurchaseOrderPrint from "../pages/VendorManagement/PurchaseOrder/PurchaseOrderPrint";
import VendorDashboard from "../pages/VendorManagement/VendorDashboard/VendorDashboard";
import PurchaseOrderInvoicePayments from "../pages/VendorManagement/Invoices/PurchaseOrderInvoicePayments";
const EmployeeRouter = () => {
  return (
    <Switch>
      {/* Vendor Dashboard Start*/}
      <Route exact path="/vendor-dashboard" component={VendorDashboard} />
      {/* Vendor Dashboard End*/}
      {/* Vendor Management Routes Start */}
      {/* Project Master Start */}
      <Route exact path="/project-list" component={ProjectList} />
      <Route exact path="/add-project/:id" component={AddProject} />{" "}
      {/*edit by id */}
      <Route exact path="/add-project" component={AddProject} />
      <Route exact path="/task-list" component={TaskList} />
      <Route exact path="/add-task" component={AddTask} />
      <Route exact path="/add-task/:fld_id" component={AddTask} />
      <Route exact path="/task-list/:fld_id" component={TaskList} />
      {/* Project Master End */}
      {/* Vendor Master Start */}
      <Route exact path="/vendor-list" component={VendorInfoList} />
      <Route exact path="/add-vendor" component={AllVendor} />
      <Route exact path="/vendor-address-list" component={VendorAddressList} />
      <Route exact path="/add-vendor-address" component={AddVendorAddress} />
      <Route exact path="/vendor-contact-list" component={VendorContactList} />
      <Route exact path="/purchaseorderprint" component={PurchaseOrderPrint} />
      <Route
        exact
        path="/purchaseorderinvoicepayments"
        component={PurchaseOrderInvoicePayments}
      />
      <Route
        exact
        path="/add-vendor-contact-details"
        component={AddVendorContactDetails}
      />
      <Route exact path="/vendor-bank-list" component={VendorBankList} />
      <Route
        exact
        path="/add-vendor-bank-details"
        component={AddVendorBankDetails}
      />
      {/* Vendor Master End */}
      {/* RFQ  Start */}
      <Route exact path="/rfq-list" component={RfqList} />
      <Route exact path="/add-rfq" component={AddRFQ} />
      <Route exact path="/rfq-task-list" component={RfqTaskList} />
      <Route exact path="/add-rfq-task" component={AddRfqTask} />
      {/* Add Quotation */}
      <Route exact path="/add-quotation" component={AddQuotation} />
      <Route exact path="/quotation-list" component={QuotationList} />
      {/* Purchase Order */}
      <Route exact path="/add-purchase-Order" component={AddPurchaseOrder} />
      <Route exact path="/purchase-order-list" component={PurchaseOrderList} />
      {/* Purchase Order Invoice */}
      <Route
        exact
        path="/add-purchase-Order-invoice"
        component={AddPurchaseOrderInvoice}
      />
      <Route
        exact
        path="/purchase-Order-invoice-list"
        component={PurchaseOrderInvoiceDetails}
      />
      {/* <Route
        exact
        path="/add-purchase-Order-details"
        component={AddPurchaseOrderDetails}
      />
      <Route
        exact
        path="/purchase-order-details-list"
        component={AddPurchaseOrderDetailsList}
      /> */}
      {/* Payment Details */}
      <Route exact path="/add-payments" component={AddPayment} />
      <Route exact path="/payment-details" component={PaymentDetails} />
      {/* Vendor Management Routes End */}
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};
export default EmployeeRouter;
