import "../Personalinfo/Personalinfo.css";
import React, { useState, useContext } from "react";
import { TextField, Button, Box, Avatar, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { MDBDataTable } from 'mdbreact';
import Notiflix from "notiflix";
import moment from "moment";
import PostApiCall from "../../../assets/API/PostApi";
import store from "../../../store";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
export default function TicketHistory() {
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const [Ticket, setTicket] = React.useState([]);
  const [open, setOpen] = React.useState(null);
  const [Answer, setAnswer] = useState('')
  const [AnswerList, setAnswerList] = useState([])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  React.useEffect(() => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_ScholarTicketMessageList"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setTicket(obj.data);
          //
          Notiflix.Loading.remove();
        }
      })
    )
  }, []);
  const getTicketAnswer = (stdid, quesid) => {
    Notiflix.Loading.dots()
    PostApiCall.postRequest(
      {
        studentId: stdid,
        questionId: quesid,
      },
      "Get_TicketAnswer_ById"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          //
          setAnswerList(obj.data)
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!")
        }
      })
    );
  }
  const updateAnswer = (stdid, quesid) => {
    if (Answer != '') {
      Notiflix.Loading.dots();
      PostApiCall.postRequest({
        studentId: stdid,
        questionId: quesid,
        answer: Answer,
        updatedOn: moment().format('lll'),
        updatedBy: login.fld_id,
      }, "Add_TicketAmswer").then((result) =>
        result.json().then(obj => {
          if (result.status == 200 || result.status == 201) {
            Notiflix.Loading.remove();
            Notiflix.Notify.success('Query Submitted Successfully')
            setAnswer("")
            getTicketAnswer(stdid, quesid)
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("There seems to be an Error. Please retry!")
          }
        })
      )
    }
    else {
      Notiflix.Notify.failure('Please enter query.')
    }
  }
  return (
    <>
      <div className="form-head">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Track your Query</h4>
        </div>
      </div>
      <div className="card-vahani p-0" style={{ marginTop: "24px" }} >
        <p className="personal-info left-line-card px-lg-4 px-4 py-4">Query List</p>
        <div className="row m-0 p-4">
          {Ticket.length > 0 ?
            <>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead className="tablesimple-head" >
                    <TableRow>
                      <TableCell className='fw-bold' style={{ minWidth: "101px" }}>Ticket No.</TableCell>
                      <TableCell className='fw-bold' >Query</TableCell>
                      <TableCell className='fw-bold' style={{ minWidth: "110px" }} >Date/Time</TableCell>
                      <TableCell className='fw-bold' >Status</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Ticket.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                      return (
                        <>
                          <TableRow style={{ backgroundColor: open == i ? "#f2f2f2" : "white" }} sx={{ '& > *': { borderBottom: 'unset' } }}>
                            <TableCell component="th" scope="row">{row.fld_id}</TableCell>
                            <TableCell>{row.fld_question}</TableCell>
                            <TableCell>{row.fld_generatedon}</TableCell>
                            <TableCell>{row.fld_status == "Delete" ? "Closed" : row.fld_status}</TableCell>
                            <TableCell>
                              <IconButton
                                className='bg-primary text-white'
                                aria-label="expand row"
                                // size="small"
                                onClick={() => {
                                  if (open != null) {
                                    setOpen(open == i ? null : i)
                                    if (open != i) {
                                      getTicketAnswer(row.fld_studentid, row.fld_id)
                                    }
                                  } else {
                                    setOpen(i)
                                    getTicketAnswer(row.fld_studentid, row.fld_id)
                                  }
                                }}
                              >{open == i ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                              <Collapse in={open == i ? true : false} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                  <div className='row m-0 py-3 '>
                                    {AnswerList.map(val => {
                                      return (
                                        <div className='row m-0 py-2  border-bottom'>
                                          <div className='d-flex align-items-center'>
                                            <Avatar alt="Remy Sharp" src={val.fld_photo} sx={{ width: 60, height: 60 }} />
                                            <div className='w-100'>
                                              <div className='d-flex justify-content-between align-items-center'>
                                                <span className='ms-3 fw-bold'>{val.fld_fullName}</span>
                                                <span className='fw-bold'>Posted : {moment(val.fld_updatedon).calendar()}</span>
                                              </div>
                                              <p className='mt-2 ms-3' >{val.fld_answer}</p>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                    {(row.fld_status == "Open" && login.fld_type != "Scholar" && login.fld_type != "Student") &&
                                      <>
                                        <TextField type="text" fullWidth label="Write Your Answers" value={Answer} onChange={e => setAnswer(e.target.value)} />
                                        <div className='d-flex justify-content-end mt-3'>
                                          <Button variant="contained" onClick={() => updateAnswer(row.fld_studentid, row.fld_id)} >Reply</Button>
                                        </div>
                                      </>
                                    }
                                  </div>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={Ticket.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </>
            : <p>No Data Found</p>
          }
        </div>
      </div>
    </>
  );
}
