import React from 'react'
import { MenuItem, FormControl, Button, Select, InputLabel, TextField, FormHelperText, Box, Chip, OutlinedInput } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import Notiflix from 'notiflix';
import moment from 'moment';
import PostApiCall from '../../../assets/API/PostApi';
import GetApiCall from '../../../assets/API/GetApi';

const CollegeInformation = ({ stdid, action }) => {
    var login = stdid ? { fld_id: stdid } : JSON.parse(localStorage.getItem("LoginDetail"))[0]; //get student id
    const [Country, setCountry] = React.useState([]);
    const [State, setState] = React.useState([]);
    const [City, setCity] = React.useState([]);
    const [formdata, setformdata] = React.useState({ countryId: 1, stateId: 1, cityId: 1, });
    const { register, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm();

    // function to save data
    const onSave = (data) => {
        Notiflix.Loading.dots()
        var detail = JSON.parse(localStorage.getItem("LoginDetail"))[0]; // get loggin details
        PostApiCall.postRequest({
            studentId: login.fld_id,
            collageid: data.fld_id == undefined ? null : data.fld_id,
            universityName: data.fld_universityName,
            universityAddress: data.fld_universityAddress,
            universityCountry: data.fld_universityCountry,
            universityState: data.fld_universityState,
            universityCity: data.fld_universityCity,
            universityPincode: data.fld_universityPincode,
            updatedby: detail.fld_id,
            updatedon: moment().format("lll"),
        }, "AddScholarCollegeInformation").then((results) =>
            results.json().then(obj => {
                if (results.status == 200 || results.status == 201) {
                    //
                    Notiflix.Loading.remove();
                    Notiflix.Notify.success("Saved and Updated Successfully")
                } else {
                    Notiflix.Loading.remove()
                    Notiflix.Notify.failure("There seems to be an Error. Please retry!");
                }
            })
        )
    }

    React.useEffect(() => {
        GetApiCall.getRequest("GetCountry").then(resultdes => resultdes.json().then(obj => { setCountry(obj.data) }));
        PostApiCall.postRequest(
            {
                studentId: login.fld_id
            },
            "GetScholarCollegeInformation_ById"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    //
                    reset(obj.data[0])
                    onCountryChange(obj.data[0].fld_universityCountry)
                    onStateChange(obj.data[0].fld_universityState)
                }
            }
            )
        )
    }, [])
    const onCountryChange = (data) => {
        Notiflix.Loading.dots()
        PostApiCall.postRequest({
            countryid: data,
        }, "GetState").then((results) =>
            results.json().then(objstate => {
                if (results.status == 200 || results.status == 201) {
                    setState(objstate.data)
                }
                Notiflix.Loading.remove()
            })
        )
    }
    const onStateChange = (data) => {
        Notiflix.Loading.dots()
        PostApiCall.postRequest({
            stateid: data,
        }, "GetCity").then((results) =>
            results.json().then(objcity => {
                if (results.status == 200 || results.status == 201) {
                    setCity(objcity.data)
                }
                Notiflix.Loading.remove()
            })
        )
    }
    return (
        <>
            <div className="form-head ">
                <div style={{ borderLeft: "10px solid rgb(32, 101, 209)", paddingLeft: "10px" }} >
                    <h4>College Information</h4>
                </div>
            </div>
            <form className='row m-0' onSubmit={handleSubmit(onSave)} >
                <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
                    <p className="personal-info left-line-card px-lg-4 px-4 py-4">College/ University Details</p>
                    <div className="row m-0 p-lg-4 p-3">
                        <div className='col-md-6 mb-3'>
                            <TextField
                                fullWidth
                                label="College / University Name*"
                                className="text-field capital"
                                {...register("fld_universityName", {
                                    required: "This field is required.",
                                })}
                                value={watch('fld_universityName') ? watch('fld_universityName') : ""}
                                error={errors?.fld_universityName}
                                helperText={errors?.fld_universityName ? errors?.fld_universityName.message : null}
                            />
                        </div>
                        <div className='col-12 mb-3'>
                            <TextField
                                fullWidth
                                label="Address*"
                                multiline
                                maxRows={4}
                                variant="outlined"
                                style={{ width: "100%" }}
                                {...register("fld_universityAddress", { required: "This field is required" })}
                                value={watch('fld_universityAddress') ? watch('fld_universityAddress') : ""}
                                error={errors?.fld_universityAddress}
                                helperText={errors?.fld_universityAddress ? errors.fld_universityAddress.message : null}
                            />
                        </div>
                        <div className="col-lg-3 col-12 mb-3">
                            <FormControl fullWidth error={Boolean(errors.fld_universityCountry)} className="mobile-personal" >
                                <InputLabel id="demo-simple-select-label">Country*</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select2"
                                    label="Country*"
                                    {...register("fld_universityCountry", { required: "This field is required", })}
                                    value={watch('fld_universityCountry') ? watch('fld_universityCountry') : ""}
                                    error={errors?.fld_universityCountry}
                                    onChange={e => {
                                        onCountryChange(e.target.value)
                                        setValue("fld_universityCountry", e.target.value)
                                        setValue("fld_universityState", "")
                                        setValue("fld_universityCity", "")
                                        setCity([])
                                    }}
                                >
                                    {Country.map(record => {
                                        return (
                                            <MenuItem value={record.value}>{record.label}</MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText > {errors?.fld_universityCountry ? errors.fld_universityCountry.message : null} </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="col-md-3 mb-3">
                            <FormControl fullWidth error={Boolean(errors.fld_universityState)} >
                                <InputLabel id="demo-simple-select-label">State*</InputLabel>
                                <Select
                                    labelId="label-state"
                                    id="id-state"
                                    label="State *"
                                    {...register("fld_universityState", { required: "This field is required", })}
                                    error={errors?.fld_universityState}
                                    onChange={e => {
                                        onStateChange(e.target.value)
                                        setValue("fld_universityState", e.target.value)
                                        setValue("fld_universityCity", "")
                                    }}
                                    value={watch('fld_universityState') ? watch('fld_universityState') : ""}
                                >
                                    {State.map((record, item) => {
                                        return (
                                            <MenuItem value={record.value}>{record.label}</MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText> {errors?.fld_universityState ? errors.fld_universityState.message : null} </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="col-md-3 mb-3">
                            <FormControl fullWidth error={Boolean(errors.fld_universityCity)} >
                                <InputLabel id="label-id-city" >City*</InputLabel>
                                <Select
                                    labelId="label-id-city"
                                    id="id-city"
                                    {...register("fld_universityCity", { required: "This field is required" })}
                                    error={errors?.fld_universityCity}
                                    value={watch('fld_universityCity') ? watch('fld_universityCity') : ""}
                                    label="City "
                                >
                                    {City.map(record => {
                                        return (<MenuItem key={record.label} value={record.value}>{record.label}</MenuItem>);
                                    })}
                                </Select>
                                <FormHelperText> {errors?.fld_universityCity ? errors.fld_universityCity.message : null} </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="col-md-3 mb-3">
                            <TextField
                                fullWidth
                                type="number"
                                id="outlined-basic"
                                label="Pincode*"
                                variant="outlined"
                                className="text-field mobile-personal"
                                {...register("fld_universityPincode", {
                                    required: "This field is required",
                                    minLength: { value: 6, message: "Enter minimum 6-digit Pincode", },
                                    maxLength: { value: 6, message: "Enter maximum 6-digit Pincode", }
                                })}
                                value={watch('fld_universityPincode') ? watch('fld_universityPincode') : ""}
                                error={errors?.fld_universityPincode}
                                helperText={errors?.fld_universityPincode ? errors.fld_universityPincode.message : null}
                            />
                        </div>
                    </div>
                </div>
                {action != "view" && <div className='col-12 text-end'>
                    <div className="card-vahani" style={{ marginTop: "26px" }}>
                        <div className="d-flex justify-content-end" >
                            <div className="but-width-form">
                                <Button variant="contained" className="but-login-vahani" type="submit" >
                                    Save and update
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>}
            </form>
        </>
    )
}
export default CollegeInformation