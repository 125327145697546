import "../Personalinfo/Personalinfo.css";
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
// import GetApiCall from "../../../assets/API/GetApi";
import moment from "moment";
import Notiflix from "notiflix";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
export default function SubmitTicket() {
  const history = useHistory()
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  const { register, handleSubmit, formState: { errors }, } = useForm();
  const onPost = (ticket) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        studentId: login[0].fld_id,
        question: ticket.fld_question,
        generatedon: moment().format("lll"),
        updatedOn: moment().format("lll"),
        updatedBy: login[0].fld_id,
      },
      "Add_TicketMessage"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Query Submitted Successfully");
          history.push("/ticket-history")
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  const onSubmit = (ticket) => {
    // e.preventDefault();
    onPost(ticket);
  };
  return (
    <>
      <div style={{ marginBottom: "210px" }}>
        <div className="form-head">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Raise your query </h4>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">Question</p>
            <div className="row m-0 p-lg-4 p-3">
              <div className="col-lg-12 col-12">
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Ask Your Question*"
                  multiline
                  maxRows={4}
                  style={{ width: "100%" }}
                  {...register("fld_question", {
                    required: "This field is required",
                  })}
                  error={!!errors?.fld_question}
                  helperText={
                    errors?.fld_question ? errors.fld_question.message : null
                  }
                />
              </div>
            </div>
          </div>
          <div className="card-vahani" style={{ marginTop: "26px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <div className="but-width-form">
                <Button
                  variant="contained"
                  className="but-login-vahani"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
