import "../Personalinfo/Personalinfo.css";
import React, { useState } from "react";
// import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
// import GetApiCall from "../../../assets/API/GetApi";
import moment from "moment";
import Notiflix from "notiflix";
export default function SchoolInfo({ stdid, action }) {
  //
  var login = stdid ? { fld_id: stdid } : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  var ValidationList = JSON.parse(localStorage.getItem("Validation"));
  // ------city country state----------
  const [country, setCountry] = useState([]);
  const [st, setSt] = useState([]);
  const [city, setCity] = useState([]);
  const getcountry = async () => {
    const rescountry = await fetch(
      "https://admin.vahanischolarship.in/API/GetCountry"
    );
    const rescon = await rescountry.json();
    setCountry(await rescon.data);
  };
  const handlecountry = (data) => {
    Notiflix.Loading.dots()
    PostApiCall.postRequest({
      countryid: data,
    }, "GetState").then((results) =>
      results.json().then(objstate => {
        if (results.status == 200 || results.status == 201) {
          setSt(objstate.data)
        }
        Notiflix.Loading.remove()
      })
    )
  };
  const handlestate = (data) => {
    Notiflix.Loading.dots()
    PostApiCall.postRequest({
      stateid: data,
    }, "GetCity").then((results) =>
      results.json().then(objcity => {
        if (results.status == 200 || results.status == 201) {
          setCity(objcity.data)
        }
        Notiflix.Loading.remove()
      })
    )
  };
  React.useEffect(() => {
    Notiflix.Loading.dots();
    getcountry()
    GetStudentSchoolAndAcademicInformation();
  }, []);
  const GetStudentSchoolAndAcademicInformation = () => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "GetStudentSchoolAndAcademicInformation_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length !== 0) {
            //
            reset(obj.data[0]);
            handlecountry(obj.data[0].fld_country)
            handlestate(obj.data[0].fld_state)
          }
        }
        Notiflix.Loading.remove();
      })
    );
  };
  const { register, handleSubmit, watch, setValue, reset, formState: { errors }, } = useForm();
  const onSubmit = (school) => {
    // e.preventDefault();
    onPost(school);
    //
  };
  const onPost = (school) => {
    Notiflix.Loading.dots();
    var detail = JSON.parse(localStorage.getItem("LoginDetail"))[0];
    PostApiCall.postRequest(
      {
        id: school.fld_id == undefined ? null : school.fld_id,
        studentId: login.fld_id,
        schoolName: school.fld_schoolName,
        address: school.fld_address,
        country: school.fld_country,
        state: school.fld_state,
        city: school.fld_city,
        pincode: school.fld_pincode,
        computerKnowledge: school.fld_computerKnowledge,
        englishKnowledge: school.fld_englishKnowledge,
        updatedon: moment().format("lll"),
        updatedby: detail.fld_id,
      },
      "UpdateStudentSchoolAndAcademicInformation"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Saved and Updated Successfully");
          Notiflix.Loading.remove();
          GetStudentSchoolAndAcademicInformation();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  return (
    <>
      <div>
        <div className="form-head">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>School/Academic Information</h4>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">School Details</p>
            <div className="row m-0 p-lg-4 p-3">
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="School Name*"
                  variant="outlined"
                  className="capital"
                  {...register("fld_schoolName", {
                    required: "This field is required",
                  })}
                  error={errors?.fld_schoolName}
                  helperText={errors?.fld_schoolName ? errors.fld_schoolName.message : null}
                  InputLabelProps={{ shrink: true, }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Address Of School*"
                  variant="outlined"
                  className="capital"
                  {...register("fld_address", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[3].fld_reg_ex, 'i'),
                      message: "Enter a valid address",
                    },
                  })}
                  error={errors?.fld_address}
                  helperText={errors?.fld_address ? errors.fld_address.message : null}
                  InputLabelProps={{ shrink: true, }}
                />
              </div>
              <div className="col-lg-3 col-12 mb-lg-3 form-auto mobile-personal ">
                <FormControl fullWidth error={Boolean(errors.fld_country)}>
                  <InputLabel >Country*</InputLabel>
                  <Select
                    label="Country*"
                    {...register("fld_country", { required: "This field is required", })}
                    error={errors?.fld_country}
                    onChange={e => {
                      handlecountry(e.target.value)
                      setValue("fld_country", e.target.value)
                      setValue("fld_state", "")
                      setValue("fld_city", "")
                      setCity([])
                    }}
                    value={watch("fld_country") ? watch("fld_country") : ""}
                    InputLabelProps={{ shrink: true, }}
                  >
                    {country.map((record, item) => {
                      return (
                        <MenuItem value={record.value}>{record.label}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_country ? errors.fld_country.message : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-3 col-12 mb-lg-3 mobile-personal">
                <FormControl fullWidth error={Boolean(errors.fld_state)}>
                  <InputLabel >State*</InputLabel>
                  <Select
                    label="State*"
                    {...register("fld_state", { required: "This field is required", })}
                    error={errors?.fld_state}
                    onChange={e => {
                      handlestate(e.target.value)
                      setValue("fld_state", e.target.value)
                      setValue("fld_city", "")
                    }}
                    value={watch("fld_state") ? watch("fld_state") : ""}
                    InputLabelProps={{ shrink: true, }}
                  >
                    {st.map((record, item) => {
                      return (<MenuItem value={record.value} >{record.label}</MenuItem>);
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_state ? errors.fld_state.message : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-3 mb-lg-3 mobile-personal">
                <FormControl fullWidth error={Boolean(errors.fld_city)}>
                  <InputLabel >City*</InputLabel>
                  <Select
                    label="City*"
                    {...register("fld_city", { required: "This field is required", })}
                    value={watch("fld_city") ? watch("fld_city") : ""}
                    error={errors?.fld_city}
                    InputLabelProps={{ shrink: true, }}
                  >
                    {city.map((record, item) => {
                      return (
                        <MenuItem value={record.value}>{record.label}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText> {errors?.fld_city ? errors.fld_city.message : null} </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-3 col-12 mb-lg-3 mobile-personal">
                <TextField
                  id="standard-multiline-flexible"
                  label="Pincode*"
                  variant="outlined"
                  style={{ width: "100%" }}
                  {...register("fld_pincode", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[4].fld_reg_ex, 'i'),
                      message: "Enter a valid pincode",
                    },
                    minLength: {
                      value: 6,
                      message: "Enter minimum 6-digit number",
                    },
                  })}
                  error={errors?.fld_pincode}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 6,
                  }}
                  helperText={
                    errors?.fld_pincode ? errors.fld_pincode.message : null
                  }
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <FormControl
                  fullWidth
                  error={Boolean(errors.fld_englishKnowledge)}
                >
                  <InputLabel id="lab-id-eng">
                    Level of English Knowledge*
                  </InputLabel>
                  <Select
                    labelId="id-eng"
                    id="eng-label"
                    label="Level of English Knowledge*"
                    {...register("fld_englishKnowledge", {
                      required: "This field is required",
                    })}
                    error={errors?.fld_englishKnowledge}
                    value={watch("fld_englishKnowledge") ? watch("fld_englishKnowledge") : ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value={"Beginner"}>Beginner</MenuItem>
                    <MenuItem value={"Intermediate"}>Intermediate</MenuItem>
                    <MenuItem value={"Advanced"}>Advanced</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.fld_englishKnowledge
                      ? errors.fld_englishKnowledge.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <FormControl
                  fullWidth
                  error={Boolean(errors.fld_computerKnowledge)}
                >
                  <InputLabel id="demo-simple-select-label">
                    Level of Computer Knowledge*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select1"
                    label="Level of Computer Knowledge*"
                    {...register("fld_computerKnowledge", {
                      required: "This field is required",
                    })}
                    value={watch("fld_computerKnowledge") ? watch("fld_computerKnowledge") : ""}
                    error={errors?.fld_computerKnowledge}
                    InputLabelProps={{ shrink: true, }}
                  >
                    <MenuItem value={"Beginner"}>Beginner</MenuItem>
                    <MenuItem value={"Intermediate"}>Intermediate</MenuItem>
                    <MenuItem value={"Advanced"}>Advanced</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.fld_computerKnowledge
                      ? errors.fld_computerKnowledge.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
          </div>
          {action != "view" &&
            <div className="card-vahani" style={{ marginTop: "26px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  // padding: "24px 0px",
                }}
              >
                <div className="but-width-form">
                  <Button
                    variant="contained"
                    className="but-login-vahani"
                    type="submit"
                  >
                    Save And Update
                  </Button>
                </div>
              </div>
            </div>}
        </form>
      </div>
    </>
  );
}
