import React from 'react';
import PostApiCall from '../../../assets/API/PostApi';
import Notiflix from 'notiflix';
import moment from 'moment';


// modal for expenses
const ViewExpense = ({ stdid, action }) => {
    var login = stdid ? { fld_id: stdid } : JSON.parse(localStorage.getItem("LoginDetail"))[0];
    const [ExpenseList, setExpenseList] = React.useState([])
    React.useEffect(() => {
        GetExpenseList()
    }, [])

    const GetExpenseList = () => {
        Notiflix.Loading.dots()
        PostApiCall.postRequest(
            {
                whereClause: `where fld_studentId=${login.fld_id} order by fld_id desc`,
            },
            "Get_ScholarExpenseInformation"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    //
                    Notiflix.Loading.remove();
                    setExpenseList(obj.data)
                }
            })
        )
    }
    return (
        <div className="row m-0 ">
            {ExpenseList.map((data, i) => {
                return (
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-3">
                        <div className="card-hover p-3">
                            <div>
                                <h6 className="personal-info p-0">{data.fld_expense_type}</h6>
                                <span style={{ fontSize: "14px", color: "GrayText" }}>Amount (₹) : {data.fld_feeAmount}/-</span><br />
                                <span style={{ fontSize: "14px", color: "GrayText" }}>Pay To : {data.payto}</span><br />
                                <span style={{ fontSize: "14px", color: "GrayText" }}>Raise On : {moment(data.fld_requestedon).format("DD/MM/YYYY")}</span><br /><br />
                                <h6 style={{ fontWeight: 700, fontSize: "20px" }} className={` ${data.fld_approval_status == "Closed" ? "text-success" : (data.fld_approval_status == "Rejected" ? "text-danger" : "text-warning")}`} > {data.fld_approval_status} </h6>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
export default ViewExpense
{/* <div className="col-md-3 p-2" >
<div className="col-card-main px-4 text-start" style={{ backgroundColor: i%4 == 1 ? "rgb(255, 247, 205)" : (i%4 == 2 ? "#CCFFFF" : ( (i%4 == 3 && "rgb(255, 231, 217)" ) ) ) }}>
    <h5 className='fw-bold text-dark'>{data.fld_expense_type}</h5>
</div>
</div> */}