import React, { useState, useEffect } from 'react';
import PostApiCall from '../../../assets/API/PostApi';
import GetApiCall from '../../../assets/API/GetApi';
import Notiflix from 'notiflix';
// import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useHistory } from 'react-router-dom';
const ValidationMaster = () => {
  const history = useHistory()
  const [ValidationDropdown, setValidationDropdown] = useState([]);
  const [ValidationList, setValidationList] = useState(JSON.parse(localStorage.getItem("CommonValidation")));
  const [SelectValidation, setSelectValidation] = useState(null);
  const [ExpenseList, setExpenseList] = useState([]);
  const [ExpenseDropdown, setExpenseDropdown] = useState([]);
  const [ExpenseSelect, setExpenseSelect] = useState(null);
  const [FieldDropdown, setFieldDropdown] = useState([]);
  const [FieldForm, setFieldForm] = useState(null);
  const GetValidationDropdown = () => {
    Notiflix.Loading.dots()
    GetApiCall.getRequest(
      "Get_Validation_dropdown"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status === 200 || result.status === 201) {
          //
          setValidationDropdown(obj.data)
        }
        Notiflix.Loading.remove();
      })
    );
  }
  const GetValidationData = (id) => {
    Notiflix.Loading.dots()
    PostApiCall.postRequest(
      { whereClause: `where fld_module='${id}'`, },
      "FormValidation"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          //
          setValidationList(obj.data)
          // setExpenseList(obj.data)
          Notiflix.Loading.remove();
        }
      }))
  }
  useEffect(() => {
    GetValidationDropdown()
  }, [])
  const UpdateValidateFormField = () => {
    Notiflix.Loading.dots()
    //
    PostApiCall.postRequest(
      {
        validationid: FieldForm.configId,
        module: SelectValidation,
        fieldname: FieldForm.name,
        helptext: FieldForm.helper,
        errormessage: FieldForm.error,
        mandatory: FieldForm.required ? "Yes" : "No",
        enabled: FieldForm.enabled,
        editable: FieldForm.editable ? "Yes" : "No",
      },
      "Update_Validation"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          //
          setFieldForm(null)
          GetValidationData(SelectValidation)
          Notiflix.Loading.remove();
        }
      })
    )
  }
  return (
    <div className='row m-0' >
      <div className='col-md-6'>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={FieldForm?.validationType}
            name="expenseType"
            label="Select Expense"
            onChange={e => {
              // setExpenseSelect(e.target.value)
              // GetExpenseData(e.target.value)
              GetValidationData(e.target.value)
              setSelectValidation(e.target.value)
            }}
          >
            {ValidationDropdown.map(val => <MenuItem value={val.fld_module}>{val.fld_module}</MenuItem>)}
          </Select>
        </FormControl>
      </div>
      {SelectValidation != null &&
        <>
          <table class="table table-responsive mt-5 border p-0 rounded-3">
            <thead class="table-dark">
              <tr>
                <th scope="col">Field Name</th>
                <th scope="col">Helper Text</th>
                <th scope="col">Error Message</th>
                <th scope="col">Editable</th>
                <th scope="col">Required</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {FieldForm?.configId &&
                <tr>
                  <td><input type="text" class="form-control" name='name' value={FieldForm?.name} onChange={e => setFieldForm({ ...FieldForm, [e.target.name]: e.target.value })} /></td>
                  <td><input type="text" class="form-control" name='helper' value={FieldForm?.helper} onChange={e => setFieldForm({ ...FieldForm, [e.target.name]: e.target.value })} /></td>
                  <td><input type="text" class="form-control" name='error' value={FieldForm?.error} onChange={e => setFieldForm({ ...FieldForm, [e.target.name]: e.target.value })} /></td>
                  <td><input class="form-check-input ms-3" type="checkbox" name='editable' value={FieldForm?.required} onChange={e => setFieldForm({ ...FieldForm, [e.target.name]: e.target.checked })} checked={FieldForm?.editable} /></td>
                  <td><input class="form-check-input ms-3" type="checkbox" name='required' value={FieldForm?.required} onChange={e => setFieldForm({ ...FieldForm, [e.target.name]: e.target.checked })} checked={FieldForm?.required} /></td>
                  <td><AddCircleIcon style={{ cursor: "pointer" }} color='primary' onClick={UpdateValidateFormField} /></td>
                </tr>
              }
              {ValidationList.map(item => {
                return (
                  <tr>
                    {/* <th scope="row">{i + 1}</th> */}
                    {/* <td>{item.fld_sequence}</td> */}
                    <td>{item.fld_field_name}</td>
                    <td>{item.fld_help_text}</td>
                    <td>{item.fld_error_message}</td>
                    <td>{item.fld_editable}</td>
                    <td>{item.fld_mandatory}</td>
                    <td>
                      <ModeEditOutlineIcon style={{ cursor: "pointer" }} color='primary' onClick={() => setFieldForm({ configId: item.fld_validation_id, name: item.fld_field_name, helper: item.fld_help_text, error: item.fld_error_message, enabled: item.fld_enabled, editable: item.fld_editable == "Yes" ? true : false, required: item.fld_mandatory == "Yes" ? true : false })}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className='row justify-content-end m-0 mt-3'>
            <button className=' col-auto btn btn-primary' onClick={() => history.push(`/expense-master-preview/${ExpenseSelect}`)}>Continue</button>
          </div>
        </>
      }
    </div >
  )
}
export default ValidationMaster