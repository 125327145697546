import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import PostApiCall from "../../../assets/API/PostApi";
import { FaEdit } from "react-icons/fa";

export default function PaymentDeatils() {
  const [orderInvoiceDetails, setOrderInvoiceDetails] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetPurchaseInvoices"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setOrderInvoiceDetails(obj.data);
          Notiflix.Notify.success("Data saved successfully");
        }
      })
    );
  }, []);

  const data = {
    columns: [
      {
        label: "S.No.",
        field: "serial",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Invoice Id",
        field: "invoiceid",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "disabled",
        width: 400,
      },
      {
        label: "TDS Percentage",
        field: "tdspercentage",
        sort: "disabled",
        width: 150,
      },
      {
        label: "TDS Amount",
        field: "tdsamount",
        sort: "disabled",
        width: 400,
      },
      {
        label: "SGST",
        field: "sgst",
        sort: "disabled",
        width: 400,
      },
      {
        label: "CGST",
        field: "cgst",
        sort: "disabled",
        width: 400,
      },
      {
        label: "IGST",
        field: "igst",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
        sort: "asc",
      },
    ],
    rows: orderInvoiceDetails.map((data, i) => {
      return {
        serial: i + 1,

        createdby: data.created_by_name,
        UpdatedBy: data.updated_by_name,
        action: (
          <div className="d-flex">
            <Link className="m-auto" to={{}}>
              <FaEdit className="edit-icon edit-icon-font-size" />
            </Link>
          </div>
        ),
      };
    }),
  };

  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Payment details</h4>
        </div>
      </div>
      <div className="card-vahani p-0 mt-2">
        <p className="personal-info m-0 left-line-card p-4 d-flex align-items-center justify-content-between">
          Payment List{" "}
          <div className="col-md-2 ps-0 pe-0">
            <Button variant="contained" className="w-100">
              <Link to="/add-payments" className="text-white">
                Add New Payment
              </Link>
            </Button>
          </div>
        </p>
        <div className="p-3">
          {/* {addProjectData.length > 0 ? ( */}
          <>
            <MDBDataTable
              responsive
              hover
              disableRetreatAfterSorting={true}
              data={data}
              entriesOptions={[10, 25, 50, 100]}
            >
              <MDBTableHead columns={data.columns} />
              <MDBTableBody rows={data.rows} />
            </MDBDataTable>
          </>
          {/* ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )} */}
        </div>
      </div>
    </>
  );
}
