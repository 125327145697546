import React, { Component } from "react";
import Notiflix from "notiflix";
import GetApiCall from "../../../../assets/API/GetApi";
import PostApiCall from "../../../../assets/API/PostApi";
import moment from "moment";
import { XSquare } from "react-feather";
import imageCompression from "browser-image-compression";
import {
  MenuItem,
  TextField,
  Checkbox,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput,
  Button,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SwipeableViews from "react-swipeable-views";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { Link } from "react-router-dom";

const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas m-0 img-button"
  >
    <div className="img-wrap img-upload">
      <img
        for="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input
      className="d-none"
      accept="image/*"
      id="photo-upload"
      type="file"
      onChange={onChange}
    />
  </label>
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dependants: [],

      imagePreviewUrl:
        "https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1223671392?k=20&m=1223671392&s=612x612&w=0&h=lGpj2vWAI3WUT1JeJWm1PRoHT3V15_1pdcTn2szdwQ0=",
      ImageApiUrl: "http://api.vahanischolarship.in/AddImage",
      ImageData: [],
      OriginalImage: [],
      outputFileName: "",

      imagePreviewUrl2:
        "https://talentview.asia/wp-content/uploads/Wait-Staff-Icon-2.png",
      ImageApiUrl2: "http://api.vahanischolarship.in/AddImage",
      ImageData2: [],

      imagePreviewUrlCheque:
        "https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png",
      ImageApiUrlCheque: "http://api.vahanischolarship.in/AddImage",
      ImageDataCheque: [],

      DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
      NumRegex: /^[0-9]*$/,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(https:\/\/www\.|https:\/\/www\.|https:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      GenderData: [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Others", label: "Others" },
      ],
      Martialstatus: [
        { value: "Single", label: "Single" },
        { value: "Married", label: "Married" },
        { value: "Divorced", label: "Divorced" },
        { value: "Widow", label: "Widow" },
      ],
      Qualifications: [
        { value: "10th", label: "10th" },
        { value: "12th", label: "12th" },
        { value: "BE/B.Tech", label: "BE/B.Tech" },
        { value: "B.Arch", label: "B.Arch" },
        { value: "BCA", label: "BCA" },
        {
          value: "B.Sc.- Information Technology",
          label: "B.Sc.- Information Technology",
        },
        { value: "B.Sc- Nursing.", label: "B.Sc- Nursing." },
        {
          value: "B.Pharma- Bachelor of Pharmacy.",
          label: "B.Pharma- Bachelor of Pharmacy.",
        },
        { value: "B.Sc- Interior Design.", label: "B.Sc- Interior Design." },
        {
          value: "BDS- Bachelor of Dental Surgery.",
          label: "BDS- Bachelor of Dental Surgery.",
        },
        { value: "MBA", label: "MBA" },
        { value: "LLB", label: "LLB" },
      ],
      IsVisible: false,
      Data: [],
      PersonalData: [],
      StatusData: [],
      StudentData: [],
      Editable: false,
      Status: [],

      CountryData: [],
      CityData: [],
      StateData: [],
      CountryPermanentData: [],
      CityPermanentData: [],
      StatePermanentData: [],
      CountryId: 0,
      StateId: 0,
      CityId: 0,
      CountryPermanentId: 0,
      StatePermanentId: 0,
      CityPermanentId: 0,
      Family: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
      ],

      TypeFamily: [
        { value: "Joint Family", label: "Joint Family" },
        { value: "Nuclear", label: "Nuclear" },
      ],
      Vehicle: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
      House: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
      FamilyOwns: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],

      Education: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
      EarningData: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
      ],
      EducationData: [
        { value: "No formal education", label: "No formal education" },
        { value: "Primary education", label: "Primary education" },
        { value: "Secondary education", label: "Secondary education" },
        { value: "GED", label: "GED" },
        {
          value: "Vocational qualification",
          label: "Vocational qualification",
        },
        { value: "Bachelor's degree", label: "Bachelor's degree" },
        { value: "Master's degree", label: "Master's degree" },
        { value: "Doctorate or higher", label: "Doctorate or higher" },
      ],
      OccupationData: [],
      FamilyInfoData: [],
      MOccupationData: [],
      Data: [],
      StatusData: [],
      StudentData: [],

      FatherSignUrl: "http://api.vahanischolarship.in/AddPdf",
      FatherSign: [],

      MotherSignUrl: "http://api.vahanischolarship.in/AddPdf",
      MotherSign: [],

      GuardianSignUrl: "http://api.vahanischolarship.in/AddPdf",
      GuardianSign: [],

      SalaryData: [],
      SalaryUrl: "http://api.vahanischolarship.in/AddPdf",

      FatherFile: "",
      MotherFile: "",
      GuardianFile: "",
      SalaryFile: "",

      ComputerData: [
        { value: "Beginner", label: "Beginner" },
        { value: "Intermediate", label: "Intermediate" },
        { value: "Advanced", label: "Advanced" },
      ],
      EnglishData: [
        { value: "Beginner", label: "Beginner" },
        { value: "Intermediate", label: "Intermediate" },
        { value: "Advanced", label: "Advanced" },
      ],
      BoardData: [
        { value: "CBSE", label: "CBSE" },
        { value: "ISC", label: "ISC" },
        { value: "ICSE", label: "ICSE" },
        { value: "NIOS", label: "NIOS" },
        { value: "UP Board", label: "UP Board" },
        { value: "JKBOSE", label: "JKBOSE" },
        { value: "RBSE", label: "RBSE" },
        { value: "HPBOSE", label: "HPBOSE" },
        { value: "MPBSE", label: "MPBSE" },
        { value: "CGBSE", label: "CGBSE" },
        { value: "PSEB", label: "PSEB" },
        { value: "BSEH", label: "BSEH" },
        { value: "BSEB", label: "BSEB" },
        { value: "GSEB", label: "GSEB" },
        { value: "MSBSHSE", label: "MSBSHSE" },
        { value: "BIEAP", label: "BIEAP" },
        { value: "BSEAP", label: "BSEAP" },
        { value: "WBBSE", label: "WBBSE" },
        { value: "WBCHES", label: "WBCHES" },
        { value: "KBPE", label: "KBPE" },
        { value: "KSEEB", label: "KSEEB" },
        { value: "Others", label: "Others" },
      ],

      Name: "",
      Gender: "",
      Marritalstatus: "",
      Dob: "",
      Educationqualification: "",
      Pancard: "",
      Peremail: "",
      Bloodgroup: "",
      Percontno: "",
      Percountry: 0,
      Perstate: "",

      Percity: "",
      Perpincode: "",
      Peraddress: "",
      Curcountry: "",
      Curstate: "",
      Curcity: "",
      Curpincode: "",
      Curaddress: "",

      Dependents: "",
      Spousename: "",
      Spouseage: "",
      Children: "",

      Fathername: "",
      Fathercontact: "",
      Mothername: "",

      Mothercontact: "",
      Bankname: "",
      Accountnumber: "",

      Ifsccode: "",
      Phone1: "",
      Email: "",
      Joiningdate: "",
      Currentposition: "",
      Photo: "",
      Employmenttype: "",
      Experience: "",
      Noticeperiod: "",
      Dateofresignation: "",

      Exitdate: "",
      Year: "",
      Department: "",
      Usertype: "",
      Firstname: "",
      Lastname: "",
      MiddleName: "",
      Firstnumber: "",
      Secondnumber: "",
      Thirdnumber: "",
      Fourthnumber: "",
      Fifthnumber: "",
      CertificateName: "",
      YearOfPassSenSecondary: "",
      YearOfPassSecondary: "",
      Graduation: "",
      Secondary: "10th",
      SeniorSecondary: "12th",
      Dateofjoining: "",
      Monthlysalary: "",
      Anualsalary: "",
      EmFirstContactRelation: "",
      EmFirstContactNumber: "",
      EmFirstContactName: "",
      SecondContactRelation: "",
      EmSecondContactNumber: "",
      EmSecondContactName: "",
      InputValues: "",
      GraduationCounter: 0,
      CertificateCounter: 0,
      GraduationList: [
        {
          graduationname: "",
          passinyear: "",
        },
      ],
      CertificateList: [
        {
          certificatename: "",
          passinyear: "",
        },
      ],

      EmpId: "",
      EmpFirstName: "",
      EmpMiddleName: "",
      EmpLastname: "",
      EmpBloodgroup: "",
      EmpEmail: "",
      EmpDob: "",
      EmpGender: "",
      EmpMarritalstatus: "",
      EmpPassword: "",
      EmpCommunicatioAdd: "",
      EmpCuraddress: "",
      EmpPeraddress: "",
      EmpPhone1: "",
      EmpPhone2: "",
      EmpAadharcard: "",
      EmpPancard: "",
      SchoolEducationTenth: 10,
      YearOfPassTenth: "",
      SchoolEducationTwelth: 12,
      YearOfPassTwelth: 0,
      EmpJoiningdate: "",
      EmpCurrentposition: "",
      EmpMonthlysalary: 0,
      EmpAnualsalary: 0,
      EmpEmFirstContactName: "",
      EmpEmFirstContactNumber: "",
      EmpEmFirstContactRelation: "",
      EmpEmSecondContactName: "",
      EmpEmSecondContactNumber: "",
      EmpEmSecondContactRelation: "",
      EmpGraduation: "",
      EmpAllGraduation: [],
      EmpGraduationPassYear: 0,
      EmpCertificateName: "",
      EmpAllCertificate: [],
      DependentName: "",
      DependentAge: 0,
      EmpAllDependents: [],
      FatherDependentName: "",
      FatherDependentAge: 0,
      FatherGender: "",
      MotherDependentName: "",
      MotherDependentAge: 0,
      MotherGender: "",
      SpouseDependentName: "",
      SpouseDependentAge: 0,
      SpouseGender: "",
      ChildDependentName: "",
      ChildDependentAge: 0,
      ChildGender: "",
      OtherDependentName: "",
      OtherDependentAge: 0,
      OtherGender: "",
      OtherRelationName: "",
      Id: 0,
      BankAccNo: "",
      AccHolderName: "",
      IFSCCode: "",
      BranchName: "",
      YearOfJoining: "",
      DependentId: 0,
      tab: 0,
      EmployeeCountry: [],
      EmployeeCommunicationState: [],
      EmployeeCommunicationCity: [],
      EmployeePresentState: [],
      EmployeePresentCity: [],
      EmployeePermanentState: [],
      EmployeePermanentCity: [],
      Pincode: "",
      EmpCommunicationAdd: "",
      EmpCommunicationCountry: "",
      EmpCommunicationState: "",
      EmpCommunicationCity: "",
      EmpCommunicationPincode: "",
      EmpPresentAdd: "",
      EmpPresentCountry: "",
      EmpPresentState: "",
      EmpPresentCity: "",
      EmpPresentPincode: "",
      EmpPermanentAdd: "",
      EmpPermanentCountry: "",
      EmpPermanentState: "",
      EmpPermanentCity: "",
      EmpPermanentPincode: "",
      SameAsPresent: false,
      CourseData: [
        { value: "Select Course", label: "Select Course" },
        {
          value: "Bachelor of Science - B.Sc",
          label: "Bachelor of Science - B.Sc",
        },
        {
          value: "Bachelor of Physiotherapy - B.P.T",
          label: "Bachelor of Physiotherapy - B.P.T",
        },
        {
          value:
            "Bachelor of Engineering / Bachelor of Technology - B.E./B.Tech",
          label:
            "Bachelor of Engineering / Bachelor of Technology - B.E./B.Tech",
        },
        { value: "Bachelor of Arts - B.A", label: "Bachelor of Arts - B.A" },
        {
          value: "Bachelor of Commerce - B.Com",
          label: "Bachelor of Commerce - B.Com",
        },
        {
          value: "Bachelor of Medicine and Bachelor of Surgery - MBBS",
          label: "Bachelor of Medicine and Bachelor of Surgery - MBBS",
        },
        {
          value: "Bachelor of Pharmacy - B.Pharm / B.Pharma.",
          label: "Bachelor of Pharmacy - B.Pharm / B.Pharma.",
        },
        {
          value: "Bachelor of Management Studies(BMS)",
          label: "Bachelor of Management Studies(BMS)",
        },
        {
          value: "Bachelor of Mass Communications - B.M.C. / B.M.M.",
          label: "Bachelor of Mass Communications - B.M.C. / B.M.M.",
        },
        {
          value: "Bachelor of Business Management (BBM)",
          label: "Bachelor of Business Management (BBM)",
        },
        {
          value: "Bachelor of Business Administration(BBA)",
          label: "Bachelor of Business Administration(BBA)",
        },
        {
          value: "Bachelor of Business Studies(BBS)",
          label: "Bachelor of Business Studies(BBS)",
        },
        { value: "Mass Communication", label: "Mass Communication" },
        {
          value: "Bachelor of Laws - L.L.B.",
          label: "Bachelor of Laws - L.L.B.",
        },
        { value: "B.Des Fashion Design", label: "B.Des Fashion Design" },
        {
          value: "Bachelor of Architecture - B.Arch",
          label: "Bachelor of Architecture - B.Arch",
        },
        {
          value: "Master of Science - M.Sc",
          label: "Master of Science - M.Sc",
        },
        {
          value: "Master of Physiotherapy - M.P.T.",
          label: "Master of Physiotherapy - M.P.T.",
        },
        {
          value: "Master of Engineering / Master of Technology - M.E./ M.Tech",
          label: "Master of Engineering / Master of Technology - M.E./ M.Tech",
        },
        { value: "Master of Arts - M.A", label: "Master of Arts - M.A" },
        {
          value: "Master of Commerce - M.Com",
          label: "Master of Commerce - M.Com",
        },
        {
          value: "Master of Pharmacy - M.Pharm ",
          label: "Master of Pharmacy - M.Pharm",
        },
        {
          value: "Master of Mass Communications / Mass Media - M.M.C / M.M.M",
          label: "Master of Mass Communications / Mass Media - M.M.C / M.M.M",
        },
        { value: "Master of Laws - L.L.M", label: "Master of Laws - L.L.M" },
        {
          value: "Master of Business Administration - M.B.A",
          label: "Master of Business Administration - M.B.A",
        },
        {
          value: "Master of Design - M.Des./ M.Design",
          label: "Master of Design - M.Des./ M.Design",
        },
        {
          value: "Master of Architecture - M.Arch",
          label: "Master of Architecture - M.Arch",
        },
        { value: "Others, Please specify", label: "Others, Please specify" },
      ],
      OtherGraduation: false,
      PleaseSpecify: "",
      SelectedDependant: "",
      AllRequiredFeild: [],
      EmpAllGraduation: [],
      EmpAllCertificate: [],
      CertificateSaved: true,
      Saved: false,
      AppointmentLetterName: "",
      AppointmentLetterFile: [],
      AppointmentLetterPath: "",
      AppointmentLetterUploaded: false,
      ContractUploaded: false,
      ContractName: "",
      ContractFile: [],
      ContractPath: "",
      EmpDepartment: "",
      EmpFirstGraduationName: "",
      EmpFirstGraduationPassingYear: "",
      AllGradutaionList: [],
      GraduationName: "",
      GraduationPassingYear: "",
      AllCertificateList: [],
      CertificateFile: [],
      GradutaionId: null,
      CertificateId: null,
      FatherDependentId: null,
      MotherDependentId: null,
      SpouseDependentId: null,
      ChildDependentId: null,
      OtherDependentId: null,
    };
  }

  componentDidMount() {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetCountry").then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({ EmployeeCountry: obj.data });
          Notiflix.Loading.remove();
        }
      })
    );

    PostApiCall.postRequest(
      {
        whereClause: `where [fld_employee_id]= '${JSON.parse(localStorage.getItem("LoginDetail")).fld_employee_id
          }'`,
      },
      "GetGraduationById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            AllGradutaionList: obj.data,
          });
          Notiflix.Loading.remove();
        }
        Notiflix.Loading.remove();
      })
    );

    PostApiCall.postRequest(
      {
        whereClause: `where [fld_employee_id]= '${JSON.parse(localStorage.getItem("LoginDetail")).fld_employee_id
          }'`,
      },
      "GetCertificateById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            AllCertificateList: obj.data,
          });
          Notiflix.Loading.remove();
        }
        Notiflix.Loading.remove();
      })
    );

    PostApiCall.postRequest(
      {
        whereClause: `where [fld_employee_id]= '${JSON.parse(localStorage.getItem("LoginDetail")).fld_employee_id
          }'`,
      },
      "GetDependentsById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          obj.data.map((dependant, i) => {
            if (dependant.fld_relation == "father") {
              this.setState({
                FatherDependentName: dependant.fld_dependent_name,
                FatherDependentAge: dependant.fld_dependent_age,
                FatherGender: dependant.fld_dependent_gender,
                FatherDependentId: dependant.fld_id,
              });
            } else if (dependant.fld_relation == "mother") {
              this.setState({
                MotherDependentName: dependant.fld_dependent_name,
                MotherDependentAge: dependant.fld_dependent_age,
                MotherGender: dependant.fld_dependent_gender,
                MotherDependentId: dependant.fld_id,
              });
            } else if (dependant.fld_relation == "spouse") {
              this.setState({
                SpouseDependentName: dependant.fld_dependent_name,
                SpouseDependentAge: dependant.fld_dependent_age,
                SpouseGender: dependant.fld_dependent_gender,
                SpouseDependentId: dependant.fld_id,
              });
            } else if (dependant.fld_relation == "children") {
              this.setState({
                ChildDependentName: dependant.fld_dependent_name,
                ChildDependentAge: dependant.fld_dependent_age,
                ChildAge: dependant.fld_dependent_gender,
                ChildDependentId: dependant.fld_id,
              });
            } else {
              this.setState({
                OtherDependentName: dependant.fld_dependent_name,
                OtherDependentAge: dependant.fld_dependent_age,
                OtherGender: dependant.fld_dependent_gender,
                OtherRelationName: dependant.fld_relation,
                OtherDependentId: dependant.fld_id,
              });
            }
          });

          Notiflix.Loading.remove();
        }
        Notiflix.Loading.remove();
      })
    );

    PostApiCall.postRequest(
      {
        whereClause: `where [fld_id]= ${JSON.parse(localStorage.getItem("LoginDetail")).fld_id
          }`,
      },
      "GetEmployeeById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.remove();
          this.setState({
            Id: obj.data[0].fld_id,
            imagePreviewUrl: obj.data[0].fld_employee_image,
            EmpFirstName: obj.data[0].fld_employee_full_name.split(" ")[0],
            EmpMiddleName:
              obj.data[0].fld_employee_full_name.split(" ").length > 2
                ? obj.data[0].fld_employee_full_name.split(" ")[1]
                : "",
            EmpLastname:
              obj.data[0].fld_employee_full_name.split(" ").length > 2
                ? obj.data[0].fld_employee_full_name.split(" ")[2]
                : obj.data[0].fld_employee_full_name.split(" ")[1],
            EmpBloodgroup: obj.data[0].fld_blood_group,
            EmpEmail: obj.data[0].fld_email,
            EmpDob: moment(obj.data[0].fld_dob).format("YYYY-MM-DD"),
            EmpGender: obj.data[0].fld_gender,
            EmpMarritalstatus: obj.data[0].fld_martial_status,
            EmpCommunicationAdd: obj.data[0].fld_communication_add,
            EmpCommunicationCountry: obj.data[0].fld_communication_country,
            EmpCommunicationState: obj.data[0].fld_communication_state,
            EmpCommunicationCity: obj.data[0].fld_communication_city,
            EmpCommunicationPincode: obj.data[0].fld_communication_pincode,

            EmpPresentAdd: obj.data[0].fld_present_add,
            EmpPresentCountry: obj.data[0].fld_present_country,
            EmpPresentState: obj.data[0].fld_present_state,
            EmpPresentCity: obj.data[0].fld_present_city,
            EmpPresentPincode: obj.data[0].fld_present_pincode,

            EmpPermanentAdd: obj.data[0].fld_permanent_add,
            EmpPermanentCountry: obj.data[0].fld_permanent_country,
            EmpPermanentState: obj.data[0].fld_permanent_state,
            EmpPermanentCity: obj.data[0].fld_permanent_city,
            EmpPermanentPincode: obj.data[0].fld_permanent_pincode,
            EmpPhone1: obj.data[0].fld_contact_no.split(" ")[0],
            EmpPhone2: obj.data[0].fld_contact_no.split(" ")[1],
            EmpAadharcard: obj.data[0].fld_aadhar_card_number,
            EmpPancard: obj.data[0].fld_pan_card_number,
            BankAccNo: obj.data[0].fld_bank_account_number,
            AccHolderName: obj.data[0].fld_account_holder_name,
            IFSCCode: obj.data[0].fld_ifsc_code,
            BranchName: obj.data[0].fld_branch_name,
            YearOfPassTenth: obj.data[0].fld_year_of_pass_10,
            YearOfPassTwelth: obj.data[0].fld_year_of_pass_12,
            EmpJoiningdate: moment(obj.data[0].fld_date_of_joining).format(
              "YYYY-MM-DD"
            ),
            EmpCurrentposition: obj.data[0].fld_designation,
            EmpDepartment: obj.data[0].fld_department,
            EmpMonthlysalary: obj.data[0].fld_monthly_salary,
            EmpAnualsalary: obj.data[0].fld_annual_salary,
            AppointmentLetterName: obj.data[0].fld_appointment_name,
            AppointmentLetterPath: obj.data[0].fld_appointment_file,
            ContractName: obj.data[0].fld_contract_name,
            ContractPath: obj.data[0].fld_contract_file,
            EmpEmFirstContactName:
              obj.data[0].fld_emergency_name == null
                ? ""
                : obj.data[0].fld_emergency_name.split(" ")[0],
            EmpEmFirstContactNumber:
              obj.data[0].fld_emergency_contact_no == null
                ? ""
                : obj.data[0].fld_emergency_contact_no.split(" ")[0],
            EmpEmFirstContactRelation:
              obj.data[0].fld_emergency_relation == null
                ? ""
                : obj.data[0].fld_emergency_relation.split(" ")[0],
            EmpEmSecondContactName:
              obj.data[0].fld_emergency_name == null
                ? ""
                : obj.data[0].fld_emergency_name.split(" ")[1],
            EmpEmSecondContactNumber:
              obj.data[0].fld_emergency_contact_no == null
                ? ""
                : obj.data[0].fld_emergency_contact_no.split(" ")[1],
            EmpEmSecondContactRelation:
              obj.data[0].fld_emergency_relation == null
                ? ""
                : obj.data[0].fld_emergency_relation.split(" ")[1],
          });

          let communicationCountryId =
            obj.data[0].fld_communication_country == null
              ? ""
              : obj.data[0].fld_communication_country;
          if (communicationCountryId != "" && communicationCountryId != null) {
            PostApiCall.postRequest(
              { countryid: communicationCountryId },
              "GetState"
            ).then((resultcategory) =>
              resultcategory.json().then((obj) => {
                if (
                  resultcategory.status == 200 ||
                  resultcategory.status == 201
                ) {
                  this.setState({ EmployeeCommunicationState: obj.data });
                } else {
                  Notiflix.Notify.failure(obj.data);
                }
              })
            );
          }

          let presentCountryId = obj.data[0].fld_present_country;
          if (presentCountryId != "" && presentCountryId != null) {
            PostApiCall.postRequest(
              { countryid: presentCountryId },
              "GetState"
            ).then((resultcategory) =>
              resultcategory.json().then((obj) => {
                if (
                  resultcategory.status == 200 ||
                  resultcategory.status == 201
                ) {
                  this.setState({ EmployeePresentState: obj.data });
                } else {
                  Notiflix.Notify.failure(obj.data);
                }
              })
            );
          }

          let permanentCountryId = obj.data[0].fld_permanent_country;
          if (permanentCountryId != "" && permanentCountryId != null) {
            PostApiCall.postRequest(
              { countryid: permanentCountryId },
              "GetState"
            ).then((resultcategory) =>
              resultcategory.json().then((obj) => {
                if (
                  resultcategory.status == 200 ||
                  resultcategory.status == 201
                ) {
                  this.setState({ EmployeePermanentState: obj.data });
                } else {
                  Notiflix.Notify.failure(obj.data);
                }
              })
            );
          }

          let CommunicationStateId = obj.data[0].fld_communication_state;

          if (CommunicationStateId != "" && CommunicationStateId != null) {
            PostApiCall.postRequest(
              { stateid: CommunicationStateId },
              "GetCity"
            ).then((resultcategory) =>
              resultcategory.json().then((obj) => {
                if (
                  resultcategory.status == 200 ||
                  resultcategory.status == 201
                ) {
                  this.setState({ EmployeeCommunicationCity: obj.data });
                } else {
                  Notiflix.Notify.failure(obj.data);
                }
              })
            );
          }

          let PresentStateId = obj.data[0].fld_present_state;
          if (PresentStateId != "" && PresentStateId != null) {
            PostApiCall.postRequest(
              { stateid: PresentStateId },
              "GetCity"
            ).then((resultcategory) =>
              resultcategory.json().then((obj) => {
                if (
                  resultcategory.status == 200 ||
                  resultcategory.status == 201
                ) {
                  this.setState({ EmployeePresentCity: obj.data });
                } else {
                  Notiflix.Notify.failure(obj.data);
                }
              })
            );
          }

          let PermanentStateId = obj.data[0].fld_permanent_state;
          if (PermanentStateId != "" && PermanentStateId != null) {
            PostApiCall.postRequest(
              { stateid: PermanentStateId },
              "GetCity"
            ).then((resultcategory) =>
              resultcategory.json().then((obj) => {
                if (
                  resultcategory.status == 200 ||
                  resultcategory.status == 201
                ) {
                  this.setState({ EmployeePermanentCity: obj.data });
                } else {
                  Notiflix.Notify.failure(obj.data);
                }
              })
            );
          }
        }
        Notiflix.Loading.remove();
      })
    );
  }

  photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    this.setState({
      imagePreviewUrl: URL.createObjectURL(imageFile),
      OriginalImage: imageFile,
      outputFileName: imageFile.name,
    });
  };
  getGraduation = async () => {
    await PostApiCall.postRequest(
      {
        whereClause: `where [fld_employee_id]= '${JSON.parse(localStorage.getItem("LoginDetail")).fld_employee_id
          }'`,
      },
      "GetGraduationById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            AllGradutaionList: obj.data,
          });
          Notiflix.Loading.remove();
        }
        Notiflix.Loading.remove();
      })
    );
  };
  GraduationhandleSave = () => {
    PostApiCall.postRequest(
      {
        graduationid: this.state.GradutaionId,
        employeeid: JSON.parse(localStorage.getItem("LoginDetail"))
          .fld_employee_id,
        graduationname:
          this.state.GraduationName != "Others, Please specify"
            ? this.state.GraduationName
            : this.state.PleaseSpecify + "-" + "Others, Please specify",
        graduationpassyear: this.state.GraduationPassingYear,
      },
      "AddGraduation"
    ).then((graduationresponse) =>
      graduationresponse.json().then((graduationobj) => {
        this.getGraduation();
        this.setState({
          GraduationName: "",
          GraduationPassingYear: "",
        });
      })
    );
  };

  getCertificate = async () => {
    await PostApiCall.postRequest(
      {
        whereClause: `where [fld_employee_id]= '${JSON.parse(localStorage.getItem("LoginDetail")).fld_employee_id
          }'`,
      },
      "GetCertificateById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            AllCertificateList: obj.data,
          });
          Notiflix.Loading.remove();
        }
        Notiflix.Loading.remove();
      })
    );
  };

  CertificatehandleSave = () => {
    PostApiCall.postRequest(
      {
        certificateid: this.state.CertificateId,
        employeeid: JSON.parse(localStorage.getItem("LoginDetail"))
          .fld_employee_id,
        certificatename: this.state.EmpCertificateName,
        certificatefile:
          ".//vahanischolarship.in/Images/EmployInformation/" +
          this.state.CertificateFile.name,
      },
      "AddCertificate"
    ).then((certificateresponse) =>
      certificateresponse.json().then((certificateobj) => {
        if (
          certificateresponse.status == 200 ||
          certificateresponse.status == 201
        ) {
          this.uploadEmployeeCertificate(this.state.CertificateFile);
          this.getCertificate();
          this.setState({
            EmpCertificateName: "",
            CertificateFile: [],
          });
        }
      })
    );
  };

  uploadProfileImage = () => {
    Notiflix.Loading.dots();
    if (this.state.outputFileName != "") {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(this.state.OriginalImage, options).then(
        (compressedFile) => {
          const form = new FormData();
          form.append("file", compressedFile);
          form.append("foldername", "EmployInformation");
          form.append("filename", compressedFile.name);
          let response = fetch(
            "https://admin.vahanischolarship.in/API/AddImage2",
            {
              method: "POST",
              body: form,
            }
          )
            .then((response) => response.json())
            .then((data) => {
              Notiflix.Notify.success("Profile Picture Uploaded Successfully.");
              Notiflix.Loading.remove();
            });
        }
      );
    } else {
      Notiflix.Notify.failure("Please Upload the Profile Picture.");
      Notiflix.Loading.remove();
    }
  };

  uploadEmployeeCertificate = (certificateFile) => {
    Notiflix.Loading.dots();
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const form = new FormData();
    form.append("file", certificateFile);
    form.append("foldername", "EmployInformation");
    form.append("filename", certificateFile.name);
    let response = fetch("https://admin.vahanischolarship.in/API/AddPdf2", {
      method: "POST",
      body: form,
    })
      .then((response) => response.json())
      .then((data) => {
        Notiflix.Notify.success("Certificate Uploaded Successfully.");
        Notiflix.Loading.remove();
      });
  };

  uploadEmployeeAppointmentContract = (file) => {
    Notiflix.Loading.dots();
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const form = new FormData();
    form.append("file", file);
    form.append("foldername", "EmployInformation");
    form.append("filename", file.name);
    let response = fetch("https://admin.vahanischolarship.in/API/AddPdf2", {
      method: "POST",
      body: form,
    })
      .then((response) => response.json())
      .then((data) => {
        Notiflix.Notify.success("Document Uploaded Successfully");
        Notiflix.Loading.remove();
      });
  };

  getEmployeeState = (value, addresstype) => {
    PostApiCall.postRequest({ countryid: value }, "GetState").then(
      (resultcategory) =>
        resultcategory.json().then((obj) => {
          if (resultcategory.status == 200 || resultcategory.status == 201) {
            if (addresstype === "communication") {
              this.setState({ EmployeeCommunicationState: obj.data });
            } else if (addresstype === "present") {
              this.setState({ EmployeePresentState: obj.data });
            } else {
              this.setState({ EmployeePermanentState: obj.data });
            }
          } else {
            Notiflix.Notify.failure(obj.data);
          }
        })
    );
  };

  getEmployeeCity = (value, addresstype) => {
    PostApiCall.postRequest({ stateid: value }, "GetCity").then(
      (resultcategory) =>
        resultcategory.json().then((obj) => {
          if (resultcategory.status == 200 || resultcategory.status == 201) {
            if (addresstype === "communication") {
              this.setState({ EmployeeCommunicationCity: obj.data });
            } else if (addresstype === "present") {
              this.setState({ EmployeePresentCity: obj.data });
            } else {
              this.setState({ EmployeePermanentCity: obj.data });
            }
          } else {
            Notiflix.Notify.failure(obj.data);
          }
        })
    );
  };

  SameAsPresent = () => {
    if (this.state.SameAsPresent == true) {
      this.setState({
        SameAsPresent: false,
        EmpPermanentAdd: "",
        EmpPermanentCountry: null,
        EmpPermanentState: null,
        EmpPermanentCity: null,
        EmpPermanentPincode: "",
      });
    } else {
      this.setState({
        SameAsPresent: true,
        EmpPermanentAdd: this.state.EmpPresentAdd,
        EmpPermanentCountry: this.state.EmpPresentCountry,
        EmpPermanentState: this.state.EmpPresentState,
        EmpPermanentCity: this.state.EmpPresentCity,
        EmpPermanentPincode: this.state.EmpPresentPincode,
      });
    }
  };
  onSaveEmployee = (requiredField) => {
    Notiflix.Loading.dots();
    if (
      this.state.imagePreviewUrl ==
      "https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1223671392?k=20&m=1223671392&s=612x612&w=0&h=lGpj2vWAI3WUT1JeJWm1PRoHT3V15_1pdcTn2szdwQ0="
    ) {
      if (this.state.outputFileName.length == 0)
        Notiflix.Notify.failure("Please add your profile image!");
    } else {
      let allvalidatedfield = [];
      let allFieldValidationCheck = false;
      for (var i = 0; i < requiredField.length; i++) {
        if (requiredField[i] != "" || requiredField[i] != 0) {
          allvalidatedfield.push(requiredField[i]);
          allFieldValidationCheck = true;
        } else {
          Notiflix.Notify.failure("Please Enter the all Mandatory Fields!");
          this.setState({
            EmpPermanentAdd: "",
            EmpPermanentCountry: null,
            EmpPermanentState: null,
            EmpPermanentCity: null,
            EmpPermanentPincode: null,
          });
          Notiflix.Loading.remove();
          allFieldValidationCheck = false;
          break;
        }
      }
      let empobj = {};

      if (allFieldValidationCheck == true) {
        empobj = {
          id: JSON.parse(localStorage.getItem("LoginDetail")).fld_id,
          empprofileimage:
            this.state.outputFileName.length != 0
              ? ".//vahanischolarship.in/Images/EmployInformation/" +
              this.state.outputFileName
              : this.state.imagePreviewUrl,
          empfullname:
            this.state.EmpFirstName +
            " " +
            this.state.EmpMiddleName +
            " " +
            this.state.EmpLastname,
          empbloodgroup: this.state.EmpBloodgroup,
          empdob: this.state.EmpDob,
          empgender: this.state.EmpGender,
          empmarritalstatus: this.state.EmpMarritalstatus,
          empcommunicationadd: this.state.EmpCommunicationAdd,
          empcommunicationcountry: this.state.EmpCommunicationCountry,
          empcommunicationstate: this.state.EmpCommunicationState,
          empcommunicationcity: this.state.EmpCommunicationCity,
          empcommunicationpincode: this.state.EmpCommunicationPincode,
          emppresentadd: this.state.EmpPresentAdd,
          emppresentcountry: this.state.EmpPresentCountry,
          emppresentstate: this.state.EmpPresentState,
          emppresentcity: this.state.EmpPresentCity,
          emppresentpincode: this.state.EmpPresentPincode,
          emppermanentadd: this.state.EmpPermanentAdd,
          emppermanentcountry: this.state.EmpPermanentCountry,
          emppermanentstate: this.state.EmpPermanentState,
          emppermanentcity: this.state.EmpPermanentCity,
          emppermanentpincode: this.state.EmpPermanentPincode,
          empphonecontactno: this.state.EmpPhone1 + " " + this.state.EmpPhone2,
          empaadharcard: this.state.EmpAadharcard,
          emppancard: this.state.EmpPancard,
          bankaccno: this.state.BankAccNo,
          accholdername: this.state.AccHolderName,
          ifsccode: this.state.IFSCCode,
          branchname: this.state.BranchName,
          empyearofpass10: this.state.YearOfPassTenth,
          empyearofpass12: this.state.YearOfPassTwelth,
          empjoiningdate: this.state.EmpJoiningdate,
          empcurrentposition: this.state.EmpCurrentposition,
          empdepartment: this.state.EmpDepartment,
          empmonthlysalary: parseInt(this.state.EmpMonthlysalary),
          empanualsalary: this.state.EmpAnualsalary,
          empappointmentlettername: this.state.AppointmentLetterName,
          empappointmentletterfile:
            this.state.AppointmentLetterUploaded == true
              ? ".//vahanischolarship.in/Images/EmployInformation/" +
              this.state.AppointmentLetterFile.name
              : this.state.AppointmentLetterPath,
          empcontractname: this.state.ContractName,
          empcontractfile:
            this.state.ContractUploaded == true
              ? "https://admin.vahanischolarship.in/Images/EmployInformation/" +
              this.state.ContractFile.name
              : this.state.ContractPath,
          emergencycontactname:
            this.state.EmpEmFirstContactName +
            " " +
            this.state.EmpEmSecondContactName,
          emergencyrealtion:
            this.state.EmpEmFirstContactRelation +
            " " +
            this.state.EmpEmSecondContactRelation,
          empemergencycontact:
            this.state.EmpEmFirstContactNumber +
            " " +
            this.state.EmpEmSecondContactNumber,
          yearofjoining: this.state.YearOfJoining.toString(),
        };
        PostApiCall.postRequest(empobj, "Add_Employee").then((resultcategory) =>
          resultcategory.json().then((obj) => {
            if (resultcategory.status == 200 || resultcategory.status == 201) {
              if (this.state.outputFileName.length != 0) {
                this.uploadProfileImage();
              }
              if (this.state.AppointmentLetterUploaded == true) {
                this.uploadEmployeeAppointmentContract(
                  this.state.AppointmentLetterFile
                );
              }
              if (this.state.ContractUploaded == true) {
                this.uploadEmployeeAppointmentContract(this.state.ContractFile);
              }
              if (
                this.state.FatherDependentName != "" &&
                this.state.FatherDependentAge != 0 &&
                this.state.FatherGender != 0
              ) {
                PostApiCall.postRequest(
                  {
                    dependentid: this.state.FatherDependentId,
                    dependentname: this.state.FatherDependentName,
                    employeeid: JSON.parse(localStorage.getItem("LoginDetail"))
                      .fld_employee_id,
                    dependentage: this.state.FatherDependentAge,
                    dependantgender: this.state.FatherGender,
                    dependentrelation: "father",
                  },
                  "AddDependent"
                ).then((dependantsresponse) =>
                  dependantsresponse.json().then((dependantsobj) => { })
                );
              }
              if (
                this.state.MotherDependentName != "" &&
                this.state.MotherDependentAge != 0 &&
                this.state.MotherGender != 0
              ) {
                PostApiCall.postRequest(
                  {
                    dependentid: this.state.MotherDependentId,
                    dependentname: this.state.MotherDependentName,
                    employeeid: JSON.parse(localStorage.getItem("LoginDetail"))
                      .fld_employee_id,
                    dependentage: this.state.MotherDependentAge,
                    dependantgender: this.state.MotherGender,
                    dependentrelation: "mother",
                  },
                  "AddDependent"
                ).then((dependantsresponse) =>
                  dependantsresponse.json().then((dependantsobj) => { })
                );
              }
              if (
                this.state.SpouseDependentName != "" &&
                this.state.SpouseDependentAge != 0 &&
                this.state.SpouseGender != 0
              ) {
                PostApiCall.postRequest(
                  {
                    dependentid: this.state.SpouseDependentId,
                    dependentname: this.state.SpouseDependentName,
                    employeeid: JSON.parse(localStorage.getItem("LoginDetail"))
                      .fld_employee_id,
                    dependentage: this.state.SpouseDependentAge,
                    dependantgender: this.state.SpouseGender,
                    dependentrelation: "spouse",
                  },
                  "AddDependent"
                ).then((dependantsresponse) =>
                  dependantsresponse.json().then((dependantsobj) => { })
                );
              }
              if (
                this.state.ChildDependentName != "" &&
                this.state.ChildDependentAge != 0 &&
                this.state.ChildGender != 0
              ) {
                PostApiCall.postRequest(
                  {
                    dependentid: this.state.ChildDependentId,
                    dependentname: this.state.ChildDependentName,
                    employeeid: JSON.parse(localStorage.getItem("LoginDetail"))
                      .fld_employee_id,
                    dependentage: this.state.ChildDependentAge,
                    dependantgender: this.state.ChildGender,
                    dependentrelation: "child",
                  },
                  "AddDependent"
                ).then((dependantsresponse) =>
                  dependantsresponse.json().then((dependantsobj) => { })
                );
              }
              if (
                this.state.OtherDependentName != "" &&
                this.state.OtherDependentAge != 0 &&
                this.state.OtherGender != 0 &&
                this.state.OtherRelationName
              ) {
                PostApiCall.postRequest(
                  {
                    dependentid: this.state.OtherDependentId,
                    dependentname: this.state.OtherDependentName,
                    employeeid: JSON.parse(localStorage.getItem("LoginDetail"))
                      .fld_employee_id,
                    dependentage: this.state.OtherDependentAge,
                    dependantgender: this.state.OtherGender,
                    dependentrelation: this.state.OtherRelationName,
                  },
                  "AddDependent"
                ).then((dependantsresponse) =>
                  dependantsresponse.json().then((dependantsobj) => { })
                );
              }
              Notiflix.Loading.remove();
              Notiflix.Notify.success("Employee successfully added.");
            } else {
              Notiflix.Notify.failure("Error Occured");
            }
          })
        );
      }
    }
  };
  render() {
    var login = JSON.parse(localStorage.getItem("LoginDetail"));
    return (
      <>
        <AppBar position="static">
          <Tabs
            className="employee-form-tabs"
            value={this.state.tab}
            onChange={(event, newValue) => this.setState({ tab: newValue })}
            textColor="inherit"
            aria-label="scrollable force tabs example"
          >
            <Tab label="Personal Details" />
            <Tab label="Education" />
            <Tab label="Professional" />
            <Tab label="Dependants" />
            <Tab label="Emergency Contact Details" />
          </Tabs>
        </AppBar>
        <SwipeableViews
          index={this.state.tab}
          onChangeIndex={(index) => this.setState({ tab: index })}>

          <TabPanel value={this.state.tab} index={0}>
            <div id="personal-details">
              <div className="form-head ">
                <div
                  style={{
                    borderLeft: "10px solid rgb(32, 101, 209)",
                    paddingLeft: "10px",
                  }}
                >
                  <h4>Personal Information</h4>
                </div>
              </div>
              <div className="card-vahani p-0 pb-4 ">
                <p className="personal-info left-line-card px-lg-4 px-3 py-4 d-flex align-items-center justify-content-between">
                  <div className="col-md-6 text-start col-12 mb-3">
                    Basic Information
                  </div>
                  <div className="col-md-6 text-end col-12 mb-3 ms-auto">
                    {login.fld_admin == "Yes" && (
                      <div className="row justify-content-end">
                        <div className="col-lg-6 col-lg-6 mb-lg-0 mb-2">
                          <Button variant="contained" className="w-100">
                            <Link to="/employee_list" className="text-white">
                              Show All Employee Records
                            </Link>
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3">
                      <div class="row">
                        <div class="col-md-3 ps-0 pe-0">
                          <div class="div1">
                            <div className="img-file-form1">
                              <ImgUpload
                                src={this.state.imagePreviewUrl}
                                onChange={this.photoUpload}
                                enable
                              />
                            </div>
                            <div className="upload-info-personal">
                              Upload Profile Pic *
                              <small>(Maximum Size 2MB)</small> <br />
                              File Format *.jpeg, *.jpg, *.png
                            </div>
                          </div>
                        </div>
                        <div class="col-md-9 pe-4">
                          <div class="row">
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="First Name"
                                required={true}
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpFirstName}
                                readonly={true}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Middle Name"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpMiddleName}
                                readonly={true}
                              />
                            </div>

                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Last Name"
                                required={true}
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpLastname}
                                readonly={true}
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <Box>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Blood Group*
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Blood Group*"
                                    value={this.state.EmpBloodgroup}
                                    onChange={(e) => {
                                      this.setState({
                                        EmpBloodgroup: e.target.value,
                                      });
                                    }}
                                  >
                                    <MenuItem value="A+">A+</MenuItem>
                                    <MenuItem value="B+">B+</MenuItem>
                                    <MenuItem value="A-">A-</MenuItem>
                                    <MenuItem value="B-">B-</MenuItem>
                                    <MenuItem value="O+">O+</MenuItem>
                                    <MenuItem value="O-">O-</MenuItem>
                                    <MenuItem value="AB+">AB+</MenuItem>
                                    <MenuItem value="AB-">AB-</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="date"
                                required={true}
                                helperText={" Date of Birth"}
                                className="text-field capitalonly"
                                value={this.state.EmpDob}
                                onChange={(e) => {
                                  this.setState({
                                    EmpDob: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <Box>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Gender*
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Gender*"
                                    value={this.state.EmpGender}
                                    onChange={(e) => {
                                      this.setState({
                                        EmpGender: e.target.value,
                                      });
                                    }}
                                  >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <Box>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Marital Status*
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Marital Status*"
                                    value={this.state.EmpMarritalstatus}
                                    onChange={(e) => {
                                      this.setState({
                                        EmpMarritalstatus: e.target.value,
                                      });
                                    }}
                                  >
                                    <MenuItem value="Single">Single</MenuItem>
                                    <MenuItem value="Married">Married</MenuItem>
                                    <MenuItem value="Divorced">
                                      Divorced
                                    </MenuItem>
                                    <MenuItem value="Widow">Widow</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                    </div>{" "}
                    {/* end col-md-12 */}
                  </div>
                </div>
              </div>

              <div className="form-head pt-4">
                <div
                  style={{
                    borderLeft: "10px solid rgb(32, 101, 209)",
                    paddingLeft: "10px",
                    paddingTop: "10px",
                  }}
                >
                  <h4>Address</h4>
                </div>
              </div>
              <div className="card-vahani p-0 pb-4 ">
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Employee Communication Address
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-12 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Address"
                                required={true}
                                multiline
                                rows={10}
                                className="text-field capitalonly address-textarea"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpCommunicationAdd}
                                onChange={(e) => {
                                  this.setState({
                                    EmpCommunicationAdd: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-12 mb-lg-3 mobile-personal">
                              <div className="col-lg-12 mb-lg-3 mobile-personal">
                                <Box>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Country*
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Country"
                                      required={true}
                                      value={this.state.EmpCommunicationCountry}
                                      onChange={(e) => {
                                        this.setState({
                                          EmpCommunicationCountry:
                                            e.target.value,
                                        });
                                        this.getEmployeeState(
                                          e.target.value,
                                          "communication"
                                        );
                                      }}
                                    >
                                      {this.state.EmployeeCountry.map(
                                        (country, i) => {
                                          return (
                                            <MenuItem
                                              value={`${country.value}`}
                                            >
                                              {country.label}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </div>
                              <div className="col-lg-12 mb-lg-3 mobile-personal">
                                <Box>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      State*
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={this.state.EmpCommunicationState}
                                      required={true}
                                      label="State"
                                      onChange={(e) => {
                                        this.setState({
                                          EmpCommunicationState: e.target.value,
                                        });
                                        this.getEmployeeCity(
                                          e.target.value,
                                          "communication"
                                        );
                                      }}
                                    >
                                      {this.state.EmployeeCommunicationState.map(
                                        (state, i) => {
                                          return (
                                            <MenuItem value={`${state.value}`}>
                                              {state.label}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </div>
                              <div className="col-lg-12 mb-lg-3 mobile-personal">
                                <Box>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      City*
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      required={true}
                                      label="City"
                                      value={this.state.EmpCommunicationCity}
                                      onChange={(e) => {
                                        this.setState({
                                          EmpCommunicationCity: e.target.value,
                                        });
                                      }}
                                    >
                                      {this.state.EmployeeCommunicationCity.map(
                                        (city, i) => {
                                          return (
                                            <MenuItem value={city.value}>
                                              {city.label}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </div>
                              <div className="col-lg-12 mb-lg-3 mobile-personal">
                                <Box>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    required={true}
                                    label="Pincode"
                                    className="text-field capitalonly"
                                    InputProps={{ inputProps: { min: 1 } }}
                                    value={this.state.EmpCommunicationPincode}
                                    onChange={(e) => {
                                      this.setState({
                                        EmpCommunicationPincode: e.target.value,
                                      });
                                    }}
                                  />
                                </Box>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    {/* end col-md-12 */}
                  </div>
                </div>
              </div>

              <div className="card-vahani p-0 pb-4 mt-4">
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Employee Present Address
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-12 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Address"
                                required={true}
                                multiline
                                rows={10}
                                className="text-field capitalonly address-textarea"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpPresentAdd}
                                onChange={(e) => {
                                  this.setState({
                                    EmpPresentAdd: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-12 mb-lg-3 mobile-personal">
                              <div className="col-lg-12 mb-lg-3 mobile-personal">
                                <Box>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Country*
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Country"
                                      value={this.state.EmpPresentCountry}
                                      required={true}
                                      onChange={(e) => {
                                        this.setState({
                                          EmpPresentCountry: e.target.value,
                                        });
                                        this.getEmployeeState(
                                          e.target.value,
                                          "present"
                                        );
                                      }}
                                    >
                                      {this.state.EmployeeCountry.map(
                                        (country, i) => {
                                          return (
                                            <MenuItem
                                              value={`${country.value}`}
                                            >
                                              {country.label}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </div>
                              <div className="col-lg-12 mb-lg-3 mobile-personal">
                                <Box>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      State*
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      required={true}
                                      label="State"
                                      value={this.state.EmpPresentState}
                                      onChange={(e) => {
                                        this.setState({
                                          EmpPresentState: e.target.value,
                                        });
                                        this.getEmployeeCity(
                                          e.target.value,
                                          "present"
                                        );
                                      }}
                                    >
                                      {this.state.EmployeePresentState.map(
                                        (state, i) => {
                                          return (
                                            <MenuItem value={`${state.value}`}>
                                              {state.label}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </div>
                              <div className="col-lg-12 mb-lg-3 mobile-personal">
                                <Box>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      City*
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      required={true}
                                      value={this.state.EmpPresentCity}
                                      label="City"
                                      onChange={(e) => {
                                        this.setState({
                                          EmpPresentCity: e.target.value,
                                        });
                                      }}
                                    >
                                      {this.state.EmployeePresentCity.map(
                                        (city, i) => {
                                          return (
                                            <MenuItem value={city.value}>
                                              {city.label}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </div>
                              <div className="col-lg-12 mb-lg-3 mobile-personal">
                                <Box>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    required={true}
                                    label="Pincode"
                                    className="text-field capitalonly"
                                    InputProps={{ inputProps: { min: 1 } }}
                                    value={this.state.EmpPresentPincode}
                                    onChange={(e) => {
                                      this.setState({
                                        EmpPresentPincode: e.target.value,
                                      });
                                    }}
                                  />
                                </Box>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="lab"
                          checked={this.state.SameAsPresent}
                          id="flexCheckChecked"
                          onChange={() => {
                            this.SameAsPresent();
                          }}
                        />
                        <label class="form-check-label" for="flexCheckChecked">
                          Permanent Address is same as present address
                        </label>
                      </div>
                    </div>{" "}
                    {/* end col-md-12 */}
                  </div>
                </div>
              </div>

              <div
                className="card-vahani p-0 pb-4 mt-4"
                style={{
                  display: this.state.SameAsPresent == true ? "none" : "block",
                }}
              >
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Employee Permanent Address
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-12 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Address"
                                required={true}
                                multiline
                                rows={10}
                                className="text-field capitalonly address-textarea"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpPermanentAdd}
                                onChange={(e) => {
                                  this.setState({
                                    EmpPermanentAdd: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-12 mb-lg-3 mobile-personal">
                              <div className="col-lg-12 mb-lg-3 mobile-personal">
                                <Box>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Country*
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Country"
                                      value={this.state.EmpPermanentCountry}
                                      required={true}
                                      onChange={(e) => {
                                        this.setState({
                                          EmpPermanentCountry: e.target.value,
                                        });
                                        this.getEmployeeState(
                                          e.target.value,
                                          "permanent"
                                        );
                                      }}
                                    >
                                      {this.state.EmployeeCountry.map(
                                        (country, i) => {
                                          return (
                                            <MenuItem
                                              value={`${country.value}`}
                                            >
                                              {country.label}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </div>
                              <div className="col-lg-12 mb-lg-3 mobile-personal">
                                <Box>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      State*
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="State"
                                      value={this.state.EmpPermanentState}
                                      required={true}
                                      onChange={(e) => {
                                        this.setState({
                                          EmpPermanentState: e.target.value,
                                        });
                                        this.getEmployeeCity(
                                          e.target.value,
                                          "permanent"
                                        );
                                      }}
                                    >
                                      {this.state.EmployeePermanentState.map(
                                        (state, i) => {
                                          return (
                                            <MenuItem value={state.value}>
                                              {state.label}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </div>
                              <div className="col-lg-12 mb-lg-3 mobile-personal">
                                <Box>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      City*
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="City"
                                      value={this.state.EmpPermanentCity}
                                      required={true}
                                      onChange={(e) => {
                                        this.setState({
                                          EmpPermanentCity: e.target.value,
                                        });
                                      }}
                                    >
                                      {this.state.EmployeePermanentCity.map(
                                        (city, i) => {
                                          return (
                                            <MenuItem value={city.value}>
                                              {city.label}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </div>
                              <div className="col-lg-12 mb-lg-3 mobile-personal">
                                <Box>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    label="Pincode*"
                                    required={true}
                                    className="text-field capitalonly"
                                    InputProps={{ inputProps: { min: 1 } }}
                                    value={this.state.EmpPermanentPincode}
                                    onChange={(e) => {
                                      this.setState({
                                        EmpPermanentPincode: e.target.value,
                                      });
                                    }}
                                  />
                                </Box>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    {/* end col-md-12 */}
                  </div>
                </div>
              </div>

              <div className="form-head pt-4">
                <div
                  style={{
                    borderLeft: "10px solid rgb(32, 101, 209)",
                    paddingLeft: "10px",
                    paddingTop: "10px",
                  }}
                >
                  <h4>Contact Details</h4>
                </div>
              </div>
              <div className="card-vahani p-0 pb-4">
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Employee Contact Details
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Mobile Number 1"
                                required={true}
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpPhone1}
                                readonly={true}
                              />
                            </div>
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Mobile Number 2"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpPhone2}
                                onChange={(e) => {
                                  this.setState({
                                    EmpPhone2: e.target.value,
                                  });
                                }}
                              />
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div className="form-head pt-4">
                <div
                  style={{
                    borderLeft: "10px solid rgb(32, 101, 209)",
                    paddingLeft: "10px",
                    paddingTop: "10px",
                  }}
                >
                  <h4>Identification Details</h4>
                </div>
              </div>
              <div className="card-vahani p-0 pb-4">
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Employee Identification Details
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Aadhar Card Number"
                                required={true}
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpAadharcard}
                                onChange={(e) => {
                                  this.setState({
                                    EmpAadharcard: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Pan Card Number"
                                required={true}
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpPancard}
                                onChange={(e) => {
                                  this.setState({
                                    EmpPancard: e.target.value,
                                  });
                                }}
                              />
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div className="form-head pt-4">
                <div
                  style={{
                    borderLeft: "10px solid rgb(32, 101, 209)",
                    paddingLeft: "10px",
                    paddingTop: "10px",
                  }}
                >
                  <h4>Bank Details</h4>
                </div>
              </div>
            </div>
            <div className="card-vahani p-0 pb-4">
              <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                Employee Bank Details
              </p>
              <div class="toast-body">
                <div class="row">
                  <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row ps-2 pe-3">
                          <div className="col-lg-6 mb-lg-3 mobile-personal">
                            <TextField
                              fullWidth
                              type="text"
                              required={true}
                              label="Bank Account Number"
                              className="text-field capitalonly"
                              InputProps={{ inputProps: { min: 1 } }}
                              value={this.state.BankAccNo}
                              onChange={(e) => {
                                this.setState({
                                  BankAccNo: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6 mb-lg-3 mobile-personal">
                            <TextField
                              fullWidth
                              type="text"
                              required={true}
                              label="Account Holder Name"
                              className="text-field capitalonly"
                              InputProps={{ inputProps: { min: 1 } }}
                              value={this.state.AccHolderName}
                              onChange={(e) => {
                                this.setState({
                                  AccHolderName: e.target.value,
                                });
                              }}
                            />
                          </div>{" "}
                        </div>
                        <div class="row ps-2 pe-3">
                          <div className="col-lg-6 mb-lg-3 mobile-personal">
                            <TextField
                              fullWidth
                              type="text"
                              required={true}
                              label="IFSC Code"
                              className="text-field capitalonly"
                              InputProps={{ inputProps: { min: 1 } }}
                              value={this.state.IFSCCode}
                              onChange={(e) => {
                                this.setState({
                                  IFSCCode: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6 mb-lg-3 mobile-personal">
                            <TextField
                              fullWidth
                              type="text"
                              required={true}
                              label="Branch Name"
                              className="text-field capitalonly"
                              InputProps={{ inputProps: { min: 1 } }}
                              value={this.state.BranchName}
                              onChange={(e) => {
                                this.setState({
                                  BranchName: e.target.value,
                                });
                              }}
                            />
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={this.state.tab} index={1}>
            <div>
              <div className="form-head ">
                <div
                  style={{
                    borderLeft: "10px solid rgb(32, 101, 209)",
                    paddingLeft: "10px",
                  }}
                >
                  <h4>Educational Information</h4>
                  {/* <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="text.primary" href="/dashboard" aria-current="page" >  Dashboard  </Link>
              </Breadcrumbs> */}
                </div>
              </div>
              <div className="card-vahani p-0 pb-4 ">
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Education
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="10th"
                                disabled
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.SchoolEducationTenth}
                              />
                            </div>
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                className="text-field"
                                type="date"
                                helperText={"Year of Passing"}
                                value={this.state.YearOfPassTenth}
                                onChange={(e) => {
                                  this.setState({
                                    YearOfPassTenth: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="12th"
                                disabled
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.SchoolEducationTwelth}
                              />
                            </div>
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                className="text-field"
                                type="date"
                                helperText={"Year of Passing"}
                                value={this.state.YearOfPassTwelth}
                                onChange={(e) => {
                                  this.setState({
                                    YearOfPassTwelth: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                id="standard-select-currency"
                                name="mySelectBox"
                                required={true}
                                select
                                label="Graduation"
                                value={this.state.GraduationName}
                                onChange={(e) => {
                                  this.setState({
                                    GraduationName: e.target.value,
                                  });
                                }}
                              >
                                {this.state.CourseData.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                            <div
                              className="col-lg-6 mb-lg-3 mobile-personal"
                              style={{
                                display:
                                  this.state.GraduationName ==
                                    "Others, Please specify"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <TextField
                                fullWidth
                                type="text"
                                required={true}
                                label="Please Specify the Other Graduation*"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.PleaseSpecify}
                                onChange={(e) => {
                                  this.setState({
                                    PleaseSpecify: e.target.value,
                                  });
                                }}
                              />
                            </div>{" "}
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="date"
                                helperText={"Year of Passing"}
                                value={moment(
                                  this.state.GraduationPassingYear
                                ).format("YYYY-MM-DD")}
                                onChange={(e) => {
                                  this.setState({
                                    GraduationPassingYear: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div
                              role="alert"
                              aria-live="assertive"
                              aria-atomic="true"
                              data-toggle="toast"
                              className="mb-3"
                            >
                              <div
                                className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3"
                                style={{ float: "right" }}
                              >
                                <div
                                  className=" justify-content-end"
                                  style={{ display: "flex" }}
                                >
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      this.GraduationhandleSave();
                                    }}
                                  >
                                    Save
                                  </Button>
                                </div>
                              </div>
                            </div>
                            {this.state.AllGradutaionList.map(
                              (graduation, i) => {
                                return (
                                  <>
                                    <div className="col-lg-6 mb-lg-3 mobile-personal">
                                      <TextField
                                        fullWidth
                                        required={true}
                                        type="text"
                                        label="Graduation"
                                        value={
                                          graduation.fld_graduation_name.split(
                                            "-"
                                          )[0]
                                        }
                                      />
                                    </div>
                                    <div className="col-lg-5 mb-lg-3 mobile-personal">
                                      <TextField
                                        fullWidth
                                        type="text"
                                        label="Passing Year"
                                        value={moment(
                                          graduation.fld_graduation_passing_year
                                        ).format("YYYY-MM-DD")}
                                      />
                                    </div>
                                    <div className="col-lg-1 mb-lg-3 mobile-personal">
                                      <Button
                                        variant="contained"
                                        onClick={() => {
                                          if (
                                            graduation.fld_graduation_name.split(
                                              "-"
                                            )[1] == "Others, Please specify"
                                          ) {
                                            this.setState({
                                              GraduationName:
                                                "Others, Please specify",
                                              PleaseSpecify:
                                                graduation.fld_graduation_name.split(
                                                  "-"
                                                )[0],
                                              GraduationPassingYear:
                                                graduation.fld_graduation_passing_year,
                                            });
                                          } else {
                                            this.setState({
                                              GradutaionId: graduation.fld_id,
                                              GraduationName:
                                                graduation.fld_graduation_name,
                                              GraduationPassingYear:
                                                graduation.fld_graduation_passing_year,
                                            });
                                          }
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div className="form-head pt-4">
                <div
                  style={{
                    borderLeft: "10px solid rgb(32, 101, 209)",
                    paddingLeft: "10px",
                  }}
                >
                  <h4>Additional Certificate</h4>
                </div>
              </div>
              <div className="card-vahani p-0 pb-4 ">
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Certificate
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Name of Certificate*"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                onChange={(e) => {
                                  this.setState({
                                    EmpCertificateName: e.target.value,
                                  });
                                }}
                                value={this.state.EmpCertificateName}
                              />
                            </div>
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Upload Certificate(Maximum Size 6Mb | File Format .pdf .jpeg)"
                                variant="outlined"
                                className="text-field"
                                type="File"
                                onChange={(e) => {
                                  e.preventDefault();
                                  if (e.target.files[0].size < 6000000) {
                                    const reader = new FileReader();
                                    const file = e.target.files[0];
                                    reader.onloadend = () => {
                                      this.setState({
                                        CertificateFile: file,
                                      });
                                    };
                                    reader.readAsDataURL(file);
                                  } else {
                                    Notiflix.Notify.failure(
                                      "File too large, upload file less than 6 MB."
                                    );
                                  }
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  accept: "application/pdf, image/jpeg",
                                }}
                              />
                            </div>
                            <div
                              role="alert"
                              aria-live="assertive"
                              aria-atomic="true"
                              data-toggle="toast"
                            >
                              <div className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3">
                                <div
                                  className=" justify-content-end"
                                  style={{ display: "flex" }}
                                >
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      this.CertificatehandleSave();
                                    }}
                                  >
                                    Add Certificate
                                  </Button>
                                </div>
                              </div>
                            </div>
                            {this.state.AllCertificateList.map(
                              (certificate, i) => {
                                return (
                                  <>
                                    <div className="col-lg-10 mb-lg-3 mobile-personal">
                                      <TextField
                                        fullWidth
                                        type="text"
                                        label="Name of Certificate*"
                                        className="text-field capitalonly"
                                        InputProps={{ inputProps: { min: 1 } }}
                                        value={certificate.fld_certificate_name}
                                      />
                                    </div>

                                    <div className="col-lg-1 mb-lg-3 mobile-personal">
                                      <Button
                                        variant="contained"
                                        onClick={() => {
                                          this.setState({
                                            EmpCertificateName:
                                              certificate.fld_certificate_name,
                                            CertificateId: certificate.fld_id,
                                          });
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </div>

                                    {certificate.fld_certificate_file != "" &&
                                      certificate.fld_certificate_file != null ? (
                                      <div className="col-lg-1 mb-lg-3 mobile-personal">
                                        <Button variant="contained">
                                          <a
                                            class="text-decoration-none text-reset"
                                            variant="contained"
                                            href={
                                              certificate.fld_certificate_file
                                            }
                                            target="_blank"
                                          >
                                            View
                                          </a>
                                        </Button>
                                      </div>
                                    ) : null}
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={this.state.tab} index={2}>
            <div>
              <div className="form-head ">
                <div
                  style={{
                    borderLeft: "10px solid rgb(32, 101, 209)",
                    paddingLeft: "10px",
                  }}
                >
                  <h4>Professional Information</h4>
                  {/* <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="text.primary" href="/dashboard" aria-current="page" >  Dashboard  </Link>
              </Breadcrumbs> */}
                </div>
              </div>
              <div className="card-vahani p-0 pb-4 mt-4">
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Employee Details
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                className="text-field"
                                type="date"
                                required={true}
                                helperText={" Date of Joining*"}
                                value={this.state.EmpJoiningdate}
                                readonly={true}
                              />
                            </div>
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Designation"
                                required={true}
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpCurrentposition}
                                readonly={true}
                              />
                            </div>
                            <div className="col-lg-12 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Department"
                                required={true}
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpDepartment}
                                onChange={(e) => {
                                  this.setState({
                                    EmpDepartment: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div className="form-head pt-4">
                <div
                  style={{
                    borderLeft: "10px solid rgb(32, 101, 209)",
                    paddingLeft: "10px",
                  }}
                >
                  <h4>Salary Details</h4>
                </div>
              </div>
              <div className="card-vahani p-0 pb-4">
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Salary Details
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Monthly Salary"
                                required={true}
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpMonthlysalary}
                                readonly={true}
                              />
                            </div>
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                required={true}
                                label=" Annual Salary"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpAnualsalary}
                                readonly={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div className="form-head pt-4">
                <div
                  style={{
                    borderLeft: "10px solid rgb(32, 101, 209)",
                    paddingLeft: "10px",
                  }}
                >
                  <h4>Upload Documents</h4>
                </div>
              </div>
              <div className="card-vahani p-0 pb-4">
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Upload Appointment Letter and Contract
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Name of Appointment Letter*"
                                className="text-field capitalonly"
                                value={this.state.AppointmentLetterName}
                                InputProps={{ inputProps: { min: 1 } }}
                                onChange={(e) => {
                                  this.setState({
                                    AppointmentLetterName: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Upload Appointment Letter(Maximum Size 6Mb | File Format .pdf .jpeg)"
                                variant="outlined"
                                className="text-field"
                                type="File"
                                onChange={(e) => {
                                  e.preventDefault();
                                  if (e.target.files[0].size < 6000000) {
                                    const reader = new FileReader();
                                    const file = e.target.files[0];
                                    reader.onloadend = () => {
                                      this.setState({
                                        AppointmentLetterFile: file,
                                        AppointmentLetterUploaded: true,
                                      });
                                    };
                                    reader.readAsDataURL(file);
                                  } else {
                                    Notiflix.Notify.failure(
                                      "File too large, upload file less than 6 MB."
                                    );
                                  }
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  accept: "application/pdf, image/jpeg",
                                }}
                              />
                            </div>
                            {this.state.AppointmentLetterPath != "" &&
                              this.state.AppointmentLetterPath != null ? (
                              <div className="col-lg-2 mb-lg-3 mobile-personal">
                                <div className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3">
                                  <div
                                    className=" justify-content-end"
                                    style={{ display: "flex" }}
                                  >
                                    <Button variant="contained">
                                      <a
                                        class="text-decoration-none text-reset"
                                        variant="contained"
                                        href={this.state.AppointmentLetterPath}
                                        target="_blank"
                                      >
                                        View
                                      </a>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-lg-6 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Name of Contract*"
                                className="text-field capitalonly"
                                value={this.state.ContractName}
                                InputProps={{ inputProps: { min: 1 } }}
                                onChange={(e) => {
                                  this.setState({
                                    ContractName: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Upload Contract(Maximum Size 6Mb | File Format .pdf .jpeg)"
                                variant="outlined"
                                className="text-field"
                                type="File"
                                onChange={(e) => {
                                  e.preventDefault();
                                  if (e.target.files[0].size < 6000000) {
                                    const reader = new FileReader();
                                    const file = e.target.files[0];
                                    reader.onloadend = () => {
                                      this.setState({
                                        ContractFile: file,
                                        ContractUploaded: true,
                                      });
                                    };
                                    reader.readAsDataURL(file);
                                  } else {
                                    Notiflix.Notify.failure(
                                      "File too large, upload file less than 6 MB."
                                    );
                                  }
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  accept: "application/pdf, image/jpeg",
                                }}
                              />
                            </div>
                            {this.state.ContractPath != "" &&
                              this.state.ContractPath != null ? (
                              <div className="col-lg-2 mb-lg-3 mobile-personal">
                                <div className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3">
                                  <div
                                    className=" justify-content-end"
                                    style={{ display: "flex" }}
                                  >
                                    <Button variant="contained">
                                      <a
                                        class="text-decoration-none text-reset"
                                        variant="contained"
                                        href={this.state.ContractPath}
                                        target="_blank"
                                      >
                                        View
                                      </a>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={this.state.tab} index={3}>
            <div>
              <div className="form-head ">
                <div
                  style={{
                    borderLeft: "10px solid rgb(32, 101, 209)",
                    paddingLeft: "10px",
                  }}
                >
                  <h4>Dependants Details</h4>
                </div>
              </div>
              <div className="card-vahani p-0 pb-4 mt-4">
                {/* <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  
                </p> */}
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12 pt-4">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-12 mb-lg-3 mobile-personal">
                              <Box>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Dependants*
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.SelectedDependant}
                                    label="Dependants*"
                                    onChange={(e) => {
                                      this.setState({
                                        SelectedDependant: e.target.value,
                                      });
                                    }}
                                  >
                                    <MenuItem value="Father">Father</MenuItem>
                                    <MenuItem value="Mother">Mother</MenuItem>
                                    <MenuItem value="Spouse">Spouse</MenuItem>
                                    <MenuItem value="Children">
                                      Children
                                    </MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div
                className="card-vahani p-0 pb-4 mt-4"
                style={{
                  display:
                    this.state.SelectedDependant == "" ||
                      this.state.SelectedDependant != "Father"
                      ? "none"
                      : "block",
                }}
              >
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Father Information
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Name"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.FatherDependentName}
                                onChange={(e) => {
                                  this.setState({
                                    FatherDependentName: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Age"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.FatherDependentAge}
                                onChange={(e) => {
                                  this.setState({
                                    FatherDependentAge: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <Box>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Gender*
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.FatherGender}
                                    label="Gender*"
                                    onChange={(e) => {
                                      this.setState({
                                        FatherGender: e.target.value,
                                      });
                                    }}
                                  >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div
                className="card-vahani p-0 pb-4 mt-4"
                style={{
                  display:
                    this.state.SelectedDependant == "" ||
                      this.state.SelectedDependant != "Mother"
                      ? "none"
                      : "block",
                }}
              >
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Mother Information
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Name"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.MotherDependentName}
                                onChange={(e) => {
                                  this.setState({
                                    MotherDependentName: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Age"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.MotherDependentAge}
                                onChange={(e) => {
                                  this.setState({
                                    MotherDependentAge: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <Box>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Gender*
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Gender*"
                                    value={this.state.MotherGender}
                                    onChange={(e) => {
                                      this.setState({
                                        MotherGender: e.target.value,
                                      });
                                    }}
                                  >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div
                className="card-vahani p-0 pb-4 mt-4"
                style={{
                  display:
                    this.state.SelectedDependant == "" ||
                      this.state.SelectedDependant != "Spouse"
                      ? "none"
                      : "block",
                }}
              >
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Spouse Information
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Name"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.SpouseDependentName}
                                onChange={(e) => {
                                  this.setState({
                                    SpouseDependentName: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Age"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.SpouseDependentAge}
                                onChange={(e) => {
                                  this.setState({
                                    SpouseDependentAge: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <Box>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Gender*
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Gender*"
                                    value={this.state.SpouseGender}
                                    onChange={(e) => {
                                      this.setState({
                                        SpouseGender: e.target.value,
                                      });
                                    }}
                                  >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div
                className="card-vahani p-0 pb-4 mt-4"
                style={{
                  display:
                    this.state.SelectedDependant == "" ||
                      this.state.SelectedDependant != "Children"
                      ? "none"
                      : "block",
                }}
              >
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Children Information
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Name"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.ChildDependentName}
                                onChange={(e) => {
                                  this.setState({
                                    ChildDependentName: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Age"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.ChildDependentAge}
                                onChange={(e) => {
                                  this.setState({
                                    ChildDependentAge: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <Box>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Gender*
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Gender*"
                                    value={this.state.ChildGender}
                                    onChange={(e) => {
                                      this.setState({
                                        ChildGender: e.target.value,
                                      });
                                    }}
                                  >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div
                className="card-vahani p-0 pb-4 mt-4"
                style={{
                  display:
                    this.state.SelectedDependant == "" ||
                      this.state.SelectedDependant != "Others"
                      ? "none"
                      : "block",
                }}
              >
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Others Relation Information
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-3 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Name"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.OtherDependentName}
                                onChange={(e) => {
                                  this.setState({
                                    OtherDependentName: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-3 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Relation With Person"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.OtherRelationName}
                                onChange={(e) => {
                                  this.setState({
                                    OtherRelationName: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-3 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Age"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.OtherDependentAge}
                                onChange={(e) => {
                                  this.setState({
                                    OtherDependentAge: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-3 mb-lg-3 mobile-personal">
                              <Box>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Gender*
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Gender*"
                                    value={this.state.OtherGender}
                                    onChange={(e) => {
                                      this.setState({
                                        OtherGender: e.target.value,
                                      });
                                    }}
                                  >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={this.state.tab} index={4}>
            <div>
              <div className="form-head ">
                <div
                  style={{
                    borderLeft: "10px solid rgb(32, 101, 209)",
                    paddingLeft: "10px",
                  }}
                >
                  <h4>Emergency Contacts</h4>
                </div>
              </div>
              <div className="card-vahani p-0 pb-4 mt-4">
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Emergency Contact 1
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Emergency Contact Name"
                                required={true}
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpEmFirstContactName}
                                onChange={(e) => {
                                  this.setState({
                                    EmpEmFirstContactName: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                label="Emergency Contact Mobile Number"
                                required={true}
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpEmFirstContactNumber}
                                onChange={(e) => {
                                  this.setState({
                                    EmpEmFirstContactNumber: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                required={true}
                                label="Relationship With Contact Person"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpEmFirstContactRelation}
                                onChange={(e) => {
                                  this.setState({
                                    EmpEmFirstContactRelation: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div className="card-vahani p-0 pb-4 mt-4">
                <p className="personal-info left-line-card px-lg-4 px-3 py-4">
                  Emergency Contact 2
                </p>
                <div class="toast-body">
                  <div class="row">
                    <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row ps-2 pe-3">
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                required={true}
                                label="Emergency Contact Name"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpEmSecondContactName}
                                onChange={(e) => {
                                  this.setState({
                                    EmpEmSecondContactName: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                required={true}
                                label="Emergency Contact Mobile Number"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpEmSecondContactNumber}
                                onChange={(e) => {
                                  this.setState({
                                    EmpEmSecondContactNumber: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-lg-3 mobile-personal">
                              <TextField
                                fullWidth
                                type="text"
                                required={true}
                                label="Relationship With Contact Person"
                                className="text-field capitalonly"
                                InputProps={{ inputProps: { min: 1 } }}
                                value={this.state.EmpEmSecondContactRelation}
                                onChange={(e) => {
                                  this.setState({
                                    EmpEmSecondContactRelation: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                data-toggle="toast"
              >
                <div
                  className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3"
                  style={{ float: "right" }}
                >
                  <div
                    className=" justify-content-end"
                    style={{ display: "flex" }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        let allrequiredfeild = [
                          this.state.EmpFirstName,
                          this.state.EmpLastname,
                          this.state.EmpEmail,
                          this.state.EmpDob,
                          this.state.EmpCommunicationAdd,
                          this.state.EmpCommunicationCountry,
                          this.state.EmpCommunicationState,
                          this.state.EmpCommunicationCity,
                          this.state.EmpCommunicationPincode,
                          this.state.EmpPresentAdd,
                          this.state.EmpPresentCountry,
                          this.state.EmpPresentState,
                          this.state.EmpPresentCity,
                          this.state.EmpPresentPincode,
                          this.state.EmpPermanentAdd,
                          this.state.EmpPermanentCountry,
                          this.state.EmpPermanentState,
                          this.state.EmpPermanentCity,
                          this.state.EmpPermanentPincode,
                          this.state.EmpPhone1,
                          this.state.EmpPhone2,
                          this.state.EmpAadharcard,
                          this.state.EmpPancard,
                          this.state.BankAccNo,
                          this.state.AccHolderName,
                          this.state.IFSCCode,
                          this.state.BranchName,
                          this.state.YearOfPassTenth,
                          this.state.YearOfPassTwelth,
                          this.state.EmpJoiningdate,
                          this.state.EmpCurrentposition,
                          this.state.EmpMonthlysalary,
                          this.state.EmpAnualsalary,
                          this.state.EmpEmFirstContactName,
                          this.state.EmpEmFirstContactNumber,
                          this.state.EmpEmFirstContactRelation,
                          this.state.EmpEmSecondContactName,
                          this.state.EmpEmSecondContactNumber,
                          this.state.EmpEmSecondContactRelation,
                        ];
                        allrequiredfeild.push();
                        this.onSaveEmployee(allrequiredfeild);
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

        </SwipeableViews>
      </>
    );
  }
}
export default Profile;
