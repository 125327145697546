import React, { Component, useEffect, useState, useContext } from "react";
import { Edit3 } from "react-feather";
import Notiflix from "notiflix";
import { CiFileOff } from "react-icons/ci";
import { Button } from "@mui/material";
import { FaEdit } from "react-icons/fa";

import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// import Drawer from "@mui/material/Drawer";
import PostApiCall from "../../../assets/API/PostApi";
import store from "../../../store";
import AddContactDetails from "../Vendor/AddVendorContactDetails";
import ContactDetailsList from "../Vendor/VendorContactList";

import { Drawer } from "antd";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Col } from "reactstrap";
export default function VendorAddressList({ setShowVendorAddressList }) {
  // For drawer
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const showDefaultDrawer = () => {
    setSize("default");
    setOpen(true);
  };

  const showLargeDrawer = () => {
    setSize("large");
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [addAddressData, setAddAddressData] = useState([]);
  const {
    vendorGlobalId,
    setVendorGlobalId,
    addressId,
    setAddressId,
    address1,
    setAddress1,
    address2,
    setAddress2,
    address3,
    setAddress3,
    landmark,
    setLandmark,
    pincode,
    setPincode,
    default1,
    setDefault1,
    countryData,
    setCountryData,
    currCountry,
    setCurrCountry,
    stateData,
    setStateData,
    currState,
    setCurrState,
    cityData,
    setCityData,
    currCity,
    setCurrCity,
    contactId,
    setContactId,
    contactName,
    setContactName,
    designation,
    setDesignation,
    contactEmail,
    setContactEmail,
    contactMobile,
    setContactMobile,
  } = useContext(store);

  const [contactAddressId, setContactAddressId] = useState(null);

  useEffect(() => {
    Notiflix.Loading.init({
      svgColor: "rgb(6,10,74)",
    });
    Notiflix.Loading.dots("Please wait...");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    PostApiCall.postRequest(
      {
        whereClause: vendorGlobalId,
      },
      "GetVendorAddress"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setAddAddressData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Notify.failure("error occurred!");
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    setContactAddressId(id);
    setContactId(null);
    setContactName("");
    setContactMobile("");
    setContactEmail("");
    setDesignation("");
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Vendor Contact Info Details

  return (
    <>
      <div
        className={`card-vahani p-0 mt-2 ${
          addAddressData.length != 0 ? "vendor-card-address" : ""
        }`}
      >
        <p className="personal-info m-0 left-line-card p-4 d-flex align-items-center justify-content-between">
          Address List{" "}
          <div className="col-md-2 ps-0 pe-0">
            <Button
              variant="contained"
              className="w-100"
              onClick={() => {
                setAddressId(null);
                setAddress1("");
                setAddress2("");
                setAddress3("");
                setLandmark("");
                setCurrCountry("");
                setCurrState("");
                setCurrCity("");
                setPincode("");
                setDefault1(false);
                setShowVendorAddressList(false);
              }}
            >
              Add New Address
            </Button>
          </div>
        </p>
        {/* *** Carousel *** */}
        {/* mapping area start */}
        {addAddressData.length != 0 ? (
          <Slider {...settings} className="mb-3">
            {addAddressData?.map((address) => {
              return (
                <div className="mx-3 my-3">
                  <div class="card mx-2">
                    <div class="card-body">
                      <h5 class="card-title">
                        <strong>{address.fld_name}</strong>
                      </h5>
                      <p class="card-text mb-1 ">{address.fld_industry}</p>
                      <p class="card-text mb-1">
                        {address.fld_address1 != "" ? (
                          <>{address.fld_address1},</>
                        ) : null}
                        {address.fld_address2 != ""
                          ? (address.fld_address2, (<br />))
                          : null}
                        {address.fld_address3 != "" ? (
                          <>{address.fld_address3},</>
                        ) : null}
                        {address.fld_country_name}, {address.fld_state_name},{" "}
                        {address.fld_city_name}, {address.fld_pincode}
                      </p>
                      <p class="card-text mb-1">
                        <strong>Landmark</strong>
                      </p>
                      <p class="card-text mb-1">{address.fld_landmark}</p>
                    </div>

                    <div class="card-footer d-flex justify-content-between">
                      <div>
                        {["right"].map((anchor) => (
                          <React.Fragment key={anchor}>
                            <Button
                              className="bg-primary text-white"
                              onClick={() => {
                                showLargeDrawer();
                                setContactAddressId(address.fld_id);
                                setContactId(null);
                                setContactName("");
                                setContactMobile("");
                                setContactEmail("");
                                setDesignation("");
                              }}
                            >
                              Add Contact
                            </Button>
                          </React.Fragment>
                        ))}
                      </div>
                      <a
                        href="javascript:void(0)"
                        class="btn btn-primary float-end"
                        onClick={() => {
                          setAddressId(address.fld_id);
                          setAddress1(address.fld_address1);
                          setAddress2(address.fld_address2);
                          setAddress3(address.fld_address3);
                          setLandmark(address.fld_landmark);
                          setCurrCountry(address.fld_country);
                          setCurrState(address.fld_state);
                          setCurrCity(address.fld_city);
                          setPincode(address.fld_pincode);
                          setDefault1(address.fld_default);
                          setShowVendorAddressList(false);
                        }}
                      >
                        <FaEdit className="edit-icon edit-icon-font-size" />
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        ) : (
          <Col lg={12} className="justify-content-center d-flex">
            <div className="text-center w-25">
              <div className="justify-content-center no-data-icon mt-4">
                <AiOutlineFileSearch className="no-found-data-size" />
              </div>
              <h4>No data found</h4>
            </div>
          </Col>
        )}
        {/*  ***** Contact Address *******/}
        <Drawer
          className="vendor-contact-show-draw"
          placement="right"
          size={size}
          onClose={onClose}
          open={open}
        >
          <AddContactDetails contactAddressId={contactAddressId} />
          <div className="row">
            <div className="col-12 mt-5">
              <ContactDetailsList contactAddressId={contactAddressId} />
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
}
