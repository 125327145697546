import React from "react";
import { Route, Switch } from "react-router-dom";
import Attendance from "../pages/HRMS/AdminControls/Attendance Mangement/Attendance";
import Calender from "../pages/HRMS/AdminControls/Attendance Mangement/Calender";
import EditEmployeeLoginCredentials from "../pages/HRMS/AdminControls/Employee Mangement/EditEmployeeLoginCredentials";
import EditEmployeePersonalDetails from "../pages/HRMS/AdminControls/Employee Mangement/EditEmployeePersonalDetails";
import Employee from "../pages/HRMS/AdminControls/Employee Mangement/Employee";
import EmployeeList from "../pages/HRMS/AdminControls/Employee Mangement/EmployeeList";
import AddHoliday from "../pages/HRMS/AdminControls/Holiday Mangement/AddHoliday";
import ListHolidays from "../pages/HRMS/AdminControls/Holiday Mangement/ListHolidays";
import Update_Holiday from "../pages/HRMS/AdminControls/Holiday Mangement/UpdateHoliday";
import LeaveApplicationListAdmin from "../pages/HRMS/AdminControls/Leave Mangement/LeaveAplicationList";
import Update_DayOff from "../pages/HRMS/AdminControls/Leave Mangement/Update_DayOff";
import AddSalarySlip from "../pages/HRMS/AdminControls/Salary Mangement/AddSalarySlip";
import SalarySlipRecords from "../pages/HRMS/AdminControls/Salary Mangement/SalarySlipRecords";
import ViewSalarySlip from "../pages/HRMS/AdminControls/Salary Mangement/ViewSalarySlip";
import AttendanceCalender from "../pages/HRMS/EmployeeControls/Attendance Management/AttendanceCalender";
import HolidayList from "../pages/HRMS/EmployeeControls/Holiday Management/HolidayList";
import ApplyLeave from "../pages/HRMS/EmployeeControls/Leave Management/ApplyLeave";
import LeaveApplicationList from "../pages/HRMS/EmployeeControls/Leave Management/LeaveApplications";
import ViewLeave from "../pages/HRMS/EmployeeControls/Leave Management/ViewLeave";
import Profile from "../pages/HRMS/EmployeeControls/Personal Details/Profile";
import SalarySlipRecordsEmployee from "../pages/HRMS/EmployeeControls/Salary Management/SalarySlipRecordsEmployee";
import ViewSalarySlipEmployee from "../pages/HRMS/EmployeeControls/Salary Management/ViewSalarySlipEmployee";
import PageNotFound from "../pages/PageNotFound";
// import InvoiceList from "../pages/HRMS/EmployeeControls/Invoice Management/InvoiceList"
// import AddInvoice from "../pages/HRMS/EmployeeControls/Invoice Management/AddInvoice";

const EmployeeRouter = () => {
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  return (
    <Switch>
      <Route exact path="/applyleave" component={ApplyLeave} />
      {/* <Route exact path="/salaryinvoice" component={InvoiceList} /> */}
      <Route exact path="/leaveapplications" component={LeaveApplicationList} />
      <Route exact path="/viewleave" component={ViewLeave} />
      <Route exact path="/attendance" component={AttendanceCalender} />
      <Route exact path="/holidayslist" component={ListHolidays} />
      <Route
        exact path="/salarysliprecordsemployee" component={SalarySlipRecordsEmployee} />
      <Route exact path="/viewsalaryslip" component={ViewSalarySlipEmployee} />
      <Route exact path="/employeeprofile" component={Profile} />
      {/* <Route exact path="/addinvoice" component={AddInvoice} /> */}
      {/*admin protected routes  */}
      {login.fld_admin == "Yes" && (
        <>
          <Route exact path="/addemployee" component={Employee} />
          <Route exact path="/employee_list" component={EmployeeList} />
          <Route
            exact path="/dayoff-list" component={LeaveApplicationListAdmin} />
          <Route exact path="/update-dayoff" component={Update_DayOff} />
          <Route exact path="/employee_attendance" component={Attendance} />
          <Route exact path="/addholiday" component={AddHoliday} />
          <Route
            exact
            path="/salaryslip_records"
            component={SalarySlipRecords}
          />
          <Route exact path="/addsalaryslip" component={AddSalarySlip} />
          <Route exact path="/viewsalaryslip" component={ViewSalarySlip} />
          <Route exact path="/calender" component={Calender} />
          <Route exact path="/updateholiday" component={Update_Holiday} />
          <Route
            exact
            path="/editpersonaldetails"
            component={EditEmployeePersonalDetails}
          />
          <Route
            exact
            path="/editlogincredentials"
            component={EditEmployeeLoginCredentials}
          />



        </>
      )}

      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};
export default EmployeeRouter;
