import "../Personalinfo/Personalinfo.css";
import React, { useState, useContext, useEffect } from "react";
// import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
// import { AiOutlineCamera } from "react-icons/ai";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
// import { BsFillCameraFill } from "react-icons/bs";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
// import GetApiCall from "../../../assets/API/GetApi";
import moment from "moment";
import Notiflix from "notiflix";
// import { NavLink } from "react-router-dom";
// import axios from "axios";
import imageCompression from "browser-image-compression";
export default function Form({ stdid, action }) {
  var login = stdid
    ? { fld_id: stdid }
    : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  var preurl =
    "https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1223671392?k=20&m=1223671392&s=612x612&w=0&h=lGpj2vWAI3WUT1JeJWm1PRoHT3V15_1pdcTn2szdwQ0=";
  // --------------validation-------------------------------
  // let [MiddleName, setMiddleName] = useState("");
  let [ValidationList, setValidationList] = useState("");
  let [UploadProfilePic, setUploadProfilePic] = useState("");
  let [StudentIdentificationNumber, setStudentIdentificationNumber] =
    useState("");
  let [FirstName, setFirstName] = useState("");
  let [MiddleName, setMiddleName] = useState("");
  let [LastName, setLastName] = useState("");
  let [Mobile, setMobile] = useState("");
  let [Email, setEmail] = useState("");
  let [Dateofbirth, setDateofbirth] = useState("");
  let [Gender, setGender] = useState("");
  let [BloodGroup, setBloodGroup] = useState("");
  let [StateofBirth, setStateofBirth] = useState("");
  let [CityofBirth, setCityofBirth] = useState("");
  let [EmergencyContact, setEmergencyContact] = useState("");
  //  EmergencyContact selyear Aadhar Pan know seniorscholar nameschola
  let [selyear, setselyear] = useState("");
  let [Aadhar, setAadhar] = useState("");
  let [Pan, setPan] = useState("");
  let [know, setknow] = useState("");
  let [seniorscholar, setseniorscholar] = useState("");
  let [namescholar, setnamescholar] = useState("");
  let [BatchFieldShow, setBatchFieldShow] = useState(false);
  let [cvfile, setCvFile] = useState([]);
  // let [disableFields, setdisableFields] = useState([]);
  //-----------------validation-----------------------------
  const [image, setimage] = React.useState("");
  const [status, setstatus] = React.useState("");
  const [datas, setData] = React.useState("");
  const [values, setValues] = React.useState({
    fld_dob: "",
    fld_gender: "",
    fld_startbatch: "",
    fld_aboutVahani: "",
    fld_knowVahani: "",
    fld_photo: "",
    fld_vahaniSpecify: "",
    fld_bloodgroup: "",
  });
  const [vimg, setvimg] = React.useState({
    files: "",
    imagePreviewUrls: "",
    ImageDatas: "",
  });
  const [previewUrl, setPreviewUrl] = useState(
    "https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1223671392?k=20&m=1223671392&s=612x612&w=0&h=lGpj2vWAI3WUT1JeJWm1PRoHT3V15_1pdcTn2szdwQ0="
  );
  const [ImageApiUrl, setImageApiUrl] = useState(
    "https://admin.vahanischolarship.in/API/AddImage2"
  );
  const [originalImage, setoriginalImage] = useState([]);
  const [uploadImage, setuploadImage] = useState(false);
  const [outputFileName, setoutputFileName] = useState("");
  const [otherspecifyText, setotherspecifyText] = useState(false);

  const ImgUpload = ({ onChange, src }) => (
    <label
      htmlFor="photo-upload"
      className="custom-file-upload fas m-0 img-button"
    >
      <div className="img-wrap img-upload">
        <img
          for="photo-upload"
          src={src}
          style={{ width: "100%", height: "100%" }}
          alt="pho"
        />
      </div>
      <input
        accept="image/*"
        id="photo-upload"
        type="file"
        className="input-file"
        onChange={onChange}
        style={{ display: "none" }}
      />
    </label>
  );
  const onChangeHandler = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  //
  //------stateandcity-----------
  const [stp, setStp] = useState([]);
  const [st, setSt] = useState([]);
  const [stateid, setStateid] = useState(15);
  const [city, setCity] = useState([]);
  const [cityid, setCityid] = useState("");
  React.useEffect(() => {
    const asyncPostCallp = async () => {
      try {
        const response = await fetch(
          "https://admin.vahanischolarship.in/API/GetState",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              countryid: 101,
            }),
          }
        );
        const data = await response.json();
        //
        setStp(data.data);
        //
      } catch (error) {
        //
      }
    };
    asyncPostCallp();
    // const getstatep = async () => {
    //   const resstatep = await fetch(`http://localhost:8095/API/GetState ,`);
    //   const resstp = await resstatep.json();
    //   setSt(await resstp);
    // };
    // getstatep();
  }, []);
  //
  const handlestate = (event) => {
    const getstateid = event.target.value;
    //
    setStateid(getstateid);
  };
  React.useEffect(() => {
    if (stateid !== "d") {
      const asyncPostCall = async () => {
        try {
          const response = await fetch(
            "https://admin.vahanischolarship.in/API/GetCity",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                stateid: stateid,
              }),
            }
          );
          const data = await response.json();
          //
          setCity(data.data);
        } catch (error) {
          //
        }
      };
      asyncPostCall();
    }
  }, [stateid]);
  const handlecity = (e) => {
    const getcityid = e.target.value;
    setCityid(getcityid);
    //
  };
  React.useEffect(() => {
    Notiflix.Loading.dots();
    GetStudentPersonalInfo();
    fetchData();
    // statusupdate
  }, []);
  const fetchData = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_module = 'personalinformation'`,
      },
      "FormValidation"
    ).then((results10) =>
      results10.json().then((obj10) => {
        if (results10.status === 200 || results10.status === 201) {
          //
          setValidationList(obj10.data);
          Notiflix.Loading.remove();
          setUploadProfilePic(obj10.data[0]);
          setStudentIdentificationNumber(obj10.data[1]);
          setFirstName(obj10.data[2]);
          setMiddleName(obj10.data[3]);
          setLastName(obj10.data[4]);
          setMobile(obj10.data[5]);
          setEmail(obj10.data[6]);
          setDateofbirth(obj10.data[7]);
          setGender(obj10.data[8]);
          setBloodGroup(obj10.data[18]);
          setStateofBirth(obj10.data[9]);
          setCityofBirth(obj10.data[10]);
          setEmergencyContact(obj10.data[11]);
          setselyear(obj10.data[12]);
          setAadhar(obj10.data[13]);
          setPan(obj10.data[14]);
          setknow(obj10.data[15]);
          setseniorscholar(obj10.data[16]);
          setnamescholar(obj10.data[17]);
        }
      })
    );
  };
  // validation
  const GetStudentPersonalInfo = () => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "GetStudentPersonalInformation_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Loading.remove();
          //
          setData(obj.data[0]);
          setimage(obj.data[0].fld_photo);
          setPreviewUrl(
            obj.data[0].fld_photo == null ? previewUrl : obj.data[0].fld_photo
          );
          setBatchFieldShow(obj.data[0].fld_startbatch == null ? false : true);
          // let field = [
          //   "fld_firstName",
          //   "fld_middleName",
          //   "fld_studentId",
          //   "fld_email",
          //   "fld_lastName",
          //   "fld_mobile",
          //   "fld_dob",
          //   "fld_gender",
          //   "fld_emergencyContactNumber",
          //   "fld_startbatch",
          //   "fld_aadhar",
          //   "fld_pan",
          //   "fld_aboutVahani",
          //   "fld_knowVahani",
          //   "fld_scholarName",
          //   "fld_photo",
          // ];
          // field.forEach((fields) => {
          //   setValue(fields, obj.data[0][fields]);
          // });
          setCityid(obj.data[0].fld_city);
          setStateid(obj.data[0].fld_state);
          setValues(obj.data[0]);
          reset(obj.data[0]);
          // Aadhar = obj.data[0].fld_aadhar;
          // altered = Aadhar.replace(regex, "$2-$2-$3");
          // reset({
          //   fld_aadhar: altered,
          // });
        }
      })
    );
  };
  // batch year
  var year = [];
  for (
    var i = new Date().getFullYear();
    i <= new Date().getFullYear() + 1;
    i++
  ) {
    year.push({
      label: i,
      value: i,
    });
  }
  // batch year
  //select
  const [file, setFile] = useState();
  // form validation
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const onPostPersonalInfo = (personal) => {
    var detail = JSON.parse(localStorage.getItem("LoginDetail"))[0];
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
        firstName:
          personal.fld_firstName.charAt(0).toUpperCase() +
          personal.fld_firstName.substr(1),
        middleName:
          personal.fld_middleName.charAt(0).toUpperCase() +
          personal.fld_middleName.substr(1),
        lastName:
          personal.fld_lastName.charAt(0).toUpperCase() +
          personal.fld_lastName.substr(1),
        mobile: personal.fld_mobile,
        email: personal.fld_email,
        dob: values.fld_dob,
        gender: values.fld_gender,
        bloodgroup: values.fld_bloodgroup,
        photo:
          outputFileName == ""
            ? previewUrl
            : "https://admin.vahanischolarship.in/Images/StudentInformation/" +
            personal.fld_firstName.trim().replace(/\s/g, "-") +
            "-" +
            outputFileName,
        state: personal.fld_state,
        city: personal.fld_city,
        emergencyContactNumber: personal.fld_emergencyContactNumber,
        aadhar: personal.fld_aadhar,
        pan: personal.fld_pan,
        startbatch: values.fld_startbatch,
        updatedBy: detail.fld_id,
        updatedOn: moment().format("lll"),
        aboutVahani: personal.fld_aboutVahani,
        vahaniSpecify: personal.fld_vahaniSpecify,
        knowVahani: personal.fld_knowVahani,
        ScholarName: personal.fld_scholarName,
        cvfile:
          cvfile.length != 0
            ? "https://admin.vahanischolarship.in/Images/" +
            "scholarcv/" +
            personal.fld_firstName.trim().replace(/\s/g, "-") +
            "-" +
            cvfile.name
            : null,
      },
      "UpdateStudentPersonalInformation"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Saved and Updated Successfully");
          GetStudentPersonalInfo();
          // //
        } else {
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
          Notiflix.Loading.remove();
        }
      })
    );
  };
  async function onPost(personal) {
    //
    let response;
    if (outputFileName != "") {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(originalImage, options).then((compressedFile) => {
        ////
        //this.state.CategoryName.trim().replace(/\s/g, "-") +
        //"-" +
        //compressedFile.name)
        const form = new FormData();
        //
        form.append("file", compressedFile);
        form.append("foldername", "StudentInformation");
        form.append(
          "filename",
          personal.fld_firstName.trim().replace(/\s/g, "-") +
          "-" +
          compressedFile.name
        );
        response = fetch(ImageApiUrl, {
          method: "POST",
          body: form,
        })
          .then((response) => response.json())
          .then((data) => {
            //
            Notiflix.Notify.success("Profile Picture Uploaded Successfully.");
            Notiflix.Loading.remove();
            onPostScholarCv(personal);
          });
        // ////
      });
    } else {
      onPostScholarCv(personal);
    }
  }

  async function onPostScholarCv(personal) {
    //
    let response;
    if (cvfile != "") {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const form = new FormData();
      //
      form.append("file", cvfile);
      form.append("foldername", "scholarcv");
      form.append(
        "filename",
        personal.fld_firstName.trim().replace(/\s/g, "-") + "-" + cvfile.name
      );
      response = fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      })
        .then((response) => response.json())
        .then((data) => {
          //
          Notiflix.Notify.success("Cv Uploaded Successfully.");
          Notiflix.Loading.remove();
          onPostPersonalInfo(personal);
        });
      // ////
    } else {
      onPostPersonalInfo(personal);
    }
  }

  //
  const onSubmit = (personal) => {
    // e.preventDefault();
    // console.warn("updatesave", personal);
    onPost(personal);
  };
  //
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="form-head ">
            <div
              style={{
                borderLeft: "10px solid rgb(32, 101, 209)",
                paddingLeft: "10px",
              }}
            >
              <h4>Personal Information</h4>
              {/* <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="text.primary" href="/dashboard" aria-current="page" >  Dashboard  </Link>
              </Breadcrumbs> */}
            </div>
          </div>
          <div className="card-vahani p-0 pb-4 ">
            <p className="personal-info left-line-card px-lg-4 px-3 py-4">
              Basic Information
            </p>
            <div className="row px-lg-4  px-3 pb-0 pt-3">
              <div className="col-lg-3 col-12 per-mar-img">
                <TextField
                  {...register("fld_photo", {
                    required:
                      previewUrl == preurl
                        ? UploadProfilePic.fld_error_message
                        : false,
                  })}
                  style={{ visibility: "hidden", position: "absolute" }}
                />
                <div className="img-file-form1">
                  <ImgUpload
                    onChange={(e) => {
                      if (UploadProfilePic.fld_editable == "Yes") {
                        const imageFile = e.target.files[0];
                        if (
                          imageFile.type == "image/png" ||
                          imageFile.type == "image/jpeg" ||
                          imageFile.type == "image/jpg"
                        ) {
                          if (imageFile.size <= 2000000) {
                            setPreviewUrl(URL.createObjectURL(imageFile));
                            setoriginalImage(imageFile);
                            setoutputFileName(imageFile.name);
                            setuploadImage(true);
                          } else {
                            Notiflix.Notify.failure(
                              "File too large, upload file less than 2MB."
                            );
                          }
                        } else {
                          Notiflix.Notify.failure(
                            "File type should be png, jpg or jpeg."
                          );
                        }
                      }
                    }}
                    src={previewUrl}
                  />
                </div>
                <FormHelperText
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  {" "}
                  {errors?.fld_photo
                    ? errors.fld_photo.message
                    : UploadProfilePic.fld_help_text}
                </FormHelperText>
                <div className="upload-info-personal">
                  {UploadProfilePic.fld_mandatory == "Yes"
                    ? `${UploadProfilePic.fld_field_name} *`
                    : UploadProfilePic.fld_field_name}{" "}
                  <small>(Maximum Size 2MB)</small> <br />
                  File Format *.jpeg, *.jpg, *.png
                </div>
              </div>
              <div className="col-lg-9 col-12">
                <div>
                  <TextField
                    className="mb-lg-3 mobile-personal"
                    disabled={StudentIdentificationNumber.fld_editable != "Yes"}
                    label={StudentIdentificationNumber.fld_field_name}
                    fullWidth
                    {...register("fld_studentId", {
                      required:
                        StudentIdentificationNumber.fld_mandatory == "Yes"
                          ? StudentIdentificationNumber.fld_error_message
                          : false,
                    })}
                    InputLabelProps={{
                      shrink: true,
                      required:
                        StudentIdentificationNumber.fld_mandatory == "Yes"
                          ? true
                          : false,
                    }}
                  />
                  <div className="row py-2">
                    {/* validate: value => /^[a-zA-Z ]+$/.test(value)  */}
                    <div className="col-lg-4 mb-lg-3 mobile-personal">
                      <TextField
                        disabled={FirstName.fld_editable != "Yes"}
                        label={FirstName.fld_field_name}
                        fullWidth
                        className="text-field capital"
                        {...register("fld_firstName", {
                          required:
                            FirstName.fld_mandatory == "Yes"
                              ? FirstName.fld_error_message
                              : false,
                          pattern: {
                            value: new RegExp(FirstName.fld_reg_ex, "i"),
                            message: FirstName.fld_reg_ex_msg,
                          },
                        })}
                        error={errors?.fld_firstName}
                        helperText={
                          errors?.fld_firstName
                            ? errors.fld_firstName.message
                            : FirstName.fld_help_text
                        }
                        InputLabelProps={{
                          shrink: true,
                          required:
                            FirstName.fld_mandatory == "Yes" ? true : false,
                        }}
                      />
                    </div>
                    {/* /^[a-zA-Z ]+$/ */}
                    <div className="col-lg-4 mb-lg-3 mobile-personal">
                      <TextField
                        disabled={MiddleName.fld_editable != "Yes"}
                        label={MiddleName.fld_field_name}
                        fullWidth
                        error={errors?.fld_middleName}
                        className="text-field capital"
                        {...register("fld_middleName", {
                          required:
                            MiddleName.fld_mandatory == "Yes"
                              ? MiddleName.fld_error_message
                              : false,
                          pattern: {
                            value: new RegExp(MiddleName.fld_reg_ex),
                            message: MiddleName.fld_reg_ex_msg,
                          },
                        })}
                        // onChange={e => setValue('fld_middleName', /^[a-zA-Z ]+$/.test(e.target.value== "" ? ) ? e.target.value : watch("fld_middleName")  )}
                        InputLabelProps={{
                          shrink: true,
                          required:
                            MiddleName.fld_mandatory == "Yes" ? true : false,
                        }}
                        helperText={
                          errors?.fld_middleName
                            ? errors.fld_middleName.message
                            : MiddleName.fld_help_text
                        }
                      />
                    </div>
                    <div className="col-lg-4 mb-lg-3 mobile-personal">
                      <TextField
                        disabled={LastName.fld_editable != "Yes"}
                        label={LastName.fld_field_name}
                        fullWidth
                        className="text-field capital"
                        {...register("fld_lastName", {
                          required:
                            LastName.fld_mandatory == "Yes"
                              ? LastName.fld_error_message
                              : false,
                          pattern: {
                            value: new RegExp(LastName.fld_reg_ex),
                            message: LastName.fld_reg_ex_msg,
                          },
                        })}
                        error={errors?.fld_lastName}
                        helperText={
                          errors?.fld_lastName
                            ? errors.fld_lastName.message
                            : LastName.fld_help_text
                        }
                        InputLabelProps={{
                          shrink: true,
                          required:
                            LastName.fld_mandatory == "Yes" ? true : false,
                        }}
                      />
                    </div>
                  </div>
                  <div className="row py-2">
                    <div className="col-lg-4 col-12 mb-lg-3 mobile-personal">
                      <TextField
                        disabled={Mobile.fld_editable != "Yes"}
                        label={Mobile.fld_field_name}
                        fullWidth
                        className="text-field"
                        {...register("fld_mobile", {
                          required:
                            Mobile.fld_mandatory == "Yes"
                              ? Mobile.fld_error_message
                              : false,
                          pattern: {
                            value: new RegExp(Mobile.fld_reg_ex),
                            message: Mobile.fld_reg_ex_msg,
                          },
                          maxLength: {
                            value: 10,
                            message: "Enter maximum 10-digit number",
                          },
                          minLength: {
                            value: 10,
                            message: "Enter minimum 10-digit number",
                          },
                        })}
                        error={errors?.fld_mobile}
                        helperText={
                          errors?.fld_mobile
                            ? errors.fld_mobile.message
                            : Mobile.fld_help_text
                        }
                        inputProps={{ maxLength: 10 }}
                        InputLabelProps={{
                          shrink: true,
                          required:
                            Mobile.fld_mandatory == "Yes" ? true : false,
                        }}
                      />
                    </div>
                    <div className="col-lg-8 col-12 mb-lg-3 mobile-personal">
                      <TextField
                        disabled={Email.fld_editable != "Yes"}
                        label={Email.fld_field_name}
                        fullWidth
                        error={errors?.fld_email}
                        {...register("fld_email", {
                          required:
                            Email.fld_mandatory == "Yes"
                              ? Email.fld_error_message
                              : false,
                          pattern: {
                            value: new RegExp(Email.fld_reg_ex),
                            message: Email.fld_reg_ex_msg,
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                          required: Email.fld_mandatory == "Yes" ? true : false,
                        }}
                        helperText={
                          errors?.fld_email
                            ? errors.fld_email.message
                            : Email.fld_help_text
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row py-2 px-lg-4 px-3">
              <div className="col-lg-4 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  disabled={Dateofbirth.fld_editable != "Yes"}
                  className="text-field"
                  label={Dateofbirth.fld_field_name}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                    required: Dateofbirth.fld_mandatory == "Yes" ? true : false,
                  }}
                  {...register("fld_dob", {
                    required:
                      Dateofbirth.fld_mandatory == "Yes"
                        ? Dateofbirth.fld_error_message
                        : false,
                  })}
                  error={errors?.fld_dob}
                  helperText={
                    errors?.fld_dob
                      ? errors.fld_dob.message
                      : Dateofbirth.fld_help_text
                  }
                  value={values.fld_dob}
                  onChange={onChangeHandler}
                  InputProps={{
                    inputProps: {
                      min: moment()
                        .subtract(21, "years")
                        .format("YYYY/MM/DD")
                        .split("/")
                        .join("-"),
                      max: moment()
                        .subtract(15, "years")
                        .format("YYYY/MM/DD")
                        .split("/")
                        .join("-"),
                    },
                  }}
                />
              </div>
              <div className="col-lg-4 col-12 mb-lg-3 mobile-personal form-auto ">
                <FormControl
                  fullWidth
                  disabled={Gender.fld_editable != "Yes"}
                  error={Boolean(errors.fld_gender)}
                >
                  <InputLabel required={Gender.fld_mandatory == "Yes"}>
                    {Gender.fld_field_name}
                  </InputLabel>
                  <Select
                    {...register("fld_gender", {
                      required:
                        Gender.fld_mandatory == "Yes"
                          ? Gender.fld_error_message
                          : false,
                    })}
                    error={Boolean(errors.fld_gender)}
                    label={Gender.fld_field_name}
                    onChange={onChangeHandler}
                    value={values.fld_gender}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Others</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.fld_gender
                      ? errors?.fld_gender.message
                      : Gender.fld_help_text}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-4 col-12 mb-lg-3 mobile-personal">
                <FormControl
                  fullWidth
                  disabled={BloodGroup.fld_editable != "Yes"}
                  error={Boolean(errors.fld_bloodgroup)}
                >
                  <InputLabel required={BloodGroup.fld_mandatory == "Yes"}>
                    {BloodGroup.fld_field_name}
                  </InputLabel>
                  <Select
                    {...register("fld_bloodgroup", {
                      required:
                        BloodGroup.fld_mandatory == "Yes"
                          ? BloodGroup.fld_error_message
                          : false,
                    })}
                    error={Boolean(errors.fld_bloodgroup)}
                    label={BloodGroup.fld_field_name}
                    onChange={onChangeHandler}
                    value={values.fld_bloodgroup}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value="A+">A+</MenuItem>
                    <MenuItem value="B+">B+</MenuItem>
                    <MenuItem value="A-">A-</MenuItem>
                    <MenuItem value="B-">B-</MenuItem>
                    <MenuItem value="O+">O+</MenuItem>
                    <MenuItem value="O-">O-</MenuItem>
                    <MenuItem value="AB+">AB+</MenuItem>
                    <MenuItem value="AB-">AB-</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.fld_bloodgroup
                      ? errors?.fld_bloodgroup.message
                      : BloodGroup.fld_help_text}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="row py-2 px-lg-4 px-3">
              <div className="col-lg-6 col-12 form-auto mb-lg-3 mobile-personal">
                <FormControl
                  disabled={StateofBirth.fld_editable != "Yes"}
                  fullWidth
                  error={Boolean(errors.fld_state)}
                >
                  <InputLabel
                    id="id-state"
                    required={StateofBirth.fld_mandatory == "Yes"}
                  >
                    {StateofBirth.fld_field_name}{" "}
                  </InputLabel>
                  <Select
                    {...register("fld_state", {
                      required:
                        StateofBirth.fld_mandatory == "Yes"
                          ? StateofBirth.fld_error_message
                          : false,
                    })}
                    error={errors?.fld_state}
                    label={StateofBirth.fld_field_name}
                    onChange={handlestate}
                    value={stateid}
                    InputLabelProps={{ shrink: true }}
                  >
                    {stp.map((record, item) => {
                      return (
                        <MenuItem value={record.value}>{record.label}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_state
                      ? errors.fld_state.message
                      : StateofBirth.fld_help_text}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 form-auto mb-lg-3 mobile-personal">
                <FormControl
                  disabled={CityofBirth.fld_editable != "Yes"}
                  fullWidth
                  error={Boolean(errors.fld_city)}
                >
                  <InputLabel
                    id="id-city"
                    required={CityofBirth.fld_mandatory == "Yes"}
                  >
                    {" "}
                    {CityofBirth.fld_field_name}{" "}
                  </InputLabel>
                  <Select
                    labelId="label-id-city"
                    id="id-city"
                    label={CityofBirth.fld_field_name}
                    {...register("fld_city", {
                      required:
                        CityofBirth.fld_mandatory == "Yes"
                          ? CityofBirth.fld_error_message
                          : false,
                    })}
                    error={errors?.fld_city}
                    onChange={handlecity}
                    value={cityid}
                    InputLabelProps={{ shrink: true }}
                  >
                    {city.map((record, item) => {
                      return (
                        <MenuItem value={record.value}>{record.label}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_city
                      ? errors.fld_city.message
                      : CityofBirth.fld_help_text}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="row py-2 px-lg-4 px-2">
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  disabled={EmergencyContact.fld_editable != "Yes"}
                  label={EmergencyContact.fld_field_name}
                  className="text-field"
                  {...register("fld_emergencyContactNumber", {
                    required:
                      EmergencyContact.fld_mandatory == "Yes"
                        ? EmergencyContact.fld_error_message
                        : false,
                    pattern: {
                      value: new RegExp(EmergencyContact.fld_reg_ex),
                      message: EmergencyContact.fld_reg_ex_msg,
                    },
                    minLength: {
                      value: 10,
                      message: "Enter minimum 10-digit number",
                    },
                  })}
                  error={errors?.fld_emergencyContactNumber}
                  helperText={
                    errors?.fld_emergencyContactNumber
                      ? errors.fld_emergencyContactNumber.message
                      : EmergencyContact.fld_help_text
                  }
                  inputProps={{ maxLength: 10 }}
                  InputLabelProps={{
                    shrink: true,
                    required:
                      EmergencyContact.fld_mandatory == "Yes" ? true : false,
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                {BatchFieldShow ? (
                  <TextField
                    fullWidth
                    disabled
                    label={selyear.fld_field_name}
                    className="text-field mobile-personal"
                    {...register("fld_startbatch", {
                      required:
                        selyear.fld_mandatory == "Yes"
                          ? selyear.fld_error_message
                          : false,
                    })}
                    error={errors?.fld_startbatch}
                    helperText={
                      errors?.fld_startbatch
                        ? errors.fld_startbatch.message
                        : selyear.fld_help_text
                    }
                    inputProps={{ maxLength: 10 }}
                    InputLabelProps={{
                      shrink: true,
                      required: selyear.fld_mandatory == "Yes" ? true : false,
                    }}
                  />
                ) : (
                  <FormControl
                    disabled={selyear.fld_editable != "Yes"}
                    fullWidth
                    error={Boolean(errors.fld_startbatch)}
                  >
                    <InputLabel
                      id="label-batch"
                      required={selyear.fld_mandatory == "Yes"}
                    >
                      {" "}
                      {selyear.fld_field_name}{" "}
                    </InputLabel>
                    <Select
                      labelId="label-batch"
                      id="id-batch"
                      label="Which year will you start your college?*"
                      {...register("fld_startbatch", {
                        required:
                          selyear.fld_mandatory == "Yes"
                            ? selyear.fld_error_message
                            : false,
                      })}
                      error={errors?.fld_startbatch}
                      value={values.fld_startbatch}
                      onChange={onChangeHandler}
                      InputLabelProps={{ shrink: true }}
                    >
                      {year.map((record) => (
                        <MenuItem value={record.value}>{record.value}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {" "}
                      {errors?.fld_startbatch
                        ? errors.fld_startbatch.message
                        : selyear.fld_help_text}
                    </FormHelperText>
                  </FormControl>
                )}
              </div>
            </div>
          </div>
          <div className="card-vahani p-0 " style={{ marginTop: "24px" }}>
            <p className="personal-info left-line-card p-4">
              Identification Details
            </p>
            <div className="row p-4">
              <div className="col-lg-6 col-12">
                <TextField
                  fullWidth
                  type="number"
                  disabled={Aadhar.fld_editable != "Yes"}
                  label={Aadhar.fld_field_name}
                  className="text-field mobile-personal"
                  {...register("fld_aadhar", {
                    required:
                      Aadhar.fld_mandatory == "Yes"
                        ? Aadhar.fld_error_message
                        : false,
                    pattern: {
                      value: new RegExp(Aadhar.fld_reg_ex),
                      message: Aadhar.fld_reg_ex_msg,
                    },
                    minLength: {
                      value: 12,
                      message: "Enter minimum 12-digit number",
                    },
                    maxLength: {
                      value: 12,
                      message: "Enter maximum 12-digit number",
                    },
                  })}
                  error={errors?.fld_aadhar}
                  helperText={
                    errors?.fld_aadhar
                      ? errors.fld_aadhar.message
                      : Aadhar.fld_help_text
                  }
                  // inputProps={{ maxLength: 12, }}
                  InputLabelProps={{
                    shrink: true,
                    required: Aadhar.fld_mandatory == "Yes" ? true : false,
                  }}
                // value={altered}
                />
              </div>
              <div className="col-lg-6 col-12 capital-col">
                <TextField
                  fullWidth
                  disabled={Pan.fld_editable != "Yes"}
                  label={Pan.fld_field_name}
                  className="text-field capitalonly"
                  inputProps={{
                    maxLength: 10,
                  }}
                  {...register("fld_pan", {
                    required:
                      Pan.fld_mandatory == "Yes"
                        ? Pan.fld_error_message
                        : false,
                    pattern: {
                      value: new RegExp(Pan.fld_reg_ex),
                      message: Pan.fld_reg_ex_msg,
                    },
                    minLength: {
                      value: 10,
                      message: "Enter minimum 10-digit number",
                    },
                  })}
                  onChange={(e) => {
                    setValue("fld_pan", e.target.value.toUpperCase());
                  }}
                  error={errors?.fld_pan}
                  helperText={
                    errors?.fld_pan ? errors.fld_pan.message : Pan.fld_help_text
                  }
                  InputLabelProps={{
                    shrink: true,
                    required: Pan.fld_mandatory == "Yes" ? true : false,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="card-vahani p-0 pb-4" style={{ marginTop: "26px" }}>
            <p className="personal-info left-line-card p-4">Other Details</p>
            <div className="row px-4 pt-4 pb-2">
              <div className="col-lg-6 col-12 form-auto mb-lg-3 mobile-personal ">
                <FormControl
                  disabled={know.fld_editable != "Yes"}
                  fullWidth
                  error={Boolean(errors.fld_aboutVahani)}
                >
                  <InputLabel
                    id="simple-vahaniSpecify"
                    required={
                      know.fld_mandatory == "Yes"
                        ? know.fld_error_message
                        : false
                    }
                  >
                    {know.fld_field_name}
                  </InputLabel>
                  <Select
                    name="fld_aboutVahani"
                    labelId="lable-vahaniSpecify"
                    id="id-vahaniSpecify"
                    label={know.fld_field_name}
                    {...register("fld_aboutVahani", {
                      required:
                        know.fld_mandatory == "Yes"
                          ? know.fld_error_message
                          : false,
                    })}
                    error={errors?.fld_aboutVahani}
                    InputLabelProps={{ shrink: true }}
                    value={values.fld_aboutVahani}
                    onChange={onChangeHandler}
                  >
                    <MenuItem value={"Google"}>Google</MenuItem>
                    <MenuItem value={"Social Media"}>Social Media</MenuItem>
                    <MenuItem value={"Newspaper"}>Newspaper</MenuItem>
                    <MenuItem value={"From School"}>From School</MenuItem>
                    <MenuItem value={"Others - please specify"}>
                      {" "}
                      Others - please specify
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.fld_aboutVahani
                      ? errors.fld_aboutVahani.message
                      : know.fld_help_text}
                  </FormHelperText>
                </FormControl>
              </div>

              {values.fld_aboutVahani == "Others - please specify" && (
                <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                  <TextField
                    fullWidth
                    disabled={LastName.fld_editable != "Yes"}
                    label={"Please Specify *"}
                    className="other-reason"
                    {...register("fld_vahaniSpecify", {
                      required: "Please Fill.",
                    })}
                    value={values.fld_vahaniSpecify}
                    onChange={onChangeHandler}
                    error={
                      watch("fld_aboutVahani") == "Others - please specify" &&
                      errors?.fld_vahaniSpecify
                    }
                    helperText={
                      errors?.fld_vahaniSpecify
                        ? errors?.fld_vahaniSpecify.message
                        : "Enter Please Specify"
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              )}
            </div>
            <div className="row px-4 py-2">
              <div className="col-lg-6 col-12 form-auto mb-lg-3 mobile-personal">
                <FormControl
                  disabled={seniorscholar.fld_editable != "Yes"}
                  fullWidth
                  error={Boolean(errors.fld_knowVahani)}
                >
                  <InputLabel required={seniorscholar.fld_mandatory == "Yes"}>
                    {" "}
                    {seniorscholar.fld_field_name}
                  </InputLabel>
                  <Select
                    label={seniorscholar.fld_field_name}
                    {...register("fld_knowVahani", {
                      required:
                        seniorscholar.fld_mandatory == "Yes"
                          ? seniorscholar.fld_error_message
                          : false,
                    })}
                    error={errors?.fld_knowVahani}
                    onChange={onChangeHandler}
                    value={values.fld_knowVahani}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.fld_knowVahani
                      ? errors.fld_knowVahani.message
                      : seniorscholar.fld_help_text}
                  </FormHelperText>
                </FormControl>
              </div>
              {values.fld_knowVahani == "Yes" && (
                <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                  <TextField
                    fullWidth
                    disabled={namescholar.fld_editable != "Yes"}
                    label={namescholar.fld_field_name}
                    className="text-field capital "
                    {...register("fld_scholarName", {
                      required:
                        namescholar.fld_mandatory == "Yes"
                          ? namescholar.fld_error_message
                          : false,
                      pattern: {
                        value: new RegExp(namescholar.fld_reg_ex),
                        message: namescholar.fld_reg_ex_msg,
                      },
                    })}
                    error={errors?.fld_scholarName}
                    helperText={
                      errors?.fld_scholarName
                        ? errors.fld_scholarName.message
                        : namescholar.fld_help_text
                    }
                    InputLabelProps={{
                      shrink: true,
                      required: namescholar.fld_mandatory == "Yes",
                    }}
                  />
                </div>
              )}
            </div>
            <div className="row px-4 py-2">
              {values.fld_type == "Scholar" ? (
                <>
                  <div className="col-lg-6 col-12 form-auto mb-lg-3 mobile-personal ">
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Upload Cv (Maximum Size 6Mb | File Format .pdf .jpeg)"
                      variant="outlined"
                      className="text-field"
                      type="File"
                      onChange={(e) => {
                        e.preventDefault();
                        if (e.target.files[0].size < 6000000) {
                          const reader = new FileReader();
                          const file = e.target.files[0];
                          console.log(file.name);
                          reader.onloadend = () => {
                            setCvFile(file);
                          };
                          reader.readAsDataURL(file);
                        } else {
                          Notiflix.Notify.failure(
                            "File too large, upload file less than 6 MB."
                          );
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ accept: "application/pdf, image/jpeg" }}
                    />
                  </div>
                </>
              ) : (
                <div></div>
              )}
              {values.fld_type == "Scholar" ? (
                <div
                  className="col-lg-1 col-12 form-auto mb-lg-3"
                  style={{
                    display: values.fld_cv_file == null ? "none" : "block",
                    textAlign: "center",
                  }}
                >
                  <a
                    target="_blank"
                    className="but-login-vahani"
                    href={values.fld_cv_file}
                  >
                    View
                  </a>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {action != "view" && (
          <div className="card-vahani" style={{ marginTop: "26px" }}>
            <div className="d-flex justify-content-end">
              <div className="but-width-form">
                <Button
                  variant="contained"
                  className="but-login-vahani"
                  type="submit"
                >
                  Save and update
                </Button>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
}
