import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Notiflix from "notiflix";
import moment from "moment";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { MDBDataTable } from "mdbreact";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PostApiCall from "../../../assets/API/PostApi";
import GetApiCall from "../../../assets/API/GetApi";
export default function ViewNotification() {
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const [rows, setrows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModal, setshowModal] = useState(false);
  const [SelectData, setSelectData] = useState(null);
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    GetNotificationList();
  }, []);
  const GetNotificationList = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
        startbatch: login.fld_startbatch,
      },
      "Get_ScholarNotificationListById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setrows(
            obj.data.sort(function (a, b) {
              return new Date(b.fld_updatedOn) - new Date(a.fld_updatedOn);
            })
          );
          Notiflix.Loading.remove();
        }
      })
    );
  };
  return (
    <>
      <div className="form-head">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Notification List</h4>
        </div>
      </div>
      <div className="card-vahani p-0">
        <p className="personal-info left-line-card p-4 ">Notification List</p>
        <div className="px-4">
          {rows.length > 0 ? (
            <MDBDataTable
              disableRetreatAfterSorting={true}
              responsive
              hover
              striped
              data={{
                columns: [
                  {
                    label: "Title",
                    field: "title",
                    sort: "disabled",
                  },
                  {
                    label: "Sent To",
                    field: "sent",
                    sort: "disabled",
                  },
                  {
                    label: "Received On",
                    field: "create",
                    sort: "disabled",
                  },
                  {
                    label: "View",
                    field: "view",
                    sort: "disabled",
                  },
                ],
                rows: rows.map((row, i) => {
                  return {
                    title: row.fld_title,
                    sent: row.fld_type,
                    create: moment(row.fld_updatedOn).format("DD/MM/YYYY"),
                    view: (
                      <VisibilityIcon
                        className="cursor-pointer"
                        color="primary"
                        onClick={() => {
                          setshowModal(true);
                          setSelectData(row);
                        }}
                      />
                    ),
                  };
                }),
              }}
              entriesOptions={[10, 25, 50, 100]}
            />
          ) : (
            <p className="m-3">No Data Found</p>
          )}
          <Dialog
            open={showModal}
            maxWidth={"md"}
            onClose={() => setshowModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              className="d-flex justify-content-between align-items-center"
              id="alert-dialog-title"
            >
              <h2>Notification Detail</h2>
              <CancelIcon
                className="cursor-pointer ms-3"
                onClick={() => setshowModal(false)}
              />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div
                  className="card-vahani text-white "
                  style={{
                    backgroundImage:
                      "url('https://media.istockphoto.com/photos/cork-board-background-picture-id848498476?b=1&k=20&m=848498476&s=170667a&w=0&h=jPpRWw52eQxqOg4-ygMEekauGcoY0ye-UlrY-SFJsBQ=')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <h1 className="mb-5">{SelectData?.fld_title}</h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: SelectData?.fld_description,
                    }}
                  />
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  );
}
