import React, { Component } from "react";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import { Edit } from "react-feather";
import { Link } from "react-router-dom";
import GetApiCall from "../../../../assets/API/GetApi";
import PostApiCall from "../../../../assets/API/PostApi";
class Dayoff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,

      UserData: [],
      Id: "",
      searchInput: "",
      Details: [],
      list: [],
      ShowViewModal: null,
      SelectedStatus: "Pending",
      DateData: { from: "", to: "" },
      SortData: {
        type: "desc",
        value: "fld_id",
      },
    };
  }

  componentDidMount() {
    Notiflix.Loading.init({
      svgColor: "rgb(6, 10, 74)",
    });
    Notiflix.Loading.dots("Please wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);

    this.setState({
      Details: details[0],
    });

    GetApiCall.getRequest("GetEmployeeLeaves").then((resultdes) =>
      resultdes.json().then((obj) => {
        var allpendingleave = [];
        obj.data.map((leave, i) => {
          if (leave.fld_status == "Pending") {
            allpendingleave.push(leave);
          }
        });
        this.setState({
          list: allpendingleave,
        });
        Notiflix.Loading.remove();
      })
    );
  }

  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };

  getLeaveList = (status) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause:
          status == "All"
            ? `order by ${this.state.SortData.value} ${this.state.SortData.type}`
            : `where fld_status='${status.toLowerCase()}' ${this.state.DateData.from != "" && this.state.DateData.to != ""
              ? `and fld_raised_date between '${this.state.DateData.from}' and '${this.state.DateData.to}' `
              : ""
            } order by ${this.state.SortData.value} ${this.state.SortData.type
            }`,
      },
      "GetFilterLeave"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({ list: obj.data });
          Notiflix.Loading.remove();
        }
        Notiflix.Loading.remove();
      })
    );
  };
  render() {
    return (
      <>
        <div className="form-head mb-lg-2 mb-3">
          <div
            className="col-auto "
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Leave List</h4>
          </div>
        </div>
        <div className="card-vahani p-0 mt-2">
          <div className="row m-0 justify-content-between align-items-center left-line-card px-4 pt-3  ">
            <div className="personal-info m-0 p-0 col-auto">
              Leave Application's{" "}
            </div>
            <div className="col-md-6 col-12 mb-3 ms-auto">
              <div className="row justify-content-end">
                <div className="col-lg-5 col-lg-5 mb-lg-0 mb-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Status"
                      value={this.state.SelectedStatus}
                      onChange={(e) => {
                        this.setState({ SelectedStatus: e.target.value });
                        this.getLeaveList(e.target.value);
                      }}
                    >
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Approved">Approved</MenuItem>
                      <MenuItem value="Rejected">Rejected</MenuItem>
                      <MenuItem value="All">List All</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-4 col-lg-4 mb-lg-0 my-2">
                  <Button variant="contained" className="w-100">
                    <Link to="/leaveapplications" className="text-white">
                      Show My Leaves
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-0 justify-content-between border-top p-2 ">
            <div className="col-xl-auto col-12 mt-3">
              <TextField
                type="date"
                label="From Date"
                id="outlined-size-small"
                size="small"
                value={this.state.DateData.from}
                onChange={(e) =>
                  this.setState({
                    DateData: { ...this.state.DateData, from: e.target.value },
                  })
                }
                InputLabelProps={{ shrink: true }}
                className="mb-md-0 mb-3 col-sm-auto col-12"
              />
              <TextField
                type="date"
                label="To Date"
                id="outlined-size-small"
                size="small"
                value={this.state.DateData.to}
                onChange={(e) =>
                  this.setState({
                    DateData: { ...this.state.DateData, to: e.target.value },
                  })
                }
                InputLabelProps={{ shrink: true }}
                className="mx-sm-3 mx-0 mb-md-0 mb-3 col-sm-auto col-12"
              />
              <Button
                className="col-sm-auto col-12"
                variant="contained"
                onClick={() => {
                  if (this.state.DateData.from != "") {
                    if (this.state.DateData.to != "") {
                      this.getLeaveList(this.state.SelectedStatus);
                    } else {
                      Notiflix.Notify.failure("Enter To date");
                    }
                  } else {
                    Notiflix.Notify.failure("Enter From date");
                  }
                }}
              >
                Filter
              </Button>
            </div>
            <div className="col-xl-auto col-12 row m-0 mt-3">
              <div className="col-sm-auto mb-sm-0 mb-2 col-12 p-0">
                <select
                  class="form-select form-select-sm "
                  aria-label=".form-select-sm example"
                  onChange={(e) =>
                    this.setState({
                      SortData: {
                        ...this.state.SortData,
                        value: e.target.value,
                      },
                    })
                  }
                >
                  <option value="fld_id">Sort By</option>
                  <option value="fld_emp_id">Employee ID</option>
                  <option value="fld_employee_name">Name</option>
                  <option value="fld_leave_type">Leave Type</option>
                  <option value="fld_leave_days">No. of Days</option>
                  <option value="fld_raised_date">Raised On</option>
                  <option value="fld_status">Status</option>
                </select>
              </div>
              <div className="col-sm-auto col-12 p-0 mx-sm-3 mx-0 mb-sm-0 mb-3 ">
                <select
                  class="form-select form-select-sm "
                  aria-label=".form-select-sm example"
                  onChange={(e) =>
                    this.setState({
                      SortData: {
                        ...this.state.SortData,
                        type: e.target.value,
                      },
                    })
                  }
                >
                  <option value="desc">Order</option>
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending </option>
                </select>
              </div>
              <Button
                className="col-sm-auto col-12 mb-sm-0 mb-3 "
                variant="contained"
                onClick={() => {
                  this.getLeaveList(this.state.SelectedStatus);
                }}
              >
                Sort
              </Button>
            </div>
          </div>
          <div className="p-3">
            {this.state.list.length > 0 ? (
              <MDBDataTable
                disableRetreatAfterSorting={true}
                responsive
                hover
                //   striped
                data={{
                  columns: [
                    {
                      label: "S.No.",
                      field: "serial",
                      sort: "disabled",
                      width: 150,
                    },
                    {
                      label: "Employee Id",
                      field: "empId",
                      sort: "disabled",
                      width: 400,
                    },
                    {
                      label: "Employee Name",
                      field: "name",
                      sort: "disabled",
                      width: 400,
                    },

                    {
                      label: "Leave Type",
                      field: "type",
                      sort: "disabled",
                      width: 150,
                    },

                    {
                      label: "Total Days",
                      field: "days",
                      sort: "disabled",
                      width: 150,
                    },
                    {
                      label: "Start Date",
                      field: "startdate",
                      sort: "disabled",
                      width: 100,
                    },
                    {
                      label: "End Date",
                      field: "enddate",
                      sort: "disabled",
                      width: 100,
                    },
                    {
                      label: "Raised On",
                      field: "raiseddate",
                      sort: "disabled",
                      width: 100,
                    },
                    {
                      label: "Status",
                      field: "status",
                      sort: "disabled",
                      width: 100,
                    },
                    {
                      label: "Action",
                      field: "action",
                      sort: "disabled",
                      width: 100,
                    },
                  ],
                  rows: this.state.list.map((data, i) => {
                    return {
                      serial: i + 1,
                      empId: data.fld_emp_id,
                      name: data.fld_employee_name,
                      type: data.fld_leave_type,
                      days: data.fld_leave_days,
                      startdate: moment(data.fld_star_date).format(
                        "DD-MM-YYYY"
                      ),
                      enddate: moment(data.fld_end_date).format("DD-MM-YYYY"),
                      raiseddate: moment(data.fld_raised_date).format(
                        "DD-MM-YYYY"
                      ),
                      status: data.fld_status,
                      action: (
                        <Link
                          to={{
                            pathname: `/update-dayoff`,
                            state: {
                              leaveid: data.fld_id,
                            },
                          }}
                        >
                          <Edit />
                        </Link>
                      ),
                    };
                  }),
                }}
                entriesOptions={[10, 25, 50, 100]}
              />
            ) : (
              <p className="m-3">No Data Found</p>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default Dayoff;
