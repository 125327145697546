import React from "react";
import Notiflix from "notiflix";
import PostApiCall from "../../../assets/API/PostApi";
import GetApiCall from "../../../assets/API/GetApi";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  TableBody,
  Table,
  Paper,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  RadioGroup,
  InputLabel,
  MenuItem,
  Select,
  Radio,
  Button,
  useTheme,
  OutlinedInput,
  Box,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { MDBDataTable, MDBTableHead } from "mdbreact";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { arrayIncludes } from "@mui/x-date-pickers/internals/utils/utils";
import { CSVLink } from "react-csv";
// import BatchwiseReport from './BatchwiseReport';
const MenuProps = {
  PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } },
};
const ReportList = () => {
  const [ReportDropdown, setReportDropdown] = React.useState([]);
  const [ReportColumns, setReportColumns] = React.useState([]);
  const [ListAll, setListAll] = React.useState(false);
  const [SortData, setSortData] = React.useState({
    type: "desc",
    value: "fld_id",
  });
  const [ApiType, setApiType] = React.useState("");
  const [listType, setlistType] = React.useState([]);
  const [FilterForm, setFilterForm] = React.useState({
    name: "",
    comapre: "",
    value: "",
  });

  const [FilterList, setFilterList] = React.useState([]);
  const [ReportList, setReportList] = React.useState([]);
  const theme = useTheme();
  const [ColumnList, setColumnList] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setColumnList(typeof value === "string" ? value.split(",") : value);
  };
  React.useEffect(() => {
    GetApiCall.getRequest("Get_Report_Master_Dropdown").then((resultdes) =>
      resultdes.json().then((obj) => {
        setReportDropdown(obj.data);
      })
    );
  }, []);
  //

  // let scholarlist = ReportList.filter(
  //   (ele, ind) =>
  //     ind ===
  //     ReportList.findIndex(
  //       (elem) =>
  //         elem.fld_studentId === ele.fld_studentId &&
  //         elem.fld_preference === ele.fld_preference
  //     )
  // );
  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto"
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Report Master</h4>
        </div>
      </div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-1"
          id="panel-1"
        >
          <Typography>Create Report</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl className="">
            <FormLabel id="demo-row-radio-buttons-group-label">
              Select Report Type
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              {ReportDropdown.map((val) => {
                return (
                  <FormControlLabel
                    value={val.FLD_db_view}
                    onChange={(e) => {
                      setApiType(e.target.value);
                      PostApiCall.postRequest(
                        {
                          whereClause: `where FLD_report_id=${val.FLD_id} order by Fld_column_display_name desc`,
                        },
                        "Get_Report_Master_Column"
                      ).then((results) =>
                        results.json().then((obj) => {
                          if (
                            results.status === 200 ||
                            results.status === 201
                          ) {
                            setReportColumns(obj.data);
                            setColumnList([]);
                            setFilterList([]);
                            setReportList([]);
                            //
                            Notiflix.Loading.remove();
                          }
                        })
                      );
                    }}
                    control={<Radio />}
                    label={val.FLD_report_name}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth className="mt-5">
            <InputLabel id="demo-multiple-chip-label">
              Select Column Field
            </InputLabel>
            <Select
              multiple
              value={ColumnList}
              onChange={handleChange}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Select Column Field"
                />
              }
              renderValue={(selected) => {
                //
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip label={value.split("#")[0]} />
                    ))}
                  </Box>
                );
              }}
              MenuProps={MenuProps}
            >
              {ReportColumns.map((val) => (
                <MenuItem
                  value={`${val.Fld_column_display_name}#${val.FLD_column_field_name}`}
                >
                  {" "}
                  {val.Fld_column_display_name}{" "}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TableContainer component={Paper} className="mt-5">
            <Table aria-label="simple table">
              <TableHead className="tablesimple-head">
                <TableRow>
                  <TableCell className="tablecell-head">Column Name</TableCell>
                  <TableCell className="tablecell-head">Compare</TableCell>
                  <TableCell className="tablecell-head">Enter Value</TableCell>
                  <TableCell className="tablecell-head">Add</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <select
                      id="inputState"
                      class="form-select"
                      name="name"
                      onChange={(e) =>
                        setFilterForm({
                          ...FilterForm,
                          displayName: e.target.value.split("#")[0],
                          [e.target.name]: e.target.value.split("#")[1],
                        })
                      }
                    >
                      <option value="">choose...</option>
                      {ColumnList.length > 0
                        ? ColumnList.map((val) => (
                          <option
                            selected={
                              FilterForm.displayName == val.split("#")[0]
                            }
                            value={val}
                          >
                            {val.split("#")[0]}
                          </option>
                        ))
                        : ReportColumns.map((val) => (
                          <option
                            selected={
                              FilterForm.displayName ==
                              val.Fld_column_display_name
                            }
                            value={`${val.Fld_column_display_name}#${val.FLD_column_field_name}`}
                          >
                            {val.Fld_column_display_name}
                          </option>
                        ))}
                    </select>
                  </TableCell>
                  <TableCell>
                    <select
                      id="inputState"
                      class="form-select"
                      name="comapre"
                      value={FilterForm.comapre}
                      onChange={(e) =>
                        setFilterForm({
                          ...FilterForm,
                          displayCompare:
                            e.target.value == "="
                              ? "Equal To"
                              : e.target.value == "<"
                                ? "Smaller than"
                                : e.target.value == ">"
                                  ? "Greater than"
                                  : e.target.value == "LIKE"
                                    ? "Includes"
                                    : e.target.value == "from"
                                      ? "From Date"
                                      : e.target.value == "to"
                                        ? "To Date"
                                        : "",
                          [e.target.name]: e.target.value,
                        })
                      }
                    >
                      <option value="">choose...</option>
                      <option value="=">Equal to</option>
                      <option value="<">Smaller than</option>
                      <option value=">">Greater than</option>
                      <option value="LIKE">Includes</option>
                      <option value="from">From Date</option>
                      <option value="to"> To Date</option>
                    </select>
                  </TableCell>
                  <TableCell>
                    {FilterForm.comapre == "from" ||
                      FilterForm.comapre == "to" ? (
                      <input
                        type="date"
                        class="form-control"
                        name="value"
                        value={FilterForm.value}
                        onChange={(e) =>
                          setFilterForm({
                            ...FilterForm,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <input
                        type="text"
                        class="form-control"
                        name="value"
                        value={FilterForm.value}
                        onChange={(e) =>
                          setFilterForm({
                            ...FilterForm,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <AddCircleIcon
                      color="primary"
                      onClick={() => {
                        if (
                          FilterForm.column != "" &&
                          FilterForm.comapre != "" &&
                          FilterForm.value != ""
                        ) {
                          // UpdateExpenseFormField()
                          setFilterList([...FilterList, FilterForm]);
                          setFilterForm({ name: "", comapre: "", value: "" });
                        } else {
                          Notiflix.Notify.failure("Please Fill Filter.");
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
                {FilterList.map((val) => {
                  return (
                    <TableRow>
                      <TableCell>{val.displayName}</TableCell>
                      <TableCell>{val.displayCompare}</TableCell>
                      <TableCell>{val.value}</TableCell>
                      <TableCell
                        onClick={() =>
                          setFilterList([
                            ...FilterList.filter(
                              (item) => item.name != val.name
                            ),
                          ])
                        }
                      >
                        <DeleteForeverIcon style={{ color: "red" }} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="row  m-0 justify-content-end mt-3 ">
            <button
              className="col-auto btn btn-primary"
              onClick={() => {
                let whereClause = FilterList.map((val) => {
                  //
                  if (val.comapre == "LIKE") {
                    return `UPPER(trim(${val.name})) ${val.comapre} UPPER(trim('%${val.value}%'))`;
                  } else if (val.comapre == "from") {
                    return `convert(varchar,cast(${val.name} as date), 120) between '${val.value}'`;
                  } else if (val.comapre == "to") {
                    return `'${val.value}'`;
                  } else {
                    return `UPPER(trim(${val.name})) ${val.comapre} UPPER(trim('${val.value}'))`;
                  }
                }).join(" AND ");
                //
                Notiflix.Loading.dots();
                PostApiCall.postRequest(
                  {
                    value: `select ${ColumnList.length > 0
                      ? ColumnList.map((val) => val.split("#")[1]).join(",")
                      : ReportColumns.map(
                        (val) => val.FLD_column_field_name
                      ).join(",")
                      } from ${ApiType} ${whereClause == "" ? "" : `where ${whereClause}`
                      }`,
                  },
                  "Get_Report"
                ).then((results) =>
                  results.json().then((obj) => {
                    if (results.status === 200 || results.status === 201) {
                      //
                      setColumnList(
                        ColumnList.length > 0
                          ? ColumnList
                          : ReportColumns.map(
                            (val) =>
                              `${val.Fld_column_display_name}#${val.FLD_column_field_name}`
                          )
                      );
                      setReportList(obj.data);
                      Notiflix.Loading.remove();
                    }
                    Notiflix.Loading.remove();
                  })
                );
              }}
            >
              Get Report
            </button>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className="mt-3">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-2"
          id="panel-2"
        >
          <Typography>Report List</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row m-0 border-top pt-2 justify-content-end ">
            <div className="col-sm-3 col-6">
              <select
                class="form-select form-select-sm "
                aria-label=".form-select-sm example"
                onChange={(e) =>
                  setSortData({ ...SortData, value: e.target.value })
                }
              >
                <option value="fld_id">Sort By</option>
                {ColumnList.map((val) => (
                  <option value={val.split("#")[1]}>{val.split("#")[0]}</option>
                ))}
              </select>
            </div>
            <div className="col-sm-2 col-6">
              <select
                class="form-select form-select-sm "
                aria-label=".form-select-sm example"
                onChange={(e) =>
                  setSortData({ ...SortData, type: e.target.value })
                }
              >
                <option value="desc">Order</option>
                <option value="asc">Ascending</option>
                <option value="desc">Descending </option>
              </select>
            </div>
            <Button
              className="col-sm-auto mt-sm-0 ms-2 mt-2 col-12"
              variant="contained"
              onClick={() => {
                let whereClause = FilterList.map((val) => {
                  if (val.comapre == "LIKE") {
                    return `UPPER(trim(${val.name})) ${val.comapre} UPPER(trim('%${val.value}%'))`;
                  } else {
                    return `UPPER(trim(${val.name})) ${val.comapre} UPPER(trim('${val.value}'))`;
                  }
                }).join(" AND ");
                Notiflix.Loading.dots();
                PostApiCall.postRequest(
                  {
                    value: `select ${ColumnList.length > 0
                      ? ColumnList.map((val) => val.split("#")[1]).join(",")
                      : ReportColumns.map(
                        (val) => val.FLD_column_field_name
                      ).join(",")
                      } from ${ApiType} ${whereClause == ""
                        ? `order by ${SortData.value} ${SortData.type}`
                        : `where ${whereClause} order by ${SortData.value} ${SortData.type}`
                      }`,
                  },
                  "Get_Report"
                ).then((results) =>
                  results.json().then((obj) => {
                    if (results.status === 200 || results.status === 201) {
                      //
                      setColumnList(
                        ColumnList.length > 0
                          ? ColumnList
                          : ReportColumns.map(
                            (val) =>
                              `${val.Fld_column_display_name}#${val.FLD_column_field_name}`
                          )
                      );
                      setReportList(obj.data);
                      Notiflix.Loading.remove();
                    }
                    Notiflix.Loading.remove();
                  })
                );
              }}
            >
              Sort
            </Button>
            <Button
              variant="contained"
              className="text-white ms-auto col-sm-auto col-12 mt-sm-0 mt-2"
            >
              <CSVLink
                className="text-white"
                filename={"Report-Mater-List.csv"}
                data={ReportList}
              >
                Download List{" "}
                <span style={{ textTransform: "lowercase" }}>(.csv)</span>{" "}
              </CSVLink>
            </Button>
          </div>
          {ReportList.length > 0 ? (
            <MDBDataTable
              disableRetreatAfterSorting={true}
              responsive
              hover
              striped
              data={{
                columns: ColumnList.map((val) => ({
                  label: val.split("#")[0],
                  field: val.split("#")[1],
                })),
                rows: ReportList.map((val) => val),
              }}
              entriesOptions={[10, 25, 50, 100]}
            ></MDBDataTable>
          ) : (
            <p className="m-3">No Data Found</p>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default ReportList;
{
  /* <TableContainer component={Paper} >
<Table class="table-responsive" aria-label="simple table">
    <TableHead className="tablesimple-head" >
        <TableRow>
            {ColumnList.map(val => <TableCell className="tablecell-head" >{val.split("#")[0]}</TableCell>)}
        </TableRow>
    </TableHead>
    <TableBody>
        {ReportList.map(val => {
            //
            return (
                <TableRow>
                    {Object.entries(val).map(item => {
                        let x = ColumnList.find(data => data.split("#")[1] == item[0])
                        if (x) {
                            return (<TableCell>{item[1]}</TableCell>)
                        }
                    })}
                </TableRow>
            )
        })}
    </TableBody>
</Table>
</TableContainer> */
}
