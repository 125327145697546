// import "../../../Components/MainSection/Mainsection";
import React, { useState, useContext, useEffect } from "react";
import {
  LinearProgress,
  Breadcrumbs,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Scholar from "../../../assets/Image/scholar.png";
import Scholar2 from "../../../assets/Image/scholar2.png";
import "./Dashboard.css";
import CancelIcon from "@mui/icons-material/Cancel";
import PostApiCall from "../../../assets/API/PostApi";
import "../../../Components/Adminpanel/Slider/Slider.css";
import Cardwhite from "../../../Components/Adminpanel/Mainsection/maincontent/Card/cardwhite";
import Cardcolor from "../../../Components/Adminpanel/Mainsection/maincontent/Card/Cardcolor";
import store from "../../../store";
import Table from "../../../Components/Adminpanel/Mainsection/maincontent/Table/Tablesimple";
import Donutsg from "../../../Components/Adminpanel/Mainsection/maincontent/Chart/donut";
import Arealine from "../../../Components/Adminpanel/Mainsection/maincontent/Chart/Arealine";
import Bar from "../../../Components/Adminpanel/Mainsection/maincontent/Chart/Bar";
import { HiUsers } from "react-icons/hi";
import Simplepie from "../../../Components/Adminpanel/Mainsection/maincontent/Chart/Simplepie";
import { border, borderRadius } from "@mui/system";
// import personalIcon from '../../../assets/icons/Personal_infocard.svg';
// import familyIcon from '../../../assets/icons/familycard.svg';
// import addressIcon from '../../../assets/icons/addresscard.svg';
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import HomeIcon from "@mui/icons-material/Home";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SchoolIcon from "@mui/icons-material/School";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import BarChartIcon from "@mui/icons-material/BarChart";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import PostAddIcon from "@mui/icons-material/PostAdd";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { useHistory } from "react-router-dom";
import PersonalInfoIcon from "../../../assets/icons/Personal_info.svg";
import FamilyInfoIcon from "../../../assets/icons/family_info.svg";
import AddressIcon from "../../../assets/icons/address.svg";
import PersonalBankIcon from "../../../assets/icons/personal_bank.svg";
import ParentBankIcon from "../../../assets/icons/parent_bank.svg";
import AcademicInfoIcon from "../../../assets/icons/academic_info.svg";
import AcademicAcheivement from "../../../assets/icons/academic_achievement.svg";
import EssayIcon from "../../../assets/icons/essay_icon.svg";
import CollegePrefIcon from "../../../assets/icons/college_icon.svg";
import ExtraIcon from "../../../assets/icons/extra_curricular_icon.svg";
import AdditionalIcon from "../../../assets/icons/additional_applicant.svg";
export default function Dashboard() {
  const history = useHistory();
  const [personalInfo, setPersonalInfo] = useState({});
  const [familyInfo, setFamilyInfo] = useState({});
  const [address, setAddress] = useState({});
  const [personalBankAccount, setPersonalBankAccount] = useState({});
  const [parentsBankAccount, setParentsBankAccount] = useState({});
  const [school, setSchool] = useState({});
  const [academicAchievements, setAcademicAchievements] = useState({});
  const [extraactivities, setextraactivities] = useState({});
  const [AdditionalApplicant, setAdditionalApplicant] = useState({});
  const [StudentEssay, setStudentEssay] = useState({});
  const [CollegePreferenceStatus, setCollegePreferenceStatus] = useState({});
  const [VideoToggle, setVideoToggle] = useState(
    localStorage.getItem("Video_Popup") == null
      ? false
      : Boolean(localStorage.getItem("Video_Popup") == "true")
  );
  var login = JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const { drawerWidth, Showdraw, setShowdraw } = useContext(store);
  const showdrawer = () => {
    setShowdraw(!Showdraw);
  };
  // personal info
  useEffect(() => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentPersonalStatus_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setPersonalInfo({ personalInfo: obj.data[0].fld_formStatus });
        }
      })
    );
  }, []);
  // family info
  useEffect(() => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentFamilyStatus_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //
          //
          setFamilyInfo({
            familyInfo: obj.data[0].fld_formStatus,
          });
        }
      })
    );
  }, []);
  //  address info
  useEffect(() => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentAddressStatus_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setAddress({
            address: obj.data[0].fld_formStatus,
          });
        }
      })
    );
  }, []);
  // personal bank account
  useEffect(() => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentPersonalBankStatus_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setPersonalBankAccount({
            personalBankAccount: obj.data[0].fld_formStatus,
          });
        }
      })
    );
  }, []);
  // parent bank account
  useEffect(() => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentParentBankStatus_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setParentsBankAccount({
            parentsBankAccount: obj.data[0].fld_formStatus,
          });
        }
      })
    );
  }, []);
  // college bank account
  //school and academic
  useEffect(() => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentSchoolAndAcademicStatus_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //
          setSchool({
            school: obj.data[0]?.fld_formStatus,
          });
        }
      })
    );
  }, []);
  // Academic Achievements
  useEffect(() => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentAcademicAchievmentStstus_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setAcademicAchievements({
            academicAchievements: obj.data[0]?.fld_formStatus,
          });
        }
      })
    );
  }, []);
  // ExtraCurricularActivities
  useEffect(() => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentExtraCurricularActivitiesStatus_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setextraactivities({
            extraactivities: obj.data[0]?.fld_formStatus,
          });
        }
      })
    );
  }, []);
  // AdditionalApplicant
  useEffect(() => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentAdditionalApplicantStatus_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setAdditionalApplicant({
            AdditionalApplicant: obj.data[0]?.fld_formStatus,
          });
        }
      })
    );
  }, []);
  // CollegePreferenceStatus
  useEffect(() => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentCollegePreferenceStatus_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setCollegePreferenceStatus({
            CollegePreferenceStatus: obj.data[0]?.fld_formStatus,
          });
        }
      })
    );
  }, []);
  // StudentEssay
  useEffect(() => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentEssayStatus_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setStudentEssay({ StudentEssay: obj.data[0]?.fld_formStatus });
        }
      })
    );
  }, []);
  return (
    <>
      <div>
        <div className="main-top-left-cont">
          {/* <h4>Hi {login.full_name}, Welcome back! </h4> */}
          <div className="form-head">
            <div
              style={{
                borderLeft: "10px solid rgb(32, 101, 209)",
                paddingLeft: "10px",
              }}
            >
              <h4>Hi {login.full_name}, Welcome back!</h4>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="text.primary"
                  href="/dashboard"
                  aria-current="page"
                >
                  Dashboard
                </Link>
              </Breadcrumbs>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 mt-2" style={{ paddingTop: "28px" }}>
        <div
          className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-0"
          onClick={() => history.push("/personalinformation")}
        >
          <div className=" card-hover p-3">
            <div className="row m-0 justify-content-between align-items-start ">
              <div className="col-auto px-0">
                <h6 className="personal-info p-0">Personal Information</h6>
                <p className="text-start fs-6" style={{ color: "#334151" }}>
                  {personalInfo.personalInfo ?? "Incomplete"}
                </p>
              </div>
              <div className="col-auto px-0">
                {/* <AccountBalanceIcon style={{ fontSize: "1.5em", color: "#1976D2" }} /> */}
                <img src={PersonalInfoIcon} className="dash-card-icon" />
              </div>
            </div>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={parentsBankAccount.parentsBankAccount ? 100 : 0}
              style={{ height: "7px", borderRadius: "20px" }}
            />
            <div className="d-flex justify-content-between text-secondary">
              <span className="profile-percentage">0%</span>{" "}
              <span className="profile-percentage">100%</span>
            </div>
          </div>
        </div>
        <div
          className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-0"
          onClick={() => history.push("/familyinformation")}
        >
          <div className=" card-hover p-3">
            <div className="row m-0 justify-content-between align-items-start ">
              <div className="col-auto px-0">
                <h6 className="personal-info p-0">Family Information</h6>
                <p className="text-start fs-6" style={{ color: "#334151" }}>
                  {familyInfo.familyInfo ?? "Incomplete"}
                </p>
              </div>
              <div className="col-auto px-0">
                {/* <SchoolIcon style={{ fontSize: "1.5em", color: "#ED6C02" }} /> */}
                <img src={FamilyInfoIcon} className="dash-card-icon orange" />
              </div>
            </div>
            <LinearProgress
              variant="determinate"
              color="warning"
              value={school.school ? 100 : 0}
              style={{ height: "7px", borderRadius: "20px" }}
            />
            <div className="d-flex justify-content-between text-secondary">
              <span className="profile-percentage">0%</span>{" "}
              <span className="profile-percentage">100%</span>
            </div>
          </div>
        </div>
        <div
          className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-0"
          onClick={() => history.push("/address")}
        >
          <div className=" card-hover p-3">
            <div className="row m-0 justify-content-between align-items-start ">
              <div className="col-auto px-0">
                <h6 className="personal-info p-0">Address</h6>
                <p className="text-start fs-6" style={{ color: "#334151" }}>
                  {address.address ?? "Incomplete"}
                </p>
              </div>
              <div className="col-auto px-0">
                {/* <EmojiEventsIcon style={{ fontSize: "1.5em", color: "#9C27B0" }} /> */}
                <img src={AddressIcon} className="dash-card-icon purple" />
              </div>
            </div>
            <LinearProgress
              variant="determinate"
              color="secondary"
              value={academicAchievements.academicAchievements ? 100 : 0}
              style={{ height: "7px", borderRadius: "20px" }}
            />
            <div className="d-flex justify-content-between text-secondary">
              <span className="profile-percentage">0%</span>{" "}
              <span className="profile-percentage">100%</span>
            </div>
          </div>
        </div>
        <div
          className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-0"
          onClick={() => history.push("/personal-bank")}
        >
          <div className=" card-hover p-3">
            <div className="row m-0 justify-content-between align-items-start ">
              <div className="col-auto px-0">
                <h6 className="personal-info p-0">Personal Bank Account</h6>
                <p className="text-start fs-6" style={{ color: "#334151" }}>
                  {personalBankAccount.personalBankAccount ?? "Incomplete"}
                </p>
              </div>
              <div className="col-auto px-0">
                {/* <AccountBalanceIcon style={{ fontSize: "1.5em", color: "gray" }} /> */}
                <img src={PersonalBankIcon} className="dash-card-icon gray" />
              </div>
            </div>
            <LinearProgress
              variant="determinate"
              color="inherit"
              value={personalBankAccount.personalBankAccount ? 100 : 0}
              style={{ height: "7px", borderRadius: "20px" }}
            />
            <div className="d-flex justify-content-between text-secondary">
              <span className="profile-percentage">0%</span>{" "}
              <span className="profile-percentage">100%</span>
            </div>
          </div>
        </div>
        <div
          className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-0"
          onClick={() => history.push("/parent-bank")}
        >
          <div className=" card-hover p-3">
            <div className="row m-0 justify-content-between align-items-start ">
              <div className="col-auto px-0">
                <h6 className="personal-info p-0">Parent's Bank Account</h6>
                <p className="text-start fs-6" style={{ color: "#334151" }}>
                  {parentsBankAccount.parentsBankAccount ?? "Incomplete"}
                </p>
              </div>
              <div className="col-auto px-0">
                {/* <AccountBalanceIcon style={{ fontSize: "1.5em", color: "#1976D2" }} /> */}
                <img src={ParentBankIcon} className="dash-card-icon" />
              </div>
            </div>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={parentsBankAccount.parentsBankAccount ? 100 : 0}
              style={{ height: "7px", borderRadius: "20px" }}
            />
            <div className="d-flex justify-content-between text-secondary">
              <span className="profile-percentage">0%</span>{" "}
              <span className="profile-percentage">100%</span>
            </div>
          </div>
        </div>
        <div
          className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-0"
          onClick={() => history.push("/schoolinfo")}
        >
          <div className=" card-hover p-3">
            <div className="row m-0 justify-content-between align-items-start ">
              <div className="col-auto px-0">
                <h6 className="personal-info p-0">Academic Information</h6>
                <p className="text-start fs-6" style={{ color: "#334151" }}>
                  {school.school ?? "Incomplete"}
                </p>
              </div>
              <div className="col-auto px-0">
                {/* <SchoolIcon style={{ fontSize: "1.5em", color: "#ED6C02" }} /> */}
                <img src={AcademicInfoIcon} className="dash-card-icon orange" />
              </div>
            </div>
            <LinearProgress
              variant="determinate"
              color="warning"
              value={school.school ? 100 : 0}
              style={{ height: "7px", borderRadius: "20px" }}
            />
            <div className="d-flex justify-content-between text-secondary">
              <span className="profile-percentage">0%</span>{" "}
              <span className="profile-percentage">100%</span>
            </div>
          </div>
        </div>
        <div
          className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-0"
          onClick={() => history.push("/academic-achievment")}
        >
          <div className=" card-hover p-3">
            <div className="row m-0 justify-content-between align-items-start ">
              <div className="col-auto px-0">
                <h6 className="personal-info p-0">Academic Achievement</h6>
                <p className="text-start fs-6" style={{ color: "#334151" }}>
                  {academicAchievements.academicAchievements ?? "Incomplete"}
                </p>
              </div>
              <div className="col-auto px-0">
                <img
                  src={AcademicAcheivement}
                  className="dash-card-icon purple"
                />
              </div>
            </div>
            <LinearProgress
              variant="determinate"
              color="secondary"
              value={academicAchievements.academicAchievements ? 100 : 0}
              style={{ height: "7px", borderRadius: "20px" }}
            />
            <div className="d-flex justify-content-between text-secondary">
              <span className="profile-percentage">0%</span>{" "}
              <span className="profile-percentage">100%</span>
            </div>
          </div>
        </div>
        <div
          className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-0"
          onClick={() => history.push("/university-college-preference")}
        >
          <div className=" card-hover p-3">
            <div className="row m-0 justify-content-between align-items-start ">
              <div className="col-auto px-0">
                <h6 className="personal-info p-0">College Preference</h6>
                <p className="text-start fs-6" style={{ color: "#334151" }}>
                  {CollegePreferenceStatus.CollegePreferenceStatus ??
                    "Incomplete"}
                </p>
              </div>
              <div className="col-auto px-0">
                {/* <BarChartIcon style={{ fontSize: "1.5em", color: "gray" }} /> */}
                <img src={CollegePrefIcon} className="dash-card-icon gray" />
              </div>
            </div>
            <LinearProgress
              variant="determinate"
              color="inherit"
              value={CollegePreferenceStatus.CollegePreferenceStatus ? 100 : 0}
              style={{ height: "7px", borderRadius: "20px" }}
            />
            <div className="d-flex justify-content-between text-secondary">
              <span className="profile-percentage">0%</span>{" "}
              <span className="profile-percentage">100%</span>
            </div>
          </div>
        </div>
        <div
          className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-0"
          onClick={() => history.push("/extra-curricular-activities")}
        >
          <div className=" card-hover p-3">
            <div className="row m-0 justify-content-between align-items-start ">
              <div className="col-auto px-0">
                <h6 className="personal-info p-0">Extra Activities</h6>
                <p className="text-start fs-6" style={{ color: "#334151" }}>
                  {extraactivities.extraactivities ?? "Incomplete"}
                </p>
              </div>
              <div className="col-auto px-0">
                {/* <LocalActivityIcon style={{ fontSize: "1.5em", color: "#1976D2" }} /> */}
                <img src={ExtraIcon} className="dash-card-icon" />
              </div>
            </div>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={extraactivities.extraactivities ? 100 : 0}
              style={{ height: "7px", borderRadius: "20px" }}
            />
            <div className="d-flex justify-content-between text-secondary">
              <span className="profile-percentage">0%</span>{" "}
              <span className="profile-percentage">100%</span>
            </div>
          </div>
        </div>
        <div
          className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-0"
          onClick={() => history.push("/essay")}
        >
          <div className=" card-hover p-3">
            <div className="row m-0 justify-content-between align-items-start ">
              <div className="col-auto px-0">
                <h6 className="personal-info p-0">Essay</h6>
                <p className="text-start fs-6" style={{ color: "#334151" }}>
                  {StudentEssay.StudentEssay ?? "Incomplete"}
                </p>
              </div>
              <div className="col-auto px-0">
                {/* <NewspaperIcon style={{ fontSize: "1.5em", color: "#9C27B0" }} /> */}
                <img src={EssayIcon} className="dash-card-icon  orange" />
              </div>
            </div>
            <LinearProgress
              variant="determinate"
              color="warning"
              value={StudentEssay.StudentEssay ? 100 : 0}
              style={{ height: "7px", borderRadius: "20px" }}
            />
            <div className="d-flex justify-content-between text-secondary">
              <span className="profile-percentage">0%</span>{" "}
              <span className="profile-percentage">100%</span>
            </div>
          </div>
        </div>
        <div
          className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 cursor-pointer mb-lg-5 mb-4 px-lg-3 px-0"
          onClick={() => history.push("/additional-applicant")}
        >
          <div className=" card-hover p-3">
            <div className="row m-0 justify-content-between align-items-start ">
              <div className="col-auto px-0">
                <h6 className="personal-info p-0">Additional Information</h6>
                <p className="text-start fs-6" style={{ color: "#334151" }}>
                  {AdditionalApplicant.AdditionalApplicant ?? "Incomplete"}
                </p>
              </div>
              <div className="col-auto px-0">
                {/* <PostAddIcon style={{ fontSize: "1.5em", color: "#ED6C02" }} /> */}
                <img src={AdditionalIcon} className="dash-card-icon purple" />
              </div>
            </div>
            <LinearProgress
              variant="determinate"
              color="secondary"
              value={AdditionalApplicant.AdditionalApplicant ? 100 : 0}
              style={{ height: "7px", borderRadius: "20px" }}
            />
            <div className="d-flex justify-content-between text-secondary">
              <span className="profile-percentage">0%</span>{" "}
              <span className="profile-percentage">100%</span>
            </div>
          </div>
        </div>
      </div>
      {/* comment out when new video is avaiable */}
      {/* <Dialog
        open={VideoToggle}
        PaperProps={{
          sx: {
            width: "100%",
            height: "auto",
          },
        }}
        onClose={() => {
          setVideoToggle(false);
          localStorage.setItem("Video_Popup", false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       >
        <DialogTitle
          className="d-flex justify-content-between"
          id="alert-dialog-title"
        >
          <h6>Vahani Scholarship Application Tutorial</h6>{" "}
          <CancelIcon
            className="cursor-pointer"
            onClick={() => {
              setVideoToggle(false);
              localStorage.setItem("Video_Popup", false);
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div>
            <iframe
              style={{ aspectRatio: "16/9", width: "100%" }}
              width="100%"
              height="400px"
              src="https://www.youtube.com/embed/gRqcuHfcDLo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </DialogContent>
      </Dialog> */}
    </>
  );
}
