import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";

import { Button, TextField } from "@mui/material";
import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import PostApiCall from "../../../assets/API/PostApi";
import { FaEdit } from "react-icons/fa";

export default function ProjectList() {
  const [addProjectData, setAddProjectData] = useState([]); // state to handle api data
  const [ProjectData, setProjectData] = useState([]);
  const [dateData, setdateData] = React.useState({ from: "", to: "" });
  const [ConditionType, setConditionType] = useState("On Hold");

  const login = JSON.parse(localStorage.getItem("LoginDetail"))[0]
  const [SortData, setSortData] = React.useState({
    type: "desc",
    value: "fld_id",
  });

  useEffect(() => {
    Notiflix.Loading.init({
      svgColor: "rgb(6, 10, 74)",
    });
    Notiflix.Loading.dots("Please wait...");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });


    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetProjectsMaster"
      // "GetProjectsTasks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setAddProjectData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Notify.failure("error occurred!");
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);

  const data = {
    columns: [
      {
        label: "S.No.",
        field: "serial",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Project Name",
        field: "projectName",
        sort: "disabled",
        width: 400,
      },
      {
        label: "City",
        field: "city",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Start Date",
        field: "startDate",
        sort: "disabled",
        width: 150,
      },
      {
        label: "End Date",
        field: "endDate",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Created By",
        field: "createdby",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Updated By",
      //   field: "UpdatedBy",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Action",
        field: "action",
        width: 150,
        sort: "asc",
      },
    ],
    rows: addProjectData.map((data, i) => {
      return {
        serial: i + 1,
        projectName: data.fld_name,
        city: data.fld_city,
        startDate: moment(data.fld_start_date).format("DD-MM-YYYY"),
        endDate: moment(data.fld_end_date).format("DD-MM-YYYY"),
        description: data.fld_desc,
        reason: data.fld_reason,
        status: data.fld_status,
        createdby: data.created_by_name,
        //UpdatedBy: data.updated_by_name,
        action: (

          <div className="d-flex">
            {/* Edit a project */}
            <Link
              className="m-auto"
              to={{
                pathname: `/add-project/${data.fld_id}`,
                state: { projectData: data }, // sending data as a state
              }}
            >
              <FaEdit className="edit-icon edit-icon-font-size" />
            </Link>
            {data.fld_task_count > 0 ? (
              <Link
                to={{
                  pathname: `/task-list/${data.fld_id}`,
                  state: { projectData: data }, // sending data as a state
                }}
                className="task-btn-color ms-2"
              >
                View Tasks
              </Link>
            ) : (
              <Link
                to={{
                  pathname: `/task-list/${data.fld_id}`,
                  state: { projectData: data }, // sending data as a state
                }}
                className="task-btn-color ms-2"
              >
                Add Tasks
              </Link>
            )}
          </div>
        ),
      };
    }),
  };

  const getProjectList = (condition) => {
    PostApiCall.postRequest(
      {
        whereClause:
          dateData.from == "" && dateData.to == ""
            ? `order by ${SortData.value} ${SortData.type}`
            : // : `where fld_status='${condition}' ${dateData.from != "" && dateData.to != ""
            `where fld_created_on between '${dateData.from}' and '${dateData.to
            }' ${dateData.from != "" && dateData.to != ""
              ? `and fld_start_date between '${dateData.from}' and '${dateData.to}' `
              : ""
            } order by ${SortData.value} ${SortData.type}`,
      },
      "GetProjectsMaster"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setAddProjectData(obj.data);
        }
      })
    );
  };
  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Project List </h4>
        </div>
      </div>
      <div className="card-vahani p-0 mt-2">
        <div className="personal-info left-line-card row m-0 align-items-lg-center justify-content-between px-2 py-4 mb-2 w-100">
          <div className="col-8 col-md-10 mb-2 mb-md-0">Projects</div>
          {login.fld_type != "Accountant" && (
            <Link
              to="/add-project"
              className="col-4 col-md-2 mb-sm-0 text-white text-end"
            >
              <Button variant="contained">Add New Project</Button>
            </Link>
          )
          }
        </div>
        <div className="personal-info m-0 ps-md-1 pe-md-3 pb-0 d-lg-flex align-items-center justify-content-between">
          <div className="col-xxl-6 col-12 mt-3">
            <div className="row px-0">
              <TextField
                type="date"
                label="From Date"
                id="outlined-size-small"
                size="small"
                value={dateData.from}
                onChange={(e) =>
                  setdateData({ ...dateData, from: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className="mb-3 mb-md-0 px-2 col-md-4"
              />
              <TextField
                type="date"
                label="To Date"
                id="outlined-size-small"
                size="small"
                value={dateData.to}
                onChange={(e) =>
                  setdateData({ ...dateData, to: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className="px-2 col-md-4 mb-3 mb-md-0"
              />
              <div className="px-2 col-md-2">
                <Button
                  className="sort-filter-size w-100"
                  variant="contained"
                  onClick={() => {
                    if (dateData.from != "") {
                      if (dateData.to != "") {
                        getProjectList(ConditionType);
                      } else {
                        Notiflix.Notify.failure("Enter To date");
                      }
                    } else {
                      Notiflix.Notify.failure("Enter From date");
                    }
                  }}
                >
                  Filter
                </Button>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 px-lg-0 col-12 mt-3 px-0">
            <div className="row m-0 px-0 align-items-lg-start justify-content-lg-end">
              <div className="col-sm-3 mb-sm-0 mb-3 col-12 pe-lg-0">
                <select
                  class="form-select form-select-sm sort-filter-size"
                  aria-label=".form-select-sm example"
                  onChange={(e) =>
                    setSortData({ ...SortData, value: e.target.value })
                  }
                >
                  <option value="fld_id">Sort By</option>
                  <option value="fld_name">Project Name</option>
                  <option value="fld_city">City</option>
                  <option value="cast(fld_created_on as date)">
                    Raised On
                  </option>
                </select>
              </div>
              <div className="col-sm-3 col-12 mb-sm-0 mb-3 ">
                <select
                  class="form-select form-select-sm sort-filter-size"
                  aria-label=".form-select-sm example"
                  onChange={(e) =>
                    setSortData({ ...SortData, type: e.target.value })
                  }
                >
                  <option value="desc">Order</option>
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending </option>
                </select>
              </div>
              <div className="col-sm-2 col-12 mb-sm-0 mb-3 px-lg-0">
                <Button
                  className="w-100"
                  variant="contained"
                  onClick={() => {
                    getProjectList(ConditionType);
                  }}
                >
                  Sort
                </Button>
              </div>
              {/* <Button
              variant="contained"
              className="col-sm-auto col-12 mb-sm-0 mb-3">
              <Link to="/add-project" className="text-white">
                Add New Project
              </Link>
            </Button> */}
            </div>
          </div>
          {/* </div> */}
        </div>
        <div className="p-3 ">
          {addProjectData.length > 0 ? (
            <>
              <MDBDataTable
                responsive
                hover
                disableRetreatAfterSorting={true}
                data={data}
                entriesOptions={[10, 25, 50, 100]}
              >
                <MDBTableHead columns={data.columns} />
                <MDBTableBody rows={data.rows} />
              </MDBDataTable>
            </>
          ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )}
        </div>
      </div>
    </>
  );
}
