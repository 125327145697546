import React, { useContext, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";
import "./Sidebar.css";
import logo from "../../assets/Image/Vahanilogo.png";
import logo2 from "../../assets/Image/vahanilogo2.png";
import { NavLink } from "react-router-dom";
import { BsChevronDoubleLeft } from "react-icons/bs";
// import Avatar from "@mui/material/Avatar";
// import Header from "../../Header/Header";
// import Mainsection from "../Mainsection/Mainsection";
import store from "../../store";
import { GoPrimitiveDot, GoChevronRight, GoChevronDown } from "react-icons/go";
import { AiFillHome, AiFillBank, AiFillFileAdd } from "react-icons/ai";
import { HiUser } from "react-icons/hi";
import { FaUsers, FaBookReader, FaSchool } from "react-icons/fa";
import { MdLocationOn, MdMail, MdLocalActivity } from "react-icons/md";
import { IoMdSchool } from "react-icons/io";
import { RiFilePaperFill, RiTicketFill } from "react-icons/ri";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import FeedIcon from "@mui/icons-material/Feed";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import DashboardIcon from "../../assets/icons/Home.svg";
import PersonalIcon from "../../assets/icons/Personal_info.svg";
import FamilyIcon from "../../assets/icons/family.svg";
import SubFamilyIcon from "../../assets/icons/family_info.svg";
import AddFamilyIcon from "../../assets/icons/additional_family_info.svg";
import AddressIcon from "../../assets/icons/address.svg";
import BankIcon from "../../assets/icons/bank.svg";
import PersonalBankIcon from "../../assets/icons/personal_bank.svg";
import ParentBankIcon from "../../assets/icons/parent_bank.svg";
import AcademicInfoIcon from "../../assets/icons/academic_info.svg";
import AcademicAcheivement from "../../assets/icons/academic_achievement.svg";
import CollegePrefIcon from "../../assets/icons/college_icon.svg";
import CollegeInfoIcon from "../../assets/icons/college_info.svg";
import CourseInfoIcon from "../../assets/icons/course_info.svg";
import ExamResultIcon from "../../assets/icons/exam_result.svg";
import AccomodationDetailsIcon from "../../assets/icons/accomodation_details.svg";
import ExtraIcon from "../../assets/icons/extra_curricular_icon.svg";
import EssayIcon from "../../assets/icons/essay_icon.svg";
import AdditionalIcon from "../../assets/icons/additional_applicant.svg";
import HeplDeskIcon from "../../assets/icons/help_desk.svg";
import TrackQueryIcon from "../../assets/icons/track_query.svg";
import RaiseQueryIcon from "../../assets/icons/raise_query.svg";
import ChangeMail from "../../assets/icons/mail.svg";
import NotificationIcon from "../../assets/icons/notification.svg";
import TicketManagementIcon from "../../assets/icons/ticket_management.svg";
import ExpenseListIcon from "../../assets/icons/expense_master.svg";
import ChangeEmailIcon from "../../assets/icons/change_email.svg";
import ChangePasswordIcon from "../../assets/icons/change_password.svg";
import CollegeBankIcon from "../../assets/icons/college_bank.svg";
import HostalBankIcon from "../../assets/icons/pg_bank.svg";
import DiscussionIcon from "../../assets/icons/discussion-icon.svg";

const ScholarSidebar = () => {
  const { drawerWidth, setdrawerWidth, Showdraw, setShowdraw } =
    useContext(store);
  const [open, setOpen] = useState(true);
  const [subcat1, setSubcat1] = useState(false);
  const [subcat, setSubcat] = useState(false);
  const [subcat2, setSubcat2] = useState(false);
  const [topc, settopc] = useState("slidertop2");
  // const [openmobile, setOpenmobile] = useState(true);
  const mobiledrawer = () => {
    setShowdraw(false);
  };
  const handleDrawerOpen = () => {
    settopc("topcno");
    setOpen(false);
    setdrawerWidth(70);
  };
  const handleDrawerclose = () => {
    settopc("slidertop2");
    setOpen(true);
    setdrawerWidth(290);
  };
  const showsub1 = () => {
    setSubcat1(!subcat1);
  };
  const showsub = () => {
    setSubcat(!subcat);
  };
  const showsub2 = () => {
    setSubcat2(!subcat2);
  };
  let NavList = () => {
    return (
      <List>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/dashboard"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <AiFillHome style={{ fontSize: "18px" }} className="active-sub" /> */}
                <img
                  src={DashboardIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Dashboard"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/personalinformation"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={PersonalIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Personal Information"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        {/* family */}
        <ListItem disablePadding onClick={showsub1}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              className="drawer-icon"
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <img src={FamilyIcon} className="sidebar-icon sidebar-icon-x" />
            </ListItemIcon>
            <ListItemText
              primary="Family"
              sx={{ opacity: open ? 1 : 0 }}
              style={{ fontsize: "14px !important" }}
            />
            {subcat1 ? (
              <ListItemIcon
                className="drawer-icon-arrow"
                sx={{ display: open ? "block" : "none" }}
              >
                <GoChevronDown
                  style={{ fontSize: "1.8em" }}
                  sx={{ display: open ? "block" : "none" }}
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon
                className="drawer-icon-arrow"
                sx={{ display: open ? "block" : "none" }}
              >
                <GoChevronRight style={{ fontSize: "1.8em" }} />
              </ListItemIcon>
            )}
          </ListItemButton>
        </ListItem>
        {subcat1 ? (
          <div sx={{ opacity: open ? 1 : 0 }}>
            <NavLink
              onClick={mobiledrawer}
              exact
              to="/familyinformation"
              activeClassName="active"
            >
              <ListItem disablePadding className="li-slide">
                <ListItemButton>
                  <ListItemIcon className="drawer-icon">
                    <img
                      src={SubFamilyIcon}
                      className="sidebar-icon sidebar-icon-x"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Family Information"
                    style={{ fontsize: "14px !important" }}
                    className="active-sub"
                  />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink
              onClick={mobiledrawer}
              exact
              to="/additionalfamily"
              activeClassName="active"
            >
              <ListItem disablePadding className="li-slide">
                <ListItemButton>
                  <ListItemIcon className="drawer-icon">
                    <img
                      src={AddFamilyIcon}
                      className="sidebar-icon sidebar-icon-x"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Additional Family Information"
                    style={{ fontsize: "14px !important" }}
                    className="active-sub"
                  />
                </ListItemButton>
              </ListItem>
            </NavLink>
          </div>
        ) : null}
        {/* family */}
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/address"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={AddressIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                primary="Address"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
                className="active-sub"
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        {/* up down */}
        <ListItem disablePadding onClick={showsub} className="li-slide">
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              className="drawer-icon"
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <img src={BankIcon} className="sidebar-icon sidebar-icon-x" />
            </ListItemIcon>
            <ListItemText
              primary="Bank Details"
              sx={{ opacity: open ? 1 : 0 }}
              style={{ fontsize: "14px !important" }}
              className="active-sub"
            />
            {subcat ? (
              <ListItemIcon
                className="drawer-icon-arrow"
                sx={{ display: open ? "block" : "none" }}
              >
                <GoChevronDown
                  style={{ fontSize: "1.8em" }}
                  sx={{ display: open ? "block" : "none" }}
                  className="active-sub"
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon
                className="drawer-icon-arrow"
                sx={{ display: open ? "block" : "none" }}
              >
                <GoChevronRight
                  style={{ fontSize: "1.8em" }}
                  className="active-sub"
                />
              </ListItemIcon>
            )}
          </ListItemButton>
        </ListItem>
        {subcat ? (
          <div sx={{ opacity: open ? 1 : 0 }}>
            <NavLink
              onClick={mobiledrawer}
              exact
              to="/personal-bank"
              activeClassName="active"
            >
              <ListItem disablePadding className="li-slide">
                <ListItemButton>
                  <ListItemIcon className="drawer-icon">
                    <img
                      src={PersonalBankIcon}
                      className="sidebar-icon sidebar-icon-x"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Personal Bank Account"
                    style={{ fontsize: "14px !important" }}
                    className="active-sub"
                  />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink
              onClick={mobiledrawer}
              exact
              to="/parent-bank"
              activeClassName="active"
            >
              <ListItem disablePadding className="li-slide">
                <ListItemButton>
                  <ListItemIcon className="drawer-icon">
                    <img
                      src={ParentBankIcon}
                      className="sidebar-icon sidebar-icon-x"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Parent's Bank Account"
                    style={{ fontsize: "14px !important" }}
                    className="active-sub"
                  />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink
              onClick={mobiledrawer}
              exact
              to="/college-bank"
              activeClassName="active"
            >
              <ListItem disablePadding className="li-slide">
                <ListItemButton>
                  <ListItemIcon className="drawer-icon">
                    <img
                      src={CollegeBankIcon}
                      className="sidebar-icon sidebar-icon-x"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="College Bank Account"
                    style={{ fontsize: "14px !important" }}
                    className="active-sub"
                  />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink
              onClick={mobiledrawer}
              exact
              to="/Hostel-bank"
              activeClassName="active"
            >
              <ListItem disablePadding className="li-slide">
                <ListItemButton>
                  <ListItemIcon className="drawer-icon">
                    <img
                      src={HostalBankIcon}
                      className="sidebar-icon sidebar-icon-x"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="PG/Hostel Bank Account"
                    style={{ fontsize: "14px !important" }}
                    className="active-sub"
                  />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink
              onClick={mobiledrawer}
              exact
              to="/Mess-bank"
              activeClassName="active"
            >
              <ListItem disablePadding className="li-slide">
                <ListItemButton>
                  <ListItemIcon className="drawer-icon">
                    <img
                      src={HostalBankIcon}
                      className="sidebar-icon sidebar-icon-x"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Mess Bank Account"
                    style={{ fontsize: "14px !important" }}
                    className="active-sub"
                  />
                </ListItemButton>
              </ListItem>
            </NavLink>
          </div>
        ) : null}
        {/* updown */}
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/expense-list"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={ExpenseListIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                primary="Expense List"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
                className="active-sub"
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/discussion-forum"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={DiscussionIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Discussion Forum"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/schoolinfo"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={AcademicInfoIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                primary="Academic Information"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
                className="active-sub"
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/academic-achievment"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={AcademicAcheivement}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                primary="Academic Achievement"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
                className="active-sub"
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/extra-curricular-activities"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={ExtraIcon} className="sidebar-icon sidebar-icon-x" />
              </ListItemIcon>
              <ListItemText
                primary="Extra Curricular Activities"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
                className="active-sub"
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/university-college-preference"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={CollegePrefIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                primary="College Preference"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
                className="active-sub"
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/college-information"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={CollegeInfoIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                primary="College Information"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
                className="active-sub"
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/course-information"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={CourseInfoIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                primary="Course Information"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
                className="active-sub"
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/exam-result"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={ExamResultIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                primary="Exam Results"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
                className="active-sub"
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/accomodation-list"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={AccomodationDetailsIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                primary="Accomodation Details"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
                className="active-sub"
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/view-notification"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={NotificationIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                primary="Notifications"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
                className="active-sub"
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/essay"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={EssayIcon} className="sidebar-icon sidebar-icon-x" />
              </ListItemIcon>
              <ListItemText
                primary="Essay"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
                className="active-sub"
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/additional-applicant"
          activeClassName="active"
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={AdditionalIcon}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                primary="Additional Information"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
                className="active-sub"
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        {/* up down  */}
        <ListItem disablePadding onClick={showsub2}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              className="drawer-icon"
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <img src={HeplDeskIcon} className="sidebar-icon sidebar-icon-x" />
            </ListItemIcon>
            <ListItemText
              primary="Help Desk"
              sx={{ opacity: open ? 1 : 0 }}
              style={{ fontsize: "14px !important" }}
            />
            {subcat2 ? (
              <ListItemIcon
                className="drawer-icon-arrow"
                sx={{ display: open ? "block" : "none" }}
              >
                <GoChevronDown
                  style={{ fontSize: "1.8em" }}
                  sx={{ display: open ? "block" : "none" }}
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon
                className="drawer-icon-arrow"
                sx={{ display: open ? "block" : "none" }}
              >
                <GoChevronRight style={{ fontSize: "1.8em" }} />
              </ListItemIcon>
            )}
          </ListItemButton>
        </ListItem>
        {subcat2 ? (
          <div sx={{ opacity: open ? 1 : 0 }}>
            <NavLink
              onClick={mobiledrawer}
              exact
              to="/submit-ticket"
              activeClassName="active"
            >
              <ListItem disablePadding className="li-slide">
                <ListItemButton>
                  <ListItemIcon className="drawer-icon">
                    <img
                      src={RaiseQueryIcon}
                      className="sidebar-icon sidebar-icon-x"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Raise your query"
                    style={{ fontsize: "14px !important" }}
                    className="active-sub"
                  />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink
              onClick={mobiledrawer}
              exact
              to="/ticket-history"
              activeClassName="active"
            >
              <ListItem disablePadding className="li-slide">
                <ListItemButton>
                  <ListItemIcon className="drawer-icon">
                    <img
                      src={TrackQueryIcon}
                      className="sidebar-icon sidebar-icon-x"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Track your query"
                    style={{ fontsize: "14px !important" }}
                    className="active-sub"
                  />
                </ListItemButton>
              </ListItem>
            </NavLink>
          </div>
        ) : null}
      </List>
    );
  };
  return (
    <>
      <div className="drawer-laptop">
        <Drawer
          className="drawer-d smallImagesScroll"
          style={{ width: "drawerWidth", transition: " width 5s  all" }}
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <div className="swipe-col-dw">
            <div className="swipe-left-dw">
              <div onClick={handleDrawerclose}>
                {open ? (
                  <img
                    src={logo2}
                    alt="logo"
                    style={{ width: "100px", cursor: "pointer" }}
                  />
                ) : (
                  <img
                    src={logo}
                    alt="logo"
                    style={{ width: "40px", height: "40px", cursor: "pointer" }}
                  />
                )}
              </div>
              {open ? (
                <div onClick={handleDrawerOpen}>
                  <BsChevronDoubleLeft className="arrow-0pen" />
                </div>
              ) : null}
            </div>
          </div>
          <NavList />
        </Drawer>
      </div>
      {Showdraw ? (
        <div className="drawer-moblie">
          <Drawer
            className="drawer-d"
            style={{ width: "drawerWidth", transition: " width 1s  all" }}
            sx={{
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <div className="swipe-col-dw">
              <div className="swipe-left-dw">
                <div>
                  <img
                    src={logo2}
                    alt="logo"
                    style={{
                      width: "100px",
                      // height: "40px",
                      cursor: "pointer",
                    }}
                  />
                </div>
                {open ? (
                  <div onClick={mobiledrawer}>
                    {" "}
                    <BsChevronDoubleLeft className="arrow-0pen" />{" "}
                  </div>
                ) : null}
              </div>
            </div>
            <NavList />
          </Drawer>
        </div>
      ) : null}
    </>
  );
};
export default ScholarSidebar;
