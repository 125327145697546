import React, { Component } from "react";

import Notiflix from "notiflix";
import GetApiCall from "../../../../assets/API/GetApi";
import PostApiCall from "../../../../assets/API/PostApi";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { XSquare } from "react-feather";
import {
  MenuItem,
  FormControl,
  Select,
  Button,
  InputLabel,
  TextField,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput,
} from "@mui/material";

class ApplyLeave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PageTitle: "1",
      Page1: "Pending",
      Question: "",
      Answer: "",
      Data: [],
      Details: [],
      QuestionId: [],
      InformationData: [],
      Name: "",
      Type: "",
      Notes: "",
      Days: "",
      StartDate: "",
      EndDate: "",
      leaveFile: [],
      leaveFileName: "",


      LeaveData: [
        {
          value: "Privilege Leave/Earned Leave/Annual Leave",
          label: "Privilege Leave/Earned Leave/Annual Leave",
        },
        { value: "Casual Leave", label: "Casual Leave" },
        { value: "Sick Leave", label: "Sick Leave" },
        { value: "Maternity Leave", label: "Maternity Leave" },
        { value: "Marriage Leave", label: "Marriage Leave" },
        { value: "Paternity Leave", label: "Paternity Leave" },
        { value: "Bereavement Leave", label: "Bereavement Leave" },
        { value: "Compensatory Off", label: "Compensatory Off" },
        { value: "Loss Of Pay Leave", label: "Loss Of Pay Leave" },
      ],
      NameData: [],
      NumRegex: /^[0-9]*$/,
      LeaveId: null,
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "/assets/js/pages/form-wizard.init.js";
    script.async = true;
    document.body.appendChild(script);
  }

  handleFileSave = () => {
    PostApiCall.postRequest(
      {
        certificateid: this.state.CertificateId,
        employeeid: JSON.parse(localStorage.getItem("LoginDetail"))
          .fld_employee_id,
        certificatename: this.state.EmpCertificateName,
        certificatefile:
          "https:admin.vahanischolarship.in/Images/EmployInformation/" +
          this.state.leaveFile.name,
      },
      "AddCertificate"
    ).then((result) =>
      result.json().then((certificateobj) => {
        if (
          result.status == 200 ||
          result.status == 201
        ) {
          this.uploadEmpDoc(this.state.leaveFile);
          this.getCertificate();
          this.setState({
            leaveFileName: "",
            leaveFile: [],
          });
        }
      })
    );
  };

  uploadEmpDoc = (leaveFile) => {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const form = new FormData();
    form.append("file", leaveFile);
    form.append("foldername", "EmployInformation");
    form.append("filename", leaveFile.name);
    let response = fetch("https://admin.vahanischolarship.in/API/AddPdf2", {
      method: "POST",
      body: form,
    })
      .then((response) => response.json())
      .then((data) => {
        Notiflix.Notify.success("Document Uploaded Successfully.");
      });
  };

  SaveLeave() {
    if (this.state.LeaveDays !== "") {
      if (this.state.LeaveType != "") {
        if (this.state.LeavesNotes != "") {
          if (this.state.StartDate != "") {
            if (this.state.EndDate != "") {
              this.onPost();
            } else {
              Notiflix.Notify.failure("Please select End Date");
            }
          } else {
            Notiflix.Notify.failure("Please select Start Date");
          }
        } else {
          Notiflix.Notify.failure("Please Select Leave Notes");
        }
      } else {
        Notiflix.Notify.failure("Please select Leave Type");
      }
    } else {
      Notiflix.Notify.failure("Please fill how many days for leave");
    }
  }

  onPost = () => {
    Notiflix.Loading.dots();
    if (this.state.leaveFile.length) {
      this.handleFileSave() // doc save to DB
      let date1 = new Date(this.state.StartDate);
      let date2 = new Date(this.state.EndDate);

      let timeDiff = date2.getTime() - date1.getTime();
      let daysdiff = timeDiff / (1000 * 60 * 60 * 24) + 1;
      PostApiCall.postRequest(
        {
          id: this.state.LeaveId,
          employeeid: JSON.parse(localStorage.getItem("LoginDetail"))
            .fld_employee_id,
          leavetype: this.state.LeaveType,
          leavedays: daysdiff,
          startdate: this.state.StartDate,
          enddate: this.state.EndDate,
          leavenotes: this.state.LeavesNotes,
          email: JSON.parse(localStorage.getItem("LoginDetail"))
            .fld_email,
          status: "Pending",
          reasonofrejection: "",
          employeename: JSON.parse(localStorage.getItem("LoginDetail"))
            .fld_employee_full_name,
          raiseddate: new Date(),
        },
        "AddLeave"
      ).then((resultcategory) =>
        resultcategory.json().then((obj) => {
          if (resultcategory.status == 200 || resultcategory.status == 201) {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Leave successfully submitted for approval.");
            window.history.go(-1);
          } else {
            Notiflix.Notify.failure("Error Occured");
            Notiflix.Loading.remove()
          }
        })
      );
    } else {
      Notiflix.Notify.failure("Please upload the required document")
      Notiflix.Loading.remove()
    }

  };

  render() {
    return (
      <>
        <div className="form-head mb-lg-2 mb-3">
          <div
            className="col-auto "
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Raise New Leave Application</h4>
          </div>
        </div>
        <div className="card-vahani p-0 mt-2">
          <div className="row page-title"></div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body p-0">
                  <div id="smartwizard-arrows">
                    <p className="personal-info m-0 left-line-card p-4">
                      Apply For Leave Application
                    </p>

                    <div className="p-3" style={{ minHeight: "0px" }}>
                      <div
                        id="sw-arrows-step-1"
                        className="tab-pane step-content"
                        style={{
                          display:
                            this.state.PageTitle == "1" ? "block" : "none",
                        }}
                      >
                        <form
                          className="needs-validation"
                          novalidate
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <div
                            className=""
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                            data-toggle="toast"
                          >
                            <div class="toast-body">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div className="col-lg-4 mb-lg-3 mobile-personal">
                                      <TextField
                                        fullWidth
                                        id="standard-select-currency"
                                        name="mySelectBox"
                                        select
                                        label="Select Leave Type"
                                        value={this.state.LeaveType}
                                        onChange={(e) => {
                                          this.setState({
                                            LeaveType: e.target.value,
                                          });
                                        }}
                                      >
                                        {this.state.LeaveData.map((option) => (
                                          <MenuItem
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </div>

                                    <div className="col-lg-4 mb-lg-3 mobile-personal">
                                      <TextField
                                        fullWidth
                                        className="text-field"
                                        type="date"
                                        helperText={" Start Date"}
                                        onChange={(e) => {
                                          this.setState({
                                            StartDate: e.target.value,
                                          });
                                        }}
                                        inputProps={{
                                          min: new Date()
                                            .toISOString()
                                            .slice(0, 16),
                                        }}
                                      />
                                    </div>

                                    <div className="col-lg-4 mb-lg-3 mobile-personal">
                                      <TextField
                                        fullWidth
                                        className="text-field"
                                        type="date"
                                        helperText={" End Date"}
                                        onChange={(e) => {
                                          this.setState({
                                            EndDate: e.target.value,
                                          });
                                        }}
                                        inputProps={{
                                          min: new Date()
                                            .toISOString()
                                            .slice(0, 16),
                                        }}
                                      />
                                    </div>

                                    <div class="col-md-12">
                                      <div class="form-group mb-2">
                                        <label for="validationCustom05">
                                          Leave Notes
                                          <span className="mandatory">*</span>
                                        </label>
                                        <textarea
                                          type="text"
                                          class="form-control"
                                          id="validationCustom05"
                                          value={this.state.LeavesNotes}
                                          onChange={(text) => {
                                            this.setState({
                                              LeavesNotes: text.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Upload Document(Maximum Size 6Mb | File Format .pdf .jpeg)"
                            variant="outlined"
                            className="text-field mt-3"
                            type="File"
                            onChange={(e) => {
                              e.preventDefault();
                              if (e.target.files[0].size < 6000000) {
                                const reader = new FileReader();
                                const file = e.target.files[0];
                                reader.onloadend = () => {
                                  this.setState({
                                    leaveFile: file,
                                  });
                                };
                                reader.readAsDataURL(file);
                              } else {
                                Notiflix.Notify.failure(
                                  "File too large, upload file less than 6 MB."
                                );
                              }
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              accept: "application/pdf, image/jpeg",
                            }}
                          />
                          <div
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                            data-toggle="toast"
                          >
                            <div
                              className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3"
                              style={{ float: "right" }}
                            >
                              <div
                                className=" justify-content-end"
                                style={{ display: "flex" }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={this.SaveLeave.bind(this)}
                                >
                                  Send For Approval
                                </Button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ApplyLeave;
