import { Button, MenuItem } from "@mui/material";
import { Select } from "antd";
import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineFileSearch } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { Col } from "reactstrap";
import PostApiCall from "../../../assets/API/PostApi";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

let index = 0;
export default function AddRfqTask() {
  let history = useHistory();
  let location = useLocation();
  const params = useParams();
  const [addTaskData, setTaskData] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const [RfqDetails, setRFQDetails] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);

  const inputRef = useRef(null);

  const handleChange = (value) => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        rfqid: location.state.id,
        projectid: value,
      },
      "GetProjectTaskForRFQ"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setTaskData(obj.data);
          Notiflix.Loading.remove();
        }
      })
    );
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckedItems([...checkedItems, value]);
      const updatedData = [...addTaskData];
      // Find the index of the object to update
      const index = updatedData.findIndex(
        (item) => item.fld_id === parseInt(event.target.value)
      );

      if (index !== -1) {
        // Update the object in the copied array
        updatedData[index] = {
          ...updatedData[index],
          is_selected: "true",
        };

        // Set state with the updated array
        setTaskData(updatedData);
      }
    } else {
      const updatedItems = checkedItems.filter((item) => item !== value);
      setCheckedItems(updatedItems);
      const updatedData = [...addTaskData];

      // Find the index of the object to update
      const index = updatedData.findIndex(
        (item) => item.fld_id === parseInt(event.target.value)
      );

      if (index !== -1) {
        // Update the object in the copied array
        updatedData[index] = {
          ...updatedData[index],
          is_selected: "false",
        };

        // Set state with the updated array
        setTaskData(updatedData);
      }
    }
  };

  const onAddRfqTask = (action) => {
    const rfid = location.state.id;
    PostApiCall.postRequest(
      {
        id: null,
        rfq: rfid,
        taskid: checkedItems,
        userid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
      },
      "UpdateRFQTasks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          history.push("/rfq-list");
          Notiflix.Notify.success("RFQ saved successfully");
        }
      })
    );
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    PostApiCall.postRequest(
      {
        whereClause: ` where fld_id = ${location.state.id} `,
      },
      "GetRFQMasters"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setRFQDetails(obj.data);
        }
      })
    );

    PostApiCall.postRequest(
      {
        whereClause: ` where fld_rfq_id = ${location.state.id} `,
      },
      "GetRFQTasks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setTaskData(obj.data);
          Notiflix.Loading.remove();
        }
      })
    );

    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetProjectsMaster"
      // "GetProjectsTasks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setProjectData(
            obj.data.length > 0
              ? obj.data.filter((project) => {
                  return project.fld_status === "New" && project.fld_task_count;
                })
              : null
          );
        }
      })
    );
  }, []);

  // Rfq Tasks
  const data = {
    columns: [
      {
        label: "S.No.",
        field: "serial",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Project Name",
        field: "projectname",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Task",
        field: "task",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Deadline",
        field: "deadline",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Start Date",
        field: "startDate",
        sort: "disabled",
        width: 150,
      },
      {
        label: "End Date",
        field: "endDate",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Budget",
        field: "budget",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 150,
      },
      {
        label: (
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Select All
            </label>
          </div>
        ),
        field: "action",
        sort: "disabled",
        width: 150,
      },
    ],

    rows: addTaskData.map((data, i) => {
      return {
        serial: i + 1,
        task: data.fld_task,
        projectname: data.project_name,
        description: data.fld_desc,
        deadline: moment(data.fld_deadline).format("DD-MM-YYYY"),
        endDate: moment(data.fld_end_date).format("DD-MM-YYYY"),
        startDate: moment(data.fld_start_date).format("DD-MM-YYYY"),
        reason: data.fld_reason,
        budget: data.fld_budget,
        status: data.status,
        action: (
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value={data.fld_id}
              checked={data.is_selected == "true" ? true : false}
              id={i}
              onChange={handleCheckboxChange}
            />
          </div>
        ),
      };
    }),
  };
  return (
    <>
      <div>
        <div className="form-head ">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Add New Task ({RfqDetails[0]?.fld_rfq_no})</h4>
          </div>
        </div>
        <div className="card-vahani p-0 pb-4 ">
          <p className="personal-info left-line-card px-lg-4 px-3 py-4">
            Select Project
          </p>
          <div class="toast-body">
            <div class="row">
              <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row ps-2 pe-3">
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <Select
                          className="rfq-task-input w-100"
                          placeholder="Projects"
                          label="Projects"
                          mode="tags"
                          style={{
                            width: "100%",
                          }}
                          onChange={handleChange}
                          tokenSeparators={[","]}
                        >
                          {projectData.map((record) => {
                            return (
                              <MenuItem
                                key={record.fld_id}
                                value={record.fld_id}
                              >
                                {record.fld_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>

      {/* ***** Show All Task on selected projects *********** */}

      <>
        <div className="form-head mb-lg-2 mb-3"></div>
        <div className="card-vahani p-0 mt-2">
          <p className="personal-info m-0 left-line-card p-4 d-flex align-items-center justify-content-between">
            Select Tasks{" "}
          </p>
          <div className="p-3">
            {addTaskData.length > 0 ? (
              <MDBDataTable
                responsive
                hover
                disableRetreatAfterSorting={true}
                data={data}
                entriesOptions={[10, 25, 50, 100]}
              >
                <MDBTableHead columns={data.columns} />
                <MDBTableBody rows={data.rows} />
              </MDBDataTable>
            ) : (
              <Col lg={12}>
                <div className="text-center">
                  <div className="justify-content-center no-data-icon mt-4">
                    <AiOutlineFileSearch className="no-found-data-size" />
                  </div>
                  <h4>No data found</h4>
                </div>
              </Col>
            )}
          </div>
        </div>

        {/* ***** Save button ***** */}
        <div className="card-vahani p-0 mt-5">
          <div className="justify-content-end d-flex m-3">
            <Button variant="contained" onClick={onAddRfqTask}>
              Save
            </Button>
          </div>
        </div>
      </>
    </>
  );
}
