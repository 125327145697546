import { Button, TextField } from "@mui/material";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
// import {
//   MDBDataTable,
//   MDBTableBody,
//   MDBTableHead,
//   Backdrop,
//   Fade,
//   Box,
//   Typography,
//   Modal,
// } from "mdbreact";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "react-bootstrap/Modal";
import Typography from "@mui/material/Typography";

import PostApiCall from "../../../assets/API/PostApi";
// import { Modal } from "antd";

import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import AddPo from "./AddNewPurchaseOrder";
import { Eye, Download } from "react-feather";
import moment from "moment";
export default function PurchaseOrderList() {
  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const login = JSON.parse(localStorage.getItem("LoginDetail"))[0];


  // Filter Data
  const [RFQData, setRFQData] = useState([]);
  const [dateData, setdateData] = React.useState({ from: "", to: "" });
  const [ConditionType, setConditionType] = useState("New");
  const [purchasOrderId, setPurchaseOrderId] = useState(null);
  const [SortData, setSortData] = React.useState({
    type: "desc",
    value: "fld_id",
  });

  // **** Add Po Modal *****
  const [open, setOpen] = React.useState(false);
  const [invoiceListopen, setInvoiceListOpen] = React.useState(false);
  const [invoiceListData, setInvoiceListData] = React.useState([]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenInvoiceList = () => {
    setInvoiceListOpen(true);
  };
  const handleCloseInvoiceList = () => {
    setInvoiceListOpen(false);
  };

  let filteredList = []

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetPurchaseOrders"
      // "GetProjectsTasks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setPurchaseOrderData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);

  const QuotationListData = (condition) => {
    PostApiCall.postRequest(
      {
        whereClause:
          dateData.from == "" && dateData.to == ""
            ? `order by ${SortData.value} ${SortData.type}`
            : `where fld_rfq_status='${condition}' ${dateData.from != "" && dateData.to != ""
              ? `and fld_created_on between '${dateData.from}' and '${dateData.to}' `
              : ""
            } order by ${SortData.value} ${SortData.type}`,
      },
      "GetPurchaseOrders_Details"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setRFQData(obj.data);
        }
      })
    );
  };


  const purchaseOrderCols = [
    {
      label: "S.No.",
      field: "serial",
      sort: "disabled",
      width: 150,
    },
    {
      label: "PO Number",
      field: "ponumber",
      sort: "disabled",
      width: 400,
    },
    {
      label: "Vendor Name",
      field: "vendorname",
      sort: "disabled",
      width: 400,
    },
    {
      label: "Vendor Address",
      field: "vendoraddress",
      sort: "disabled",
      width: 400,
    },
    {
      label: "Vendor Contact Details",
      field: "vendorcontactdetails",
      sort: "disabled",
      width: 400,
    },
    {
      label: "Status",
      field: "status",
      sort: "disabled",
      width: 150,
    },
    {
      label: "Created By",
      field: "createdby",
      sort: "asc",
      width: 150,
    },
    {
      label: "Updated By",
      field: "UpdatedBy",
      sort: "asc",
      width: 150,
    },
    {
      label: "Invoice",
      field: "createpo",
      width: 150,
      sort: "asc",
    },
    {
      label: "Action",
      field: "action",
      width: 150,
      sort: "asc",
    },
  ]

  const data = {
    columns: purchaseOrderCols.filter((col) => {
      // console.log("function running", login.fld_type)
      if (login.fld_type == "Accountant") {
        // console.log("if executed")
        return col.label !== "Invoice"
      } else {
        // console.log("hello")
        return col
      }
    }),
    rows: purchaseOrderData.map((data, i) => {
      return {
        serial: i + 1,
        ponumber: data.fld_po_no,
        vendorname: data.fld_name,
        vendoraddress: (
          <div
            style={{ fontWeight: "400" }}
            dangerouslySetInnerHTML={{ __html: data.fld_po_address }}
          ></div>
        ),
        vendorcontactdetails: (
          <div
            style={{ fontWeight: "400" }}
            dangerouslySetInnerHTML={{ __html: data.fld_po_contact_person }}
          ></div>
        ),
        status: data.fld_po_status,
        createdby: data.created_by_name,
        UpdatedBy: data.updated_by_name,


        createpo: (
          <>
            {login.fld_type !== "Accountant" &&
              (
                <div className="d-flex">
                  {data.fld_invoice_count > 0 ? (
                    <Button
                      className="task-btn-color ms-2"
                      onClick={() => {
                        Notiflix.Loading.dots();
                        setPurchaseOrderId(data.fld_id);
                        PostApiCall.postRequest(
                          {
                            whereClause: `where fld_po_id=${data.fld_id}`,
                          },
                          "Get_Vendor_Invoice_History"
                          // "GetProjectsTasks"
                        ).then((results) =>
                          results.json().then((obj) => {
                            if (results.status == 200 || results.status == 201) {
                              setInvoiceListData(obj.data);
                              setInvoiceListOpen(true);
                              Notiflix.Loading.remove();
                            } else {
                              Notiflix.Loading.remove();
                            }
                          })
                        );
                      }}
                    >
                      View Purchase Order
                    </Button>
                  ) : (
                    <Link
                      to={{
                        pathname: `/purchaseorderprint`,
                        state: {
                          id: data.fld_id,
                        },
                      }}
                      className="task-btn-color ms-2"
                    >
                      Generate Purchase Order
                    </Link>
                  )}

                </div>
              )
            }

          </>

        ),
        action: (
          <div className="d-flex">
            <Link
              to={{
                pathname: `/add-purchase-Order`,
                state: {
                  id: data.fld_id,
                },
              }}
            >
              <Button variant="contained">
                {login.fld_type !== "Accountant" ?
                  "Edit" : "View"
                }
              </Button>
            </Link>
          </div>
        ),
      };
    }),
  };

  const dataInvoiceList = {
    columns: [
      {
        label: "S.No.",
        field: "serial",
        sort: "disabled",
        width: 150,
      },

      {
        label: "CreatedOn",
        field: "createdon",
        sort: "disabled",
        width: 150,
      },

      {
        label: "CreatedBy",
        field: "createdby",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
        sort: "asc",
      },
    ],
    rows: invoiceListData.map((data, i) => {
      return {
        serial: i + 1,
        createdon: moment(data.fld_created_on).format("DD/MM/YYYY"),
        createdby: data.created_by_name,
        action: (
          <div className="d-flex">
            <a
              href={data.fld_file_url}
              download="Purchase-Order_Invoice.pdf"
              target="_blank"
            >
              <Download />
            </a>
          </div>
        ),
      };
    }),
  };

  return (
    <>
      <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Purchase Order List </h4>
        </div>
      </div>
      <div className="card-vahani p-0 mt-2">
        <div className="personal-info left-line-card row m-0 align-items-lg-center justify-content-between px-2 py-4 mb-2 w-100">
          <div className="col-4 col-md-6 mb-2 mb-md-0">Orders</div>

          {login.fld_type !== "Accountant" &&
            <Link
              to={{
                pathname: `/add-purchase-Order`,
                state: {
                  id: null,
                },
              }}
              className="col-8 col-md-6 mb-sm-0 text-white text-end"
            >
              <Button variant="contained">Create New Purchase Order</Button>
            </Link>
          }
        </div>

        <div className="personal-info m-0 ps-md-1 pe-md-3 pb-0 d-lg-flex align-items-center justify-content-between">
          <div className="col-xxl-6 col-12 mt-3">
            <div className="row px-0">
              <TextField
                type="date"
                label="From Date"
                id="outlined-size-small"
                size="small"
                value={dateData.from}
                onChange={(e) =>
                  setdateData({ ...dateData, from: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className="mb-3 mb-md-0 px-2 col-md-4"
              />
              <TextField
                type="date"
                label="To Date"
                id="outlined-size-small"
                size="small"
                value={dateData.to}
                onChange={(e) =>
                  setdateData({ ...dateData, to: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className="mb-3 mb-md-0 px-2 col-md-4"
              />
              <div className="px-2 col-md-2">
                <Button
                  className="sort-filter-size w-100"
                  variant="contained"
                  onClick={() => {
                    if (dateData.from != "") {
                      if (dateData.to != "") {
                        QuotationListData(ConditionType);
                      } else {
                        Notiflix.Notify.failure("Enter To date");
                      }
                    } else {
                      Notiflix.Notify.failure("Enter From date");
                    }
                  }}
                >
                  Filter
                </Button>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 px-lg-0 col-12 mt-3 px-0">
            <div className="row m-0 px-0 align-items-lg-start justify-content-lg-end">
              <div className="col-sm-3 mb-sm-0 mb-3 col-12 pe-lg-0">
                <select
                  class="form-select form-select-sm sort-filter-size"
                  aria-label=".form-select-sm example"
                  onChange={(e) =>
                    setSortData({ ...SortData, value: e.target.value })
                  }
                >
                  <option value="fld_id">Sort By</option>
                  <option value="trim(fld_rfq_no)">RFQ Number</option>
                  <option value="cast(fld_created_on as date)">
                    Raised On
                  </option>
                  <option value="trim(fld_rfq_status)">Status</option>
                </select>
              </div>
              <div className="col-sm-3 col-12 mb-sm-0 mb-3 ">
                <select
                  class="form-select form-select-sm sort-filter-size"
                  aria-label=".form-select-sm example"
                  onChange={(e) =>
                    setSortData({ ...SortData, type: e.target.value })
                  }
                >
                  <option value="desc">Order</option>
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending </option>
                </select>
              </div>
              <div className="col-sm-2 col-12 mb-sm-0 mb-3 px-lg-0">
                <Button
                  className="w-100"
                  variant="contained"
                  onClick={() => {
                    QuotationListData(ConditionType);
                  }}
                >
                  Sort
                </Button>

                {/* <Button
                variant="contained"
                className="col-sm-auto col-12 mb-sm-0 mb-3"
              >
                <Link to="/add-purchase-Order" className="text-white">
                  Add New Purchase Order
                </Link>
              </Button> */}
              </div>
            </div>
          </div>
        </div>
        {/* </p> */}

        <div className="p-3 purchase-order-table overflow-x-auto">
          {/* <table class="table custom-table-size">
              <thead>
                <tr>
                  <th class="th-sm">S.No.</th>
                  <th class="th-sm">Vendor Name</th>
                  <th class="th-sm">Vendor Address</th>
                  <th class="th-sm">Vendor Contact Details</th>
                  <th class="th-sm">RFQ Number</th>
                  <th class="th-sm">Quotation Number</th>
                  <th class="th-sm">Status</th>
                  <th class="th-sm">Created By</th>
                  <th class="th-sm">Updated By</th>
                  <th class="th-sm">Apporved Quotation</th>
                  <th class="th-sm">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Vendor1</td>
                  <td>vendor address1</td>
                  <td>vendor contact details</td>
                  <td>22212</td>
                  <td>Q123</td>
                  <td></td>
                  <td>test</td>
                  <td>test1</td>
                  <td>
                    <img src="" />
                  </td>
                  <td>
                    <Button
                      variant="contained"
                      className="w-100"
                      onClick={handleOpen}>
                      Create PO
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table> */}
          <MDBDataTable
            responsive
            hover
            disableRetreatAfterSorting={true}
            data={data}
            entriesOptions={[10, 25, 50, 100]}
          >
            <MDBTableHead columns={data.columns} />
            <MDBTableBody rows={data.rows} />
          </MDBDataTable>

          {/* {purchaseOrderData.length > 0 ? ( */}
          {/* <>
            <MDBDataTable
              responsive
              hover
              disableRetreatAfterSorting={true}
              data={data}
              entriesOptions={[10, 25, 50, 100]}
            >
              <MDBTableHead columns={data.columns} />
              <MDBTableBody rows={data.rows} />
            </MDBDataTable>
          </> */}
          {/* ) : (
            <Col lg={12}>
              <div className="text-center">
                <div className="justify-content-center no-data-icon mt-4">
                  <AiOutlineFileSearch className="no-found-data-size" />
                </div>
                <h4>No data found</h4>
              </div>
            </Col>
          )} */}
        </div>
        {/* </div> */}
      </div>

      {/* *********** Modal For Add PO ***************** */}
      {/* <Modal
        className="add-po-modal-overflow"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sm class="add-po-modal">
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <AddPo />
            </Typography>
          </Box>
        </Fade>
      </Modal> */}

      <Modal show={invoiceListopen} onHide={handleCloseInvoiceList} size="lg">
        <Modal.Body>
          <div className="d-flex justify-content-end w-100">
            <Link
              variant="contained"
              to={{
                pathname: `/purchaseorderprint`,
                state: {
                  id: purchasOrderId,
                },
              }}
            >
              <Button className="sort-filter-size" variant="contained">
                Generate Purchase Order
              </Button>
            </Link>
          </div>

          <MDBDataTable
            responsive
            hover
            data={dataInvoiceList}
            entriesOptions={[10, 25, 50, 100]}
          >
            <MDBTableHead columns={dataInvoiceList.columns} />
            <MDBTableBody rows={dataInvoiceList.rows} />
          </MDBDataTable>
        </Modal.Body>
      </Modal>
    </>
  );
}
