import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
import moment from "moment";
import Notiflix from "notiflix";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function CollegeBank({ stdid, action }) {
  var login = stdid
    ? { fld_id: stdid }
    : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  var ValidationList = JSON.parse(localStorage.getItem("Validation"));
  const [Type, setType] = useState("bank");
  const {
    register,
    handleSubmit,
    watch,
    required,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const [bankDetailsVerification, setBankDetailsVerification] = useState(false);
  const [modalShow, setModalShow] = useState(false); // modal to add bank details

  const GetCollegeBankDetail = () => {
    Notiflix.Loading.dots();
    // send login id and then get the college and bank account details
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "GetScholarCollegeBankAcountInformationn_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (
          (results.status == 200 || results.status == 201) &&
          obj.data.length > 0
        ) {
          PostApiCall.postRequest(
            {
              studentId: login.fld_id,
              bankaccno: obj.data[0].fld_accountNum,
            },
            "BankDetailsVerfication"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                if (obj.data.length != 0) {
                  var bankdetailsVerification = false;
                  for (var i = 0; i < obj.data.length; i++) {
                    if (
                      obj.data[0].fld_approval_status === "Paid" ||
                      obj.data[0].fld_approval_status === "Closed"
                    ) {
                      bankdetailsVerification = false;
                      break;
                    } else {
                      bankdetailsVerification = true;
                    }
                  }
                  setBankDetailsVerification(bankdetailsVerification);
                } else {
                  Notiflix.Loading.remove();
                  setBankDetailsVerification(true);
                }
              }
            })
          );

          // from the first api in this ladder
          setType(
            obj.data[0].fld_accountNum == null ||
              obj.data[0].fld_accountNum == ""
              ? "portal"
              : "bank"
          );
          reset(obj.data[0]);
          Notiflix.Loading.remove();
        } else {
          setBankDetailsVerification(true);
        }
        Notiflix.Loading.remove();
      })
    );
  };

  React.useEffect(() => {
    GetCollegeBankDetail();
  }, []);

  // handler to save bank account details
  const onSave = (data) => {
    Notiflix.Loading.dots();
    const detail = JSON.parse(localStorage.getItem("LoginDetail"))[0];

    PostApiCall.postRequest(
      {
        // fld_id: modalShow === true ? null : data.fld_id,
        // studentId: login.fld_id,
        // accountNum: Type === "portal" ? data.fld_accountNum : null,
        // accountName: Type === "portal" ? data.fld_accountName : null,
        // bankName: Type === "portal" ? data.fld_bankName : null,
        // branchName: Type === "portal" ? data.fld_branchName : null,
        // ifscCode: Type === "portal" ? data.fld_ifscCode : null,
        // updatedby: detail.fld_id,
        // updatedon: moment().format("lll"),
        // loginId: Type === "portal" ? data.fld_loginId : null,
        // password: Type === "portal" ? data.fld_password : null,
        // portalUrl: Type === "portal" ? data.fld_portalUrl : null,
        // otherDetails: Type === "portal" ? data.fld_otherDetails : null,


        fld_id: modalShow === true ? null : data.fld_id,
        studentId: login.fld_id,
        accountNum: data.fld_accountNum,
        accountName: data.fld_accountName,
        bankName: data.fld_bankName,
        branchName: data.fld_branchName,
        ifscCode: data.fld_ifscCode,
        updatedby: detail.fld_id,
        updatedon: moment().format("lll"),
        loginId: data.fld_loginId,
        password: data.fld_password,
        portalUrl: data.fld_portalUrl,
        otherDetails: data.fld_otherDetails,
      },
      "UpdateScholarCollegeBankAcountInformation"
    ).then((result) => {
      if (result.status === 200 || result.status === 201) {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Saved and Updated Successfully.");
      } else {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure("There seems to be an Error. Please retry!");
      }
    });
  };
  return (
    <>
      {!action && (
        <>
          <div className="form-head">
            <div
              className="row justify-content-between m-0 align-items-center"
              style={{
                borderLeft: "10px solid rgb(32, 101, 209)",
                paddingLeft: "10px",
              }}
            >
              <h4 className="col-auto">Bank Details</h4>
              <div className="col-md-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Type}
                    label="Select Type"
                    onChange={(e) => setType(e.target.value)}
                  >
                    <MenuItem value="bank">College Bank Details</MenuItem>
                    <MenuItem value="portal">College Portal Details</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </>
      )}

      {/* {formStep && */}
      <form
        onSubmit={bankDetailsVerification === true ? handleSubmit(onSave) : ""}
      >
        {Type == "bank" && (
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                College Bank Details
                <div className="but-width-form">
                  <Button
                    variant="contained"
                    className="but-login-vahani"
                    type="button"
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    Add Bank Details
                  </Button>
                </div>
              </div>
            </p>
            <div className="row m-0 p-lg-4 p-3 ">
              {/* Accounr number */}
              <div className="col-md-6 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  label="Account Number*"
                  {...register("fld_accountNum", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[10].fld_reg_ex, "i"),
                      message: "Enter a valid account number",
                    },
                    minLength: {
                      value: 9,
                      message: "Enter minimum 9-digit number",
                    },
                  })}
                  value={watch("fld_accountNum") ? watch("fld_accountNum") : ""}
                  error={errors?.fld_accountNum}
                  helperText={
                    errors?.fld_accountNum
                      ? errors.fld_accountNum.message
                      : null
                  }
                />
              </div>
              {/* Account holder */}
              <div className="col-md-6 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  label="Account Holder Name*"
                  className="capital"
                  {...register("fld_accountName", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[0].fld_reg_ex, "i"),
                      message: "Enter a valid name",
                    },
                  })}
                  value={
                    watch("fld_accountName") ? watch("fld_accountName") : ""
                  }
                  error={errors?.fld_accountName}
                  helperText={
                    errors?.fld_accountName
                      ? errors.fld_accountName.message
                      : null
                  }
                />
              </div>
              {/* Bank name */}
              <div className="col-md-6 mb-lg-3 mobile-personal capital-col ">
                <TextField
                  fullWidth
                  label="Bank Name*"
                  className="capitalonly"
                  {...register("fld_bankName", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[7].fld_reg_ex, "i"),
                      message: "Enter a valid bank name",
                    },
                  })}
                  value={watch("fld_bankName") ? watch("fld_bankName") : ""}
                  error={errors?.fld_bankName}
                  helperText={
                    errors?.fld_bankName ? errors.fld_bankName.message : null
                  }
                />
              </div>
              {/* Branch name */}
              <div className="col-md-6 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  label="Branch Name*"
                  className="capital"
                  {...register("fld_branchName", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[11].fld_reg_ex, "i"),
                      message: "Enter a valid branch name",
                    },
                  })}
                  value={watch("fld_branchName") ? watch("fld_branchName") : ""}
                  error={errors?.fld_branchName}
                  helperText={
                    errors?.fld_branchName
                      ? errors.fld_branchName.message
                      : null
                  }
                />
              </div>
              {/* IFSC code */}
              <div className="col-lg-6 mb-lg-3 mobile-personal capital-col ">
                <TextField
                  fullWidth
                  label="IFSC Code*"
                  className="capitalonly"
                  {...register("fld_ifscCode", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[5].fld_reg_ex, "i"),
                      message: ValidationList[5].fld_reg_ex_msg,
                    },
                    maxLength: {
                      value: 11,
                      message: "Enter maximum 11-digit IFSC",
                    },
                    minLength: {
                      value: 11,
                      message: "Enter minimum 11-digit IFSC",
                    },
                  })}
                  value={watch("fld_ifscCode") ? watch("fld_ifscCode") : ""}
                  error={errors?.fld_ifscCode}
                  helperText={
                    errors?.fld_ifscCode ? errors.fld_ifscCode.message : null
                  }
                />
              </div>
            </div>
          </div>
        )}

        {/* Modal for college portal details */}
        {Type == "portal" && (
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p className="personal-info left-line-card px-lg-4 px-4 py-4">
              College Portal Details
            </p>
            <div className="row m-0 p-4">
              <div className="col-lg-6 mb-lg-3 mobile-personal ">
                <TextField
                  fullWidth
                  label="College Login ID"
                  {...register("fld_loginId", {
                    // required: "This field is required",
                  })}
                  value={watch("fld_loginId") ? watch("fld_loginId") : ""}
                  error={errors?.fld_loginId}
                  helperText={
                    errors?.fld_loginId ? errors.fld_loginId.message : null
                  }
                />
              </div>
              <div className="col-lg-6 mb-lg-3 mobile-personal ">
                <TextField
                  fullWidth
                  label="Password"
                  {...register("fld_password", {
                    // required: "This field is required",
                  })}
                  value={watch("fld_password") ? watch("fld_password") : ""}
                  error={errors?.fld_password}
                  helperText={
                    errors?.fld_password ? errors.fld_password.message : null
                  }
                />
              </div>
              <div className="col-12 mb-lg-3 mobile-personal ">
                <TextField
                  fullWidth
                  label="Portal Link"
                  {...register("fld_portalUrl", {
                    // required: "This field is required",
                  })}
                  value={watch("fld_portalUrl") ? watch("fld_portalUrl") : ""}
                  error={errors?.fld_portalUrl}
                  helperText={
                    errors?.fld_portalUrl ? errors.fld_portalUrl.message : null
                  }
                />
              </div>
              <div className="col-12 mb-lg-3 mobile-personal ">
                <TextField
                  fullWidth
                  label="Other Details"
                  multiline
                  rows="3"
                  {...register("fld_otherDetails", {
                    // required: "This field is required",
                  })}
                  value={
                    watch("fld_otherDetails") ? watch("fld_otherDetails") : ""
                  }
                  error={errors?.fld_otherDetails}
                  helperText={
                    errors?.fld_otherDetails
                      ? errors.fld_otherDetails.message
                      : null
                  }
                />
              </div>
            </div>
          </div>
        )}

        {action != "view" && Type == "bank" && (
          <div className="card-vahani" style={{ marginTop: "26px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* does not allow form editing once the payment has been made */}
              <span
                className="text-danger"
                style={{
                  display: bankDetailsVerification === true ? "none" : "block",
                }}
              >
                Payment is already made on this bank account you can't edit
                this. You can add new account details.
              </span>
              <div className="but-width-form ms-auto">
                <Button
                  variant="contained"
                  // type={bankDetailsVerification === true ? "submit" : "button"}
                  type="button"
                  style={{
                    backgroundColor:
                      bankDetailsVerification === true
                        ? "rgb(32, 101, 209)"
                        : "#adad85",
                  }}
                  onClick={() => setType('portal')}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        )}
        {action != "view" && Type == "portal" && (
          <div className="card-vahani" style={{ marginTop: "26px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <div className="but-width-form">
                <Button variant="contained"
                  type="submit"
                  // type={bankDetailsVerification === false ? "submit" : "button"}
                  onClick={(e) => {
                    e.preventDefault();
                    onSave(watch()); // Pass the form data to onSave
                  }}
                >
                  Save & Update
                </Button>
              </div>
            </div>
          </div>
        )}
      </form>
      {/* } */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Bank Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/* {formStep === 2 && */}
          <form onSubmit={handleSubmit(onSave)}>
            {Type == "bank" && (
              <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
                <p className="personal-info left-line-card px-lg-4 px-4 py-4">
                  College Bank Details
                </p>
                <div className="row m-0 p-lg-4 p-3 ">
                  <div className="col-md-6 mb-lg-3 mobile-personal">
                    <TextField
                      fullWidth
                      label="Account Number*"
                      {...register("fld_accountNum", {
                        required: "This field is required",
                        pattern: {
                          value: new RegExp(ValidationList[10].fld_reg_ex, "i"),
                          message: "Enter a valid account number",
                        },
                        minLength: {
                          value: 9,
                          message: "Enter minimum 9-digit number",
                        },
                      })}
                      value={
                        watch("fld_accountNum") ? watch("fld_accountNum") : ""
                      }
                      error={errors?.fld_accountNum}
                      helperText={
                        errors?.fld_accountNum
                          ? errors.fld_accountNum.message
                          : null
                      }
                    />
                  </div>
                  <div className="col-md-6 mb-lg-3 mobile-personal">
                    <TextField
                      fullWidth
                      label="Account Holder Name*"
                      className="capital"
                      {...register("fld_accountName", {
                        required: "This field is required",
                        pattern: {
                          value: new RegExp(ValidationList[0].fld_reg_ex, "i"),
                          message: "Enter a valid name",
                        },
                      })}
                      value={
                        watch("fld_accountName") ? watch("fld_accountName") : ""
                      }
                      error={errors?.fld_accountName}
                      helperText={
                        errors?.fld_accountName
                          ? errors.fld_accountName.message
                          : null
                      }
                    />
                  </div>
                  <div className="col-md-6 mb-lg-3 mobile-personal capital-col ">
                    <TextField
                      fullWidth
                      label="Bank Name*"
                      className="capitalonly"
                      {...register("fld_bankName", {
                        required: "This field is required",
                        pattern: {
                          value: new RegExp(ValidationList[7].fld_reg_ex, "i"),
                          message: "Enter a valid bank name",
                        },
                      })}
                      value={watch("fld_bankName") ? watch("fld_bankName") : ""}
                      error={errors?.fld_bankName}
                      helperText={
                        errors?.fld_bankName
                          ? errors.fld_bankName.message
                          : null
                      }
                    />
                  </div>
                  <div className="col-md-6 mb-lg-3 mobile-personal">
                    <TextField
                      fullWidth
                      label="Branch Name*"
                      className="capital"
                      {...register("fld_branchName", {
                        required: "This field is required",
                        pattern: {
                          value: new RegExp(ValidationList[11].fld_reg_ex, "i"),
                          message: "Enter a valid branch name",
                        },
                      })}
                      value={
                        watch("fld_branchName") ? watch("fld_branchName") : ""
                      }
                      error={errors?.fld_branchName}
                      helperText={
                        errors?.fld_branchName
                          ? errors.fld_branchName.message
                          : null
                      }
                    />
                  </div>
                  <div className="col-lg-6 mb-lg-3 mobile-personal capital-col ">
                    <TextField
                      fullWidth
                      label="IFSC Code*"
                      className="capitalonly"
                      {...register("fld_ifscCode", {
                        required: "This field is required",
                        pattern: {
                          value: new RegExp(ValidationList[5].fld_reg_ex, "i"),
                          message: ValidationList[5].fld_reg_ex_msg,
                        },
                        maxLength: {
                          value: 11,
                          message: "Enter maximum 11-digit IFSC",
                        },
                        minLength: {
                          value: 11,
                          message: "Enter minimum 11-digit IFSC",
                        },
                      })}
                      value={watch("fld_ifscCode") ? watch("fld_ifscCode") : ""}
                      error={errors?.fld_ifscCode}
                      helperText={
                        errors?.fld_ifscCode
                          ? errors.fld_ifscCode.message
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {action != "view" && (
              <div className="card-vahani" style={{ marginTop: "26px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="text-danger">
                    If you add new bank details your current bank details will
                    get deactivated!
                  </span>

                  <div className="but-width-form ms-auto">
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </form>
          {/* } */}
        </Modal.Body>
      </Modal>
    </>
  );
}
