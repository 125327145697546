import React, { Component, useEffect, useState, useContext } from "react";
import { Edit3 } from "react-feather";
import Notiflix from "notiflix";

import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import moment from "moment";
import { Edit } from "react-feather";
import { Link } from "react-router-dom";

import { Button, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FaEdit } from "react-icons/fa";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import PostApiCall from "../../../assets/API/PostApi";
import store from "../../../store";
import { AiOutlineFileSearch } from "react-icons/ai";

export default function VendorBankList({ setShowVendorBankList }) {
  const [addBankData, setAddBankData] = useState([]);
  const {
    vendorGlobalId,
    setVendorGlobalId,
    bankId,
    setBankId,
    bankName,
    setBankName,
    accountHolderName,
    setAccountHolderName,
    branch,
    setBranch,
    accountNumber,
    setAccountNumber,
    ifsccode,
    setIfsccode,
    swiftCode,
    setSwiftCode,
    defaultBank,
    setDefaultBank,
  } = useContext(store);

  useEffect(() => {
    Notiflix.Loading.init({
      svgColor: "rgb(6, 10, 74)",
    });
    Notiflix.Loading.dots("Please wait...");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    PostApiCall.postRequest(
      {
        wherecalue: vendorGlobalId,
      },
      "GetVendorBanks"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setAddBankData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Notify.failure("error occurred!");
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {/* <div className="form-head mb-lg-2 mb-3">
        <div
          className="col-auto "
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>Bank List </h4>
        </div>
      </div> */}
      <div
        className={`card-vahani p-0 mt-2 ${
          addBankData.length != 0 ? "vendor-card-address" : ""
        }`}
      >
        <p className="personal-info m-0 left-line-card p-4 d-flex align-items-center justify-content-between">
          Bank List{" "}
          <div className="col-md-2 ps-0 pe-0">
            <Button
              variant="contained"
              className="w-100"
              onClick={() => {
                setBankId(null);
                setBankName("");
                setAccountNumber("");
                setBranch("");
                setAccountHolderName("");
                setIfsccode("");
                setSwiftCode("");
                setDefaultBank(false);
                setShowVendorBankList(false);
              }}
            >
              Add Bank Details
            </Button>
          </div>
        </p>

        {/* *** Carousel *** */}

        {addBankData.length != 0 ? (
          <Slider {...settings} className="mb-3">
            {addBankData?.map((bank) => {
              return (
                <div className="mx-3 my-3">
                  <div class="card mx-2">
                    <div class="card-body">
                      <h5 class="card-title">
                        <strong>{bank.fld_name}</strong>
                      </h5>
                      <p class="card-text mb-1 ">
                        <strong>{bank.fld_bank_name}</strong>
                      </p>
                      <p class="card-text mb-1">{bank.fld_account_number}</p>
                      <p class="card-text mb-1">{bank.fld_branch_name}</p>
                      <p class="card-text mb-1">{bank.fld_bank_holder_name}</p>
                      <p class="card-text mb-1">{bank.fld_ifsccode}</p>
                    </div>

                    <div class="card-footer">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-primary float-end"
                      >
                        <FaEdit
                          className="edit-icon edit-icon-font-size"
                          onClick={() => {
                            setBankId(bank.fld_id);
                            setVendorGlobalId(bank.fld_vendor_id);
                            setBankName(bank.fld_bank_name);
                            setAccountNumber(bank.fld_account_number);
                            setBranch(bank.fld_branch_name);
                            setAccountHolderName(bank.fld_account_holder_name);
                            setIfsccode(bank.fld_ifsccode);
                            setDefaultBank(bank.fld_default);
                            setSwiftCode(bank.fld_swiftc_code);
                            setShowVendorBankList(false);
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        ) : (
          <Col lg={12} className="justify-content-center d-flex">
            <div className="text-center w-25">
              <div className="justify-content-center no-data-icon mt-4">
                <AiOutlineFileSearch className="no-found-data-size" />
              </div>
              <h4>No data found</h4>
            </div>
          </Col>
        )}
      </div>
    </>
  );
}
