import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useContext, useState } from "react";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import logo from "../../assets/Image/Vahanilogo.png";
import logo2 from "../../assets/Image/vahanilogo2.png";
import store from "../../store";
import "./Sidebar.css";

// ********** Vendor Sidebar Icons ******************
import Po from "../../../src/assets/Image/vendor-image/AddNewPurchaseOrder.svg";
import Quotation from "../../../src/assets/Image/vendor-image/AddQuotation.svg";
import Home from "../../../src/assets/Image/vendor-image/home.png";
import Invoice from "../../../src/assets/Image/vendor-image/invoice.png";
import Project from "../../../src/assets/Image/vendor-image/ProjectList.svg";
import Rfq from "../../../src/assets/Image/vendor-image/RfqList.svg";
import Vendor from "../../../src/assets/Image/vendor-image/VendorComponets.svg";

const EmployeeSidebar = () => {
  const { drawerWidth, setdrawerWidth, Showdraw, setShowdraw } =
    useContext(store);
  const [open, setOpen] = useState(true);
  const [subcat1, setSubcat1] = useState(false);
  const [subcat, setSubcat] = useState(false);
  const [subcat2, setSubcat2] = useState(false);
  const [subcat4, setSubcat4] = useState(false);
  const [subcat5, setSubcat5] = useState(false);
  const [topc, settopc] = useState("slidertop2");
  // const [openmobile, setOpenmobile] = useState(true);
  const mobiledrawer = () => {
    setShowdraw(false);
  };
  const handleDrawerOpen = () => {
    settopc("topcno");
    setOpen(false);
    setdrawerWidth(70);
  };
  const handleDrawerclose = () => {
    settopc("slidertop2");
    setOpen(true);
    setdrawerWidth(290);
  };
  const NavList = () => {
    return (
      <List>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/vendor-dashboard"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Home}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Dashboard"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/project-list"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Project}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Projects"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/vendor-list"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Vendor}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Vendors"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/rfq-list"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Rfq}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="RFQs"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/quotation-list"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Quotation}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Quotations"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/purchase-order-list"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Po}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Purchase Orders"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={mobiledrawer}
          exact
          to="/purchase-Order-invoice-list"
          activeClassName="active"
          params={{ dataToSend: "55" }}
        >
          <ListItem disablePadding className="li-slide">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className="drawer-icon"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Invoice}
                  className="sidebar-icon sidebar-icon-x"
                />
              </ListItemIcon>
              <ListItemText
                className="active-sub"
                primary="Invoices"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ fontsize: "14px !important" }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>

      </List>
    );
  };
  return (
    <>
      <div className="drawer-laptop">
        <Drawer
          className="drawer-d smallImagesScroll"
          style={{ width: "drawerWidth", transition: " width 5s  all" }}
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <div className="swipe-col-dw">
            <div className="swipe-left-dw">
              <div onClick={handleDrawerclose}>
                {open ? (
                  <img
                    src={logo2}
                    alt="logo"
                    style={{
                      width: "100px",
                      // height: "40px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <img
                    src={logo}
                    alt="logo"
                    style={{ width: "40px", height: "40px", cursor: "pointer" }}
                  />
                )}
              </div>
              {open ? (
                <div onClick={handleDrawerOpen}>
                  <BsChevronDoubleLeft className="arrow-0pen" />
                </div>
              ) : null}
            </div>
            <div></div>
          </div>
          <NavList />
        </Drawer>
      </div>
      {Showdraw ? (
        <div className="drawer-moblie">
          <Drawer
            className="drawer-d"
            style={{ width: "drawerWidth", transition: " width 1s  all" }}
            sx={{
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <div className="swipe-col-dw">
              <div className="swipe-left-dw">
                <div>
                  <img
                    src={logo2}
                    alt="logo"
                    style={{
                      width: "100px",
                      // height: "40px",
                      cursor: "pointer",
                    }}
                  />
                </div>
                {open ? (
                  <div onClick={mobiledrawer}>
                    {" "}
                    <BsChevronDoubleLeft className="arrow-0pen" />{" "}
                  </div>
                ) : null}
              </div>
            </div>
            <NavList />
          </Drawer>
        </div>
      ) : null}
    </>
  );
};
export default EmployeeSidebar;
