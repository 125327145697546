import "../Personalinfo/Personalinfo.css";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import NumberFormat, { InputAttributes } from "react-number-format";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import PostApiCall from "../../../assets/API/PostApi";
// import GetApiCall from "../../../assets/API/GetApi";
// import moment from "moment";
import Notiflix from "notiflix";
import Family from "../../../assets/API/Data/Family.json";
import TextField from "@mui/material/TextField";
var addInfoId;
export default function AdditionalFamilyInfo({ stdid, action }) {
  var otp = Math.floor(100000 + Math.random() * 900000);
  var login = stdid ? { fld_id: stdid } : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  var ValidationList = JSON.parse(localStorage.getItem("Validation"));
  const [stuid, setstudid] = React.useState([]);
  const [fam, setstudidfam] = React.useState(null);
  const [salarySlip, setSalarySlip] = React.useState(null);
  const [sign, setSign] = React.useState([]);
  const [APIUrl, setAPIUrl] = useState(
    "https://admin.vahanischolarship.in/API/AddPdf2"
  );
  React.useEffect(() => {
    Notiflix.Loading.dots();
    GetStudentPersonalInfo();
    // statusupdate
  }, []);
  const GetStudentPersonalInfo = () => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentFamilyAdditionalInfo_ById"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (obj.data.length !== 0) {
          //
          setstudid(obj.data[0]);
          reset(obj.data[0]);
          setstudidfam(stuid.fld_additional_info_id);
          setSalarySlip(obj.data[0].fld_salarySlip);
        }
      })
    );
  };
  const { register, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm();
  const onPost = (familyadd) => {
    if (sign.length != 0) {
      Notiflix.Loading.dots();
      const form = new FormData();
      //
      form.append("file", sign);
      form.append("foldername", "FamilyInformation");
      form.append("filename", otp + "-FamilyAdditionalSign" + "-" + sign.name);
      fetch(APIUrl, {
        method: "POST",
        body: form,
      }).then((image) => {
        image
          .json()
          .then((data) => ({
            data: data,
            status: image.status,
          }))
          .then((res) => {
            Notiflix.Notify.success("Document Uploaded Successfully");
            Notiflix.Loading.remove();
            onPostAdditionalFamilyInfo(familyadd);
          });
      });
    } else {
      onPostAdditionalFamilyInfo(familyadd);
    }
  };
  const onPostAdditionalFamilyInfo = (familyadd) => {
    Notiflix.Loading.dots();
    var ids = stuid.fld_additional_info_id;
    //
    if (ids == null || ids == "" || ids == undefined) {
      addInfoId = null;
    } else {
      addInfoId = ids;
    }
    PostApiCall.postRequest(
      {
        studentid: login.fld_id,
        additionalinfoid: addInfoId,
        rationcard: familyadd.fld_rationCard,
        earningmember: familyadd.fld_earningMember,
        annualincome: familyadd.fld_annualIncome,
        salaryslip:
          sign.length == 0
            ? salarySlip
            : 'https://admin.vahanischolarship.in/Images/' +
            "FamilyInformation/" +
            otp +
            "-FamilyAdditionalSign" +
            "-" +
            sign.name,
        financiallydependent: familyadd.fld_financiallyDependent,
        typeoffamily: familyadd.fld_typeOfFamily,
        familyhouse: familyadd.fld_familyHouse,
        familyvehicle: familyadd.fld_familyVehicle,
        liveinfamilyhouse: familyadd.fld_liveInFamilyHouse,
        anyoneelsesupporting: familyadd.fld_anyoneElseSupporting,
        annualincomesupportingpeople:
          familyadd.fld_annualIncomeSupportingPeople,
      },
      "update_StudentFamilyAdditionalInfo"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Saved and Updated Successfully");
          GetStudentPersonalInfo();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!")
        }
      })
    );
  };
  const onSubmit = (familyadd) => {
    // e.preventDefault();
    onPost(familyadd);
    //
  };
  return (
    <>
      <div>
        <div className="form-head">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Additional Family Information</h4>
            {/* <p style={{ margin: "0px", fontSize: "0.9rem" }}>
              Drop files here or click browse thorough your machine
            </p> */}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-vahani p-0" style={{ marginTop: "24px" }}>
            <p className="personal-info left-line-card px-lg-4 px-3 py-4">
              Additional Family Information
            </p>
            <div className="row m-0 p-lg-4 p-2">
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Ration Card Details"
                  variant="outlined"
                  className="text-field"
                  {...register("fld_rationCard", { required: false })}
                  value={watch("fld_rationCard") ? watch("fld_rationCard") : ""}
                  error={errors?.fld_rationCard}
                  helperText={
                    errors?.fld_rationCard
                      ? errors.fld_rationCard.message
                      : null
                  }
                // InputLabelProps={{
                //   shrink: true,
                // }}
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal form-auto">
                <FormControl
                  fullWidth
                  error={Boolean(errors.fld_earningMember)}
                >
                  <InputLabel id="demo-simple-select-label">
                    Number of Earning Members In The Family*
                  </InputLabel>
                  <Select
                    labelId="labeld"
                    id="idlabel"
                    label="Number of Earning Members In The Family*"
                    {...register("fld_earningMember", {
                      required: "This field is required",
                    })}
                    error={errors?.fld_earningMember}
                    value={
                      watch("fld_earningMember")
                        ? watch("fld_earningMember")
                        : ""
                    }
                    onChange={(e) =>
                      setValue("fld_earningMember", e.target.value)
                    }
                  >
                    {Family.map((record) => (
                      <MenuItem value={record.value}>{record.value}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_earningMember
                      ? errors.fld_earningMember.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal form-auto">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Annual Family Income*"
                  variant="outlined"
                  className="text-field"
                  {...register("fld_annualIncome", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[6]?.fld_reg_ex, "i"),
                      message: "Enter a valid income",
                    },
                  })}
                  value={
                    watch("fld_annualIncome") ? watch("fld_annualIncome") : ""
                  }
                  error={errors?.fld_annualIncome}
                  helperText={
                    errors?.fld_annualIncome
                      ? errors.fld_annualIncome.message
                      : null
                  }
                />
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal form-auto">
                <FormControl
                  fullWidth
                  error={Boolean(errors.fld_financiallyDependent)}
                >
                  <InputLabel id="demo-simple-select-label">
                    Number of Financially Dependent Children In Family*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    label="Number of Financially Dependent Children In Family*"
                    {...register("fld_financiallyDependent", {
                      required: "This field is required",
                    })}
                    error={!!errors?.fld_financiallyDependent}
                    value={
                      watch("fld_financiallyDependent")
                        ? watch("fld_financiallyDependent")
                        : ""
                    }
                    onChange={(e) =>
                      setValue("fld_financiallyDependent", e.target.value)
                    }
                  >
                    {Family.map((record) => (
                      <MenuItem value={record.value}>{record.value}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors?.fld_financiallyDependent
                      ? errors.fld_financiallyDependent.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal form-auto">
                <FormControl fullWidth error={Boolean(errors.fld_typeOfFamily)}>
                  <InputLabel id="demo-simple-select-label">
                    Type of Family
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    label="Type of Family"
                    {...register("fld_typeOfFamily", {
                      required: false,
                    })}
                    error={!!errors?.fld_typeOfFamily}
                    value={
                      watch("fld_typeOfFamily") ? watch("fld_typeOfFamily") : ""
                    }
                    onChange={(e) =>
                      setValue("fld_typeOfFamily", e.target.value)
                    }
                  >
                    <MenuItem value={"joint family"}>Joint Family</MenuItem>
                    <MenuItem value={"Nuclear"}>Nuclear Family</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.fld_typeOfFamily
                      ? errors.fld_typeOfFamily.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal form-auto">
                <FormControl fullWidth error={Boolean(errors.fld_familyHouse)}>
                  <InputLabel id="demo-simple-select-label">
                    Does Your Family Own A House*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    label="Does Your Family Own A House*"
                    {...register("fld_familyHouse", {
                      required: "This field is required",
                    })}
                    error={errors?.fld_typeOfFamily}
                    value={
                      watch("fld_familyHouse") ? watch("fld_familyHouse") : ""
                    }
                    onChange={(e) =>
                      setValue("fld_familyHouse", e.target.value)
                    }
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.fld_familyHouse
                      ? errors.fld_familyHouse.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal form-auto">
                <FormControl
                  fullWidth
                  error={Boolean(errors.fld_familyVehicle)}
                >
                  <InputLabel id="demo-simple-select-label">
                    Does Your Family Own A Vehicle*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    label="Does Your Family Own A Vehicle*"
                    {...register("fld_familyVehicle", {
                      required: "This field is required",
                    })}
                    error={!!errors?.fld_familyVehicle}
                    value={
                      watch("fld_familyVehicle")
                        ? watch("fld_familyVehicle")
                        : ""
                    }
                    onChange={(e) =>
                      setValue("fld_familyVehicle", e.target.value)
                    }
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.fld_familyVehicle
                      ? errors.fld_familyVehicle.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 mb-lg-3 mobile-personal form-auto">
                <FormControl
                  fullWidth
                  error={Boolean(errors.fld_liveInFamilyHouse)}
                >
                  <InputLabel id="demo-simple-select-label">
                    Do You Live In The House That Your Family Owns*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    label="Do You Live In The House That Your Family Owns*"
                    {...register("fld_liveInFamilyHouse", {
                      required: "This field is required",
                    })}
                    error={!!errors?.fld_liveInFamilyHouse}
                    value={
                      watch("fld_liveInFamilyHouse")
                        ? watch("fld_liveInFamilyHouse")
                        : ""
                    }
                    onChange={(e) =>
                      setValue("fld_liveInFamilyHouse", e.target.value)
                    }
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.fld_liveInFamilyHouse
                      ? errors.fld_liveInFamilyHouse.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal form-auto">
                <FormControl
                  fullWidth
                  error={Boolean(errors.fld_anyoneElseSupporting)}
                >
                  <InputLabel id="demo-simple-select-label">
                    Is Anyone Else Supporting Your Education
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    // value={age} fld_annualIncomeSupportingPeople
                    label="Is Anyone Else Supporting Your Education"
                    {...register("fld_anyoneElseSupporting", {
                      required: false,
                    })}
                    error={errors?.fld_anyoneElseSupporting}
                    value={
                      watch("fld_anyoneElseSupporting")
                        ? watch("fld_anyoneElseSupporting")
                        : ""
                    }
                    onChange={(e) =>
                      setValue("fld_anyoneElseSupporting", e.target.value)
                    }
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.fld_anyoneElseSupporting
                      ? errors.fld_anyoneElseSupporting.message
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-lg-6 col-12 mb-lg-3 mobile-personal form-auto">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=" Total Annual Income of People Supporting Your Education*"
                  variant="outlined"
                  className="text-field"
                  {...register("fld_annualIncomeSupportingPeople", {
                    required: "This field is required",
                    pattern: {
                      value: new RegExp(ValidationList[6]?.fld_reg_ex, "i"),
                      message: "Enter a valid income",
                    },
                  })}
                  value={
                    watch("fld_annualIncomeSupportingPeople")
                      ? watch("fld_annualIncomeSupportingPeople")
                      : ""
                  }
                  helperText={
                    errors?.fld_annualIncomeSupportingPeople
                      ? errors.fld_annualIncomeSupportingPeople.message
                      : null
                  }
                  error={errors?.fld_annualIncomeSupportingPeople}
                />
              </div>
              <div
                className={
                  salarySlip == null
                    ? "col-lg-6 col-12 mobile-personal form-auto"
                    : "col-lg-5 form-auto"
                }
              >
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Income Certificate - Salary Slip (Maximum Size 6Mb | File Format .pdf .jpeg)"
                  variant="outlined"
                  className="text-field"
                  type="File"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ accept: "application/pdf, image/jpeg" }}
                  {...register("fld_Income", {
                    required: false,
                  })}
                  onChange={(e) => {
                    if (e.target.files[0].size < 6000000) {
                      const reader = new FileReader();
                      const file = e.target.files[0];
                      reader.onloadend = () => {
                        setSign(file);
                      };
                      reader.readAsDataURL(file);
                    } else {
                      Notiflix.Notify.failure(
                        "File too large, upload file less than 6 MB."
                      );
                    }
                  }}
                  error={!!errors?.fld_Income}
                  helperText={
                    errors?.fld_Income ? errors.fld_Income.message : null
                  }
                />
              </div>
              <div
                className="col-lg-1  mt-1"
                style={{
                  display: salarySlip == null ? "none" : "block",
                  textAlign: "center",
                }}
              >
                <a
                  target="_blank"
                  href={salarySlip}
                  alt="pdf"
                  className="but-login-vahani mt-lg-0 mt-1"
                >
                  View
                </a>
              </div>
            </div>
          </div>
          {action != "view" && (
            <div className="card-vahani" style={{ marginTop: "26px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <div className="but-width-form">
                  <Button
                    variant="contained"
                    className="but-login-vahani"
                    type="submit"
                  >
                    Save and update
                  </Button>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
