import DeleteIcon from "@mui/icons-material/Delete";
import {
  Backdrop,
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Modal as Modalantd } from "antd";

import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { useEffect, useRef, useState } from "react";
import FileViewer from "react-file-viewer";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FaDownload, FaEdit, FaEye } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import { Col } from "reactstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PostApiCall from "../../../assets/API/PostApi";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import Login from "../../Login/Login";
export default function AddNewPurchaseOrder() {
  let history = useHistory();
  let location = useLocation();
  const [open, setOpen] = React.useState(false); // modal controller for address and contact
  const [purchaseOrderDetailId, setPurchaseOrderDetailId] = useState(null);
  const inputRef = useRef(null);
  const [name, setName] = useState("");
  const [items, setItems] = useState([]);
  const [addTaskData, setTaskData] = useState([]); // this state holds data for tasks related to a project
  const [vendorList, setVendorList] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [narration, setNarration] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [description, setDescription] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [poDate, setPoDate] = useState(null); // PO date format was not correct before this
  const [showFields, setShowFields] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [addAddressData, setAddAddressData] = useState([]);
  const [addressContacts, setAddressContacts] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(0);
  const [selectedQuotation, setSelectedQuotation] = useState("");
  const [approvedQuotationsList, setApprovedQuotationsList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [selectedAddressText, setSelectedAddressText] = useState("");
  const [selectedContact, setSelectedContact] = useState(0);
  const [selectedContactText, setSelectedContactText] = useState("");

  const handleButtonClick = () => {
    setShowFields(true);
    setShowBtn(false);
    handleClose();
  };
  // const handleCheckboxChange = (event) => {
  //   const value = event.target.value;
  //   if (event.target.checked) {
  //     setCheckedItems([...checkedItems, value]);
  //   } else {
  //     const updatedItems = checkedItems.filter(item => item !== value);
  //     setCheckedItems(updatedItems);
  //   }

  // };
  const handleClick = () => {
    setOpenPreview(true);
  };
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  function getExtension(filename) {
    return filename?.split(".").pop();
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (location.state.id != null && location.state.id != undefined) {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          whereClause: `where fld_id=${location.state.id}`,
        },
        "GetPurchaseOrders"
        // "GetProjectsTasks"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            PostApiCall.postRequest(
              {
                whereClause:
                  " where fld_id in (select qm.fld_vendor_id from udv_quotations qm where qm.fld_status = 'Approved')",
              },
              "GetVendorMaster"
            ).then((results) =>
              results.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                  PostApiCall.postRequest(
                    {
                      whereClause: ` where vendor_id = ${obj.data[0].fld_vendor_id} and fld_status = 'Approved'`,
                    },
                    "GetQuotations"
                  ).then((results) =>
                    results.json().then((obj2) => {
                      if (results.status == 200 || results.status == 201) {
                        PostApiCall.postRequest(
                          {
                            whereClause: `${obj.data[0].fld_vendor_id}`,
                          },
                          "GetVendorAddress"
                        ).then((results) =>
                          results.json().then((obj3) => {
                            if (
                              results.status === 200 ||
                              results.status === 201
                            ) {
                              PostApiCall.postRequest(
                                {
                                  whereClause: `where fld_po_id = ${location.state.id}`,
                                  // whereClause: "",
                                },
                                "GetPurchaseOrders_Details"
                              ).then((results) =>
                                results.json().then((obj4) => {
                                  if (
                                    results.status === 200 ||
                                    results.status === 201
                                  ) {
                                    setTaskData(obj4.data);
                                    setPurchaseOrderDetailId(
                                      obj.data[0].fld_id
                                    );
                                  }
                                  Notiflix.Loading.remove();
                                })
                              );
                              setAddAddressData(obj3.data);
                            }
                            Notiflix.Loading.remove();
                          })
                        );

                        setDocumentUrl(obj2.data[0].fld_quotation_doc);
                        setApprovedQuotationsList(obj2.data);
                      }
                    })
                  );
                  setVendorList(obj1.data);
                }
              })
            );
            setShowFields(true);
            setShowBtn(true);
            setSelectedVendor(obj.data[0].fld_vendor_id);
            setSelectedQuotation(obj.data[0].fld_quotaion_id);
            setSelectedAddressText(obj.data[0].fld_po_address);
            setSelectedContactText(obj.data[0].fld_po_contact_person);
            setPoDate(moment(obj.data[0].fld_po_date).format("YYYY-MM-DD"));
            setNarration(obj.data[0].fld_narration);
            setPaymentTerms(obj.data[0].fld_payment_terms);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
          }
        })
      );
    } else {
      // in case of adding a PO
      PostApiCall.postRequest(
        {
          whereClause:
            " where fld_id in (select qm.fld_vendor_id from udv_quotations qm where qm.fld_status = 'Approved')",
        },
        "GetVendorMaster"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            setVendorList(obj.data);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
          }
        })
      );
    }
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const data = {
    columns: [
      {
        label: "S.No.",
        field: "serial",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Task Details",
        field: "taskdetails",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Description",
        field: "description",
        sort: "disabled",
        width: 400,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "disabled",
        width: 50,
      },
      {
        label: "GST%",
        field: "gstper",
        sort: "disabled",
        width: 50,
      },
      {
        label: "CGST",
        field: "cgst",
        sort: "disabled",
        width: 50,
      },
      {
        label: "SGST",
        field: "sgst",
        sort: "disabled",
        width: 50,
      },
      {
        label: "IGST",
        field: "igst",
        sort: "disabled",
        width: 50,
      },
      {
        label: "Budget",
        field: "budget",
        sort: "disabled",
        width: 20,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 20,
      },
    ],

    rows: addTaskData.map((data, i) => {
      if (location.state.id != null && location.state.id != undefined) {
        return {
          serial: i + 1,
          taskdetails: (
            <div>
              <b>Task Name: </b> {data.task_details}
            </div>
          ),
          description: (
            <div>
              <TextField
                fullWidth
                key={i}
                multiline={true}
                rows={3}
                className="text-field"
                type="text"
                value={data.fld_description}
                // required={true}
                InputLabelProps={{
                  shrink: false,
                }}
                onChange={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_description: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
                onBlurCapture={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_description: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
              />
            </div>
          ),
          amount: (
            <div>
              <TextField
                className="text-field"
                type="number"
                value={data.fld_amount}
                // required={true}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_amount: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
                onBlurCapture={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_amount: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
              />
            </div>
          ),
          gstper: (
            <div>
              <TextField
                className="text-field"
                value={data.fld_gst_rate}
                type="number"
                // required={true}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_gstper: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
                onBlurCapture={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_gstper: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
              />
            </div>
          ),
          cgst: (
            <div>
              <TextField
                fullWidth
                className="text-field"
                type="number"
                value={data.fld_cgst}
                // required={true}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_cgst: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
                onBlurCapture={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_cgst: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
              />
            </div>
          ),
          sgst: (
            <div>
              <TextField
                className="text-field"
                type="number"
                // required={true}
                value={data.fld_sgst}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_sgst: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
                onBlurCapture={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_sgst: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
              />
            </div>
          ),
          igst: (
            <div>
              <TextField
                className="text-field"
                type="number"
                // required={true}
                value={data.fld_igst}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_igst: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
                onBlurCapture={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_igst: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
              />
            </div>
          ),
          action: (
            <Button
              id="basic-button"
              className="text-danger"
              onClick={(e) => {
                const updatedData = [...addTaskData];

                // Find the index of the object to update
                const index = updatedData.findIndex(
                  (item) => item.fld_id === data.fld_id
                );

                if (index !== -1) {
                  // Remove the object from the copied array
                  updatedData.splice(index, 1);
                  setTaskData(updatedData);
                }
              }}
            >
              <DeleteIcon />
            </Button>
          ),
        };
      } else {
        return {
          serial: i + 1,
          taskdetails: (
            <div>
              <b>Project Name: </b> {data.project_name}
              <br />
              <b>Task Name: </b> {data.fld_task}
            </div>
          ),
          description: (
            <div>
              <TextField
                fullWidth
                key={i}
                multiline={true}
                rows={3}
                className="text-field"
                type="text"
                value={data.po_description}
                // required={true}
                InputLabelProps={{
                  shrink: false,
                }}
                onChange={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_description: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
                onBlurCapture={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_description: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
              />
            </div>
          ),
          amount: (
            <div>
              <TextField
                className="text-field"
                type="number"
                value={data.po_amount}
                // required={true}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_amount: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
                onBlurCapture={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_amount: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
              />
            </div>
          ),
          gstper: (
            <div>
              <TextField
                className="text-field"
                value={data.po_gstper}
                type="number"
                // required={true}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_gstper: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
                onBlurCapture={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_gstper: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
              />
            </div>
          ),
          cgst: (
            <div>
              <TextField
                fullWidth
                className="text-field"
                type="number"
                value={data.po_cgst}
                // required={true}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_cgst: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
                onBlurCapture={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_cgst: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
              />
            </div>
          ),
          sgst: (
            <div>
              <TextField
                className="text-field"
                type="number"
                // required={true}
                value={data.po_sgst}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_sgst: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
                onBlurCapture={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_sgst: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
              />
            </div>
          ),
          igst: (
            <div>
              <TextField
                className="text-field"
                type="number"
                // required={true}
                value={data.po_igst}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_igst: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
                onBlurCapture={(e) => {
                  const updatedData = [...addTaskData];

                  // Find the index of the object to update
                  const index = updatedData.findIndex(
                    (item) => item.fld_id === data.fld_id
                  );

                  if (index !== -1) {
                    // Update the object in the copied array
                    updatedData[index] = {
                      ...updatedData[index],
                      po_igst: e.target.value,
                    };

                    // Set state with the updated array
                    setTaskData(updatedData);
                  }
                }}
              />
            </div>
          ),
          budget: data.fld_budget,
          action: (
            <Button
              id="basic-button"
              className="text-danger"
              onClick={(e) => {
                const updatedData = [...addTaskData];

                // Find the index of the object to update
                const index = updatedData.findIndex(
                  (item) => item.fld_id === data.fld_id
                );

                if (index !== -1) {
                  // Remove the object from the copied array
                  updatedData.splice(index, 1);
                  setTaskData(updatedData);
                }
              }}
            >
              <DeleteIcon />
            </Button>
          ),
        };
      }
    }),
  };
  return (
    <>
      <div>
        <div className="form-head ">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Create New Purchase Order</h4>
          </div>
        </div>

        <div className="card-vahani p-0 pb-4 my-5">
          <p className="personal-info left-line-card px-lg-4 px-3 py-4">
            Purchase Order Details
          </p>
          <div class="toast-body">
            <div class="row">
              <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row ps-2 pe-3">
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <Box>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Select Vendor
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedVendor}
                              // required={true}
                              disabled={JSON.parse(localStorage.getItem("LoginDetail"))[0] // make it disable for accountant
                                .fld_type == "Accountant"}
                              label="Select Vendor"
                              onChange={(value) => {
                                setSelectedVendor(value.target.value);
                                setShowBtn(true); // make sddress button visible
                                // fetch quotations for the selected vendor
                                PostApiCall.postRequest(
                                  {
                                    whereClause: ` where vendor_id = ${value.target.value} and fld_status = 'Approved'`,
                                  },
                                  "GetQuotations"
                                ).then((results) =>
                                  results.json().then((obj) => {
                                    if (
                                      results.status == 200 ||
                                      results.status == 201
                                    ) {
                                      setApprovedQuotationsList(obj.data);
                                    }
                                  })
                                );
                                // fetch available addresses for the selected vendor
                                PostApiCall.postRequest(
                                  {
                                    whereClause: `${value.target.value}`,
                                  },
                                  "GetVendorAddress"
                                ).then((results) =>
                                  results.json().then((obj) => {
                                    if (
                                      results.status === 200 ||
                                      results.status === 201
                                    ) {
                                      setAddAddressData(obj.data);
                                    }
                                    Notiflix.Loading.remove();
                                  })
                                );
                              }}
                            >
                              {vendorList.map((record) => {
                                return (
                                  <MenuItem
                                    key={record.fld_name}
                                    value={record.fld_id}
                                  >
                                    {record.fld_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <Box>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-quo">
                              Select Quotation
                            </InputLabel>
                            <div className="d-flex">
                              <Select
                                labelId="demo-simple-select-quo"
                                id="demo-simple-select-quo"
                                value={selectedQuotation}
                                // required={true}
                                disabled={JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_type == "Accountant"}
                                label="Select Quotation"
                                className="w-100"
                                onClick={(value) => {
                                  Notiflix.Loading.dots();
                                  PostApiCall.postRequest(
                                    {
                                      rfqid: value.target.getAttribute("rfqid"),
                                    },
                                    "GetProjectTaskForQuotation"
                                  ).then((results) =>
                                    results.json().then((obj) => {
                                      if (
                                        results.status == 200 ||
                                        results.status == 201
                                      ) {
                                        PostApiCall.postRequest(
                                          {
                                            whereClause: ` where fld_rfq_id = ${value.target.getAttribute(
                                              "rfqid"
                                            )}`,
                                          },
                                          "GetQuotations"
                                        ).then((results) =>
                                          results.json().then((obj) => {
                                            if (
                                              results.status == 200 ||
                                              results.status == 201
                                            ) {
                                              setPaymentTerms(
                                                obj.data[0].fld_rfq_terms
                                              );
                                            }
                                          })
                                        );

                                        setTaskData(obj.data);
                                        setDocumentName(
                                          value.target.getAttribute("name")
                                        );
                                        setDocumentUrl(
                                          value.target.getAttribute("text")
                                        );
                                        setSelectedQuotation(
                                          value.target.getAttribute(
                                            "quotationid"
                                          )
                                        );
                                        Notiflix.Loading.remove();
                                      }
                                    })
                                  );
                                }}
                              >
                                {approvedQuotationsList.map((record) => {
                                  return (
                                    <MenuItem
                                      name={record.fld_filename}
                                      text={record.fld_quotation_doc}
                                      rfqid={record.fld_rfq_id}
                                      quotationid={record.fld_id}
                                      key={record.fld_id}
                                      value={record.fld_id}
                                    >
                                      {record.fld_quotation_number}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              <Link to={{}} className="view-order-doc">
                                <FaEye
                                  className="fs-4 edit-icon edit-icon-font-size"
                                  onClick={() => {
                                    handleClick();
                                  }}
                                />
                              </Link>
                            </div>
                          </FormControl>
                        </Box>
                      </div>

                      <div className="col-lg-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          className="text-field"
                          type="date"
                          value={poDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={JSON.parse(localStorage.getItem("LoginDetail"))[0] // make it disable for accountant
                            .fld_type == "Accountant"}
                          // required={true}
                          label="Purchase Order Date"
                          onChange={(e) => setPoDate(e.target.value)}
                        />
                      </div>


                      <>
                        {showBtn === true ? (
                          <Button
                            className="col-12 mb-4 text-center"
                          >

                            <Button variant="contained"
                              disabled={JSON.parse(localStorage.getItem("LoginDetail"))[0] // make it disable for accountant
                                .fld_type == "Accountant"}
                              onClick={handleOpen}
                            >
                              Select Address And Contact Person
                            </Button>
                          </Button>
                        ) : (
                          <div
                            className="col-12 mb-4 text-center"
                            onClick={handleOpen}
                          />
                        )}
                      </>


                      {showFields && (
                        <div className="col-lg-6 mb-lg-4 mobile-personal position-relative">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: selectedAddressText,
                            }}
                          ></div>
                          {JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_type != "Accountant" &&
                            <div class="position-absolute check-box-po-btn top-0">
                              <Link to={{}}>
                                <FaEdit className="fs-4 edit-icon edit-icon-font-size" />
                              </Link>
                            </div>
                          }
                        </div>
                      )}
                      {showFields && (
                        <div className="col-lg-6 mb-lg-4 mobile-personal position-relative">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: selectedContactText,
                            }}
                          ></div>
                          {JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_type != "Accountant" &&
                            <div class="position-absolute check-box-po-contact-btn top-0">
                              <Link to={{}}>
                                <FaEdit className="fs-4 edit-icon edit-icon-font-size" />
                              </Link>
                            </div>
                          }
                        </div>
                      )}

                      <div className="col-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          type="text"
                          label="Narration"
                          // required={true}
                          disabled={JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_type == "Accountant"}
                          multiline
                          value={narration}
                          rows={7}
                          className="text-field capitalonly"
                          onChange={(e) => {
                            setNarration(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-6 mb-lg-4 mobile-personal">
                        <TextField
                          fullWidth
                          type="text"
                          label="Payment Terms"
                          // required={true}
                          disabled={JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_type == "Accountant"}
                          multiline
                          rows={7}
                          value={paymentTerms}
                          className="text-field capitalonly"
                          onChange={(e) => {
                            setPaymentTerms(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
          <div className="form-head mb-lg-2 mb-3"></div>
        </div>
        <div className="card-vahani p-0 mt-2">
          <p className="personal-info m-0 left-line-card p-4 d-flex align-items-center justify-content-between">
            Select Tasks{" "}
          </p>
          <div className="p-3">
            {addTaskData.length > 0 ? (
              <MDBDataTable
                responsive
                hover
                disableRetreatAfterSorting={true}
                data={data}
                entriesOptions={[10, 25, 50, 100]}
              >
                <MDBTableHead columns={data.columns} />
                <MDBTableBody rows={data.rows} />
              </MDBDataTable>
            ) : (
              <Col lg={12}>
                <div className="text-center">
                  <div className="justify-content-center no-data-icon mt-4">
                    <AiOutlineFileSearch className="no-found-data-size" />
                  </div>
                  <h4>No data found</h4>
                </div>
              </Col>
            )}
          </div>
        </div>
      </div>
      <div className="card-vahani p-0 my-5">
        <div
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          data-toggle="toast"
        >
          <div
            className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-3"
            style={{ float: "right" }}
          >
            {JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_type != "Accountant" &&
              (
                <div className="d-flex justify-content-end">
                  <Button
                    variant="contained"
                    onClick={() => {
                      Notiflix.Loading.init({
                        svgColor: "rgb(6,10,74)",
                      });
                      Notiflix.Loading.dots("Please wait...");
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });

                      PostApiCall.postRequest(
                        {
                          id: location.state.id,
                          vendorid: selectedVendor,
                          quotaionid: selectedQuotation,
                          pono: null,
                          podate: poDate,
                          narration: narration,
                          postatus: "New",
                          poaddress: selectedAddressText,
                          paymentterms: paymentTerms,
                          userid: JSON.parse(localStorage.getItem("LoginDetail"))[0]
                            .fld_id,
                          pocontactperson: selectedContactText,
                        },
                        "UpdatePurchaseOrders"
                      ).then((results) =>
                        results.json().then((obj) => {
                          if (results.status === 200 || results.status === 201) {
                            PostApiCall.postRequest(
                              {
                                id: purchaseOrderDetailId,
                                // poid: location.state.id,
                                poid: obj.data[0]?.poid,
                                taskdetails: addTaskData,
                                userid: JSON.parse(
                                  localStorage.getItem("LoginDetail")
                                )[0].fld_id,
                              },
                              "UpdatePurchaseOrderDetails"
                            ).then((results) =>
                              results.json().then((obj) => {
                                if (
                                  results.status === 200 ||
                                  results.status === 201
                                ) {
                                  Notiflix.Notify.success(
                                    "PO Created successfully"
                                  );
                                  history.push('/purchase-order-list') // route back to the list
                                  Notiflix.Loading.remove();
                                } else {
                                  Notiflix.Notify.failure("Error Occurred");
                                  Notiflix.Loading.remove();
                                }
                              })
                            );
                          } else {
                            Notiflix.Notify.failure("Error Occurred");
                            Notiflix.Loading.remove();
                          }
                        })
                      );
                    }}
                  >
                    Save
                  </Button>
                </div>

              )
            }

          </div>
        </div>
      </div>
      {/* ******** Preview Doc********** */}

      <Modalantd
        centered
        open={openPreview}
        onOk={() => setOpenPreview(false)}
        onCancel={() => setOpenPreview(false)}
        width={700}
      >
        <h4>Preview Document</h4>

        <div className="row">
          <div className="col-12">
            <div className="mx-2 my-3 w-100">
              <div class="card me-3 quotaion-doc-slider position-relative">
                <div class="card-body p-0">
                  <div className="quotation-img-preview">
                    <FileViewer
                      fileType={getExtension(documentName)}
                      filePath={documentUrl}
                    />
                  </div>
                  <p className="mt-3 text-center px-1">
                    <a
                      href={documentUrl}
                      download={getExtension(documentName)}
                      target="_blank"
                    >
                      <FaDownload></FaDownload>{" "}
                    </a>
                    {documentName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modalantd>

      {/* *** Open Modal On click Address for contact person for Select Address and contact details *** */}
      <Modalantd
        open={open}
        onCancel={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        width={1000}
        onOk={() => handleButtonClick()}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        {/* ****** Select Vendor Address ******** */}

        <p className="personal-info left-line-card mx-0 mb-0 pt-4 px-4 pb-1 d-flex align-items-center justify-content-between">
          Address List{" "}
        </p>
        <Slider {...settings} className="mb-3">
          {addAddressData?.map((address) => {
            return (
              <div className="mx-3 my-3 contact-show-draw">
                <div class="card mx-2 position-relative top-0">
                  <div class="card-body">
                    <p class="card-text mb-1 ">{address.fld_industry}</p>
                    <p class="card-text mb-1">
                      {address.fld_address1},
                      <br />
                      {address.fld_address2},
                      <br />
                      {address.fld_address3}, {address.fld_country_name},
                      {address.fld_state_name}, {address.fld_city_name},
                      {address.fld_pincode}
                    </p>
                    <p class="card-text mb-1">
                      <strong>Landmark</strong>
                    </p>
                    <p class="card-text mb-1">{address.fld_landmark}</p>
                  </div>

                  <div class="form-check position-absolute check-box-po-btn">
                    <input
                      class="form-check-input fs-4"
                      type="radio"
                      name="addressselect"
                      value=""
                      id="flexCheckDefault"
                      onChange={(e) => {
                        setSelectedAddress(address.fld_id);
                        setSelectedAddressText(`<div class="card-body">
                                  <p class="card-text mb-1 ">${address.fld_industry}</p>
                                  <p class="card-text mb-1">
                                    ${address.fld_address1},
                                    <br />
                                    ${address.fld_address2},
                                    <br />
                                    ${address.fld_address3}, ${address.fld_country_name},
                                    ${address.fld_state_name}, ${address.fld_city_name},
                                    ${address.fld_pincode}
                                  </p>
                                  <p class="card-text mb-1">
                                    <strong>Landmark</strong>
                                  </p>
                                  <p class="card-text mb-1">${address.fld_landmark}</p>
                                </div>`);
                        PostApiCall.postRequest(
                          {
                            wherecaluse: address.fld_id,
                          },
                          "GetVendorContacts"
                        ).then((results) =>
                          results.json().then((obj) => {
                            if (
                              results.status === 200 ||
                              results.status === 201
                            ) {
                              setAddressContacts(obj.data);
                            }
                            Notiflix.Loading.remove();
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>

        {/*  ********** Contact Person List *********** */}
        <p className="personal-info m-0 pt-4 left-line-card px-4 pb-1 d-flex align-items-center justify-content-between">
          Contact List
        </p>
        <Slider {...settings} className="mb-3">
          {addressContacts.length != 0
            ? addressContacts?.map((contact) => {
              return (
                <div className="mx-3 my-3 contact-show-draw">
                  <div class="card mx-2 position-relative top-0">
                    <div class="card-body">
                      <h5 class="card-title">
                        <strong>{contact.fld_contact_name}</strong>
                      </h5>
                      <p class="card-text mb-1">{contact.fld_designation}</p>
                      <p class="card-text mb-1">{contact.fld_email}</p>
                      <p class="card-text mb-1">{contact.fld_mobile}</p>
                      <p class="card-text mb-1">{contact.fld_vendor_name}</p>
                    </div>

                    <div class="form-check position-absolute check-box-po-btn">
                      <input
                        class="form-check-input fs-4"
                        type="radio"
                        name="contactlist"
                        value=""
                        id="flexCheckDefault"
                        onChange={(e) => {
                          setSelectedContact(contact.fld_id);
                          setSelectedContactText(`<div class="card-body">
                                  <h5 class="card-title">
                                    <strong>${contact.fld_contact_name}</strong>
                                  </h5>
                                  <p class="card-text mb-1">${contact.fld_designation}</p>
                                  <p class="card-text mb-1">${contact.fld_email}</p>
                                  <p class="card-text mb-1">${contact.fld_mobile}</p>
                                  <p class="card-text mb-1">${contact.fld_vendor_name}</p>
                                </div>`);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })
            : ""}
        </Slider>
      </Modalantd>
    </>
  );
}
