import React, { Component } from "react";

import Notiflix from "notiflix";
import GetApiCall from "../../../../assets/API/GetApi";
import PostApiCall from "../../../../assets/API/PostApi";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { XSquare } from "react-feather";

const ImgUpload = ({ onChange, onCancel, src }) => (
  <label htmlFor="photo-upload" className="custom-file-upload fas">
    <div className="img-wrap img-upload">
      <img
        for="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>

    <input id="photo-upload" type="file" onChange={onChange} />

    <XSquare class="product-img" onClick={onCancel}></XSquare>
  </label>
);

class ViewLeave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PageTitle: "1",
      Page1: "Pending",
      Question: "",
      Answer: "",
      Data: [],
      Details: [],
      QuestionId: [],
      InformationData: [],
      Name: "",
      Type: "",
      Notes: "",
      Days: "",
      StartDate: "",
      EndDate: "",
      LeaveData: [
        {
          value: "Privilege Leave/Earned Leave/Annual Leave",
          label: "Privilege Leave/Earned Leave/Annual Leave",
        },
        { value: "Casual Leave", label: "Casual Leave" },
        { value: "Sick Leave", label: "Sick Leave" },
        { value: "Maternity Leave", label: "Maternity Leave" },
        { value: "Marriage Leave", label: "Marriage Leave" },
        { value: "Paternity Leave", label: "Paternity Leave" },
        { value: "Bereavement Leave", label: "Bereavement Leave" },
        { value: "Compensatory Off", label: "Compensatory Off" },
        { value: "Loss Of Pay Leave", label: "Loss Of Pay Leave" },
      ],
      NameData: [],
      NumRegex: /^[0-9]*$/,
      Details: [],
      Status: "approved",
      ReasonOfRejection: "",
      LeaveId: null,
      LeaveType: "",
      LeaveDays: 0,
      StartDate: "",
      EndDate: "",
      LeavesNotes: "",
      ReasonOfRejection: "",
      EmpName: "",
      EmpId: null,
      RaisedOn: "",
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "/assets/js/pages/form-wizard.init.js";
    script.async = true;
    document.body.appendChild(script);

    Notiflix.Loading.init({
      svgColor: "#060a4a",
    });

    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);

    this.setState({
      Details: details[0],
    });

    var det = localStorage.getItem("dayoffdetail");

    this.setState({
      LeaveId:
        this.props.location.state === undefined
          ? 0
          : this.props.location.state.leaveid,
    });
    if (this.props.location.state != undefined) {
      this.getLeaveDetails();
    }
  }

  getLeaveDetails = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_id=${this.props.location.state.leaveid}`,
      },
      "GetLeaveById"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        Notiflix.Loading.dots("Please wait...");
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            this.setState({
              EmpId: obj.data[0].fld_emp_id,
              EmpName: obj.data[0].fld_employee_name,
              LeaveType: obj.data[0].fld_leave_type,
              LeaveDays: obj.data[0].fld_leave_days,
              StartDate: obj.data[0].fld_star_date,
              EndDate: obj.data[0].fld_end_date,
              RaisedOn: obj.data[0].fld_raised_date,
              LeavesNotes: obj.data[0].fld_leave_notes,
              ReasonOfRejection: obj.data[0].fld_reason_of_rejection,
              Status: obj.data[0].fld_status,
            });
            Notiflix.Loading.remove();
          }
        }
        Notiflix.Loading.remove();
      })
    );
  };

  render() {
    return (
      <>
        <div className="form-head mb-lg-2 mb-3">
          <div
            className="col-auto "
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>View Leave</h4>
          </div>
        </div>
        <div className="card-vahani p-0 mt-2">
          <div className="row page-title"></div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body p-0">
                  <div id="smartwizard-arrows">
                    <p className="personal-info m-0 left-line-card p-4">
                      Applied Leave
                    </p>

                    <div className="p-3" style={{ minHeight: "0px" }}>
                      <div
                        id="sw-arrows-step-1"
                        className="tab-pane step-content"
                        style={{
                          display:
                            this.state.PageTitle == "1" ? "block" : "none",
                        }}
                      >
                        <form
                          className="needs-validation"
                          novalidate
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <div
                            className="vahani-card text-start"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                            data-toggle="toast"
                          >
                            <div class="toast-body">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="form-group mb-2">
                                        <label for="validationCustom05">
                                          Leave Type
                                          <span className="mandatory">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="validationCustom05"
                                          value={this.state.LeaveType}
                                          readonly
                                        />
                                      </div>
                                    </div>

                                    <div class="col-md-6">
                                      <div class="form-group mb-2">
                                        <label for="validationCustom05">
                                          Total Days
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="validationCustom05"
                                          value={this.state.LeaveDays}
                                          readonly
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group mb-2">
                                        <label for="validationCustom05">
                                          Status
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="validationCustom05"
                                          value={this.state.Status}
                                          readonly
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group mb-2">
                                        <label for="validationCustom05">
                                          Start Date
                                          <span className="mandatory">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={moment(
                                            this.state.StartDate
                                          ).format("DD/MM/YYYY")}
                                          readonly
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group mb-2">
                                        <label for="validationCustom05">
                                          End Date
                                          <span className="mandatory">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={moment(
                                            this.state.EndDate
                                          ).format("DD/MM/YYYY")}
                                          readonly
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-12">
                                      <div class="form-group mb-2">
                                        <label for="validationCustom05">
                                          Leave Notes
                                          <span className="mandatory">*</span>
                                        </label>
                                        <textarea
                                          type="text"
                                          class="form-control"
                                          id="validationCustom05"
                                          value={this.state.LeavesNotes}
                                          readonly
                                        />
                                      </div>
                                    </div>

                                    <div
                                      class="col-md-12"
                                      style={{
                                        display:
                                          this.state.Status == "Rejected"
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <div class="form-group mb-2">
                                        <label for="validationCustom05">
                                          Reason Of Rejection
                                          <span className="mandatory">*</span>
                                        </label>
                                        <textarea
                                          type="text"
                                          class="form-control"
                                          id="validationCustom05"
                                          value={this.state.ReasonOfRejection}
                                          onChange={(e) => {
                                            this.setState({
                                              ReasonOfRejection: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>{" "}
                                {/* end col-md-12 */}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ViewLeave;
