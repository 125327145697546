import React, { useEffect, useState } from "react";
import Notiflix from "notiflix";
import PostApiCall from "../../../src/assets/API/PostApi";
import {
  MenuItem,
  TextField,
  Checkbox,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput,
  Button,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function AskQuestion() {
  const [question, setQuestion] = useState("");

  function onAddNewQuestion() {
    if (question != "") {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          id: null,
          question: question,
          scholarid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_id,
          questionerprofilepic: JSON.parse(
            localStorage.getItem("LoginDetail")
          )[0].profile_pic,
          createdon: new Date(),
          updatedon: new Date(),
        },
        "AddQuestion"
      ).then((resultcategory) =>
        resultcategory.json().then((obj) => {
          if (resultcategory.status == 200 || resultcategory.status == 201) {
            Notiflix.Notify.success("Question Successfully added.");
            Notiflix.Loading.remove();
            window.history.go(-1);
          } else {
            Notiflix.Notify.failure(obj.data);
            Notiflix.Loading.remove();
          }
        })
      );
    } else {
      Notiflix.Notify.failure("Please Enter the question!");
    }
  }
  return (
    <>
      <div>
        <div className="form-head ">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Ask Question</h4>
          </div>
        </div>
        <div className="card-vahani p-0 pb-4 ">
          <p className="personal-info left-line-card px-lg-4 px-3 py-4">
            Add Question
          </p>
          <div class="toast-body">
            <div class="row">
              <div class="col-md-12 px-3 pb-0 pt-3 ps-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row ps-2 pe-3">
                      <div class="row pe-0">
                        <div className="col-lg-12 mb-lg-3 mobile-personal">
                          <TextField
                            fullWidth
                            type="text"
                            label="Question"
                            required={true}
                            className="text-field capitalonly"
                            InputProps={{ inputProps: { min: 1 } }}
                            value={question}
                            onChange={(e) => {
                              setQuestion(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
          <div
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-toggle="toast"
          >
            <div
              className="btn-toolbar sw-toolbar sw-toolbar-top justify-content-right pb-3 pt-3 pe-3"
              style={{ float: "right" }}
            >
              <div className=" justify-content-end" style={{ display: "flex" }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    onAddNewQuestion();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
