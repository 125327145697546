import React, { useEffect, useState } from "react";
import "./FirstPage.css";
import Login from "./Login/Login";
import Register from "./Register/Register";

import CancelIcon from "@mui/icons-material/Cancel";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import GetApiCall from "../assets/API/GetApi";

const FirstPage = () => {
  // get the registration status 
  const [toggle, settoggle] = useState(false);
  // const [registrationOpen, setRegistrationOpen] = useState(false);
  const [VideoToggle, setVideoToggle] = useState(false);

  // get the registraation status from the api 
  useEffect(() => {
    GetApiCall.getRequest("Get_RegistrationMaster").then((resultdes) =>
      resultdes.json().then((obj) => {
        settoggle(obj.data[0].fld_registrationStatus
          == "YES" ? true : false)
      })
    );
  }, []);

  return (
    <>
      <div className="row align-items-lg-center align-items-end m-0 firstpage-bg">
        <div
          style={{
            maxHeight: "90vh",
          }}
          className={`${toggle ? "col-md-5" : "col-md-4"
            } offset-lg-1 card-vahani text-center`}
        >
          {toggle ? <Register /> : <Login />}

          {
            toggle ? (
              <div className="d-lg-flex justify-content-between mx-3">
                <p>
                  Already have an account?{" "}
                  <span
                    className="cursor-pointer text-primary"
                    onClick={() => settoggle(!toggle)}
                  >
                    Login Now
                  </span>
                </p>
                <p
                  className="cursor-pointer text-primary mb-0"
                  onClick={() => setVideoToggle(true)}
                >
                  Watch Application Tutorial
                </p>
              </div>
            ) :
              toggle === true ?
                <span
                  className="cursor-pointer text-primary"
                  onClick={() => settoggle(!toggle)}
                >
                  Register Now
                </span>

                : (
                  <p>Registration is closed.</p>

                )
            // <p>Don’t have an account? <span className="cursor-pointer text-primary" onClick={() => settoggle(!toggle)}>Register Now</span></p>
          }
        </div>
        <div className="position-fixed bottom-0 d-lg-flex justify-content-between align-items-center px-lg-5 login-footer mt-lg-0 mt-3 py-lg-2 py-2">
          <p className="text-center mb-lg-0 mb-0">Powered by Global Trendz</p>
          <p className="text-center mb-lg-0 mb-0">
            Copyright © {new Date().getFullYear()} Vahani Scholarship.{" "}
            <span className="d-lg-inline-block d-block">
              All rights reserved.
            </span>
          </p>
        </div>
      </div>
      <Dialog
        open={VideoToggle}
        PaperProps={{
          sx: {
            width: "100%",
            height: "auto",
          },
        }}
        onClose={() => setVideoToggle(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className="d-flex justify-content-between"
          id="alert-dialog-title"
        >
          <h6>Vahani Scholarship Application Tutorial</h6>{" "}
          <CancelIcon
            className="cursor-pointer"
            onClick={() => setVideoToggle(false)}
          />
        </DialogTitle>
        <DialogContent>
          <div>
            <iframe
              style={{ aspectRatio: "16/9", width: "100%" }}
              width="100%"
              height="400px"
              src="https://www.youtube.com/embed/gRqcuHfcDLo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default FirstPage;