import Notiflix from "notiflix";
var urlString = "https://admin.vahanischolarship.in/API/";
// var urlString = "http://localhost:8020/API/";
//var urlString = "https://dev.vahanischolarship.in/API/";
const PostApiCall = {
  postRequest(userData, url) {
    return fetch(urlString + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
        "x-auth-token": JSON.parse(localStorage.getItem("access")),
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location.href = "/";
          Notiflix.Notify.failure("Session Expired. Login Again");
          //comment
        } else {
          return response;
        }
      })
      .catch((error) => {
        ////
        return error;
      });
  },
};
export default PostApiCall;
