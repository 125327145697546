import { height } from '@mui/system';
import React from 'react';
import ErrorImg from '../assets/Image/error404.png'
const PageNotFound = () => {
    return (
        <div className='row m-0 justify-content-center '>
            <div className='col-6'>
                <img src={ErrorImg} width="100%" />
            </div>
        </div>
    )
}
export default PageNotFound