import React, { useState, useContext } from "react";
import {
  Table,
  FormControl,
  FormLabel,
  Tooltip,
  Button,
  Select,
  FormControlLabel,
  MenuItem,
  FormHelperText,
  InputLabel,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { MDBDataTable } from "mdbreact";
import PostApiCall from "../../../assets/API/PostApi";
// import GetApiCall from "../../../assets/API/GetApi";
import moment from "moment";
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import "../Personalinfo/Personalinfo.css";
export default function Preference({ stdid, action }) {
  var login = stdid
    ? { fld_id: stdid }
    : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  const [Prefrence, setPrefrence] = React.useState([]);
  const [showPrefrence, setshowPrefrence] = React.useState(false);
  const [masterPrefrence, setmasterPrefrence] = React.useState([]);
  const [disabledPrefrenece, setdisabledPrefrenece] = React.useState(false);
  const [stuid, setstudid] = React.useState(null);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  var count = 0;
  var year = [];
  for (var i = 2000; i <= 2050; i++) {
    year.push({
      label: i,
      value: i,
    });
  }
  React.useEffect(() => {
    PostApiCall.postRequest(
      {
        countryid: 101,
      },
      "GetState"
    ).then((results) =>
      results.json().then((objstate) => {
        if (results.status == 200 || results.status == 201) {
          setState(objstate.data);
          setCity([]);
        }
        Notiflix.Loading.remove();
      })
    );
    Get_PreferenceMaster_ByStudentId(login.fld_id);
    Get_StudentCollegePreferenceInformation_ByStudentId();
  }, []);
  const Get_PreferenceMaster_ByStudentId = (idmaster) => {
    // //
    PostApiCall.postRequest(
      {
        studentId: idmaster,
      },
      "Get_PreferenceMaster_ByStudentId"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          // if (obj.data.length !== 0) {
          setmasterPrefrence(obj.data);
          //
          // }
        }
      })
    );
  };
  const Get_StudentCollegePreferenceInformation_ByStudentId = () => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "Get_StudentCollegePreferenceInformation_ByStudentId"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          if (obj.data.length !== 0) {
            setPrefrence(obj.data);
            // setCityid(obj.data[0].fld_city);
            //
          }
        }
      })
    );
  };
  const [values, setValues] = React.useState({
    fld_institutename: "",
    fld_preference: "",
    fld_city: "",
    fld_state: "",
    fld_degree: "",
  });
  const onPost = (Preference) => {
    //
    Notiflix.Loading.dots();
    var detail = JSON.parse(localStorage.getItem("LoginDetail"))[0];
    PostApiCall.postRequest(
      {
        id: stuid,
        studentid: login.fld_id,
        preference: Preference.fld_preference,
        InstituteName: Preference.fld_institutename,
        Degree: Preference.fld_degree,
        city: Preference.fld_city,
        state: Preference.fld_state,
        updatedon: moment().format("lll"),
        updatedby: detail.fld_id,
      },
      "UpdateStudentCollegePreferenceInformation"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Get_StudentCollegePreferenceInformation_ByStudentId();
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Saved and Updated Successfully");
          setdisabledPrefrenece(false);
          reset();
          window.location.reload();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
          reset();
        }
      })
    );
  };
  //  ----city
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    onPost(data);
  };
  //
  return (
    <>
      <div className="form-head">
        <div
          style={{
            borderLeft: "10px solid rgb(32, 101, 209)",
            paddingLeft: "10px",
          }}
        >
          <h4>University / College Preference</h4>
        </div>
      </div>
      <div className="card-vahani p-0">
        <div className="row justify-content-between align-items-center m-0 left-line-card px-4 py-3 ">
          <p className="personal-info m-0 p-0 col-auto ">
            {" "}
            University / College Preference List
          </p>
          {/* <Link className=' btn btn-primary col-auto' onClick={() => setaccomodationData({ action: "add" })} to='/add-accomodation' > Create New +</Link> */}
        </div>
        <div className="px-4">
          {Prefrence.length > 0 ? (
            <MDBDataTable
              responsive
              hover
              striped
              disableRetreatAfterSorting={true}
              data={{
                columns: [
                  {
                    label: "Preference",
                    field: "preference",
                    sort: "disabled",
                  },
                  {
                    label: "Name of Institute",
                    field: "instituteName",
                    sort: "disabled",
                  },
                  {
                    label: "Degree",
                    field: "degree",
                    sort: "disabled",
                  },
                  {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                  },
                ],
                rows: Prefrence.map((data, i) => {
                  return {
                    preference: data.fld_preference,
                    instituteName: data.fld_institutename,
                    degree: data.fld_degree,
                    action: (
                      <>
                        <Tooltip title="Edit">
                          <EditIcon
                            color="primary"
                            onClick={() => {
                              Notiflix.Loading.dots();
                              PostApiCall.postRequest(
                                {
                                  stateid: data.fld_state,
                                },
                                "GetCity"
                              ).then((results) =>
                                results.json().then((objcity) => {
                                  if (
                                    results.status == 200 ||
                                    results.status == 201
                                  ) {
                                    setCity(objcity.data);
                                    setstudid(data.fld_id);
                                    reset(data);
                                    Get_PreferenceMaster_ByStudentId(null);
                                    setdisabledPrefrenece(true);
                                  }
                                  Notiflix.Loading.remove();
                                })
                              );
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Delete">
                          <DeleteForeverIcon
                            color="primary"
                            onClick={() => {
                              Notiflix.Loading.dots();
                              var detail = JSON.parse(
                                localStorage.getItem("LoginDetail")
                              )[0];
                              PostApiCall.postRequest(
                                {
                                  id: data.fld_id,
                                  studentid: data.fld_studentId,
                                  preference: data.fld_preference,
                                  InstituteName:
                                    data.fld_institutename
                                      .charAt(0)
                                      .toUpperCase() +
                                    data.fld_institutename.substr(1),
                                  Degree: data.fld_degree,
                                  city: data.fld_city,
                                  state: data.fld_state,
                                  status: "Delete",
                                  updatedon: moment().format("lll"),
                                  updatedby: detail.fld_id,
                                },
                                "UpdateStudentCollegePreferenceInformation"
                              ).then((result) =>
                                result.json().then((obj) => {
                                  if (
                                    result.status == 200 ||
                                    result.status == 201
                                  ) {
                                    Get_StudentCollegePreferenceInformation_ByStudentId();
                                    Get_PreferenceMaster_ByStudentId(
                                      login.fld_id
                                    );
                                    Notiflix.Loading.remove();
                                    Notiflix.Notify.success(
                                      "Prefrence Deleted Successfully"
                                    );
                                    window.location.reload();
                                  } else {
                                    Notiflix.Loading.remove();
                                    Notiflix.Notify.failure(
                                      "There seems to be an Error. Please retry!"
                                    );
                                    window.location.reload();
                                  }
                                })
                              );
                            }}
                          />
                        </Tooltip>
                      </>
                    ),
                  };
                }),
              }}
              entriesOptions={[10, 25, 50, 100]}
            />
          ) : (
            <p className="m-3">No Data Found</p>
          )}
        </div>
      </div>
      {masterPrefrence.length > 0 && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {action != "view" && (
            <div className="card-vahani" style={{ marginTop: "24px" }}>
              {/* <p className="personal-info">Board / Examination / Stream</p> */}
              <div className="row py-2">
                <div className="col-lg-6 col-12 mobile-personal">
                  <FormControl fullWidth error={Boolean(errors.fld_preference)}>
                    <InputLabel id="demo-simple-select-label">
                      Preference*
                    </InputLabel>
                    <Select
                      label="Preference*"
                      {...register("fld_preference", {
                        required: "This field is required",
                      })}
                      value={
                        watch("fld_preference") ? watch("fld_preference") : ""
                      }
                      error={errors?.fld_preference}
                      disabled={disabledPrefrenece}
                    >
                      {masterPrefrence.map((record) => (
                        <MenuItem value={record.fld_preferencename}>
                          {record.fld_preferencename}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {" "}
                      {errors?.fld_preference
                        ? errors.fld_preference.message
                        : null}{" "}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-lg-6 col-12">
                  <TextField
                    fullWidth
                    label="Name of Institution*"
                    className="capital"
                    {...register("fld_institutename", {
                      required: "This field is required",
                    })}
                    value={
                      watch("fld_institutename")
                        ? watch("fld_institutename")
                        : ""
                    }
                    error={errors?.fld_institutename}
                    helperText={
                      errors?.fld_institutename
                        ? errors.fld_institutename.message
                        : null
                    }
                    // InputLabelProps={{ required: true, }}
                  />
                </div>
              </div>
              <div className="row py-2">
                <div className="col-lg-6 col-12 form-auto mobile-personal">
                  <FormControl fullWidth error={Boolean(errors.fld_state)}>
                    <InputLabel id="id-state">State*</InputLabel>
                    <Select
                      label="State*"
                      {...register("fld_state", {
                        required: "This field is required ",
                      })}
                      error={errors?.fld_state}
                      value={watch("fld_state") ? watch("fld_state") : ""}
                      onChange={(e) => {
                        Notiflix.Loading.dots();
                        PostApiCall.postRequest(
                          {
                            stateid: e.target.value,
                          },
                          "GetCity"
                        ).then((results) =>
                          results.json().then((objcity) => {
                            if (
                              results.status == 200 ||
                              results.status == 201
                            ) {
                              setCity(objcity.data);
                              setValue("fld_state", e.target.value);
                            }
                            Notiflix.Loading.remove();
                          })
                        );
                      }}
                    >
                      {state.map((record) => {
                        return (
                          <MenuItem value={record.value}>
                            {record.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {errors?.fld_state ? errors.fld_state.message : null}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-lg-6 col-12">
                  <FormControl fullWidth error={Boolean(errors.fld_city)}>
                    <InputLabel id="demo-simple-select-label">City*</InputLabel>
                    <Select
                      label="City*"
                      {...register("fld_city", {
                        required: "This field is required",
                      })}
                      error={errors?.fld_city}
                      value={watch("fld_city") ? watch("fld_city") : ""}
                    >
                      {city.map((record, item) => {
                        return (
                          <MenuItem value={record.value}>
                            {record.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {errors?.fld_city ? errors.fld_city.message : null}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="row py-2">
                <div
                  className="col-lg-6 col-12 drop-down"
                  style={{ position: "relative !important" }}
                >
                  <FormControl fullWidth error={Boolean(errors.fld_degree)}>
                    <InputLabel>Undergraduate Degree*</InputLabel>
                    <Select
                      label="Undergraduate Degree*"
                      {...register("fld_degree", {
                        required: "This field is required",
                      })}
                      error={errors?.fld_degree}
                      value={watch("fld_degree") ? watch("fld_degree") : ""}
                    >
                      <MenuItem value={"Architecture (BArch)"}>
                        Architecture (BArch)
                      </MenuItem>
                      <MenuItem
                        value={"Arts / Fine Arts / Applied Arts (B.A) (BFA)"}
                      >
                        Arts / Fine Arts / Applied Arts (B.A) (BFA)
                      </MenuItem>
                      <MenuItem value={"Commerce / Economics (BCom)"}>
                        Commerce / Economics (BCom)
                      </MenuItem>
                      <MenuItem value={"Computer (BCA, BCS)"}>
                        Computer (BCA, BCS)
                      </MenuItem>
                      <MenuItem value={"Education / Special Education (B.Ed)"}>
                        Education / Special Education (B.Ed)
                      </MenuItem>
                      <MenuItem
                        value={"Fashion / Textile / Interior Design/  (BDes)"}
                      >
                        Fashion / Textile / Interior Design/ (BDes)
                      </MenuItem>
                      <MenuItem value={"Law"}>Law</MenuItem>
                      <MenuItem value={" Live Sciences / Sciences (BSc)"}>
                        Live Sciences / Sciences (BSc)
                      </MenuItem>
                      <MenuItem value={"Management (BBA, BMS)"}>
                        Management (BBA, BMS)
                      </MenuItem>
                      <MenuItem value={"Mass Media / Journalism (BJMC)"}>
                        Mass Media / Journalism (BJMC)
                      </MenuItem>
                      <MenuItem
                        value={
                          "Medicine / Healthcare /Nursing (MBBS) (BDS) (BSc Nursing)"
                        }
                      >
                        Medicine / Healthcare /Nursing (MBBS) (BDS) (BSc
                        Nursing)
                      </MenuItem>
                      <MenuItem
                        value={
                          "Politics / Humanities / Psychology/ Language/ Social Sciences etc (BA)"
                        }
                      >
                        Politics / Humanities / Psychology/ Language/ Social
                        Sciences etc (BA)
                      </MenuItem>
                      <MenuItem value={"Technology (BTech, BE)"}>
                        Technology (BTech, BE)
                      </MenuItem>
                      <MenuItem value={" Travel / Hospitality / Tourism (BA)"}>
                        Travel / Hospitality / Tourism (BA)
                      </MenuItem>
                      {/* {year.map((record) => (
                        <MenuItem value={record.value}>{record.value}</MenuItem>
                      ))} */}
                    </Select>
                    <FormHelperText>
                      {errors?.fld_degree ? errors.fld_degree.message : null}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "26px",
                }}
              >
                <div className="but-width-form">
                  <Button
                    variant="contained"
                    className="but-login-vahani"
                    type="submit"
                  >
                    Save and update
                  </Button>
                </div>
              </div>
            </div>
          )}
        </form>
      )}
    </>
  );
}
