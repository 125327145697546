import React, { useContext, useEffect, useState } from "react";
// import "./App.css";
import CancelIcon from "@mui/icons-material/Cancel";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import GoToBtn from "../assets/icons/gototop.svg";
import EmployeeHeader from "../Components/Header/EmployeeHeader";
import EmployeeSidebar from "../Components/Sidebar/EmployeeSidebar";
import HRMSSidebar from "../Components/Sidebar/HRMSSidebar";
import EmployeeRouter from "../Router/EmployeeRouter";
import store from "../store";

function EmployeeHome() {
  const history = useHistory;
  const top = useLocation();
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  var AdminDetail = JSON.parse(localStorage.getItem("AccessAdmin"));
  const { drawerWidth, Showdraw, setShowdraw, setNotificationData } =
    useContext(store);
  const [IconStatus, setIconStatus] = useState(true);
  const [VideoToggle, setVideoToggle] = useState(false);
  const showdrawer = () => {
    setShowdraw(false);
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY > 500) {
      setIconStatus(true);
    } else {
      setIconStatus(false);
    }
  });
  useEffect(() => {
    GoToTop();
  }, [top]);
  const GoToTop = () => {
    window.scrollTo(0, 0);
  };

  if (
    localStorage.getItem("access") != null &&
    localStorage.getItem("access") != undefined
  ) {
    if (
      JSON.parse(localStorage.getItem("LoginDetail")).fld_type == "Employee"
    ) {
      return (
        <div>
          <EmployeeHeader />
          <EmployeeSidebar />

          <Box
            className="mobile-section"
            onClick={showdrawer}
            component="main"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              bgcolor: "background.default",
            }}
          >
            <Toolbar />
            <Typography
              className="ty-pd"
              style={{ minHeight: "calc(100vh - 105px)" }}
            >
              <Switch>
                <EmployeeRouter />
              </Switch>
            </Typography>
            <div
              style={{
                backgroundColor: "rgb(32 101 209)",
                color: "white",
              }}
              className="d-lg-flex justify-content-between align-items-center px-lg-5 login-footer mt-lg-0 mt-3 py-lg-2 py-2"
            >
              <p className="text-center mb-lg-0 mb-0">
                Powered by Global Trendz
              </p>
              <p className="text-center mb-lg-0 mb-0">
                Copyright © {new Date().getFullYear()} Vahani Scholarship.{" "}
                <span className="d-lg-inline-block d-block">
                  All rights reserved.
                </span>
              </p>
            </div>
          </Box>
          {IconStatus && (
            <div
              style={{
                position: "fixed",
                display: window.scrollY > 500 ? "block" : "none",
                bottom: "100px",
                right: "50px",
                zIndex: "999999",
                padding: "0px",
                borderRadius: "50%",
                backgroundColor: "white",
              }}
            >
              <img
                src={GoToBtn}
                onClick={GoToTop}
                width="50px"
                height="50px"
                className="cursor-pointer"
              />
            </div>
          )}
          <Dialog
            open={VideoToggle}
            PaperProps={{
              sx: {
                width: "100%",
                height: "auto",
              },
            }}
            onClose={() => setVideoToggle(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              className="d-flex justify-content-between"
              id="alert-dialog-title"
            >
              <h6>Vahani Scholarship Application Tutorial</h6>{" "}
              <CancelIcon
                className="cursor-pointer"
                onClick={() => setVideoToggle(false)}
              />
            </DialogTitle>
            <DialogContent>
              <div>
                <iframe
                  style={{ aspectRatio: "16/9", width: "100%" }}
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/gRqcuHfcDLo"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      );
    } else {
      return <Redirect exact to="/" />;
    }
  } else {
    return <Redirect exact to="/" />;
  }
}
export default EmployeeHome;
