import "../Personalinfo/Personalinfo.css";
import React, { useState, useContext } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { useForm } from "react-hook-form";
import PostApiCall from "../../../assets/API/PostApi";
import moment from "moment";
import Notiflix from "notiflix";
import TextField from "@mui/material/TextField";
export default function Essay({ stdid, action }) {
  var login = stdid
    ? { fld_id: stdid }
    : JSON.parse(localStorage.getItem("LoginDetail"))[0];
  React.useEffect(() => {
    Notiflix.Loading.dots();
    GetStudentEssayInformation();
    // statusupdate
  }, []);
  const GetStudentEssayInformation = () => {
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
      },
      "GetStudentEssayInformation_ById"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Loading.remove();
          reset(obj.data[0]);
        }
      })
    );
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  //
  const onPost = (essay) => {
    Notiflix.Loading.dots();
    var detail = JSON.parse(localStorage.getItem("LoginDetail"))[0];
    PostApiCall.postRequest(
      {
        studentId: login.fld_id,
        questionOne: essay.fld_questionOne,
        questionTwo: essay.fld_questionTwo,
        questionThree: essay.fld_questionThree,
        updatedby: detail.fld_id,
        updatedon: moment().format("lll"),
      },
      "UpdateStudentEssayInformation"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Saved and Updated Successfully");
          GetStudentEssayInformation();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("There seems to be an Error. Please retry!");
        }
      })
    );
  };
  const onSubmit = (essay) => {
    // e.preventDefault();
    onPost(essay);
    //
    //
  };
  // const handleChange = (e) => {
  //   e.preventDefault();
  //   Notiflix.Notify.failure("You Can’t cut copy and paste");
  // };
  return (
    <>
      <div>
        <div className="form-head">
          <div
            style={{
              borderLeft: "10px solid rgb(32, 101, 209)",
              paddingLeft: "10px",
            }}
          >
            <h4>Essay</h4>
          </div>
          <div class="alert alert-warning mt-3" role="alert">
            Note: Copy Pasting directly from Internet will result to
            cancellation of the application.
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-vahani" style={{ marginTop: "24px" }}>
            <p className="personal-info">
              1. Vahani is looking for individuals who will be future leaders or
              influencers in their society. Explain how your preferred course
              and college will contribute to your personal achievement,
              intellectual growth and leadership potential. (Min 200 & Max. 750
              words).
            </p>
            <div className="row py-2">
              <div className="col-lg-12 col-12">
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Answer*"
                  multiline
                  style={{ width: "100%" }}
                  {...register("fld_questionOne", {
                    validate: {
                      lessthan: (val) =>
                        val.split(" ").length > 201 ||
                        "Enter minimum 200 words.",
                      morethan: (val) =>
                        val.split(" ").length < 751 ||
                        "Enter maximum 750 words.",
                    },
                    required: "This field is required",
                  })}
                  value={
                    watch("fld_questionOne") ? watch("fld_questionOne") : ""
                  }
                  error={errors?.fld_questionOne}
                  helperText={
                    errors?.fld_questionOne
                      ? errors.fld_questionOne.message
                      : null
                  }
                />
              </div>
            </div>
          </div>
          <div className="card-vahani" style={{ marginTop: "24px" }}>
            <p className="personal-info">
              2. Provide a comprehensive background of your family. Include
              details such as your upbringing, the number of siblings in your
              family, the earning member's occupation and any significant
              financial challenges or hardships that have impacted you and your
              family. (Min 200 & Max. 750 words )
            </p>
            <div className="row py-2">
              <div className="col-lg-12 col-12">
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Answer*"
                  multiline
                  style={{ width: "100%" }}
                  {...register("fld_questionTwo", {
                    required: "This field is required",
                    validate: {
                      lessthan: (val) =>
                        val.split(" ").length > 201 ||
                        "Enter minimum 200 words.",
                      morethan: (val) =>
                        val.split(" ").length < 751 ||
                        "Enter maximum 750 words.",
                    },
                  })}
                  value={
                    watch("fld_questionTwo") ? watch("fld_questionTwo") : ""
                  }
                  error={errors?.fld_questionTwo}
                  helperText={
                    errors?.fld_questionTwo
                      ? errors.fld_questionTwo.message
                      : null
                  }
                />
              </div>
            </div>
          </div>
          <div className="card-vahani" style={{ marginTop: "24px" }}>
            <p className="personal-info">
              3. Describe a significant personal challenge or setback you have
              faced and how you overcame it. Reflect on the lessons learned from
              this experience and how it has influenced your character. (Min 200
              & Max. 750 words)
            </p>
            <div className="row py-2">
              <div className="col-lg-12 col-12">
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Question3*"
                  multiline
                  style={{ width: "100%" }}
                  {...register("fld_questionThree", {
                    required: "This field is required",
                    validate: {
                      lessthan: (val) =>
                        val.split(" ").length > 201 ||
                        "Enter minimum 200 words.",
                      morethan: (val) =>
                        val.split(" ").length < 751 ||
                        "Enter maximum 750 words.",
                    },
                  })}
                  value={
                    watch("fld_questionThree") ? watch("fld_questionThree") : ""
                  }
                  error={!!errors?.fld_questionThree}
                  helperText={
                    errors?.fld_questionThree
                      ? errors.fld_questionThree.message
                      : null
                  }
                  onChange={(e) => {
                    if (e.target.value.trim().split(" ").length < 201) {
                      setValue("fld_questionThree", e.target.value);
                      setError("fld_questionThree", {
                        type: "custom",
                        message: "Enter minimum 200 words.",
                      });
                    } else if (e.target.value.trim().split(" ").length > 751) {
                      setValue("fld_questionThree", e.target.value);
                      setError("fld_questionThree", {
                        type: "custom",
                        message: "Enter maximum 750 words",
                      });
                    } else {
                      setValue("fld_questionThree", e.target.value);
                      clearErrors("fld_questionThree");
                    }
                  }}
                />
              </div>
            </div>
            {action != "view" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "26px",
                }}
              >
                <div className="but-width-form">
                  <Button
                    variant="contained"
                    className="but-login-vahani"
                    type="submit"
                  >
                    Save and update
                  </Button>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
